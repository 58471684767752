import BurgerMenu from './BurgerMenu';
import WhiteReadism from './WhiteReadism';
import Close from './Close';
import GreenReadism from './GreenReadism';
import Facebook from './Facebook';
import Google from './Google';
import ErrorInput from './ErrorInput';
import EyeOpen from './EyeOpen';
import EyeClose from './EyeClose';
import CheckedBox from './CheckedBox';
import ReadismWithTextLogo from './ReadismWithTextLogo';
import Search from './Search';
import Mail from './Mail';
import Notification from './Notification';
import CaretDownWhite from './CaretDownWhite';
import ReadismIconOnly from './ReadismIconOnly';
import Newspaper from './Newspaper';
import Users from './Users';
import Writing from './Writing';
import Plus from './Plus';
import WhitePen from './WhitePen';
import Trash from './Trash';
import Check from './Check';
import Star from './Star';
import HomeFeatures from './HomeFeatures';
import HomeGenres from './HomeGenres';
import FooterSocialIcons from './FooterSocialIcons';
import StarOutline from './StarOutline';
import BookArtwork from './BookArtwork';
import SolidPen from './SolidPen';
import PlusCircle from './PlusCircle';
import Download from './Download';
import AddUser from './AddUser';
import OutGroup from './OutGroup';
import ThreeDots from './ThreeDots';

import HomeBooks from './HomeBooks';
import HomeMes from './HomeMes';
import HomeQuote from './HomeQuote';
import HomeWriting from './HomeWriting';
import BookShelfPoster from './BookShelfPoster';
import Pin from './Pin';
import FacebookCircle from './FacebookCircle';
import Shield from './Shield';
import UserProfileNoActivity from './UserProfileNoActivity';
import Readism06 from './Readism06';
import InfoCircle from './InfoCircle';
import Upload from './Upload';

import Fire from './Fire';
import NewIcon from './NewIcon';
import ThreeStar from './ThreeStar';
import Sparkle from './Sparkle';

// https://react-svgr.com/playground/?native=true

export const ICON = {
  BurgerMenu,
  WhiteReadism,
  Close,
  ReadismWithTextLogo,
  Facebook,
  Google,
  ErrorInput,
  EyeOpen,
  EyeClose,
  CheckedBox,
  Search,
  Notification,
  Mail,
  CaretDownWhite,
  ReadismIconOnly,
  Newspaper,
  Users,
  Writing,
  Plus,
  WhitePen,
  Trash,
  Check,
  Star,
  HomeFeatures,
  HomeGenres,
  FooterSocialIcons,
  StarOutline,
  BookArtwork,
  SolidPen,
  PlusCircle,
  Download,
  AddUser,
  OutGroup,
  ThreeDots,
  Pin,
  HomeBooks,
  HomeMes,
  HomeQuote,
  HomeWriting,
  BookShelfPoster,
  FacebookCircle,
  Shield,
  UserProfileNoActivity,
  Readism06,
  InfoCircle,
  Upload,
  Fire,
  NewIcon,
  ThreeStar,
  Sparkle,
};

export const IMAGE = {
  CheckMailBig: require('./CheckMailBig.webp'),
  SlideBg1: require('./slide_bg_1.webp'),
  SlideBg2: require('./slide_bg_2.webp'),
  SlideBg3: require('./slide_bg_3.webp'),
  SlideBg4: require('./slide_bg_4.webp'),
  BookBanner: require('./book_banner.webp'),
  BookIcon10Deg: require('./book_10deg.webp'),
  BookArtwork: require('./book_artwork.webp'),
  GroupBanner: require('./banner_group.webp'),
  QuotesBanner: require('./banner_quotes.webp'),
  WritingBanner: require('./banner_writing.webp'),
  AuthorBanner: require('./banner_author.webp'),

  QuoteBg1: require('./quote_bg/quote_bg_1.webp'),
  QuoteBg2: require('./quote_bg/quote_bg_2.jpg'),
  QuoteBg3: require('./quote_bg/quote_bg_3.jpg'),
  QuoteBg4: require('./quote_bg/quote_bg_4.jpg'),
  QuoteBg5: require('./quote_bg/quote_bg_5.jpg'),
  QuoteBg6: require('./quote_bg/quote_bg_6.jpg'),

  WelcomeUserBg1: require('./welcome-user-slide/slide1.webp'),
  WelcomeUserBg2: require('./welcome-user-slide/slide2.webp'),
  WelcomeUserBg3: require('./welcome-user-slide/slide3.webp'),
  Readism06: require('./readism-06_quote-01.svg'),
  BannerNews: require('./banner_news.jpg'),

  QuoteContestStats: require('./quote_contest_summarize.jpg'),
  QuoteContestEnd: require('./quote_content_end.jpg'),
  Font_QuoteContestStats: require('./MTD_JV_Signature_Regular.ttf'),

};