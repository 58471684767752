import moment from 'moment';
import 'moment/min/moment-with-locales';
import 'moment/src/locale/en-gb'
import 'moment/src/locale/vi'

class TimeHelper {

  format(t : any, f = 'DD/MM/YYYY') {
    return moment(t).format(f);
  }

  fromNow = (time, format = undefined) => {
    if (!time) return '';
    moment.locale('vi');
    const text = moment(time, format).fromNow();
    if (text === 'Invalid date') {
      console.log('Invalid date', time);
    }
    return text === 'Invalid date' ? '' : text;
  }

  wait = async (milisec) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, milisec)
  })

  _Time = 0
  throttle(fn, wait) {
    var time = Date.now();
    if ((time - this._Time) > wait) {
      fn();
      this._Time = Date.now();
    }
  }

  _Timeout;
  debounce(fn, wait) {
    if (this._Timeout) clearTimeout(this._Timeout);
    this._Timeout = setTimeout(() => {
      fn();
    }, wait);
  }

  isToday(time, format = undefined) {
    return moment(time, format).isSame(new Date(), "day");
  }

  diffDays(time1, time2) {
    var a = moment(time1);
    var b = moment(time2);
    const diff = a.diff(b, 'days');
    return diff;
  }

  diffHours(time1, time2) {
    var a = moment(time1);
    var b = moment(time2);
    const diff = a.diff(b, 'hours');
    return diff;
  }
}

const timeHelper = new TimeHelper();
window.timeHelper = timeHelper;
export default timeHelper;