import React, { Component, useState, useRef } from 'react';
import { Col } from 'react-quick-style-components';
import AsyncSelect, { NoOptionsMessage, LoadingMessage } from '../elements/AsyncSelect';
import Store from 'store';
import { SCREEN_NAME, COLOR } from 'const';
import { useNavFunc } from 'navigation';
import { VarHelper } from 'helpers';
import { hideModal } from 'components/elements';

interface ISearchBookSuggestionProps {
  navigation?: any,
  isInModal?: boolean,
  components?: any,
}

const DropdownIndicator = () => null;
// const NoOptionsMessage = () => null;

const SearchBookSuggestion = ({ navigation, isInModal, ...props } : ISearchBookSuggestionProps) => {
  const [value, onChange] = useState();
  const [{}, bApi] = Store.Book.createBookStore();
  const [name, setName] = useState('');
  return (
    <AsyncSelect
      label=""
      placeholder="Nhập tìm Sách theo tên, tác giả"
      value={value}
      onChange={(data) => {
        onChange(data);
        if (!!data?.value) {
          navigation.navigate(SCREEN_NAME.BookDetail, { id: data.value, name: VarHelper.toSlug(data.label) });
          isInModal && hideModal();
        }
      }}
      noOptionsMessage={!!name ? undefined : () => null}
      components={{ NoOptionsMessage, DropdownIndicator, LoadingMessage, ...props.components }}
      defaultOptions={[]}
      onInputChange={(nameInput) => { setName(nameInput); return nameInput; }}
      loadOptions={async (value, callback) => {
        const [res, err] = await bApi.listBook({
          visibility: 'public',
          page: 1, pageSize: 20,
          options: {
            search: [{ label: 'name', value: value }, { label: 'authors', value: value }]
          },
        });
        if (!!res && !!res.data && !!res.data.rows) {
          callback(res.data.rows.map(val => ({ value: val.id, label: val.name, raw: val })));
        }
      }}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          navigation.navigate(SCREEN_NAME.BookHomepage, { search: name });
          isInModal && hideModal();
        }
      }}
      additionStyles={{
        placeholder: (style) => ({
          ...style,
          fontSize: 14,
          color: COLOR.GRAY_500,
          cursor: 'text',
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          cursor: 'text',
        }),
      }}
    />
  )
};

export default SearchBookSuggestion;