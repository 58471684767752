import React, { useEffect, useState } from 'react';
import { Col, Text, Row, Scroll } from 'react-quick-style-components';
import { COLOR, ERROR, STYLES, useFontSizeStyle } from 'const';
import { A, useNavFunc } from 'navigation';
import { SCREEN_NAME } from 'const';
import {
  Header, Button, LeftSidebarOneThird, Avatar,
  Placeholder, ReviewItemPublic, FeedItem, usePreDataOrFetchApi, QuoteNoCreatorItem, WritingItem, WritingInProfileItem,
  AvatarDisplayAndUpdate, BookShelfItem,
  RatioCol, WelcomeUserProfile,
  Tooltip,
} from 'components';
import { ICON } from 'assets';
import Store from 'store';
import { IActivity, IUser, IUserProfile } from 'type';
import { TimeHelper, VarHelper } from 'helpers';
import AuthorCover from './AuthorCover';
import AddFriendButton from './AddFriendButton.Comp';

interface IUserProfileProps {
  noForce?: boolean
  noFetchOnFocus?: boolean,
  children?: any,
}

const UserProfile = (props: IUserProfileProps) => {
  const { route, navigation, goWithPreData } = useNavFunc();
  // @ts-ignore
  const { id, name } = route.params;
  const [{ user }, uApi] = Store.User.createUserStore();
  const [{}, bApi] = Store.Book.createBookStore();

  const [userData, setUserData, { fetchError: errorMes }] = usePreDataOrFetchApi<IUserProfile>(id, 'user', { force: !props.noForce, fetchOnFocus: !props.noFetchOnFocus });

  if (!id) return <Col flex1 middle><Text>Trang không hợp lệ</Text></Col>;
  if (errorMes) return <Col flex1 middle><Text>{errorMes}</Text></Col>;

  const goToFriendProfile = (id, name) => {
    navigation.navigate(SCREEN_NAME.UserProfile, { id, name: VarHelper.toSlug(name) });
  };

  // console.log('userData', userData);

  if (!userData) return (
    <LeftSidebarOneThird
      renderMainContent={() => (
        <Col>
          <Placeholder.Newfeed width={'100%'} />
        </Col>
      )}
      renderSidebarContent={() => (
        <Col paddingVertical24 paddingHorizontal16 borderRadius4 bgWhite>
          <Col middle>
            <Placeholder.Circle width={139} />
          </Col>
          <Col marginTop24 middle>
            <Placeholder.LineText width={200} height={20} />
          </Col>
        </Col>
      )}
    />
  );

  const publicListBookshelves = user.id === userData?.id ? userData?.bookshelves : bApi.computePublicBookshelfData(userData?.bookshelves);

  const color = (() => {
    if (userData?.type === 'ADMIN') return COLOR.MAIN;
    if (userData?.type === 'USER') return COLOR.FONT;
    if (userData?.type === 'AUTHOR') return COLOR.FONT;
    if (userData?.type === 'UNVERIFY-USER') return COLOR.GRAY_500;
  })();

  const icon = () => {
    if (userData?.type === 'AUTHOR') return (
      <Col width15 height15 middle bgMain borderRadius10>
        <ICON.Check color='white' width={25/2.5} height={18/2.5} />
      </Col>
    )
    if (userData?.type === 'ADMIN') return (
      <ICON.Shield fill={COLOR.MAIN} width={15} height={15} />
    );
    return null;
  };

  const isNotMyUserNoActivity = userData.id !== user.id && !userData.profileData?.reviews?.length && !userData.profileData?.quotes?.length && !userData.profileData?.writing?.length && !userData.profileData?.manga?.length;

  const isMyUserNoActivity = userData.id === user.id && !userData.profileData?.reviews?.length && !userData.profileData?.quotes?.length && !userData.profileData?.writing?.length && !userData.profileData?.manga?.length;
  
  const isMyUserFistDay = userData.id === user.id && TimeHelper.isToday(userData.createdAt);

  return (
    <LeftSidebarOneThird
      renderHeader={() => {
        return (
          <>
            <Header />
            {Boolean(userData.type === 'AUTHOR') && <AuthorCover userId={userData.id} uri={userData.coverUrl} marginTop32 />}
          </>
        );
      }}
      renderMainContent={() => !!props.children ? props.children : (
        <Scroll>
          {Boolean(isMyUserNoActivity || isMyUserFistDay) && (
            <WelcomeUserProfile />
          )}
          {Boolean(isNotMyUserNoActivity) && (
            <Col height200 middle>
              <ICON.UserProfileNoActivity />
              <Text subtitle1 color={COLOR.GRAY_500}>Chưa có hoạt động nào</Text>
            </Col>
          )}
          {Boolean(publicListBookshelves?.length > 0) && (
            <Col marginBottom24>
              <Row justifyContent="space-between">
                <Text btnTextLarge>TỦ SÁCH</Text>
                <Col padding4 onPress={() => navigation.navigate(SCREEN_NAME.UserProfileAllBookshelf, { id, name })}>
                  <Text colorMain subtitle1>Xem tất cả</Text>
                </Col>
              </Row>
              <Row xs='50%' marginLeft={-12} marginRight={-12} padding6>
                {publicListBookshelves.map(val => (
                  <BookShelfItem
                    data={val} margin6
                    key={val.id}
                    onPress={() => goWithPreData(SCREEN_NAME.UserProfileBookshelfDetail, { id, name, bookshelf_id: val.id }, val)}
                    forPublic
                  />
                ))}
              </Row>
              
            </Col>
          )}

          {Boolean(userData.profileData?.reviews?.length > 0) ? (
            <Col marginBottom24>
              <Row justifyContent="space-between">
                <Text btnTextLarge>ĐÁNH GIÁ</Text>
                <Col padding4 onPress={() => navigation.navigate(SCREEN_NAME.UserProfileAllReview, { id, name })}>
                  <Text colorMain subtitle1>Xem tất cả</Text>
                </Col>
              </Row>
              {userData.profileData?.reviews.sort((a,b) => a.createdAt > b.createdAt ? -1 : 1 ).map(val => (
                <FeedItem
                  key={val.id}
                  data={{
                    object: {
                      type: 'review',
                      id: val.id,
                    },
                    data: val
                  } as IActivity}
                  marginTop16
                />
              ))}
            </Col>
          ) : Boolean(userData?.id === user.id) ? (
            <Col marginBottom24>
              <Text btnTextLarge>ĐÁNH GIÁ</Text>
              <Col marginTop16 bgWhite borderRadius4 padding24>
                <Text body1>Bạn chưa có đánh giá nào</Text>
                <Col marginTop24 padding24 borderRadius4 borderWidth1 borderColor={COLOR.BORDER} middle>
                  <Text subtitle1 center>"Đọc sách là hộ chiếu cho vô số cuộc phiêu lưu"</Text>
                  <Text body2 marginTop12>Mary Pope Osborne</Text>
                </Col>
                <Button
                  solid large alignSelf='center'
                  marginTop24 text="Khám phá sách" width232 height43
                  onPress={() => navigation.navigate(SCREEN_NAME.BookHomepage)}
                />
              </Col>
            </Col>
          ) : null}
          
          {Boolean(userData.profileData?.quotes?.length > 0) ? (
            <Col marginBottom24>
              <Row justifyContent="space-between">
                <Text btnTextLarge>TRÍCH DẪN</Text>
                <Col padding4 onPress={() => navigation.navigate(SCREEN_NAME.UserProfileAllQuote, { id, name })}>
                  <Text colorMain subtitle1>Xem tất cả</Text>
                </Col>
              </Row>
              {userData.profileData?.quotes.map(val => (
                <QuoteNoCreatorItem
                  key={val.id}
                  data={val}
                  marginTop16
                />
              ))}
            </Col>
          ) : Boolean(userData?.id === user.id) ? (
            <Col marginBottom24>
              <Text btnTextLarge>TRÍCH DẪN</Text>
              <Col marginTop16 bgWhite borderRadius4 padding24>
                <Text body1>Bạn chưa có trích dẫn nào</Text>
                <Col marginTop24 padding24 borderRadius4 borderWidth1 borderColor={COLOR.BORDER} middle>
                  <Text subtitle1 center>"Một ngày nào đó, bạn sẽ đủ già để đọc lại những câu chuyện cổ tích"</Text>
                  <Text body2 marginTop12>C.S. Lewis</Text>
                </Col>
                <Button
                  solid large alignSelf='center'
                  marginTop24 text="Thêm trích dẫn" width232 height43
                  onPress={() => navigation.navigate(SCREEN_NAME.CreateQuote)}
                />
              </Col>
            </Col>
          ) : null}
          {Boolean(userData.profileData?.writing?.length > 0) ? (
            <Col marginBottom24>
              <Row justifyContent="space-between">
                <Text btnTextLarge>VIẾT</Text>
                <Col padding4 onPress={() => navigation.navigate(SCREEN_NAME.UserProfileAllWriting, { id, name })}>
                  <Text colorMain subtitle1>Xem tất cả</Text>
                </Col>
              </Row>
              <Row xs='50%' marginLeft={-12} marginRight={-12} padding6>
                {userData.profileData?.writing.map(val => (
                  <WritingInProfileItem
                    data={val} margin6
                    key={val.id}
                    onPress={() => navigation.navigate(SCREEN_NAME.WritingDetail, { id: val.id, name: VarHelper.toSlug(val.name) })}
                  />
                ))}
              </Row>
              {/* {userData.profileData?.writing.map(val => (
                <FeedItem
                  key={val.id}
                  data={{
                    data: val,
                    object: {
                      id: val.id,
                      type: 'writing',
                    }
                  } as IActivity}
                  onPress={() => navigation.navigate(SCREEN_NAME.WritingDetail, { id: val.id, name: VarHelper.toSlug(val.name) })}
                  marginTop16
                />
              ))} */}
            </Col>
          ) : Boolean(userData?.id === user.id) ? (
            <Col marginBottom24>
              <Text btnTextLarge>VIẾT</Text>
              <Col marginTop16 bgWhite borderRadius4 padding24>
                <Text body1>Bạn chưa viết tác phẩm nào</Text>
                <Col marginTop24 padding24 borderRadius4 borderWidth1 borderColor={COLOR.BORDER} middle>
                  <Text subtitle1 center>“Chúng ta hãy dịu dàng và tử tế nâng niu những phương tiện của tri thức. Chúng ta hãy dám đọc, nghĩ, nói và viết.”</Text>
                  <Text body2 marginTop12>John Adams</Text>
                </Col>
                <Button
                  solid large alignSelf='center'
                  marginTop24 text="Viết mới" width232 height43
                  onPress={() => navigation.navigate(SCREEN_NAME.CreateWriting)}
                />
              </Col>
            </Col>
          ) : null}

        {Boolean(userData.profileData?.manga?.length > 0) ? (
          <Col marginBottom24>
            <Row justifyContent="space-between">
              <Text btnTextLarge>TRUYỆN TRANH</Text>
              <Col padding4 onPress={() => navigation.navigate(SCREEN_NAME.UserProfileAllWriting, { id, name })}>
                <Text colorMain subtitle1>Xem tất cả</Text>
              </Col>
            </Row>
            <Row xs='50%' marginLeft={-12} marginRight={-12} padding6>
              {userData.profileData?.manga.map(val => (
                <WritingInProfileItem
                  data={val} margin6
                  key={val.id}
                  onPress={() => navigation.navigate(SCREEN_NAME.MangaDetail, { id: val.id, name: VarHelper.toSlug(val.name) })}
                />
              ))}
            </Row>
          </Col>
        ) : null}

        </Scroll>
      )}
      renderSidebarContent={() => (
        <Col >
          <Col paddingVertical24 paddingHorizontal16 borderRadius4 bgWhite>
            <Col middle>
              {Boolean(user.id === id) ? (
                <AvatarDisplayAndUpdate name={userData.displayName} uri={userData.photoUrl} size={139} />
              ) : (
                <Avatar name={userData.displayName} uri={userData.photoUrl} size={139} />
              )}
              <Text color={color} marginTop24 h5>{userData.displayName} {icon()}</Text>
              {Boolean(userData.type === 'AUTHOR') && (
                <Col
                  absolute width70 height33 borderRadius48 bgMain middle borderWidth1 borderColor="white"
                  left='50%' top={139} transform={[{ translateX: -70 / 2 }, { translateY: -33 / 2 }]}
                >
                  <Text subtitle2 colorWhite>Tác giả</Text>
                </Col>
              )}
              {Boolean(userData.type === 'UNVERIFY-USER') && (
                <Text caption color={COLOR.GRAY_500}>(chưa xác thực)</Text>
              )}
            </Col>
            {Boolean(!!userData.profileData?.numberOfReviews || !!userData.profileData?.numberOfFriend || !!userData.profileData?.numberOfWriting) && (
              <Row middle marginTop8>
                {!!userData.profileData?.numberOfWriting && <Text color={COLOR.GRAY_500} body1 marginHorizontal6>{userData.profileData?.numberOfWriting} viết</Text>}
                {!!userData.profileData?.numberOfReviews && <Text color={COLOR.GRAY_500} body1 marginHorizontal6>{userData.profileData?.numberOfReviews} đánh giá</Text>}
                {!!userData.profileData?.numberOfFriend && <Text color={COLOR.GRAY_500} body1 marginHorizontal6>{!!userData.profileData?.numberOfFriend} bạn bè</Text>}
              </Row>
            )}
            {Boolean(user.id === id) ? (
              <Button marginTop24 solid={false} outline small text={'Chỉnh sửa'} onPress={() => navigation.navigate(SCREEN_NAME.UserProfileSetting)} />
            ) : (
              <Row marginTop24>
                <Button onPress={() => navigation.navigate(SCREEN_NAME.DirectMessage, { mode: 'gui-tin-nhan', userId: id })} flex1 marginRight8 solid={false} outline small text={'Nhắn tin'} />
                <AddFriendButton id={userData.id} photoUrl={userData.photoUrl} displayName={userData.displayName} />
              </Row>
            )}

            {Boolean(userData.type === 'AUTHOR' && (userData.bio || userData.website)) && (
              <Col marginTop24>
                <Text subtitle1>THÔNG TIN TÁC GIẢ</Text>

                {Boolean(userData.bio) && (
                  <Text body1 marginTop16><Text bold>Giới thiệu:</Text> {userData.bio}</Text>
                )}
                {Boolean(userData.website) && (
                  <Text body1 marginTop16 onPress={() => {
                    typeof window !== 'undefined' && window.open('http://' + userData.website, '_blank');
                  }}><Text bold>Website:</Text> <Text onHoverStyle={{ color: COLOR.MAIN }}>{user.website}</Text></Text>
                )}
              </Col>
            )}

            {Boolean(userData.type !== 'AUTHOR' && userData.bio) && (
              <Col marginTop24>
                <Text subtitle1>THÔNG TIN</Text>
                <Text body1 marginTop8>{userData.bio}</Text>
              </Col>
            )}
          </Col>


          {Boolean(userData.friends && userData.friends.length > 0) && (
            <Col paddingVertical24 paddingHorizontal16 borderRadius4 bgWhite marginTop2>
              <Text subtitle1>BẠN BÈ CỦA {userData.firstName.toUpperCase()}</Text>

              <Row xs='25%' padding6 marginLeft={-12} marginRight={-12}>
                {userData.friends.slice(0, 4).map((val, i) => (
                  <Col margin6 padding6 key={val.id}>
                    {Boolean(val) && (
                      <Tooltip title={val.name}>
                        <RatioCol width='100%' ratio={1} overflow='hidden' onPress={() => goToFriendProfile(val.id, val.name)}>
                          <Avatar uri={val.avatar} name={val.name} />
                        </RatioCol>
                      </Tooltip>
                    )}
                  </Col>
                ))}
              </Row>
              <Text bold colorMain onPress={() => navigation.navigate(SCREEN_NAME.UserProfileAllFriends, { id, name })}>Xem tất cả {userData.friends.length} bạn bè</Text>
            </Col>
          )}
        </Col>
      )}
    />
  );
};

export default UserProfile;