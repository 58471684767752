
import React from 'react';
import { Col, Row } from 'react-quick-style-components';

export default () => {
  console.count('rerender');
  return (
    <Col flex1 bgMain>
      <Row xs='100%' md='1:2' alignItems="flex-start" padding20>
        <Col height100 backgroundColor={"red"} />
        <Col height100 backgroundColor={"yellow"} />
      </Row>
    </Col>
  );
}