import React, { useEffect, useState } from 'react';
import { Col, Row, Text, usePropsStyle } from 'react-quick-style-components';
import { Button, Popup, useSubmitUIState } from 'components';
import { COLOR, ERROR } from 'const';
import Store from 'store';

interface IAddFriendButtonProps {
  id: string,
  displayName: string,
  photoUrl: string,
}

const STATUS = {
  // NO_FRIEND: 'NO_FRIEND',
  // DID_SENT: 'DID_SENT',
  // IS_FRIEND: 'IS_FRIEND',

  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  REQUESTED: 'REQUESTED',
  DENIED: 'DENIED',
  AWAIT_RESPONSE: 'AWAIT-RESPONSE',
}

const AddFriendButton = (props : IAddFriendButtonProps) => {
  const { id, displayName, photoUrl } = props;
  const [{ friends }, uApi] = Store.User.createUserStore();

  const [status, setStatus] = useState(STATUS.INACTIVE);
  const [{ loading }, setUI] = useSubmitUIState(); 

  useEffect(() => {
    const findFriend = friends.find(val => val.id === id);
    if (findFriend && findFriend.status) {
      setStatus(findFriend.status);
    } 
  }, [friends, id]);

  const sendFriendRequest = async () => {
    setUI({ loading: true });
    const [res, err] = await uApi.addFriend({ id, displayName, photoUrl });
    setUI({ loading: false });
    if (err) return Popup.showError(ERROR.parseError(err));
    if (res.error) return Popup.showError(ERROR.parseError(res.error));
    setStatus(STATUS.REQUESTED);
  };

  const unfriend = async () => {
    setUI({ loading: true });
    const [res, err] = await uApi.unFriend({ id, displayName, photoUrl });
    setUI({ loading: false });
    if (err) return Popup.showError(ERROR.parseError(err));
    if (res.error) return Popup.showError(ERROR.parseError(res.error));
    setStatus(STATUS.INACTIVE);
  };

  const acceptRequest = async () => {
    setUI({ loading: true });
    const [res, err] = await uApi.acceptFriend({ id, displayName, photoUrl });
    setUI({ loading: false });
    if (err) return Popup.showError(ERROR.parseError(err));
    if (res.error) return Popup.showError(ERROR.parseError(res.error));
    setStatus(STATUS.ACTIVE);
  }

  if (status === STATUS.INACTIVE)
  return (
    <Button flex1 solid small text={'Kết bạn'} onPress={sendFriendRequest} loading={loading} />
  );

  if (status === STATUS.REQUESTED)
  return (
    <Button flex1 solid small text={'Đã gửi kết bạn'} onPress={() => {}} />
  );

  if (status === STATUS.DENIED)
  return (
    <Button flex1 solid small text={'kết bạn'} onPress={() => {}} backgroundColor={COLOR.GRAY_500} />
  );

  if (status === STATUS.ACTIVE)
  return (
    <Button flex1 solid small text={'Hủy kết bạn'} onPress={unfriend} loading={loading} backgroundColor={COLOR.DANGER_500} />
  );

  if (status === STATUS.AWAIT_RESPONSE)
  return (
    <Button flex1 solid small text={'Đồng ý kết bạn'} onPress={acceptRequest} loading={loading} />
  );

  return null;
};

export default AddFriendButton;