import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import {
  Popup, Button, Input, Uploader, Select, Header, Checkbox, RightSidebarOneThird, Avatar, RateStars,
  RichTextEditor2,
} from 'components';
import { useNavFunc } from 'navigation';
import { COLOR, DEVICE_INFO, ERROR, SCREEN_NAME, BOOK_VISIBILITY } from 'const';
import { ICON } from 'assets';
import Store from 'store';
import { VarHelper } from 'helpers';
import { Entypo } from '@expo/vector-icons';

let _timeout;
const debounce = (func) => {
  if (_timeout) clearTimeout(_timeout);
  _timeout = setTimeout(() => {
    func();
  }, 300);
}

const CreateBook = () => {
  const { reset, navigate } = useNavFunc();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [authorName, setAuthorName] = useState('');
  const [publisher, setPublisher] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [genres, setGenres] = useState([]);
  const [similarBooks, setSimilarBooks] = useState([]);
  const [localFile, setLocalFile] = useState({
    path: '',
    mime: '',
    name: '',
    raw: undefined,
  });
  const [rate, setRate] = useState(0);
  const [spoiler, setSpoiler] = useState(false);

  const [showLinkNotice, setShowLinkNotice] = useState(false);

  const rV = useDynamicResponsiveValue();
  const [{ }, bookApi] = Store.Book.createBookStore();
  const [{ listGenres }, gApi] = Store.Genres.createStore();
  const { token, user } = Store.User.state;
  const uploaderRef = useRef<{ openFileDialog: Function, uploadLocal: Function }>();
  const editorRef = useRef<{ getHTML: Function, getJSON: Function, getText: Function }>();

  useEffect(() => {
    gApi.getList('BOOK');
  }, []);

  const searchForExistedBook = useCallback(async () => {
    const keyword = name;
    if (!keyword) return;
    const [res, err] = await bookApi.listBook({
      visibility: 'public',
      page: 1,
      pageSize: 3,
      options: {
        search: [{ label: 'name', value: keyword }],
      }
    });
    if (res && res.data && res.data.rows) {
      setSimilarBooks(res.data.rows);
    }
  }, [name])

  const submit = async () => {
    if (!localFile.path) return setErrorMessage(ERROR.book_need_image);
    if (!uploaderRef.current) return setErrorMessage(ERROR.co_error);
    if (!name || !authorName || genres.length === 0) return setErrorMessage(ERROR.co_fill_all_required_form);
    const review = editorRef.current?.getText();
    const reviewJSON = editorRef.current?.getJSON();
    const reviewHTML = editorRef.current?.getHTML();
    const shouldReview = !!rate || !!review;
    const shouldAskAboutKeepEditingReview = !rate;

    const doBook = async (withReview: boolean) => {
      setLoading(true);
      const [bookImage, err] = await uploaderRef.current.uploadLocal(localFile);
      if (err) {
        setLoading(false);
        return setErrorMessage(ERROR.parseError(err));
      }
      const [res, err2] = await bookApi.useContributeBook({
        name,
        authors: [{ id: 0, name: authorName, avatar: '' }],
        content: description,
        genres: genres.map(val => ({ id: val.value, name: val.label })),
        publisher,
        images: !!bookImage ? [bookImage] : [],
        rate: withReview ? rate : undefined,
        review: withReview ? review : undefined,
        reviewJSON: withReview ? reviewJSON : undefined,
        reviewHTML: withReview ? reviewHTML : undefined,
        hasSpoiler: spoiler,
      });
      setLoading(false);
      if (err2) return setErrorMessage(ERROR.parseError(err2));
      if (res.error) return setErrorMessage(ERROR.parseError(res.error));
      Popup.show(
        'Đóng góp của bạn đã được gửi, chúng tôi sẽ tiến hành kiểm duyệt và đăng lên sớm, xin cảm ơn.',
        [
          { text: 'OK', solid: true, onPress: () => reset(SCREEN_NAME.UserProfileBookshelfDetail, { id: user.id, name: VarHelper.toSlug(user.displayName), bookshelf_id: res.bookShelf?.id }) },
          // {
          //   text: 'Đăng sách khác', solid: false, onPress: () => {
          //     if (DEVICE_INFO.IS_WEB) window.location.reload();
          //   }
          // }
        ],
      );
    };

    if (shouldReview) {
      // if (review.length < 300) return setErrorMessage(ERROR.review_too_short);
      return doBook(true);
    }
    if (shouldAskAboutKeepEditingReview) return Popup.show(
      'Bạn có muốn bổ sung/hoàn thiện đánh giá trước khi đóng góp sách không?',
      [
        { text: 'Tiếp tục soạn', solid: true, onPress: () => { } },
        { text: 'Chỉ đóng\ngóp sách', solid: false, outline: true, onPress: () => doBook(false) },
      ],
    );
  };

  const imageWidth = rV({ xs: 80, lg: 160 });
  const imageHeight = rV({ xs: 128, lg: 256 });
  const imageTextWidth = rV({ xs: 83, lg: 80 });

  if (!token) {
    return (
      <Col flex1>
        <Header />
        <Col flex1 middle>
          <Text>Bạn cần đăng nhập để thực hiện chức năng này</Text>
        </Col>
      </Col>
    )
  }

  if (user.type === 'ADMIN') {
    return (
      <Col flex1>
        <Header />
        <Col flex1 middle>
          <Text>Bạn đang truy cập với quyền hạn Admin, hãy sử dụng trang quản trị để tạo sách mới</Text>
        </Col>
      </Col>
    )
  }

  return (
    <RightSidebarOneThird
      renderMainContent={() => {
        return (
          <Col>
            <Scroll>
              <Text h4 marginBottom18>ĐÓNG GÓP SÁCH</Text>
              <Col bgWhite padding={rV({ xs: 8, lg: 40 })} borderColor="#E4E4E4" borderWidth1>
                <Row alignItems="flex-start">
                  {useMemo(() => (
                    <Col
                      width={imageWidth} height={imageHeight} borderColor="#C9C9C9" borderWidth1 borderStyle="dashed"
                      borderRadius8 backgroundColor="#EFEFEF" overflow="hidden"
                      onPress={() => uploaderRef.current?.openFileDialog()}
                      onHoverStyle={{ backgroundColor: COLOR.GRAY_200 }}
                      useNestedHover useNativeStyleProps
                    >
                      {Boolean(!localFile.path) ? (
                        <Col flex1 middle>
                          <ICON.Plus />
                          <Text center width={imageTextWidth} marginTop16 subtitle1 color={COLOR.GRAY_500}>Thêm ảnh bìa sách</Text>
                        </Col>
                      ) : (
                        <Col flex1>
                          <Col absoluteFill>
                            <Img source={{ uri: localFile.path }} width100p height100p resizeMode="cover" />
                          </Col>
                          <Col absoluteFill middle opacity={0} backgroundColor="rgba(0,0,0,0.7)" onHoverStyle={{ opacity: 1 }} useNativeStyleProps>
                            <ICON.WhitePen />
                            <Text center width={imageTextWidth} marginTop16 subtitle1 colorWhite>Sửa ảnh bìa sách</Text>
                          </Col>
                        </Col>
                      )}
                      <Uploader
                        ref={uploaderRef}
                        onChangeLocal={(newLocal) => setLocalFile(newLocal)}
                      />
                    </Col>
                  ), [localFile, imageWidth, imageHeight, imageTextWidth])}
                  <Col flex1 marginLeft={rV({ xs: 8, lg: 40 })}>
                    <Input
                      label="Tên sách"
                      required value={name}
                      onChange={setName}
                      placeholder="Nhập tên sách"
                      inputProps={{
                        onBlur: searchForExistedBook,
                      }}
                    />
                    {Boolean(similarBooks.length > 0) && (
                      <Col marginTop12 paddingHorizontal12>
                        <Text marginBottom8 color={COLOR.DANGER_500}>Kết quả tìm kiếm sách có tên tương tự. Vui lòng lưu ý sách của bạn sẽ không được duyệt nếu bị trùng</Text>
                        <Row style={{ flexWrap: 'wrap', width: '100%' }}>
                          {similarBooks.map(val => (
                            <Row key={val.id} padding8 borderRadius8 backgroundColor="rgba(0,0,0,0.1)" marginRight8 marginBottom8 onPress={() => navigate(SCREEN_NAME.BookDetail, { id: val.id, name: VarHelper.toSlug(val.name) })}>
                              <Entypo name="book" size={12} color={COLOR.MAIN} />
                              <Text marginLeft4>{val.name}</Text>
                            </Row>
                          ))}
                        </Row>
                      </Col>
                    )}
                    <Input label="Tên tác giả" required value={authorName} onChange={setAuthorName} placeholder="Nhập tên tác giả" marginTop16 />
                    <Select
                      label="Thể loại"
                      required
                      value={genres}
                      options={listGenres.map(val => ({ label: val.name, value: val.id }))}
                      onChange={setGenres}
                      isMulti
                      placeholder="Tìm và chọn thể loại"
                      marginTop16
                    />
                  </Col>
                </Row>

                <Input
                  marginTop45
                  label="Mô tả thêm về cuốn sách"
                  placeholder="Mô tả ngắn về cuốn sách (vui lòng không viết đánh giá vào đây)"
                  multiline
                  value={description} onChange={setDescription}
                  inputStyle={{ padding: 12 }}
                />

                <Input label="Nhà xuất bản" value={publisher} onChange={setPublisher} placeholder="" marginTop24 />

              </Col>
              <Col bgWhite padding={rV({ xs: 8, lg: 24 })} borderColor="#E4E4E4" borderWidth1 borderTopWidth1>
                <Row 
                  onResponsiveStyle={{
                    xs: { flexWrap: 'wrap' },
                    md: { justifyContent: "space-between" },
                  }}
                >
                  <Row alignItems="flex-start" marginBottom8>
                    <Avatar size={48} uri={user.photoUrl} name={user.displayName} />
                    <Col marginLeft13>
                      <Text fontSize14>Đánh giá của bạn về cuốn sách</Text>
                      <RateStars
                        marginTop8
                        width151
                        value={rate}
                        onChange={setRate}
                      />
                    </Col>
                  </Row>
                  <Row onPress={() => setSpoiler(!spoiler)} marginBottom8>
                    <Checkbox value={spoiler} onChange={setSpoiler} />
                    <Text marginLeft8 body1>Spoiler alert</Text>
                  </Row>
                </Row>

                {/* <Input
                  marginTop24 multiline
                  label="" placeholder="Viết bình luận"
                  value={review} onChange={setReview}
                  inputStyle={{ padding: 12 }}
                /> */}
                <RichTextEditor2
                  marginTop24
                  ref={editorRef}
                  placeholder='Hãy viết đánh giá tại đây'
                />

                {/* <Text marginVertical16 color={review.length > 300 ? 'red' : COLOR.GRAY_500}>{review.length}/300</Text> */}
              </Col>

              <Row marginTop16 marginBottom={rV({ xs: 24, md: 177 })}>
                <Col flex1 />
                <Col alignItems="flex-end">
                  {Boolean(showLinkNotice) && <Text marginBottom8>Lưu ý: Toàn bộ đường dẫn ra bên ngoài hệ thống sẽ bị gỡ bỏ</Text>}
                  {Boolean(errorMessage) && <Text color={COLOR.DANGER_500} marginBottom8>{errorMessage}</Text>}
                  <Button small solid text={"Gửi đóng góp sách"} loading={loading} width219 onPress={() => {
                    debounce(() => {
                      submit();
                    })
                  }} />
                </Col>
              </Row>
            </Scroll>
          </Col>
        );
      }}

      renderSidebarContent={() => {
        return (
          <Col>
            <Text h6 marginTop44>Giúp Readism phát triển văn hóa đọc ở Việt Nam</Text>
            <Text body1 marginTop15>Là một mạng xã hội dành cho những người yêu thích sách, Readism luôn muốn cập nhật nhanh nhất những cuốn sách mới, các bài cảm nhận chân thật nhất từ các bạn độc giả. Vì vậy hãy cùng Readism xây dựng cộng đồng lớn mạnh hơn nữa bằng cách đóng góp thêm thông tin và các bài chia sẻ của những cuốn sách mà chúng mình chưa có tại đây nhé.</Text>
            <Text h6 marginTop32>Bạn có thể review cuốn sách</Text>
            <Text body1 marginTop15>Các bạn hãy điền đủ thông tin tại các ô bên cạnh đây nhé. Các tips khi đóng góp sách:</Text>
            <Text body1 marginTop15>- Những ô có dấu sao (*) là bắt buộc phải điền.</Text>
            <Text body1 marginTop15>- Hình ảnh bìa sách bạn hãy lên google search và tải về giúp chúng mình nha.</Text>
            <Text body1 marginTop15>- Bạn có thể trực tiếp đánh giá và viết cảm nhận về cuốn sách ở ngay bên dưới.</Text>
            <Text body1 marginTop15>- Cuối cùng cuốn sách của bạn đóng góp sẽ xuất hiện trên Readism và tất cả mọi người có thể cùng đánh giá cũng như nhận xét về cuốn sách này.</Text>
          </Col>
        );
      }}
    />
  );
};

export default CreateBook;