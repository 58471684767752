import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { COLOR, ERROR, SCREEN_NAME, STYLES } from 'const';
import { useNavFunc, A } from 'navigation';
import { IQuote, IBook } from 'type';
import { ICON } from 'assets';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import { useDropdownOptions, usePostCURDDropdown, usePreDataOrFetchApi } from '../../hooks';
import { Popup, showModal, RatioCol } from '../../elements';
import LikeComments from '../LikeComments/LikeComments';
import QuoteExportImage from '../QuoteExportImage';
import Store from 'store';
import { VarHelper } from 'helpers';

interface IQuoteNoCreatorItemProps {
  data: IQuote,
  children?: any,
  [anyProps: string]: any,
}

const QuoteNoCreatorItem = (props: IQuoteNoCreatorItemProps) => {
  const { navigation, goWithPreData } = useNavFunc();
  const propStyle = usePropsStyle(props);
  const { user } = Store.User.state;
  const [{ }, qApi] = Store.Quotes.createStore();

  const [didRemove, setDidRemove] = useState(false);

  const isMyPost = !!user.id && user.id === props.data.createdById;
  const isAdmin = !!user.type && user.type === 'ADMIN';

  const [book] = usePreDataOrFetchApi<IBook>(props.data?.tagBooks?.length > 0 ? props.data?.tagBooks[0].id : undefined, 'book');

  const onPressRemove = useCallback(() => {

    const doRemove = async () => {
      const [res, err] = await qApi.update({
        id: props.data.id,
        visibility: 'trash',
      });
      if (err) return Popup.showError(ERROR.parseError(err));
      if (res.error) return Popup.showError(ERROR.parseError(res.error));
      setDidRemove(true);
    }

    Popup.show('Bạn cho chắc chắn muốn xóa?', [
      { text: 'Có', solid: false, outline: true, onPress: doRemove },
      { text: 'Không', solid: true, outline: false, onPress: () => { } },
    ]);
  }, [props.data.id]);

  const showExportPopup = () => {
    showModal({
      component: (
        <QuoteExportImage data={props.data} />
      ),
      noWrapper: true,
    })
  };

  const { showDropdown } = usePostCURDDropdown({
    navigation,
    onPressEdit: () => goWithPreData(SCREEN_NAME.EditQuote, { id: props.data.id }, props.data),
    onPressRemove: onPressRemove,
    hasEditPermission: isMyPost || isAdmin,
    objectId: props.data.id,
    objectType: 'quote',
    additionOptions: [
      {
        icon: <ICON.Download height={24} width={12} fill={COLOR.MAIN} />,
        text: 'Tải về',
        onPress: showExportPopup,
      }
    ],
  });

  if (didRemove) return null;

  const goToBookDetailProps = {
    route: SCREEN_NAME.BookDetail,
    params: { id: book?.id, name: VarHelper.toSlug(book?.name) },
    preData: book,
  }

  const renderBook = () => {
    return (
      <Col marginBottom24 bgWhite borderRadius10>
        <Row height192 stretch padding24 marginHorizontal24 borderRadius4 borderWidth1 borderColor={'#ECF0EF'}>
          <A {...goToBookDetailProps}>
            <RatioCol ratio={100 / 160} width={100}>
              <Img source={{ uri: book?.images && book?.images?.length > 0 ? book?.images[0] : '' }} width100p height100p />
            </RatioCol>
          </A>
          <Col flex1 paddingLeft16>
            <A {...goToBookDetailProps}>
              <Col>
                <Text numberOfLines={2} subtitle1 marginBottom8>{book?.name}</Text>
                <Text numberOfLines={1} body2 marginBottom16>{book?.authors?.length > 0 ? book.authors[0].name : ''}</Text>
                <Text numberOfLines={2} body2 marginBottom16>{book?.content}</Text>
              </Col>
            </A>
          </Col>
        </Row>
      </Col>
    );
  }

  const renderSettingIcon = () => {
    return (
      <Col width24 height24 middle marginLeft24 onPress={showDropdown}>
        <ICON.ThreeDots />
      </Col>
    );
  }

  return (
    <Col style={propStyle} borderWidth1 borderColor={COLOR.BORDER} borderRadius4 bgWhite>
      <Col padding24>
        {Boolean(props.children) && (
          <Row alignItems="flex-start" marginBottom16>
            <Col flex1>
              {props.children}
            </Col>
            {renderSettingIcon()}
          </Row>
        )}
        <Row alignItems="flex-start">
          <Col flex1>
            <Text fontStyle="italic" body1>"{props.data.content}"</Text>
          </Col>
          {Boolean(!props.children) && renderSettingIcon()}
        </Row>

        <Row marginTop16>
          <AntDesign name="minus" size={24} color={COLOR.FONT} />
          <Text subtitle1 marginLeft4>{props.data.quoteBy.name}</Text>
        </Row>
      </Col>
      {Boolean(props.data?.tagBooks && props.data?.tagBooks?.length > 0) && renderBook()}
      <LikeComments entityData={props.data} postType='quote' />
    </Col>
  );
};

export default QuoteNoCreatorItem;