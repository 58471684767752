import { ERROR } from 'const';
import getVideoId from 'get-video-id';
const Route = require('route-parser');
const stringSimilarity = require("string-similarity");

class VarHelper {

  genId() {
    const id = Math.floor(Math.random() * 1000000) + '' + Math.floor(Math.random() * 1000000);
    return id;
  }

  erria = async (callback) => {
    try {
      const data = await callback();
      return [data, null];
    } catch(err) {
      return [null, err];
    }
  }

  parseRoute = (str : string, route: string | { path: string }) : { isValid: boolean, params?: any } => {
    // const r = new Route(route);
    const routeUri = typeof route === 'string' ? route : route.path;
    const parse = new Route(routeUri).match(str);
    if (!parse) return { isValid: false, params: {} };
    return { isValid: true, params: parse };
  }

  boDauTiengViet = (str) => {
    if (typeof str != 'string') {
      return null;
    }
    str = str.replace(/(á|à|ả|ã|ạ|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ)/g, 'a');
    str = str.replace(/(A|À|Ả|Ã|Ạ|Ă|Ắ|Ằ|Ẳ|Ẵ|Ặ|Â|Ấ|Ầ|Ẩ|Ẫ|Ậ)/g, 'A');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/Đ/g, 'D');
    str = str.replace(/(é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ)/g, 'e');
    str = str.replace(/(É|È|Ẻ|Ẽ|Ẹ|Ê|Ế|Ề|Ể|Ễ|Ệ)/g, 'E');
    str = str.replace(/(í|ì|ỉ|ĩ|ị)/g, 'i');
    str = str.replace(/(Í|Ì|Ỉ|Ĩ|Ị)/g, 'I');
    str = str.replace(/(ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ)/g, 'o');
    str = str.replace(/(Ó|Ò|Ỏ|Õ|Ọ|Ô|Ố|Ồ|Ổ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ở|Ỡ|Ợ)/g, 'O');
    str = str.replace(/(ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự)/g, 'u');
    str = str.replace(/(Ú|Ù|Ủ|Ũ|Ụ|Ư|Ứ|Ừ|Ử|Ữ|Ự)/g, 'U');
    str = str.replace(/(ý|ỳ|ỷ|ỹ|ỵ)/g, 'y');
    str = str.replace(/(Ý|Ỳ|Ỷ|Ỹ|Ỵ)/g, 'Y');

    return str.toLowerCase();
  }

  toSlug(str) {
    if (!str) return '_';
    return this.boDauTiengViet(str).replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, '-').toLowerCase();
  }

  handleError(res, err, setMessage = (m: string) => {}, callback = () => {}) : { shouldReturn: boolean } {
    if (err) {
      setMessage(ERROR.parseError(err));
      callback();
      return { shouldReturn: true };
    }
    if (!!res && res.error) {
      setMessage(ERROR.parseError(res.error));
      callback();
      return { shouldReturn: true };
    }
    setMessage('');
    return { shouldReturn: false };
  }

  rateSimilarity(str1, str2) {
    if (str1 === str2) return 1;
    if (!str1 || !str2) return 0;
    return stringSimilarity(str1, str2);
  }

  getYoutubeIdFromLink = (link : string) => {
    if (typeof link !== 'string') return link;
    const { id } = getVideoId(link);
    return id;
  }

  removeHttpFromWebsiteLink = (url) => {
    if (!url || typeof url !== 'string') return '';
    let cleaned = url.replace('http://', '').replace('https://', '');
    return cleaned.endsWith('/') ? cleaned.substring(0, cleaned.length - 1) : cleaned;
  }

  extractTextValueFromHtmlString = (s) => {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  genWritingParagraphId = (html) => {
    const textValue = this.extractTextValueFromHtmlString(html);
    return `${textValue.length}_${textValue.slice(0, 10)}_${textValue.slice(textValue.length - 10, textValue.length)}`;
  }

  filterEmptyObjProp = obj => {
    let newObj = {};
    for (let prop in obj) {
      let value = obj[prop];
      if(value !== "" && value !== null && value !== undefined) newObj[prop] = value;
    }
    return newObj;
  }
};

export default new VarHelper();