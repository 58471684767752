import React, { useState, useEffect, useRef } from 'react';
import { Text } from 'react-quick-style-components';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { COLOR, DEVICE_INFO } from 'const';
import { useEvent } from 'js-events-listener/react';
import { useNavFunc } from 'navigation';
import { TimeHelper } from 'helpers';

interface ILoadMoreDetect {
  hasMore?: boolean,
  isLoadingMore?: boolean,
  page?: number,
  fetcher?: any,
}

const isInViewport = (element : HTMLElement) => {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.height <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const LoadMoreDetect = (props : ILoadMoreDetect) => {
  const [id] = useState(('load-more-detect-'+Math.random()).replace('.', ''));
  const [isFocused, setIsFocused] = useState(true);
  const { navigation } = useNavFunc();

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setIsFocused(true);
    });
    return unsubscribe;
  }, []);
  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setIsFocused(false);
    });
    return unsubscribe;
  }, []);

  const handleScrollRef = useRef<any>(() => {
    TimeHelper.debounce(() => {
      // console.log('DEVICE_INFO.IS_WEB && isInViewport(document.querySelector(`#${id}`))', DEVICE_INFO.IS_WEB && isInViewport(document.querySelector(`#${id}`)));
      if (DEVICE_INFO.IS_WEB && isInViewport(document.querySelector(`#${id}`))) {
        typeof props.fetcher === 'function' && props.fetcher();
      }
    }, 300);
  });

  useEffect(() => {
    if (!isFocused || props.isLoadingMore || !props.hasMore) return () => {
      window.removeEventListener('scroll', handleScrollRef.current);
    };
    window.addEventListener('scroll', handleScrollRef.current);
    return () => {
      window.removeEventListener('scroll', handleScrollRef.current);
    }
  }, [isFocused, id, props.isLoadingMore, props.hasMore]);

  // console.log('LoadMoreDetect', { isLoadingMore: props.isLoadingMore, hasMore: props.hasMore });

  return (
    <View style={styles.container} nativeID={id}>
      {!!props.isLoadingMore && (
        <>
          <ActivityIndicator size='small' color={COLOR.MAIN} />
          <Text caption marginLeft12>đang tải thêm..</Text>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 24,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default LoadMoreDetect;
