import { DEVICE_INFO } from './deviceInfo';

export const ERROR = {
	co_error: 'Có lỗi xảy ra',
	co_fill_all_form: 'Vui lòng điền đầy đủ các ô dữ liệu',
	co_fill_all_required_form: 'Vui lòng điền đầy đủ các ô dữ liệu bắt buộc ',
	auth_password_not_match: 'Mật khẩu không khớp nhau',
	auth_need_to_agree_term: 'Bạn cần đồng ý điều khoản để tiếp tục',
	auth_email_accept_list: (list) => `Chỉ nhận đăng ký mới với các email từ ${list.join(', ')}`,
	book_need_image: 'Vui lòng thêm ảnh cho sách',
	review_too_short: 'Bài đánh giá cần ít nhất 300 kí tự',

	wr_no_empty_chapter: 'Bạn có một vài chương không có nội dung, vui lòng kiểm tra lại và chắc chắn các chương đều có nội dung.',

	rp_need_explain_for_other: 'Bạn cần điền chi tiết cho "Lý do khác"',

	// parse server error
	LOGIN_FAIL: 'Thông tin đăng nhập sai',
	NOT_AUTHEN: 'Bạn chưa đăng nhập',
	INVALID_PASSWORD: 'Không đúng mật khẩu',
	ACCOUNT_ALREADY_EXISTED: 'Tài khoản đã tồn tại',
	ACCOUNT_NOT_EXISTED: 'Tài khoản không tồn tại',
	INVALID_CODE: 'Mã không hợp lệ',
	ENTITY_NOT_EXISTED: 'Thông tin này không tồn tại',
	NOT_EXISTED: 'Tài khoản này không tồn tại',
	'Network Error': 'Kết nối tới Server bị lỗi',
	ACTION_LIMIT: 'Hành động của bạn bị giới hạn, vui lòng thử lại sau',
	parseError: (err) => '',
};

ERROR.parseError = (err) => {
	if (err && err.stack && err.message) return `Lỗi: ${err.message}`;
	if (err in ERROR) {
		if (err === 'NOT_AUTHEN') {
			setTimeout(() => {
				if (DEVICE_INFO.IS_WEB) {
					window.location.reload();
				}
			}, 1000);
		}
		return ERROR[err];
	}
	return String(err);
};
