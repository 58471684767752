import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={15}
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M5.352 1.836a.9.9 0 000 1.328l3.046 2.773H.938A.925.925 0 000 6.875v1.25c0 .547.39.938.938.938h7.46l-3.007 2.773c-.43.39-.43.976-.04 1.367l.86.86c.351.351.937.351 1.328 0l5.899-5.86a.944.944 0 000-1.367L7.538.976a.931.931 0 00-1.328 0l-.86.86zM20 11.25v-7.5C20 1.68 18.32 0 16.25 0h-3.281a.475.475 0 00-.469.469V2.03c0 .274.195.469.469.469h3.281c.664 0 1.25.586 1.25 1.25v7.5c0 .703-.586 1.25-1.25 1.25h-3.281a.475.475 0 00-.469.469v1.562c0 .274.195.469.469.469h3.281c2.07 0 3.75-1.68 3.75-3.75z"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
