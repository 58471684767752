import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Col, Text, Scroll, Img, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import { Header, Button, MainContainer, RatioCol, QuoteWithCreatorItem, Pagination, useRefState2, QuoteContestStats, BookRecommendationRightSidebar } from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';

const Quote = () => {
  const { navigate, navigation } = useNavFunc();
  const { h2 } = useFontSizeStyle();
  const [tab, getTab, setTab] = useRefState2<'all' | 'trending' | '999lathu'>('all');
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [numberOfQuoteInContest, setNumberOfQuoteInContest] = useState(0);
  const rV = useDynamicResponsiveValue();
  const viewport = rV({ xs: 'xs', md: 'md' });
  const [{ }, qApi] = Store.Quotes.createStore();

  const TABS = [
    { label: 'Mới nhất', key: 'all' },
    { label: 'Phổ biến', key: 'trending' },
    { label: '999 lá thư', key: '999lathu' },
  ];

  const pagingRef = useRef({ count: 0 })

  const getData = useCallback(async (page = 1) => {

    let options;
    switch (getTab()) {
      case 'trending':
        options = {
          sort: [
            { value: 'numberOfLikes', orderType: 'DESC' },
          ]
        };
        break;
      case '999lathu':
        options = {
          search: [
            { label: 'quoteBy', value: '#999lathuguichochinhminh' },
          ]
        };
        break;
      default:
    }

    const [res, err] = await qApi.getList({
      visibility: 'public', page, pageSize: 20,
      options,
    });
    if (!!res && res.data && res.data.rows) {
      pagingRef.current.count = res.data.count;
      setList(res.data.rows);
      setPage(page);
      if (getTab() === '999lathu') {
        setNumberOfQuoteInContest(res.data.count);
      }
    }
  }, [list]);

  const changePage = (p) => {
    getData(p);
    window.scrollTo({ top: 0, left: 0 });
  }

  const allPossiblePages = list.length !== 0 && pagingRef.current.count > 0 ? Math.ceil(pagingRef.current.count / (20 || list.length)) : 1;

  useEffect(() => {

    const unsubscribe = navigation.addListener('focus', () => {
      getData();
    });
    return unsubscribe;
  }, [tab, getData]);

  // console.log('list', list);

  const renderMainColumn = () => {
    return (
      <Col marginTop16>
        {list.map((val, i) => (
          <QuoteWithCreatorItem
            key={val.id}
            data={val}
            marginTop16
          />
        ))}
        <Pagination
          page={page}
          changePage={changePage}
          allPossiblePages={allPossiblePages}
        />
      </Col>
    );
  }

  const renderSubColumn = () => {
    return (
      <Col marginTop32>
        {/* <QuoteContestStats
          onPress={() => {
            navigate(SCREEN_NAME.CreateQuote, { author: '#999lathuguichochinhminh' });
          }}
          number={numberOfQuoteInContest}
        /> */}
        <BookRecommendationRightSidebar />
      </Col>
    );
  }

  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1>
        <Scroll flex1>
          <MainContainer>
            <RatioCol ratio={1140 / 249} width="100%" marginTop16>
              <Img source={IMAGE.QuotesBanner} style={STYLES.all100p} />
              <Row absoluteFill paddingHorizontal='5%'>
                <Col alignItems="flex-start">
                  <ICON.ReadismWithTextLogo white height={27} />
                  <Text style={h2} colorWhite>Trích dẫn</Text>
                </Col>
              </Row>
            </RatioCol>
            {/* TAB HEADER */}
            <Row bgWhite height68 marginTop12 stretch paddingHorizontal12 borderRadius4 borderWidth1 borderColor={COLOR.BORDER}>
              {TABS.slice(0, viewport === 'xs' ? 2 : 3).map((tabItem, i) => {
                const isActive = tabItem.key === tab;
                const borderStyle = isActive ? { borderBottomWidth: 5, borderBottomColor: COLOR.MAIN } : {};
                return (
                  <Col
                    key={tabItem.key} {...borderStyle} paddingHorizontal16 middle
                    onHoverStyle={{ opacity: 1 }} useNestedHover
                    onPress={() => {
                      if (!isActive) {
                        setTab(tabItem.key as any);
                        setList([]);
                        setTimeout(() => {
                          getData(1);
                        }, 500);
                      }
                    }}
                  >
                    <Text btnTextLarge onHoverStyle={{ color: COLOR.MAIN }}>{tabItem.label}</Text>
                  </Col>
                );
              })}
              <Col flex1 />
              <Col middle>
                <Row height44 bgMain borderRadius6 paddingHorizontal16 middle onPress={() => navigate(SCREEN_NAME.CreateQuote)}>
                  <ICON.PlusCircle fill='white' width={20} height={20} />
                  <Text btnTextMedium colorWhite marginLeft8>Tạo mới</Text>
                </Row>
              </Col>
            </Row>
            {/* CONTENT - SIDEBAR */}
            <Row xs='100%' md='2:any:1' alignItems='flex-start'>
              {viewport === 'xs' ? renderSubColumn() : renderMainColumn()}
              <Col width24 />
              {viewport === 'xs' ? renderMainColumn() : renderSubColumn()}
            </Row>
          </MainContainer>
        </Scroll>
      </Col>
    </Col>
  );
};

export default Quote;