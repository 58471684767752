import * as DeviceInfo from 'expo-device';
import { Platform, Dimensions } from 'react-native';
import { getBottomSpace, getStatusBarHeight } from 'react-native-iphone-x-helper';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
const { detect } = require('detect-browser');

export const DEVICE_INFO = {
  IS_IOS: Platform.OS === 'ios',
  IS_ANDROID: Platform.OS === 'android',
  IS_WEB: Platform.OS === 'web',
  MODEL: DeviceInfo.modelName,
  WIDTH: Dimensions.get('window').width,
  HEIGHT: Dimensions.get('window').height,
  BOTTOM_SPACE: getBottomSpace(),
  STATUS_BAR_HEIGHT: getStatusBarHeight(),
  BROWSER: '',
  BROWSER_ID: '',
};

if (DEVICE_INFO.IS_WEB) {
  const browser = detect();
  if (window.isFacebookApp()) {
    DEVICE_INFO.BROWSER = `${DeviceInfo.modelName} - Facebook inApp`;
  } else {
    DEVICE_INFO.BROWSER = !browser ? DeviceInfo.productName || DeviceInfo.modelName : `${browser.name} ${browser.version} ${browser.os}`;
  }
  window.deviceInfo = DEVICE_INFO;
  const fpPromise = FingerprintJS.load();
  requestAnimationFrame(async () => {
    const fp = await fpPromise
    const result = await fp.get()
    const visitorId = result.visitorId
    DEVICE_INFO.BROWSER_ID = visitorId;
  });
}

Dimensions.addEventListener('change', () => {
  DEVICE_INFO.HEIGHT = Dimensions.get('window').height;
  DEVICE_INFO.WIDTH = Dimensions.get('window').width;
});
