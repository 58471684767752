import React from 'react';
import { Row, Col, usePropsStyle } from 'react-quick-style-components';
import { ICON } from 'assets';
import { COLOR } from 'const';

interface IRateStarsProps {
  value: number,
  onChange(newValue : number): void,
  activeColor?: string,
  inactiveColor?: string,
  outline?: boolean,
  starSize?: {
    width: number,
    height: number,
  },
  [addition: string]: any,
}

const RateStars = (props : IRateStarsProps) => {
  const propStyle = usePropsStyle(props);
  const activeColor = props.activeColor || COLOR.MAIN;
  const inactiveColor = props.inactiveColor || COLOR.GRAY_200;
  const starSize = props.starSize || {
    width: 27, 
    height: 24,
  }
  const InactiveStarComponent = props.outline ? ICON.StarOutline : ICON.Star;
  const arr = [
    { value: 1, isActive: props.value >= 1 },
    { value: 2, isActive: props.value >= 2 },
    { value: 3, isActive: props.value >= 3 },
    { value: 4, isActive: props.value >= 4 },
    { value: 5, isActive: props.value >= 5 },
  ]
  return (
    <Row style={propStyle}>
      {arr.map((val, i) => {
        return (
          <Col key={'star-'+i} flex1 middle onPress={() => props.onChange(val.value)}>
            {Boolean(val.isActive) ? (
              <ICON.Star fill={activeColor} width={starSize.width} height={starSize.height} />
            ) : (
              <InactiveStarComponent fill={inactiveColor} width={starSize.width} height={starSize.height} />
            )}
          </Col>
        )
      })}
    </Row>
  );
};

export default RateStars;
