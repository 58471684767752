import React, { useEffect, useState, useRef } from 'react';
import { Col, Text, useDynamicResponsiveValue, Row } from 'react-quick-style-components';
import { Header, MainContainer, ControlledRichTextEditor, Input, Select } from 'components';
import { } from 'navigation';
import { BOOK_VISIBILITY, COLOR, CHAPTER_EDIT_VISIBILITY } from 'const';
import { ICON } from 'assets';
import { AntDesign } from '@expo/vector-icons';

export const EditableChapterTitle = (props) => {
  const { data: { name, order, visibility, password }, isActive, onPress } = props;
  const [mode, setMode] = useState('view');
  const [tempName, setTempName] = useState(name);
  const [tempPass, setTempPass] = useState(password);
  const [vis, setVis] = useState(visibility);
  useEffect(() => {
    if (name !== tempName) setTempName(name);
    if (vis !== visibility) setVis(visibility);
  }, [name, visibility]);
  const toggleMode = () => {
    if (mode == 'view') {
      setMode('edit');
      setTimeout(() => {
        if (textInputRef.current?.focus) textInputRef.current?.focus();
      }, 500);
      return
    }
    setMode('view');
    props.onChange && props.onChange({
      name: tempName,
      visibility: vis,
      password: tempPass,
    });
  }

  const textInputRef = useRef<{ focus: Function }>();

  return (
    <Row
      onPress={onPress} paddingHorizontal24 paddingVertical12
      onHoverStyle={mode === 'view' ? { backgroundColor: COLOR.BORDER } : { opacity: 1 }}
      alignItems={mode === 'view' ? 'center' : 'flex-start'}
    >
      {Boolean(mode === 'view') ? (
        <Col flex1>
          <Text body1 color={isActive ? COLOR.MAIN : COLOR.FONT}>{name}{visibility === 'draft' ? ' (nháp)' : ''}</Text>
        </Col>
      ) : (
        <Col flex1>
          <Input
            label={'Tên chương'}
            placeholder=""
            value={tempName} onChange={setTempName}
            onEnter={toggleMode}
            onRef={ref => textInputRef.current = ref}
          />
          <Select
            marginTop12
            label="Hiển thị"
            options={Object.keys(CHAPTER_EDIT_VISIBILITY).map(val => ({ value: val, label: CHAPTER_EDIT_VISIBILITY[val] }))}
            value={{ label: CHAPTER_EDIT_VISIBILITY[vis], value: vis }}
            onChange={(v) => setVis(v.value)}
          />
          <Input
            label={'Mật khẩu'}
            placeholder=""
            value={tempPass} onChange={setTempPass}
            onEnter={toggleMode}
          />
          <Row justifyContent="space-between" paddingTop24 borderTopColor={COLOR.BORDER} borderTopWidth={1} marginTop24>
            <Row onPress={toggleMode}>
              <ICON.Check width={20} height={20} fill={COLOR.MAIN} />
              <Text subtitle1 colorMain marginLeft5>Lưu</Text>
            </Row>
            <Row onPress={props.onPressDelete}>
              <ICON.Trash width={20} height={20} fill={COLOR.DANGER_500} />
              <Text subtitle1 color={COLOR.DANGER_500} marginLeft5>Xóa</Text>
            </Row>
          </Row>
        </Col>
      )}
      {mode === 'view' && (
        <Row
          height={44}
          middle
        >
          <Col padding4 onPress={props.onMoveUp}>
            <AntDesign name="caretup" size={12} color={COLOR.GRAY_500} />
          </Col>
          <Col padding4 onPress={props.onMoveDown}>
            <AntDesign name="caretdown" size={12} color={COLOR.GRAY_500} />
          </Col>
          <Col width18 height18 middle marginRight4 onPress={() => {
            props.onPress();
            toggleMode();
          }}>
            <ICON.SolidPen width={14} height={14} />
          </Col>
          <Col width18 height18 middle onPress={props.onPressDelete}>
            <ICON.Trash width={14} height={14} />
          </Col>
        </Row>
      )}
    </Row>
  );
}