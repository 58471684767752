import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import { Header, Button, MainContainer, RatioCol, GroupItem, useSubmitUIState, useDropdownOptions, InviteFriendsToGroupModal, useRefState2 } from '../index';
import { ICON, IMAGE } from 'assets';
import Store from 'store';
import { ActivityIndicator } from 'react-native';
import { IGroup, IGroupFeed } from 'type';
import { FontAwesome5, FontAwesome, Ionicons } from '@expo/vector-icons';
import { Popup, showModal } from 'components/elements';
import { useFetchUIState } from 'components/hooks';

interface IGroupDetailLayoutProps {
  children({ groupFeedData, params, setGroupFeedData, navigation, myUser, page, getData, fetchUI, onChangeFeedOrder } : { groupFeedData: IGroupFeed, params: any, setGroupFeedData: any, navigation: any, myUser: any, page: any, getData: any, fetchUI: any, onChangeFeedOrder: any }): any,
  initialTab?: 'feed' | 'sach' | 'thanh-vien',
}

const GroupDetailLayout = (props : IGroupDetailLayoutProps) => {
  const rV = useDynamicResponsiveValue();
  const { navigate, navigation, route } = useNavFunc();
  const { user } = Store.User.state;
  const [{ }, gApi] = Store.Group.createStore();

  // const [page, setPage] = useState(1);
  const page = useRef(1);
  const [groupFeedData, getGroupFeedData, setGroupFeedData] = useRefState2<IGroupFeed>();
  const [{ loading, errorMes }, setUIState] = useSubmitUIState({
    errorMes: '',
    loading: true,
    btnText: '',
  });
  const [{ isLoadingMore, hasMore }, setFetchUI] = useFetchUIState(); 

  const [{ loading: loadingJoin }, setJoinUIState] = useSubmitUIState();

  const { tab, id, name } : any = route.params;

  const TABS = [
    { label: 'Feed', key: 'feed' },
    // { label: 'Sách', key: 'sach' },
    { label: 'Thành viên', key: 'thanh-vien' },
  ];

  const optionsRef = useRef<any>();

  const getData = useCallback(async (p = 1) => {
    if (p !== 1) setFetchUI({ isLoadingMore: true });
    const [res, err] = await gApi.feed({ id, page: p, pageSize: 20, options: optionsRef.current });
    if (err) return setUIState({ loading: false, errorMes: ERROR.parseError(err) });
    if (res.error) return setUIState({ loading: false, errorMes: ERROR.parseError(res.error) });
    if (!!res && res.data && res.data.rows) {
      const rows = getGroupFeedData()?.rows?.slice() || [];
      const newRows = p === 1 ? res.data.rows : [...rows, ...res.data.rows];
      setGroupFeedData({
        ...res.data,
        rows: newRows,
      });
      if (p === 1) setUIState({ loading: false, errorMes: '' });
      setFetchUI({
        hasMore: !!res && res.data && res.data.hasNext,
        isLoadingMore: false
      });
      page.current = p;
    }
  }, [id, tab]);

  const onChangeFeedOrder = async ({ value }) => {
    if (!value) return;
    const parts = value.split('_');
    optionsRef.current = {
      sort: [
        { value: parts[0], orderType: parts[1] },
      ]
    }
    await getData(1);
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getData();
    });
    return unsubscribe;
  }, [tab, getData]);

  const setTab = (newTab) => {
    navigate(SCREEN_NAME.GroupDetail, { id, name, tab: newTab });
  };

  const findMyMember = !user.id ? false : groupFeedData?.detail?.members?.find(val => val.id === user.id);
  const isAdmin = !!findMyMember && findMyMember.isAdmin;

  const leaveGroup = () => {
    const doLeave = async () => {
      const [res, err] = await gApi.leaveGroup(id);
      if (err) return Popup.showError(ERROR.parseError(err));
      if (res.error) return Popup.showError(ERROR.parseError(res.error));
      if (res && res.data) {
        navigate(SCREEN_NAME.Group);
      }
    }

    if (isAdmin) return Popup.showError('Bạn không thể rời khỏi nhóm vì bạn là admin của nhóm, vui lòng liên hệ support từ Readism');

    Popup.show('Bạn có chắc chắn muốn rời nhóm?', [
      { text: 'Có', solid: false, outline: true, onPress: doLeave },
      { text: 'Không', solid: true, outline: false, onPress: () => {} },
    ]);
    
  }

  const inviteFriend = () => {
    showModal({
      component: <InviteFriendsToGroupModal groupId={groupFeedData.detail.id} members={groupFeedData.detail.members} />
    })
  };

  const { showDropdown } = useDropdownOptions({
    navigation, width: 150,
    useEventPosition: true,
    positions: { top: 60, right: 24 + 36 },
    dropdownOptions: [
      !isAdmin ? undefined : {
        icon: <ICON.SolidPen height={12} width={12} fill={COLOR.MAIN} />,
        text: 'Chỉnh sửa',
        onPress: () => navigate(SCREEN_NAME.UpdateGroup, { id: id, name: name }),
      },
      isAdmin ? undefined : {
        icon: <ICON.OutGroup height={12} width={12} fill={COLOR.MAIN} style={{ transform: [{ rotate: '180deg' }] }} />,
        text: 'Rời nhóm',
        onPress: leaveGroup,
      },
      findMyMember && rV({ xs: 'xs', md: 'md' }) === 'xs' ? {
        icon: <ICON.AddUser height={12} width={12} fill={COLOR.MAIN} />,
        text: 'Mời bạn bè',
        onPress: inviteFriend,
      } : undefined,
    ].filter(val => !!val),
  });

  const joinGroup = async () => {
    setJoinUIState({ loading: true });
    const [res, err] = await gApi.joinGroup(id);
    setJoinUIState({ loading: true });
    if (err) return Popup.showError(ERROR.parseError(err));
    if (res.error) return Popup.showError(ERROR.parseError(res.error));
    if (res && res.data) {
      setGroupFeedData({
        ...groupFeedData,
        detail: {
          ...groupFeedData.detail,
          members: res.data.members,
        }
      });
      Popup.show('Chào mừng bạn đến với nhóm');
    }
  };

  if (loading) {
    return (
      <Col flex1 backgroundColor="#F3F6F5">
        <Header />
        <Col flex1 middle>
          <ActivityIndicator size="large" color={COLOR.MAIN} />
        </Col>
      </Col>
    );
  }
  if (errorMes) {
    return (
      <Col flex1 backgroundColor="#F3F6F5">
        <Header />
        <Col flex1 middle>
          <Text color={COLOR.DANGER_500}>{errorMes}</Text>
        </Col>
      </Col>
    );
  }

  const avatarSize = rV({ xs: 50, md: 100 });

  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1>
        <Scroll flex1>
          <MainContainer>
            <RatioCol ratio={1140 / 249} width="100%" marginTop16 shadow>
              <Img source={groupFeedData?.detail?.image ? { uri: groupFeedData?.detail?.cover } : IMAGE.GroupBanner} style={[STYLES.all100p, { borderRadius: 10 }]} />
              {/* <Row absolute top0 bottom0 left={rV({ xs: 10, lg: 54 })}>
                <Col width={avatarSize} height={avatarSize}>
                  <Img source={{ uri: groupFeedData?.detail?.image || '' }} style={[STYLES.all100p, { borderRadius: 10, borderWidth: rV({ xs: 1, md: 3 }), borderColor: 'white' }]} />
                </Col>
                <Col marginLeft={rV({ xs : 12, md: 32 })} height={avatarSize} justifyContent="flex-end">
                  <Text
                    colorWhite bold fontSize={rV({ xs : 18, md: 28})}
                    textShadowColor='rgba(0, 0, 0, 1)'
                    textShadowOffset={{width: -1, height: 1}}
                    textShadowRadius={10}
                    numberOfLines={1}
                  >
                    {groupFeedData?.detail?.name}
                  </Text>
                </Col>
              </Row> */}
            </RatioCol>

            <Text marginTop24 h4>{groupFeedData?.detail?.name}</Text>

            <Row height68 marginTop24 stretch paddingHorizontal12 borderRadius4 borderWidth1 borderColor={COLOR.GRAY_500}>
              {TABS.map((tabItem, i) => {
                const isActive = tabItem.key === tab;
                const borderStyle = isActive ? { borderBottomWidth: 5, borderBottomColor: COLOR.MAIN } : {};
                return (
                  <Col
                    key={tabItem.key} {...borderStyle} paddingHorizontal16 middle
                    onHoverStyle={{ opacity: 1 }} useNestedHover
                    onPress={() => {
                      if (!isActive) setTab(tabItem.key as any);
                    }}
                  >
                    <Text btnTextLarge onHoverStyle={{ color: COLOR.MAIN }}>{tabItem.label}</Text>
                  </Col>
                );
              })}

              <Col flex1 />

              <Col middle>
                {Boolean(findMyMember) ? (
                  <Row height44 stretch>
                    <Row onPress={inviteFriend} bgMain width186 borderRadius6 middle display={rV({ xs: 'none', md: 'flex' })}>
                      <ICON.AddUser />
                      <Text marginLeft8 colorWhite btnTextMedium>Mời bạn bè</Text>
                    </Row>

                    {/* <Row marginLeft16 backgroundColor={COLOR.GRAY_100} width73 borderRadius6 middle>
                      <ICON.Search fill={COLOR.GRAY_500} width={24} height={24} />
                    </Row> */}

                    <Row onPress={showDropdown} marginLeft16 backgroundColor={COLOR.GRAY_100} width73 borderRadius6 middle>
                      <ICON.ThreeDots width={24} height={24} />
                    </Row>
                  </Row>
                ) : (
                  <Row height44 stretch>
                    <Row bgMain paddingHorizontal12 borderRadius6 middle onPress={joinGroup}>
                      {Boolean(loadingJoin) ? (
                        <ActivityIndicator size="small" color="white" />
                      ) : (
                        <ICON.OutGroup />
                      )}
                      <Text marginLeft8 colorWhite btnTextMedium>Tham gia</Text>
                    </Row>
                  </Row>
                )}
              </Col>
            </Row>
            
            <Row marginTop24 xs='100%' md='2:any:1' alignItems="flex-start">
              <Col marginBottom24>
                {props.children({
                  groupFeedData, setGroupFeedData,
                  params: route.params, navigation, myUser: findMyMember,
                  page,
                  getData,
                  fetchUI: {
                    isLoadingMore,
                    hasMore,
                  },
                  onChangeFeedOrder,
                })}
              </Col>
              <Col width24 />
              <Col marginBottom24>

                <Text btnTextLarge>Giới thiệu nhóm</Text>

                <Row marginTop24 alignItems="flex-start" borderRadius4 padding16 bgWhite shadow>
                  <Col flex1>
                    <Text>{groupFeedData?.detail?.description}</Text>
                  </Col>
                  <Col width={avatarSize} height={avatarSize} marginLeft8>
                    <Img source={{ uri: groupFeedData?.detail?.image || '' }} style={[STYLES.all100p, { borderRadius: 10, borderWidth: 1.5, borderColor: COLOR.FONT }]} />
                  </Col>
                </Row>
                
                <Col marginTop2 bgWhite padding16 borderRadius4 shadow>
                  <Row>
                    <Col width16 height16 middle>
                      <FontAwesome name="user" size={15} color={COLOR.GRAY_300} />
                    </Col>
                    <Text marginLeft12>{groupFeedData?.detail?.members?.length} thành viên</Text>
                  </Row>

                  <Row marginTop16>
                    <Col width16 height16 middle>
                      <Ionicons name="md-document-text-sharp" size={15} color={COLOR.GRAY_300} />
                    </Col>
                    <Text marginLeft12>{groupFeedData?.detail?.numberOfPosts || 0} bài viết</Text>
                  </Row>
                </Col>
              </Col>
            </Row>
          
            
          </MainContainer>
        </Scroll>
      </Col>
    </Col>
  );
};

export default GroupDetailLayout;