import React, { useState, useEffect } from 'react';
import { Row, Col, Text, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { DEVICE_INFO, useFontSizeStyle } from 'const';
import { useNavigation } from '@react-navigation/native';
import { SCREEN_NAME, COLOR, ERROR } from 'const';
import { ICON } from 'assets';
import { Button, Checkbox, Input } from 'components';
import { StyleSheet } from 'react-native';
import { Reset } from 'navigation';
import Store from 'store';
import { VarHelper } from 'helpers';
import { useGoogleFacebookAuth } from './useGoogleFacebookAuth';

const acceptedEmailDomains = [
  '@gmail.com',
  '@gmail.com.vn',
  '@yahoo.com',
  '@yahoo.com.vn',
];

const Register = () => {
  const rV = useDynamicResponsiveValue();
  const navigation = useNavigation();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [registerErrorMessage, setRegisterErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [agreedTerm, setAgreedTerm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alreadyLoggedIn, setAlreadyLoggedIn] = useState(false);
  const [{ user }, userApi] = Store.User.createUserStore();

  const reset = (screenName) => {
    navigation.reset({ index: 0, routes: [{ name: screenName }] })
  };

  const { getFacebookLoginData, getGoogleLoginData } = useGoogleFacebookAuth();

  const loginFacebook = async () => {
    setLoading(true);
    const onEnd = () => setLoading(false);
    const [data, err] = await getFacebookLoginData();
    if (VarHelper.handleError(data, err, setRegisterErrorMessage, onEnd).shouldReturn) return;
    const { accessToken } = data?.credential;
    console.log('facebook accessToken', accessToken);
    const [res, err2] = await userApi.loginFacebook(accessToken);
    if (VarHelper.handleError(res, err2, setRegisterErrorMessage, onEnd).shouldReturn) return;
    console.log('res', res);
    setLoading(false);
  };

  const loginGoogle = async () => {
    setLoading(true);
    const onEnd = () => setLoading(false);
    const [data, err] = await getGoogleLoginData();
    if (VarHelper.handleError(data, err, setRegisterErrorMessage, onEnd).shouldReturn) return;
    const { idToken } = data?.credential;
    const { photoURL, displayName } = data?.user;
    const { family_name: lastName, given_name: firstName } = data?.additionalUserInfo?.profile;
    const [res, err2] = await userApi.loginGoogle({ idToken, photoURL, displayName, lastName, firstName });
    if (VarHelper.handleError(res, err2, setRegisterErrorMessage, onEnd).shouldReturn) return;
    setLoading(false);
  };

  const register = async () => {
    if (!agreedTerm) {
      setRegisterErrorMessage(ERROR.auth_need_to_agree_term);
      return;
    }
    if (!email || !password || !passwordConfirm || !firstName || !lastName) {
      setRegisterErrorMessage(ERROR.co_fill_all_form);
      return;
    }
    let emailInAcceptList = false;
    acceptedEmailDomains.forEach(val => {
      if (email.includes(val)) emailInAcceptList = true;
    });
    if (!emailInAcceptList) {
      setRegisterErrorMessage(ERROR.auth_email_accept_list(acceptedEmailDomains));
      return;
    }
    if (passwordConfirm !== password) {
      setRegisterErrorMessage('');
      setPasswordErrorMessage(ERROR.auth_password_not_match);
      return;
    }
    setLoading(true);
    const [res, err] = await userApi.register({ email, password, firstName, lastName });
    setLoading(false);
    if (err) return setPasswordErrorMessage(String(err));
    if (res.error) return setPasswordErrorMessage(res.error);
    navigation.navigate(SCREEN_NAME.AccountVerifyNotice);
  };

  useEffect(() => {
    Store.User.onReady().then(() => {
      if (!!Store.User.state.token) {
        console.log('setAlreadyLoggedIn');
        setAlreadyLoggedIn(true);
      }
    })
  }, []);

  if (alreadyLoggedIn) {
    return (
      <Col middle flex1 bgWhite>
        <Text>Bạn đã đăng nhập..</Text>
        <Reset to={SCREEN_NAME.Home} timeout={500} />
      </Col>
    );
  }

  return (
    <Col middle flex1 bgWhite>
      <Scroll
        paddingVertical48
      >
        <Col width={rV({ xs: 320, lg: 364 })}>
          <ICON.ReadismWithTextLogo fill={COLOR.MAIN} style={{ alignSelf: 'center', width: '80%' }} />
          <Text marginTop40 h4>Đăng ký</Text>
          <Row height44 marginTop24>
            <Row flex1 height100p backgroundColor="#425894" borderRadius6 middle onPress={loginFacebook}>
              <ICON.Facebook />
              <Text colorWhite btnTextMedium marginLeft8>Facebook</Text>
            </Row>
            <Row flex1 height100p backgroundColor="#D85140" borderRadius6 marginLeft16 middle onPress={loginGoogle}>
              <ICON.Google />
              <Text colorWhite btnTextMedium marginLeft8>Google</Text>
            </Row>
          </Row>
          <Col line marginTop16 />
          <Col marginTop22>
            <Row xs="100%" lg="1:any:1">
              <Input
                label="Họ"
                value={lastName}
                onChange={setLastName}
                placeholder="Nhập họ"
                marginTop24
              />
              <Col width24 height0 />
              <Input
                label="Tên"
                value={firstName}
                onChange={setFirstName}
                placeholder="Nhập tên"
                marginTop24
              />
            </Row>
            <Input
              label="Email"
              value={email}
              onChange={setEmail}
              placeholder="Nhập email"
              error={registerErrorMessage}
              marginTop24
            />
            <Input.Password
              marginTop24
              label="Mật khẩu"
              value={password}
              password
              onChange={setPassword}
              placeholder="Nhập mật khẩu"
              error={passwordErrorMessage}
            />
            <Input.Password
              marginTop24
              label="Nhập lại mật khẩu"
              value={passwordConfirm}
              password
              onChange={setPasswordConfirm}
              placeholder=""
            />
          </Col>
          <Row marginTop16 alignItems="flex-start">
            <Checkbox value={agreedTerm} onChange={setAgreedTerm} />
            <Col flex1 marginLeft8>
              <Text body2>Tôi đồng ý với <Text semiBold colorMain onPress={() => navigation.navigate(SCREEN_NAME.Terms)}>Chính sách và Điều khoản</Text> của Readism</Text>
            </Col>
          </Row>
          <Button marginTop24 text="Đăng ký" solid large loading={loading} onPress={register} />
          <Col marginTop24 middle onPress={() => reset(SCREEN_NAME.Login)}>
            <Text>Bạn có tài khoản? <Text colorMain semiBold>Đăng nhập</Text></Text>
          </Col>
        </Col>
      </Scroll>
      <Col style={styles.cornerCloseBtn} onPress={() => reset(SCREEN_NAME.Home)}>
        <ICON.Close />
      </Col>
    </Col>
  );
};

const styles = StyleSheet.create({
  cornerCloseBtn: {
    position: 'absolute',
    top: DEVICE_INFO.IS_IOS ? 48 : 12,
    right: 12,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Register;