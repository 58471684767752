import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { CMSLayout, Button, Input, Uploader, Select, useRefState, CMSUploaderUI } from 'components';
import { Linking } from 'react-native';
import { useNavFunc } from 'navigation';
import { COLOR, DEVICE_INFO, ERROR, SCREEN_NAME, USER_TYPE } from 'const';
import { ICON } from 'assets';
import Store from 'store';
import { VarHelper } from 'helpers';
import { IUser } from 'type';

const CMSCreateUser = () => {
  const { goBackOrTo, navigate } = useNavFunc();
  const [btnText, setBtnText] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [type, setType] = useState('USER');
  const rV = useDynamicResponsiveValue();
  const [{ }, uApi] = Store.User.createUserStore();
  const avatarRef = useRef<{ getUploadedUrl: Function }>();

  const submit = async () => {
    if (!firstName || !lastName || !email || !password) return setErrorMessage(ERROR.co_fill_all_required_form);
    setLoading(true);
    const [photoUrl, err2] = await avatarRef.current?.getUploadedUrl();
    const [res, err] = await uApi.create({ 
      displayName: `${lastName} ${firstName}`,
      firstName,
      lastName,
      email,
      password,
      photoUrl,
      phoneNumber: phone,
      type,
    } as IUser);
    setLoading(false);
    if (err) return setErrorMessage(ERROR.parseError(err));
    if (res.error) return setErrorMessage(ERROR.parseError(res.error));
    setBtnText('Tạo người dùng thành công..');
    setTimeout(() => {
      setBtnText('');
      setTimeout(() => {
        goBackOrTo(SCREEN_NAME.CMSListUser);
      }, 500)
    }, 500);
  };

  return (
    <Col flex1>
      <CMSLayout title="Tạo người dùng">
        <Row>
          <Col flex1>
            <Text h4>TẠO NGƯỜI DÙNG</Text>
          </Col>
          <Row>
            <Button paddingHorizontal12 small solid text="Quay lại" onPress={() => goBackOrTo(SCREEN_NAME.CMSListBook)} />
          </Row>
        </Row>

        <Row marginTop24 alignItems="flex-start">
          <Col width160>
            <CMSUploaderUI aspectRatio={1} ref={avatarRef} />
          </Col>
          <Col flex1 marginLeft={rV({ xs: 8, lg: 40 })}>
            <Input
              label="Họ"
              required
              value={lastName}
              onChange={setLastName}
              placeholder="Nhập họ"
            />
            <Input
              label="Tên"
              required
              value={firstName}
              onChange={setFirstName}
              placeholder="Nhập tên"
              marginTop16
            />
          </Col>
        </Row>

        <Input
          label="Email" required
          value={email}
          onChange={setEmail}
          placeholder="Nhập email"
          marginTop24
        />

        <Input
          marginTop24
          label="Số điện thoại"
          placeholder=""
          value={phone}
          onChange={setPhone}
        />

        <Input.Password
          marginTop24
          label="Mật khẩu"
          placeholder=""
          value={password}
          onChange={setPassword}
        />

        <Row marginTop24 marginBottom200 justifyContent="space-between" alignItems="flex-start">
          <Col width220>
            <Select
              label="Loại User"
              options={Object.keys(USER_TYPE).map(val => ({ value: val, label: USER_TYPE[val] }))}
              value={{ label: USER_TYPE[type], value: type }}
              onChange={(v) => setType(v.value)}
            />
          </Col>
          <Col height100p justifyContent="flex-end">
            {Boolean(errorMessage) && <Text color={COLOR.DANGER_500} marginTop8>{errorMessage}</Text>}
            <Button small solid text={btnText ? btnText : "Xác nhận"} loading={loading} paddingHorizontal12 onPress={submit} />
          </Col>
        </Row>
      </CMSLayout>
    </Col>
  );
};

export default CMSCreateUser;