import { useNavigation, useRoute } from '@react-navigation/native';
import { DEVICE_INFO, SCREEN_NAME } from 'const';
import { VarHelper } from 'helpers';

export const reset = (navigation, screenName, params) => {
  navigation.reset({ index: 0, routes: [{ name: screenName, params }] })
};

export const goBackOrTo = (navigation, screenName, data) => {
  const { index } = navigation.dangerouslyGetState();
  index === 0 ? navigation.navigate(screenName, data) : navigation.goBack();
};

export const openNewTabOrNavigate = (navigation, url, screenName, data) => {
  if (DEVICE_INFO.IS_WEB) {
    window.open(url, '_blank');
  } else {
    navigation.navigate(screenName, data);
  }
};

let _idDatas = {};
export const goWithPreData = (navigation, screenName, params, preData) => {
  if (preData.id) _idDatas[preData.id] = preData;
  navigation.navigate(screenName, params);
};
export const getScreenPreData = (id) => !id ? undefined : _idDatas[id];
export const setScreenPreData = (preData) => { if (!!preData && !!preData.id) _idDatas[preData.id] = preData; };

export const useNavFunc = (n =  undefined, r = undefined) => {
  const navigation = !!n ? n : useNavigation();
  const route = !!r ? r : useRoute<any>();
  return {
    navigation,
    route,
    navigate: navigation.navigate,
    // navigate: (screenName, params = {}) => reset(navigation, screenName, params),
    goWithPreData: (screenName, params, preData) => goWithPreData(navigation, screenName, params, preData),
    getScreenPreData,
    setScreenPreData,
    reset: (screenName, params = {}) => reset(navigation, screenName, params),
    goBackOrTo: (screenName, data = undefined) => goBackOrTo(navigation, screenName, data),
    openNewTabOrNavigate: (url, screenName, data = {}) => openNewTabOrNavigate(navigation, url, screenName, data),
    goToUserProfile: (id, name) => navigation.navigate(SCREEN_NAME.UserProfile, { id, name: VarHelper.toSlug(name) }),
  };
}