import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { CMSLayout, Input, Button } from 'components';
import { COLOR, ERROR } from 'const';
import { ActivityIndicator } from 'react-native';
import { ICON } from 'assets';

import Store from 'store';
import { VarHelper } from 'helpers';
import BookGenre from './CMS.ListGenre/BookGenre';
import WritingGenre from './CMS.ListGenre/WritingGenre';
import MangaGenre from './CMS.ListGenre/MangaGenre';
import SingleGenreType from './CMS.ListGenre/SingleGenreType';

const TAB = {
  BOOK: 'book',
  WRITING: 'writing',
  MANGA: 'manga',
}

const CMSListGenre = () => {
  const [tab, setTab] = useState<string>(TAB.BOOK);
  const tabItemStyle = {
    active: { wrapper: { borderWidth: 2, borderColor: COLOR.MAIN }, text: { color: COLOR.MAIN, bold: true } },
    inactive: { wrapper: { borderWidth: 1, borderColor: COLOR.BORDER }, text: {} },
  }
  return (
    <Col flex1>
      <CMSLayout title="Quản lý Thể loại">
        <Row borderBottomWidth1 borderBottomColor={COLOR.BORDER} paddingBottom20 marginBottom={20}>
          <Row flex1 flexWrap='wrap'>
            <Col
              onPress={() => setTab(TAB.BOOK)}
              padding10 borderRadius10 middle
              {...(tab === TAB.BOOK ? tabItemStyle.active.wrapper : tabItemStyle.inactive.wrapper)}
            >
              <Text {...(tab === TAB.BOOK ? tabItemStyle.active.text : tabItemStyle.inactive.text)}>Sách</Text>
            </Col>
            <Col
              onPress={() => setTab(TAB.WRITING)}
              marginLeft8 padding10 borderRadius10 middle
              {...(tab === TAB.WRITING ? tabItemStyle.active.wrapper : tabItemStyle.inactive.wrapper)}
            >
              <Text {...(tab === TAB.WRITING ? tabItemStyle.active.text : tabItemStyle.inactive.text)}>Truyện chữ</Text>
            </Col>
            <Col
              onPress={() => setTab(TAB.MANGA)}
              marginLeft8 padding10 borderRadius10 middle
              {...(tab === TAB.MANGA ? tabItemStyle.active.wrapper : tabItemStyle.inactive.wrapper)}
            >
              <Text {...(tab === TAB.MANGA ? tabItemStyle.active.text : tabItemStyle.inactive.text)}>Truyện tranh</Text>
            </Col>
          </Row>

        </Row>
        <SingleGenreType type={tab.toUpperCase() as any} />
        
      </CMSLayout>
    </Col>
  );
};

export default CMSListGenre;