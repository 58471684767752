import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm6.181-29C27.68 11 29 12.321 29 13.819V25.18C29 26.68 27.679 28 26.181 28h-2.995v-6.43h2.82V18.75h-2.82v-1.145c0-.528.265-.793.529-.793h2.29v-2.818h-2.29c-1.762 0-3.171 1.673-3.171 3.611v1.145H18.43v2.819h2.114V28h-5.725C13.32 28 12 26.679 12 25.181V13.82C12 12.32 13.321 11 14.819 11H26.18z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
