import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={10}
      height={6}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M1.533.5c-.527 0-.79.645-.41 1.025l3.75 3.75a.601.601 0 00.85 0l3.75-3.75C9.853 1.145 9.59.5 9.063.5h-7.53z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
