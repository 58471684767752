import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect, useMemo } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { IUser, ISearchAndFilterOptions, IActivity, IFriend } from 'type';
import erria from 'erria/decorator';
import { VarHelper, EventHelper } from 'helpers';
interface ISetters {
  setUser?(v : any): void,
  setToken?(v : any): void,
  setLoginBehaviour?(v : any): void,
  setListFeeds?(v : any): void,
  setListAdminFeeds?(v : any): void,
  setScore?(v : any): void,
  setFriends?(v : any): void,
  setUnreadNoti?(v : any): void,
  [additionSetter: string]: (v : any) => void,
}

interface IUserState {
  user: IUser,
  token: string,
  score: number,
  loginBehaviour: {
    shouldRemember: boolean,
    lastTimeActivity: string,
  },
  listFeeds: Array<IActivity>,
  listAdminFeeds: Array<IActivity>,
  friends: Array<IFriend>,
  unreadNoti: number,
  didDismissWelcome: boolean,
};

class User extends PersistReady {

  constructor() {
    super();
    this.getInitialData();
  }

  state : IUserState = {
    user: {} as IUser,
    token: '',
    score: 0,
    loginBehaviour: {
      shouldRemember: true,
      lastTimeActivity: '0',
    },
    listFeeds: [],
    listAdminFeeds: [],
    friends: [],
    unreadNoti: 0,
    didDismissWelcome: false,
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  getInitialData = async () => {
    console.time('getInitialData');
    let [user, token, loginBehaviour, score, didDismissWelcome] = await Promise.all([
      Persist.sync('userStore_user', 'object'),
      Persist.sync('userStore_token', 'string'),
      Persist.sync('userStore_loginBehaviour', 'object'),
      Persist.sync('userStore_score', 'number'),
      Persist.sync('userStore_didDismissWelcome', 'boolean'),
      // Persist.sync('userStore_listFeeds', 'object'),
    ]);
    console.timeEnd('getInitialData');
    if (!loginBehaviour) {
      loginBehaviour = { shouldRemember: true, lastTimeActivity: '0' };
    }
    this.updateState({ user, token, loginBehaviour, score, didDismissWelcome }, false);
    if (!token) {
      this.makeReady();
      return;
    }
    if (!loginBehaviour.shouldRemember) {
      const deltaTime = new Date().getTime() - Number(loginBehaviour.lastTimeActivity);
      console.log('deltaTime', deltaTime);
      if (deltaTime > 1000 * 60 * 5) { // 5 mins
        this.logout();
        this.makeReady();
        return;
      } else {
        Request._beforeEachCall = () => {
          const newLoginBehaviour = {
            shouldRemember: false,
            lastTimeActivity: String(new Date().getTime()),
          };
          this.updateState({ loginBehaviour: newLoginBehaviour });
          Persist.save(newLoginBehaviour, 'userStore_loginBehaviour');
        };
      }
    }
    Request.setToken(token);
    await this.getMyUserInfo();
    this.makeReady(); // await onReady();
    this.getMyExtraInfo();
  }

  createUserStore() {
    const [user, setUser] = useGlobalState<IUser>(this.state.user, 'userStore_user');
    const [token, setToken] = useGlobalState<string>(this.state.token, 'userStore_token');
    const [score, setScore] = useGlobalState<number>(this.state.score, 'userStore_score');
    const [loginBehaviour, setLoginBehaviour] = useGlobalState<{ shouldRemember: boolean, lastTimeActivity: string }>(this.state.loginBehaviour, 'userStore_loginBehaviour');
    const [listFeeds, setListFeeds] = useGlobalState<Array<IActivity>>(this.state.listFeeds, 'userStore_listFeeds');
    const [listAdminFeeds, setListAdminFeeds] = useGlobalState<Array<IActivity>>(this.state.listFeeds, 'userStore_listAdminFeeds');
    const [unreadNoti, setUnreadNoti] = useGlobalState<number>(this.state.unreadNoti, 'userStore_unreadNoti');
    const [friends, setFriends] = useGlobalState<Array<IFriend>>(this.state.friends, 'userStore_friends');
    const [didDismissWelcome, setDidDismissWelcome] = useGlobalState<Boolean>(this.state.didDismissWelcome, 'userStore_didDismissWelcome');

    if (!this.setters.setUser) this.setters.setUser = setUser;
    if (!this.setters.setToken) this.setters.setToken = setToken;
    if (!this.setters.setLoginBehaviour) this.setters.setLoginBehaviour = setLoginBehaviour;
    if (!this.setters.setListFeeds) this.setters.setListFeeds = setListFeeds;
    if (!this.setters.setListAdminFeeds) this.setters.setListAdminFeeds = setListAdminFeeds;
    if (!this.setters.setScore) this.setters.setScore = setScore;
    if (!this.setters.setUnreadNoti) this.setters.setUnreadNoti = setUnreadNoti;
    if (!this.setters.setFriends) this.setters.setFriends = setFriends;
    if (!this.setters.setDidDismissWelcome) this.setters.setDidDismissWelcome = setDidDismissWelcome;

    useEffect(() => {
      this.updateState({ user, token, loginBehaviour, listAdminFeeds, score, unreadNoti, friends, didDismissWelcome });
      Persist.save(user, 'userStore_user');
      Persist.save(token, 'userStore_token');
      Persist.save(score, 'userStore_score');
      Persist.save(loginBehaviour, 'userStore_loginBehaviour');
      Persist.save(didDismissWelcome, 'userStore_didDismissWelcome');
    }, [user, token, loginBehaviour, listAdminFeeds, score, unreadNoti, friends, didDismissWelcome]);

    useEffect(() => {
      this.updateState({ listFeeds });
      Persist.save(listFeeds, 'userStore_listFeeds');
    }, [listFeeds]);

    return [
      { user, token, loginBehaviour, listFeeds, listAdminFeeds, score, unreadNoti, friends, didDismissWelcome },
      {
        setUser,
        setDidDismissWelcome,
        setLoginBehaviour,
        setUnreadNoti,
        login: this.login,
        loginGoogle: this.loginGoogle,
        loginFacebook: this.loginFacebook,
        logout: this.logout,
        register: this.register,
        sendVerifyCode: this.sendVerifyCode,
        useVerifyCode: this.useVerifyCode,
        sendResetPasswordCode: this.sendResetPasswordCode,
        useResetPasswordCode: this.useResetPasswordCode,
        listUser: this.listUser,
        detail: this.detail,
        update: this.update,
        create: this.create,
        like: this.like,
        comment: this.comment,
        updateComment: this.updateComment,
        deleteComment: this.deleteComment,
        report: this.report,
        getFeed: this.getFeed,
        getAdminFeed: this.getAdminFeed,
        changePassword: this.changePassword,
        deleteAccount: this.deleteAccount,
        addFriend: this.addFriend,
        listNotification: this.listNotification,
        markReadNotification: this.markReadNotification,
        acceptFriend: this.acceptFriend,
        unFriend: this.unFriend,
        detailNotification: this.detailNotification,
        listUserBasic: this.listUserBasic,
        getFeedDetail: this.getFeedDetail,
        getMyExtraInfo: this.getMyExtraInfo,
      }
    ];
  }

  login = async ({ email, password }) => {
    return VarHelper.erria(async () => {
      if (!this.state.loginBehaviour.shouldRemember) {
        this.setters.setLoginBehaviour({
          ...this.state.loginBehaviour,
          lastTimeActivity: String(new Date().getTime()),
        })
      }
      const res = await Request.post(HOST + '/users/login', { email, password });
      if (res.data.success) {
        const { token, publicInfo, score } = res.data.data;
        this.setters.setToken(token);
        this.setters.setUser(publicInfo);
        this.setters.setScore(score);
        Request.setToken(token);
        if (window.OneSignal) {
          window.OneSignal.push(function() {
            window.OneSignal.setEmail(publicInfo.email);
            window.OneSignal.sendTag('user_email', publicInfo.email.toLowerCase());
          });
        }
        EventHelper.setUserId(publicInfo.id);
      }
      return res.data;
    });
  }

  loginGoogle = async ({ idToken, photoURL, displayName, firstName, lastName }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/login-google', { idToken, photoURL, displayName, firstName, lastName });
      if (res.data.success) {
        const { token, publicInfo, score } = res.data.data;
        this.setters.setToken(token);
        this.setters.setUser(publicInfo);
        this.setters.setScore(score);
        Request.setToken(token);
        if (window.OneSignal) {
          window.OneSignal.push(function() {
            window.OneSignal.setEmail(publicInfo.email);
            window.OneSignal.sendTag('user_email', publicInfo.email.toLowerCase());
          });
        }
        EventHelper.setUserId(publicInfo.id);
      }
      return res.data;
    });
  }

  loginFacebook = async (accessToken) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/login-facebook', { accessToken });
      if (res.data.success) {
        const { token, publicInfo, score } = res.data.data;
        this.setters.setToken(token);
        this.setters.setUser(publicInfo);
        this.setters.setScore(score);
        Request.setToken(token);
        if (window.OneSignal) {
          window.OneSignal.push(function() {
            window.OneSignal.setEmail(publicInfo.email);
            window.OneSignal.sendTag('user_email', publicInfo.email.toLowerCase());
          });
        }
        EventHelper.setUserId(publicInfo.id);
      }
      return res.data;
    });
  }

  getMyUserInfo = async () => {
    const res = await Request.get(HOST + '/users/me', {});
    if (res.data.success) {
      this.updateState({ user: res.data.data });
      EventHelper.setUserId(res.data.data.id);
    } else {
      this.updateState({
        user: {},
        token: '',
      });
    }
  }

  getMyExtraInfo = async () => {
    const res = await Request.get(HOST + '/users/me-extra', {});
    if (res.data.success) {
      !!this.setters.setFriends && this.setters.setFriends(res.data.friends);
      !!this.setters.setUnreadNoti && this.setters.setUnreadNoti(res.data.unreadNoti);
      !!this.setters.setScore && this.setters.setScore(res.data.score);
    }
  }

  logout = async () => {
    const email = this.state.user.email.toLowerCase();
    Request.post(HOST + '/users/logout', {});
    if (window.OneSignal) {
      window.OneSignal.push(function() {
        window.OneSignal.logoutEmail();
        window.OneSignal.deleteTag('user_email');
      });
    }
    Request.setToken('');
    Request._beforeEachCall = undefined;
    this.updateState({
      user: {},
      token: '',
      unreadNoti: 0,
      score: 0,
      friends: [],
      didDismissWelcome: false,
    });
    GlobalEvent.emit('LOGOUT', undefined);
  }

  register = async ({ email, password, firstName, lastName }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/register', { email, password, firstName, lastName });
      if (res.data.success) {
        const { token, publicInfo } = res.data.data;
        this.setters.setToken(token);
        this.setters.setUser(publicInfo);
        Request.setToken(token);
      }
      return res.data;
    })
  }

  sendVerifyCode = async () => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/send-verify-code', {});
      return res.data;
    });
  }

  useVerifyCode = async (verifyCode) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/use-verify-code', { verifyCode });
      if (res.data.success) {
        const { publicInfo } = res.data.data;
        this.setters.setUser(publicInfo);
      }
      return res.data;
    });
  }

  sendResetPasswordCode = async (email) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/send-reset-password-code', { email });
      return res.data;
    });
  }

  useResetPasswordCode = async ({ email, code, newPassword }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/use-reset-password-code', { email, code, newPassword });
      return res.data;
    });
  }

  listUser = async ({ type, page, pageSize, options } : { type: string, page: number, pageSize: number, options?: ISearchAndFilterOptions }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/list', { type, page, pageSize, options });
      return res.data;
    });
  }

  detail = async id => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/users/'+id, {});
      return res.data;
    });
  }

  create = async (data : IUser) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/create', data);
      return res.data;
    });
  }

  update = async (data : IUser) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/update', data);
      return res.data;
    });
  }

  like = async ({ id, postType }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/give-like', { id, postType });
      return res.data;
    });
  }

  comment = async ({ id, commentId, postType, content, image, parentCommentId, browserName, browserId }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/give-comment', { id, postType, data: { commentId, content, image, parentCommentId, browserName, browserId } });
      return res.data;
    });
  }

  updateComment = async ({ content, image, commentId }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/update-comment', { content, image, commentId });
      return res.data;
    });
  }

  deleteComment = async (id) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/delete-comment', { id });
      return res.data;
    });
  }

  report = async ({ objectId, objectType, reason, explain }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/give-report', { objectId, objectType, reason, explain });
      return res.data;
    });
  }

  getFeed = async (page, type) => {
    return VarHelper.erria(async () => {
      console.time('getFeed');
      const res = await Request.get(HOST + '/users/me/feed', { page, type });
      console.timeEnd('getFeed');
      if (!!res.data.data && this.setters.setListFeeds) {

        const mergeListData = (page1Rows = [], currentList) => {
          const mergedList = currentList.slice();
          page1Rows.reverse().forEach(val => {
            const findIndex = mergedList.findIndex(item => item.id === val.id);
            if (findIndex === -1) {
              mergedList.unshift(val)
            } else {
              mergedList[findIndex] = val;
            }
          });
          return mergedList.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1);
        }

        this.setters.setListFeeds(
          page === 1 ? mergeListData(res.data.data.rows, this.state.listFeeds)
          : [
            ...this.state.listFeeds,
            ...res.data.data.rows,
          ]
        )
      }
      return res.data;
    });
  }

  getAdminFeed = async (page) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/users/all/feed', { page });
      if (!!res.data.data && this.setters.setListAdminFeeds) {
        this.setters.setListAdminFeeds(page === 1 ? res.data.data.rows : [
          ...this.state.listAdminFeeds,
          ...res.data.data.rows,
        ])
      }
      return res.data;
    });
  }

  changePassword = async ({ currentPassword, newPassword }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/me/change-password', { currentPassword, newPassword });
      return res.data;
    });
  }

  deleteAccount = async (name) => {
    return VarHelper.erria(async () => {
      if (this.state.user.displayName !== name) return;
      const res = await Request.post(HOST + '/users/me/delete-account', { });
      return res.data;
    });
  }

  addFriend = async ({ id, displayName, photoUrl }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/add-friend', { id, displayName, photoUrl });
      if (res.data.success) {
        this.getMyUserInfo();
      }
      return res.data;
    });
  }

  acceptFriend = async ({ id, displayName, photoUrl }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/accept-friend', { id, displayName, photoUrl });
      if (res.data.success) {
        this.getMyUserInfo();
      }
      return res.data;
    });
  }

  unFriend = async ({ id, displayName, photoUrl }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/unfriend', { id, displayName, photoUrl });
      if (res.data.success) {
        this.getMyUserInfo();
      }
      return res.data;
    });
  }

  listNotification = async ({ page, pageSize }) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/users/me/notifications', { page, pageSize });
      return res.data;
    });
  }

  markReadNotification = async ({ id, isAll }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/me/notifications/mark-read', { id, isAll });
      return res.data;
    });
  }

  detailNotification = async id => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/users/me/notification/' + id, {});
      return res.data;
    });
  }

  listUserBasic = async (list: Array<string>) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/users/get-list-basic-info', { list });
      return res.data;
    });
  }

  getFeedDetail = async (id: string) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/feed/'+id, {});
      return res.data;
    });
  }
}

export default new User();
