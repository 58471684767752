import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { COLOR, ERROR, SCREEN_NAME, STYLES } from 'const';
import { useNavFunc } from 'navigation';
import { IQuote } from 'type';
import { ICON } from 'assets';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import { useDropdownOptions, usePostCURDDropdown } from '../../hooks';
import { Avatar, PostTimestamp } from '../../elements';
import LikeComments from '../LikeComments/LikeComments';
import QuoteNoCreatorItem from './QuoteNoCreatorItem';
import Store from 'store';
import { VarHelper } from 'helpers';
import UserName from '../UserName';

interface IQuoteWithCreatorItemProps {
  data: IQuote,
  children?: any,
  [anyProps: string]: any,
}

const QuoteWithCreatorItem = (props: IQuoteWithCreatorItemProps) => {
  const { navigate, navigation, goWithPreData } = useNavFunc();
  const propStyle = usePropsStyle(props);
  const { data } = props;

  const goToUserProfile = () => {
    navigation.navigate(SCREEN_NAME.UserProfile, { id: data.createdBy.id, name: VarHelper.toSlug(data.createdBy.name) });
  }

  const goToFeedDetail = () => {
    goWithPreData(SCREEN_NAME.QuoteDetail, { id: data.id }, data);
  }


  return (
    <QuoteNoCreatorItem data={data as IQuote} style={propStyle}>
      <Row flex1>
        <Col width48 onPress={goToUserProfile}>
          <Avatar size={48} name={data.createdBy.name} uri={data.createdBy.avatar} />
        </Col>
        <Col marginLeft16 flex1>
          <Text><UserName  {...data.createdBy} /></Text>
          <PostTimestamp
            onPress={goToFeedDetail}
            updatedAt={data.updatedAt} createdAt={data.createdAt}
          />
        </Col>
      </Row>
    </QuoteNoCreatorItem>
  );
};

export default QuoteWithCreatorItem;