import { DEVICE_INFO, TAB_NAME, SCREEN_NAME } from 'const';
import { VarHelper } from 'helpers';
import qs from 'qs';

const Route = require('route-parser');

const getParamFromUrlQuery = () => !DEVICE_INFO.IS_WEB ? {} : qs.parse(window.location.search, { ignoreQueryPrefix: true });

export const linking  = {
  prefixes: [],
  config: {
    screens: {
      Home: '/trang-chu',
      CMSHome: '/quan-ly',
      CMSListBook: '/quan-ly/sach',
      CMSCreateBook: '/quan-ly/tao-sach',
      CMSEditBook: '/quan-ly/sach/:id/:name',
      CMSListGenre: '/quan-ly/the-loai',
      CMSListNews: '/quan-ly/tin-tuc',
      CMSListUser: '/quan-ly/nguoi-dung',
      CMSListReport: '/quan-ly/vi-pham',
      CMSBulkCreateReview: '/quan-ly/danh-gia/nhap-hang-loat',
      CMSListReview: '/quan-ly/danh-gia',
      CMSEditReview: '/quan-ly/danh-gia/:id',
      CMSCreateNews: '/quan-ly/tin-tuc/them-tin',
      CMSEditNews: '/quan-ly/tin-tuc/:id/:name',
      CMSBulkCreateBook: '/quan-ly/sach/tai-hang-loat-sach',
      CMSCreateUser: '/quan-ly/nguoi-dung/them',
      CMSEditUser: '/quan-ly/nguoi-dung/:id/:name',
      CMSEditReport: '/quan-ly/vi-pham/:id',
      CMSDisplay: '/quan-ly/hien-thi',
      Login: '/dang-nhap',
      Register: '/dang-ky',
      ForgotPassword: '/quen-mat-khau',
      Terms: '/dieu-khoan-su-dung',
      AccountVerifyNotice: '/xac-thuc-tai-khoan',
      News: '/tin-tuc',
      Group: '/hoi-nhom',
      CreateGroup: '/hoi-nhom/tao-moi',
      UpdateGroup: '/hoi-nhom/:id/:name/chinh-sua',
      Writing: '/viet',
      WritingViewAll: '/viet/xem-tat-ca',
      Manga: '/truyen-tranh',
      MangaViewAll: '/truyen-tranh/xem-tat-ca',
      Quote: '/trich-dan',
      UseResetPasswordCode: '/dat-lai-mat-khau/:email/:code',
      CreateBook: '/sach/dong-gop',
      BookHomepage: '/sach',
      NewsDetail: '/tin-tuc/:id/:name',
      BookDetail: '/sach/:id/:name',
      BookDetailSingleReview: '/sach/:bookid/:bookname/danh-gia/:id',
      HomeAdminAllFeed: '/admin-newsfeed',
      UserProfile: '/nguoi-dung/:id/:name',
      UserProfileAllReview: '/nguoi-dung/:id/:name/danh-gia',
      UserProfileAllQuote: '/nguoi-dung/:id/:name/trich-dan',
      UserProfileAllWriting: '/nguoi-dung/:id/:name/viet',
      UserProfileAllManga: '/nguoi-dung/:id/:name/truyen-tranh',
      UserProfileSetting: '/ca-nhan/thiet-lap/:tab',
      UserProfileAllFriends: '/nguoi-dung/:id/:name/ban-be',
      UserProfileAllBookshelf: '/nguoi-dung/:id/:name/tu-sach',
      UserProfileBookshelfDetail: '/nguoi-dung/:id/:name/tu-sach/:bookshelf_id',
      CreateQuote: '/trich-dan/them-moi',
      EditQuote: '/trich-dan/chinh-sua/:id',
      CreateWriting: '/viet/viet-moi',
      WritingDetail: '/viet/:id/:name',
      WritingDetailChapter: '/viet/:id/:name/chuong/:chapterNumber/:slug',
      UpdateWriting: '/viet/:id/:name/update',
      CreateManga: '/truyen-tranh/viet-moi',
      MangaDetail: '/truyen-tranh/:id/:name',
      MangaDetailChapter: '/truyen-tranh/:id/:name/chuong/:chapterNumber/:slug',
      MangaDetailChapterNew: '/truyen-tranh/:id/:name/chuong/:chapterNumber/:slug/new',
      UpdateManga: '/truyen-tranh/:id/:name/update',
      GroupDetail: '/hoi-nhom/:id/:name/:tab',
      GroupDetailEditPost: '/hoi-nhom/:id/:name/:tab/:postid/:postmode',
      DirectMessage: '/tin-nhan/:mode',
      DetailConversation: '/tin-nhan/chi-tiet/:conversationId',
      NotificationDetail: '/thong-bao/:id',
      EditorScreen: '/editor-screen',
      Feedback: '/dong-gop-y-kien',
      FeedDetail: '/feed/:id',
      QuoteDetail: '/trich-dan/:id',
      Test: '/test',
      
      // DESKTOP
      Stacks: '/',

      // MOBILE
      Tabs: '/m',
      Tab_Home: '/h',
      Tab_CMS: '/c',
      Tab_News: '/n',
      Tab_Group: '/g',
      Tab_Writing: '/w',
      [TAB_NAME.User]: '/u',
    },
  },
};

export const parseInitialRouteForStack = () => {
  const defaultRoute = {
    routeName: SCREEN_NAME.Home,
    screenParams: undefined,
  }
  if (!DEVICE_INFO.IS_WEB) return defaultRoute;
  if (window.location.pathname === '/') return defaultRoute;
  const linkingPaths = Object.keys(linking.config.screens).map(val => ({ name: val, path: linking.config.screens[val] }));
  if (window.location.pathname.includes('/m')) {
    const processingPath = window.location.pathname.replace('/m', '/');
    const find = linkingPaths.find(val => VarHelper.parseRoute(processingPath, val.path).isValid)
    if (!!find) return {
      routeName: find.name,
      screenParams: { [find.name]: VarHelper.parseRoute(processingPath, find.path).params },
    }
    else return defaultRoute;
  } else {
    const processingPath = window.location.pathname;
    const find = linkingPaths.find(val => {
      const parse = VarHelper.parseRoute(processingPath, val.path);
      if (!parse.isValid) return false;
      // console.log(processingPath, parse);
      return parse.isValid;
    })
    if (!!find) return {
      routeName: find.name,
      screenParams: {
        [find.name]: {
          ...VarHelper.parseRoute(processingPath, find.path).params,
          ...getParamFromUrlQuery(),
        },
      },
    }
    else return defaultRoute;
  }
};

export const convertRouteToLink = (route, params) => {
  if (!linking.config.screens[route]) return '';
  var route = new Route(linking.config.screens[route]);
  return route.reverse(params) || undefined;
}

interface IGenDetailLink {
  type: string,
  id: string,
  name?: string,
}

export const genDetailLink = ({ type, id, name } : IGenDetailLink) => {
  let route;
  switch(type) {
    case 'quote':
      route = new Route(linking.config.screens.QuoteDetail);
      return route.reverse({ id });
    case 'book':
      route = new Route(linking.config.screens.BookDetail);
      return route.reverse({ id, name: VarHelper.toSlug(name) });
  }
  return '';
}