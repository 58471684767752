import { DEVICE_INFO  } from './deviceInfo'

export * from './color';
export * from './style';
export * from './screens';
export * from './deviceInfo';
export * from './error';



export const POST_VISIBILITY = {
  public: 'Công khai',
  draft: 'Nháp',
  private: 'Riêng tư',
};

export const USER_TYPE = {
  ADMIN: 'Admin',
  'UNVERIFY-USER': 'User (chưa xác thực)',
  USER: 'User',
  AUTHOR: 'Tác giả',
};

export const REPORT_STATUS = {
  PENDING: 'Chờ duyệt',
  REJECTED: 'Đã bác bỏ',
  APPROVED: 'Đã phê duyệt',
};

export const REPORT_TYPE = {
  review: 'Đánh giá',
  quote: 'Trích dẫn',
  writing: 'Viết',
};

export const REPORT_REASON_SHORT = {
  SPAM: 'Spam',
  NOT_SUITABLE_WORDS: 'Không phù hợp',
  COPYRIGHT_ISSUE: 'Bản quyền',
  OTHER: 'Lý do khác',
};

export const REPORT_REASON_FULL = {
  SPAM: 'Bài viết Spam',
  NOT_SUITABLE_WORDS: 'Ngôn từ, nội dung không phù hợp',
  COPYRIGHT_ISSUE: 'Vi phạm bản quyền',
  OTHER: 'Lý do khác',
};

export const BOOK_VISIBILITY = {
  public: 'Công khai',
  draft: 'Nháp',
  trash: 'Thùng rác',
};

export const CHAPTER_EDIT_VISIBILITY = {
  public: 'Công khai',
  draft: 'Nháp',
};

export const MANGA_VISIBILITY = {
  public: 'Công khai',
  draft: 'Nháp',
  trash: 'Thùng rác',
};

export let READISM_HTTP_LINK = `http://localhost:19006`;
if (DEVICE_INFO.IS_WEB && window.location.href.includes('readism.hanoiapp.xyz')) {
  READISM_HTTP_LINK = 'https://readism.hanoiapp.xyz';
} else if (DEVICE_INFO.IS_WEB && window.location.href.includes('readism.vn')) {
  READISM_HTTP_LINK = 'https://readism.vn';
};