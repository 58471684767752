import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Text, Img, usePropsStyle } from 'react-quick-style-components';
import Store from 'store';
import { RatioCol, MainContainer, Uploader } from 'components';
import { ILocalFileUpload, IUser } from 'type';
import { STYLES } from 'const';
import { ICON, IMAGE } from 'assets';

interface IAuthorCover {
  uri: string,
  userId: string,
  [anyProps: string]: any,
}

const AuthorCover = (props : IAuthorCover) => {
  const [{user}, uApi] = Store.User.createUserStore();
  const [localFile, setLocalFile] = useState<ILocalFileUpload>({
    path: props.uri,
    name: '', mime: '',
    raw: undefined,
  });

  const uploaderRef = useRef<{ openFileDialog: Function, uploadLocal: Function }>();

  useEffect(() => {
    setLocalFile({
      path: props.uri,
      name: '', mime: '',
      raw: undefined,
    });
  }, [props.uri]);

  const propStyle = usePropsStyle(props);

  const updateCover = async (newLocal) => {
    setLocalFile(newLocal);
    const [coverUrl, err] = await uploaderRef.current?.uploadLocal(newLocal);
    if (!!coverUrl) {
      await uApi.update({ id: user.id, coverUrl } as IUser);
    }
  }

  if (user.id !== props.userId && !props.uri) return (
    <MainContainer style={propStyle}>
      <RatioCol width='100%' ratio={1140/249} borderRadius10 overflow="hidden">
        <Img source={IMAGE.AuthorBanner} style={STYLES.all100p} />
      </RatioCol>
    </MainContainer>
  )

  return (
    <MainContainer style={propStyle}>
      <RatioCol width='100%' ratio={1140/249} borderRadius10 overflow="hidden">
        <Img source={{ uri: localFile.path || '' }} style={STYLES.all100p} />
      </RatioCol>
      {Boolean(user.id === props.userId) && (
        <Row onPress={() => uploaderRef.current?.openFileDialog()} absoluteFill onHoverStyle={{ backgroundColor: 'rgba(0,0,0,0.3)' }} useNestedHover middle>
          <Row display="none" onHoverStyle={{ display: 'flex' }}>
            <ICON.SolidPen fill="white" width={32} height={32} />
            <Text colorWhite subtitle1 marginLeft16>Sửa cover</Text>
          </Row>
          <Uploader
            ref={uploaderRef}
            onChangeLocal={updateCover}
          />
        </Row>
      )}
    </MainContainer>
  );
};

export default  AuthorCover;