import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={17}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M19.484 3.078A.522.522 0 0020 2.563V1.187c0-.257-.258-.515-.516-.515H1.266C.965.672.75.93.75 1.187v1.375c0 .301.215.516.516.516h18.218zm0 6.875A.522.522 0 0020 9.438V8.062c0-.257-.258-.515-.516-.515H1.266c-.301 0-.516.258-.516.516v1.374c0 .301.215.516.516.516h18.218zm0 6.875a.522.522 0 00.516-.515v-1.375c0-.258-.258-.516-.516-.516H1.266c-.301 0-.516.258-.516.515v1.376c0 .3.215.515.516.515h18.218z"
        fill={props.fill || "#394D63"}
      />
    </Svg>
  )
}

export default SvgComponent
