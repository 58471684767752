import * as React from "react"
import Svg, { G, Path, Defs, Pattern, Use } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
  return (
    <Svg
      width={87}
      height={118}
      viewBox="0 0 87 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <G opacity={0.3} filter="url(#prefix__filter0_d)">
        <Path fill="#498270" d="M7 10h56v89.6H7z" />
      </G>
      <G opacity={0.3} filter="url(#prefix__filter1_d)">
        <Path fill="#498270" d="M11.003 7h60v96h-60z" />
      </G>
      <G filter="url(#prefix__filter2_d)">
        <Path fill="#000" d="M15 3h65v104H15z" />
      </G>
      <Defs>
        <Pattern
          id="prefix__pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <Use
            xlinkHref="#prefix__image0"
            transform="matrix(.00337 0 0 .0021 -.017 0)"
          />
        </Pattern>
      </Defs>
    </Svg>
  )
}

export default SvgComponent
