import React, { memo } from 'react';
import { Col, Img, Row, Text, usePropsStyle, useDynamicResponsiveValue } from 'react-quick-style-components';
import { RatioCol, Avatar, UseMemo, showModal, PostTimestamp } from 'components/elements';
import { usePreDataOrFetchApi, usePostCURDDropdown } from 'components/hooks';
import { IActivity, IBook, IReview } from 'type';
import { useNavFunc, A } from 'navigation';
import AddToBookshelfModal from '../AddToBookshelfModal';
import UserName from '../UserName';
import LikeComments from '../LikeComments/LikeComments';
import { SCREEN_NAME, STYLES, COLOR } from 'const';
import { VarHelper, TimeHelper } from 'helpers';
import { FontAwesome5 } from '@expo/vector-icons';
import Store from 'store';

interface IFeedItemProps {
  data: IActivity,
  [anyProp: string]: any,
}

const BookFeed = (props: IFeedItemProps) => {
  const rV = useDynamicResponsiveValue();
  const { navigate } = useNavFunc();
  const propStyle = usePropsStyle(props);
  const bookData: IBook = props.data?.data;
  const data = bookData;

  const onPressGoToDetail = () => {
    navigate(SCREEN_NAME.BookDetail, { id: bookData.id, name: VarHelper.toSlug(bookData.name) })
  }

  const goPressCreatorProfile = () => {
    if (!data.createdById) return;
    navigate(SCREEN_NAME.UserProfile, { id: data.createdById, name: VarHelper.toSlug(data.createdBy.name) });
  }

  const bookImage = bookData?.images?.length > 0 ? bookData.images[0] : '';

  const openBookShelfModal = () => {
    showModal({
      component: (
        <AddToBookshelfModal bookId={data.id} bookName={data.name} bookImage={bookImage} />
      )
    })
  }

  const renderBookDetail = () => {
    return (
      <Row height192 stretch padding14 marginHorizontal24 borderRadius4 borderWidth1 borderColor={'#ECF0EF'}>
        <RatioCol ratio={100 / 160} width={100} onPress={onPressGoToDetail}>
          <Img source={{ uri: bookImage }} width100p height100p />
        </RatioCol>
        <Col flex1 paddingLeft16>
          <Col onPress={onPressGoToDetail}>
            <Text numberOfLines={2} subtitle1 marginBottom8>{bookData?.name}</Text>
            <Text numberOfLines={1} body2 marginBottom16>{bookData?.authors?.length > 0 ? bookData.authors[0].name : ''}</Text>

            <Text numberOfLines={2} body2 marginBottom16>{bookData?.content}</Text>
          </Col>

          <Col bgMain borderRadius8 width141 height33 middle onPress={openBookShelfModal}>
            <Text colorWhite bold>Thêm vào tủ sách</Text>
          </Col>
        </Col>
      </Row>
    );
  };

  const shouldShowLikeCommentActivity = !!data?.lastComment?.createdAt;
  const showUnknownUpdate = shouldShowLikeCommentActivity && TimeHelper.diffHours(data?.updatedAt, data?.lastComment?.createdAt) >= 1;
  return (
    <UseMemo deps={[data, shouldShowLikeCommentActivity]}>
      <Col style={propStyle} borderWidth1 borderColor={COLOR.BORDER} borderRadius4 bgWhite>
        <Col padding24>
          {showUnknownUpdate ? (
            <Text marginBottom16>Có cập nhật mới {TimeHelper.fromNow(data?.updatedAt)}</Text>
          ) : Boolean(shouldShowLikeCommentActivity) && (
            <Row alignItems="flex-start" marginBottom16 paddingBottom8 borderBottomColor={COLOR.BORDER} borderBottomWidth1>
              <Col flex1>
                <Text><UserName  {...data.createdBy} /> đã bình luận vào bài đăng</Text>
                <Col marginTop4 onHoverStyle={{ opacity: 1 }} useNestedHover onPress={onPressGoToDetail}>
                  <Text onHoverStyle={{ textDecorationLine: 'underline' }} color={COLOR.GRAY_500}>{TimeHelper.fromNow(data?.lastComment?.createdAt)}</Text>
                </Col>
              </Col>
            </Row>
          )}
          <Row alignItems="flex-start">
            <Col width48 onPress={goPressCreatorProfile}>
              <Avatar size={48} name={data.createdBy?.name} uri={data.createdBy?.avatar} />
            </Col>
            <Col marginLeft16 flex1>
              <Text><UserName  {...data.createdBy} /> đã đóng góp cuốn sách <A route={SCREEN_NAME.BookDetail} params={{ id: data.id, name: VarHelper.toSlug(data.name) }}><Text bold>{data.name}</Text></A></Text>
              {Boolean(!shouldShowLikeCommentActivity) && (
                <PostTimestamp onPress={onPressGoToDetail} createdAt={data.createdAt} updatedAt={data.updatedAt} />
              )}
            </Col>
          </Row>
        </Col>
        {renderBookDetail()}
        <LikeComments entityData={data} postType='book' />
      </Col>
    </UseMemo>
  );
};

export default memo(BookFeed);
