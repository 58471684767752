import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { CMSLayout, Input, Button, Select } from 'components';
import { COLOR, ERROR } from 'const';
import { ActivityIndicator } from 'react-native';
import { ICON } from 'assets';

import Store from 'store';
import { VarHelper } from 'helpers';

import SliderHome from './CMS.Display.Comps/SliderHome';
import BannerNewsFeed from './CMS.Display.Comps/BannerNewsFeed';
import BookRecommendationSidebar from './CMS.Display.Comps/BookRecommendationSidebar';

const CMSDisplay = () => {
  const [{ listSettings }, sApi] = Store.Setting.createStore();
  const [nav, setNav] = useState<{ label: string, value: string }>();

  useEffect(() => {
    if (listSettings.length === 0) sApi.getList();
  }, []);

  const navs = [
    { label: 'Slide Banner trang chủ (chưa login)', value: 'SLIDER_HOME' },
    { label: 'Banner Event trang chủ (đã login)', value: 'BANNER_EVENT_HOME' },
    { label: 'Danh sách Sách hiển thị ở sidebar', value: 'BOOK_RECOMMENDATION_SIDEBAR' },
  ]

  return (
    <Col flex1>
      <CMSLayout title="Quản lý Thể loại">
        <Select
          label=""
          placeholder="Chọn thiết lập"
          options={navs}
          value={nav} onChange={setNav}
        />

        <Col marginTop24>
          {!nav ? null : (
            nav?.value === 'SLIDER_HOME' ? <SliderHome /> :
            nav?.value === 'BANNER_EVENT_HOME' ? <BannerNewsFeed /> :
            nav?.value === 'BOOK_RECOMMENDATION_SIDEBAR' ? <BookRecommendationSidebar /> :
            null
          )}
        </Col>
      </CMSLayout>
    </Col>
  );
};

export default CMSDisplay;