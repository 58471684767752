import React, { useState, useCallback } from 'react';
import { Col, Row, Text, Scroll, Img } from 'react-quick-style-components';
import { hideModal, Input, RatioCol, RateStars, Popup, YoutubeEmbed, Button, Avatar, useSubmitUIState } from '../index';
import { ICON } from 'assets';
import { COLOR, STYLES } from 'const';
import { useWindowDimensions, } from 'react-native';
import Store from 'store';
import { IBook } from 'type';
import { VarHelper } from 'helpers';
import { ERROR } from 'const';

interface IInviteFriendsToGroupModalProps {
  groupId: string,
  members: Array<{ id: string, }>,
}

const InviteButton = ({ groupId, friendId }) => {
  const [{ loading, btnText }, setUI] = useSubmitUIState();
  const [{}, gApi] = Store.Group.createStore();
  const invite = async (id) => {
    await setUI({ loading: true });
    const [res, err] = await gApi.inviteFriend(groupId, friendId);
    await setUI({ loading: false });
    if (!!res && !!res.success) {
      setUI({ btnText: 'Đã mời' });
    }
  };

  return (
    <Button marginTop8 width90 onPress={invite} loading={loading} text={btnText || 'Mời'} solid small />
  )
};

const InviteFriendsToGroupModal = (props: IInviteFriendsToGroupModalProps) => {
  const { friends } = Store.User.state;

  const { width, height } = useWindowDimensions();

  const containerWidth = width < 768 ? width * 0.9 : width / 2;
  const containerHeight = width < 768 ? height * 0.8 : height / 2;

  const filterFriendAlreadyJoined = (() => {
    if (!props.members) return friends;
    let list = [];
    friends.forEach(val => {
      const findIndex = props.members.findIndex(m => m.id === val.id);
      if (findIndex === -1) {
        list.push(val);
      }
    })
    return list;
  })();

  return (
    <Col width={containerWidth} height={containerHeight} bgWhite borderRadius10 onPress={() => { }}>
      <Scroll width={containerWidth} height={containerHeight} padding24 borderRadius10>
        <Row justifyContent="space-between">
          <Text fontSize20 bold>Mời bạn bè</Text>
          <Col width30 height30 middle onPress={hideModal}>
            <ICON.Close fill={COLOR.GRAY_500} width={13} height={20} />
          </Col>
        </Row>

        <Row xs='100%' md='50%' padding12>
          {filterFriendAlreadyJoined.map((u, i) => (
            <Row
              key={'member-' + i} margin12 padding12 borderWidth1 borderColor={COLOR.BORDER} borderRadius10
            >
              <Avatar size={80} uri={u.avatar} name={u.name} />

              <Col flex1 marginLeft16>
                <Text subtitle1>{u.name}</Text>

                <InviteButton groupId={props.groupId} friendId={u.id} />
              </Col>
            </Row>
          ))}
        </Row>

      </Scroll>
    </Col>
  );
};

export default InviteFriendsToGroupModal;
