import React from 'react';

export const lineText = {
  width: 100,
  height: 10,
  bg: 'white',
  color: '#ece4e4',
  renderChildren: () => (
    <>
      <rect x="0" y="0" rx="0" ry="0" width="100" height="10" />
    </>
  ),
};