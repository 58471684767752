import React, { useState } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { COLOR, STYLES, useFontSizeStyle } from 'const';
import { useNavigation } from '@react-navigation/native';
import { SCREEN_NAME } from 'const';
import { Header, Button, Slider, MainContainer, BookLeftSidebar, NewsFeed, RatioCol } from 'components';
import { useWindowDimensions } from 'react-native';
import { ICON, IMAGE } from 'assets';
import { useNavFunc } from 'navigation';
import Store from 'store';

const HomeLoggedIn = ({ bannerEvent }) => {
  const { navigate } = useNavFunc();
  const [tab, setTab] = useState<'all' | 'following'>('all');
  const TABS = [
    { label: 'Tất cả', key: 'all' },
    { label: 'Theo dõi', key: 'following' },
  ];
  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1>
        <Scroll>
          <MainContainer padding10 paddingVertical32>

            {Boolean(bannerEvent && bannerEvent.data && bannerEvent.data.enable && bannerEvent.data.image) && (
              <RatioCol
                ratio={1140 / 249} width="100%" marginBottom32 borderRadius16
                onPress={!!bannerEvent.data.link ? () => { window.location = bannerEvent.data.link; } : undefined}
              >
                <Img source={{ uri: bannerEvent.data.image }} style={STYLES.all100p} />
              </RatioCol>
            )}

            <Row xs='100%' md='1:any:2' alignItems="flex-start">
              <BookLeftSidebar
                onPressGenre={name => navigate(SCREEN_NAME.BookHomepage, { genre: name })}
                marginBottom24
              />
              <Col width32 height0 />
              <Col>
                <Row height68 marginBottom24 bgWhite stretch paddingHorizontal12 borderRadius4 borderWidth1 borderColor={COLOR.BORDER}>
                  {TABS.map((tabItem, i) => {
                    const isActive = tabItem.key === tab;
                    const borderStyle = isActive ? { borderBottomWidth: 5, borderBottomColor: COLOR.MAIN } : { borderBottomWidth: 5, borderBottomColor: COLOR.WHITE_100 };
                    return (
                      <Col
                        key={tabItem.key} {...borderStyle} paddingHorizontal16 middle
                        onHoverStyle={{ opacity: 1 }} useNestedHover
                        onPress={() => {
                          if (!isActive) setTab(tabItem.key as any);
                        }}
                      >
                        <Text btnTextLarge onHoverStyle={{ color: COLOR.MAIN }}>{tabItem.label}</Text>
                      </Col>
                    );
                  })}
                </Row>
                <NewsFeed type={tab} />
              </Col>
            </Row>
          </MainContainer>

        </Scroll>
      </Col>
    </Col>
  );
};

export default HomeLoggedIn;