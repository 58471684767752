import { Popup, RatioCol, Tooltip } from 'components/elements';
import { COLOR, ERROR, STYLES } from 'const';
import React, { useState } from 'react';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { RateStars, showModal, MoveBookshelfModal } from 'components';
import { useNavFunc } from 'navigation';
import { IBook, IBookShelf } from 'type';
import { usePreDataOrFetchApi, useDropdownOptions } from 'components/hooks';
import { Entypo, FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { ICON } from 'assets';
import Store from 'store';
interface IBookItemProps {
  data: {
    id: string,
    name: string,
    image: string,
    status?: 'PENDING' | 'APPROVED',
  },
  onPress?: any,
  bookshelf: IBookShelf,
  onRemove?: any,
  onMove?: any,
  [anyProps: string]: any,
}

const BookItemBookShelf = (props : IBookItemProps) => {
  const { navigation } = useNavFunc();
  const propStyle = usePropsStyle(props);
  const [{}, bApi] = Store.Book.createBookStore();
  const [data] = usePreDataOrFetchApi<IBook>(props.data?.id, 'book');

  const bookImage = !data ? props.data.image : (data?.images ? data?.images[0] : '');

  const removeBookFromBookShelf = () => {
    const doRemove = async () => {
      const [res, err] = await bApi.removeBookFromBookShelf(props.bookshelf, props.data.id);
      if (err || res.error) return Popup.showError(ERROR.parseError(err || res.error));
      !!props.onRemove && props.onRemove();
    };

    Popup.show(`Bạn có chắc muốn xóa sách "${data?.name}" khỏi tủ sách?`, [
      { text: 'Xóa', solid: false, outline: true, onPress: doRemove },
      { text: 'Không', solid: true, outline: false, onPress: () => {} },
    ])
  }

  const moveBook = () => {
    showModal({
      component: (
        <MoveBookshelfModal
          bookId={data?.id} bookImage={bookImage} bookName={data?.name} bookshelfSource={props.bookshelf}
          onMove={props.onMove}
        />
      )
    })
  }

  const { showDropdown } = useDropdownOptions({
    navigation,
    width: 150,
    useEventPosition: true,
    positions: { top: 60, right: 24 + 36 },
    dropdownOptions: [
      { text: 'Xóa', icon: <ICON.Trash />, onPress: removeBookFromBookShelf },
      { text: 'Chuyển tủ', icon: <FontAwesome name="arrows-h" size={16} color={COLOR.MAIN} />, onPress: moveBook },
    ],
  });

  const handlePress = () => {
    if (props.data.status === 'PENDING') return;
    props.onPress(data);
  }
  
  return (
    <Row style={propStyle} alignItems="flex-start" height160 bgWhite shadow>
      <RatioCol ratio={100/160} width={100} shadow onPress={handlePress}>
        <Img source={{ uri: bookImage }} style={STYLES.all100p} />
      </RatioCol>
      <Col flex1 paddingHorizontal16 height160>
        <Col onPress={handlePress} marginVertical8>
          <Text bold subtitle1 numberOfLines={2} >{data?.name || props.data.name}</Text>
        </Col>
        {Boolean(!!data && !!data.authors && !!data.authors[0]) && <Text caption marginBottom10>{data.authors[0].name}</Text>}
        <Row>
          <RateStars
            width86 height12 activeColor={COLOR.WARNING_400} inactiveColor={COLOR.WARNING_400}
            value={Math.round(data?.averageRating)}
            starSize={{ width: 14, height: 12 }}
            onChange={() => {}}
            outline
          />
          <Text subtitle2 marginLeft8>{String(data?.averageRating || '0.0')}</Text>
        </Row>

        <Row marginTop16>
          {props.data.status === 'PENDING' ? (
            <Col borderRadius4 backgroundColor={COLOR.WARNING_500} middle flex3 padding8>
              <Text colorWhite btnTextSmall>Đang chờ duyệt</Text>
            </Col>
          ) : (
            <Col borderRadius4 bgMain middle flex3 padding8 onPress={handlePress}>
              <Text colorWhite btnTextSmall>Chi tiết</Text>
            </Col>
          )}
          {props.bookshelf.name !== 'Sách đóng góp' && (
            <Col
              onPress={showDropdown}
              marginLeft4 borderRadius4 flex1 height32 middle bgMain opacity={0.4} onHoverStyle={{ opacity: 1 }}
            >
              <Entypo name="chevron-down" size={24} color="white" />
            </Col>
          )}
          
        </Row>
        
      </Col>
    </Row>
  );
};

export default BookItemBookShelf;
