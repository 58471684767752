import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import {
  CMSLayout, Button, Input, Uploader, Select, Checkbox, CMSUploaderUI, FilePicker, showModal,
  ControlledRichTextEditor, RateStars, MainContainer, Popup,
} from 'components';
import { Linking, StyleSheet, useWindowDimensions, ActivityIndicator } from 'react-native';
import { useNavFunc, Reset } from 'navigation';
import { COLOR, DEVICE_INFO, ERROR, SCREEN_NAME, BOOK_VISIBILITY } from 'const';
import { ICON } from 'assets';
import Store from 'store';
import * as Animatable from 'react-native-animatable';
import { IBook, IReview } from 'type';
import { VarHelper } from 'helpers';
import { Entypo, AntDesign } from '@expo/vector-icons';
import SelectBook from './CMS.BulkCreateReview.SelectBook';

const emptyReview = {
  bookId: '',
  bookName: '',
  hasSpoiler: false,
  contentJSON: {},
  contentHTML: '',
  contentRaw: undefined,
  content: '',
  visibility: 'public',
  star: 0,
  creatorType: 'me',
  creatorEmail: '',
  creatorFirstName: '',
  creatorLastName: '',
};

interface IReviewCreate extends IReview {
  contentRaw: any,
  creatorType: 'me' | 'new' | 'existing',
  creatorEmail: string,
  creatorFirstName: string,
  creatorLastName: string,
}

const creatorTypeOptions = [
  {value: 'me', label: 'Tôi'},
  {value: 'new', label: 'Tạo 1 user mới'},
  {value: 'existing', label: 'Chọn 1 user có sẵn'},
];

const CMSBulkCreateReview = () => {
  const { goBackOrTo, reset } = useNavFunc();
  const [btnText, setBtnText] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDownloadExcel, setLoadingDownloadExcel] = useState(false);
  const [loadingUploadExcel, setLoadingUploadExcel] = useState(false);
  const [syncing, setsyncing] = useState(false);
  const [shouldExportWhenSubmit, setShouldExportWhenSubmit] = useState(false);

  const rV = useDynamicResponsiveValue();
  const { user } = Store.User.state;
  const [{ }, rApi] = Store.Reviews.createStore();
  const [{ listGenres }, gApi] = Store.Genres.createStore();
  const [rows, setRows] = useState<Array<IReviewCreate>>([]);

  const createObj = useRef<Array<IReviewCreate>>([]);
  useEffect(() => {
    // console.log('rows', rows);
    createObj.current = rows;
  }, [rows]);

  const { width } = useWindowDimensions();

  const createAll = async () => {
    if (createObj.current.length === 0) return alert(ERROR.co_fill_all_form);
    console.log(createObj.current.filter(val => !val.bookId || !val.bookName || !val.star).length > 0);
    console.log(createObj.current.filter(val => !val.bookId || !val.bookName || !val.star));
    if (createObj.current.filter(val => !val.bookId || !val.bookName || !val.star).length > 0) {
      return alert(ERROR.co_fill_all_form);
    }
    setLoading(true);
    const withoutRawData = createObj.current.map(val => {
      delete val.contentRaw;
      return val;
    })
    const [res, err] = await rApi.bulkCreateReview(withoutRawData);
    if (err) {
      setLoading(false);
      return alert(ERROR.parseError(err));
    }
    if (res.error) {
      setLoading(false);
      return alert(ERROR.parseError(res.error));
    }
    if (shouldExportWhenSubmit) {
      alert('Tạo thành công, dữ liệu sẽ đồng thời được tải xuống dưới dạng excel, vui lòng lưu trữ file như 1 hình thức backup dữ liệu');
      await exportExcel();
    } else {
      alert('Tạo thành công');
    }
    setLoading(false);
    setRows([]);
  };

  const exportExcel = async () => {
  };

  const addNewRow = () => {
    const clone = rows.slice();
    clone.push({
      id: VarHelper.genId(),
      ...emptyReview as IReviewCreate,
    });
    setRows(clone);
  };

  const removeRow = useCallback((index) => {
    const newRows = [
      ...rows.slice(0, index),
      ...rows.slice(index + 1, rows.length),
    ];
    setRows(newRows);
  }, [rows]);

  const updateObj = (id, index, labelOrFunc) => (newValue) => {
    const newRows = createObj.current.slice();
    const label = typeof labelOrFunc === 'string' ? labelOrFunc : undefined;
    if (!newRows[index]) {
      newRows[index] = {
        ...emptyReview as IReviewCreate,
        id,
        ...(typeof labelOrFunc === 'string' ? { [label]: newValue } : labelOrFunc(newValue))
      }
    } else {
      newRows[index] = {
        ...newRows[index],
        ...(typeof labelOrFunc === 'string' ? { [label]: newValue } : labelOrFunc(newValue)),
      };
    }
    setRows(newRows);
  };

  if (!user.type || user.type !== 'ADMIN') {
    return (
      <Col middle flex1 bgWhite>
        <Text>Bạn không đủ quyền hạn để truy cập trang này..</Text>
        <Reset to={SCREEN_NAME.Home} timeout={1500} />
      </Col>
    );
  }

  const renderContent = () => {
    if (width < 1280) {
      return (
        <Col flex1 middle>
          <Text>Vui lòng sử dụng màn hình kích thước 1280px hoặc lớn hơn</Text>
        </Col>
      )
    }
    return (
      <Col flex1 backgroundColor="#F3F6F5">
        <Scroll style={{ padding: 20 }}>
          {rows.map((val, index) => {
            return (
              <Row key={'row-' + val.id} bgWhite marginBottom24 alignItems="flex-start" padding8 borderRadius8>
                <Col flex1 marginRight8>
                  <SelectBook
                    value={val.bookId ? [{ label: val.bookName, value: val.bookId }] : undefined}
                    onChange={(newValue) => {
                      updateObj(val.id, index, data => ({ bookId: data.value, bookName: data.label }))(newValue);
                    }}
                  />
                  <Row marginTop12 justifyContent="space-between">
                    <Col>
                      <Text subtitle1 marginBottom8>Đánh giá</Text>
                      <RateStars
                        marginTop8
                        width151
                        value={val.star}
                        onChange={updateObj(val.id, index, 'star')}
                      />
                    </Col>
                    <Row onPress={() => updateObj(val.id, index, 'hasSpoiler')(!val.hasSpoiler)} marginRight16>
                      <Checkbox value={val.hasSpoiler} onChange={updateObj(val.id, index, 'hasSpoiler')} />
                      <Text marginLeft8 body1>Spoiler alert</Text>
                    </Row>
                  </Row>
                  <Col marginTop12>
                    <Select
                      label='Người tạo'
                      value={creatorTypeOptions.find(item => item.value === val.creatorType)}
                      onChange={updateObj(val.id, index, (newVal) => ({ creatorType: newVal.value }))}
                      options={creatorTypeOptions}
                    />
                    {Boolean(val.creatorType === 'new') && (
                      <Row marginTop12 xs='50%' marginLeft={-12} marginRight={-12} padding6>

                        <Input
                          margin6
                          placeholder=""
                          label='Họ'
                          value={val.creatorLastName}
                          flex1
                          onChange={updateObj(val.id, index, 'creatorLastName')}
                        />

                        <Input
                          margin6
                          placeholder=""
                          label='Tên'
                          value={val.creatorFirstName}
                          flex1
                          onChange={updateObj(val.id, index, 'creatorFirstName')}
                        />

                        <Input
                          margin6
                          placeholder=""
                          label='Email'
                          value={val.creatorEmail}
                          flex1
                          onChange={updateObj(val.id, index, 'creatorEmail')}
                        />

                        <Col margin6>
                          <Text>Password sẽ được đặt giống email</Text>
                        </Col>

                      </Row>
                    )}
                    {Boolean(val.creatorType === 'existing') && (
                      <Input
                        marginTop12
                        placeholder=""
                        label='Email'
                        value={val.creatorEmail}
                        flex1
                        onChange={updateObj(val.id, index, 'creatorEmail')}
                      />
                    )}
                  </Col>
                </Col>
                <Col flex1 marginRight8>
                  <Text subtitle1 marginBottom8>Nội dung</Text>
                  <ControlledRichTextEditor
                    value={{
                      html: val.contentHTML,
                      // @ts-ignore
                      raw: val.contentRaw,
                      text: val.content,
                      json: val.contentJSON,
                    }}
                    onChange={updateObj(val.id, index, data => {
                      return { contentHTML: data.html, contentRaw: data.raw, content: data.text, contentJSON: data.json };
                    })}
                  />
                </Col>

                <Col padding10 onPress={() => removeRow(index)}>
                  <ICON.Trash fill={COLOR.GRAY_500} />
                </Col>
              </Row>
            );
          })}
          <Row marginTop24>
            <Col borderRadius8 bgMain middle width100 paddingVertical5 onPress={addNewRow}>
              <Text colorWhite>Thêm dòng</Text>
            </Col>
            <Col flex1 />
            <Row>
              {/* <Row marginRight8 onPress={() => setShouldExportWhenSubmit(v => !v)} bgWhite borderRadius8 paddingHorizontal16 paddingVertical5 borderWidth1 borderColor={COLOR.MAIN}>
                <Checkbox value={shouldExportWhenSubmit} onChange={setShouldExportWhenSubmit} />
                <Text marginLeft8 body1>Tự động export ra excel</Text>
              </Row> */}
              <Row onPress={loading ? undefined : createAll} marginLeft8 borderRadius8 bgMain middle width140 paddingVertical5>
                {Boolean(loading) ? (
                  <ActivityIndicator size="small" color={"white"} />
                ) : (
                  <>
                    <Entypo name="save" size={12} color={"white"} />
                    <Text colorWhite marginLeft8>Tạo hàng loạt</Text>
                  </>
                )}
              </Row>
            </Row>

          </Row>

        </Scroll>
      </Col>
    )
  }

  return (
    <Animatable.View
      animation="fadeIn"
      duration={300}
      style={styles.container}
    >
      <Row bgMain padding12 middle>
        <Row
          onPress={() => {
            const shouldClose = confirm('Bạn có chắc muốn đóng trang lại? Lưu ý kiểm tra lại dữ liệu chưa lưu!\nBấm OK để đóng, Cancel để quay lại');
            if (shouldClose) reset(SCREEN_NAME.CMSHome);
          }}
        >
          <ICON.Close fill={COLOR.WHITE_100} />
          <Text colorWhite marginLeft8>Đóng trang</Text>
        </Row>
        <Row
          marginLeft24
          onPress={syncing ? undefined : async () => {
            setsyncing(true);
            const [res, err] = await rApi.syncDataAfterReview();
            setsyncing(false);
            if (err) return Popup.showError(ERROR.parseError(err));
            if (res.error) return Popup.showError(ERROR.parseError(res.error));
            Popup.show('Đã đồng bộ dữ liệu');
          }}
        >
          {Boolean(syncing) ? (
            <ActivityIndicator size="small" color={"white"} />
          ) : (
            <AntDesign name="reload1" size={24} color="white" />
          )}
          <Text colorWhite marginLeft8>Đồng bộ số liệu</Text>
        </Row>
      </Row>
      <Row paddingVertical5 middle>
        <MainContainer>
          <Text center caption paddingHorizontal={'5%'}>Lưu ý: Tạo hàng loạt đánh giá sẽ ảnh hưởng đến việc thống kê điểm đánh giá của từng sách, profile của từng user. Do đó hãy bấm nút <Text bold>Đồng bộ số liệu</Text> để cập nhật sau khi đã nhập được 1 số lượng nhiều đánh giá (không nên bấm quá nhiều)</Text>
        </MainContainer>
      </Row>
      {renderContent()}

    </Animatable.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cornerCloseBtn: {
    position: 'absolute',
    top: DEVICE_INFO.IS_IOS ? 48 : 12,
    left: 12,
    height: 24,
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CMSBulkCreateReview;