import React, { useEffect, useState, useCallback } from 'react';
import { Col, Text, Scroll, Row } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle, SCREEN_NAME, ERROR } from 'const';
import { useNavigation } from '@react-navigation/native';
import {  } from 'const';
import { Header, Button, NewsRowItem, MainContainer, BookRecommendationRightSidebar } from 'components';
import { useNavFunc } from 'navigation';
import { ICON } from 'assets';
import Store from 'store';
import { VarHelper } from 'helpers';

const News = () => {
  const { navigation, goWithPreData } = useNavFunc();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [errorMes, setErrorMes] = useState('');
  const [{}, nApi] = Store.News.createStore();

  const getList = useCallback(async (page = 1) => {
    setLoading(true);
    const [res, err] = await nApi.getList({ visibility: 'public', page });
    setLoading(false);
    if (err) return setErrorMes(ERROR.parseError(err));
    if (res && res.error) return setErrorMes(ERROR.parseError(res.error));
    if (res && res.data && res.data.rows) {
      setList(page == 1 ? res.data.rows : [...list, ...res.data.rows]);
      setCount(res.data.count);
      setPage(page);
      if (!!errorMes) setErrorMes('');
    }
  }, [page, list]);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getList(1);
    });
    return unsubscribe;
  }, []);

  // console.log('list', list);

  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1>
        <Scroll>
          <MainContainer alignSelf="center" padding10>
            <Row xs='100%' md='2:any:1' marginTop24 alignItems='flex-start'>
              <Col marginBottom24>
                <Text h5 marginBottom24>BÀI VIẾT MỚI</Text>
                {list.map((val, i) => (
                  <NewsRowItem
                    data={val}
                    key={'news-row-'+val.id+val.i}
                    onPress={() => goWithPreData(SCREEN_NAME.NewsDetail, { id: val.id, name: VarHelper.toSlug(val.name) }, val)}
                    marginBottom16
                  />
                ))}
              </Col>
              <Col width26 height0 />
              <Col marginBottom24>
                <BookRecommendationRightSidebar marginTop48 />
              </Col>
            </Row>
          </MainContainer>
        </Scroll>
      </Col>
    </Col>
  );
};

export default News;