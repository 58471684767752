import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={30}
    height={29}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M14.15 1.373a1 1 0 0 1 1.7 0l1.377 2.224a1 1 0 0 0 .565.432l2.5.746a1 1 0 0 1 .517 1.553l-1.672 2.256a1 1 0 0 0-.195.635l.111 2.809a1 1 0 0 1-1.37.968l-2.312-.923a1 1 0 0 0-.742 0l-2.312.923a1 1 0 0 1-1.37-.968l.111-2.809a1 1 0 0 0-.195-.635L9.19 6.328a1 1 0 0 1 .518-1.553l2.5-.746a1 1 0 0 0 .564-.432l1.377-2.224ZM5.667 14.256a1 1 0 0 1 1.666 0l1.562 2.354a1 1 0 0 0 .565.41l2.721.758a1 1 0 0 1 .515 1.585l-1.756 2.213a1 1 0 0 0-.216.664l.12 2.822a1 1 0 0 1-1.348.98l-2.647-.987a1 1 0 0 0-.698 0l-2.647.986a1 1 0 0 1-1.348-.98l.12-2.821a1 1 0 0 0-.216-.664L.304 19.363a1 1 0 0 1 .515-1.585l2.721-.758a1 1 0 0 0 .565-.41l1.562-2.354ZM22.667 14.256a1 1 0 0 1 1.666 0l1.562 2.354a1 1 0 0 0 .565.41l2.721.758a1 1 0 0 1 .515 1.585l-1.756 2.213a1 1 0 0 0-.216.664l.12 2.822a1 1 0 0 1-1.348.98l-2.647-.987a1 1 0 0 0-.698 0l-2.647.986a1 1 0 0 1-1.348-.98l.12-2.821a1 1 0 0 0-.216-.664l-1.756-2.213a1 1 0 0 1 .515-1.585l2.721-.758a1 1 0 0 0 .565-.41l1.562-2.354Z"
      fill={props.fill || "#F66262"}
    />
  </Svg>
)

export default SvgComponent
