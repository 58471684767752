import React from 'react';
import 'react-tippy/dist/tippy.css';
import {
  Tooltip as TooltipLib
} from 'react-tippy';
interface ITooltipProps {
  children: any,
  title: string,
  [other: string]: any,
}

const Tooltip = ({ children, title, ...other }: ITooltipProps) => {
  return (
    <TooltipLib title={title} {...other}>
      {children}
    </TooltipLib>
  );
};

export default Tooltip;