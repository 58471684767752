import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={84}
      height={88}
      viewBox="0 0 84 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M32.8 59H3.3l-2 24.5h11.5l4.5 2 4-2 10.5-1V66l1-7z"
        fill="#63B199"
      />
      <Path
        d="M78.712 46.322h-8.417c.014-.165.022-.332.022-.501V5.64c0-3.11-2.53-5.64-5.64-5.64H13.883c-3.11 0-5.64 2.53-5.64 5.64v40.18c0 3.11 2.53 5.64 5.64 5.64h20.531l3.611 3.611v24.38a4.516 4.516 0 004.511 4.51h13.836l.772 2.686a1.719 1.719 0 002.742.853l4.312-3.538h14.514a4.516 4.516 0 004.511-4.511V50.834a4.517 4.517 0 00-4.511-4.512zm-43.586 1.701H13.883a2.205 2.205 0 01-2.202-2.202V5.64c0-1.215.988-2.202 2.202-2.202h50.794c1.214 0 2.202.987 2.202 2.202v40.18a2.205 2.205 0 01-2.202 2.203H43.434a1.72 1.72 0 00-1.215.503l-2.939 2.939-2.938-2.939a1.719 1.719 0 00-1.216-.503zm44.66 31.428c0 .592-.482 1.073-1.074 1.073h-15.13c-.397 0-.783.138-1.09.39l-2.767 2.271-.408-1.417a1.719 1.719 0 00-1.651-1.244h-15.13a1.075 1.075 0 01-1.074-1.073V54.144l2.684-2.683h20.531a5.622 5.622 0 004.033-1.701h10.002c.592 0 1.073.482 1.073 1.074V79.45z"
        fill="#333"
      />
      <Path
        d="M30.104 57.156H4.103a3.73 3.73 0 00-3.726 3.726V81.45a3.73 3.73 0 003.726 3.725h10.26l2.964 2.434a1.72 1.72 0 002.742-.854l.455-1.58h9.58a3.73 3.73 0 003.726-3.725V60.88a3.73 3.73 0 00-3.726-3.725zm.289 24.295c0 .159-.13.288-.289.288H19.23c-.767 0-1.44.507-1.652 1.243l-.09.313-1.42-1.166a1.72 1.72 0 00-1.09-.39H4.102a.289.289 0 01-.289-.288V60.88c0-.158.13-.288.289-.288h26.001c.16 0 .288.13.288.289V81.45z"
        fill="#333"
      />
      <Path
        d="M35.788 25.048v-2.76a1.719 1.719 0 00-3.437 0v2.76a1.719 1.719 0 003.437 0zM44.49 26.766c.95 0 1.72-.769 1.72-1.718v-2.76a1.719 1.719 0 00-3.438 0v2.76c0 .949.77 1.718 1.719 1.718zM44.682 33.272a1.719 1.719 0 00-2.43-2.43 4.174 4.174 0 01-2.972 1.231 4.174 4.174 0 01-2.971-1.23 1.719 1.719 0 00-2.432 2.43 7.589 7.589 0 005.403 2.238c2.04 0 3.96-.795 5.402-2.239z"
        fill="#fff"
      />
      <Path
        d="M23.327 70.86H10.88a1.719 1.719 0 000 3.437h12.445a1.719 1.719 0 000-3.438z"
        fill="#E5F0EC"
      />
    </Svg>
  )
}

export default SvgComponent
