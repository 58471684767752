
var Color = require('color');

export const COLOR = {
  FONT: '#333333',
  MAIN: '#63B199',

  PRI_500: '#63B199', // PRIMARY
  PRI_600: '#498270',
  PRI_400: '#8AC4B3',
  PRI_300: '#89CEB7',
  PRI_200: '#DEF8F0',
  PRI_100: '#ECF0EF',

  SEC_600: '#876C51', // SECONDARY
  SEC_500: '#876C51',
  SEC_200: '#D7CEC5',

  DANGER_600: '#B84949', // DANGER
  DANGER_500: '#F66262',
  DANGER_400: '#F88989',
  DANGER_300: '#FAB0B0',
  DANGER_200: '#FDD8D8',
  DANGER_100: '#FEEFEF',

  WARNING_500: '#FFB800',
  WARNING_400: '#F6D271',

  BLACK_100: '#333333',
  BLACK_70: 'rgba(51, 51, 51, 0.85)',

  WHITE_100: '#FFFFFF',
  WHITE_70: 'rgba(255,255,255,0.7)',

  BORDER: '#E4E4E4',

  GRAY_100: '#EFEFEF',
  GRAY_200: '#E4E4E4',
  GRAY_300: '#C9C9C9',
  GRAY_500: '#949494',
};
