import React, { useEffect, useState } from 'react';
import { Col, Text, Row, Scroll } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME } from 'const';
import { Header, Button, LeftSidebarOneThird, Avatar, BookItemBookShelf, BookShelfItem, usePreDataOrFetchApi, LoadMoreDetect, QuoteNoCreatorItem } from 'components';
import { ICON } from 'assets';
import Store from 'store';
import { IActivity, IBookShelf, IUserProfile } from 'type';
import UserProfile from './UserProfile';
import { VarHelper } from 'helpers';

const UserProfileBookshelfDetail = () => {
  const { route, goBackOrTo, goWithPreData } = useNavFunc();
  // @ts-ignore
  const { id, name, bookshelf_id } = route.params;
  const [userData] = usePreDataOrFetchApi<IUserProfile>(id, 'user');
  const [bookShelfData, _, { fetcher }] = usePreDataOrFetchApi<IBookShelf>(bookshelf_id, 'bookshelf', { fetchOnFocus: true });

  return (
    <UserProfile noForce noFetchOnFocus>
      {!userData ? <Col /> :
        <Scroll>
          <Col>
            <Row justifyContent="space-between">
              <Col>
                <Text btnTextLarge>TỦ SÁCH: {bookShelfData?.name}</Text>
                <Text marginTop4 body2 color={COLOR.GRAY_500}>{bookShelfData?.books?.length || 0} sách</Text>
              </Col>
              <Col padding4 onPress={() => goBackOrTo(SCREEN_NAME.UserProfileAllBookshelf, { id, name })}>
                <Text colorMain subtitle1>Quay lại</Text>
              </Col>
            </Row>
          </Col>

          <Row xs='100%' md='50%' marginLeft={-12} marginRight={-12} padding6>
            {bookShelfData?.books?.map(val => (
              <BookItemBookShelf
                data={val} margin6 bookshelf={bookShelfData}
                onPress={(bookData) => goWithPreData(SCREEN_NAME.BookDetail, { id: val.id, name: VarHelper.toSlug(val.name) }, bookData)}
                onRemove={() => fetcher()}
                onMove={() => fetcher()}
              />
            ))}
          </Row>
          
        </Scroll>
      }
    </UserProfile>
  );
};

export default UserProfileBookshelfDetail;