import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { ISearchAndFilterOptions, IUser } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';

interface ISetters {
  setPressSpoiler?: (v : any) => void, 
  [additionSetter: string]: (v : any) => void,
}

interface IState {
  pressSpoiler: {
    [reviewId: string]: boolean,
  }
};

class Reviews extends PersistReady {

  constructor() {
    super();
    this.getInitialData();
  }

  state : IState = {
    pressSpoiler: {},
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  getInitialData = async () => {
    let [pressSpoiler] = await Promise.all([
      Persist.sync('review_store_press_spoiler', 'object'),
    ]);
    if (!pressSpoiler) {
      pressSpoiler = {};
    }
    this.updateState({ pressSpoiler }, false);
    this.makeReady(); // await onReady();
  }

  createStore() {
    const [pressSpoiler, setPressSpoiler] = useGlobalState(this.state.pressSpoiler, 'review_store_press_spoiler');
    if (!this.setters.setPressSpoiler) this.setters.setPressSpoiler = setPressSpoiler;
    useEffect(() => {
      this.updateState({ pressSpoiler });
      Persist.save(pressSpoiler, 'review_store_press_spoiler');
    }, [pressSpoiler]);
    return [
      { pressSpoiler },
      {
        adminGetList: this.adminGetList,
        detailReview: this.detailReview,
        updateReview: this.updateReview,
        giveAReview: this.giveAReview,
        updatePressSpoiler: this.updatePressSpoiler,
        bulkCreateReview: this.bulkCreateReview,
        syncDataAfterReview: this.syncDataAfterReview,
        syncDataSingleBook: this.syncDataSingleBook,
      }
    ];
  }

  adminGetList = async ({ visibility, page = 1, pageSize = 20, options, includeMyReviews } : { visibility: string, page: number, pageSize?: number, options?: ISearchAndFilterOptions, includeMyReviews?: boolean }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/reviews/list', { visibility, page, pageSize, options, includeMyReviews });
      return res.data;
    });
  }

  detailReview = async (id) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/reviews/'+id, {});
      return res.data;
    });
  }

  updateReview = async data => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/reviews/update', data);
      return res.data;
    });
  }

  giveAReview = async data => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/reviews/give', data);
      return res.data;
    });
  }

  updatePressSpoiler = (id : string) => {
    if (!id) return;
    const newObj = {
      ...this.state.pressSpoiler,
      [id]: true,
    }
    if (this.setters.setPressSpoiler) {
      this.setters.setPressSpoiler(newObj);
    } else this.updateState({ pressSpoiler: newObj });
  }

  bulkCreateReview = (data) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/reviews/bulk-create', { data });
      return res.data;
    });
  }

  syncDataAfterReview = () => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/reviews/sync-data', {});
      return res.data;
    });
  }

  syncDataSingleBook = id => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/reviews/sync-data-single', { id });
      return res.data;
    });
  }
}

export default new Reviews();
