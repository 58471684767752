import React, { useEffect, useRef, useImperativeHandle, forwardRef, useState, useMemo } from 'react';
import { Col, usePropsStyle } from 'react-quick-style-components';

import _ from 'lodash';

import RichTextEditor2 from './RichTextEditor2';

interface IControlledRichTextEditorProps {
  id?: string, // not support change dynamicly
  placeholder?: string,
  height?: number,
  onChange?({ html, text, json, raw }): any,
  value: { html, text, json, raw },
  [additionProps: string]: any,
}

const ControlledRichTextEditor = (props : IControlledRichTextEditorProps) => {
  const [id] = useState(('editor-'+Math.random()).replace('.', ''));
  // const [ops, setOps] = useState();
  const editorRef = useRef<{ getHTML: Function, getJSON: Function, getText: Function, setJSON: Function }>();
  const opsRef = useRef();

  useEffect(() => {
    if (!_.isEqual(props.value.json, opsRef.current)) {
      opsRef.current = props.value.json;
      editorRef.current?.setJSON(opsRef.current);
    }
  }, [props.value]);

  const propsStyle = usePropsStyle(props);
  return useMemo(() => (
    <RichTextEditor2
      ref={editorRef}
      id={id}
      height={props.height}
      style={propsStyle}
      placeholder={props.placeholder}
      onChange={(delta, oldDelta, source) => {
        opsRef.current = editorRef.current.getJSON();
        props.onChange && props.onChange({
          html: editorRef.current.getHTML(),
          json: editorRef.current.getJSON(),
          text: editorRef.current.getText(),
          raw: { delta, oldDelta, source },
        })
      }}
    />
  ), []);
};

export default ControlledRichTextEditor;