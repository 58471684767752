import React, { useEffect, useState } from 'react';
import { useWindowDimensions, ActivityIndicator } from 'react-native';
import { Col, Row, Text, Scroll, Img } from 'react-quick-style-components';
import { ICON } from 'assets';
import { COLOR, STYLES, ERROR, SCREEN_NAME } from 'const';
import { hideModal, Input, RatioCol, RateStars, Popup, YoutubeEmbed, Button, Avatar, useSubmitUIState } from '../index';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { VarHelper } from 'helpers';
import { UserNameWithoutA } from './UserName';

interface IListLikeModalProps {
  likes: any,
  navigation: any,
}

const ListLikeModal = (props: IListLikeModalProps) => {
  const { width, height } = useWindowDimensions();
  const containerWidth = width < 768 ? width * 0.9 : width / 2;
  const containerHeight = width < 768 ? height * 0.8 : height / 2;
  const [{ loading }, setUI] = useSubmitUIState();
  const uApi = Store.User;
  const [list, setList] = useState<Array<{ id, name, avatar, type }>>([]);

  // console.log(props.likes);
  const getData = async () => {
    setUI({ loading: true });
    const [res, err] = await uApi.listUserBasic(Object.keys(props.likes).filter(val => !!props.likes[val]));
    setUI({ loading: false });
    if (!!res && !!res.data) {
      setList(res.data);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Col width={containerWidth} height={containerHeight} bgWhite borderRadius10 onPress={() => { }}>
      <Scroll width={containerWidth} height={containerHeight} padding24 borderRadius10>
        <Row justifyContent="space-between">
          <Text fontSize20 bold>Những người đã thích</Text>
          <Col width30 height30 middle onPress={hideModal}>
            <ICON.Close fill={COLOR.GRAY_500} width={13} height={20} />
          </Col>
        </Row>

        {Boolean(loading) ? (
          <Col padding12 height100 middle>
            <ActivityIndicator size='small' color={COLOR.MAIN} />
          </Col>
        ) : (
          <Row padding6 marginLeft={-12} marginRight={-12} xs='100%' md='50%'>
            {list.map((u, uI) => (
              <Row
                key={'u-' + uI} margin12 padding12 borderWidth1 borderColor={COLOR.BORDER} borderRadius10
                onHoverStyle={{ backgroundColor: COLOR.PRI_200 }}
                onPress={() => {
                  props.navigation.navigate(SCREEN_NAME.UserProfile, { id: u.id, name: VarHelper.toSlug(u.name) });
                  hideModal();
                }}
              >
                <Avatar size={50} uri={u.avatar} name={u.name} />
                <Text marginLeft16>
                  <UserNameWithoutA {...u} onPress={() => props.navigation.navigate(SCREEN_NAME.UserProfile, { id: u.id, name: VarHelper.toSlug(u.name) })} />
                </Text>
              </Row>
            ))}
          </Row>
        )}
      </Scroll>
    </Col>
  );
};

export default ListLikeModal;