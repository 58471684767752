import React from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { COLOR, DEVICE_INFO, SCREEN_NAME } from 'const';
import { NavigationProp } from '@react-navigation/native';
import { showModal, hideModal, Tooltip } from '../../elements';
import Store from 'store';
import { VarHelper } from 'helpers';
import { MaterialIcons } from '@expo/vector-icons';
interface IHeaderAvatarDropdownProps {
  navigation: {
    navigate: Function,
  },
}

const HeaderAvatarDropdown = ({ navigation } : IHeaderAvatarDropdownProps) => {
  const { user, score } = Store.User.state;
  const useApi = Store.User;

  const logout = () => {
    useApi.logout();
    navigation.navigate(SCREEN_NAME.Login);
  };

  const listItems = [
    { label: 'Trang cá nhân', onPress: () => navigation.navigate(SCREEN_NAME.UserProfile, { id: user.id, name: VarHelper.toSlug(user.displayName) }) },
    user.type === 'UNVERIFY-USER' ? { label: 'Xác thực tài khoản', onPress: () => navigation.navigate(SCREEN_NAME.AccountVerifyNotice) } : undefined,
    user.type === 'ADMIN' ? { label: 'Trang quản trị', onPress: () => navigation.navigate(SCREEN_NAME.CMSHome) } : undefined,
    user.type === 'ADMIN' ? { label: 'Admin Newsfeed', onPress: () => navigation.navigate(SCREEN_NAME.HomeAdminAllFeed) } : undefined,
    { label: 'Bạn bè', onPress: () => navigation.navigate(SCREEN_NAME.UserProfileAllFriends, { id: user.id, name: VarHelper.toSlug(user.displayName) }) },
    user.type === 'USER' ? { label: 'Đóng góp sách', onPress: () => navigation.navigate(SCREEN_NAME.CreateBook) } : undefined,
    user.type === 'USER' ? { label: 'Viết mới', onPress: () => navigation.navigate(SCREEN_NAME.CreateWriting) } : undefined,
    { label: 'Đăng xuất', onPress: logout },
  ].filter(val => !!val);

  const renderItem = (val, i) => {
    return (
      <Row
        padding12
        key={'avatar-dropdown-'+i}
        onPress={() => {
          hideModal();
          !!val.onPress && val.onPress();
        }}
        onHoverStyle={{
          backgroundColor: COLOR.PRI_200,
        }}
      >
        <Text>{val.label}</Text>
      </Row>
    );
  }

	return (
		<Col width150 bgWhite shadow >
      <Tooltip title="Tích cực kiếm Xu bằng việc đăng Đánh giá và Viết">
        <Row padding12>
          <Text>{user.displayName}</Text>
        </Row>
        <Row padding12>
          <MaterialIcons name="attach-money" size={24} color={COLOR.MAIN} />
          <Row>
            <Text>Xu: <Text bold>{String(score)}</Text></Text>
          </Row>
        </Row>
      </Tooltip>
      {listItems.map(renderItem)}
    </Col>
	);
};

export const useHeaderAvatarDropdown = (navigation) => {

	const showDropdown = () => {
		showModal({
			component: (
				<HeaderAvatarDropdown navigation={navigation} />
			),
			modalProps: {
				style: {
					position: 'absolute',
					top: 60,
					right: (DEVICE_INFO.WIDTH - 1130) / 2,
				},
			},
			noWrapper: true,
			animation: 'pulse',
		})
	};

	return {
		showDropdown,
		hideDropdown: hideModal,
	};
};
