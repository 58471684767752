import React, { useEffect, useState, useRef } from 'react';
import { Col, Text, Row } from 'react-quick-style-components';
import { CMSLayout, Button, CMSTableLayout, Popup } from 'components';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, POST_VISIBILITY, BOOK_VISIBILITY, ERROR } from 'const';
import Store from 'store';
import { TimeHelper, VarHelper } from 'helpers';

const CMSListBook = () => {
  const { navigation, goWithPreData } = useNavFunc();
  const [{}, bookApi] = Store.Book.createBookStore();

  const tableRef = useRef<{ getData: Function }>();

  const quickApprove = async val => {
    const [res, err] = await bookApi.updateBook({
      id: val.id,
      visibility: 'public',
    });
    if (!!res && !!res.success) {
      tableRef.current?.getData();
    } else {
      Popup.showError(ERROR.parseError(err || res.error));
    }
  };

  return (
    <Col flex1>
      <CMSLayout title="Quản lý Sách">
        <Row>
          <Col flex1>
            <Text h4>SÁCH</Text>
          </Col>
          <Button paddingHorizontal15 small solid text="Tạo sách" onPress={() => navigation.navigate(SCREEN_NAME.CMSCreateBook)} />
        </Row>
        <Row marginTop24>
          <CMSTableLayout
            ref={tableRef}
            initialPage={1}
            tableSchema={[
              { title: 'Ảnh', type: 'image', key: (val) => !!val.images && val.images.length > 0 ? val.images[0] : '', valueStyle: { width: 50, height: 50 * 256 / 160 } },
              { title: 'Tên', type: 'text', key: 'name', width: 270 },
              { title: 'Tác giả', type: 'text', width: 150, key: (val) => !!val.authors && val.authors.length > 0 ? val.authors[0].name : '' },
              { title: 'Trạng thái', type: 'text', key: (val) => (
                <Col
                  onPress={val.visibility !== 'draft' ? undefined :() => quickApprove(val)}
                  onHoverStyle={{ opacity: 1 }} useNestedHover
                >
                  <Text>{POST_VISIBILITY[val.visibility]}</Text>
                  {Boolean(val.visibility === 'draft') && <Text onHoverStyle={{ textDecorationLine: 'underline' }} caption colorMain marginTop5>Duyệt nhanh</Text>}
                </Col>
              ) },
              { title: 'Lần sửa cuối', type: 'text', width: 130, key: (val) => TimeHelper.fromNow(val.updatedAt) },
            ]}
            fetcher={(page, options) => bookApi.listBook({ visibility: 'all', page, pageSize: 20, options })}
            pageSize={20}
            onRowPress={(rowData) => goWithPreData(SCREEN_NAME.CMSEditBook, { id: rowData.id, name: VarHelper.toSlug(rowData.name) }, rowData)}
            fetchWhenFocus
            searchAndFilter={{
              search: [{ label: 'Tên', value: 'name' }],
              filter: [{ label: 'Trạng thái', value: 'visibility', options: Object.keys(BOOK_VISIBILITY).map(val => ({ value: val, label: BOOK_VISIBILITY[val] })) }],
              sort: [
                { label: 'Tên', orderType: 'DESC', value: 'name' },
                { label: 'Thời gian cập nhật', orderType: 'DESC', value: 'updatedAt' },
              ],
            }}
            remover={(id, name) => new Promise((resolve, reject) => {
              const doRemove = async () => {
                const [res, err] = await bookApi.updateBook({
                  id,
                  visibility: 'trash',
                });
                if (err) alert(ERROR.parseError(err));
                else if (res.error) alert(ERROR.parseError(res.error));
                resolve(undefined);
              };
              Popup.show(
                'Bạn có chắc chắn muốn xóa: '+ name,
                [
                  { text: 'Không', solid: true, onPress: () => {} },
                  {
                    text: 'Có', solid: false, onPress: doRemove,
                  }
                ],
              );
            })}  
          />
        </Row>
      </CMSLayout>
    </Col>
  );
};

export default CMSListBook;