import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Col, Scroll, Row, Text, useResponsiveStyle } from 'react-quick-style-components';
import { Platform, useWindowDimensions } from 'react-native';
import LeftSidebar272 from './LeftSidebar272';
import { useRoute, useNavigation, CommonActions } from '@react-navigation/native';
import { ICON } from 'assets';
import { SCREEN_NAME } from 'const';
import { Reset } from 'navigation';
import Store from 'store';

interface ICMSLayoutProps {
  children?: any,
  title?: string,
  nav?: Function,
}

export const useCMSNavigation = () => {
  const navigationRef = useRef({ navigate: (...arg) => {} });
  const setNavigationRef = (navigate) => {
    navigationRef.current.navigate = navigate;
  };
  return {
    navigate: (...arg) => navigationRef.current.navigate(...arg),
    setNavigation: setNavigationRef,
  };
}

const CMSLayout = forwardRef((props : ICMSLayoutProps, ref) => {
  const breakpoint = useResponsiveStyle({ xs: 'xs', lg: 'lg' });
  const [showMenu, setShowMenu] = useState((breakpoint === 'xs' ? false : true));
  const route = useRoute();
  const navigation = useNavigation();
  const { height } = useWindowDimensions();
  const { user } = Store.User.state;

  const displayMenus = [
    {
      name: 'Quản lý Sách',
      screen: SCREEN_NAME.CMSListBook,
    },
    {
      name: 'Quản lý Đánh giá',
      screen: SCREEN_NAME.CMSListReview,
    },
    {
      name: 'Quản lý Thể loại',
      screen: SCREEN_NAME.CMSListGenre,
    },
    {
      name: 'Quản lý Tin tức',
      screen: SCREEN_NAME.CMSListNews,
    },
    {
      name: 'Quản lý Người dùng',
      screen: SCREEN_NAME.CMSListUser,
    },
    {
      name: 'Quản lý Vi phạm',
      screen: SCREEN_NAME.CMSListReport,
    },
    {
      name: 'Quản lý Hiển thị',
      screen: SCREEN_NAME.CMSDisplay,
    },
  ];

  useEffect(() => {
    if (breakpoint === 'lg') setShowMenu(true);
  }, [breakpoint]);

  const navigate = (screenName, params = undefined) => {
    if (Platform.OS === 'web') {
      navigation.navigate(screenName, params);
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: screenName }],
      });
    }
    breakpoint === 'xs' && setShowMenu(false);
  };

  useEffect(() => {
    if (props.nav) {
      props.nav(navigate);
    }
  }, [props.nav])

  useImperativeHandle(
    ref,
    () => ({
      navigate,
    }),
    [],
  );

  if (!user.type || user.type !== 'ADMIN') {
    return (
      <Col middle flex1 bgWhite>
        <Text>Bạn không đủ quyền hạn để truy cập trang này..</Text>
        <Reset to={SCREEN_NAME.Home} timeout={1500} />
      </Col>
    );
  }

  return (
    <Scroll flex1>
      <LeftSidebar272
        renderSidebarContent={() => {
          return (
            <Col>
              <Row marginBottom={showMenu ? 18 : 0} onPress={() => breakpoint === 'xs' && setShowMenu(!showMenu)}>
                {Boolean(breakpoint === 'xs') && (
                  <Col marginRight12>
                    <ICON.BurgerMenu />
                  </Col>
                )}
                <Col flex1 onPress={() => navigate(SCREEN_NAME.CMSHome)}>
                  <Text h6 >CMS</Text>
                </Col>
                {Boolean(!showMenu && props.title && breakpoint === 'lg') && <Text fontSize12 numberOfLines1>{props.title}</Text>}
              </Row>
              {Boolean(showMenu) && displayMenus.map((val, i) => (
                <Col
                  key={'menu-'+val.name}
                  paddingVertical6
                  onPress={() => !!val.screen && navigate(val.screen) }
                >
                  <Text
                    body1
                    semiBold={route.name === val.screen}
                    colorMain={route.name === val.screen}
                  >
                    {val.name}
                  </Text>
                </Col>
              ))}
            </Col>
          );
        }}
        renderMainContent={() => {
          return (
            <Col>
              {props.children}
            </Col>
          );
        }}
      />
    </Scroll>
  );
});

export default CMSLayout;
