import { useState, useEffect, useCallback } from 'react';
import { useNavFunc } from 'navigation';
import Store from 'store';
import { ERROR } from 'const';

// these options should not change after set
interface IFetchOptions {
  force?: boolean,
  fetchOnFocus?: boolean,
}

export const createFetcher = (id, type) => {
  const bApi = Store.Book;
  const uApi = Store.User;
  const qApi = Store.Quotes;
  const wApi = Store.Writing;
  const gApi = Store.Group;
  const mApi = Store.Message;
  const mangaApi = Store.Manga;

  const fetcher = useCallback(async () => {
    let res, err;
    if (type === 'book') {
      [res, err] = await bApi.detailBook(id);
    } else if(type === 'bookshelf') {
      [res, err] = await bApi.detailBookshelf(id);
    } else if (type === 'user') {
      [res, err] = await uApi.detail(id);
      if (!!res && !!res.data && !!res.friends) {
        res.data.friends = res.friends;
      }
      if (!!res && !!res.data && !!res.friends) {
        res.data.bookshelves = res.bookshelves;
      }
    } else if (type === 'quote') {
      [res, err] = await qApi.detail(id);
    } else if (type === 'writing') {
      [res, err] = await wApi.detail(id);
    } else if (type === 'manga') {
      [res, err] = await mangaApi.detail(id);
    } else if (type === 'group-post' && !!id && id.includes('_')) {
      const parts = id.split('_');
      [res, err] = await gApi.detailPost(parts[0], parts[1]);
    } else if (type === 'group-feed-detail') {
      [res, err] = await gApi.feed({ id, page: 1, pageSize: 1, options: {} });
    } else if (type === 'conversation') {
      [res, err] = await mApi.conversationDetail(id);
      // console.log('res conversation', res);
    } else if (type === 'feed') {
      [res, err] = await uApi.getFeedDetail(id);
    }

    return [res, err];
  }, [id, type]);
  return fetcher;
}

type TPostType = 'book' | 'user' | 'quote' |
  'writing' | 'manga' | 'group-post' | 'group-feed-detail' |
  'conversation' | 'bookshelf' | 'feed' |
  'manga-chapter-extra-info';

export const usePreDataOrFetchApi = <T>(
  id : string, type : TPostType,
  options : IFetchOptions = { force: false, fetchOnFocus: false },
) : [T, (v: T) => void, { fetcher: Function, fetchError: string }] => {
  
  const { getScreenPreData, setScreenPreData, navigation } = useNavFunc();
  let preData = getScreenPreData(id);
  // console.log('preData', preData);
  const [data, setData] = useState(preData);
  const [errorMes, setErrorMes] = useState('');

  const rawFetcher = createFetcher(id, type);

  const fetcher = useCallback(async () => {
    const [res, err] = await rawFetcher();
    if (err) setErrorMes(ERROR.parseError(err))
    else if (res.error) setErrorMes(ERROR.parseError(res.error))
    else if (!!res && !!res.data) {
      setData(res.data);
      setScreenPreData(res.data);
    }
  }, [rawFetcher]);

  useEffect(() => {
    if (!id) return;
    setErrorMes('');
    if (type === 'user') setData(undefined);
    preData = getScreenPreData(id);
    // console.log('options', options, preData);
    if (options.fetchOnFocus) {
      const unsubscribe = navigation.addListener('focus', () => {
        if (!id) return;
        fetcher();
      });
      if ((!!data && data.id !== id)) {
        fetcher();
      }
      return unsubscribe;
    }

    if (options.force || !preData || (!!data && data.id !== id)) {
      fetcher();
    } else if (type === 'user' && !!preData) {
      setData(preData);
    }
  }, [id, fetcher]);
  return [data, setData, { fetcher, fetchError: errorMes }];
};
