import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Col, Text, Scroll, Img, Row } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { VarHelper } from 'helpers';
import { SCREEN_NAME, STYLES } from 'const';
import {
  Header, Button, MainContainer, 
  RatioCol, GroupItem, GroupDetailLayout, Input, useSubmitUIState, Tooltip, showModal,
  ControlledUploaderUI,
  YoutubeEmbed, createFetcher,
  Popup
} from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';
import { IBook, IGroupFeed, IGroupPost, ILocalFileUpload } from 'type';
import { useNavFunc } from 'navigation';
import Svg, { Path } from "react-native-svg";

import ModalAddBook from './AddBookModalWithSuggestion';
import ModalAddYoutube from './AddYoutubeModal';
import PostFeedOrder from './PostFeedOrder';

interface IGroupDetailChildrenProps {
  groupFeedData: IGroupFeed,
  getPage?: any,
  onNewPostAdded?: any,
  onChangeFeedOrder?: any,
  editMode?: boolean,
  data?: IGroupPost,
}

const GroupDetailAddPost = (props: IGroupDetailChildrenProps) => {
  const { navigate, goBackOrTo, getScreenPreData } = useNavFunc();
  const [mode, setMode] = useState<'create-button' | 'form'>('form');
  const [showImage, setShowImage] = useState(false);
  const [content, setContent] = useState('');
  const [book, setBook] = useState<IBook>();
  const [imagePost, setImagePost] = useState<ILocalFileUpload>();
  const [youtubeId, setYoutubeId] = useState('');
  const [isPinned, setIsPinned] = useState(false);
  const [{ loading, errorMes, btnText }, setSubmitUI] = useSubmitUIState();

  const [{}, gApi] = Store.Group.createStore();
  const [{}, bApi] = Store.Book.createBookStore();
  const [{}, mApi] = Store.Misc.createMiscStore();
  const imgUploaderRef = useRef<{ getUploadedUrl: Function }>();

  useEffect(() => {
    if (props.editMode && !!props.data) {
      setContent(props.data.content);
      if (props.data.tagBooks?.length > 0) {
        let bookData = getScreenPreData(props.data.tagBooks[0].id);
        if (!!bookData) setBook(bookData);
        else bApi.detailBook(props.data.tagBooks[0].id).then(([res, err]) => {
          if (!!res && !!res.data) setBook(res.data);
        });
      }
      if (props.data.images?.length > 0) {
        setImagePost({ path: props.data.images[0], mime: '', name: '', raw: undefined });
        setShowImage(true);
      }
      if (props.data.videos?.length > 0) {
        setYoutubeId(props.data.videos[0]);
      }
      if (props.data.isPinned) {
        setIsPinned(props.data.isPinned);
      }
    }
  }, [props.editMode, props.data])

  const showModalAddBook = () => {
    showModal({
      component: (
        <ModalAddBook onSelectBook={setBook} />
      )
    })
  };

  const showModalAddYoutube = () => {
    // if (youtubeId) return setShowImage(false);
    showModal({
      component: (
        <ModalAddYoutube
          currentId={youtubeId}
          onSelectYoutube={(newId) => {
            setYoutubeId(newId);
            setShowImage(false);
          }}
        />
      )
    })
  };

  const onPressGoToDetail = () => {
    if (!book) return;
    navigate(SCREEN_NAME.BookDetail, { id: book.id, name: VarHelper.toSlug(book.name) })
  }

  const submit = async () => {
    if (!props.groupFeedData?.detail?.id) return Popup.showError(ERROR.co_error);
    if (!content) return Popup.showError(ERROR.co_fill_all_required_form);
    setSubmitUI({ loading: true });
    const [image] = !imagePost ? [''] : await mApi.uploadLocal(imagePost);
    let res, err;
    if (props.editMode && !!props.data) {
      [res, err] = await gApi.updatePost(props.groupFeedData?.detail?.id, {
        id: props.data.id,
        content,
        images: !!image && !!showImage ? [image] : [],
        tagBooks: !!book ? [{ id: book.id, name: book.name, image: book.images && book.images.length > 0 ? book.images[0] : '' }] : [],
        videos: !!youtubeId && !showImage ? [youtubeId] : [],
        visibility: 'public',
        isPinned,
      });
    } else {
      [res, err] = await gApi.addPost(props.groupFeedData?.detail?.id, {
        content,
        images: !!image && !!showImage ? [image] : [],
        tagBooks: !!book ? [{ id: book.id, name: book.name, image: book.images && book.images.length > 0 ? book.images[0] : '' }] : [],
        videos: !!youtubeId && !showImage ? [youtubeId] : [],
      });
    }
    if (err) return setSubmitUI({ loading: false, errorMes: ERROR.parseError(err) });
    if (res.error) return setSubmitUI({ loading: false, errorMes: ERROR.parseError(res.error) });
    setSubmitUI({ loading: false, errorMes: '' });
    setShowImage(false);
    setImagePost(undefined);
    setContent('');
    setBook(undefined);
    setYoutubeId('');
    if (props.editMode) {
      setSubmitUI({ loading: false, errorMes: '', btnText: 'Thành công' });
      setTimeout(() => {
        setSubmitUI({ loading: false, errorMes: '', btnText: '' });
        goBackOrTo(SCREEN_NAME.GroupDetail, { id: props.groupFeedData?.detail?.id, name: VarHelper.toSlug(props.groupFeedData?.detail?.name) });
      }, 1000);
    } else {
      props.onNewPostAdded && props.onNewPostAdded(res.data);
    }
  };

  return (
    <Col>
      {Boolean(mode === 'create-button') ? (
        <Row justifyContent="space-between">
          <Button small solid text="Thêm bài" width100 height38 onPress={() => setMode('form')} />
        </Row>
      ) : (
        <>
          <Col borderRadius10>
            <Input
              multiline
              inputStyle={{ padding: 12, backgroundColor: 'white', borderRadius: 10, }}
              label=""
              required
              value={content}
              onChange={setContent}
              placeholder="Hãy viết gì đó.."
            />
          </Col>
          {Boolean(book) && (
            <Col marginTop24 bgWhite borderRadius10 shadow>
              <Row height192 stretch padding24 marginHorizontal24 borderRadius4 borderWidth1 borderColor={'#ECF0EF'}>
                <RatioCol ratio={100 / 160} width={100} onPress={onPressGoToDetail}>
                  <Img source={{ uri: book?.images && book?.images?.length > 0 ? book?.images[0] : '' }} width100p height100p />
                </RatioCol>
                <Col flex1 paddingLeft16>
                  <Col onPress={onPressGoToDetail}>
                    <Text numberOfLines={2} subtitle1 marginBottom8>{book?.name}</Text>
                    <Text numberOfLines={1} body2 marginBottom16>{book?.authors?.length > 0 ? book.authors[0].name : ''}</Text>

                    <Text numberOfLines={2} body2 marginBottom16>{book?.content}</Text>
                  </Col>

                  <Button smal outline width141 height33 text='Bỏ sách' onPress={() => setBook(undefined)} />
                </Col>
              </Row>
            </Col>
          )}
          {Boolean(showImage) ? (
            <Col marginTop24>
              <ControlledUploaderUI
                text="Tải ảnh"
                subText="(1200x512)"
                aspectRatio={1200/512}
                width={'100%'}
                value={imagePost}
                onChange={setImagePost}
                ref={imgUploaderRef}
              />
            </Col>
          ) : Boolean(youtubeId) ? (
            <Col marginTop24>
              <YoutubeEmbed id={youtubeId} ratio={16/9} width='100%' />
            </Col>
          ) : null}
          <Col marginTop24>
            {Boolean(errorMes) && <Text textAlign="right" marginBottom4 color={COLOR.DANGER_500}>{errorMes}</Text>}
            <Row justifyContent="space-between">
              <Row>
                <Tooltip title="Sách">
                  <Col onPress={showModalAddBook} width44 height44 borderRadius22 backgroundColor="#ECF0EF" middle>
                    <SvgBook />
                  </Col>
                </Tooltip>

                <Tooltip title="Ảnh">
                  <Col onPress={() => setShowImage(v => !v)} marginLeft16 width44 height44 borderRadius22 backgroundColor="#ECF0EF" middle>
                    <SvgImage />
                  </Col>
                </Tooltip>

                <Tooltip title="Youtube">
                  <Col onPress={showModalAddYoutube} marginLeft16 width44 height44 borderRadius22 backgroundColor="#ECF0EF" middle>
                    <SvgVideo />
                  </Col>
                </Tooltip>
              </Row>
              <Row>
                <Button onPress={() => {
                  if (props.editMode) {
                    goBackOrTo(SCREEN_NAME.GroupDetail, { id: props.groupFeedData?.detail?.id, name: VarHelper.toSlug(props.groupFeedData?.detail?.name) });
                  } else {
                    setContent('');
                  }
                }} small outline text="Hủy bỏ" paddingHorizontal12 height38  />
                <Button loading={loading} small solid text={btnText || (props.editMode ? "Cập nhật" : "Thêm bài")} paddingHorizontal12 height38 marginLeft16 onPress={submit} />
              </Row>
            </Row>
          </Col>
        </>
      )}
    </Col>
  );
};

const SvgBook = (props) => {
  return (
    <Svg
      width={18}
      height={21}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M17.75 14.563V1.437A.95.95 0 0016.812.5H4C1.93.5.25 2.18.25 4.25v12.5c0 2.07 1.68 3.75 3.75 3.75h12.813c.507 0 .937-.39.937-.938v-.625a.95.95 0 00-.352-.703c-.195-.625-.195-2.343 0-2.93a.967.967 0 00.352-.742zM5.25 5.733c0-.117.078-.234.234-.234h8.282c.117 0 .234.117.234.234v.782c0 .156-.117.234-.234.234H5.484c-.156 0-.234-.078-.234-.234v-.782zm0 2.5c0-.117.078-.234.234-.234h8.282c.117 0 .234.117.234.234v.782c0 .156-.117.234-.234.234H5.484c-.156 0-.234-.078-.234-.234v-.782zM15.133 18H4c-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25h11.133c-.078.703-.078 1.836 0 2.5z"
        fill="#63B199"
      />
    </Svg>
  )
};
const SvgImage = (props) => {
  return (
    <Svg
      width={20}
      height={15}
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M18.125 15C19.141 15 20 14.18 20 13.125V1.875C20 .859 19.14 0 18.125 0H1.875C.82 0 0 .86 0 1.875v11.25C0 14.18.82 15 1.875 15h16.25zM4.375 2.187c1.172 0 2.188 1.016 2.188 2.188 0 1.21-1.016 2.188-2.188 2.188a2.184 2.184 0 01-2.188-2.188c0-1.172.977-2.188 2.188-2.188zM2.5 12.5v-1.875l2.773-2.773a.45.45 0 01.665 0L8.124 10l5.273-5.273a.45.45 0 01.665 0L17.5 8.125V12.5h-15z"
        fill="#63B199"
      />
    </Svg>
  )
}
const SvgVideo = (props) => {
  return (
    <Svg
      width={22}
      height={15}
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M21.195 2.383A2.657 2.657 0 0019.32.469C17.64 0 11 0 11 0S4.32 0 2.64.469A2.657 2.657 0 00.767 2.383c-.47 1.64-.47 5.156-.47 5.156s0 3.477.47 5.156c.234.938.976 1.64 1.875 1.875C4.32 15 11 15 11 15s6.64 0 8.32-.43a2.605 2.605 0 001.875-1.875c.47-1.68.47-5.156.47-5.156s0-3.516-.47-5.156zm-12.383 8.32V4.375l5.547 3.164-5.546 3.164z"
        fill="#63B199"
      />
    </Svg>
  )
};

export default GroupDetailAddPost;