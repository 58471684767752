import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Col, usePropsStyle } from 'react-quick-style-components';

const Quill = require('quill');
import { COLOR, ERROR, READISM_HTTP_LINK } from 'const';
import { Uploader } from '../../widgets';
import { DomHelper } from 'helpers';

interface IRichTextEditorProps {
  id?: string, // not support change dynamicly
  placeholder?: string,
  height?: number,
  onChange?: any,
  allowLink?: boolean,
  forNews?: boolean,
  [additionProps: string]: any,
}

(() => {
  const fontCss = `
    .ql-toolbar.ql-snow {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  `;
  const style = document.createElement('style')
  style.textContent = fontCss;
  document.head.append(style);
})();

var Link = Quill.import('formats/link');

class MyLink extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    // node.removeAttribute('href');
    if(value.startsWith(READISM_HTTP_LINK)) {
      node.setAttribute('href', value.replace(READISM_HTTP_LINK, ''));
    } else {
      node.setAttribute('href', '');
      // node.text = '[Đường dẫn bị gỡ bỏ]';
      // console.log(node);
    }
    node.removeAttribute('target');
    return node;
  }
};

// Quill.register(MyLink);

let didImportCSS = false;

const RichTextEditor2 = forwardRef((props : IRichTextEditorProps, ref) => {
  const id = props.id || 'editor';
  const propsStyle = usePropsStyle(props);
  const quillRef = useRef<any>();
  const quillOnTextChangeEvent = useRef<any>();
  const uploaderRef = useRef<{ openFileDialog: Function, uploadLocal: Function }>();

  const imageHandler = async () => {
    const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.click();
		input.onchange = async () => {
			const file = input.files[0];
			const [link, err] = await uploaderRef.current?.uploadLocal({
        name: file.name,
        path: '',
        mime: file.type,
        raw: file,
      });
      if (err) {
        return alert(ERROR.parseError(err));
      } else {
        console.log("link", link);
        const range = quillRef.current.getSelection();
        quillRef.current.insertEmbed(range.index, 'image', link);
      }
		}
  };

  useImperativeHandle(ref, () => ({
    getHTML: () => quillRef.current?.container.firstChild.innerHTML,
    // getHTML: () => quillRef.current?.container.querySelector('.ql-editor').innerHTML,
    getJSON: () => quillRef.current?.getContents(),
    getText: () => quillRef.current?.getText(),
    setJSON: (ops) => quillRef.current?.setContents(ops, 'api'),
  }));

  useEffect(() => {
    if (!props.allowLink) {
      Quill.register(MyLink);
    } else {
      Quill.register(Link);
    }
  }, [props.allowLink])

  useEffect(() => {
    if (!didImportCSS) {
      DomHelper.loadjscssfile("https://cdn.quilljs.com/1.3.6/quill.snow.css", "css");
      didImportCSS = true;
    }
  }, []);

  useEffect(() => {
    quillRef.current = new Quill('#'+id, {
			modules: {
				toolbar: {
					container: [
						[{ header: props.forNews ? [1, 2, 3, 4, 5, 6, false] : [1, 2, false] }],
            props.forNews ? [{ color: [] }, { background: [] }] : [],
						[{ 'list': 'ordered' }, { 'list': 'bullet' }],
						['bold', 'italic', 'underline'],
						[{ 'align': [] }],
						// ['image'],
						props.allowLink ? ['link', 'image'] : ['image'],
						['clean']
					],
					handlers: {
						image: imageHandler
					}
				}
			},
			placeholder: props.placeholder || 'Hãy viết gì đó...',
			theme: 'snow'
		});
    if (typeof props.onChange === 'function') {
      quillOnTextChangeEvent.current = (delta, oldDelta, source) => {
        props.onChange(delta, oldDelta, source);
      };
      quillRef.current.on('text-change', quillOnTextChangeEvent.current);
    }
    return () => {
      if (typeof props.onChange === 'function') {
        quillRef.current.off('text-change', quillOnTextChangeEvent.current);
      }
    };
  }, []);


  return (
    <Col
      style={propsStyle}
    >
      <div id={id} style={{ height: props.height || 250, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} />
      <Uploader
        ref={uploaderRef}
        onChangeLocal={(newLocal) => undefined}
      />
    </Col>
  );
});

export default RichTextEditor2;