import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
    >
      <Path
        d="M9.496 3.629l-8.144 8.144L1 15.113c-.059.44.322.82.762.762l3.34-.352 8.144-8.144-3.75-3.75zm6.064-.557l-1.757-1.758c-.528-.556-1.436-.556-1.993 0l-1.64 1.641 3.75 3.75 1.64-1.64c.557-.557.557-1.465 0-1.993z"
        fill={props.fill ||"#498270"}
      />
    </Svg>
  )
}

export default SvgComponent
