import { COLOR, ERROR } from 'const';
import React, { useState, useEffect, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Col, Text, Row, Scroll, Img, usePropsStyle } from 'react-quick-style-components';
import { StyleSheet, TextInput } from 'react-native';
import { useNavFunc } from 'navigation';
import { Input, Select, Popup, UseMemo, Pagination } from '../elements';
import { ICON } from 'assets';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { ISearchAndFilterOptions } from 'type';
interface ICMSTableLayoutProps {
  initialPage: number,
  onChangePage?(newPage: number): void,
  fetcher?(page: number, options?: ISearchAndFilterOptions): Promise<any>,
  remover?(id: string, name: string): Promise<any>,
  featuredMaker?(id: string, isFeatured: boolean): Promise<any>,
  tableSchema: Array<{
    title: string,
    type: string,
    key: string | Function,
    width?: number,
    valueStyle?: any,
  }>,
  columnMinWidth?: number,
  fetchWhenFocus?: boolean,
  pageSize?: number,
  searchAndFilter?: ISearchAndFilterOptions,
  hasFeatured?: boolean,
  onRowPress?(val: any): void,
}

const CMSTableLayout = forwardRef((props: ICMSTableLayoutProps, ref) => {
  const { navigation } = useNavFunc();
  const propStyles = usePropsStyle(props);
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(props.initialPage);
  const [errorMessage, setErrorMessage] = useState('');

  const [showSearchAndFilterForm, setShowSearchAndFilterForm] = useState(false);
  const [options, setOptions] = useState<ISearchAndFilterOptions>({});
  const sortOptions = [{ label: 'Giảm dần', value: 'DESC' }, { label: 'Tăng dần', value: 'ASC' }];

  const getData = useCallback(async (p = 1, o = undefined) => {
    if (!props.fetcher) return;
    // remove undefiend sort and filter
    const searchAndFilterOptions = o || options;
    if (searchAndFilterOptions.sort) searchAndFilterOptions.sort = searchAndFilterOptions.sort.filter(val => !!val);
    if (searchAndFilterOptions.filter) searchAndFilterOptions.filter = searchAndFilterOptions.filter.filter(val => !!val);

    const [res, err] = await props.fetcher(p, searchAndFilterOptions);
    if (err) return setErrorMessage(ERROR.parseError(err));
    if (res.error) return setErrorMessage(ERROR.parseError(res.error));
    console.log(res.data);
    setList(res.data.rows ? res.data.rows : res.data);
    setCount(res.data.count ? res.data.count : 0);
    setPage(p);
  }, [options]);

  const clearOptions = useCallback(() => {
    setOptions({});
    getData(1, {});
  }, []);

  const changePage = (p) => {
    getData(p);
  }

  useEffect(() => {
    if (props.fetchWhenFocus) {
      const unsubscribe = navigation.addListener('focus', () => {
        getData();
      });
      return unsubscribe;
    } else {
      getData();
    }
  }, []);

  const allPossiblePages = list.length !== 0 && count > 0 ? Math.ceil(count / (props.pageSize || list.length)) : 1;
  
  useImperativeHandle(ref, () => ({
    getData: () => getData(page),
  }));

  return (
    <Scroll horizontal width100p style={[propStyles]}>
      <Col width100p>
        {Boolean(props.searchAndFilter) && (
          <Row marginBottom8 alignItems="flex-start">
            <Row
              borderRadius8 height30 paddingHorizontal16 middle
              onPress={() => setShowSearchAndFilterForm(true)}
              backgroundColor={COLOR.PRI_200} onHoverStyle={{ backgroundColor: COLOR.PRI_300 }}
            >
              <ICON.Search width={12} height={12} fill={COLOR.FONT} />
              <FontAwesome name="filter" size={12} color={COLOR.FONT} style={{ marginLeft: 4 }} />
              <MaterialCommunityIcons name="sort" size={12} color={COLOR.FONT} style={{ marginLeft: 4 }} />
              <Text marginLeft4>Bộ lọc</Text>
            </Row>
            {Boolean(showSearchAndFilterForm) && (
              <Col flex1 marginLeft8>
                {Boolean(props.searchAndFilter.search && props.searchAndFilter.search[0]) && (
                  <Row padding4 borderWidth1 borderColor={COLOR.BORDER} borderRadius8 height30 marginBottom8>
                    <Text>Tìm kiếm {props.searchAndFilter.search[0].label}:</Text>
                    <TextInput
                      style={{ flex: 1, height: '100%', paddingHorizontal: 8 }}
                      value={!!options.search && options.search[0] ? options.search[0].value : ''}
                      onChangeText={(newText) => {
                        const newOptions = { ...options };
                        if (!newOptions.search) {
                          newOptions.search = [{ value: newText, label: props.searchAndFilter.search[0].value }]
                        } else {
                          newOptions.search[0].value = newText;
                        }
                        setOptions(newOptions);
                      }}
                    />
                  </Row>
                )}
                <Row style={{ flexWrap: 'wrap' }} marginBottom8>
                  {Boolean(props.searchAndFilter.filter && props.searchAndFilter.filter.length > 0) && (
                    props.searchAndFilter.filter.map((val, i) => (
                      <Select
                        key={'filter-' + i}
                        width={185 + val.label.length * 5}
                        marginBottom8
                        marginRight8
                        height={30}
                        label=""
                        placeholder={"Lọc theo: " + val.label}
                        options={val.options}
                        value={(() => {
                          if (!options.filter || !options.filter[i]) return { value: '', label: "Lọc theo: " + val.label };
                          const value = options.filter[i].value;
                          return val.options.find(item => item.value === value);
                        })()}
                        onChange={newValue => {
                          const newOptions = { ...options };
                          if (!newOptions.filter) {
                            newOptions.filter = [{ value: newValue.value, label: val.value }]
                          } else {
                            if (!newOptions.filter[i]) newOptions.filter[i] = { value: newValue.value, label: val.value };
                            else newOptions.filter[i].value = newValue.value;
                          }
                          setOptions(newOptions);
                        }}
                      />
                    ))
                  )}
                  {Boolean(props.searchAndFilter.sort && props.searchAndFilter.sort.length > 0) &&
                    props.searchAndFilter.sort.map((val, i) => (
                      <Select
                        key={'sort-' + i}
                        width={185 + val.label.length * 5}
                        marginBottom8
                        marginRight8
                        height={30}
                        label=""
                        placeholder={"Sắp xếp: " + val.label}
                        options={sortOptions}
                        value={(() => {
                          if (!options.sort || !options.sort[i]) return { value: '', label: "Sắp xếp: " + val.label };
                          const value = options.sort[i].orderType;
                          return sortOptions.find(item => item.value === value);
                        })()}
                        onChange={newValue => {
                          const newOptions = { ...options };
                          if (!newOptions.sort) {
                            newOptions.sort = [{ value: val.value, orderType: newValue.value }]
                          } else {
                            if (!newOptions.sort[i]) newOptions.sort[i] = { value: val.value, orderType: newValue.value };
                            else newOptions.sort[i].orderType = newValue.value;
                          }
                          setOptions(newOptions);
                        }}
                      />
                    ))}
                </Row>
                <Row>
                  <Col onPress={() => getData(1)} width100 height30 borderRadius8 backgroundColor={COLOR.PRI_200} onHoverStyle={{ backgroundColor: COLOR.PRI_300 }} middle>
                    <Text>Áp dụng</Text>
                  </Col>
                  <Col marginLeft8 onPress={clearOptions} width100 height30 borderRadius8 backgroundColor={COLOR.DANGER_200} onHoverStyle={{ backgroundColor: COLOR.DANGER_300 }} middle>
                    <Text>Xóa bộ lọc</Text>
                  </Col>
                </Row>

              </Col>
            )}

          </Row>
        )}

        {Boolean(count > 0) && <Text marginBottom16>Tổng số: {count}</Text>}

        <Row bgMain minHeight40 padding8 borderBottomWidth1 borderBottomColor={COLOR.BORDER} width100p>
          {props.tableSchema.map((val, i) => (
            <Col key={'title-' + val.title} width={val.width || props.columnMinWidth || 100}>
              <Text width100p bold colorWhite>{val.title}</Text>
            </Col>
          ))}
          {Boolean(props.hasFeatured) && (
            <Col width={30} />
          )}
          {Boolean(props.remover) && (
            <Col width={30} />
          )}
        </Row>
        {Boolean(errorMessage) && <Text color={COLOR.DANGER_500}>{errorMessage}</Text>}
        <UseMemo deps={[list, page, allPossiblePages]}>
          {list.length === 0 ? (
            <Col height300 middle>
              <Text>Chưa có nội dung</Text>
            </Col>
          ) : (
            <>
              {list.map((val, i) => (
                <Row
                  minHeight40 padding8 borderBottomWidth={0.5} borderBottomColor={COLOR.BORDER} key={'row-' + val.id}
                  onPress={!props.onRowPress ? undefined : () => props.onRowPress(val)}
                  onHoverStyle={{ backgroundColor: COLOR.GRAY_200 }}
                  useNativeStyleProps
                >
                  {props.tableSchema.map((schema, j) => {
                    const value = typeof schema.key === 'string' ? val[schema.key] : schema.key(val);
                    return (
                      <Col key={'column-' + j + val.id} width={schema.width || props.columnMinWidth || 100}>
                        {Boolean(schema.type === 'text') ? (
                          <Text width100p numberOfLines={1}>{value}</Text>
                        ) : Boolean(schema.type === 'image') ? (
                          <Img source={{ uri: value }} style={[styles.imgColumn, schema.valueStyle]} resizeMode="cover" />
                        ) : null}

                      </Col>
                    );
                  })}
                  {Boolean(props.hasFeatured) && (
                    <Col
                      width={30} middle
                      onPress={async () => {
                        if (props.featuredMaker) {
                          await props.featuredMaker(val.id, !val.isFeatured);
                          await getData(page);
                        }
                      }}
                    >
                      <ICON.Star width={20} height={20} fill={val.isFeatured ? COLOR.MAIN : COLOR.GRAY_200} />
                    </Col>
                  )}
                  {Boolean(props.remover) && (
                    <Col
                      width={30} middle
                      onPress={async () => {
                        if (val.visibility === 'trash') {
                          return Popup.show('Đã nằm sẵn trong thùng rác');
                        }
                        await props.remover(val.id, val.name);
                        await getData(page);
                      }}
                    >
                      <ICON.Trash fill={COLOR.MAIN} />
                    </Col>
                  )}
                </Row>
              ))}
              <Pagination
                page={page}
                changePage={changePage}
                allPossiblePages={allPossiblePages}
              />
            </>
          )}
        </UseMemo>
      </Col>
    </Scroll>
  );
});

const styles = StyleSheet.create({
  imgColumn: {
    width: 50,
    height: 50,
  },
});

export default CMSTableLayout;
