import React from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { Octicons } from '@expo/vector-icons';
import { COLOR } from 'const';
import CommentModal from './CommentModal';
import { showModal, HTMLView } from 'components';
import { useNavFunc } from 'navigation';
import { VarHelper } from 'helpers';

interface IWritingImageItemWithCommentProps {
  writingChapterId: string,
  writingId: string,
  html: string,
  extraData: {
    id: string,
    numberOfComments: number,
    writingChapterId: string,
    writingId: string,
  },
}

const WritingImageItemWithComment = (props : IWritingImageItemWithCommentProps) => {
  const { navigation } = useNavFunc();

  const showCommentModal = () => {
    showModal({
      component: (
        <CommentModal
          mangaId={props.writingId}
          mangaChapterId={props.writingChapterId}
          id={VarHelper.genWritingParagraphId(props.html)}
          html={props.html}
          navigation={navigation}
        />
      ),
      // noWrapper: true,
    })
  }

  return (
    <Col onHoverStyle={{ opacity: 1, backgroundColor: COLOR.PRI_100 }} useNestedHover useNativeStyleProps>
      <HTMLView content={props.html} />
      <Col
        absolute top0 right={-40} width40 height40 middle backgroundColor="rgba(255,255,255,0.5)" borderRadius4
        onPress={showCommentModal}
        opacity={props.extraData?.numberOfComments > 0 ? 1 : 0}
        onHoverStyle={{ opacity: 1 }}
      >
        {Boolean(props.extraData?.numberOfComments > 0) && (
          <Text bold colorMain marginRight4>{props.extraData.numberOfComments}</Text>
        )}
        <Octicons name="comment-discussion" size={24} color={COLOR.MAIN} />
      </Col>
    </Col>
  )
};

export default WritingImageItemWithComment;