import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Col, Row, Text } from 'react-quick-style-components';
import { COLOR, DEVICE_INFO, SCREEN_NAME } from 'const';
import { showModal, hideModal } from '../elements';
import { ICON } from 'assets';

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const ShareModal = ({ link }) => {
  const { width, height } = useWindowDimensions();
  const [didCopy, setDidCopy] = useState(false);
  const containerWidth = width < 768 ? width * 0.9 : width / 2;
  const containerHeight = width < 768 ? height * 0.8 : height / 2;

  if (!link) return null;
  const fullLink = link.indexOf('http') === 0 ? link : `${window.location.protocol}//${window.location.host}${link}`;

  const shareFacebook = () => {
    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${fullLink}`;
    window.open(facebookLink, 'popupWindow', 'height=555,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
  };

  const copyLink = () => {
    copyToClipboard(fullLink);
    setDidCopy(true);
    setTimeout(() => {
      setDidCopy(false);
    }, 1500);
  };

  return (
    <Col width={containerWidth} height={containerHeight} bgWhite borderRadius10 onPress={() => {}} activeOpacity={1} middle>
      <Text center subtitle1 marginBottom24>Chia sẻ bài</Text>
      <Text marginBottom24 width="90%" center padding10 borderWidth1 borderColor={COLOR.BORDER} borderRadius4>{fullLink}</Text>
      <Row width="90%" middle>
        <Col width250 middle bgMain padding10 borderRadius4 margin8 onPress={shareFacebook}>
          <Text colorWhite>Đăng lên Facebook</Text>
        </Col>
        <Col width250 middle borderWidth1 borderColor={COLOR.MAIN} padding10 borderRadius4 margin8 onPress={copyLink}>
          <Text colorMain>{didCopy ? 'Đã sao chép' : 'Sao chép liên kết'}</Text>
        </Col>
      </Row>
      <Col absolute top20 right20 width30 height30 middle onPress={hideModal}>
        <ICON.Close />
      </Col>
    </Col>
  );
}

export const useShareModal = ({ link }) => {

	const showShareModal = (e) => {
    // console.log({ left, top });
		showModal({
			component: (
				<ShareModal
          link={link}
        />
			),
		})
	};

	return {
		showShareModal,
		hideShareModal: hideModal,
	};
};