import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Col, Text, Scroll, Img, Row } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import { Header, Button, MainContainer, RatioCol, WritingItem, Pagination } from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';
import { VarHelper } from 'helpers';

const WritingViewAll = () => {
  const { navigate, navigation } = useNavFunc();
  const { h2 } = useFontSizeStyle();
  const [tab, setTab] = useState<'all' | 'trending'>('all');
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);

  const [{}, wApi] = Store.Writing.createStore();

  const TABS = [
    // { label: 'Phổ biến', key: 'trending' },
    { label: 'Mới nhất', key: 'all' },
  ];

  const pagingRef = useRef({ count: 0 })

  const getData = useCallback(async (page = 1) => {
    const [res, err] = await wApi.getList({
      visibility: 'public', page, pageSize: 20,
    });
    if (!!res && res.data && res.data.rows) {
      pagingRef.current.count = res.data.count;
      setList(res.data.rows);
      setPage(page);
    }
  }, [tab, list]);

  const changePage = (p) => {
    getData(p);
    window.scrollTo({ top: 0, left: 0 });
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getData();
    });
    return unsubscribe;
  }, [tab, getData]);

  const allPossiblePages = list.length !== 0 && pagingRef.current.count > 0 ? Math.ceil(pagingRef.current.count / (20 || list.length)) : 1;

  return (
    <Col flex1 backgroundColor="white">
      <Header />
      <Col flex1>
        <Scroll flex1>
          <MainContainer>
            <RatioCol ratio={1140 / 249} width="100%" marginTop16 bgMain borderRadius16>
              <Img source={IMAGE.WritingBanner} style={STYLES.all100p} />
              <Row absoluteFill paddingHorizontal='5%'>
                <Col alignItems="flex-start">
                  <ICON.ReadismWithTextLogo white height={27} />
                  <Text style={h2} colorWhite>Viết</Text>
                </Col>
              </Row>
            </RatioCol>

            <Row height68 marginTop12 stretch paddingHorizontal12 borderRadius4 borderWidth1 borderColor={COLOR.GRAY_500}>
              {TABS.map((tabItem, i) => {
                const isActive = tabItem.key === tab;
                const borderStyle = isActive ? { borderBottomWidth: 5, borderBottomColor: COLOR.MAIN } : {};
                return (
                  <Col
                    key={tabItem.key} {...borderStyle} paddingHorizontal16 middle
                    onHoverStyle={{ opacity: 1 }} useNestedHover
                    onPress={() => {
                      if (!isActive) setTab(tabItem.key as any);
                    }}
                  >
                    <Text btnTextLarge onHoverStyle={{ color: COLOR.MAIN }}>{tabItem.label}</Text>
                  </Col>
                );
              })}

              <Col flex1 />

              <Col middle>
                <Row onPress={() => navigate(SCREEN_NAME.CreateWriting)} height44 bgMain borderRadius6 paddingHorizontal16 middle>
                  <ICON.PlusCircle fill='white' width={20} height={20} />
                  <Text btnTextMedium colorWhite marginLeft8>Viết mới</Text>
                </Row>
              </Col>
            </Row>

            <Row xs='100%' md='50%' lg={`${100/3}%`} padding12 marginLeft={-24} marginRight={-24}>
              {list.map((val, i) => (
                <WritingItem
                  margin12
                  key={val.id}
                  data={val}
                  onPress={() => navigate(SCREEN_NAME.WritingDetail, { id: val.id, name: VarHelper.toSlug(val.name) })}
                />
              ))}
            </Row>

            <Pagination
              page={page}
              changePage={changePage}
              allPossiblePages={allPossiblePages}
            />

          </MainContainer>
        </Scroll>
      </Col>
    </Col>
  );
};

export default WritingViewAll;