import React, { Fragment } from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { ActivityIndicator } from 'react-native';
import { COLOR } from 'const';
import ContentLoader from "react-content-loader";

interface IContentLoaderProps {
  width: number,
  height: number,
  backgroundColor?: string,
  foregroundColor?: string,
  svgViewBox?: string,
  children?: any,
}

const ContentPlaceholder = (props : IContentLoaderProps) => {
  return (
    <ContentLoader
      width={props.width}
      height={props.height}
      viewBox={props.svgViewBox}
      backgroundColor={props.backgroundColor || "#f3f3f3"}
      foregroundColor={props.foregroundColor || "#ecebeb"}
    >
      {props.children}
    </ContentLoader>
  );
};

export default ContentPlaceholder;
