import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { CMSLayout, Button, Input, Uploader, Select, RichTextEditor2, RateStars, Checkbox, Avatar } from 'components';
import { Linking } from 'react-native';
import { useNavFunc, Reset } from 'navigation';
import { useRoute } from '@react-navigation/native';
import { BOOK_VISIBILITY, COLOR, DEVICE_INFO, ERROR, SCREEN_NAME } from 'const';
import { ICON } from 'assets';
import { IReview } from 'type';
import Store from 'store';
import { VarHelper } from 'helpers';

const CMSEditReview = () => {
  const { goBackOrTo, getScreenPreData, navigation } = useNavFunc();
  const [btnText, setBtnText] = useState('');

  const [visibility, setVisibility] = useState('draft');
  const [rate, setRate] = useState(0);
  const [spoiler, setSpoiler] = useState(false);
  const [reviewData, setReviewData] = useState<IReview>();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [notExist, setNotExist] = useState(false);

  const rV = useDynamicResponsiveValue();
  const [{ }, bookApi] = Store.Book.createBookStore();
  const [{ }, rApi] = Store.Reviews.createStore();
  const editorRef = useRef<{ getHTML: Function, getJSON: Function, getText: Function, setJSON: Function }>();
  const route = useRoute();

  const id = route.params?.id;

  if (!id) {
    return (
      <Col middle flex1 bgWhite>
        <Text>Đường dẫn không hợp lệ..</Text>
        <Reset to={SCREEN_NAME.CMSHome} timeout={1500} />
      </Col>
    );
  }

  const getInitialData = async () => {
    let data: IReview = getScreenPreData(id);
    if (!data) {
      const [res, err] = await rApi.detailReview(id);
      if (err) return alert(ERROR.parseError(err));
      if (res.error) return alert(ERROR.parseError(res.error));
      data = res.data;
    }
    if (!!data) {
      setVisibility(data.visibility);
      setReviewData(data);
      setRate(data.star);
      setSpoiler(data.hasSpoiler);
      editorRef.current?.setJSON(data.contentJSON);
    } else {
      setNotExist(true);
    }

  }

  const submit = async () => {
    const content = editorRef.current?.getText();
    const contentJSON = editorRef.current?.getJSON();
    const contentHTML = editorRef.current?.getHTML();
    setLoading(true);
    const [res, err] = await rApi.updateReview({
      id,
      content,
      contentJSON,
      contentHTML,
      star: rate,
      hasSpoiler: spoiler,
      visibility,
    })
    setLoading(false);
    if (err) return setErrorMessage(ERROR.parseError(err));
    if (res.error) return setErrorMessage(ERROR.parseError(res.error));
    setBtnText('Cập nhật thành công..');
    setTimeout(() => {
      setBtnText('');
      setTimeout(() => {
        goBackOrTo(SCREEN_NAME.CMSListReview);
      }, 500)
    }, 500);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Col flex1>
      <CMSLayout title="Chỉnh sửa Đánh giá">
        <Row>
          <Col flex1>
            <Text h4></Text>
          </Col>
          <Button paddingHorizontal12 small solid text="Quay lại" onPress={() => goBackOrTo(SCREEN_NAME.CMSListReview)} />
        </Row>

        {Boolean(notExist) ? (
          <Col height300 middle>
            <Text>Nội dung không tồn tại</Text>
          </Col>
        ) : (
          <>
            <Col marginTop24>
              <Col
                onHoverStyle={{ backgroundColor: 'trasparent' }}
                onPress={() => navigation.navigate(SCREEN_NAME.CMSEditBook, { id: reviewData?.bookId, name: VarHelper.toSlug(reviewData?.bookName) })}
                paddingVerticon4
                useNestedHover
              >
                <Text h4 onHoverStyle={{ textDecoratioLine: 'underline', color: COLOR.MAIN }}>Sách: {reviewData?.bookName}</Text>
              </Col>
              <Row justifyContent="space-between" marginTop24>
                <Row alignItems="flex-start">
                  <Avatar size={48} uri={reviewData?.createdBy?.avatar} name={reviewData?.createdBy?.name} />
                  <Col marginLeft13>
                    <Text fontSize14>{reviewData?.createdBy?.name} đã đánh giá</Text>
                    <RateStars
                      marginTop8
                      width151
                      value={rate}
                      onChange={setRate}
                    />
                  </Col>
                </Row>
                <Row onPress={() => setSpoiler(!spoiler)}>
                  <Checkbox value={spoiler} onChange={setSpoiler} />
                  <Text marginLeft8 body1>Spoiler alert</Text>
                </Row>
              </Row>
              <RichTextEditor2
                ref={editorRef}
                marginTop24
              />
            </Col>

            <Row marginTop24 marginBottom200 justifyContent="space-between" alignItems="flex-start">
              <Col width150>
                <Select
                  label="Trạng thái hiển thị"
                  options={Object.keys(BOOK_VISIBILITY).map(val => ({ value: val, label: BOOK_VISIBILITY[val] }))}
                  value={{ label: BOOK_VISIBILITY[visibility], value: visibility }}
                  onChange={(v) => setVisibility(v.value)}
                />
              </Col>
              <Col height100p justifyContent="flex-end">
                <Button small solid text={btnText ? btnText : "Xác nhận"} loading={loading} paddingHorizontal12 onPress={submit} />
                {Boolean(errorMessage) && <Text color={COLOR.DANGER_500} marginTop8>{errorMessage}</Text>}
              </Col>
            </Row>
          </>
        )}

      </CMSLayout>
    </Col>
  );
};

export default CMSEditReview;