import React, { useEffect, useState } from 'react';
import { Col, Text, Row, Scroll } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME } from 'const';
import { Header, Button, LeftSidebarOneThird, Avatar, Placeholder, BookShelfItem, usePreDataOrFetchApi, LoadMoreDetect, QuoteNoCreatorItem } from 'components';
import { ICON } from 'assets';
import Store from 'store';
import { IActivity, IUserProfile } from 'type';
import UserProfile from './UserProfile';

const UserProfileAllBookshelf = () => {
  const { route, goBackOrTo, goWithPreData } = useNavFunc();
  // @ts-ignore
  const { id, name } = route.params;
  const [{ user }] = Store.User.createUserStore();
  const [{ }, bApi] = Store.Book.createBookStore();
  const [userData] = usePreDataOrFetchApi<IUserProfile>(id, 'user');
  const [list, setlist] = useState(userData?.bookshelves || []);
  const [errorMes, setErrorMes] = useState('');

  const getData = async () => {
    const [res, err] = await bApi.getBookshelfListOfUser(id);
    if (err) return setErrorMes(ERROR.parseError(err));
    if (res.error) return setErrorMes(ERROR.parseError(res.error));
    setlist(res.data);
  };

  useEffect(() => {
    getData();
  }, [id]);

  const isMe = user.id === userData?.id;
  const listDisplay = isMe ? list : bApi.computePublicBookshelfData(list);

  return (
    <UserProfile noForce noFetchOnFocus>
      {!userData ? <Col /> :
        <Scroll>
          <Col>
            <Row justifyContent="space-between">
              <Col>
                <Text btnTextLarge>TỦ SÁCH</Text>
                <Text marginTop4 body2 color={COLOR.GRAY_500}>{listDisplay.length || 0} tủ sách</Text>
              </Col>
              <Col padding4 onPress={() => goBackOrTo(SCREEN_NAME.UserProfile, { id, name })}>
                <Text colorMain subtitle1>Quay lại</Text>
              </Col>
            </Row>
          </Col>

          {!!errorMes && <Text color={COLOR.DANGER_500}>{errorMes}</Text>}

          <Row xs='50%' marginLeft={-12} marginRight={-12} padding6>
            {listDisplay.map(val => (
              <BookShelfItem
                data={val} margin6
                key={val.id}
                onPress={() => goWithPreData(SCREEN_NAME.UserProfileBookshelfDetail, { id, name, bookshelf_id: val.id }, val)}
              />
            ))}
          </Row>
        </Scroll>
      }
    </UserProfile>
  );
};

export default UserProfileAllBookshelf;