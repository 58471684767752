import React, { useState, useCallback, useRef, useEffect, memo, Fragment } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { IPost, IComment } from 'type';
import Store from 'store';
import { COLOR, DEVICE_INFO, POST_VISIBILITY, SCREEN_NAME, ERROR } from 'const';
import { Avatar, Popup, showModal, Input, hideModal, Button } from 'components/elements';
import { useSubmitUIState } from 'components/hooks';
import { useNavFunc } from 'navigation';
import { StyleSheet, TextInput, useWindowDimensions, Platform } from 'react-native';
import { TimeHelper, VarHelper } from 'helpers';

import ListLikeModal from '../ListLikeModal';
import UserName, { UserNameWithoutA } from '../UserName';
import { ICON } from 'assets';

const UpdateModal = (props) => {
  const [value, setValue] = useState(props.content)
  const [{ loading }, setUI] = useSubmitUIState();
  const { width, height } = useWindowDimensions();
  const containerWidth = width < 768 ? width * 0.9 : width / 2;
  const containerHeight = width < 768 ? height * 0.8 : height / 2;
  return (
    <Col padding24 width={containerWidth} height={containerHeight} bgWhite activeOpacity={1} borderRadius10 onPress={() => { }}>
      <Row justifyContent="space-between" marginBottom16>
        <Text fontSize20 bold>Sửa bình luận</Text>
        <Col width30 height30 middle onPress={hideModal}>
          <ICON.Close fill={COLOR.GRAY_500} width={13} height={20} />
        </Col>
      </Row>
      <Col flex1>
        <Input
          multiline
          inputStyle={{ padding: 12 }}
          placeholder=""
          label=""
          value={value}
          onChange={setValue}
        />
        <Button loading={loading} marginTop16 alignSelf="flex-end" solid large width80 height35 text="Sửa" onPress={async () => {
          setUI({ loading: true });
          await props.onUpdate(value);
          setUI({ loading: false });
          hideModal();
        }} />
      </Col>
    </Col>
  );
}

const CommentDeviceInfo = (comment) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Col alignItems='flex-end' onHoverStyle={{ opacity: 1 }} useNestedHover >
      <Col paddingVertical4 onPress={() => setShowMore(!showMore)}>
        <Text color={COLOR.GRAY_500} caption onHoverStyle={{ textDecorationLine: 'underline' }}>IP: {comment.ip}</Text>
      </Col>
      {showMore && (
        <>
          <Text caption color={COLOR.GRAY_500}>gửi từ: <Text bold color={COLOR.PRI_400}>{comment.browserName}</Text></Text>
          <Text caption color={COLOR.GRAY_500}>id thiết bị: <Text bold color={COLOR.PRI_400}>{comment.browserId}</Text></Text>
        </>
      )}
    </Col>

  );
}

const SingleComment = ({ isLast, navigate, comment, showSenderIP, onReplyThisComment, ...props }) => {
  const [content, setContent] = useState(comment.content);
  useEffect(() => {
    setContent(content);
  }, [comment.content])
  const { user } = Store.User.state;
  const [isRemoved, setIsRemoved] = useState(false);
  const canEditRemove = user.type === 'ADMIN' || user.id === comment.createdBy.id;

  const removeComment = async () => {
    const doRemove = async () => {
      const [res, err] = await Store.User.deleteComment(comment.id);
      if (err) alert(ERROR.parseError(err));
      else if (res.error) alert(ERROR.parseError(res.error));
      else setIsRemoved(true);

    };
    Popup.show(
      'Bạn có chắc chắn muốn xóa comment?',
      [
        { text: 'Không', solid: true, onPress: () => { } },
        {
          text: 'Có', solid: false, onPress: doRemove,
        }
      ],
    );
  }

  const onRequestUpdate = () => {
    showModal({
      component: (
        <UpdateModal
          content={comment.content}
          onUpdate={async (newContent) => {
            const [res, err] = await Store.User.updateComment({
              content: newContent,
              commentId: comment.id,
              image: '',
            });
            if (err) alert(ERROR.parseError(err));
            else if (res.error) alert(ERROR.parseError(res.error));
            else setContent(newContent);
          }}
        />
      )
    })
  }

  if (isRemoved) return null;

  const goToUserProfile = (display, id) => {
    navigate(SCREEN_NAME.UserProfile, { id: id, name: VarHelper.toSlug(display) });
  }

  const parsedMention = (content) => {
    if (!content || !content.includes('@')) return <Text>{content}</Text>;
    // console.log('here parsedMention', content);
    const regexp = /@\[.*\]\(user:__.*__\)/g;
    const match = content.match(regexp);
    if (!match) return content;
    let results = [];
    let lastIndex = 0;
    let contentClone = content;
    match.forEach((p, pI) => {
      const index = content.indexOf(p);
      if (index > 0) {
        const one = contentClone.slice(lastIndex, index);
        results.push(one);
        results.push(p);
      } else {
        results.push(p);
      }
      contentClone = contentClone.slice(index + p.length, contentClone.length);
      if (pI === match.length - 1) {
        if (!!contentClone) results.push(contentClone);
      }
    });
    return (
      <Text>
        {
          results.map((part, i) => {
            if (!part.includes('@[')) return <Text key={'part-' + i}>{part}</Text>;
            // exmaple: @[Hải Li](user:__852066675191__)
            const cleaned = part.replace(/__/g, '').replace('@[', '').replace(')', '').replace('user:', '');
            const [display, id] = cleaned.split('](');
            console.log(display, id);
            return (
              <Text
                key={'part-' + i} bold
                paddingVertical={2} paddingHorizontal={4}
                backgroundColor={COLOR.PRI_300} borderRadius={4} onPress={() => goToUserProfile(display, id)}
              >
                {display}
              </Text>
            )
          })
        }
      </Text>
    )
  }

  return (
    <Col paddingVertical12 borderBottomWidth={!isLast ? 1 : 0} borderBottomColor={COLOR.BORDER} backgroundColor={props.commentBgColor}>
      <Row>
        <Row onPress={() => goToUserProfile(comment.createdBy.name, comment.createdBy.id)}>
          <Avatar name={comment.createdBy.name} uri={comment.createdBy.avatar} size={32} />
          <Text marginLeft13>
            <UserNameWithoutA {...comment.createdBy} />
          </Text>
        </Row>
        <Col flex1 />
        <Col alignItems='flex-end'>
          <Text color={COLOR.GRAY_500}>{TimeHelper.fromNow(comment.createdAt, 'YYYY-MM-DD HH:mm:ssZZ')}</Text>
          {Boolean(showSenderIP && comment.ip) && (
            <CommentDeviceInfo {...comment} />
          )}
        </Col>
      </Row>
      <Row onHoverStyle={{ opacity: 1 }} useNestedHover alignItems="flex-start">
        <Col flex1>
          <Col marginTop12>{parsedMention(content)}</Col>
          {!!user && (
            <Col height30>
              <Row height30 width90 display='none' onHoverStyle={{ display: 'flex' }} onPress={onReplyThisComment}>
                <Text caption colorMain>Trả lời</Text>
              </Row>
            </Col>
          )}
        </Col>
        {Boolean(canEditRemove && comment.id) && (
          <Row paddingTop12 width60 justifyContent="flex-end" display='none' onHoverStyle={{ display: 'flex' }}>
            <Col onPress={removeComment}>
              <ICON.Trash width={12} height={12} fill={COLOR.GRAY_500} style={{ margin: 2.5 }} />
            </Col>
            <Col onPress={onRequestUpdate}>
              <ICON.SolidPen width={12} height={12} fill={COLOR.GRAY_500} style={{ margin: 2.5 }} />
            </Col>
          </Row>
        )}
      </Row>
    </Col>
  );
};

export default memo(SingleComment);