import React, { useState } from 'react';
import { Col, Row, Img, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { RatioCol } from '../index';
import { ICON, IMAGE } from 'assets';
import { COLOR, STYLES } from 'const';
import { Button } from 'components/elements';
import Store from 'store';

const WelcomeUserProfile = () => {
  const rV = useDynamicResponsiveValue();
  const [step, setStep] = useState(1);
  const [{ didDismissWelcome }, uApi] = Store.User.createUserStore();
  const bg = step === 1 ? IMAGE.WelcomeUserBg1
    : step === 2 ? IMAGE.WelcomeUserBg2
    : IMAGE.WelcomeUserBg3
  const viewport = rV({ xs: 'xs', md: 'md' });

  const buttonProps = rV({
    xs: {
      width: 160, solid: true, large: true,
    },
    md: {
      width: 232, solid: true, large: true, marginTop: 32,
    }
  })

  const dismiss = () => {
    uApi.setDidDismissWelcome(true);
  };

  const open = (url) => {
    window.open(url, '_blank');
  }
  
  if (didDismissWelcome) return null;
  
  return (
    <RatioCol width='100%' ratio={752/220} bgWhite borderRadius4 marginBottom24>
      <Row stretch absoluteFill>
        <Col flex={752-338} />
        <Col flex={338}>
          <Img source={bg} style={STYLES.all100p} />
        </Col>
      </Row>
      <Col absoluteFill padding24>
        <Row>
          <Col
            width36 height4 backgroundColor={step === 1 ? COLOR.MAIN : COLOR.GRAY_200}
            onPress={() => setStep(1)}
          />
          <Col
            width36 height4 backgroundColor={step === 2 ? COLOR.MAIN : COLOR.GRAY_200} marginHorizontal4
            onPress={() => setStep(2)}
          />
          <Col
            width36 height4 backgroundColor={step === 3 ? COLOR.MAIN : COLOR.GRAY_200}
            onPress={() => setStep(3)}
          />
        </Row>

        {Boolean(step === 1) ? (
          <Col marginTop16>
            {Boolean(viewport !== 'xs') && (
              <>
                <Text subtitle1> Chào mừng bạn đến với Readism!</Text>
                <Text marginTop16>Hãy bắt tay vào cập nhật trang cá nhân nào!</Text>
              </>
            )}
            <Button {...buttonProps} text='Cập nhật thông tin' onPress={() => open('/ca-nhan/thiet-lap/co-ban')} />
          </Col>
        ) : Boolean(step === 2) ? (
          <Col marginTop16>
            {Boolean(viewport !== 'xs') && (
              <>
                <Text subtitle1>Khám phá thêm đầu sách tại Readism và đánh giá</Text>
                <Text marginTop16>Bắt đầu khám phá và đánh giá sách</Text>
              </>
            )}
            <Button {...buttonProps} text='Khám phá sách' onPress={() => open('/sach')} />
          </Col>
        ) : (
          <Col marginTop16>
            {Boolean(viewport !== 'xs') && (
              <>
                <Text subtitle1>Phát triển khả năng viết của bản thân</Text>
                <Text marginTop16>Khám phá tính năng viết tại Readism!</Text>
              </>
            )}
            <Button {...buttonProps} text='Khám phá bài viết' onPress={() => open('/viet')} />
          </Col>
        )}
        <Col absolute top12 right12 padding6 onPress={dismiss}>
          <ICON.Close fill='white' width={12} height={12} />
        </Col>
      </Col>
    </RatioCol>
  );
}

export default WelcomeUserProfile;