import { RatioCol } from 'components/elements';
import { COLOR, STYLES } from 'const';
import React from 'react';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { RateStars } from 'components';
import { IGroup } from 'type';
import { FontAwesome } from '@expo/vector-icons';

interface IGroupItemProps {
  data: IGroup,
  onPress?: any,
  [anyProps: string]: any,
}

const GroupItem = (props : IGroupItemProps) => {
  const propStyle = usePropsStyle(props);
  const { data } = props;

  if (!data) return null;
  
  return (
    <Col borderRadius4 overflow="hidden" shadow bgWhite style={propStyle}>
      <RatioCol ratio={362/157} width={'100%'} onPress={props.onPress}>
        <Img source={{ uri: data.cover || '' }} style={STYLES.all100p} />
      </RatioCol>
      <Col padding24 borderTopWidth1 borderTopColor={COLOR.BORDER}>
        <Text height43 marginTop24 btnTextLarge onPress={props.onPress} numberOfLines={2}>{data.name}</Text>
        <Row marginTop16>
          <FontAwesome name="user" size={14} color={COLOR.GRAY_300} />
          <Text marginLeft8>{data.members?.length || 0} thành viên</Text>
        </Row>
        <Col marginTop16>
          <Text body2 height32 numberOfLines={2}>{data.description}</Text>
        </Col>
        <Col
          borderWidth1 borderColor={COLOR.BORDER}
          absolute left24 top={-88 + 24} width88 height88 borderRadius4 bgWhite middle
        >
          <Img source={{ uri: data.image || '' }} width86 height86 borderRadius4 />
        </Col>
      </Col>
    </Col>
  );
};

export default GroupItem;
