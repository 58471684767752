import React from 'react';
import Svg, { Path } from "react-native-svg";

export const SvgBook = (props) => {
  return (
    <Svg
      width={18}
      height={21}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M17.75 14.563V1.437A.95.95 0 0016.812.5H4C1.93.5.25 2.18.25 4.25v12.5c0 2.07 1.68 3.75 3.75 3.75h12.813c.507 0 .937-.39.937-.938v-.625a.95.95 0 00-.352-.703c-.195-.625-.195-2.343 0-2.93a.967.967 0 00.352-.742zM5.25 5.733c0-.117.078-.234.234-.234h8.282c.117 0 .234.117.234.234v.782c0 .156-.117.234-.234.234H5.484c-.156 0-.234-.078-.234-.234v-.782zm0 2.5c0-.117.078-.234.234-.234h8.282c.117 0 .234.117.234.234v.782c0 .156-.117.234-.234.234H5.484c-.156 0-.234-.078-.234-.234v-.782zM15.133 18H4c-.703 0-1.25-.547-1.25-1.25 0-.664.547-1.25 1.25-1.25h11.133c-.078.703-.078 1.836 0 2.5z"
        fill="white"
      />
    </Svg>
  )
};