import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={14}
      height={13}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M12.375 4.031L8.953 3.54 7.406.422c-.258-.54-1.054-.563-1.336 0L4.547 3.539l-3.445.492c-.61.094-.844.844-.399 1.29l2.461 2.413-.586 3.399c-.094.61.563 1.078 1.102.797l3.07-1.617 3.047 1.617c.539.28 1.195-.188 1.101-.797l-.585-3.399 2.46-2.414c.446-.445.211-1.195-.398-1.289zM9.492 7.453l.656 3.797L6.75 9.469 3.328 11.25l.656-3.797L1.22 4.781l3.82-.562L6.75.75l1.688 3.469 3.82.562-2.766 2.672z"
        fill="#F6D271"
      />
    </Svg>
  )
}

export default SvgComponent