import React, { useEffect, useState } from 'react';
import { Col, Text, Row } from 'react-quick-style-components';
import { CMSLayout, Button, CMSTableLayout } from 'components';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, REPORT_STATUS, REPORT_TYPE, REPORT_REASON_SHORT } from 'const';
import Store from 'store';
import { VarHelper, TimeHelper } from 'helpers';

const CMSListReport = () => {
  const { navigation, goWithPreData } = useNavFunc();
  const [{}, uApi] = Store.User.createUserStore();
  const [{}, vApi] = Store.ViolationReport.createStore();

  const fetcher = (page : number, options) => vApi.list({ page, pageSize: 20, options });
  const onPressRow = (rowData) => goWithPreData(SCREEN_NAME.CMSEditReport, { id: rowData.id }, rowData);

  const entity = "Vi phạm";

  const tableSchema = [
    { title: 'Trạng thái', type: 'text', key: (val) => REPORT_STATUS[val.status] },
    { title: 'Người gửi', type: 'text', key: val => val.createdBy.name, width: 200 },
    { title: 'Lý do', type: 'text', key: val => REPORT_REASON_SHORT[val.reason] },
    { title: 'Loại', type: 'text', key: (val) => REPORT_TYPE[val.objectType] },
    { title: 'Thời gian gửi', type: 'text', width: 150, key: (val) => TimeHelper.fromNow(val.createdAt) },
  ];

  return (
    <Col flex1>
      <CMSLayout title={"Quản lý" + entity}>
        <Row>
          <Col flex1>
            <Text h4>{entity.toUpperCase()}</Text>
          </Col>
        </Row>
        <Row marginTop24>
          <CMSTableLayout
            initialPage={1}
            tableSchema={tableSchema}
            fetcher={fetcher}
            pageSize={20}
            onRowPress={onPressRow}
            fetchWhenFocus
            searchAndFilter={{
              filter: [
                { label: 'Trạng thái', value: 'status', options: Object.keys(REPORT_STATUS).map(val => ({ value: val, label: REPORT_STATUS[val] })) },
                { label: 'Thể loại', value: 'objectType', options: Object.keys(REPORT_TYPE).map(val => ({ value: val, label: REPORT_TYPE[val] })) }
              ],
              sort: [
                { label: 'Thời gian cập nhật', orderType: 'DESC', value: 'updatedAt' },
              ],
            }}
          />
        </Row>
      </CMSLayout>
    </Col>
  );
};

export default CMSListReport;