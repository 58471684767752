import React, { createRef } from 'react';
import { Text } from 'react-quick-style-components';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { DEVICE_INFO, TAB_NAME } from 'const';
import { AllStackFromHome, TabCMS } from './StackAll';
import TabMobile from './TabMobile';
import { linking } from './linking';
import { EventHelper } from 'helpers';

const Stack = createStackNavigator();

export const navigationRef = React.createRef<any>();

export default function Navigation() {
  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ?? route?.name} - Readism`,
      }}
      fallback={<Text>Loading...</Text>}
      ref={navigationRef}
      onStateChange={(state) => {
        EventHelper.logEvent('Page View', {
          name: navigationRef.current?.getCurrentRoute().name || '',
          url: window.location.pathname,
        })
        EventHelper.setCurrentScreen(navigationRef.current?.getCurrentRoute().name);
      }}
    >
      <Stack.Navigator
        screenOptions={{ header: () => null }}
        initialRouteName='Stacks'
        // initialRouteName={(() => {
        //   if (!DEVICE_INFO.IS_WEB) return "Tabs";
        //   if (window.location.pathname === '/') return DEVICE_INFO.WIDTH <= 768 ? "Tabs" : "Stacks";
        //   if (window.location.pathname.includes('/m')) return 'Tabs';
        //   if (!window.location.pathname.includes('/m')) return 'Stacks';
        // })()}
      >

        {/* DESKTOP */}
        <Stack.Screen name={"Stacks"} component={AllStackFromHome} options={{ title: 'Trang chủ' }} />

        {/* MOBILE */}
        {/* <Stack.Screen name={"Tabs"} component={TabMobile} options={{ title: 'Trang chủ' }} /> */}
      </Stack.Navigator>
    </NavigationContainer>
  );
}