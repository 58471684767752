import React, { useRef, useState, useMemo } from 'react';
import { Animated, useWindowDimensions } from 'react-native'
import { Scrollbars } from './react-custom-scrollbars-2';
import { UseScrollDirection } from '../../elements';

import './MangaReaderDesktopLayout.css';

interface IProps {
  onRef?: any,
  children: any,
  images: Array<{ name: string, url: string }>,
  readerSize: {
    width: number,
    height: number,
    y: number,
  },
  onScrollingDown: any,
  onScrollingUp: any,
}

let _timeout;

const MangaReaderDesktopLayout = (props : IProps) => {
  const [totalHeight, setTotalHeight] = useState(1);
  const [previewHeight, setPreviewHeight] = useState(1);
  const viewRef = useRef({ scrollHeight: 1, scrollTop: 0, addEventListener: undefined });
  const scrollPercentage = useRef(new Animated.Value(0)).current;
  const { height } = useWindowDimensions();
  const scrollTrackHeight = useMemo(() => {
    return window.innerHeight - 2 * 84 - previewHeight;
  }, [height]);

  const offsetPreviewTop = useMemo(() => {
    return props.readerSize.y * scrollTrackHeight / totalHeight;
  }, [props.readerSize, scrollTrackHeight, totalHeight]);
  const offsetPreviewBottom = useMemo(() => {
    return (totalHeight - props.readerSize.height - props.readerSize.y) * scrollTrackHeight / totalHeight;
  }, [props.readerSize, offsetPreviewTop, totalHeight]);

  return (
    <Scrollbars
      // renderThumbVertical={({ props }) => <div {...props} className='thumb-scroll-manga' />}
      ref={props.onRef}
      onViewRef={ref => {
        viewRef.current = ref;
        clearTimeout(_timeout);
        _timeout = setTimeout(() => {
          if (!viewRef.current) return;
          if (viewRef.current.scrollHeight !== totalHeight) setTotalHeight(viewRef.current.scrollHeight);
        }, 500);
      }}
      onScroll={e => {
        requestAnimationFrame(() => {
          Animated.timing(scrollPercentage, {
            duration: 100,
            useNativeDriver: true,
            toValue: viewRef.current.scrollTop / viewRef.current.scrollHeight,
          }).start();
        });
      }}
      thumbMinSize={50}
      trackVerticalWrapperClassName="track-scroll-manga"
      renderTrackVertical={() => {
        return (
          <div className="manga-preview">
            <Animated.View
              style={{
                transform: [
                  { translateY: scrollPercentage.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, Math.min(scrollTrackHeight - previewHeight - 30, -1)]
                  }) },
                ]
              }}
              onLayout={e => {
                const newPreviewHeight = e.nativeEvent.layout.height;
                if (newPreviewHeight !== previewHeight) setPreviewHeight(newPreviewHeight);
              }}
            >
              <div style={{ height: offsetPreviewTop }} />
              {!props.images ? null : props.images.map((val, i) => !val || !val.url ? null : (
                <img src={val.url} alt="" key={'preview'+i} />
              ))}
              <div style={{ height: offsetPreviewBottom }} />
            </Animated.View>
          </div>
        )
      }}
    >
      {props.children}
      <UseScrollDirection
        onScrollingDown={props.onScrollingDown}
        onScrollingUp={props.onScrollingUp}
        target={viewRef.current}
      />
    </Scrollbars>
  );
};

export default MangaReaderDesktopLayout;
