import React, { useRef, useState } from 'react';
import Slick from "react-slick";
import { Col, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { COLOR } from 'const';

interface ISliderProps {
  children?: any,
  numberOfSlide?: number,
  [anyProp : string]: any,
}

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

(() => {
  const css = `
    @import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
    @import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");
  `;
  const style = document.createElement('style')
  style.textContent = css;
  document.head.append(style);
})();

const Slider = (props : ISliderProps) => {
  const [index, setIndex] = useState(0);
  const slickRef = useRef<{ slickGoTo: Function }>(null);
  const rV = useDynamicResponsiveValue();
  const dotWidth = (() => {
    if (!props.numberOfSlide || isNaN(props.numberOfSlide)) return 0;
    return props.numberOfSlide * 14 + props.numberOfSlide * 12;
  })();
  return (
    <Col width100p overflow="hidden">
      <Slick
        {...settings}
        ref={slickRef}
        beforeChange={(oldIndex, newIndex) => {
          setIndex(newIndex);
        }}
      >
        {props.children}
      </Slick>
      {Boolean(!!props.numberOfSlide && props.numberOfSlide > 1) && (
        <Row absolute bottom={rV({ xs: 8, lg: 32 })} left="50%" width={dotWidth} transform={[{ translateX: - dotWidth / 2}]}>
          {new Array(props.numberOfSlide).fill(1).map((val, i) => (
            <Col
              key={'dot-'+i}
              backgroundColor={i === index ? COLOR.MAIN : 'rgba(255,255,255,0.4)'}
              onPress={() => {
                i !== index && slickRef.current?.slickGoTo(i);
              }}
              width14 height14 borderRadius7
              marginHorizontal6
            />
          ))}
        </Row>
      )}
    </Col>
  )
};

export default Slider;