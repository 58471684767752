import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={75}
      height={76}
      viewBox="0 0 75 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#prefix__clip0)">
        <Path
          d="M36.695 13.469h.012l22.42-6.014a2.484 2.484 0 013.032 1.757l3.193 11.928 1.447 5.408v.012l6.806 25.366a2.475 2.475 0 01-1.745 3.031l-13.648 3.65-21.517-45.138z"
          fill="#207D61"
        />
        <Path
          d="M32.86 14.43h.012l22.42-6.014a2.483 2.483 0 013.032 1.757l3.192 11.928 1.448 5.407v.012l6.805 25.366a2.475 2.475 0 01-1.745 3.031l-13.648 3.65L32.86 14.43z"
          fill="#E5F0EC"
        />
        <Path
          d="M67.418 24.258L54.61 72.07a2.475 2.475 0 01-3.031 1.745L3.77 61.007a2.475 2.475 0 01-1.745-3.031L12.32 19.544l3.563-10.802a2.407 2.407 0 011.98-.321l45.002 12.052 2.808.754a2.475 2.475 0 011.745 3.031z"
          fill="#E5F0EC"
        />
        <Path
          d="M63.693 23.034l-12.806 47.81a2.475 2.475 0 01-3.032 1.745L2.854 60.537a2.475 2.475 0 01-.83-2.561L12.32 19.544l3.563-10.802a2.407 2.407 0 011.98-.321l45.002 12.052a2.474 2.474 0 01.828 2.56z"
          fill="#E5F0EC"
        />
        <Path
          d="M37.772 39.564l-8.155-2.722 7.004-7.003 2.722 8.154a1.247 1.247 0 01-1.571 1.571z"
          fill="#fff"
        />
        <Path
          d="M13.878 7.097L6.875 14.1l-3.502-3.502a4.952 4.952 0 017.004-7.003l3.501 3.502z"
          fill="#207D61"
        />
        <Path
          d="M36.62 29.84l-2.622 2.622v.013l-1.745 1.744h-.012l-2.624 2.623-19.24-19.24 2.635-2.624 1.745-1.744 2.623-2.636L36.62 29.84z"
          fill="#63B199"
        />
        <Path
          d="M6.876 14.1l7.003-7.003 3.501 3.501-7.003 7.004L6.876 14.1z"
          fill="#fff"
        />
        <Path
          d="M52.22 75.137a3.73 3.73 0 003.588-2.747l3.421-12.774 12.959-3.464a3.719 3.719 0 002.617-4.545l-6.719-25.042.532-1.98a3.712 3.712 0 00-2.238-4.403L63.355 8.889a3.738 3.738 0 00-4.548-2.628l-22.099 5.928-18.53-4.968a3.64 3.64 0 00-2.299.124L11.25 2.724A6.186 6.186 0 102.5 11.47l8.438 8.438L.828 57.647A3.72 3.72 0 003.446 62.2l47.81 12.81c.314.085.638.129.963.128zm20.19-22.89a1.238 1.238 0 01-.866 1.511L59.97 56.852l6.832-25.503 5.609 20.897zM59.44 8.653a1.237 1.237 0 011.524.88l2.64 9.859-22.116-5.925 17.953-4.814zM38.159 38.387l-6.246-2.082 4.164-4.17 2.082 6.247v.005zm-5.035-6.79l-10.5-10.5a1.237 1.237 0 10-1.748 1.75l10.5 10.498-1.75 1.751-17.5-17.5 1.75-1.75 3.499 3.501a1.238 1.238 0 001.751-1.75l-3.5-3.5 1.75-1.75 17.497 17.499-1.75 1.75zm-24.498-17.5l5.25-5.25 1.75 1.75-5.25 5.25-1.75-1.75zm-5.462-7a3.713 3.713 0 016.338-2.624l2.624 2.624-5.25 5.25L4.25 9.722a3.687 3.687 0 01-1.086-2.625zm.057 51.2l9.74-36.365L28.75 37.72c.129.126.284.222.455.281.01 0 .017.014.029.017l8.15 2.722c.254.084.521.125.79.124a2.45 2.45 0 001.313-.387l14.23 3.82a1.236 1.236 0 10.641-2.39l-13.735-3.686a2.56 2.56 0 00-.114-.619l-2.722-8.15c0-.012-.013-.02-.018-.03a1.228 1.228 0 00-.28-.453L18.364 9.836l46.983 12.586a1.237 1.237 0 01.876 1.517L53.419 71.743a1.236 1.236 0 01-1.518.876L4.096 59.814a1.237 1.237 0 01-.876-1.517z"
          fill="#333"
        />
        <Path
          d="M57.883 29.994a1.238 1.238 0 00.32-2.433l-17.6-4.715a1.236 1.236 0 00-1.544 1.516c.12.434.466.768.904.873l17.6 4.716c.104.029.212.043.32.043zM55.96 37.164a1.238 1.238 0 00.32-2.433l-11.966-3.2a1.237 1.237 0 10-.638 2.39l11.965 3.201c.104.028.212.042.32.042zM53.313 50.588a1.237 1.237 0 00-.874-1.515l-27.49-7.364a1.238 1.238 0 00-.64 2.384l27.489 7.365a1.237 1.237 0 001.516-.87zM50.517 56.245L23.029 48.88a1.239 1.239 0 10-.642 2.39l27.49 7.365a1.238 1.238 0 00.64-2.39z"
          fill="#333"
        />
        <Path
          d="M13.795 15.928l1.837-1.84 1.54.533 1.834 2.292 14.286 14.53-2.34 2.334-16.53-15.947-.627-1.901z"
          fill="#63B199"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path
            fill="#fff"
            transform="translate(.67 .87)"
            d="M0 0h74.262v74.262H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default SvgComponent
