import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={19}
      height={22}
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M9.813 21.625c1.435 0 2.583-1.148 2.583-2.625H7.188a2.591 2.591 0 002.625 2.625zm8.818-6.111c-.78-.862-2.297-2.133-2.297-6.358 0-3.158-2.215-5.7-5.25-6.357v-.861c0-.698-.574-1.313-1.271-1.313-.739 0-1.313.615-1.313 1.313v.86c-3.035.657-5.25 3.2-5.25 6.358 0 4.225-1.518 5.496-2.297 6.358-.246.246-.369.574-.328.861 0 .697.492 1.313 1.313 1.313h15.708c.82 0 1.313-.616 1.354-1.313a1.25 1.25 0 00-.37-.861z"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
