import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Text, Img } from 'react-quick-style-components';
import { STYLES, COLOR, useFontSizeStyle, ERROR } from 'const';
import { ICON, IMAGE } from 'assets';
import { Slider, MainContainer, RatioCol, Input, ControlledUploaderUI, Button, useSubmitUIState, Popup, Checkbox } from 'components';
import { useWindowDimensions } from 'react-native';
import { FontAwesome, MaterialCommunityIcons, AntDesign } from '@expo/vector-icons';
import { ILocalFileUpload } from 'type';

import { arrayMoveImmutable } from 'array-move';

import Store from 'store';

const makeLocalFile = (path) => ({ name: '', path, mime: '', raw: undefined });

const SliderHome = () => {
  const [{ listSettings }, sApi] = Store.Setting.createStore();
  const [{  }, mApi] = Store.Misc.createMiscStore();
  const [{ loading }, setUI] = useSubmitUIState();
  const [slides, setSlides] = useState([
    {
      bg: makeLocalFile(IMAGE.SlideBg1),
      useLocal: 'SlideBg1',
      title: 'MẠNG XÃ HỘI',
      text: 'dành cho những người yêu đọc sách',
      link: undefined,
      showOnlyBg: false,
    },
    {
      bg: makeLocalFile(IMAGE.SlideBg2),
      useLocal: 'SlideBg2',
      title: 'MẠNG XÃ HỘI',
      text: 'dành cho những người viết và sáng tác',
      link: undefined,
      showOnlyBg: false,
    },
    {
      bg: makeLocalFile(IMAGE.SlideBg3),
      useLocal: 'SlideBg3',
      title: 'MẠNG XÃ HỘI',
      text: 'dành cho những người đọc và trao đổi về sách',
      link: undefined,
      showOnlyBg: false,
    },
    {
      bg: makeLocalFile(IMAGE.SlideBg4),
      useLocal: 'SlideBg4',
      title: 'MẠNG XÃ HỘI',
      text: 'dành cho những người yêu đọc sách',
      link: undefined,
      showOnlyBg: false,
    },
  ]);

  const slidesRef = useRef([]);
  useEffect(() => {
    slidesRef.current = slides;
  }, [slides]);

  useEffect(() => {
    const find = listSettings.find(val => val.name === 'SLIDER_HOME');
    if (!!find) {
      const computed = find.data.map(val => ({ ...val, bg: !!val.useLocal ? makeLocalFile(IMAGE[val.useLocal]) : makeLocalFile(val.bg) }));
      console.log('computed', computed);
      setSlides(computed);
    }
  }, [listSettings]);

  const handleChange = (label, i) => (value) => {
    const newList = slidesRef.current.slice();
    newList[i][label] = value;
    setSlides(newList);
  }

  const moveUp = (i) => {
    if (i === 0) return;
    const newList = arrayMoveImmutable(slidesRef.current, i, i - 1);
    setSlides(newList);
  }

  const moveDown = (i) => {
    if (i === slidesRef.current.length - 1) return;
    const newList = arrayMoveImmutable(slidesRef.current, i, i + 1);
    setSlides(newList);
  }

  const remove = i => {
    const newList = [
      ...slidesRef.current.slice(0, i),
      ...slidesRef.current.slice(i+1, slidesRef.current.length),
    ];
    setSlides(newList);
  }

  const submit = async () => {
    setUI({ loading: true });
    const data = [];
    const promiseArr = slidesRef.current.map(async (val, i) => {
      const [uploaded, err] = await (async () => {
        if (!!val.useLocal && IMAGE[val.useLocal] === val.bg.path) return ['', null];
        return await mApi.uploadLocal(val.bg);
      })();
      data[i] = {
        bg: err ? '' : uploaded,
        useLocal: !uploaded ||  uploaded === IMAGE[val.useLocal] ? val.useLocal : '',
        text: val.text,
        link: val.link,
        title: val.title,
        showOnlyBg: val.showOnlyBg,
      };
    })
    await Promise.all(promiseArr);
    console.log('data', data);
    const [res, err] = await sApi.upsert({ name: 'SLIDER_HOME', value: '', data });
    setUI({ loading: false });
    if (err || res.error) return Popup.showError(ERROR.parseError(err || res.error));
    Popup.show('Cập nhật thành công');
  }

  const renderSlide = (val, i) => {
    return (
      <Row
        key={i}
        onHoverStyle={{ borderColor: COLOR.MAIN }} borderRadius12 borderWidth1 borderColor={COLOR.BORDER}
        padding={12} alignItems='flex-start'
        marginBottom12 shadow
      >
        <Col width32>
          <AntDesign name="caretup" size={24} color={COLOR.BORDER} onPress={() => moveUp(i)} />
          <AntDesign name="caretdown" size={24} color={COLOR.BORDER} onPress={() => moveDown(i)} />
          <AntDesign name="close" size={24} color={COLOR.BORDER} onPress={() => remove(i)} />
        </Col>
        <Col flex1>
          <RatioCol ratio={2732 / 1022} width='100%'>
            {/* <Img source={{ uri: val.bg }} style={STYLES.all100p} /> */}
            <ControlledUploaderUI
              value={val.bg}
              onChange={handleChange('bg', i)}
              text='Cập nhật ảnh'
              aspectRatio={2732 / 1022}
            />
          </RatioCol>
          <Row marginTop24 onPress={() => handleChange('showOnlyBg', i)(!val.showOnlyBg)}>
            <Checkbox value={val.showOnlyBg} onChange={handleChange('showOnlyBg', i)} />
            <Text marginLeft16>Chỉ hiển thị ảnh nền</Text>
          </Row>
          <Input marginTop24 label="Tiêu đề" value={val.title} onChange={handleChange('title', i)} placeholder='' />
          <Input marginTop24 label="Mô tả" value={val.text} onChange={handleChange('text', i)} placeholder='Dòng chữ..' />
          <Input marginTop24 label="Đường dẫn" value={val.link} onChange={handleChange('link', i)} placeholder='Mặc định nếu không điền sẽ hiển thị 2 nút Đánh giá ngay, sáng tác ngay' />
        </Col>
      </Row>

    );
  }

  console.log('slides', slides);

  return (
    <Col>
      {slides.map(renderSlide)}

      <Col marginTop24 middle>
        <Button loading={loading} text='Cập nhật' onPress={submit} solid width150 height44 />
      </Col>
    </Col>
  );
};

export default SliderHome;