import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { INews } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';

interface ISetters {
  setListPublic?: (v : Array<INews>) => void,
  setListFeatured?: (v : Array<INews>) => void,
  [additionSetter: string]: (v : any) => void,
}

interface IState {
  listPublic: Array<INews>,
  listFeatured: Array<INews>,
}

class News extends PersistReady {

  constructor() {
    super();
  }

  state : IState = {
    listPublic: [],
    listFeatured: [],
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {
    const [listPublic, setListPublic] = useGlobalState<Array<INews>>(this.state.listPublic, 'news_store_list_public');
    const [listFeatured, setListFeatured] = useGlobalState<Array<INews>>(this.state.listFeatured, 'news_store_list_featrued');
    if (!this.setters.setListPublic) this.setters.setListPublic = setListPublic;
    if (!this.setters.setListFeatured) this.setters.setListFeatured = setListFeatured;
    useEffect(() => {
      this.updateState({ listPublic, listFeatured });
    }, [listPublic, listFeatured]);
    return [
      { listPublic, listFeatured },
      {
        getList: this.getList,
        getListFeatured: this.getListFeatured,
        create: this.create,
        update: this.update,
        detail: this.detail,
      }
    ];
  }

  getList = async ({ visibility, page, pageSize = 20 }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/news/list', { visibility, page, pageSize });
      if (visibility === 'public' && page === 1 && res.data.data) {
        const { rows } = res.data.data;
        if (!!this.setters.setListPublic) {
          this.setters.setListPublic(rows);
        }
      }
      return res.data;
    });
  }

  getListFeatured = async ({ visibility, page, pageSize = 20 }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/news/list', { visibility, page, pageSize, options: { filter: [{ label: 'isFeatured', value: true }] } });
      if (visibility === 'public' && page === 1 && res.data.data) {
        const { rows } = res.data.data;
        if (!!this.setters.setListFeatured) {
          this.setters.setListFeatured(rows);
        }
      }
      return res.data;
    });
  }

  create = async ({ images, visibility, content, description, name, contentJSON, contentHTML }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/news', { images, visibility, content, description, name, contentJSON, contentHTML });
      return res.data;
    });
  }

  update = async ({ id, images, visibility, content, description, name, contentJSON, contentHTML, isFeatured }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/news/update', { id, images, visibility, content, description, name, contentJSON, contentHTML, isFeatured });
      return res.data;
    });
  }

  detail = async (id) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/news/' + id, {  });
      return res.data;
    });
  }
}

export default new News();
