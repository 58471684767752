import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { COLOR, DEVICE_INFO, SCREEN_NAME } from 'const';
import { NavigationProp } from '@react-navigation/native';
import { showModal, hideModal, Tooltip } from '../../elements';
import { NotificationItem } from '../../widgets';
import { useFetchUIState, useSubmitUIState } from '../../hooks';
import Store from 'store';
import { TimeHelper, VarHelper } from 'helpers';
import { MaterialIcons } from '@expo/vector-icons';
import { ActivityIndicator, useWindowDimensions } from 'react-native';

interface IHeaderNotificationDropdownProps {
  insideContainer?: boolean,
  navigation: {
    navigate: Function,
  },
}

export const HeaderNotificationDropdown = ({ navigation, insideContainer } : IHeaderNotificationDropdownProps) => {
  const [{ user, unreadNoti }, useApi] = Store.User.createUserStore();
  const rV = useDynamicResponsiveValue();
  const { width, height } = useWindowDimensions();

  const [list, setList] = useState([]);
  const page = useRef(1);
  const [{ hasMore, isLoadingMore }, setFetchUI] = useFetchUIState();
  const [{ loading }, setUI] = useSubmitUIState();

  const getData = async () => {
    if (page.current === 1) {
      setUI({ loading: true });
    } else {
      setFetchUI({ isLoadingMore: true });
    }
    
    const [res, err] = await useApi.listNotification({ page: page.current, pageSize: 20 });
    if (page.current === 1) {
      setUI({ loading: false });
    } else {
      setFetchUI({ isLoadingMore: false });
    }
    if (!!res && !!res.data && !!res.data.rows) {
      setList(page.current === 1 ? res.data.rows : [...list, ...res.data.rows]);
    }
    if (!!res && !!res.data && !!res.data.hasNext) {
      setFetchUI({ hasMore: true })
    }
  };
  
  useEffect(() => {
    getData();
  }, []);

  const markReadAll = async () => {
    if (unreadNoti === 0) return;
    await useApi.markReadNotification({ id: '0', isAll: true });
    useApi.setUnreadNoti(0);
    setList(list.map(val => ({ ...val, status: 'read' })));
  };

  const containerStyle = insideContainer ? { flex: 1 } : {
    width: rV({ xs: width, md: 250 }),
    height: rV({ xs: height - 60, md: 300 }),
  }

	return (
		<Col style={containerStyle} bgWhite shadow onPress={() => {}}>
      <Row padding12 borderBottomWidth1 borderBottomColor={COLOR.BORDER} onHoverStyle={{ opacity: 1 }} justifyContent='space-between'>
        <Col onPress={markReadAll}>
          <Text caption textDecorationLine='underline' color={COLOR.GRAY_500} onHoverStyle={{ color: COLOR.MAIN }}>Đánh dấu đã đọc</Text>
        </Col>
        <Col onPress={hideModal}>
          <Text caption textDecorationLine='underline' color={COLOR.GRAY_500} onHoverStyle={{ color: COLOR.MAIN }}>Đóng</Text>
        </Col>
      </Row>
      {Boolean(loading) ? (
        <Col flex1 middle>
          <ActivityIndicator size="large" color={COLOR.MAIN} />
        </Col>
      ) : (
        <Scroll>
          {list.map((val, i) => (
            <NotificationItem key={val.id} data={val} />
          ))}
          {hasMore && (
            <Row middle padding12 onPress={() => {
              page.current += 1;
              getData();
            }}>
              {isLoadingMore ? <Text>Đang tải thêm..</Text> : <Text>Tải thêm</Text>}
            </Row>
          )}
        </Scroll>
      )}
    </Col>
	);
};

export const useHeaderNotificationDropdown = (navigation) => {
  const rV = useDynamicResponsiveValue();

	const showDropdown = (e) => {
    console.log(e.nativeEvent.clientX, e.nativeEvent.clientY);
    const width = 250;
    const left = rV({
      xs: 0,
      md: e?.nativeEvent?.clientX ? e?.nativeEvent?.clientX - width / 2 : 0,
    });
    const top = rV({
      xs: 60,
      md: e?.nativeEvent?.clientY ? e?.nativeEvent?.clientY + 24 : 0,
    });

    console.log(top, left)
		showModal({
			component: (
				<HeaderNotificationDropdown navigation={navigation} />
			),
			modalProps: {
				style: {
					position: 'absolute',
          left,
          top,
				},
			},
			noWrapper: true,
			animation: 'pulse',
		})
	};

	return {
		showDropdown,
		hideDropdown: hideModal,
	};
};
