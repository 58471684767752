import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Col, Text, useDynamicResponsiveValue, Row, Scroll, Img } from 'react-quick-style-components';
import {
  Header, MainContainer, ControlledRichTextEditor, Popup, Button, CollapsableText,
  ControlledUploaderUI, Input, useSubmitUIState, Select, RatioCol, usePreDataOrFetchApi, Avatar,
  useLikeCommentData, HTMLView,
  UserName,
} from 'components';
import { useNavFunc } from 'navigation';
import { ActivityIndicator, useWindowDimensions } from 'react-native';
import { COLOR, ERROR, SCREEN_NAME, CHAPTER_EDIT_VISIBILITY, STYLES } from 'const';

import { EditableChapterTitle } from './CreateWriting.Comps';
import { ICON } from 'assets';
import Store from 'store';
import { IWriting, IWritingChapter } from 'type';
import { AntDesign } from '@expo/vector-icons';
import { TimeHelper, VarHelper } from 'helpers';
import LikeComment from 'components/widgets/LikeComments/LikeComments';


const WritingDetail = () => {
  const { reset, route, navigate, goWithPreData, goBackOrTo, navigation } = useNavFunc();
  // @ts-ignore
  const { id, name } = route.params;
  const [{ }, wApi] = Store.Writing.createStore();
  const { height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();

  const [writingData, _, { fetchError }] = usePreDataOrFetchApi<IWriting>(id, 'writing', { fetchOnFocus: true, force: true });
  console.log('writingData', writingData);
  const [
    { numberOfLikes, numberOfComments, didLike, listComments },
    { toggleLike, sendComment, getAllComments },
  ] = useLikeCommentData({ entityData: writingData, postType: 'writing' });

  const [expandedChapter, setExpandedChapter] = useState(false);
  const [tempComment, setTempComment] = useState('');

  const { user } = Store.User.state;
  const isMyPost = !!user.id && user.id === writingData?.createdById;
  const isAdmin = !!user.type && user.type === 'ADMIN';

  // console.log('writingData', writingData);

  const readNow = () => {
    // if (writingData?.numberOfChapter <= 1) {
    //   window.scrollTo({ left: 0, top: 436.109375 });
    // } else {
    //   goWithPreData(SCREEN_NAME.WritingDetailChapter, { id, name, chapterNumber: 1 }, writingData);
    // }
    if (writingData?.progress) {
      goWithPreData(SCREEN_NAME.WritingDetailChapter, {
        id, name, chapterNumber: writingData?.progress, slug: 'chapter-dang-doc',
      }, writingData);
    } else
    goWithPreData(SCREEN_NAME.WritingDetailChapter, { id, name, chapterNumber: writingData.listChapters[0].id, slug: VarHelper.toSlug(writingData.listChapters[0].name) }, writingData);
  }

  const renderOneChapter = () => {
    const listChapters = !writingData ? [] : writingData.listChapters;
    const chapData = !writingData ? {} as IWritingChapter : listChapters[0];
    const isPasswordProtected = chapData?.content === 'Bạn cần mật khẩu để truy cập';
    return (
      <Col marginTop24 padding16 borderRadius4 borderWidth1 borderColor={COLOR.BORDER}>
        <Text bold fontSize={18}>{writingData?.name}</Text>
        <HTMLView marginTop24 content={chapData?.contentHTML} />
      </Col>
    );
  };

  const renderListChapter = () => {
    return (
      <Col marginTop24 padding16 borderRadius4 borderWidth1 borderColor={COLOR.BORDER}>
        <Text bold fontSize={18}>DANH SÁCH</Text>
        <Text fontSize14 color={COLOR.GRAY_500}>Cập nhật lần cuối {TimeHelper.format(writingData.updatedAt, 'DD/MM/YYYY')}</Text>

        <Col marginTop20>
          {writingData.listChapters?.slice(0, expandedChapter ? writingData.listChapters?.length : 7).map((chap, i) => (
            <Col key={chap.id} marginTop12 onPress={() => goWithPreData(SCREEN_NAME.WritingDetailChapter, { id, name, chapterNumber: chap.id, slug: VarHelper.toSlug(chap.name) }, writingData)}>
              <Text fontSize16>{chap.visibility === 'draft' ? ' [Nháp]' : ''}{chap.name}</Text>
            </Col>
          ))}
          {Boolean(writingData.listChapters?.length > 6) && (
            <Col onPress={() => setExpandedChapter(!expandedChapter)}>
              <Text bold colorMain marginTop12>{expandedChapter ? 'Thu gọn' : 'Xem tất cả'}</Text>
            </Col>
          )}
        </Col>
      </Col>
    );
  };

  const handleDelete = () => {

    const doRemove = async () => {
      const [res, err] = await wApi.update({
        id,
        visibility: 'trash',
      } as any);
      Popup.show('Đã xóa thành công', [
        { text: 'OK', solid: true, outline: true, onPress: () => goBackOrTo(SCREEN_NAME.Writing) },
      ]);
    };

    Popup.show('Bạn có chắc chắn muốn xóa truyện?', [
      { text: 'Có', solid: false, outline: true, onPress: doRemove },
      { text: 'Không', solid: true, outline: false, onPress: () => { } },
    ]);
  };

  return (
    <Col flex1 backgroundColor="white">
      <Header />
      <Col flex1>
        {Boolean(fetchError) ? (
          <Col height200 middle>
            <Text>{fetchError}</Text>
          </Col>
        ) : Boolean(!writingData) ? (
          <Col height200 middle>
            <ActivityIndicator size='large' color={COLOR.MAIN} />
          </Col>
        ) : (
          <Scroll flex1>
            <MainContainer marginTop24 paddingHorizontal10>

              <Col alignSelf="center" width={rV({ xs: '100%', md: '80%', lg: 200 / 3 + '%' })}>
                {/* FIRST ROW */}
                <Row alignItems={rV({ xs: 'flex-start', md: 'center' })}>
                  <Col flex1>
                    <RatioCol ratio={1} width="100%">
                      <Img source={{ uri: writingData.image || '' }} style={STYLES.all100p} />
                    </RatioCol>
                    <Col marginTop24 display={rV({ xs: 'flex', md: 'none' })}>
                      <Text>{writingData.numberOfChapter || 0} chương</Text>
                      <Text>{writingData.numberOfView || 0} lượt xem</Text>
                      <Text>{numberOfLikes} lượt thích</Text>
                    </Col>
                  </Col>
                  <Col flex2 marginLeft24>
                    <Text fontSize={rV({ xs: 26,md: 28 })} lineHeight={33.89}>{writingData.name}</Text>
                    <Row marginTop16 onPress={() => navigate(SCREEN_NAME.UserProfile, { id: writingData.createdById, name: VarHelper.toSlug(writingData.createdBy.name) })}>
                      <Avatar name={writingData.createdBy.name} uri={writingData.createdBy.avatar} size={35} />
                      <Text marginLeft8 fontSize={rV({ xs: 14, md: 18 })}>{writingData.createdBy.name}</Text>
                    </Row>
                    <Row marginTop24 display={rV({ xs: 'none', md: 'flex' })}>
                      <Text>{writingData.numberOfChapter || 0} chương | </Text>
                      <Text>{writingData.numberOfView || 0} lượt xem | </Text>
                      <Text>{numberOfLikes} lượt thích</Text>
                    </Row>

                    <Row marginTop24 xs='100%' md="any:any">
                      <Row
                        onPress={readNow}
                        margin8 width172 height43 paddingHorizontal8 paddingVertical8 middle bgMain borderRadius8
                      >
                        <Text btnTextSmall colorWhite>{writingData?.progress ? 'Đọc tiếp' : 'Đọc ngay'}</Text>
                      </Row>
                      <Row
                        onPress={toggleLike}
                        margin8 width172 height43 paddingHorizontal8 paddingVertical8 middle borderWidth1 borderColor={COLOR.MAIN} borderRadius8
                      >
                        <AntDesign name={didLike ? "heart" : "hearto"} size={20} color={COLOR.MAIN} />
                        <Text marginLeft8 btnTextSmall colorMain>Thích</Text>
                      </Row>
                    </Row>

                    {Boolean(isAdmin || isMyPost) && (
                      <Row margin4>
                        <Row margin4 onPress={() => goWithPreData(SCREEN_NAME.UpdateWriting, { id, name }, writingData)}>
                          <ICON.SolidPen fill={COLOR.MAIN} />
                          <Text colorMain marginLeft16>Chỉnh sửa</Text>
                        </Row>
                        <Row onPress={handleDelete} margin4 marginLeft8>
                          <ICON.Trash fill={COLOR.DANGER_500} />
                          <Text color={COLOR.DANGER_500} marginLeft12>Xóa truyện</Text>
                        </Row>
                      </Row>
                    )}
                  </Col>
                </Row>
                {/* DESCRIPTION AND CHAPTER */}
                <Col marginTop24>
                  <Text h6>GIỚI THIỆU</Text>
                  <Col marginTop16>
                    <CollapsableText expandMaxHeight={150}>
                      <Text body1>{writingData.content}</Text>
                    </CollapsableText>
                  </Col>
                </Col>

                {/* GENRES */}
                {Boolean(writingData.genres && writingData.genres.length > 0) && (
                  <Col marginTop24>
                    <Text fontSize16 bold marginTop16>THỂ LOẠI</Text>
                    <Text fontSize16 marginTop8>{writingData?.genres ? writingData?.genres?.map(val => val.name).join(', ') : ''}</Text>
                  </Col>
                )}

                {/* {Boolean((writingData.numberOfChapter || 0) <= 1) ? renderOneChapter() : renderListChapter()} */}
                {renderListChapter()}

                <Col marginTop24>
                  {/* <Text h6>BÌNH LUẬN</Text> */}
                  <Col
                    borderColor={COLOR.BORDER}
                    borderWidth1
                    borderRadius8
                  >
                    <LikeComment
                      postType='writing'
                      navigation={navigation}
                      hideLike
                      entityData={writingData}
                    />
                  </Col>

                  <Col height24 />

                </Col>

              </Col>

            </MainContainer>
          </Scroll>
        )}

      </Col>
    </Col>
  )
};

export default WritingDetail;