import React, { useEffect, useState, useMemo } from 'react';
import { Col, Text, Row, Scroll } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME } from 'const';
import { Header, Button, LeftSidebarOneThird, Avatar, Placeholder, ReviewItemPublic, FeedItem, usePreDataOrFetchApi, LoadMoreDetect, Input, hideModal } from 'components';
import { ICON } from 'assets';
import Store from 'store';
import { IActivity, IUser } from 'type';

const DeleteAccountModal = (props) => {
  const [{ user }, uApi] = Store.User.createUserStore();
  const [name, setName] = useState('');

  const [errorMes, setErroMes] = useState('');
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState('');

  const confirmDeleteAccount = async () => {
    if (user.displayName !== name) return setErroMes('Nhập tên không đúng');
    setLoading(true);
    const [res, err] = await uApi.deleteAccount(name);
    setLoading(false);
    if (err) return setErroMes(ERROR.parseError(err));
    if (res.error) return setErroMes(ERROR.parseError(res.error));
    uApi.logout();
    hideModal();
    typeof props.onDone === 'function' && props.onDone();
  };
  return (
    <Col borderRadius8 bgWhite shadow padding24>
      <Row justifyContent="space-between">
        <Text fontSize20 bold>Xác nhận xóa tài khoản</Text>
        <Col width30 height30 middle onPress={hideModal}>
          <ICON.Close fill={COLOR.GRAY_500} width={13} height={20} />
        </Col>
      </Row>
      <Text>Bạn có chắc chắn muốn xóa tài khoản không? Lưu ý rằng bạn không thể khôi phục lại tài khoản sau khi xóa</Text>
      <Text marginTop24>Nhập <Text bold>{user.displayName}</Text> vào ô dưới để xác nhận</Text>
      <Input
        marginTop12
        label=""
        placeholder=""
        value={name}
        onChange={setName}
      />
      <Col alignItems="flex-end" marginTop24>
        {!!errorMes && <Text textAlign="right" color={COLOR.DANGER_500} marginBottom4>{errorMes}</Text>}
        <Button loading={loading} text={btnText || "Xóa tài khoản"} width170 onPress={confirmDeleteAccount} large solid  backgroundcolor={COLOR.DANGER_500} />
      </Col>
    </Col>
  );
};

export default DeleteAccountModal;