import React from 'react';

export const circle = {
  width: 139,
  height: 139,
  bg: 'white',
  color: '#ece4e4',
  renderChildren: () => (
    <>
      <circle cx="69.5" cy="69.5" r="69.5" />
    </>
  ),
};