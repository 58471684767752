import React, { useState } from 'react';
import { Row, Col, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ERROR, useFontSizeStyle } from 'const';
import { useNavigation } from '@react-navigation/native';
import { SCREEN_NAME, DEVICE_INFO, COLOR } from 'const';
import { ICON } from 'assets';
import { Button, Checkbox, Input } from 'components';
import { StyleSheet } from 'react-native';
import { Reset } from 'navigation';
import Store from 'store';

const ForgotPassword = () => {
  const rV = useDynamicResponsiveValue();
  const navigation = useNavigation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [{ user, token }, userApi] = Store.User.createUserStore();

  const reset = (screenName) => {
    navigation.reset({ index: 0, routes: [{ name: screenName }] })
  };

  const sendResetCode = async () => {
    if (!email) return setLoginErrorMessage(ERROR.co_fill_all_form);
    setLoading(true);
    const [res, err] = await userApi.sendResetPasswordCode(email);
    setLoading(false);
    if (err) return setLoginErrorMessage(ERROR.parseError(err));
    if (res.error) return setLoginErrorMessage(ERROR.parseError(res.error));
    setEmailSent(true);
  }

  if (!!token) {
    return (
      <Col middle flex1 bgWhite>
        <Text>Bạn đã đăng nhập..</Text>
        <Reset to={SCREEN_NAME.Home} timeout={500} />
      </Col>
    );
  }

  return (
    <Col middle flex1 bgWhite>
      <Col width={rV({ xs: 320, lg: 364 })}>
        <ICON.ReadismWithTextLogo fill={COLOR.MAIN} style={{ alignSelf: 'center', width: '80%' }} />
        <Text marginTop40 h4>Quên mật khẩu</Text>
        {Boolean(emailSent) ? (
          <>
            <Text marginTop16 body1>Email đã được gửi, vui lòng kiểm tra hộp thư và làm theo hướng dẫn.</Text>
          </>
        ) : (
          <>
            <Text marginTop16 body1>Nhập địa chỉ email bạn sử dụng để đăng nhập và chúng tôi sẽ gửi email có kèm đường link để đặt lại mật khẩu</Text>
            <Col marginTop24>
              <Input
                label="Email"
                value={email}
                onChange={setEmail}
                placeholder="Nhập email"
                error={loginErrorMessage}
              />
            </Col>
            <Button marginTop32 solid large text="Nhận mail đặt lại mật khẩu" onPress={sendResetCode} loading={loading} />
            <Col marginTop24 middle onPress={() => reset(SCREEN_NAME.Login)}>
              <Text>Bạn có tài khoản? <Text colorMain semiBold>Đăng nhập</Text></Text>
            </Col>
          </>
        )}
      </Col>
      <Col style={styles.cornerCloseBtn} onPress={() => reset(SCREEN_NAME.Home)}>
        <ICON.Close />
      </Col>
    </Col>
  );
};

const styles = StyleSheet.create({
  cornerCloseBtn: {
    position: 'absolute',
    top: DEVICE_INFO.IS_IOS ? 48 : 12,
    right: 12,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ForgotPassword;