import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import {
  MainContainer, Header, AddToBookshelfModal, RatioCol, RateStars,
  useFetchUIState, ReviewItem, ReviewItemPublic, GiveAReview, showModal, CollapsableText,
  usePreDataOrFetchApi, LoadMoreDetect, UseMemo, useRefState2
} from 'components';
import { IMAGE, ICON } from 'assets';
import { STYLES, useFontSizeStyle, SCREEN_NAME, ERROR, fSize, COLOR } from 'const';
import { useNavFunc, Reset, getScreenPreData } from 'navigation';
import { IBook } from 'type';
import Store from 'store';
import { VarHelper } from 'helpers';
import _ from 'lodash';
import MyReview from './BookDetail.MyReview'
import { ActivityIndicator } from 'react-native';

const BookDetail = () => {
  const rV = useDynamicResponsiveValue();
  const { route, openNewTabOrNavigate } = useNavFunc();

  const id = route.params?.id;
  const autoReview = !!route.params?.autoReview;
  useEffect(() => {
    console.log('Render First time');
  }, []);

  const { user, token } = Store.User.state;
  const [{ }, rApi] = Store.Reviews.createStore();
  // const [data, setData] = useState<IBook>(getScreenPreData(id) || {});
  const [data, setData, { fetchError }] = usePreDataOrFetchApi<IBook>(id, 'book');
  const [reviewData, getReviewData, setReviewData] = useRefState2({ count: 0, rows: [], myReviews: [] });
  const [{ isLoadingMore, hasMore }, setFetchUI] = useFetchUIState();

  const dataRef = useRef<IBook>(data);
  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const bookImage = data?.images ? data?.images[0] : '';

  const openBookShelfModal = () => {
    showModal({
      component: (
        <AddToBookshelfModal bookId={data.id} bookName={data.name} bookImage={bookImage} />
      )
    })
  }

  const page = useRef(1);

  const getListReview = async (id, p = 1) => {
    if (p !== 1) setFetchUI({ isLoadingMore: true });
    const [res, err] = await rApi.adminGetList({
      visibility: 'public',
      page: p,
      pageSize: 20,
      options: {
        filter: [
          { label: 'bookId', value: id },
        ]
      },
      includeMyReviews: true,
    });
    if (res && res.data) {
      const myReviews = res.data.myReviews || [];
      const listWithoutMyReview = res.data.rows.filter(val => val.createdById !== user.id);
      const otherList = p === 1 ? listWithoutMyReview : [
        ...getReviewData().rows,
        ...listWithoutMyReview,
      ]
      setReviewData({
        ...res.data,
        myReviews,
        // filter my review
        rows: otherList,
      });
      page.current = p;
      setFetchUI({
        isLoadingMore: false,
        hasMore: res.data.hasNext,
      });
      if (!res.data.hasNext) {
        recheckBookReviewStats(_.uniqWith([...myReviews, ...otherList], _.isEqual));
      }
    } else {
      setFetchUI({ isLoadingMore: false });
    }
  }

  const recheckBookReviewStats = allList => {
    if (!dataRef.current) return;
    const { averageRating, numberOfReview } = dataRef.current;

    let allRate = 0;
    allList.forEach(val => {
      allRate += val.star;
    });
    const compareAverageRating = allList.length === 0 ? 0 : +(allRate / allList.length).toFixed(2);

    if (allList.length !== numberOfReview || Math.abs(averageRating - compareAverageRating) > 0.01) {
      setData({
        ...dataRef.current,
        averageRating: compareAverageRating,
        numberOfReview: allList.length,
      });
      rApi.syncDataSingleBook(id);
    }
  };

  useEffect(() => {
    getListReview(id);
  }, [id]);

  if (!id) {
    return (
      <Col middle flex1 bgWhite>
        <Text>Đường dẫn không hợp lệ..</Text>
        <Reset to={SCREEN_NAME.Home} timeout={1500} />
      </Col>
    );
  };

  if (fetchError) {
    return (
      <Col flex1 bgWhite>
        <Header />
        <Col flex1 middle>
          <Text>{fetchError}</Text>
        </Col>
      </Col>
    )
  }

  if (!data) {
    return (
      <Col flex1 bgWhite>
        <Header />
        <Col flex1 middle>
        <ActivityIndicator size='large' color={COLOR.MAIN} />
        </Col>
      </Col>
    )
  }

  return (
    <Col flex1 bgWhite>
      <Header />
      <Col flex1>
        <Scroll style={{ flex: 1 }}>
          <MainContainer alignSelf="center" padding10 paddingVertical24>
            <Row alignItems="flex-start" xs='100%' md='any:any:1:any'>

              <Col width={rV({ xs: '100%', md: 145 })}>

                <RatioCol width={rV({ xs: '50%', md: '100%' })} alignSelf={rV({ xs: 'center', md: undefined })} ratio={145 / 232} marginBottom16 shadow>
                  <Img source={{ uri: !!data?.images && !!data?.images[0] ? data?.images[0] : '' }} style={STYLES.all100p} />
                </RatioCol>

                <Col bgMain borderRadius6 middle marginTop12 height43 onPress={openBookShelfModal}>
                  <Text colorWhite fontSize16>Thêm vào tủ</Text>
                </Col>
                {Boolean(user.type === 'ADMIN') && (
                  <Col
                    borderRadius6 middle marginTop12 borderWidth1 borderColor={COLOR.MAIN} height43
                    onPress={() => openNewTabOrNavigate(`/quan-ly/sach/${data?.id}/${VarHelper.toSlug(data?.name)}`, SCREEN_NAME)}
                  >
                    <Text colorMain fontSize16>Admin: chỉnh sửa</Text>
                  </Col>
                )}
              </Col>


              <Col width37 height0 />

              <Col paddingTop12>
                <Text bold fontSize={fSize(rV, 28)}>{data?.name}</Text>
                {Boolean(data?.visibility !== 'public') && (
                  <Text bold color={COLOR.DANGER_500} fontSize={fSize(rV, 18)}>Sách này không hiển thị công khai</Text>
                )}
                <Text bold fontSize={fSize(rV, 18)} color={COLOR.GRAY_500} marginTop8>{data?.authors && data?.authors?.length > 0 ? data.authors[0].name : ''}</Text>

                <Row marginTop24>
                  <RateStars
                    width101 height15 activeColor={COLOR.WARNING_400} inactiveColor={COLOR.WARNING_400}
                    value={Math.round(data?.averageRating)}
                    starSize={{ width: 17, height: 15 }}
                    onChange={() => { }}
                    outline
                  />
                  <Text subtitle2 marginLeft8>{String(data?.averageRating || '0.0')}</Text>

                  <Text fontSize16 color={COLOR.GRAY_500} marginLeft48>{data?.numberOfReview || 0} đánh giá</Text>
                </Row>

                <CollapsableText expandMaxHeight={150} fontSize16 marginTop24>{data?.content}</CollapsableText>

                <Text fontSize16 bold marginTop16>THỂ LOẠI</Text>
                <Text fontSize16 marginTop8>{data?.genres ? data?.genres?.map(val => val.name).join(', ') : ''}</Text>

                <Col height1 backgroundColor={COLOR.BORDER} marginTop24 />

                <MyReview reviewData={reviewData} bookData={data} onAddReview={() => getListReview(id)} autoReview={autoReview} />

                {/* {Boolean(reviewData.myReviews.length > 0) && (
                  <>
                    <Col marginTop24>
                      <Text bold fontSize={fSize(rV, 18)}>ĐÁNH GIÁ CỦA BẠN</Text>
                      <ReviewItemPublic data={reviewData.myReviews[0]} marginTop16 />
                    </Col>

                    <Col height1 backgroundColor={COLOR.BORDER} marginTop24 />
                  </>
                )} */}

                <Row justifyContent="space-between" marginTop24>
                  <Col>
                    <Text bold fontSize={fSize(rV, 18)}>ĐÁNH GIÁ BỞI CỘNG ĐỒNG</Text>

                    {/* <Row marginTop8>
                      <RateStars
                        width101 height15 activeColor={COLOR.WARNING_400} inactiveColor={COLOR.WARNING_400}
                        value={Math.round(data.averageRating)}
                        starSize={{ width: 17, height: 15 }}
                        onChange={() => { }}
                        outline
                      />
                      <Text subtitle2 marginLeft8>{String(data.averageRating || '0.0')}</Text>

                      <Text fontSize16 color={COLOR.GRAY_500} marginLeft48>{data.numberOfReview || 0} đánh giá</Text>
                    </Row> */}
                  </Col>
                  {/* {Boolean(reviewData.myReviews.length === 0) && (
                    <Col width170 height43 borderRadius6 middle borderWidth1 borderColor={COLOR.MAIN} onPress={handlePressReviewButton}>
                      <Text colorMain btnTextMedium>Đánh giá sách</Text>
                    </Col>
                  )} */}
                </Row>

                {/* {Boolean(showReviewForm) && (
                  <GiveAReview
                    bookId={data.id}
                    bookName={data.name}
                    onSuccess={() => getListReview(id)} marginTop24
                  />
                )} */}

                <Col marginTop24>
                  {reviewData.rows.length === 0 ? (
                    <>
                      <Col height1 backgroundColor={COLOR.BORDER} />
                      <Text marginTop24>Chưa có đánh giá</Text>
                    </>
                  ) : reviewData.rows.map((val, i) => (
                    <ReviewItemPublic key={'review-' + val.id} data={val} marginBottom16 />
                    // <ReviewItem data={val} key={'review-' + val.id} marginBottom16>
                    //   <LikeComments entityData={val} postType='review' />
                    // </ReviewItem>
                  ))}
                  <UseMemo deps={[hasMore, isLoadingMore]}>
                    <LoadMoreDetect
                      hasMore={hasMore}
                      isLoadingMore={isLoadingMore}
                      fetcher={() => {
                        getListReview(id, page.current + 1);
                      }}
                    />
                  </UseMemo>
                </Col>

              </Col>

              <RatioCol width={rV({ xs: '100%', md: 145 })} ratio={145 / 232} marginBottom16>
              </RatioCol>
            </Row>
          </MainContainer>

        </Scroll>
      </Col>
    </Col>
  );
};

export default BookDetail;
