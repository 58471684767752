import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { ISearchAndFilterOptions, IUser } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';

interface ISetters {
  [additionSetter: string]: (v : any) => void,
}

interface IUpdate {
  content?: string,
  quoteBy?: {
    id: string,
    name: string,
  }, 
  tagBooks?: Array<{ id: string, name: string, image: string}>,
  id: string,
  visibility?: string,
}
class Quote extends PersistReady {

  constructor() {
    super();
  }

  state = {
    
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {

    return [
      {  },
      {
        getList: this.getList,
        create: this.create,
        update: this.update,
        detail: this.detail,
      }
    ];
  }

  getList = async ({ visibility, page = 1, pageSize = 20, options } : { visibility: string, page: number, pageSize?: number, options?: ISearchAndFilterOptions }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/quotes/list', { visibility, page, pageSize, options });
      return res.data;
    });
  }

  create = async ({ content, quoteBy, tagBooks }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/quotes', { content, quoteBy, tagBooks });
      return res.data;
    });
  }

  update = async ({ content, quoteBy, id, visibility, tagBooks } : IUpdate) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/quotes/update', { content, quoteBy, id, visibility, tagBooks });
      return res.data;
    });
  }

  detail = async id => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/quotes/'+id, {  });
      return res.data;
    });
  }
}

export default new Quote();
