import React, { useEffect } from 'react';
import { DEVICE_INFO } from 'const';
import { useNavFunc } from './navFunc';
import { convertRouteToLink } from './linking';

interface IAProps {
  children?: any,
  route?: string,
  params?: any,
  forceLink?: string,
  preData?: any,
  onPress?: any,
  style?: any,
}

import './A.css';

const A = ({ children, forceLink, route, params, onPress, preData, style } : IAProps) => {
  const { navigate , goWithPreData} = useNavFunc();
  if (!DEVICE_INFO.IS_WEB) return children;

  const href = forceLink || convertRouteToLink(route, params);
  const target = !href ? undefined : href.indexOf('https://readism.vn') === 0 ? '_self' : '_blank';

  const handlePress = (e) => {
    e.preventDefault();
    if (onPress) return onPress(e);
    if (!!forceLink) {
      window.open(forceLink, target);
      return;
    }
    if (!route) return;
    if (!!preData && !!preData.id && !!params && !!params.id) {
      goWithPreData(route, params, preData);
    } else {
      navigate(route, params);
    }
  };

  return (
    <a href={href} target={target} onClick={handlePress} style={style}>
      {children}
    </a>
  )
};

export default A;