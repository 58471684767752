import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { IUser, ISetting } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';

interface ISetters {
  setListSettings?: (v : any) => void,
  [additionSetter: string]: (v : any) => void,
}

interface IState {
  listSettings: Array<ISetting>,
}

class Setting extends PersistReady {

  constructor() {
    super();
  }

  state = {
    listSettings: [],
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {
    const [listSettings, setListSettings] = useGlobalState(this.state.listSettings, 'settingStore_listSettings');
    if (!this.setters.setListSettings) this.setters.setListSettings = setListSettings;
    useEffect(() => {
      this.updateState({ listSettings });
    }, [listSettings]);
    return [
      { listSettings },
      {
        getList: this.getList,
        upsert: this.upsert,
      }
    ];
  }

  getList = async () => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/settings', { page: 1, pageSize: 100 });
      if (res.data.data && res.data.data.rows) {
        !!this.setters.setListSettings && this.setters.setListSettings(res.data.data.rows);
      }
      return res.data;
    });
  }

  upsert = async ({ name, value, data }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/settings/upsert', { name, value, data });
      if (res.data.data) {
        const cloneList = this.state.listSettings.slice();
        const findIndex = cloneList.findIndex(val => val.name === name);
        if (findIndex === -1) cloneList.push(res.data.data);
        else cloneList[findIndex] = res.data.data;
        !!this.setters.setListSettings && this.setters.setListSettings(cloneList);
      }
      return res.data;
    });
  }
}

export default new Setting();
