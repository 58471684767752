import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={12}
      height={16}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M9.48 7.174L9.1 3.687h1.26c.38 0 .703-.292.703-.703V1.578a.713.713 0 00-.704-.703H2.391a.694.694 0 00-.704.703v1.406c0 .41.293.704.704.704h1.23l-.38 3.486C1.833 7.818.75 9.02.75 10.484c0 .41.293.704.703.704h3.984v3.164l.704 1.406c.088.176.351.176.44 0l.702-1.406c0-.03.03-.059.03-.088v-3.076h3.984c.38 0 .703-.293.703-.704 0-1.494-1.113-2.666-2.52-3.31z"
        fill={props.fill || "#949494"}
      />
    </Svg>
  )
}

export default SvgComponent
