import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { CMSLayout, Button, Input, Uploader, Select, ReviewItem, ReviewItemCMS, useFetchUIState, Popup } from 'components';
import { Linking } from 'react-native';
import { useNavFunc, Reset } from 'navigation';
import { useRoute } from '@react-navigation/native';
import { BOOK_VISIBILITY, COLOR, DEVICE_INFO, ERROR, SCREEN_NAME } from 'const';
import { ICON } from 'assets';
import Store from 'store';
import { IBook, IReview } from 'type';

interface IBookListReviewProps {
  data: {
    count: number,
    rows: Array<IReview>,
  },
  bookData?: IBook,
  onUpdateBookData?: any
}

const CMSEditBookListReview = (props : IBookListReviewProps) => {
  const { goBackOrTo, getScreenPreData, navigate } = useNavFunc();
  const rV = useDynamicResponsiveValue();
  const [{ loading }, setUI] = useFetchUIState();
  const [{}, rApi] = Store.Reviews.createStore();

  const syncData = async () => {
    setUI({ loading: true });
    const [res, err] = await rApi.syncDataSingleBook(props.bookData.id);
    if (err || res.error) {
      setUI({ loading: false });
      Popup.showError(ERROR.parseError(err || res.error));
      return;
    }
    setUI({ loading: false });
    props.onUpdateBookData && props.onUpdateBookData(res.data);
  }

  return (
    <Col marginTop24>
      {props.data.rows.length === 0 ? (
        <Text>Chưa có đánh giá</Text>
      ) : (
        <>
          {Boolean(props.bookData) && (
            <>
              <Text marginBottom8>Số đánh giá được tính: {props.bookData.numberOfReview}</Text>
              <Text marginBottom8>Điểm trung bình: {props.bookData.averageRating}</Text>
              <Button loading={loading} onPress={syncData} small solid text='Đồng bộ chỉ số' marginBottom16 width150 height30 />
            </>
          )}
          {props.data.rows.map((val, i) => (
            <ReviewItemCMS data={val} key={'review-'+val.id} marginBottom16 />
          ))}
        </>
      )}
    </Col>
  );
};

export default CMSEditBookListReview;