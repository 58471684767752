import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { COLOR, ERROR, SCREEN_NAME, STYLES } from 'const';
import { useNavFunc } from 'navigation';
import { IConversation, IMessage, IQuote } from 'type';
import { ICON } from 'assets';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import { useDropdownOptions, usePostCURDDropdown } from '../../hooks';
import { Popup, showModal, Avatar } from '../../elements';
import LikeComments from '../LikeComments/LikeComments';
import QuoteExportImage from '../QuoteExportImage';
import Store from 'store';

interface IConversationItemProps {
  data: IConversation,
  children?: any,
  onPress: any,
  isActive?: boolean,
  [anyProps: string]: any,
}

const ConversationItem = (props: IConversationItemProps) => {
  const { navigation, goWithPreData } = useNavFunc();
  const propStyle = usePropsStyle(props);
  // const [{ user }] = Store.User.createUserStore();
  const { user } = Store.User.state;

  const { data } = props;

  if (!data) return null;

  const lastSender = data.userData[data.lastMessage?.sender] || { name: '', avatar: '', id: 0 };
  const isLastSenderMe = lastSender.id === user.id;

  const otherUserData = (() => {
    const conversation = data;
    const userIds = conversation.userIds || [];
    const otherUserIds = Object.keys(userIds).filter(val => val !== user.id);
    if (otherUserIds.length === 0) return;
    const otherId = otherUserIds[0];
    const otherData = conversation.userData[otherId];
    return otherData;
  })();

  const myData = data.userData[user.id];

  return (
    <Col
      onPress={props.onPress} style={propStyle}
      onHoverStyle={{ backgroundColor: COLOR.PRI_100 }}
      borderWidth1 borderColor={props.isActive ? COLOR.MAIN : COLOR.BORDER} borderRadius4 bgWhite padding12
    >
      <Row>
        <Avatar name={otherUserData?.name} uri={otherUserData?.avatar} size={40} />
        <Col marginLeft12 flex1>
          <Text subtitle1 marginBottom4 colorMain>{otherUserData?.name}</Text>
          <Text caption numberOfLines={1} bold={myData?.unread > 0}>{isLastSenderMe ? 'bạn: ' : ''}{data.lastMessage?.content}</Text>
        </Col>
      </Row>
    </Col>
  );
};

export default ConversationItem;