import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Col, Text, Scroll, Img, Row } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import { Header, Button, MainContainer, RatioCol, GroupItem, GroupDetailLayout } from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';

import Feed from './GroupDetail.Feed';
import Members from './GroupDetail.Members';
import Books from './GroupDetail.Books';

const GroupDetail = () => {
  const { navigate, navigation, route } = useNavFunc();
  const { h2 } = useFontSizeStyle();
  const { tab, id, name } = route.params;

  let ChildrenComponent = (p) => null;
  if (tab === 'feed') ChildrenComponent = Feed;
  else if (tab === 'thanh-vien') ChildrenComponent = Members;
  else if (tab === 'sach') ChildrenComponent = Books;

  return useMemo(() => (
    <GroupDetailLayout>
      {ChildrenComponent}
    </GroupDetailLayout>
  ), [tab]);
};

export default GroupDetail;