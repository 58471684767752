import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { MainContainer, Header, AddToBookshelfModal, RatioCol, RateStars, ReviewItem, ReviewItemPublic, GiveAReview, showModal, CollapsableText } from 'components';
import { IMAGE, ICON } from 'assets';
import { STYLES, useFontSizeStyle, SCREEN_NAME, ERROR, fSize, COLOR } from 'const';
import { useNavFunc, Reset, getScreenPreData } from 'navigation';
import { IBook } from 'type';
import Store from 'store';
import { VarHelper } from 'helpers';

const BookDetailMyReview = ({ reviewData, bookData, onAddReview, autoReview }) => {
  const rV = useDynamicResponsiveValue();
  return (
    <>
      <Col marginTop24>
        <Text bold fontSize={fSize(rV, 18)}>ĐÁNH GIÁ CỦA BẠN</Text>
        {Boolean(reviewData.myReviews.length > 0) ? (
          <ReviewItemPublic data={reviewData.myReviews[0]} marginTop16 />
        ) : (
          <GiveAReview
            bookId={bookData.id}
            bookName={bookData.name}
            bookGenres={bookData.genres}
            onSuccess={onAddReview} marginTop24
            autoReview={autoReview}
          />
        )}
      </Col>

      <Col height1 backgroundColor={COLOR.BORDER} marginTop24 />
    </>
  );
};

export default BookDetailMyReview;
