
import React, { useState, useRef } from 'react';
import { Col, Row, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { COLOR, DEVICE_INFO, SCREEN_NAME } from 'const';
import { ICON } from 'assets';
import Store from 'store';
import { showModal, hideModal, Input } from '../../elements';
import { listNav } from './listNav';
import { VarHelper } from 'helpers';
import { MaterialIcons, Ionicons } from '@expo/vector-icons';
import { HeaderNotificationDropdown } from './HeaderNotificationDropdown';
import SearchBookSuggestion from '../SearchBookSuggestion';

interface IHeaderSideMenuProps {
	navigation: {
		navigate: Function,
	},
	route: {
		name: string,
	},
}

const HeaderSideMenuDrawer = ({ navigation, route }: IHeaderSideMenuProps) => {
	const [searchKeyword, setSearchKeyword] = useState('');
	const [{ token, user, score }, userApi] = Store.User.createUserStore();

	const withClose = (callback = () => { }) => {
		hideModal();
		callback();
	};

	const logout = () => {
		userApi.logout();
		navigation.navigate(SCREEN_NAME.Login);
	};

	const listUserNav = !token ? [
		{ label: 'Đăng ký', onPress: () => navigation.navigate(SCREEN_NAME.Register) },
		{ label: 'Đăng nhập', onPress: () => navigation.navigate(SCREEN_NAME.Login) },
	] : [
		{ label: 'Trang cá nhân', onPress: () => navigation.navigate(SCREEN_NAME.UserProfile, { id: user.id, name: VarHelper.toSlug(user.displayName) }) },
		user.type === 'UNVERIFY-USER' ? { label: 'Xác thực tài khoản', onPress: () => navigation.navigate(SCREEN_NAME.AccountVerifyNotice) } : undefined,
		user.type === 'ADMIN' ? { label: 'Trang quản trị', onPress: () => navigation.navigate(SCREEN_NAME.CMSHome) } : undefined,
		user.type === 'ADMIN' ? { label: 'Admin Newsfeed', onPress: () => navigation.navigate(SCREEN_NAME.HomeAdminAllFeed) } : undefined,
		{ label: 'Bạn bè', onPress: hideModal },
		user.type === 'USER' ? { label: 'Đóng góp sách', onPress: () => navigation.navigate(SCREEN_NAME.CreateBook) } : undefined,
		user.type === 'USER' ? { label: 'Viết mới', onPress: () => navigation.navigate(SCREEN_NAME.CreateWriting) } : undefined,
		{ label: 'Đăng xuất', onPress: logout },
	].filter(val => !!val);

	const rV = useDynamicResponsiveValue();
	const listResponsiveNav = rV({
		lg: listNav,
		xs: (() => {
			const navs = [];
			listNav.forEach(val => {
				if (!val.children || val.children.length === 0) return navs.push(val);
				val.children.forEach(child => {
					navs.push(child);
				});
			});
			return navs;
		})(),
	});

	return (
		<Row height={DEVICE_INFO.HEIGHT} width={DEVICE_INFO.WIDTH}>
			<Col bgWhite flex1 shadow height100p>
				{Boolean(DEVICE_INFO.IS_IOS) && <Col height={DEVICE_INFO.STATUS_BAR_HEIGHT} />}

				<Row justifyContent="space-between" height60 padding12 paddingLeft10 borderBottomWidth={1} borderBottomColor={COLOR.BORDER}>
					<ICON.ReadismWithTextLogo green width={173} />
				</Row>

				<Col padding6 borderBottomWidth1 borderBottomColor={COLOR.BORDER}>
					<Col>
						<SearchBookSuggestion navigation={navigation} isInModal />
					</Col>
					<Row xs='50%' padding6 marginLeft={-12} marginRight={-12}>
						{listResponsiveNav.map((val, i) => (
							<Col
								key={'nav-' + i}
								padding12
								margin6
								onPress={() => withClose(() => {
									val.route && navigation.navigate(val.route);
								})}
							>
								<Text subtitle1>{val.label}</Text>
							</Col>
						))}
					</Row>

				</Col>

				<Col padding12>
					<Row padding12>
						<MaterialIcons name="attach-money" size={24} color={COLOR.MAIN} />
						<Row>
							<Text>Xu: <Text bold>{String(score)}</Text></Text>
						</Row>
					</Row>
					{listUserNav.map((val, i) => (
						<Col
							key={'nav-' + i}
							padding12
							onPress={() => withClose(() => {
								val.onPress && val.onPress();
							})}
						>
							<Text body1>{val.label}</Text>
						</Col>
					))}
				</Col>

			</Col>
			<Col width={!token ? 68 : 81} height100p onPress={hideModal} />
		</Row>
	);
};

export const useHeaderSideMenuDrawer = (navigation, route) => {

	const showDrawer = () => {
		showModal({
			component: (
				<HeaderSideMenuDrawer navigation={navigation} route={route} />
			),
			modalProps: {
				style: {
					position: 'absolute',
					top: 0,
					left: 0,
				},
			},
			noWrapper: true,
			animation: 'slideInLeft',
		})
	};

	return {
		showDrawer,
		hideDrawer: hideModal,
	};
};
