import React, { useEffect, useState } from 'react';
import { Col, Text, Row, Scroll } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME } from 'const';
import { Header, Button, LeftSidebarOneThird, Avatar, Placeholder, ReviewItemPublic, FeedItem, usePreDataOrFetchApi, LoadMoreDetect, QuoteNoCreatorItem } from 'components';
import { ICON } from 'assets';
import Store from 'store';
import { VarHelper } from 'helpers';
import { IActivity, IUser, IUserWithFriends } from 'type';
import UserProfile from './UserProfile';

const UserProfileAllFriends = () => {
  const { route, goBackOrTo, navigate } = useNavFunc();
  // @ts-ignore
  const { id, name } = route.params;
  const [{ }, wApi] = Store.Writing.createStore();
  const [userData] = usePreDataOrFetchApi<IUserWithFriends>(id, 'user');

  return (
    <UserProfile noForce noFetchOnFocus>
      {!userData ? <Col /> :
        <Scroll>
          <Col>
            <Row justifyContent="space-between">
              <Col>
                <Text btnTextLarge>BẠN BÈ</Text>
                <Text marginTop4 body2 color={COLOR.GRAY_500}>{userData.friends.length || 0} bạn bè</Text>
              </Col>
              <Col padding4 onPress={() => goBackOrTo(SCREEN_NAME.UserProfile, { id, name })}>
                <Text colorMain subtitle1>Quay lại</Text>
              </Col>
            </Row>
          </Col>

          <Col marginTop16 borderWidth1 borderColor={COLOR.BORDER} borderRadius10 bgWhite>
            <Row xs='100%' md='50%' padding12>
              {userData.friends?.map((u, i) => (
                <Row
                  onPress={() => navigate(SCREEN_NAME.UserProfile, { id: u.id, name: VarHelper.toSlug(u.name) })}
                  key={'member-' + i} margin12 padding12 borderWidth1 borderColor={COLOR.BORDER} borderRadius10
                >
                  <Avatar size={80} uri={u.avatar} name={u.name} />

                  <Col flex1 marginLeft16>
                    <Text subtitle1>{u.name}</Text>
                  </Col>
                </Row>
              ))}
            </Row>
          </Col>
        </Scroll>
      }
    </UserProfile>
  );
};

export default UserProfileAllFriends;