import React, { useEffect, useRef } from 'react';

import './HTMLView.css';
interface IHTMLViewProps {
  content: string,
  [anyProp: string]: any,
}



const HTMLView = (props : IHTMLViewProps) => {
  const htmlViewRef = useRef(null);
  useEffect(() => {
    const el = htmlViewRef.current;
    if (!el || !el.querySelector) return;
    const imgEls = el.querySelectorAll("img");
    if (imgEls.length == 0) return;
    imgEls.forEach(imgEl => {
      if (!imgEl.parentElement) return;
      if (!imgEl.parentElement.classList.contains('image-center')) {
        imgEl.parentElement.className += ' image-center';
      }
    });
  }, [props.content]);
  return (
    <div ref={htmlViewRef} dangerouslySetInnerHTML={{ __html: props.content }} className='readism-html-view'>

    </div>
  )
};

export default HTMLView;
