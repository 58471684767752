import { RatioCol } from 'components/elements';
import { COLOR, STYLES } from 'const';
import { ICON } from 'assets';
import React from 'react';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { RateStars, showModal } from 'components';
import { IBook, IBookShelf, IWriting } from 'type';
interface IWritingInProfileItemProps {
  data: IWriting,
  onPress?: any,
  [anyProps: string]: any,
}

const WritingInProfileItem = (props : IWritingInProfileItemProps) => {
  const propStyle = usePropsStyle(props);
  const { data } = props;
  
  return (
    <Row style={propStyle} height104 bgWhite shadow onPress={props.onPress}>
      <Col width={73} height={104}>
        <Col
          backgroundColor={COLOR.PRI_600} opacity={0.3}
          width56 height89 absolute top7 left0
        />
        <Col
          backgroundColor={COLOR.PRI_600} opacity={0.3}
          width60 height96 absolute top4 left4
        />
        <Col
          backgroundColor={COLOR.PRI_600}
          width65 height104 absolute top0 right0
        >
          <Img
            source={{ uri: data.image }}
            style={STYLES.all100p}
          />
        </Col>
      </Col>
      <Col flex1 marginLeft16>
        <Text subtitle1>{data?.name}</Text>
        <Text body1 marginTop8>{data?.numberOfChapter} chương</Text>
      </Col>
    </Row>
  );
};

export default WritingInProfileItem;
