import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Avatar } from '../elements';
import Uploader from './uploader/Uploader';
import { Col, Text, Row } from 'react-quick-style-components';
import { ICON } from 'assets';
import Store from 'store';
import { VarHelper } from 'helpers';
import { ERROR } from 'const';
import { IUser } from 'type';

interface IAvatarProps {
  uri: string,
  size?: number,
  name: string,
}

const AvatarDisplayAndUpdate = (props : IAvatarProps) => {
  const [{ user }, uApi] = Store.User.createUserStore();
  const uploaderRef = useRef<{ openFileDialog: Function, uploadLocal: Function }>();
  const [localFile, setLocalFile] = useState({
    path: props.uri,
    mime: '',
    name: '',
    raw: undefined,
  });
  const localFileRef = useRef(localFile);

  useEffect(() => {
    setLocalFile({ ...localFile, path: props.uri });
  }, [props.uri]);

  const getUploadedUrl = async () => {
    return VarHelper.erria(async () => {
      const latestLocal = localFileRef.current;
      if (!latestLocal.raw && !latestLocal.path) return '';
      if (!latestLocal.raw && !!latestLocal.path && latestLocal.path.includes('https://')) return latestLocal.path;
      const [uploadedUrl, err] = await uploaderRef.current.uploadLocal(latestLocal);
      if (err) throw err;
      return uploadedUrl;
    });
  };

  const updateAvatar = async () => {
    const [uploadedUrl, err] = await getUploadedUrl();
    if (err) {
      console.log(ERROR.parseError(err));
      return;
    }
    const [res, err2] = await uApi.update({ id: user.id, photoUrl: uploadedUrl } as IUser);
    if (!err2 && !!res && !!res.data) {
      // console.log('res.data', res.data);
      uApi.setUser(res.data)
    }
  };

  return (
    <Col borderRadius='50%' overflow="hidden" onHoverStyle={{ opacity: 1 }} useNestedHover>
      {Boolean(!localFile.path) ? (
        <Avatar uri={''} size={props.size} name={props.name} />
      ) : (
        <Avatar uri={localFile.path} size={props.size} name={props.name} />
      )}
      <Uploader
        ref={uploaderRef}
        onChangeLocal={(newLocal) => {
          localFileRef.current = newLocal;
          setLocalFile(newLocal);
          updateAvatar();
        }}
      />
      <Col
        onPress={() => uploaderRef.current?.openFileDialog()}
        absoluteFill middle display="none"
        backgroundColor="rgba(0,0,0,0.5)" onHoverStyle={{ display: 'flex' }}
      >
        <ICON.SolidPen fill="white" width={32} height={32} />
      </Col>
    </Col>
  );  
};

export default AvatarDisplayAndUpdate;