import React, { useState, useEffect } from 'react';
import { Col, Row, Text, Scroll } from 'react-quick-style-components';
import { useWindowDimensions, Platform } from 'react-native';
import { ICON } from 'assets';
import { hideModal, Avatar, Input, Button, Popup, HTMLView } from 'components';
import { COLOR, ERROR } from 'const';
import Store from 'store';
import LikeComment from '../LikeComments/LikeComments';

interface ICommentModalProps {
  mangaId: string,
  mangaChapterId: string,
  id: string,
  html: string,
  navigation: any,
}

const CommentModal = (props: ICommentModalProps) => {
  const { width, height } = useWindowDimensions();
  const containerWidth = width < 768 ? width * 0.9 : width * 0.8;
  const containerHeight = width < 768 ? height * 0.8 : height * 0.7;
  const [tempComment, setTempComment] = useState('');
  const { user } = Store.User.state;
  const [{ }, wApi] = Store.Writing.createStore();
  const [data, setData] = useState();

  const sendComment = async (abc) => {

  };

  const getData = async () => {
    const [res, err] = await wApi.extraInfoDetail(props.mangaId, props.mangaChapterId, props.id);
    if (!!res) {
      setData(res.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Col
      height={containerHeight}
      bgWhite borderRadius10 onPress={() => { }} activeOpacity={1}
      stretch
    >
      <Row>
        <Col flex1 />
        <Col padding8 onPress={hideModal}>
          <ICON.Close width={16} height={16} />
        </Col>
      </Row>
      <Col height={150} middle>
        <Scroll height={150} scrollViewProps={{ contentContainerStyle: { padding: 16 } }}>
          <HTMLView content={props.html} />
        </Scroll>
      </Col>
      <Col flex1 backgroundColor={COLOR.GRAY_100}>
        <Col flex1>
          <Scroll flex1 scrollViewProps={{ contentContainerStyle: { padding: 24 } }}>
            {Boolean(data) && (
              <LikeComment
                postType='writing-chapter-extra-info'
                entityData={data}
                navigation={props.navigation}
                hideLike
                showCommentTextInputByDefault
              />
            )}
          </Scroll>

        </Col>
      </Col>
    </Col>
  );
};

export default CommentModal;
