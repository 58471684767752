import React, { useEffect } from 'react';
import { Col, Row, useDynamicResponsiveValue, Img, Text } from 'react-quick-style-components';
import { useWindowDimensions } from 'react-native';
import { ICON, IMAGE } from 'assets';
import { hideModal, RatioCol } from 'components/elements';
import { DEVICE_INFO, STYLES } from 'const';
import { IQuote } from 'type';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons';

import { DomHelper } from 'helpers';

const LIST_BG = [
  IMAGE.QuoteBg1,
  IMAGE.QuoteBg2,
  IMAGE.QuoteBg3,
  IMAGE.QuoteBg4,
  IMAGE.QuoteBg5,
  IMAGE.QuoteBg6,
];

interface IQuoteExportImageProps {
  data: IQuote,
}

const QuoteExportImage = (props : IQuoteExportImageProps) => {
  const rV = useDynamicResponsiveValue();
  const { width, height } = useWindowDimensions();

  const bgImg = LIST_BG[Math.floor(Math.random() * LIST_BG.length)];

  const popupWidth = rV({ xs: 0.9 * Math.min(width, height) , md: 0.7 * Math.min(width, height)  });

  const saveImage = async () => {
    if (DEVICE_INFO.IS_WEB) {
      const htmlToImage = require('html-to-image');
      const FileSaver = require('file-saver');
      const node = document.getElementById('export-quote-image');

      // const domtoimage = require('dom-to-image-improved');
      // const blob = await domtoimage.toBlob(document.getElementById('export-quote-image'))
      // FileSaver.saveAs(blob, 'trich-dan.png');

      const dataUrl = await htmlToImage.toJpeg(node, { quality: 1 });
      // FileSaver.saveAs(dataUrl, 'my-node.png');
      var link = document.createElement('a');
      link.download = 'trich-dan.jpeg';
      link.href = dataUrl;
      link.click();
    }
    hideModal();
  }

  useEffect(() => {
    DomHelper.loadjscssfile("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,500&display=swap", "css");
  }, []);

  return (
    <Col width={popupWidth} alignSelf="center">
      <Row justifyContent='flex-end' >
        <Col onPress={saveImage} width48 height48 borderRadius24 backgroundColor="rgba(0,0,0,0.5)" middle>
          <ICON.Download width={20} height={24} fill="white" />
        </Col>
        <Col onPress={hideModal} marginLeft24 width48 height48 borderRadius24 backgroundColor="rgba(0,0,0,0.5)" middle>
          <ICON.Close width={20} height={24} fill="white" />
        </Col>
      </Row>
      <RatioCol marginTop24 width={popupWidth} ratio={1} nativeID='export-quote-image'>
        <Img source={bgImg} style={STYLES.all100p} />
        <Col absoluteFill middle backgroundColor="rgba(0,0,0,0.5)">
          {/* <ICON.Readism06 white height={40} revertColor="transparent" /> */}
          <Img source={IMAGE.Readism06} height40 width173 />
          <Text
            numberOfLines={5} paddingHorizontal16 center marginTop32 fontStyle="italic" colorWhite fontSize={18} lineHeight={21.78}
            fontFamily="Montserrat"
          >"{props.data.content}"</Text>

          <Row marginTop16>
            <Col height2 width12 bgWhite />
            <Text subtitle1 marginHorizontal8 colorWhite bold fontFamily="Montserrat" fontWeight="bold">{props.data.quoteBy.name}</Text>
            <Col height2 width12 bgWhite />
          </Row>
        </Col>
      </RatioCol>
    </Col>
  )
};

export default QuoteExportImage;