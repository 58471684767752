import React, { memo, useState, useRef, useEffect } from 'react';
import { Col, Row, Text, Img, useResponsiveStyle, useDynamicResponsiveValue } from 'react-quick-style-components';
import { COLOR, DEVICE_INFO, SCREEN_NAME } from 'const';
import { ICON } from 'assets';
import Store from 'store'
import { useNavigation, useRoute } from '@react-navigation/native';
import { Input, Avatar, Popup, showModal } from '../../elements';
import SearchGeneralSuggestion from '../SearchGeneralSuggestion';
import MainContainer from '../../layouts/MainContainer';
import { useHeaderAvatarDropdown } from './HeaderAvatarDropdown';
import { useHeaderSideMenuDrawer } from './HeaderSideMenuDrawer';
import { useHeaderNotificationDropdown } from './HeaderNotificationDropdown';
import { listNav } from './listNav';
import { useNavFunc, A } from 'navigation';
import Nav from './Nav';
import { TimeHelper } from 'helpers';
import { useRefState2 } from 'components';
import { Animated } from 'react-native';
import GlobalEvent, { useEvent } from 'js-events-listener/react';

interface IHeaderProps {
  searchKeyword?: string,
}

const Header = (props: IHeaderProps) => {
  const [{ token, user, unreadNoti, score }, userApi] = Store.User.createUserStore();
  const [{ unread: unreadMesage }, mApi] = Store.Message.createStore();
  const viewport = useResponsiveStyle({ xs: 'xs', lg: 'lg' });
  const rV = useDynamicResponsiveValue();
  const { route, navigate, navigation } = useNavFunc();
  const [showSearch, setShowSearch] = useState(!!props.searchKeyword);
  const [searchKeyword, setSearchKeyword] = useState(props.searchKeyword || '');

  useEffect(() => {
    if (!!props.searchKeyword) {
      setShowSearch(true);
      setSearchKeyword(props.searchKeyword);
    }
  }, [props.searchKeyword]);

  const { showDropdown } = useHeaderAvatarDropdown(navigation);
  const { showDropdown: showNotiDropdown } = useHeaderNotificationDropdown(navigation);
  const { showDrawer } = useHeaderSideMenuDrawer(navigation, route);

  const searchboxRef = useRef(null);

  useEvent('OPEN_NOTIFICATION_DROPDOWN', (e) => {
    // showNotiDropdown({
    //   nativeEvent: {
    //     clientX: 10061,
    //     clientY: 31,
    //   }
    // });
    showNotiDropdown(e);
  }, []);

  const renderLeft = () => {
    return (
      <Row flex1>
        <Col paddingVertical10 onPress={() => navigate(SCREEN_NAME.Home)}>
          <ICON.ReadismWithTextLogo white height={40} />
        </Col>
        <Row
          display={rV({ xs: 'none', lg: 'flex' })}
          marginLeft32
          height60
          flex1
        >
          {showSearch ? (
            <Row flex1>
              {/* <Input
                flex1
                backgroundColor="rgba(255,255,255,0.1)"
                label=""
                placeholder="Tìm kiếm Sách, Tác giả"
                value={searchKeyword}
                onChange={setSearchKeyword}
                inputProps={{
                  placeholderTextColor: COLOR.WHITE_100,
                }}
                inputStyle={{
                  color: COLOR.WHITE_100,
                }}
                onRef={ref => searchboxRef.current = ref}
                onEnter={() => navigate(SCREEN_NAME.BookHomepage, { search: searchKeyword })}
              /> */}
              <Col flex1>
                <SearchGeneralSuggestion
                  navigation={navigation}
                />
              </Col>
            </Row>
          ) : listNav.map((val, i) => (
            <Nav
              key={'nav-' + i}
              activeRoute={route}
              screen={val.route}
              label={val.label}
              children={val.children}
              navigation={navigation}
            />
          ))}
        </Row>
      </Row>
    );
  };

  const openDrawer = () => {

  };

  const renderAvatar = () => {
    return (
      <Row onPress={viewport === 'xs' ? showDrawer : showDropdown}>
        <Col width40 height40 bgMain marginRight7>
          <Avatar size={40} uri={user.photoUrl} name={user.displayName} />
        </Col>
        <ICON.CaretDownWhite />
      </Row>
    );
  }

  const renderNotiMes = (props = {}) => {
    return (
      <>
        <Col
          padding={rV({ xs: 6, md: 12 })} marginRight8
          onPress={showNotiDropdown}
          nativeID='noti-icon'
        >
          <ICON.Notification />
          {Boolean(unreadNoti > 0) && (
            <Col
              backgroundColor={COLOR.DANGER_500} width20 height20 borderRadius10
              absolute top0 right0 middle
            >
              <Text colorWhite fontSize={12}>{unreadNoti < 10 ? unreadNoti : '9+'}</Text>
            </Col>
          )}
        </Col>
        <Col
          padding={rV({ xs: 6, md: 12 })} {...props}
          onPress={() => navigate(SCREEN_NAME.DirectMessage)}
        >
          <ICON.Mail />
          {Boolean(unreadMesage > 0) && (
            <Col
              backgroundColor={COLOR.DANGER_500} width20 height20 borderRadius10
              absolute top0 right0 middle
            >
              <Text colorWhite fontSize={12}>{unreadNoti < 10 ? unreadMesage : '9+'}</Text>
            </Col>
          )}
        </Col>
      </>
    )
  }

  const renderRight = () => {
    return viewport === 'xs' ? (
      Boolean(token) ? (
        <Row>
          {renderNotiMes({ marginRight: 12 })}
          {renderAvatar()}
        </Row>
      ) : <Col padding12 onPress={showDrawer}>
        <ICON.BurgerMenu fill={COLOR.WHITE_100} />
      </Col>
    ) : (
      <Row>

        <Col
          padding12 marginRight8
          onPress={() => {
            setShowSearch(!showSearch);
            if (!showSearch) setTimeout(() => { !!searchboxRef.current?.focus && searchboxRef.current.focus() }, 500);
          }}
        >
          <ICON.Search />
        </Col>

        {Boolean(token) ? (
          <>
            {renderNotiMes({ marginRight: 60 })}
            {renderAvatar()}
          </>
        ) : (
          <>
            <Col
              width90 height37 borderRadius6 middle bgMain marginRight8
              borderWidth1 borderColor={COLOR.WHITE_100}
              onPress={() => navigate(SCREEN_NAME.Register)}
            >
              <Text colorWhite btnTextSmall>Đăng ký</Text>
            </Col>

            <Col
              width90 height37 borderRadius6 middle bgWhite
              onPress={() => navigate(SCREEN_NAME.Login)}
            >
              <Text colorMain btnTextSmall>Đăng nhập</Text>
            </Col>
          </>
        )}
      </Row>
    );
  };

  return (
    <Col bgMain>
      {Boolean(DEVICE_INFO.IS_IOS) && <Col height={DEVICE_INFO.STATUS_BAR_HEIGHT} />}
      <MainContainer
        height60
        onResponsiveStyle={{
          xs: { paddingHorizontal: 12 },
          lg: { paddingHorizontal: 0 },
        }}
      >
        <Row flex1>
          {renderLeft()}
          {renderRight()}
        </Row>
      </MainContainer>
    </Col>
  )
};

const HeaderSticky = (props) => {
  /*
  const stickyTop = useRef(new Animated.Value(-60)).current;
  // @ts-ignore
  const getStickyTop = () => stickyTop.__getValue();
  const handleScrollRef = useRef<any>((e) => {
    TimeHelper.debounce(() => {
      if (window.scrollY > 70) {
        if (getStickyTop() !== 0) {
          Animated.timing(stickyTop, {
            duration: 150,
            useNativeDriver: true,
            toValue: 0
          }).start();
        }
      } else if (window.scrollY <= 60) {
        if (getStickyTop() !== -60) {
          Animated.timing(stickyTop, {
            duration: 0,
            useNativeDriver: true,
            toValue: -60
          }).start();
        }
      }
    }, 300);
  });
  useEffect(() => {
    window.addEventListener('scroll', handleScrollRef.current);
    return () => {
      window.removeEventListener('scroll', handleScrollRef.current);
    }
  }, []);
  const stickyStyle = { position: 'fixed', left: 0, right: 0, zIndex: 10 };
  
  return (
    <>
      <Col height60 />
      <Animated.View
        // @ts-ignore
        style={{
          ...stickyStyle,
          top: stickyTop
        }}
      >
        <Header {...props} />
      </Animated.View>
    </>
  )
  */
 return (
   <>
    <Col height60 />
    <Col
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10,
      }}
    >
      <Header {...props} />
    </Col>
   </>
 )
}

export default memo(HeaderSticky);
