import React, { useState, useEffect } from 'react';
import { Col, Row, Text, Img, usePropsStyle } from 'react-quick-style-components';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME } from 'const';
import { RatioCol } from 'components';
import { STYLES } from 'const';
import { VarHelper } from 'helpers';

const BookRecommendationRightSidebar = (props) => {
  const [{ listSettings }, sApi] = Store.Setting.createStore();
  const { navigate } = useNavFunc();
  const books = (() => {
    const find = listSettings.find(val => val.name === 'BOOK_SIDEBAR_RECOMMENDATION');
    if (!find) return [];
    return find.data || [];
  })();
  
  useEffect(() => {
    sApi.getList();
  }, []);
  const propStyle = usePropsStyle(props);
  if (books.length === 0) return null;
  return (
    <Col style={propStyle} bgWhite shadow>
      {books.map((b, bI) => {
        const bookImage = b.image;
        return (
          <Row padding8 margin8 stretch key={b.id}>
            <RatioCol ratio={100 / 160} width={50} shadow>
              <Img source={{ uri: bookImage }} style={STYLES.all100p} />
            </RatioCol>
            <Col flex1 marginLeft16>
              <Text numberOfLines={3}>{b.name}</Text>
              <Row marginTop8>
                <Col onPress={() => navigate(SCREEN_NAME.BookDetail, { id: b.id, name: `/sach/${b.id}/${VarHelper.toSlug(b.name)}` } )}>
                  <Text caption textDecorationLine='underline'>Xem thêm</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      })}
    </Col>
  );
};

export default BookRecommendationRightSidebar;