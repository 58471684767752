import React from 'react';
import { Col, Row } from 'react-quick-style-components';

const MainContainer = (props) => {
  return (
    <Col
      maxWidth1130 width100p alignSelf="center"
      onResponsiveStyle={{
        xs: { paddingHorizontal: 12 },
        lg: { paddingHorizontal: 0 },
      }}
      {...props} 
    />
  );
};

export default MainContainer;
