import React, { useEffect, useState } from 'react';
import { useWindowDimensions, ActivityIndicator } from 'react-native';
import { Col, Row, Text, Scroll, Img } from 'react-quick-style-components';
import { ICON } from 'assets';
import { COLOR, STYLES, ERROR } from 'const';
import { hideModal, Input, RatioCol, RateStars, Popup, YoutubeEmbed, Button, Avatar, useSubmitUIState } from '../index';
import Store from 'store';
import { IBookShelf } from 'type';

interface IAddToBookShelfProps {
  bookId: string,
  bookName: string,
  bookImage: string,
  bookshelfSource: IBookShelf,
  onMove: any,
}

const MoveBookShelfModal = (props : IAddToBookShelfProps) => {
  const { width, height } = useWindowDimensions();
  const containerWidth = width < 768 ? width * 0.9 : width / 2;
  const containerHeight = width < 768 ? height * 0.8 : height / 2;
  const [{ myBookShelfList }, bApi] = Store.Book.createBookStore();
  const [{ loading }, setUI] = useSubmitUIState();
  const [{ loading : loadingAdd, btnText, errorMes }, setUIAdd] = useSubmitUIState();
  const [activeBookShelfId, setActiveBookShelfId] = useState();

  const getData = async () => {
    setUI({ loading: true });
    await bApi.getMyBookshelfList();
    setUI({ loading: false });
  };

  useEffect(() => {
    setTimeout(() => {
      if (myBookShelfList.length === 0) getData();
    }, 500);
  }, []);

  const moveBook = async (bs) => {
    setActiveBookShelfId(bs.id);
    const checkExisted = !!bs.books.find(val => val.id === props.bookId);
    if (checkExisted) {
      return setUIAdd({ errorMes: 'Sách đã có sẵn trong tủ' });
    }
    setUIAdd({ loading: true });
    const [res, err] = await Store.Book.moveBookBetweenShelves(props.bookshelfSource, bs, {
      id: props.bookId,
      name: props.bookName,
      image: props.bookImage,
    });
    if (err || res.error) {
      setUIAdd({ loading: false, errorMes: ERROR.parseError(err || res.error) });
      return;
    }
    setUIAdd({ loading: false, btnText: 'Đã chuyển thành thành công' });
    !!props.onMove && props.onMove();
    setTimeout(() => {
      hideModal();
    }, 1000);
  };

  return (
    <Col width={containerWidth} height={containerHeight} bgWhite borderRadius10 activeOpacity={1} onPress={() => { }}>
      <Scroll width={containerWidth} height={containerHeight} padding24 borderRadius10>
        <Row justifyContent="space-between">
          <Text fontSize20 bold>Chuyển sang tủ sách</Text>
          <Col width30 height30 middle onPress={hideModal}>
            <ICON.Close fill={COLOR.GRAY_500} width={13} height={20} />
          </Col>
        </Row>

        {Boolean(loading) ? (
          <Col padding12 height100 middle>
            <ActivityIndicator size='small' color={COLOR.MAIN} />
          </Col>
        ) : (
          <Col padding12>
            {myBookShelfList.filter(val => val.name !== 'Sách đóng góp' && val.id !== props.bookshelfSource.id)?.map((bs, i) => (
              <Row
                key={'bs-' + i} margin12 padding12 borderWidth1 borderColor={COLOR.BORDER} borderRadius10
                onHoverStyle={{ backgroundColor: COLOR.PRI_200 }}
                onPress={() => moveBook(bs)}
              >
                <RatioCol ratio={65/104} width={65}>
                  <Img
                    source={{ uri: bs.books.length === 0 ? '' : (bs.books[0].image || '') }}
                    style={STYLES.all100p}
                  />
                </RatioCol>
                <Col flex1 marginLeft16>
                  <Text subtitle1>{bs.name}</Text>
                  <Text body1>{bs.books.length} cuốn</Text>
                </Col>
                {Boolean(activeBookShelfId === bs.id) && (
                  <Col maxWidth={140} alignItems="flex-end">
                    {Boolean(loadingAdd) ? (
                      <ActivityIndicator size='small' color={COLOR.MAIN} />
                    ) : Boolean(btnText) ? (
                      <Text subtitle1 colorMain>{btnText}</Text>
                    ) : Boolean(errorMes) ? (
                      <Text subtitle1 color={COLOR.DANGER_500}>{errorMes}</Text>
                    ) : null}
                  </Col>
                )}
              </Row>
            ))}
          </Col>
        )}
      </Scroll>
    </Col>
  );
};

export default MoveBookShelfModal;