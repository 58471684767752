import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Col, Text, Scroll, Img, Row } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { VarHelper } from 'helpers';
import { SCREEN_NAME, STYLES } from 'const';
import {
  Header, Button, MainContainer, 
  RatioCol, GroupItem, GroupDetailLayout, Input, useSubmitUIState, Tooltip, showModal,
  ControlledUploaderUI,
  YoutubeEmbed,
  Popup, usePreDataOrFetchApi
} from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';
import { IBook, IGroupFeed, IGroupPost, ILocalFileUpload } from 'type';
import { useNavFunc } from 'navigation';
import { GroupPostFeed } from 'components';

interface IGroupDetailEditPostProps {
  groupId: string,
  postId: string,
}

const GroupSinglePost = (props: IGroupDetailEditPostProps) => {
  const [postData] = usePreDataOrFetchApi<IGroupPost>(`${props.groupId}_${props.postId}`, 'group-post');
  // console.log('postData', postData);
  if (!postData) return null;
  return (
    <GroupPostFeed
      data={postData}
    />
  );
};

export default GroupSinglePost;