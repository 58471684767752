import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={13}
      height={12}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M12.537 9.85L7.381.89C6.994.226 5.984.204 5.598.89L.44 9.85c-.386.666.108 1.525.903 1.525h10.29c.796 0 1.29-.838.903-1.525zM6.5 7.98a1 1 0 01.988.989.986.986 0 01-.988.988.972.972 0 01-.988-.988c0-.537.43-.989.988-.989zm-.945-3.544c-.022-.15.107-.28.258-.28h1.353c.15 0 .28.13.258.28l-.15 2.921c-.022.15-.13.237-.258.237H5.963c-.129 0-.236-.086-.258-.237l-.15-2.921z"
        fill="#F66262"
      />
    </Svg>
  )
}

export default SvgComponent
