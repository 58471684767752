import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Col, Text, useDynamicResponsiveValue, Row } from 'react-quick-style-components';
import {
  Header, MainContainer, ControlledRichTextEditor, Popup, Button,
  ControlledUploaderUI, Input, useSubmitUIState, Select,
  UseMemo,
} from 'components';
import { useNavFunc } from 'navigation';
import { useWindowDimensions } from 'react-native';
import { COLOR, ERROR, SCREEN_NAME, CHAPTER_EDIT_VISIBILITY } from 'const';

import { EditableChapterTitle } from './CreateWriting.Comps';
import { ICON } from 'assets';
import { ILocalFileUpload } from 'type';
import Store from 'store';

const emptyValue = { html: '', text: '', json: {}, raw: {} };

const CreateWriting = () => {
  const { reset, navigate } = useNavFunc();
  const { height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();
  const [genres, setGenres] = useState([]);
  const [step, setStep] = useState(1);
  const [chapters, setChapters] = useState([{ name: 'Chưa có tiêu đề', order: 1, visibility: 'public', data: emptyValue, password: '' }]);
  const chaptersRef = useRef({ active: 0, allChap: [] });
  const [activeChapterIndex, setActiveChapterIndex] = useState(0);
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [visibility, setVisibility] = useState('public');
  const [localImage, setLocalImage] = useState<ILocalFileUpload>();

  const { user } = Store.User.state;
  const [{ }, wApi] = Store.Writing.createStore();
  const [{ listGenres }, gApi] = Store.Genres.createStore();
  const [{ }, miscApi] = Store.Misc.createMiscStore();
  const [{ loading, errorMes, btnText }, setSubmitUI] = useSubmitUIState();

  const writingImageRef = useRef<{ getUploadedUrl: Function }>();

  useEffect(() => {
    chaptersRef.current = {
      active: activeChapterIndex,
      allChap: chapters,
    };
  }, [chapters, activeChapterIndex]);

  useEffect(() => {
    gApi.getList('WRITING');
  }, [])

  const addAnotherChapter = () => {
    setChapters([
      ...chapters,
      { name: 'Chưa có tiêu đề', order: chapters.length + 1, visibility: 'public', data: emptyValue, password: '' },
    ])
  };

  const onChangeEditor = useCallback((newData) => {
    const allChapData = chaptersRef.current.allChap.slice();
    allChapData[chaptersRef.current.active].data = newData;
    setChapters(allChapData);
  }, []);

  const handleDeleteChapter = index => {
    const allChapData = chaptersRef.current.allChap.slice();
    const chapContent = allChapData[chaptersRef.current.active].data;
    const doRemove = () => {
      if (chaptersRef.current.active === index) {
        chaptersRef.current.active = Math.max(0, chaptersRef.current.active - 1);
      }
      const newChapters = [
        ...allChapData.slice(0, index),
        ...allChapData.slice(index + 1, allChapData.length),
      ].map((val, index) => ({ ...val, order: index + 1 }));
      setActiveChapterIndex(chaptersRef.current.active);
      setChapters(newChapters.length === 0 ? [{ name: 'Chưa có tiêu đề', order: 1, visibility: 'public', data: emptyValue, password: '' }] : newChapters);
    }
    if (!chapContent.text.trim()) return doRemove();
    Popup.show('Chương đang có dữ liệu, bạn có chắc muốn xóa chương này?', [
      { text: 'Có', solid: false, outline: true, onPress: doRemove },
      { text: 'Không', solid: true, outline: false, onPress: () => { } },
    ]);
  }

  const submitWriting = async () => {
    if (!name || !content) return setSubmitUI({ errorMes: ERROR.co_fill_all_form });
    const hasEmptyChapter = chapters.filter(val => !val.data.text.trim()).length > 0;
    if (hasEmptyChapter && visibility === 'public') return setSubmitUI({ errorMes: ERROR.wr_no_empty_chapter });
    if (!localImage || !localImage.path) return setSubmitUI({ errorMes: 'Thiếu ảnh bìa truyện' });
    setSubmitUI({ loading: true });
    const [image] = await miscApi.uploadLocal(localImage);
    if (!image) return setSubmitUI({ errorMes: 'Vui lòng thêm ảnh', loading: false });
    const [res, err] = await wApi.create({
      name,
      content,
      image,
      visibility,
      chapters: chapters.map(val => ({
        name: val.name,
        order: val.order,
        visibility: val.visibility,
        content: val.data.text,
        contentHTML: val.data.html,
        contentJSON: val.data.json,
        password: val.password,
      })),
      genres: genres.map(val => ({ id: val.value, name: val.label })),
    })
    if (err) return setSubmitUI({ errorMes: ERROR.parseError(err), loading: false });
    if (res.error) return setSubmitUI({ errorMes: ERROR.parseError(res.error), loading: false });
    setSubmitUI({ btnText: 'Thành công', errorMes: '', loading: false });
    setTimeout(() => {
      setSubmitUI({ btnText: '', errorMes: '', loading: false });
      setTimeout(() => {
        reset(SCREEN_NAME.Writing);
      }, 500);
    }, 1500);
  };

  if (user.type === 'UNVERIFY-USER') {
    return (
      <Col flex1 backgroundColor="#F3F6F5">
        <Header />
        <Col flex1 middle>
          <Text>Bạn cần xác thực tài khoản để sử dụng tính năng này</Text>
          <Button marginTop16 small solid text="Xác thực" onPress={() => navigate(SCREEN_NAME.AccountVerifyNotice)} />
        </Col>
      </Col>
    )
  }

  const handleGoToStep2 = () => {
    if (!name || !content) return setSubmitUI({ errorMes: ERROR.co_fill_all_form });
    const countDrafChapter = chaptersRef.current.allChap.filter(val => val.visibility === 'draft').length;
    const doAfterConfirm = () => {
      setStep(2);
    }
    if (countDrafChapter > 1) {
      Popup.show('Bạn vẫn còn chương nháp, các chương nháp sẽ không hiển thị công khai', [
        { text: 'Đã hiểu', solid: false, outline: true, onPress: doAfterConfirm },
        { text: 'Chỉnh sửa tiếp', solid: true, outline: false, onPress: () => { } },
      ]);
    } else {
      setStep(2);
      setSubmitUI({ errorMes: '' });
    }

  }

  const updateChapterOrder = (oldIndex, newIndex) => {
    if (newIndex === -1 || newIndex === chaptersRef.current.allChap.length) return;
    const allChapData = chaptersRef.current.allChap.slice();
    const item1 = Object.assign({}, allChapData[oldIndex]);
    const item2 = Object.assign({}, allChapData[newIndex]);
    allChapData[oldIndex] = undefined;
    allChapData[newIndex] = undefined;
    allChapData[oldIndex] = item2;
    allChapData[newIndex] = item1;
    setChapters(allChapData.map((val, i) => ({ ...val, order: i + 1 })));
    chaptersRef.current.active = newIndex;
    setActiveChapterIndex(newIndex);
  }

  const renderStep2 = () => {
    return (
      <Col flex1>
        <Col borderBottomWidth1 borderBottomColor={COLOR.BORDER} paddingVertical24>
          <MainContainer>
            <Row justifyContent="space-between">
              <Text bold fontSize={18}>VIẾT MỚI</Text>

              <Col alignItems="flex-end">
                <Row>
                  <Button marginRight8 outline width154 height43 onPress={() => setStep(1)} large text="Quay lại" />
                  <Button loading={loading} width154 height43 onPress={submitWriting} large solid text={btnText || "Đăng bài"} />
                </Row>
                {Boolean(errorMes) && <Text marginTop4 color={COLOR.DANGER_500}>{errorMes}</Text>}
              </Col>

            </Row>
          </MainContainer>
        </Col>
        <MainContainer paddingVertical24 paddingHorizontal={rV({ xs: 10, md: 0 })}>
          <Row alignItems="flex-start" xs='100%' lg='2:any:1'>
            {/* MAIN */}
            <Col>
              <Text marginBottom24 subtitle1>{chapters[activeChapterIndex]?.name}</Text>
              <UseMemo deps={[chapters, activeChapterIndex]}>
                <ControlledRichTextEditor
                  placeholder='Viết nội dung...'
                  value={chapters[activeChapterIndex]?.data}
                  onChange={onChangeEditor}
                  height={height * 0.5}
                />
              </UseMemo>
            </Col>

            <Col width24 height0 />
            {/* SIDEBAR */}
            <Col>
              <Col borderRadius4 borderWidth1 borderColor={COLOR.BORDER}>
                <Col padding24>
                  <Text bold fontSize={18}>DANH SÁCH</Text>
                </Col>
                <Col>
                  {chapters.map((val, i) => {
                    return (
                      <EditableChapterTitle
                        data={val} key={'chap-' + i}
                        isActive={i === activeChapterIndex}
                        onPress={() => {
                          chaptersRef.current.active = i;
                          setActiveChapterIndex(i);
                        }}
                        onChange={({ name: newName, visibility }) => {
                          const allChapData = chaptersRef.current.allChap.slice();
                          allChapData[chaptersRef.current.active].name = newName;
                          allChapData[chaptersRef.current.active].visibility = visibility;
                          setChapters(allChapData);
                        }}
                        onPressDelete={() => handleDeleteChapter(i)}
                        onMoveUp={() => updateChapterOrder(i, i - 1)}
                        onMoveDown={() => updateChapterOrder(i, i + 1)}
                      />
                    );
                  })}
                </Col>
                <Row paddingHorizontal24 paddingVertical12 onPress={addAnotherChapter}>
                  <ICON.Plus fill={COLOR.MAIN} height={15} width={15} />
                  <Text colorMain marginLeft8>Thêm chương</Text>
                </Row>
              </Col>
              <Text marginTop16><Text bold>Ghi chú</Text>: Tác phẩm của bạn sẽ được hiển thị dưới dạng truyện ngắn nếu bạn chỉ có 1 chương duy nhất</Text>
            </Col>

          </Row>

        </MainContainer>
      </Col>
    );
  }

  const renderStep1 = () => {
    return (
      <Col flex1>
        <Col borderBottomWidth1 borderBottomColor={COLOR.BORDER} paddingVertical24>
          <MainContainer>
            <Row justifyContent="space-between">
              <Text bold fontSize={18}>CÀI ĐẶT BÀI VIẾT</Text>
              <Col alignItems="flex-end">
                <Row>
                  <Button width154 height43 onPress={handleGoToStep2} large solid text="Tiếp tục" />
                </Row>
                {Boolean(errorMes) && <Text marginTop4 color={COLOR.DANGER_500}>{errorMes}</Text>}
              </Col>

            </Row>
          </MainContainer>
        </Col>
        <MainContainer paddingVertical24 paddingHorizontal={rV({ xs: 10, md: 0 })}>
          <Row alignItems="flex-start" xs='100%' lg='any:any:1'>
            <Col width170>
              <ControlledUploaderUI
                aspectRatio={1}
                ref={writingImageRef}
                value={localImage}
                onChange={setLocalImage}
              />
            </Col>
            <Col width24 />
            <Col>
              <Input
                value={name} onChange={setName}
                label='Tên' required placeholder='Nhập tên..'
              />
              <Input
                marginTop24 multiline inputStyle={{ padding: 12 }}
                value={content} onChange={setContent}
                label='Mô tả thêm' required placeholder='Một đoạn ngắn giới thiệu về Viết của bạn..'
              />
              <Select
                label="Thể loại"
                value={genres}
                options={listGenres.map(val => ({ label: val.name, value: val.id }))}
                onChange={setGenres}
                isMulti
                placeholder="Tìm và chọn thể loại"
                marginTop16
              />
              <Select
                marginTop12
                label="Hiển thị"
                options={Object.keys(CHAPTER_EDIT_VISIBILITY).map(val => ({ value: val, label: CHAPTER_EDIT_VISIBILITY[val] }))}
                value={{ label: CHAPTER_EDIT_VISIBILITY[visibility], value: visibility }}
                onChange={(v) => setVisibility(v.value)}
              />
            </Col>
          </Row>
        </MainContainer>
      </Col>
    );
  }

  return (
    <Col flex1 backgroundColor="white">
      <Header />
      {step === 1 ? renderStep1() : renderStep2()}
    </Col>
  )
};

export default CreateWriting;