import { RatioCol } from 'components/elements';
import { COLOR, STYLES } from 'const';
import { TimeHelper } from 'helpers';
import React from 'react';
import { Col, Row, Text, usePropsStyle, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { INews } from 'type';

interface INewsRowItemProps {
  data: INews,
  onPress: any,
  [anyProps: string]: any,
}

const NewsRowItem = (props: INewsRowItemProps) => {
  const propStyle = usePropsStyle(props);
  const { data } = props;
  const rV = useDynamicResponsiveValue();

  return (
    <Col bgWhite borderWidth1 borderColor="#E4E4E4" style={propStyle} padding={rV({ xs: 12, md: 24 })} onPress={props.onPress}>
      <Text h5 marginBottom8>{data.name}</Text>
      <Text body2 color={COLOR.GRAY_500} marginBottom24>Đăng ngày {TimeHelper.format(data.createdAt)}</Text>
      <Row alignItems="flex-start" xs='100%' md='1:any:any'>
        <Col flex1 marginBottom12>
          <Text body1>{data.description}</Text>
        </Col>
        <Col width24 />
        <RatioCol ratio={187 / 125} width={rV({ xs: '100%', md: 187 })}>
          <Img source={{ uri: !!data.images ? data.images[0] : '' }} style={STYLES.all100p} />
        </RatioCol>
      </Row>
    </Col>
  );
};

export default NewsRowItem;
