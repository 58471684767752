import React, { useEffect, useState } from 'react';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { MainContainer, Header, RatioCol, FooterInfo, BookRightSidebar } from 'components';
import { IMAGE, ICON } from 'assets';
import { STYLES, useFontSizeStyle, SCREEN_NAME } from 'const';
import { useNavFunc } from 'navigation';
import BookSection from './BookHomepage.BookSection';
import TopMostReviewedSection from './BookHomepage.TopMostReviewedSection';
import TopRatingSection from './BookHomepage.TopRatingSection';
import NewestReviewSection from './BookHomepage.NewestReviewSection';

const BookHomepage = () => {
  const { route, navigation } = useNavFunc();
  // @ts-ignore
  const search = route.params?.search || '';
  // @ts-ignore
  const genre = route.params?.genre || '';
  const [viewState, setViewState] = useState({
    title: !!search ? 'Kết quả tìm kiếm cho: ' + search : !!genre ? `Sách theo thể loại: ${genre}` : 'Sách mới',
    options: !!search ? {
      search: [{ label: 'name', value: search }],
    } : !!genre ? {
      search: [{ label: 'genres', value: genre }],
    } : {},
  });
  const rV = useDynamicResponsiveValue();
  useEffect(() => {
    console.log(search, genre);
    setViewState({
      title: !!search ? 'Kết quả tìm kiếm cho: ' + search : !!genre ? `Sách theo thể loại: ${genre}` : 'Sách mới',
      options: !!search ? {
        search: [{ label: 'name', value: search }, { label: 'authors', value: search }],
      } : !!genre ? {
        search: [{ label: 'genres', value: genre }],
      } : {},
    })
  }, [search, genre]);
  const viewport = rV({ xs: 'xs', md: 'md' });

  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header searchKeyword={search} />
      <Col flex1>
        <Scroll style={{ flex: 1 }}>
          <MainContainer>
            <RatioCol ratio={1140 / 249} width="100%" marginTop16 bgMain borderRadius16>
              <Img source={IMAGE.BookBanner} style={STYLES.all100p} />
              <Row absoluteFill paddingHorizontal='5%'>
                <Col alignItems="flex-start">
                  {viewport !== 'xs' && <ICON.ReadismWithTextLogo white height={27} />}
                  <Text h4 colorWhite marginVertical={rV({ xs: 0, md: 8 })}>Đánh giá Sách</Text>
                  <Row
                    onPress={() => {
                      navigation.navigate(SCREEN_NAME.CreateBook);
                    }}
                    marginTop8 padding8 borderColor="white" borderWidth={1} borderRadius4
                  >
                    <ICON.Plus fill="white" width={10} height={10} />
                    <Text marginLeft8 center colorWhite body2>Đóng góp sách</Text>
                  </Row>
                </Col>
              </Row>
            </RatioCol>

            <Row xs="100%" lg="3:any:1" alignItems="flex-start">

              <Col>
                {Boolean(viewState.title !== 'Sách mới') && (
                  <BookSection
                    title={viewState.title}
                    options={viewState.options}
                  />
                )}
                <NewestReviewSection />
                <TopMostReviewedSection />
                <TopRatingSection />

              </Col>

              <Col width24 height0 />

              <Col>
                <BookRightSidebar
                  onPressGenre={(name) => setViewState({
                    title: name === 'all' ? 'Sách mới' : `Sách theo thể loại: ${name}`,
                    options: name === 'all' ? {} : {
                      search: [
                        { label: 'genres', value: name },
                      ]
                    }
                  })}
                />
              </Col>
            </Row>
          </MainContainer>
          <FooterInfo />
        </Scroll>
      </Col>
    </Col>
  );
};

export default BookHomepage;
