import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  const propsWithoutFill = { ...props };
  delete propsWithoutFill.fill;
  return (
    <Svg
      width={25}
      height={18}
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...propsWithoutFill}
    >
      <Path
        d="M1.415 8.61l7.317 7.317L23.366 1.293"
        stroke={props.fill || "#fff"}
        strokeWidth={2}
      />
    </Svg>
  )
}

export default SvgComponent
