import React, { useState } from 'react';
import { Select } from 'components';
import { Col } from 'react-quick-style-components';
import { COLOR } from 'const';

const options = [
  { label: 'Hoạt động mới', value: 'updatedAt_DESC' },
  { label: 'Bài đăng mới nhất', value: 'createdAt_DESC' },
  { label: 'Bài đăng cũ nhất', value: 'createdAt_ASC' },
]

const PostFeedOrder = ({ onChange }) => {
  const [value, setValue] = useState({ label: 'Hoạt động mới', value: 'updatedAt_DESC' });
  const [isDisabled, setIsDisabled] = useState(false);
  
  return (
    <Col width200> 
      <Select
        label=""
        placeholder=""
        options={options}
        value={value}
        onChange={async (newVal) => {
          setValue(newVal);
          setIsDisabled(true);
          await onChange(newVal);
          setIsDisabled(false);
        }}
        styles={{
          control: (style) => ({
            ...style,
            height: 46,
            minHeight: 46,
            borderRadius: 8,
            borderColor: 'transparent',
            boxShadow: 'none',
            '&:hover': {
              borderColor: 'transparent',
            },
            backgroundColor: 'transparent',
          }),
          indicatorSeparator: (style) => ({ display: 'none' }),
          valueContainer: (provided, state) => ({
            ...provided,
            justifyContent: 'flex-end',
            fontSize: 14,
            color: COLOR.GRAY_500,
          }),
        }}
        isSearchable={false}
        isDisabled={isDisabled}
      />
    </Col>
  )
};

export default PostFeedOrder;