import { COLOR, SCREEN_NAME } from 'const';
import React, { useEffect } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import Store from 'store';
import { useNavFunc } from 'navigation';

const GenresShape = () => {
  const { navigate } = useNavFunc();
  const [{ listGenres }, gApi] = Store.Genres.createStore();
  const rV = useDynamicResponsiveValue();
  useEffect(( ) => {
    gApi.getList('BOOK');
  }, []);

  const renderSingleGenres = ({ id, name }) => {
    return (
      <Col
        key={id} margin12 paddingHorizontal={rV({ xs: 8, md: 12 })} height={rV({ xs: 27, md: 43 })}
        borderRadius64 middle borderWidth1 borderColor={COLOR.GRAY_500} onHoverStyle={{ borderColor: COLOR.MAIN }}
      >
        <Text subtitle1={rV({ xs: false, md: true })} captain={rV({ xs: true, md: false })} onHoverStyle={{ color: COLOR.MAIN }}>{name}</Text>
      </Col>
    )
  }

  const viewport = rV({ xs: 'xs', md: 'md' });

  return (
    <Col width={rV({ xs: '90%', lg: '50%', xl: '70%' })} middle>
      <Text h4 center marginBottom24>Khám phá thế giới Readism với <Text colorMain h4>hàng ngàn danh mục sách</Text></Text>
      {viewport === 'xs' ? (
        <Row style={{ flexWrap: 'wrap' }}>
          {listGenres.slice(0, 12).map(renderSingleGenres)}
        </Row>
      ) : (
        <>
          <Row style={{ flexWrap: 'wrap' }}>
            {listGenres.slice(4, 8).map(renderSingleGenres)}
          </Row>
          <Row style={{ flexWrap: 'wrap' }}>
            {listGenres.slice(0, 4).map(renderSingleGenres)}
          </Row>
          <Row style={{ flexWrap: 'wrap' }}>
            {listGenres.slice(8, 12).map(renderSingleGenres)}
          </Row>
        </>
      )}
      

      <Col
        margin12 paddingHorizontal12 height43
        borderRadius64 middle borderWidth1 borderColor={COLOR.MAIN}
        onPress={() => navigate(SCREEN_NAME.BookHomepage)}
      >
        <Text subtitle1 colorMain>Xem tất cả danh mục</Text>
      </Col>
    </Col>
  );
};

export default GenresShape;