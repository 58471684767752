import React, { useState, useEffect } from 'react';
import { Col, Text, Scroll, Img, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import { Header, Button, MainContainer, RatioCol, Input, useSubmitUIState, showModal } from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';

import { IBook } from 'type';
import { VarHelper } from 'helpers';
import AddBookModalWithSuggestion from './comps/AddBookModalWithSuggestion';
import { SvgBook } from './comps/SvgBook';

const CreateQuote = () => {
  const { goBackOrTo, navigate, route } = useNavFunc();
  const [content, setContent] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [{ loading, errorMes, btnText }, setSubmitUI] = useSubmitUIState();
  const rV = useDynamicResponsiveValue();
  const [{ }, qApi] = Store.Quotes.createStore();
  const [{ user }] = Store.User.createUserStore();
  const [book, setBook] = useState<IBook>();
  const { author } = route.params || {};

  useEffect(() => {
    if (author) {
      setAuthorName(author);
    }
  }, [author]);

  const submitQuote = async () => {
    if (!authorName || !content) return setSubmitUI({ errorMes: ERROR.co_fill_all_form });
    setSubmitUI({ loading: true });
    const [res, err] = await qApi.create({
      content,
      quoteBy: { id: '0', name: authorName },
      tagBooks: !!book ? [{ id: book.id, name: book.name, image: book.images && book.images.length > 0 ? book.images[0] : '' }] : [],
    });
    if (err) return setSubmitUI({ loading: false, errorMes: ERROR.parseError(err) });
    if (res.error) return setSubmitUI({ loading: false, errorMes: ERROR.parseError(res.error) });
    setSubmitUI({ loading: false, errorMes: '', btnText: 'Tạo thành công' });
    setTimeout(() => {
      setSubmitUI({ loading: false, errorMes: '', btnText: '' });
      setContent('');
      setAuthorName('');
      goBackOrTo(SCREEN_NAME.Quote);
    }, 1500);
  };

  const showModalAddBook = () => {
    showModal({
      component: (
        <AddBookModalWithSuggestion onSelectBook={setBook} />
      )
    })
  };

  const onPressGoToDetail = () => {
    if (!book) return;
    navigate(SCREEN_NAME.BookDetail, { id: book.id, name: VarHelper.toSlug(book.name) })
  }

  if (user.type === 'UNVERIFY-USER') {
    return (
      <Col flex1 backgroundColor="#F3F6F5">
        <Header />
        <Col flex1 middle>
          <Text>Bạn cần xác thực tài khoản để sử dụng tính năng này</Text>
          <Button marginTop16 small solid text="Xác thực" onPress={() => navigate(SCREEN_NAME.AccountVerifyNotice)} />
        </Col>
      </Col>
    )
  }

  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1>
        <Scroll flex1>
          <MainContainer marginTop24>

            <Col alignSelf="center" width={rV({ xs: '100%', md: '80%', lg: 200 / 3 + '%' })}>

              <Col borderColor='#E4E4E4' borderWidth1 padding24 >
                <Text h5>Tạo trích dẫn</Text>

                <Input
                  marginTop24 multiline
                  value={content} onChange={setContent}
                  placeholder="Viết nội dung trích dẫn.."
                  label="" required inputStyle={{ padding: 12 }}
                />

                <Input
                  value={authorName} onChange={setAuthorName}
                  marginTop24 label="Tên tác giả" required
                  placeholder="Nhập tên tác giả"
                />
                {Boolean(!book) ? (
                  <Button
                    onPress={showModalAddBook}
                    text="Thêm sách" leftIcon={<SvgBook style={{ marginRight: 4 }} />} solid width={150} marginTop24 />
                ) : (
                  <Col marginTop24 bgWhite borderRadius10 shadow>
                    <Row height192 stretch padding24 marginHorizontal24 borderRadius4 borderWidth1 borderColor={'#ECF0EF'}>
                      <RatioCol ratio={100 / 160} width={100} onPress={onPressGoToDetail}>
                        <Img source={{ uri: book?.images && book?.images?.length > 0 ? book?.images[0] : '' }} width100p height100p />
                      </RatioCol>
                      <Col flex1 paddingLeft16>
                        <Col onPress={onPressGoToDetail}>
                          <Text numberOfLines={2} subtitle1 marginBottom8>{book?.name}</Text>
                          <Text numberOfLines={1} body2 marginBottom16>{book?.authors?.length > 0 ? book.authors[0].name : ''}</Text>

                          <Text numberOfLines={2} body2 marginBottom16>{book?.content}</Text>
                        </Col>

                        <Button smal outline width141 height33 text='Bỏ sách' onPress={() => setBook(undefined)} />
                      </Col>
                    </Row>
                  </Col>
                )}

              </Col>

              <Col alignItems="flex-end" marginTop24>
                {Boolean(errorMes) && <Text color={COLOR.DANGER_500} textAligned="right" marginBottom4>{errorMes}</Text>}
                <Button width170 onPress={submitQuote} solid large text={btnText || 'Tạo trích dẫn'} loading={loading} />
              </Col>
            </Col>


          </MainContainer>
        </Scroll>
      </Col>
    </Col>
  );
};

export default CreateQuote;