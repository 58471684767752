import React, { forwardRef, useState, useRef, useImperativeHandle, useMemo } from 'react';
import { Col, Row, Text, Img } from 'react-quick-style-components';
import { ICON } from 'assets';
import { COLOR } from 'const';
import { VarHelper } from 'helpers';
import Uploader from './Uploader';
import { ILocalFileUpload } from 'type';
interface ICMSUploaderUIPsop {
  aspectRatio: number, // width / height
  text?: string,
  subText?: string,
  onChange?: any,
  [anyProps: string]: any,
}

const CMSUploaderUI = forwardRef((props: ICMSUploaderUIPsop, ref) => {
  const [localFile, setLocalFile] = useState<ILocalFileUpload>({
    path: '',
    mime: '',
    name: '',
    raw: undefined,
  });
  const uploaderRef = useRef<{ openFileDialog: Function, uploadLocal: Function }>();

  const getUploadedUrl = async () => {
    return VarHelper.erria(async () => {
      if (!localFile.raw && !localFile.path) return '';
      if (!localFile.raw && !!localFile.path && localFile.path.includes('https://')) return localFile.path;
      const [uploadedUrl, err] = await uploaderRef.current.uploadLocal(localFile);
      if (err) throw err;
      return uploadedUrl;
    });
  }

  useImperativeHandle(ref, () => ({
    getUploadedUrl,
    setUri: (uri) => setLocalFile({
      path: uri,
      mime: '',
      name: '',
      raw: undefined,
    }),
    uploadLocal: (lFile) => uploaderRef.current.uploadLocal(lFile),
  }))

  return (
    <Col
      paddingBottom={100 /props.aspectRatio + '%'}
      borderColor="#C9C9C9" borderWidth1 borderStyle="dashed"
      borderRadius8 backgroundColor="#EFEFEF" overflow="hidden"
      onPress={() => uploaderRef.current?.openFileDialog()}
      onHoverStyle={{ backgroundColor: COLOR.GRAY_200 }}
      useNestedHover useNativeStyleProps
    >
      <Col absoluteFill>
        {Boolean(!localFile.path) ? (
          <Col flex1 middle>
            <ICON.Plus />
            <Text center width83 marginTop16 subtitle1 color={COLOR.GRAY_500}>{props.text || 'Thêm ảnh'}</Text>
            {Boolean(props.subText) && <Text center color={COLOR.GRAY_500} body1>{props.subText}</Text>}
          </Col>
        ) : (
          <Col flex1>
            <Col absoluteFill>
              <Img source={{ uri: localFile.path }} width100p height100p resizeMode="cover" />
            </Col>
            <Col absoluteFill middle opacity={0} backgroundColor="rgba(0,0,0,0.7)" onHoverStyle={{ opacity: 1 }} useNativeStyleProps>
              <ICON.WhitePen />
              <Text center width83 marginTop16 subtitle1 colorWhite>Đổi ảnh</Text>
            </Col>
          </Col>
        )}
        <Uploader
          ref={uploaderRef}
          onChangeLocal={(newLocal) => {
            setLocalFile(newLocal);
            props.onChange && props.onChange(newLocal);
          }}
        />
      </Col>
    </Col>
  );
});


export default CMSUploaderUI;