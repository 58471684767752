

import React, { useState, useCallback } from 'react';
import { useWindowDimensions,  } from 'react-native';
import { Col, Row, Text, Scroll } from 'react-quick-style-components';
import { Input } from 'components';
import Store from 'store';
import { COLOR, DEVICE_INFO } from 'const';
import { VarHelper } from 'helpers';

const SearchForm = () => {
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState([]);
  const { width, height } = useWindowDimensions();
  const [{}, bApi] = Store.Book.createBookStore();

  const search = useCallback(async () => {
    const [res, err] = await bApi.listBook({
      visibility: 'all',
      page: 1,
      pageSize: 10,
      options: {
        search: [{ label: 'name', value: keyword }],
      },
    });
    if (res && res.data && res.data.rows) {
      setList(res.data.rows);
    }
  }, [keyword]);

  return (
    <Col borderRadius10 width={width/2} height={height/2} bgWhite onClick={() => {}}>
      <Scroll width={width/2} height={height/2} padding8>
        <Input
          label=""
          placeholder="Điền từ khóa và enter"
          value={keyword}
          onChange={setKeyword}
          onEnter={search}
        />
        <Col marginTop12>
          {Boolean(list.length > 0) && list.map((val, i) => (
            <Row
              key={val.id} padding8 borderBottomWidth1 borderBottomColor={COLOR.BORDER}
              onPress={() => DEVICE_INFO.IS_WEB && window.open(`/quan-ly/sach/${val.id}/${VarHelper.toSlug(val.name)}`)}
              onHoverStyle={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
            >
              <Col flex1  >
                <Text>{val.name}</Text>
              </Col>
            </Row>
          ))}
        </Col>
      </Scroll>
    </Col>
  )
};

export default SearchForm;