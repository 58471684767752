import React from 'react';
import { Col, usePropsStyle } from 'react-quick-style-components';
import { View } from 'react-native';
import MangaImageItemWithComment from './MangaImageItemWithComment'
import { IMangaChapter } from 'type';
interface IMangaReaderProps {
  images: Array<{ url: string, name: string }>,
  images2?: IMangaChapter['images2'],
  mangaId: string,
  mangaChapterId: string,
  extraData: Array<{
    id: string,
    numberOfComments: number,
    mangaChapterId: string,
    mangaId: string,
  }>,
  [propStyle : string]: any,
}

const MangaReader = (props : IMangaReaderProps) => {
  const style = usePropsStyle(props);

  return (
    <View style={style} nativeID="manga-reader">
      {!!props.images && props.images.map((val, i) => !val ? null : (
        <MangaImageItemWithComment
          key={'img-'+i}
          url={val.url}
          // @ts-ignore
          mangaChapterId={props.mangaChapterId}
          mangaId={props.mangaId}
          extraData={props.extraData?.find(item => item.id === val.url)}
          noComment={!!props.scaleDown}
        />
      ))}
    </View>
  );
}

export default MangaReader;