import React, { useState, useEffect } from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import Store from 'store';

import GenresWidget from './GenresWidget';
import NewsWidget from './NewsWidget';

interface IBookRightSidebarProps {
  onPressGenre?: any,
}

const BookRightSidebar = (props : IBookRightSidebarProps) => {
  
  return (
    <Col marginVertical16>
      <GenresWidget onPressGenre={props.onPressGenre} />
      <NewsWidget marginTop16 />
    </Col>
  );
};

export default BookRightSidebar;