import React, { memo } from 'react';
import { Col, Row, Text, usePropsStyle } from 'react-quick-style-components';
import SelectWeb, { components } from 'react-select';
import { COLOR } from 'const';

interface ISelectProps {
  label: string,
  required?: boolean,
  value: any,
  onChange: any,
  options: Array<any>,
  styles?: any,
  noBorder?: boolean,
  [additionProp: string]: any,
}

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Chưa có kết quả</span> 
    </components.NoOptionsMessage>
  );
};

const Select = (props : ISelectProps) => {
  const propsStyle = usePropsStyle(props);
  const height =  props.height ? { height: props.height, minHeight: props.height } : {}
  return (
    <Col style={propsStyle}>
      {Boolean(props.label) && (
        <Row marginBottom8>
          <Col flex1>
            <Text subtitle1>{props.label} {Boolean(props.required) && <Text color={COLOR.DANGER_500}> *</Text>}</Text>
          </Col>
        </Row>
      )}
      <SelectWeb
        value={props.value}
        onChange={props.onChange}
        components={{ NoOptionsMessage }}
        styles={{
          control: (style) => ({
            ...style,
            height: props.isMulti ? 'auto' : 46,
            minHeight: 46,
            borderRadius: 8,
            borderColor: props.noBorder ? 'transparent' : COLOR.BORDER,
            boxShadow: 'none',
            '&:hover': {
              borderColor: props.noBorder ? 'transparent' :  COLOR.BORDER,
            },
            ...height,
          }),
          input: styles => ({ ...styles, outline: 'none', fontFamily: 'Inter_400Regular', ...height, }),
          indicatorSeparator: (style) => ({ display: 'none' }),
          placeholder: (style) => ({
            ...style,
            fontSize: 14,
            color: COLOR.FONT,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            ...height,
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            ...height,
          }),
          ...props.styles,
        }}
        menuPortalTarget={document.querySelector('body')}
        {...props}
      />
    </Col>
  );
};

export default memo(Select);