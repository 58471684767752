import React, { useEffect, useState } from 'react';
import { Col, Text, Row } from 'react-quick-style-components';
import { CMSLayout, Button, CMSTableLayout, Popup } from 'components';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, BOOK_VISIBILITY, ERROR } from 'const';
import Store from 'store';
import { VarHelper, TimeHelper } from 'helpers';

const moment = require('moment');

const CMSListReview = () => {
  const { navigation, goWithPreData } = useNavFunc();
  const [{}, rApi] = Store.Reviews.createStore();

  return (
    <Col flex1>
      <CMSLayout title="Quản lý Đánh giá">
        <Row>
          <Col flex1>
            <Text h4>ĐÁNH GIÁ</Text>
          </Col>
          <Button paddingHorizontal15 small solid text="Nhập đánh giá" onPress={() => navigation.navigate(SCREEN_NAME.CMSBulkCreateReview)} />
        </Row>
        <Row marginTop24>
          <CMSTableLayout
            initialPage={1}
            tableSchema={[
              { title: 'Sách', type: 'text', key: 'bookName', width: 200 },
              { title: 'Nội dung', width: 270, type: 'text', key: (val) => !val.content ? '' : val.content?.slice(0, 100) + '...' },
              { title: 'Trạng thái', type: 'text', key: (val) => BOOK_VISIBILITY[val.visibility] },
              { title: 'Lần sửa cuối', type: 'text', width: 150, key: (val) => TimeHelper.fromNow(val.updatedAt) },
            ]}
            fetcher={(page, options) => rApi.adminGetList({ visibility: 'all', page, options })}
            onRowPress={(rowData) => goWithPreData(SCREEN_NAME.CMSEditReview, { id: rowData.id }, rowData)}
            fetchWhenFocus
            searchAndFilter={{
              search: [{ label: 'Tên sách', value: 'bookName' }],
              filter: [{ label: 'Trạng thái', value: 'visibility', options: Object.keys(BOOK_VISIBILITY).map(val => ({ value: val, label: BOOK_VISIBILITY[val] })) }],
              sort: [
                { label: 'Thời gian cập nhật', orderType: 'DESC', value: 'updatedAt' },
              ],
            }}
            remover={(id, name) => new Promise((resolve, reject) => {
              const doRemove = async () => {
                const [res, err] = await rApi.updateReview({
                  id,
                  visibility: 'trash',
                });
                if (err) alert(ERROR.parseError(err));
                else if (res.error) alert(ERROR.parseError(res.error));
                resolve(undefined);
              };
              Popup.show(
                'Bạn có chắc chắn muốn xóa? ',
                [
                  { text: 'Không', solid: true, onPress: () => {} },
                  {
                    text: 'Có', solid: false, onPress: doRemove,
                  }
                ],
              );
            })}
          />
        </Row>
      </CMSLayout>
    </Col>
  );
};

export default CMSListReview;