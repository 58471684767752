import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={21}
      height={22}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M20.836 19.861l-4.963-4.963c-.123-.082-.246-.164-.37-.164h-.532c1.271-1.476 2.091-3.445 2.091-5.578 0-4.676-3.855-8.531-8.53-8.531C3.813.625 0 4.48 0 9.156a8.525 8.525 0 008.531 8.531c2.133 0 4.06-.779 5.578-2.05v.533c0 .123.041.246.123.37l4.963 4.962c.205.205.534.205.698 0l.943-.943c.205-.165.205-.493 0-.698zM8.53 15.72A6.53 6.53 0 011.97 9.156 6.555 6.555 0 018.53 2.594c3.61 0 6.563 2.953 6.563 6.562A6.556 6.556 0 018.53 15.72z"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
