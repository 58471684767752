import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Col, Text, Scroll, Img, Row } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle } from 'const';
import { useNavFunc, A } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import { Header, Button, MainContainer, RatioCol, MangaItem, Pagination, WritingItem } from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';
import { VarHelper } from 'helpers';
import { IManga, IWriting } from 'type';

interface IEachWritingList {
  count: number,
  hasNext: boolean,
  rows: Array<IWriting>,
}

interface IHomeData {
  hotAndRecentlyUpdated: IEachWritingList,
  mostViewed: IEachWritingList,
  promoted: IEachWritingList,
  recentlyUpdated: IEachWritingList,
  recommendation: IEachWritingList,
}

const Writing = () => {
  const { navigate, navigation } = useNavFunc();
  const { h2 } = useFontSizeStyle();
  const [tab, setTab] = useState<'all' | 'trending'>('all');
  const [homeData, setHomeData] = useState<IHomeData>();
  const [page, setPage] = useState(1);

  const [{ }, wApi] = Store.Writing.createStore();

  const TABS = [
    // { label: 'Phổ biến', key: 'trending' },
    { label: 'Truyện chữ', key: 'all' },
  ];

  const pagingRef = useRef({ count: 0 })

  const getData = useCallback(async (page = 1) => {
    const [res] = await wApi.listWritingHomePage();
    if (!res) return;
    console.log(res.data);
    if (!!res && !!res.data) setHomeData(res.data);
  }, [tab, homeData]);

  const changePage = (p) => {
    getData(p);
    window.scrollTo({ top: 0, left: 0 });
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getData();
    });
    return unsubscribe;
  }, [tab, getData]);

  const goToViewAll = () => {
    navigate(SCREEN_NAME.WritingViewAll);
  };

  const renderItemSidebar = (val, keyLabel) => {
    return (
      <A
        key={keyLabel + val.id}
        route={SCREEN_NAME.WritingDetail}
        params={{ id: val.id, name: VarHelper.toSlug(val.name) }}
      >
        <Row margin8 alignItems='flex-start'>
          <RatioCol width={8200 / 316 + '%'} ratio={82 / 109}>
            <Img source={{ uri: val.image }} style={STYLES.all100p} />
          </RatioCol>
          <Col flex1 marginLeft16>
            <Text subtitle1>{val.name}</Text>
            <Text marginTop8>{val.numberOfChapter === 1 ? 'oneshot' : val.numberOfChapter + ' chương'}</Text>
          </Col>
        </Row>
      </A>
    )
  };

  const renderItemMain = (val, keyLabel, isNew = false) => {
    return (
      <Col margin8>
        <A
          key={keyLabel + val.id}
          route={SCREEN_NAME.WritingDetail}
          params={{ id: val.id, name: VarHelper.toSlug(val.name) }}
        >
          <RatioCol width={'100%'} ratio={170 / 226}>
            <Img source={{ uri: val.image }} style={STYLES.all100p} />
          </RatioCol>
          <Col flex1 marginTop16>
            <Col height40>
              <Text subtitle1 numberOfLines={2}>{val.name}</Text>
            </Col>
            <Row marginTop8>
              <Text>{val.numberOfChapter === 1 ? 'oneshot' : val.numberOfChapter + ' chương'}</Text>
              {isNew && (
                <Row backgroundColor={COLOR.DANGER_500} borderRadius={32} marginLeft8 paddingHorizontal={8} paddingVertical2>
                  <Text colorWhite>Mới</Text>
                </Row>
              )}
            </Row>
          </Col>
        </A>
      </Col>
    )
  }

  const renderItemMain2 = (val, keyLabel, isNew = false) => {
    return (
      <Col margin8>
        <A
          key={keyLabel + val.id}
          route={SCREEN_NAME.WritingDetail}
          params={{ id: val.id, name: VarHelper.toSlug(val.name) }}
        >
          <WritingItem
            data={val}
          />
        </A>
      </Col>
    )
  }

  return (
    <Col flex1 backgroundColor="white">
      <Header />
      <Col flex1>
        <Scroll flex1>
          <MainContainer>
            <RatioCol ratio={1140 / 249} width="100%" marginTop16 bgMain borderRadius16>
              <Img source={IMAGE.WritingBanner} style={STYLES.all100p} />
              {/* <Row absoluteFill paddingHorizontal='5%'>
                <Col alignItems="flex-start">
                  <ICON.ReadismWithTextLogo white height={27} />
                  <Text style={h2} colorWhite>Truyện tranh</Text>
                </Col>
              </Row> */}
            </RatioCol>

            <Row height68 marginTop12 stretch paddingHorizontal12 borderRadius4 borderWidth1 borderColor={COLOR.GRAY_500}>
              {TABS.map((tabItem, i) => {
                const isActive = tabItem.key === tab;
                const borderStyle = isActive ? { borderBottomWidth: 5, borderBottomColor: COLOR.MAIN } : {};
                return (
                  <Col
                    key={tabItem.key} {...borderStyle} paddingHorizontal16 middle
                    onHoverStyle={{ opacity: 1 }} useNestedHover
                    onPress={() => {
                      if (!isActive) setTab(tabItem.key as any);
                    }}
                  >
                    <Text btnTextLarge onHoverStyle={{ color: COLOR.MAIN }}>{tabItem.label}</Text>
                  </Col>
                );
              })}

              <Col flex1 />

              <Col middle>
                <Row onPress={() => navigate(SCREEN_NAME.CreateWriting)} height44 bgMain borderRadius6 paddingHorizontal16 middle>
                  <ICON.PlusCircle fill='white' width={20} height={20} />
                  <Text btnTextMedium colorWhite marginLeft8>Thêm mới</Text>
                </Row>
              </Col>
            </Row>

            <Row xs='100%' md='2:any:1' alignItems='flex-start' marginTop32>

              <Col marginBottom={24}>
                {homeData?.hotAndRecentlyUpdated && homeData?.hotAndRecentlyUpdated.count > 0 && (
                  <Col marginBottom={32}>
                    <Row>
                      <ICON.Fire width={22.4} height={25.6} />
                      <Text marginLeft8 h6>TRUYỆN HOT MỚI CẬP NHẬT</Text>
                      <Col flex1 />
                      <Col padding4 marginLeft8 onPress={() => goToViewAll()}>
                        <Text subtitle1 colorMain>Xem tất cả</Text>
                      </Col>
                    </Row>
                    <Row marginTop30 padding8 xs='50%' md='25%' marginHorizontal={-8}>
                      {homeData?.hotAndRecentlyUpdated.rows.map((val, i) => renderItemMain(val, 'hotAndRecentlyUpdated', true))}
                    </Row>
                  </Col>
                )}
                {homeData?.promoted && homeData?.promoted.count > 0 && (
                  <Col marginBottom={32}>
                    <Row>
                      <ICON.ThreeStar width={28.8} height={27} />
                      <Text marginLeft8 h6>TRUYỆN ĐỀ CỬ</Text>
                      <Col flex1 />
                      <Col padding4 marginLeft8 onPress={() => goToViewAll()}>
                        <Text subtitle1 colorMain>Xem tất cả</Text>
                      </Col>
                    </Row>
                    <Row marginTop30 padding8 xs='50%' md='25%' marginHorizontal={-8}>
                      {homeData?.promoted.rows.map((val, i) => renderItemMain(val, 'promoted'))}
                    </Row>
                  </Col>
                )}
                {homeData?.recommendation && homeData?.recommendation.count > 0 && (
                  <Col marginBottom={32}>
                    <Row>
                      <ICON.Sparkle width={32} height={32} />
                      <Text marginLeft8 h6>GỢI Ý THÊM</Text>
                      <Col flex1 />
                      <Col padding4 marginLeft8 onPress={() => goToViewAll()}>
                        <Text subtitle1 colorMain>Xem tất cả</Text>
                      </Col>
                    </Row>
                    <Row marginTop10 padding8 xs='50%' marginHorizontal={-8}>
                      {homeData?.recommendation.rows.map((val, i) => renderItemMain2(val, 'recommendation'))}
                    </Row>
                  </Col>
                )}
              </Col>

              <Col width40 />

              <Col marginBottom={24}>
                {homeData?.mostViewed && homeData?.mostViewed.count > 0 && (
                  <Col marginBottom={32}>
                    <Row>
                      <ICON.Star width={28} height={29} fill={'#F66262'} />
                      <Text marginLeft12 h6>XEM NHIỀU NHẤT</Text>
                    </Row>
                    <Col marginTop30 backgroundColor='#F4F5F9' padding8>
                      {homeData?.mostViewed.rows.map((val, i) => renderItemSidebar(val, 'most-viewd'))}
                    </Col>
                  </Col>
                )}
                {homeData?.recentlyUpdated && homeData?.recentlyUpdated.count > 0 && (
                  <Col marginBottom={32}>
                    <Row>
                      <ICON.NewIcon width={32} height={32} fill={'#F66262'} />
                      <Text marginLeft12 h6>MỚI CẬP NHẬT</Text>
                    </Row>
                    <Col marginTop30 backgroundColor='#F4F5F9' padding8>
                      {homeData?.recentlyUpdated.rows.map((val, i) => renderItemSidebar(val, 'recently-updated'))}
                    </Col>
                  </Col>
                )}
              </Col>

            </Row>

          </MainContainer>
        </Scroll>
      </Col>
    </Col>
  );
};

export default Writing;