import React, { useState, useRef, useCallback, useEffect } from 'react';
import { COLOR, ERROR, STYLES } from 'const';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { RateStars, Avatar, HTMLView, RichTextEditor2, Checkbox, Button } from 'components';
import { IReview } from 'type';
import { TimeHelper } from 'helpers';
import { BlurView } from 'expo-blur';
import { StyleSheet } from 'react-native';
import { ICON } from 'assets';
import Store from 'store';
import * as Animatable from 'react-native-animatable';

interface IUpdateAReviewProps {
  data: IReview,
  onSuccess?: any,
  onCancel?: any,
  [anyProps: string]: any,
}

const UpdateAReview = (props: IUpdateAReviewProps) => {
  const propStyle = usePropsStyle(props);

  const [rate, setRate] = useState(props.data.star);
  const [spoiler, setSpoiler] = useState(props.data.hasSpoiler);
  
  const [showLinkNotice, setShowLinkNotice] = useState(false);
  const [errorMes, setErrorMes] = useState('');
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const [{ }, rApi] = Store.Reviews.createStore();
  const { user } = Store.User.state;
  const editorRef = useRef<{ getHTML: Function, getJSON: Function, getText: Function, setJSON: Function }>();

  useEffect(() => {
    editorRef.current?.setJSON(props.data.contentJSON);
  }, [props.data.content]);

  const onEditorChange = useCallback((delta, oldDelta, source) => {
    if (!delta || !delta.ops) return;
    // console.log('delta', delta);
    if (delta.ops.filter(val => !!val.attributes && ('link' in val.attributes)).length > 0) {
      setShowLinkNotice(true);
    } 
  }, []);

  const submit = async () => {
    const review = editorRef.current?.getText();
    const reviewJSON = editorRef.current?.getJSON();
    const reviewHTML = editorRef.current?.getHTML();
    // if (review.length <= 300) return setErrorMes(ERROR.review_too_short);
    if (rate === 0 || !review) {
      return setErrorMes(ERROR.co_fill_all_form);
    }
    setLoading(true);
    const [res, err] = await rApi.updateReview({
      id: props.data.id,
      content: review,
      contentJSON: reviewJSON,
      contentHTML: reviewHTML,
      star: rate,
      bookId: props.data.bookId,
      bookName: props.data.bookName,
      hasSpoiler: spoiler,
    });
    if (err) return setErrorMes(ERROR.parseError(err));
    if (res.error) return setErrorMes(ERROR.parseError(res.error));
    setDone(true);
    if (typeof props.onSuccess === 'function') props.onSuccess(res.data);
  };

  if (done) {
    return (
      <Animatable.View animation="tada" useNativeDriver duration={500} style={[styles.reviewContainer, styles.done, propStyle]}>
        <ICON.Check fill={COLOR.MAIN} />
        <Text center colorMain marginLeft16>Bạn đã gửi đánh giá thành công.</Text>
      </Animatable.View>
    )
  }

  return (
    <Animatable.View animation="fadeIn" useNativeDriver duration={500} style={[styles.reviewContainer, propStyle]}>
      <Row justifyContent="space-between">
        <Row alignItems="flex-start">
          <Avatar size={48} uri={user.photoUrl} name={user.displayName} />
          <Col marginLeft13>
            <Text fontSize14>Đánh giá của bạn về cuốn sách</Text>
            <RateStars
              marginTop8
              width151
              value={rate}
              onChange={setRate}
            />
          </Col>
        </Row>
      </Row>

      <RichTextEditor2
        marginTop24
        ref={editorRef}
        onChange={onEditorChange}
      />

      {Boolean(showLinkNotice) && <Text marginTop8 textAlign="right">Lưu ý: Toàn bộ đường dẫn ra bên ngoài hệ thống sẽ bị gỡ bỏ</Text>} 
      {Boolean(errorMes) && <Text color={COLOR.DANGER_500} marginTop8 textAlign="right">{errorMes}</Text>} 
      <Row
        marginTop24
        onResponsiveStyle={{
          xs: { flexWrap: 'wrap' },
          md: { justifyContent: 'space-between' }
        }}
      >
        <Col onPress={props.onCancel} marginBottom8>
          <Text>Hủy bỏ</Text>
        </Col>
        <Row xs='100%' md='any:any'>
          <Row onPress={() => setSpoiler(!spoiler)} marginRight16 marginBottom8>
            <Checkbox value={spoiler} onChange={setSpoiler} />
            <Text marginLeft8 body1>Spoiler alert</Text>
          </Row>
          <Button width120 small solid text={"Gửi đánh giá"} loading={loading} width219 onPress={submit} marginBottom8 />
        </Row>
      </Row>
    </Animatable.View>
  );
};

const styles = StyleSheet.create({
  reviewContainer: {
    padding:24,
    borderWidth:1,
    borderColor: COLOR.BORDER,
    borderRadius:4,
    backgroundColor: 'white',
  },
  done: {
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

export default UpdateAReview;
