import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358 357" {...props}>
      <Path
        d="M223.83 18.29l115.33 115.33c24.72 24.72 24.72 64.8 0 89.52L223.83 338.47c-24.72 24.72-64.8 24.72-89.52 0L18.98 223.14c-24.72-24.72-24.72-64.8 0-89.52L134.31 18.29c24.72-24.72 64.8-24.72 89.52 0z"
        fill={props.fill || "#63b199"}
      />
      <Path
        d="M187.28 104.46c-13.22 7.63-23.63 17.98-31.25 31.07-7.63 13.09-11.43 27.37-11.43 42.84v85.37h24.23v-85.37c0-11.14 2.8-21.38 8.37-30.73 5.57-9.32 13.04-16.74 22.36-22.2 9.34-5.46 19.59-8.18 30.73-8.18V93.03c-15.47 0-29.81 3.8-43.01 11.43z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent