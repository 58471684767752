import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={208}
      height={40}
      viewBox="0 0 208 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm8.3-24.5c.8-.1 1.6-.3 2.3-.6-.5.8-1.2 1.5-2 1.8v.5c0 5.3-4 11.4-11.4 11.4-2.3 0-4.4-.7-6.2-1.8.4.1.7.1 1 .1 1.9 0 3.6-.6 5-1.7-1.8 0-3.3-1.2-3.8-2.8.3.1.5.1.8.1.4 0 .8 0 1.1-.1-1.8-.3-3.2-2-3.2-3.9.5.3 1.1.5 1.8.5-1.1-.7-1.8-1.9-1.8-3.3 0-.7.2-1.4.5-2 2 2.4 5 4 8.3 4.2-.1-.3-.1-.6-.1-.9 0-2.2 1.8-4 4-4 1.1 0 2.2.5 2.9 1.3 1-.2 1.8-.5 2.6-1-.3.9-1 1.7-1.8 2.2zM76 40c11.046 0 20-8.954 20-20S87.046 0 76 0 56 8.954 56 20s8.954 20 20 20zm6.181-29C83.68 11 85 12.321 85 13.819V25.18C85 26.68 83.679 28 82.181 28h-2.995v-6.43h2.82V18.75h-2.82v-1.145c0-.528.265-.793.529-.793h2.29v-2.818h-2.29c-1.762 0-3.171 1.673-3.171 3.611v1.145H74.43v2.819h2.114V28h-5.725C69.32 28 68 26.679 68 25.181V13.82C68 12.32 69.321 11 70.819 11H82.18zM132 40c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20zm-5.581-28h12.162c1.373 0 2.419 1.046 2.419 2.42v12.16c0 1.374-1.046 2.42-2.419 2.42h-12.162c-1.373 0-2.419-1.046-2.419-2.42V14.42c0-1.374 1.046-2.42 2.419-2.42zm11.181 8.696c0 2.68-2.288 4.904-5.1 4.904s-5.1-2.223-5.035-4.97c0-.457.066-.915.197-1.372h-1.504v6.996c0 .392.327.654.654.654h11.376a.655.655 0 00.654-.654v-6.93h-1.438c.131.391.196.914.196 1.372zm-8.369-.261c0 1.765 1.438 3.204 3.269 3.204 1.831 0 3.269-1.374 3.269-3.204 0-1.766-1.438-3.204-3.269-3.204-1.831 0-3.269 1.438-3.269 3.204zm8.827-3.073a.726.726 0 00.719-.72v-1.765a.726.726 0 00-.719-.72h-1.831a.726.726 0 00-.719.72v1.765c0 .393.327.72.719.72h1.831zM188 40c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20zm8-13.74v-5.516c0-2.96-1.614-4.372-3.7-4.372-1.681 0-2.488.942-2.892 1.614v-1.345h-3.229c.068.875 0 9.62 0 9.62h3.229v-5.382c0-.336.067-.606.135-.807.201-.538.74-1.144 1.614-1.144 1.143 0 1.614.875 1.614 2.153v5.18H196zm-15-12.578c0-.942.74-1.682 1.816-1.682 1.144 0 1.816.673 1.749 1.682 0 .941-.673 1.681-1.816 1.681-1.076 0-1.749-.74-1.749-1.681zm3.43 12.578h-3.228v-9.619h3.228v9.62z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent;
