import React, { Component } from 'react';
import { StyleSheet, BackHandler, PanResponder, KeyboardAvoidingView, Platform } from 'react-native';
import { Col } from 'react-quick-style-components';
import { DEVICE_INFO } from 'const';
import GlobalEvent from 'js-events-listener';
import * as Animatable from 'react-native-animatable';

export interface Props {
  onRef?(): void,
  onPress?(): void,
  style?: any,
  open: boolean,
  modalProps?: any,
  onClose(): void,
  [key: string]: any,
}

let instance = 0;

export class ModalContainer extends Component<any> {

  state = {
    showModal: false,
    modalData: {
      onClose: undefined,
      component: null,
      modalProps: {},
      noWrapper: false,
      animation: '',
    }
  }

  _panResponder;

  componentDidMount() {
    instance++;
    // if (instance > 1) throw new Error('ModalContainer chỉ được render 1 lần');
    // console.warn('ModalContainer chỉ được render 1 lần');
    GlobalEvent.on('MODAL_SHOW', (data) => {
      this.setState({
        showModal: true,
        modalData: data,
      });
      BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    })
    GlobalEvent.on('MODAL_HIDE', () => {
      this.setState({
        showModal: false,
        modalData: {
          onClose: undefined,
          component: null,
          modalProps: {},
        }
      });
      BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
    });
    GlobalEvent.on('MODAL_RE_RENDER', (data) => {
      this.setState({
        showModal: true,
        modalData: data,
      });
    })

    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: (evt, gestureState) => {
        const { locationX, locationY, pageX, pageY } = evt.nativeEvent;
        if (!this._modalContentLayout) return false;
        const { width, height, x, y } = this._modalContentLayout;
        if (
          !Boolean(pageX >= x && pageX <= x + width &&
            pageY >= y && pageY <= y + height)
        ) this.closeModal();
        return false;
      },
      onStartShouldSetPanResponderCapture: (evt, gestureState) => false,
      onMoveShouldSetPanResponder: (evt, gestureState) => false,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => false,
      onShouldBlockNativeResponder: (evt, gestureState) => false,
    });
  }

  closeModal = () => {
    const { modalData } = this.state;
    if (!!modalData && typeof modalData.onClose === 'function') {
      modalData.onClose();
    }
  }

  handleBackPress = () => {
    this.closeModal();
    return true;
  }

  _modalContentLayout;
  _modalContentComponent

  onModalContentLayout = (e) => {
    this._modalContentLayout = e.nativeEvent.layout;
  }

  render() {
    const { children } = this.props;
    const { showModal, modalData } = this.state;
    const webStyle = Platform.OS === 'web' ? { position: 'fixed' } : {};
    return (
      <Col flex1>
        {children}
        {Boolean(!!showModal) && (
          <Animatable.View style={[StyleSheet.absoluteFill, webStyle]} animation="fadeIn" duration={300} useNativeDriver {...this._panResponder.panHandlers}>
            <Col flex1>
              <Col style={styles.modalOverlay} />
              <KeyboardAvoidingView enabled={DEVICE_INFO.IS_IOS} behavior="padding" style={!!modalData.modalProps && !!modalData.modalProps.style ? modalData.modalProps.style : styles.modalContent}>
                <Animatable.View animation={modalData.animation || "fadeInUp"} duration={300} useNativeDriver onLayout={this.onModalContentLayout}>
                  {Boolean(modalData.noWrapper) ? (
                    Boolean(!!modalData.component) && modalData.component
                  ) : (
                    <Col middle>
                      <Col width={DEVICE_INFO.WIDTH > 480 ? '50%' : '100%'}>
                        {Boolean(!!modalData.component) && modalData.component}
                      </Col>
                    </Col>
                  )}
                  
                </Animatable.View>
              </KeyboardAvoidingView>
            </Col>
          </Animatable.View>
        )}
      </Col>
    );
  }
}

export const hideModal = () => GlobalEvent.emit('MODAL_HIDE');

export const showModal = ({ component, modalProps = {}, noWrapper = false, animation = '' }) => {
  GlobalEvent.emit('MODAL_SHOW', {
    onClose: hideModal,
    component,
    modalProps,
    noWrapper,
    animation,
  })
}

export default class ModalBasic extends Component<Props> {

  componentWillReceiveProps(nextProps) {

    if (nextProps.open === true && this.props.open === false) {
      GlobalEvent.emit('MODAL_SHOW', {
        onClose: nextProps.onClose,
        component: nextProps.children,
        modalProps: nextProps.modalProps,
      });
    } else if (nextProps.open === false && this.props.open === true) {
      GlobalEvent.emit('MODAL_HIDE');
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.open === false) return false;
    GlobalEvent.emit('MODAL_RE_RENDER', {
      onClose: nextProps.onClose,
      component: nextProps.children,
      modalProps: nextProps.modalProps,
    });
    return false;
  }

  render() {
    return null;
  }
}

const styles = StyleSheet.create({
  modalContent: {
    flex: 1,
    justifyContent: 'center',
    margin: '5%',
  },
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.2)'
  },
});