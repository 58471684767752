import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Text, Img } from 'react-quick-style-components';
import { STYLES, COLOR, useFontSizeStyle, ERROR } from 'const';
import { ICON, IMAGE } from 'assets';
import { Slider, MainContainer, RatioCol, Input, ControlledUploaderUI, Button, useSubmitUIState, Popup, Checkbox } from 'components';
import { useWindowDimensions } from 'react-native';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { ILocalFileUpload } from 'type';

import { arrayMoveImmutable } from 'array-move';

import Store from 'store';

const makeLocalFile = (path) => ({ name: '', path, mime: '', raw: undefined });

const BannerNewsFeed = () => {
  const [{ listSettings }, sApi] = Store.Setting.createStore();
  const [{  }, mApi] = Store.Misc.createMiscStore();
  const [{ loading }, setUI] = useSubmitUIState();
  const [data, setData] = useState({
    image: makeLocalFile(''),
    enable: false,
    link: '',
  });

  const dataRef = useRef(data);
  useEffect(( ) => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    const find = listSettings.find(val => val.name === 'BANNER_EVENT_HOME');
    if (!!find) {
      setData({
        image: makeLocalFile(find.data.image),
        enable: find.data.enable,
        link: find.data.link,
      });
    }
  }, [listSettings]);

  const submit = async () => {
    setUI({ loading: true });
    const [uploaded] = await mApi.uploadLocal(data.image);
    const [res, err] = await sApi.upsert({ name: 'BANNER_EVENT_HOME', value: '', data: {
      image: uploaded,
      enable: dataRef.current.enable,
      link: dataRef.current.link,
    } });
    setUI({ loading: false });
    if (err || res.error) return Popup.showError(ERROR.parseError(err || res.error));
    Popup.show('Cập nhật thành công. Lưu ý: bạn cần reload lại trang chủ để thấy thay đổi');
  };
  return (
    <Col>
      <Row onPress={() => setData({ ...data, enable: !data.enable, })}>
        <Checkbox value={data.enable} onChange={(newVal) => setData({ ...dataRef.current, enable: newVal, })} />
        <Text marginLeft16>Bật banner</Text>
      </Row>

      <Col marginTop24>
        <ControlledUploaderUI
          
          value={data.image}
          onChange={(newVal) => {
            setData({
              ...dataRef.current,
              image: newVal,
            })
          }}
          text='Cập nhật ảnh'
          subText=''
          aspectRatio={1140 / 249}
        />
      </Col>

      <Input
        label="Link"
        placeholder=""
        value={data.link} onChange={(newVal) => {
          setData({
            ...dataRef.current,
            link: newVal,
          })
        }}
        marginTop24
      />

      <Col marginTop24 middle>
        <Button loading={loading} text='Cập nhật' onPress={submit} solid width150 height44 />
      </Col>
    </Col>
  );
};

export default BannerNewsFeed;