import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Col, Row, Text, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { RatioCol, ListView, LoadMoreDetect, UseMemo } from 'components';
import { useNavFunc } from 'navigation';
import Store from 'store';
import { IMAGE, ICON } from 'assets';
import { COLOR, SCREEN_NAME } from 'const';
import FeedItem from '../feed-item/FeedItem';
import Placeholder from '../placeholder/Placeholder';
import { useFetchUIState } from 'components/hooks';
import { ActivityIndicator } from 'react-native';

interface INewsFeedProps {
  forAdmin?: boolean,
  type: 'all' | 'following',
}

const NewsFeed = (props : INewsFeedProps) => {
  const uApi = Store.User;
  const [page, setPage] = useState(1);
  const [{ loading, hasMore, isLoadingMore }, setUI] = useFetchUIState();
  const [list, setList] = useState(Store.User.state.listFeeds);
  const { navigate, navigation } = useNavFunc();

  const listRef = useRef([]);
  const pageRef = useRef(1);

  useEffect(() => {
    listRef.current = list;
    pageRef.current = page;
  }, [list, page]);

  const getData = async (p = 1) => {
    if (p === 1) setUI({ loading: true })
    else setUI({ isLoadingMore: true })

    let res, err;
    if (props.forAdmin) {
      [res, err] = await uApi.getAdminFeed(p);
    } else {
      [res, err] = await uApi.getFeed(p, props.type);
    }
    if (p === 1) setUI({ loading: false })
    else setUI({ isLoadingMore: false });
    // console.log('res.data', res.data);
    if (!!res && !!res.data && !!res.data.rows) {
      // console.log('p', p);
      setList(p === 1 ? mergeListData(res.data.rows, listRef.current) : [...listRef.current, ...res.data.rows]);
      setPage(p);
      setUI({ hasMore: Boolean(res.data.hasNext) });
    }
  };

  const mergeListData = (page1Rows = [], currentList) => {
    const mergedList = currentList.slice();
    page1Rows.reverse().forEach(val => {
      const findIndex = mergedList.findIndex(item => item.id === val.id);
      if (findIndex === -1) {
        mergedList.unshift(val)
      } else {
        mergedList[findIndex] = val;
      }
    });
    return mergedList.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1);
  }

  const fetchMoreData = useCallback(async () => {
    if (isLoadingMore) return;
    setUI({ isLoadingMore: true });
    const nextPage = pageRef.current + 1;
    // console.log('nextPage', nextPage);
    await getData(nextPage);
  }, [isLoadingMore]);

  useEffect(() => {
    setList([]);
    getData();
    const unsubscribe = navigation.addListener('focus', () => {
      getData();
    });
    return unsubscribe;
  }, [props.type]);

  if (loading && list.length === 0) return (
    <Placeholder.Newfeed width='100%' />
  );

  const renderNoData = () => {
    if (props.forAdmin) return (
      <Col height200 middle>
        <Text>Chưa có dữ liệu</Text>
      </Col>
    );
    return (
      <Col middle paddingVertical32 bgWhite borderRadius6>
        <Text center subtitle1 marginBottom24>Chào mừng bạn đến với <Text bold colorMain>Readism</Text></Text>
        <RatioCol width='50%' ratio={879/777} onPress={() => navigate(SCREEN_NAME.BookHomepage)}>
          <ICON.BookArtwork width="100%" height="100%" />
        </RatioCol>
        <Col
          marginTop24 paddingVertical16 paddingHorizontal24 borderRadius6 backgroundColor={COLOR.WARNING_500}
          onPress={() => navigate(SCREEN_NAME.BookHomepage)}
        >
          <Text btnTextLarge>Đọc và đánh giá sách</Text>
        </Col>
      </Col>
    );
  }

  // console.log(list);

  return list.length === 0 ? renderNoData() : (
    <Col>
      {Boolean(loading) && (
        <Row middle marginBottom16>
          <ActivityIndicator size='small' color={COLOR.MAIN} />
          <Text marginLeft16>Đang tải thêm</Text>
        </Row>
      )}
      <UseMemo deps={[list]}>
        {list.map((val, i) => (
          <FeedItem key={val.id} data={val} marginBottom24 />
        ))}
      </UseMemo>
      
      <UseMemo deps={[hasMore, isLoadingMore, fetchMoreData]}>
        <LoadMoreDetect
          hasMore={hasMore}
          isLoadingMore={isLoadingMore}
          fetcher={fetchMoreData}
        />
      </UseMemo>
      
    </Col>
  );
};

export default NewsFeed;