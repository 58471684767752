import React, { useState } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { COLOR, STYLES, useFontSizeStyle } from 'const';
import { useNavigation } from '@react-navigation/native';
import { SCREEN_NAME } from 'const';
import { Header, Button, Slider, MainContainer, BookLeftSidebar, NewsFeed, RatioCol, FeedItem, useSubmitUIState, usePreDataOrFetchApi, QuoteWithCreatorItem } from 'components';
import { useWindowDimensions, ActivityIndicator } from 'react-native';
import { ICON, IMAGE } from 'assets';
import { useNavFunc } from 'navigation';
import Store from 'store';
import { IActivity, IQuote } from 'type';

const QuoteDetail = () => {
  const { navigate, route } = useNavFunc();
  const { id } = route.params || {};
  const [quoteData] = usePreDataOrFetchApi<IQuote>(id, 'quote');
  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1>
        <Scroll>
          <MainContainer padding10 paddingVertical32>

            <Row xs='100%' md='1:any:2' alignItems="flex-start">
              <BookLeftSidebar
                onPressGenre={name => navigate(SCREEN_NAME.BookHomepage, { genre: name })}
                marginBottom24
              />
              <Col width32 height0 />
              <Col>
                {quoteData && (
                  <QuoteWithCreatorItem
                    data={quoteData}
                  />
                )}
              </Col>
            </Row>
          </MainContainer>

        </Scroll>
      </Col>
    </Col>
  );
};

export default QuoteDetail;