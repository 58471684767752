import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { Header, MainContainer, LeftSidebarOneThird, Input, ConversationItem, UseMemo } from '../index';
import { COLOR, SCREEN_NAME } from 'const';
import { ICON } from 'assets';
import { RightSidebarOneThird } from '.';

interface IDirectMessageLayoutProps {
  children({ navigation, route }): any,
}

const DirectMessageLayout = (props: IDirectMessageLayoutProps) => {
  const rV = useDynamicResponsiveValue();
  const viewport = rV({ xs: 'xs', md: 'md' });
  const { user } = Store.User.state;
  const [{ listConversation }, mApi] = Store.Message.createStore();
  const { navigate, navigation, route, reset } = useNavFunc();

  const { conversationId } = route.params || [];
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    mApi.getMyListConversation({ page: 1, pageSize: 1000 });
  }, []);

  const onSearch = () => {

  };

  const filteredList = (() => {
    if (!keyword) return listConversation;
    return listConversation.filter(conversation => {
      if (!conversation) return false;
      const userIds = conversation.userIds || [];
      const otherUserIds = Object.keys(userIds).filter(val => val !== user.id);
      // console.log('otherUserIds', otherUserIds);
      if (otherUserIds.length === 0) return;
      const otherId = otherUserIds[0];
      const otherData = conversation.userData[otherId];
      if (otherData.name?.toLowerCase().includes(keyword.toLowerCase())) return true;
      if (conversation.lastMessage?.content?.toLowerCase().includes(keyword.toLowerCase())) return true;
      return false;
    })
  })();

  const renderSidebarContent = () => {
    if (!user.id) return null;
    return (
      <Col bgWhite borderRadius4 borderColor={COLOR.BORDER} borderWidth1 padding24>
        <Row>
          <Input
            flex1
            label=""
            placeholder="Tìm cuộc hội thoại"
            value={keyword} onChange={setKeyword}
            leftIcon={{
              comp: <ICON.Search fill={COLOR.MAIN} width={20} height={30} />,
              onPress: onSearch
            }}
            onEnter={onSearch}
          />
          <Col padding8 onPress={() => reset(SCREEN_NAME.DirectMessage, { mode: 'gui-tin-nhan' })}>
            <ICON.PlusCircle fill={COLOR.MAIN} width={30} height={30} />
          </Col>
        </Row>

        <Col marginTop24>
          {filteredList.map((val, i) => (
            <ConversationItem
              data={val}
              key={val.id}
              onPress={() => navigate(SCREEN_NAME.DetailConversation, { conversationId: val.id })}
              isActive={conversationId === val.id}
            />
          ))}
        </Col>

      </Col>
    );
  };

  const renderMainContent = () => {
    if (!user.id) return null;
    return (
      <Col>
        {props.children({ navigation, route })}
      </Col>
    );
  }

  return viewport === 'xs' ? (
    <RightSidebarOneThird
      renderMainContent={renderMainContent}
      renderSidebarContent={renderSidebarContent}
    />
  ) : (
    <LeftSidebarOneThird
      renderMainContent={renderMainContent}
      renderSidebarContent={renderSidebarContent}
    />
  );
};

export default DirectMessageLayout;
