import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={10}
      height={18}
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M7.639 2.947h1.627V.257A21.687 21.687 0 006.908.126c-2.29 0-3.885 1.428-3.885 4.018V6.5H.5v3.021h2.523v7.604h3.122V9.521h2.457L9 6.5H6.145V4.441c0-.896.265-1.494 1.494-1.494z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
