import { Popup, RatioCol } from 'components/elements';
import { COLOR, ERROR, SCREEN_NAME, STYLES } from 'const';
import React, { useEffect, useState, useCallback, memo } from 'react';
import { Col, Row, Text, usePropsStyle, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { RateStars, Avatar, HTMLView, CollapsableText, UserName, Tooltip, PostTimestamp } from 'components';
import { IReview } from 'type';
import { TimeHelper, VarHelper } from 'helpers';
import { BlurView } from 'expo-blur';
import { StyleSheet } from 'react-native';
import { ICON } from 'assets';
import { useNavFunc, A } from 'navigation';
import Store from 'store';
import { useDropdownOptions, usePostCURDDropdown } from '../../hooks';
import { FontAwesome5 } from '@expo/vector-icons';
import UpdateAReview from '../UpdateAReview';
interface IReviewItemProps {
  data: IReview,
  children?: any,
  noSpoilerHidden?: boolean,
  withoutCollapse?: boolean,
  showLikeCommentActivity?: boolean,
  [anyProps: string]: any,
}

const ReviewItem = (props: IReviewItemProps) => {
  const { navigation, goWithPreData } = useNavFunc();
  const propStyle = usePropsStyle(props);
  const [data, setData] = useState(props.data);
  const [didRemove, setDidRemove] = useState(false);
  const [{ pressSpoiler }, rApi] = Store.Reviews.createStore();
  const [isPinned, setIsPinned] = useState(false);
  // const [{ user }] = Store.User.createUserStore();
  const { user } = Store.User.state;

  const isMyPost = !!user.id && user.id === data.createdById;
  const isAdmin = !!user.type && user.type === 'ADMIN';

  const initialShowReviewSetting = (() => {
    if (!!pressSpoiler[data.id]) return true;
    if (!!props.noSpoilerHidden) return true;
    return !data.hasSpoiler;
  })();
  const [showReviewContent, setShowReviewContent] = useState(initialShowReviewSetting);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setData(props.data);
    if (props.data.isPinned) setIsPinned(true);
  }, [props.data]);

  const onPressRemove = useCallback(() => {

    const doRemove = async () => {
      const [res, err] = await rApi.updateReview({
        id: props.data.id,
        visibility: 'trash',
      });
      if (err) return Popup.showError(ERROR.parseError(err));
      if (res.error) return Popup.showError(ERROR.parseError(res.error));
      setDidRemove(true);
    }

    Popup.show('Bạn cho chắc chắn muốn xóa?', [
      { text: 'Có', solid: false, outline: true, onPress: doRemove },
      { text: 'Không', solid: true, outline: false, onPress: () => { } },
    ]);
  }, [props.data.id]);

  const pinPost = async () => {
    const [res, err] = await rApi.updateReview({
      id: props.data.id,
      isPinned: true,
    } as any);
    if (!!res && !!res.success) {
      setIsPinned(true);
    }
  };

  const unpinPost = async () => {
    const [res, err] = await rApi.updateReview({
      id: props.data.id,
      isPinned: false,
    } as any);
    if (!!res && !!res.success) {
      setIsPinned(false);
    }
  };

  const { showDropdown } = usePostCURDDropdown({
    navigation,
    onPressEdit: () => setEditMode(true),
    onPressRemove: onPressRemove,
    hasEditPermission: isMyPost || isAdmin,
    objectId: props.data.id,
    objectType: 'review',
    additionOptions: user.type === 'ADMIN' ? [
      {
        icon: <ICON.Pin fill={COLOR.MAIN} />,
        text: isPinned ? 'Bỏ ghim' : 'Ghim bài',
        onPress: isPinned ? unpinPost : pinPost,
      }
    ] : [],
  });

  const onPressGoToDetail = () => {
    goWithPreData(
      SCREEN_NAME.BookDetailSingleReview,
      {
        bookid: data.bookId, bookname: VarHelper.toSlug(data.bookName), id: data.id
      },
      data,
    );
  };

  const handlePressSpoiler = () => {
    setShowReviewContent(true);
    // this will make user only have to press show spoiler once
    rApi.updatePressSpoiler(data.id);
  }

  const goToUserProfile = () => {
    navigation.navigate(SCREEN_NAME.UserProfile, { id: data.createdBy.id, name: VarHelper.toSlug(data.createdBy.name) });
  }

  const renderReviewContent = () => {
    const trimContent = data.content?.trim();
    if (!trimContent) return null;
    return (
      <>
        {Boolean(props.withoutCollapse) ? (
          <HTMLView content={data.contentHTML} />

        ) : (
          // <CollapsableText
          //   expandMaxHeight={150}
          //   customExpandOnPress={data.content?.length < 800 ? undefined : onPressGoToDetail}
          // >
          //   <HTMLView content={data.contentHTML} />
          // </CollapsableText>
          <Col>
            <Text numberOfLines={4}>{trimContent}</Text>
            <Col onPress={onPressGoToDetail} marginTop8>
              <Text body2 bold colorMain>Xem thêm</Text>
            </Col>
          </Col>

        )}
        {Boolean(!showReviewContent) && (
          <BlurView
            intensity={60}
            style={[StyleSheet.absoluteFill, props.withoutCollapse ? {
              paddingTop: 24, alignItems: 'center',
            } : { justifyContent: 'center', alignItems: 'center' }]}
          >
            <Text btnTextSmall>Cảnh bảo spoiler</Text>
            <Row width170 height32 borderRadius6 bgMain middle marginTop8 onPress={handlePressSpoiler}>
              <ICON.EyeOpen fill="white" />
              <Text btnTextSmall colorWhite marginLeft8>Đọc bài viết</Text>
            </Row>
          </BlurView>
        )}
      </>
    );
  }

  const renderPinnedAndThreeDots = () => {
    return (
      <Row>
        {Boolean(isPinned) && (
          <Tooltip title="Bài viết đã được ghim bởi admin">
            <Col padding12>
              <ICON.Pin fill={COLOR.GRAY_500} />
            </Col>
          </Tooltip>
        )}
        <Col width24 height24 middle onPress={showDropdown}>
          <ICON.ThreeDots />
        </Col>
      </Row>
    )
  }

  const renderViewMode = () => {
    const shouldShowLikeCommentActivity = props.showLikeCommentActivity && data?.lastComment?.createdAt;
    const showUnknownUpdate = shouldShowLikeCommentActivity && TimeHelper.diffHours(data?.updatedAt, data?.lastComment?.createdAt) >= 1;
    return (
      <>
        <Col padding24>
          {showUnknownUpdate ? (
            <Text marginBottom16>Có cập nhật mới {TimeHelper.fromNow(data?.updatedAt)}</Text>
          ) : Boolean(shouldShowLikeCommentActivity) && (
            <Row alignItems="flex-start" marginBottom16 paddingBottom8 borderBottomColor={COLOR.BORDER} borderBottomWidth1>
              <Col flex1>
                <Text><UserName  {...data?.lastComment?.createdBy} /> đã bình luận vào bài đăng</Text>
                <Col marginTop4 onHoverStyle={{ opacity: 1 }} useNestedHover onPress={onPressGoToDetail}>
                  <Text onHoverStyle={{ textDecorationLine: 'underline' }} color={COLOR.GRAY_500}>{TimeHelper.fromNow(data?.lastComment?.createdAt)}</Text>
                </Col>
              </Col>
              {renderPinnedAndThreeDots()}
            </Row>
          )}
          <Row alignItems={shouldShowLikeCommentActivity ? 'center' : "flex-start"}>
            <Col width48 onPress={goToUserProfile}>
              <Avatar size={48} name={data.createdBy?.name} uri={data.createdBy?.avatar} />
            </Col>
            <Col marginLeft16 flex1>
              <Text><UserName  {...data.createdBy} /> đã đánh giá cuốn sách <A route={SCREEN_NAME.BookDetail} params={{ id: data.bookId, name: VarHelper.toSlug(data.bookName) }}><Text bold>{data.bookName}</Text></A></Text>
              {Boolean(!shouldShowLikeCommentActivity) && (
                <PostTimestamp onPress={onPressGoToDetail} createdAt={data?.createdAt} updatedAt={data?.updatedAt} />
              )}
            </Col>
            {Boolean(!shouldShowLikeCommentActivity) && renderPinnedAndThreeDots()}
          </Row>
          <Row marginTop16>
            <Text marginRight8>Đánh giá</Text>
            <RateStars
              width101 height15 activeColor={COLOR.WARNING_400} inactiveColor={COLOR.WARNING_400}
              value={data.star}
              starSize={{ width: 17, height: 15 }}
              onChange={() => { }}
              outline
            />
          </Row>
          <Col marginTop16>
            {renderReviewContent()}
          </Col>
        </Col>
        {props.children}
      </>
    )
  }

  const renderEditMode = () => {
    return (
      <>
        <Col padding24>
          <UpdateAReview
            data={data}
            onSuccess={newData => {
              setData(newData);
              setEditMode(false);
            }}
            onCancel={() => {
              setEditMode(false);
            }}
          />
        </Col>
        {props.children}
      </>
    )
  }

  const renderDidRemove = () => {
    return (
      <Col padding24 middle>
        <Text>Bài đánh giá đã xóa</Text>
      </Col>
    )
  }

  return (
    <Col style={propStyle} borderWidth1 borderColor={COLOR.BORDER} borderRadius4 bgWhite>
      {(didRemove || data.visibility === 'trash') ? renderDidRemove() : editMode === true ? renderEditMode() : renderViewMode()}
    </Col>
  );
};

export default memo(ReviewItem);
