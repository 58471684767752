

class DomHelper {
  loadjscssfile(filename, filetype) {
    if (filetype == "js") { //if filename is a external JavaScript file
      var fileref = document.createElement('script')
      fileref.setAttribute("type", "text/javascript")
      fileref.setAttribute("src", filename)
    } else if (filetype == "css") { //if filename is an external CSS file
      // @ts-ignore
      var fileref = document.createElement("link")
      fileref.setAttribute("rel", "stylesheet")
      fileref.setAttribute("type", "text/css")
      fileref.setAttribute("href", filename)
    }
    if (typeof fileref != "undefined")
      document.getElementsByTagName("head")[0].appendChild(fileref)
  }

  loadFacebook() {
    this.loadjscssfile("https://connect.facebook.net/vi_VN/sdk.js#xfbml=1&version=v11.0&appId=158366536186297&autoLogAppEvents=1", "js");
  }
}

export default new DomHelper();