import React from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { COLOR, STYLES, useFontSizeStyle } from 'const';
import { useNavigation } from '@react-navigation/native';
import { SCREEN_NAME } from 'const';
import { Header, Button, Slider, MainContainer, BookLeftSidebar, NewsFeed } from 'components';
import { useWindowDimensions } from 'react-native';
import { ICON, IMAGE } from 'assets';
import { useNavFunc } from 'navigation';
import Store from 'store';

const HomeAdminAllFeed = () => {
  const { navigate } = useNavFunc();
  const [{ user, token }] = Store.User.createUserStore();

  if (user.type !== 'ADMIN') return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1 middle>
        <Text>Bạn không có quyền truy cập trang này</Text>
      </Col>
    </Col>
  );

  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1>
        <Scroll>
          <MainContainer padding10 paddingVertical32>
            <Row xs='100%' md='1:any:2' alignItems="flex-start">
              <BookLeftSidebar
                onPressGenre={name => navigate(SCREEN_NAME.BookHomepage, { genre: name })}
              />
              <Col width32 height0 />
              <NewsFeed forAdmin />
            </Row>
          </MainContainer>
          
        </Scroll>
      </Col>
    </Col>
  );
};

export default HomeAdminAllFeed;