import React, { useEffect, useImperativeHandle, useRef, forwardRef } from 'react';
import { Col, usePropsStyle } from 'react-quick-style-components';
import GlobalEvent, { useEvent } from 'js-events-listener/react';

interface IRichTextEditorProps {
  id?: string,
  placeholder?: string,
  height?: number,
  onChange?: any,
  allowLink?: boolean,
  [additionProps: string]: any,
}

const IFrameEditor = forwardRef((props : IRichTextEditorProps, ref) => {
  const propsStyle = usePropsStyle(props);
  const url = `/editor-screen?placeholder=${props.placeholder || 'Hãy viết gì đó'}${props.allowLink ? '&allow_link=1' : ''}`;

  useEffect(() => {
    const handleEvent = (e) => {
      console.log(e.origin);
      try {
        const data = JSON.parse(e.data);
        const { action, payload } = data;
        GlobalEvent.emit('IFRAME_EDITOR_RECEIVE_EVENT', data);
      } catch(e) {}
    };
    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);

  const sendEvent = (name, payload) => {
    const element : HTMLIFrameElement = iframeRef.current;
    if (!element) return;
    if (!element.contentWindow) return;
    element.contentWindow.postMessage(JSON.stringify({
      action: name,
      payload,
    }), '*');
  }

  const iframeRef = useRef();

  const iframeLoadedRef = useRef(false);
  const onIframeLoad = () => {
    setTimeout(() => {
      iframeLoadedRef.current = true;
      GlobalEvent.emit('IFRAME_EDITOR_LOADED', undefined);
    }, 1000);
  };
  const waitUtilIframeLoaded = () => new Promise((resolve, reject) => {
    if (iframeLoadedRef.current) return resolve(undefined);
    GlobalEvent.on('IFRAME_EDITOR_LOADED', () => {
      resolve(undefined);
    });
  }) ;
  const sendEventAndReceive = (action, payload = undefined) => new Promise((resolve, reject) => {
    sendEvent(action, payload);
    let eventId = GlobalEvent.on('IFRAME_EDITOR_RECEIVE_EVENT', (data : any) => {
      if (data.action === action) {
        resolve(data.payload);
        typeof eventId === 'string' && GlobalEvent.rm(eventId);
      }
    });
  });

  useImperativeHandle(ref, () => ({
    getHTML: async () => {
      await waitUtilIframeLoaded();
      return await sendEventAndReceive('getHTML');
    },
    getJSON: async () => {
      await waitUtilIframeLoaded();
      return await sendEventAndReceive('getJSON');
    },
    getText: async () => {
      await waitUtilIframeLoaded();
      return await sendEventAndReceive('getText');
    },
    setJSON: async (ops) => {
      await waitUtilIframeLoaded();
      sendEvent('setJSON', ops);
    },
  }));



  return (
    <Col style={propsStyle}>
      <iframe onLoad={onIframeLoad} ref={iframeRef} src={url} style={{ border: 'none', width: '100%', height: 300 }} />
    </Col>
  );
});

export default IFrameEditor;