import React from 'react';
import { Col, Scroll, Row, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import Header from '../widgets/header/Header';
import MainContainer from './MainContainer';

interface ILeftSidebarOneThirdProps {
  renderHeader?: any,
  renderMainContent?: any,
  renderSidebarContent?: any,
}

const LeftSidebarOneThird = (props: ILeftSidebarOneThirdProps) => {
  const rV = useDynamicResponsiveValue();
  return (
    <Scroll flex1>
      <Col flex1>
        {Boolean(props.renderHeader) ? props.renderHeader() : <Header />}
        <Col marginTop={rV({ xs: 16, lg: 32 })} flex1>
          <MainContainer flex1>
            <Row
              flex1
              alignContent="flex-start"
              alignItems="stretch"
              xs='100%'
              md='1:any:2'
            >
              <Col
                marginHorizontal={rV({ xs: 0, lg: 0 })}
                marginBottom={rV({ xs: 16, lg: 0 })}
              >
                {Boolean(props.renderSidebarContent) && props.renderSidebarContent()}
              </Col>

              <Col width25 height0 />
              <Col
                // marginHorizontal={rV({ xs: 0, lg: 0 })}
                marginBottom={rV({ xs: 16, lg: 0 })}
              >
                {Boolean(props.renderMainContent) && props.renderMainContent()}
              </Col>

            </Row>
          </MainContainer>
        </Col>
      </Col>
    </Scroll>
  );
};

export default LeftSidebarOneThird;
