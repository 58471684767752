import React, { memo } from 'react';
import { Col, Row, Text, usePropsStyle } from 'react-quick-style-components';
import { StyleSheet, ActivityIndicator } from 'react-native';
import { COLOR } from 'const';

interface IButtonProps {
  text?: any,
  style?: any,
  solid?: boolean,
  outline?: boolean,
  large?: boolean,
  small?: boolean,
  leftIcon?: any,
  rightIcon?: any,
  onPress?: any,
  loading?: boolean,
  colors?: {
    bg?: string,
    border?: string,
    text?: string,
  }
  [additionProps: string]: any,
}

const Button = (props : IButtonProps) => {
  // console.log('props', props);
  const propsStyle = usePropsStyle(props);

  const sizeStyle = props.large ? styles.large : styles.small;
  const solidStyle = (() => {
    const solidDefaultStyle = !!props.outline ? styles.outline : styles.solid;
    if (props.colors?.bg || props.colors?.border) {
      const overwriteStyle = { backgroundColor: props.colors?.bg, borderColor: props.colors?.border };
      if (!overwriteStyle.backgroundColor) delete overwriteStyle.backgroundColor;
      if (!overwriteStyle.borderColor) delete overwriteStyle.borderColor;
      return [solidDefaultStyle, overwriteStyle];
    }
    return solidDefaultStyle;
  })();
  
  const textProps = (() => {
    return {
      style: [props.solid ? styles.solidText : styles.outlineText, props.colors?.text ? { color: props.colors?.text } : {}],
      btnTextMedium: props.large,
      btnTextSmall: props.small,
    };
  })();

  

  return (
    <Row style={[sizeStyle, solidStyle, propsStyle, props.style]} onPress={props.loading ? undefined : props.onPress}>
      {Boolean(props.loading) ? (
        <ActivityIndicator size="small" color={props.outline ? COLOR.PRI_500 : COLOR.WHITE_100} style={{ marginRight: 8 }} />
      ) : Boolean(props.leftIcon) ? props.leftIcon : null}
      {Boolean(props.text) && <Text center {...textProps}>{props.text}</Text>}
      {Boolean(props.rightIcon) && props.rightIcon}
    </Row>
  );
};

const styles = StyleSheet.create({
  large: {
    height: 43,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  small: {
    height: 37,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  outline: {
    borderColor: COLOR.PRI_500,
    borderWidth: 1,
  },
  outlineText: {
    color: COLOR.PRI_500,
  },
  solid: {
    backgroundColor: COLOR.PRI_500,
  },
  solidText: {
    color: COLOR.WHITE_100,
  }
});

export default memo(Button);
