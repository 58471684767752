import React, { useEffect, useRef, useImperativeHandle, forwardRef, useState, useMemo, useCallback } from 'react';
import { Col, usePropsStyle } from 'react-quick-style-components';
import { ILocalFileUpload } from 'type';
import { VarHelper } from 'helpers';
import _ from 'lodash';

import CMSUploaderUI from './CMSUploaderUI';

interface IControlledUploaderUIProps {
  text?: string,
  subText?: string,
  aspectRatio: number,
  onChange?(newValue: ILocalFileUpload): any,
  value: ILocalFileUpload,
  [additionProps: string]: any,
}

const ControlledUploaderUI = forwardRef((props : IControlledUploaderUIProps, ref) => {

  const uploadRef = useRef<{ uploadLocal: Function, setUri: Function }>();
  const valueRef = useRef(props.value);

  useEffect(() => {
    // console.log('props.value', props.value);
    valueRef.current = props.value;
    !!props.value?.path && uploadRef.current?.setUri(props.value.path);
  }, [props.value]);

  const getUploadedUrl = useCallback(() => {
    return VarHelper.erria(async () => {
      if (!props.value.raw && !props.value.path) return '';
      if (!props.value.raw && !!props.value.path && props.value.path.includes('https://')) return props.value.path;
      const [uploadedUrl, err] = await uploadRef.current.uploadLocal(props.value);
      if (err) throw err;
      return uploadedUrl;
    });
  }, [props.value]);

  useImperativeHandle(ref, () => ({
    getUploadedUrl,
  }));

  return useMemo(() => (
    <CMSUploaderUI
      text={props.text}
      subText={props.subText}
      aspectRatio={props.aspectRatio}
      onChange={props.onChange}
      ref={uploadRef}
    />
  ), []);
});

export default ControlledUploaderUI;