import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={21}
      height={16}
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M20.59 5.459c-.944.738-2.133 1.64-6.317 4.676-.82.615-2.338 1.969-3.773 1.969-1.477 0-2.953-1.354-3.814-1.97C2.502 7.1 1.313 6.198.369 5.46c-.164-.123-.369 0-.369.205v8.367C0 15.14.861 16 1.969 16H19.03A1.97 1.97 0 0021 14.031V5.664c0-.205-.246-.328-.41-.205zM10.5 10.75c.943.041 2.297-1.19 2.994-1.682 5.455-3.937 5.865-4.306 7.096-5.29a.933.933 0 00.41-.78v-.78C21 1.153 20.098.25 19.031.25H1.97A1.97 1.97 0 000 2.219v.78c0 .327.123.614.37.778 1.23.985 1.64 1.354 7.095 5.291.697.493 2.05 1.723 3.035 1.682z"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
