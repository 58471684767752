import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={24}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.832 1.085a1.6 1.6 0 0 0-2.32-.616c-.552.368-.982.893-1.315 1.408-.343.528-.645 1.14-.912 1.785-.535 1.287-.983 2.83-1.344 4.375a50.18 50.18 0 0 0-.981 5.728 4.224 4.224 0 0 1-1.512-1.707c-.525-1.088-.637-2.455-.637-4.247A1.6 1.6 0 0 0 4.08 6.68 11.17 11.17 0 0 0 .8 14.6a11.2 11.2 0 1 0 19.12-7.92c-.947-.946-1.568-1.576-2.157-2.347-.58-.762-1.158-1.701-1.931-3.248Zm-.44 20.107A4.8 4.8 0 0 1 7.2 17.8s1.406.8 4 .8c0-1.6.8-6.4 2-7.2.8 1.6 1.258 2.069 2.194 3.006A4.785 4.785 0 0 1 16.8 17.8a4.784 4.784 0 0 1-1.406 3.394l-.002-.002Z"
      fill={props.fill || "#F66262"}
    />
  </Svg>
)

export default SvgComponent
