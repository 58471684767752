import { useState } from 'react';

interface IFetchUI {
  loading?: boolean,
  hasMore?: boolean,
  isLoadingMore?: boolean,
}

export const useFetchUIState = (initialState = undefined) : [IFetchUI, (newValue: IFetchUI) => void] => {

  const [UIState, setUIState] = useState<IFetchUI>(initialState || {
    hasMore: false,
    loading: false,
    isLoadingMore: false,
  });

  const setValue = (obj) => setUIState(v => ({ ...v, ...obj }));

  return [
    UIState,
    setValue,
  ];
}