import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M8.736 13.568 2 10.583 8.736 7.6 11.783 1l3.047 6.599 6.736 2.984-6.736 2.985-3.047 6.599-3.047-6.6Zm15.262 7.664-2.18-4.71-2.18 4.71-4.808 2.136 4.807 2.136 2.18 4.71 2.18-4.71 4.808-2.136-4.807-2.136Z"
      fill={props.fill || "#F66262"}
    />
  </Svg>
)

export default SvgComponent
