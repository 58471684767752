import React, { Component } from 'react';
import { Col } from 'react-quick-style-components';
import { AsyncSelect } from 'components';
import Store from 'store';

const SelectBook = ({ value, onChange }) => {
  const [{}, bApi] = Store.Book.createBookStore();
  return (
    <AsyncSelect
      label="Chọn sách"
      placeholder="Nhập tìm và chọn sách"
      value={value}
      onChange={onChange}
      defaultOptions={[]}
      onInputChange={(name) => { return name; }}
      loadOptions={async (value, callback) => {
        const [res, err] = await bApi.listBook({
          visibility: 'public',
          page: 1, pageSize: 20,
          options: {
            search: [{ label: 'name', value: value }]
          },
        });
        if (!!res && !!res.data && !!res.data.rows) {
          callback(res.data.rows.map(val => ({ value: val.id, label: val.name })));
        }
      }}
    />
  )
};

export default SelectBook;