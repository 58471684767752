import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={26}
      height={21}
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M24.875 8.625h-2.5v-2.5c0-.313-.313-.625-.625-.625H20.5a.642.642 0 00-.625.625v2.5h-2.5a.642.642 0 00-.625.625v1.25c0 .352.273.625.625.625h2.5v2.5c0 .352.273.625.625.625h1.25a.642.642 0 00.625-.625v-2.5h2.5a.642.642 0 00.625-.625V9.25c0-.313-.313-.625-.625-.625zM9.25 10.5c2.734 0 5-2.227 5-5 0-2.734-2.266-5-5-5-2.773 0-5 2.266-5 5 0 2.773 2.227 5 5 5zm3.477 1.25h-.665c-.859.43-1.796.625-2.812.625-1.016 0-1.992-.195-2.852-.625h-.664C2.844 11.75.5 14.133.5 17.023v1.602c0 1.055.82 1.875 1.875 1.875h13.75c1.016 0 1.875-.82 1.875-1.875v-1.602c0-2.89-2.383-5.273-5.273-5.273z"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
