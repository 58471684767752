import React, { useState } from 'react';
import { Col, Text, Row, Scroll } from 'react-quick-style-components';
import { ICON } from 'assets';
import { showModal, hideModal, Select, Input, Button } from '../elements'
import { IUseDropdownOptions, useDropdownOptions } from './useDropdownOptions';
import { useSubmitUIState } from './useSubmitUIState';
import { COLOR, ERROR } from 'const';
import { MaterialIcons } from '@expo/vector-icons';
import Store from 'store';

const ReportPopup = ({ objectId, objectType }) => {
  const [reason, setReason] : any = useState();
  const [explain, setExplain] = useState('');
  const [{ loading, errorMes, btnText }, setSubmitUI] = useSubmitUIState();
  
  const uApi = Store.User;

  const submitReport = async () => {
    if (!reason) return setSubmitUI({ errorMes: ERROR.co_fill_all_form });
    if (reason?.value === 'OTHER' && !explain) return setSubmitUI({ errorMes: ERROR.rp_need_explain_for_other });
    setSubmitUI({ loading: true });
    const [res, err] = await uApi.report({
      objectId, objectType, reason: reason?.value, explain,
    });
    if (err) return setSubmitUI({ loading: false, errorMes: ERROR.parseError(err) });
    if (res.error) return setSubmitUI({ loading: false, errorMes: ERROR.parseError(res.error) });
    setSubmitUI({ loading: false, errorMes: '', btnText: 'Thành công' });
    setTimeout(() => {
      setSubmitUI({ btnText: '' });
      hideModal();
    }, 1000);
  };

  return (
    <Col borderRadius8 bgWhite shadow padding24>
      <Row justifyContent="space-between">
        <Text fontSize20 bold color={COLOR.DANGER_500}>Báo cáo vi phạm</Text>
        <Col width30 height30 middle onPress={hideModal}>
          <ICON.Close fill={COLOR.GRAY_500} width={13} height={20} />
        </Col>
      </Row>
      <Text marginTop16>Thấy bài này không phù hợp? Vui lòng thông báo cho chúng tôi biết lý do</Text>
      <Select
        marginTop16
        value={reason}
        label="Lý do"
        required
        placeholder=""
        options={[
          { label: 'Bài viết Spam', value: 'SPAM' },
          { label: 'Ngôn từ, nội dung không phù hợp', value: 'NOT_SUITABLE_WORDS' },
          { label: 'Vi phạm bản quyền', value: 'COPYRIGHT_ISSUE' },
          { label: 'Lý do khác', value: 'OTHER' },
        ]}
        onChange={(newData) => setReason(newData)}
      />
      <Input
        marginTop16
        multiline
        inputStyle={{ padding: 12 }}
        value={explain}
        onChange={setExplain}
        placeholder=""
        label="Chi tiết"
      />
      <Col marginTop16 alignItems="flex-end">
        {Boolean(errorMes) && <Text textAlign="right" marginBottom4 color={COLOR.DANGER_500}>{errorMes}</Text>}
        <Button onPress={submitReport} loading={loading} width154 height43 solid small text={btnText || "Gửi báo cáo"} />
      </Col>
    </Col>
  );
}

export const usePostCURDDropdown = ({ navigation, onPressRemove, onPressEdit, hasEditPermission, objectId, objectType, additionOptions = [] }) => {

  const { user } = Store.User.state;
  
  const openReportPopup = () => {
    showModal({
      component: <ReportPopup objectId={objectId} objectType={objectType} />,
    })
  };

  const { showDropdown, hideDropdown } = useDropdownOptions({
    navigation,
    width: 150,
    positions: { top: 60, right: 24 + 36 },
    dropdownOptions: [
      !hasEditPermission ? undefined : {
        icon: <ICON.SolidPen height={12} width={12} fill={COLOR.MAIN} />,
        text: 'Chỉnh sửa',
        onPress: onPressEdit
      },
      !hasEditPermission ? undefined : {
        icon: <ICON.Trash height={12} width={12} fill={COLOR.MAIN} />,
        text: 'Xóa',
        onPress: onPressRemove,
      },
      !!user && !!user.id ? {
        icon: <MaterialIcons name="report" size={16} color={COLOR.MAIN} />,
        text: 'Báo cáo',
        onPress: openReportPopup,
      } : undefined,
      ...additionOptions,
    ].filter(val => !!val),
    useEventPosition: true,
  });
  return { showDropdown, hideDropdown };
};