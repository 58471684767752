import { COLOR, STYLES } from 'const';
import React, { useState, useEffect } from 'react';
import { Col, Row, Text, Img, usePropsStyle } from 'react-quick-style-components';
import { RatioCol } from 'components';
import { IManga } from 'type';

interface IMangaItemProps {
  data: IManga,
  onPress?: any,
  [anyProp: string]: any,
}

const MangaItem = (props : IMangaItemProps) => {
  const propStyle = usePropsStyle(props);
  const { data, onPress } = props;
  return (
    <Col borderRadius4 bgWhite style={propStyle} onPress={onPress}>
      <RatioCol width='100%' ratio={170/228} shadow>
        <Img source={{ uri: data.image || '' }} style={STYLES.all100p} />
      </RatioCol>
      <Text marginTop16 subtitle1>{data.name}</Text>
      <Text marginTop10 fontSize14>{data.numberOfChapter} chương</Text>
    </Col>
  );
};

export default MangaItem;