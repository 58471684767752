import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={18}
      height={19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M15.875 18.25c1.016 0 1.875-.82 1.875-1.875V2.625c0-1.016-.86-1.875-1.875-1.875H2.125C1.07.75.25 1.61.25 2.625v13.75c0 1.055.82 1.875 1.875 1.875h13.75zm-8.008-3.828a.595.595 0 01-.898 0l-4.063-4.063a.613.613 0 010-.859l.899-.898a.613.613 0 01.86 0l2.772 2.734 5.86-5.86a.613.613 0 01.86 0l.898.899a.613.613 0 010 .86l-7.188 7.187z"
        fill="#63B199"
      />
    </Svg>
  )
}

export default SvgComponent
