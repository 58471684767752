import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { CMSLayout, Input, Button } from 'components';
import { COLOR, ERROR } from 'const';
import { ActivityIndicator } from 'react-native';
import { ICON } from 'assets';

import Store from 'store';
import { VarHelper } from 'helpers';

const PAGE = {
  LIST: 'LIST',
  DETAIL: 'DETAIL',
}

interface ISingleGenreTypeProps {
  type: 'BOOK' | 'WRITING' | 'MANGA',
}

const SingleGenreType = ({ type } : ISingleGenreTypeProps) => {
  const [{ listGenres }, gApi] = Store.Genres.createStore();
  const [page, setPage] = useState(PAGE.LIST);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [editId, setEditId] = useState('');
  const [editChildId, setEditChildId] = useState('');
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [editGenreName, setEditGenreName] = useState('');
  const [editChildGenreName, setEditChildGenreName] = useState('');
  const [tempGenre, setTempGenre] = useState('');

  const topInputRef = useRef(null);

  useEffect(() => {
    setEditId('');
    setEditGenreName('');
    setPage(PAGE.LIST);
    gApi.getList(type);
  }, [type]);

  const addTempGenre = async () => {
    if (!tempGenre) return alert(ERROR.co_fill_all_form);
    setLoading(true);
    const obj = !!editId && page === PAGE.DETAIL ? { name: tempGenre, treeLevel: editId, type } : { name: tempGenre, type }
    const [res, err] = await gApi.upsert(obj);
    setLoading(false);
    if (VarHelper.handleError(res, err, setErrorMessage).shouldReturn) return;
    setTempGenre('');
    topInputRef.current?.focus();
  };

  const onUpdate = async (g) => {
    if (!editId) return;
    if (g.name === editGenreName) {
      setEditId('');
      setEditGenreName('');
      return;
    }
    setLoadingEdit(true);
    const [res, err] = await gApi.upsert({ ...g, name: editGenreName, type });
    setLoadingEdit(false);
    if (VarHelper.handleError(res, err, setErrorMessage).shouldReturn) return;
    setEditId('');
    setEditGenreName('');
  }

  const onUpdateChild = async (g) => {
    if (!editChildId) return;
    if (g.name === editChildGenreName) {
      setEditChildId('');
      setEditChildGenreName('');
      return;
    }
    setLoadingEdit(true);
    const [res, err] = await gApi.upsert({ ...g, name: editChildGenreName, type });
    setLoadingEdit(false);
    if (VarHelper.handleError(res, err, setErrorMessage).shouldReturn) return;
    setEditChildId('');
    setEditChildGenreName('');
  } 

  const onRemove = async (g) => {
    const shouldRemove = confirm(`Bạn có chắc muốn xóa thể loại "${g.name}" không? Xóa thể loại có thể ảnh hưởng đến những sách đã tạo.`);
    if (!shouldRemove) return;
    const [res, err] = await gApi.remove(g.id);
    if (VarHelper.handleError(res, err, setErrorMessage).shouldReturn) return;
  }

  const renderDetail = () => {
    const childLevel = listGenres.filter(val => val.treeLevel.includes(editId + '.')).sort((a, b) => a.name > b.name ? 1 : -1);
    return (
      <Col flex1>
        <Row onPress={() => { setPage(PAGE.LIST); setEditId(''); }}>
          <Text colorMain textDecorationLine='underline'>← Quay lại</Text>
        </Row>
        <Row marginTop16>
          <Col flex1>
            <Input placeholder={"Thêm thể loại con của: " + editGenreName} value={tempGenre} onChange={setTempGenre}
              onRef={ref => topInputRef.current = ref} onEnter={addTempGenre} />
          </Col>
          <Button marginLeft16 small solid text='Thêm' onPress={addTempGenre} width75 height44 loading={loading} />
        </Row>
        {childLevel.map((g, i) => (
          <Row
            width={'33%'} padding8 key={'genres-' + i} height50
            onHoverStyle={{ borderBottomWidth: 1, borderBottomColor: COLOR.BORDER }}
            useNestedHover
          >
            <Col flex1>
              {Boolean(editChildId === g.id) ? (
                <Input label="" placeholder="Sửa thể loại" value={editChildGenreName} onChange={setEditChildGenreName} />
              ) : (
                <Text>{g.name}</Text>
              )}
            </Col>
            <Row marginRight8 display={editChildId === g.id ? "flex" : "none"} onHoverStyle={{ display: 'flex' }}>
              {Boolean(editChildId === g.id) ? (
                <Col padding4 onPress={loadingEdit ? undefined : () => onUpdateChild(g)}>
                  {Boolean(loadingEdit) ? <ActivityIndicator size="small" color={COLOR.MAIN} /> : <ICON.Check width={15} fill={COLOR.MAIN} />}
                </Col>
              ) : (
                <Col padding4 onPress={() => {
                  setEditChildGenreName(g.name);
                  setEditChildId(g.id);
                }}>
                  <ICON.WhitePen height={15} width={15} fill={COLOR.MAIN} />
                </Col>
              )}
              <Col padding4 onPress={() => onRemove(g)}>
                <ICON.Trash height={15} width={15} fill={COLOR.MAIN} />
              </Col>
            </Row>
          </Row>
        ))}
      </Col>
    );
  }

  return page === PAGE.DETAIL ? renderDetail() : (
    <Col flex1>
      <Row>
        <Col flex1>
          <Input placeholder="Thêm thể loại" value={tempGenre} onChange={setTempGenre}
            onRef={ref => topInputRef.current = ref} onEnter={addTempGenre} />
        </Col>
        <Button marginLeft16 small solid text='Thêm' onPress={addTempGenre} width75 height44 loading={loading} />
      </Row>
      {Boolean(errorMessage) && (
        <Text marginTop16 color={COLOR.DANGER_500}>{errorMessage}</Text>
      )}
      <Row style={{ flexWrap: 'wrap' }} marginTop24>
        {listGenres.filter(val => !val.treeLevel.includes('.')).sort((a, b) => a.name > b.name ? 1 : -1).map((g, i) => (
          <Row
            width={'33%'} padding8 key={'genres-' + i} height50
            onHoverStyle={{ borderBottomWidth: 1, borderBottomColor: COLOR.BORDER }}
            useNestedHover
          >
            <Col flex1>
              {Boolean(editId === g.id) ? (
                <Input label="" placeholder="Sửa thể loại" value={editGenreName} onChange={setEditGenreName} />
              ) : (
                <Text>{g.name}</Text>
              )}
            </Col>
            <Row marginRight8 display={editId === g.id ? "flex" : "none"} onHoverStyle={{ display: 'flex' }}>
              <Col padding4 onPress={() => {
                setPage(PAGE.DETAIL);
                setEditGenreName(g.name);
                setEditId(g.id);
              }}>
                <ICON.BurgerMenu height={15} width={15} fill={COLOR.MAIN} />
              </Col>
              {Boolean(editId === g.id) ? (
                <Col padding4 onPress={loadingEdit ? undefined : () => onUpdate(g)}>
                  {Boolean(loadingEdit) ? <ActivityIndicator size="small" color={COLOR.MAIN} /> : <ICON.Check width={15} fill={COLOR.MAIN} />}
                </Col>
              ) : (
                <Col padding4 onPress={() => {
                  setEditGenreName(g.name);
                  setEditId(g.id);
                }}>
                  <ICON.WhitePen height={15} width={15} fill={COLOR.MAIN} />
                </Col>
              )}
              <Col padding4 onPress={() => onRemove(g)}>
                <ICON.Trash height={15} width={15} fill={COLOR.MAIN} />
              </Col>
            </Row>
          </Row>
        ))}
      </Row>
    </Col>
  );
};

export default SingleGenreType;