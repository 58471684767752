import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row, Text, usePropsStyle } from 'react-quick-style-components';
import Store from 'store';

interface IGenresWidgetProps {
  onPressGenre?: any,
  title?: string
  [anyProps: string]: any,
}

const GenresWidget = (props : IGenresWidgetProps) => {
  const propStyle = usePropsStyle(props);
  const [expandedGenre, setExpandedGenre] = useState(false);
  const [focusedGenre, setFocusedGenre] = useState('');
  const [{ listGenres }, gApi] = Store.Genres.createStore();
  useEffect(() =>{
    gApi.getList();
  }, []);

  const handlePressGenres = (name, treeLevel = undefined) => {
    if (!!treeLevel && !treeLevel.includes('.')) setFocusedGenre(treeLevel);
    if (typeof props.onPressGenre === 'function') {
      props.onPressGenre(name);
    }
  };

  const renderSubGenre = (id) => {
    return (
      <Col marginLeft8>
        {listGenres.filter(val => !!val.treeLevel && val.treeLevel.includes(id + '.')).map((val, i) => !val ? null : (
          <Col key={'danh-muc-'+i} onPress={() => handlePressGenres(val.name, val.treeLevel)}>
            <Text body1 marginTop12>- {val.name}</Text>
          </Col>
        ))}
      </Col>
    );
  };

  return useMemo(() => (
    <Col style={propStyle}>
      <Col onPress={() => handlePressGenres('all')}>
        <Text h6>{props.title || 'TẤT CẢ DANH MỤC'}</Text>
      </Col>
      <Col marginTop12>
        {listGenres.filter(val => !val.treeLevel?.includes('.')).slice(0, expandedGenre ? listGenres.length : 6).map((val, i) => !val ? null : (
          <Col key={'danh-muc-'+i} onPress={() => handlePressGenres(val.name, val.treeLevel)}>
            <Text body1 marginTop12>{val.name}</Text>
            {!!focusedGenre && focusedGenre.includes(val.id) && renderSubGenre(val.id)}
          </Col>
        ))}
        {Boolean(listGenres.length > 6) && (
          <Col onPress={() => setExpandedGenre(!expandedGenre)}>
            <Text bold colorMain marginTop12>{expandedGenre ? 'Thu gọn' : 'Xem thêm'}</Text>
          </Col>
        )}
      </Col>
    </Col>
  ), [listGenres, expandedGenre, focusedGenre]);
};

export default GenresWidget;