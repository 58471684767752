import React, { Component } from 'react';
import ReactQuill from "react-quill";
import { Col, usePropsStyle } from 'react-quick-style-components';

import './RichTextEditor3.web.css';

interface IRichTextEditorProps {
  id?: string,
  placeholder?: string,
  height?: number,
  onChange?: any,
  allowLink?: boolean,
  [additionProps: string]: any,
}

class Editor extends Component {

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"]
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image"
  ];
  handleProcedureContentChange = (content, delta, source, editor) => {
    //let has_attribues = delta.ops[1].attributes || "";
    //console.log(has_attribues);
    //const cursorPosition = e.quill.getSelection().index;
    // this.quill.insertText(cursorPosition, "★");
    //this.quill.setSelection(cursorPosition + 1);
  };

  quill

  getText = () => this.quill?.getEditor().getText();
  getHTML = () => this.quill?.getEditor().getHTML();
  getJSON = () => this.quill?.getEditor().getContents();

  setJSON = (ops) => {
    console.log(Object.keys(this.quill.getEditor()));
    this.quill?.getEditor().setContents(ops, 'api');
  }

  render() {
    return (
      <Col height300>
        <div>
          <ReactQuill
            theme="snow"
            className="readism-react-quill"
            ref={ref => this.quill = ref}
            modules={this.modules}
            formats={this.formats}
            onChange={this.handleProcedureContentChange}
          >
            <div className="my-editing-area" />
          </ReactQuill>
        </div>
      </Col>
    );
  }
}

export default Editor;
