import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={440}
      height={389}
      viewBox="0 0 440 389"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        opacity={0.2}
        d="M95.57 69.41s72.76-82.798 157.251-42.615 12.395 76.714 27.41 75.645c15.015-1.06 89.506-60.43 120.464 31.838s11.316 142.973-50.496 150.211c-61.812 7.238 26.01 36.237-10.37 61.755-36.379 25.518-173.392 56.92-205.371 14.552-31.98-42.369.245-95.486.245-95.486s-39.984 68.199-74.367 31.498c-34.383-36.702-34.42-130.673.388-145.424 34.809-14.75 60.308-32.074 43.249-30.844-17.06 1.23-31.8-25.178-8.402-51.13z"
        fill="#6A8185"
      />
      <Path
        d="M187.612 178.709s-27.154-1.93-35.764-31.355c-8.61-29.435-38.783-54.49-43.637-65.304-4.853-10.814 33.343-13.407 54.763 5.81 21.421 19.216 38.178 68.775 24.638 90.849z"
        fill="#3E2D4A"
      />
      <Path
        d="M183.941 176.126c-1.06-1.107-2.138-4.873-3.775-10.559-4.352-15.176-12.461-43.419-32.746-62.824-14.183-13.568-22.916-19.17-27.741-21.487-5.242-2.508-7.134-1.798-7.153-1.789l-.19-.435c.303-.132 7.882-2.961 35.415 23.37 20.38 19.491 28.507 47.818 32.869 63.033 1.561 5.421 2.678 9.347 3.662 10.369l-.341.322z"
        fill="#FEFADA"
      />
      <Path
        d="M142.708 98.144c-.36 0-.757-.312-1.05-.577-4.608-4.125-23.134-3.737-23.323-3.737l-.009-.473c.766-.02 18.904-.388 23.644 3.85.492.436.728.483.785.445.984-.671-2.763-10.909-4.57-15.829-1.078-2.952-1.693-4.645-1.75-5.1l.473-.056c.047.425.823 2.545 1.722 4.995 3.69 10.058 5.478 15.65 4.39 16.388a.57.57 0 01-.312.094zM160.032 118.714c-.312 0-.681-.133-1.117-.398-5.752-3.482-21.231-1.296-21.392-1.268l-.066-.473c.643-.094 15.81-2.233 21.704 1.334.54.322.928.407 1.145.246 1.079-.785-.246-6.367-1.637-12.281-1.58-6.68-3.368-14.249-3.018-19.367l.473.028c-.35 5.043 1.429 12.574 3 19.226 1.778 7.541 2.725 11.846 1.457 12.773a.853.853 0 01-.549.18zM172.578 143.228c-.435 0-.785-.52-1.022-.927-3.444-6.036-23.815-10.691-24.023-10.739l.104-.463c.842.189 20.778 4.749 24.326 10.956.407.71.615.691.634.681.899-.208 1.258-6.67 1.608-12.915.473-8.335 1.05-18.724 3.085-24.41l.444.161c-2.005 5.62-2.592 15.961-3.056 24.278-.482 8.581-.785 13.066-1.977 13.35a.29.29 0 01-.123.028zM179.371 166.295c-2.261 0-6.869-2.828-12.11-6.045-4.854-2.981-9.878-6.056-13.247-6.983l.123-.454c3.435.937 8.478 4.03 13.37 7.03 5.355 3.283 10.899 6.689 12.451 5.856.265-.141.397-.416.416-.851.199-5.488 8.629-24.931 8.989-25.754l.435.189c-.085.199-8.752 20.181-8.951 25.584-.019.605-.246 1.031-.662 1.249-.227.132-.492.179-.814.179z"
        fill="#FEFADA"
      />
      <Path
        d="M197.5 218.883s25.054-10.654 23.625-41.281c-1.429-30.627 18.951-64.14 20.03-75.948 1.079-11.807-35.888-1.835-49.9 23.304-14.013 25.139-13.738 77.452 6.245 93.925z"
        fill="#CA9661"
      />
      <Path
        d="M200.13 215.249l-.426-.198c.596-1.287.379-5.356.095-10.995-.814-15.81-2.337-45.245 10.587-70.299 17.485-33.881 25.546-33.645 25.887-33.616l-.038.473c-.066.009-8.175-.076-25.433 33.361-12.867 24.941-11.353 54.29-10.54 70.053.303 5.913.511 9.83-.132 11.221z"
        fill="#FEFADA"
      />
      <Path
        d="M213.594 128.128c-.048 0-.095-.01-.142-.019-1.268-.341-1.391-6.207-1.174-16.917.057-2.612.095-4.864.01-5.28l.463-.104c.095.454.067 2.252.01 5.393-.104 5.242-.322 16.141.833 16.454.066.009.274-.104.596-.672 3.103-5.554 20.37-11.098 21.108-11.335l.142.454c-.18.057-17.825 5.715-20.834 11.117-.256.398-.596.909-1.012.909zM203.754 153.239a.863.863 0 01-.265-.038c-1.505-.464-2.006-4.845-2.773-12.556-.671-6.793-1.438-14.504-3.406-19.15l.436-.18c1.996 4.722 2.762 12.461 3.443 19.292.606 6.046 1.174 11.761 2.442 12.149.264.076.605-.123 1.002-.605 4.419-5.299 19.453-8.204 20.087-8.317l.085.463c-.151.029-15.507 2.99-19.812 8.156-.435.521-.842.786-1.239.786zM199.997 180.469c-1.211 0-2.952-4.144-6.159-11.988-3.141-7.711-7.058-17.296-10.786-21.96l.369-.293c3.775 4.721 7.701 14.343 10.852 22.073 2.347 5.743 4.769 11.685 5.705 11.685h.019c.019 0 .227-.047.379-.851 1.334-7.03 18.705-17.816 19.443-18.28l.246.407c-.18.114-17.929 11.136-19.226 17.958-.094.501-.283 1.183-.794 1.239-.01.01-.029.01-.048.01zM200.641 204.586c-.067 0-.142-.009-.208-.019-.464-.075-.814-.397-1.032-.965-1.939-5.043-16.633-21.118-16.784-21.279l.35-.321c.605.662 14.902 16.302 16.87 21.43.151.407.368.624.662.672 1.722.274 5.866-4.731 9.868-9.585 3.643-4.418 7.418-8.998 10.351-10.994l.265.388c-2.886 1.958-6.633 6.509-10.256 10.909-4.286 5.223-8.033 9.764-10.086 9.764z"
        fill="#FEFADA"
      />
      <Path
        d="M125.753 311.568s-21.762-16.359-47.563.208c-25.802 16.567-65.02 15.687-75.777 20.664-10.758 4.976 16.368 31.989 45.14 31.544 28.773-.454 73.933-26.87 78.2-52.416z"
        fill="#CA9661"
      />
      <Path
        d="M33.967 343.311c-27.25 0-30.287-5.62-30.419-5.885l.426-.208c.038.075 4.059 7.124 41.612 5.326 28.034-1.334 52.681-17.333 65.927-25.934 4.968-3.226 8.251-5.355 9.774-5.497l.047.473c-1.409.133-4.825 2.347-9.565 5.422-13.275 8.619-37.998 24.666-66.165 26.01a254.53 254.53 0 01-11.637.293z"
        fill="#FEFADA"
      />
      <Path
        d="M20.54 355.308l-.32-.35c.34-.312 1.91-1.183 4.664-2.706 4.589-2.526 14.135-7.796 13.833-8.951-.02-.066-.218-.179-.88-.179h-.076c-6.396 0-19.737-12.092-20.305-12.603l.322-.35c.142.123 13.767 12.48 19.983 12.48h.16c.502 0 1.136.066 1.26.529.34 1.268-4.684 4.315-14.06 9.49-2.29 1.268-4.268 2.356-4.58 2.64zM41.526 360.474l-.378-.284c3.084-4.087 9.405-8.629 14.978-12.64 4.93-3.548 9.594-6.898 9.29-8.194-.065-.274-.406-.464-1.021-.568-6.793-1.173-16.832-12.735-17.258-13.227l.36-.312c.104.123 10.35 11.931 16.983 13.076.833.142 1.296.444 1.41.927.35 1.533-3.189 4.163-9.48 8.686-5.554 3.983-11.846 8.505-14.883 12.536zM70.327 360.275l-.444-.17c2.195-5.63 8.562-13.842 13.681-20.446 3.832-4.949 7.796-10.058 7.257-10.805-.01-.019-.151-.171-.927.094-6.765 2.366-24.79-7.276-25.556-7.682l.227-.417c.19.095 18.611 9.944 25.168 7.655.483-.171 1.164-.35 1.466.075.71.984-2.005 4.58-7.266 11.373-5.09 6.576-11.43 14.769-13.606 20.323zM101.531 342.743l-.454-.142c.275-.861 6.661-21.061 10.115-25.338.274-.34.35-.634.246-.908-.625-1.646-7.04-2.715-13.237-3.747-5.648-.946-11.496-1.92-14.703-3.463l.208-.425c3.141 1.513 8.95 2.479 14.57 3.425 6.917 1.154 12.878 2.147 13.597 4.04.17.444.057.908-.322 1.381-3.387 4.192-9.953 24.969-10.02 25.177z"
        fill="#FEFADA"
      />
      <Path
        d="M262.33 328.239s-23.247-14.155-47.317 4.853c-24.07 19.009-63.175 21.961-73.403 27.959-10.228 5.999 19.415 30.239 48.008 26.984 28.593-3.254 70.961-33.957 72.712-59.796z"
        fill="#6E6C7B"
      />
      <Path
        d="M160.486 369.538c-14.817 0-17.125-3.425-17.248-3.633l.407-.246-.199.123.199-.123c.047.066 4.74 6.68 41.933 1.239 27.77-4.068 50.742-22.395 63.08-32.244 4.627-3.7 7.692-6.141 9.196-6.425l.086.464c-1.391.265-4.58 2.81-8.989 6.33-12.375 9.877-35.405 28.261-63.307 32.339-10.956 1.608-19.103 2.176-25.158 2.176z"
        fill="#FEFADA"
      />
      <Path
        d="M161.886 382.046l-.35-.312c.313-.341 1.789-1.362 4.381-3.151 4.324-2.97 13.312-9.139 12.896-10.256-.028-.066-.237-.161-.899-.094-6.32.719-20.891-10.096-21.506-10.55l.284-.378c.151.113 15.044 11.136 21.175 10.464.511-.057 1.211-.076 1.391.397.463 1.23-4.239 4.75-13.067 10.815-2.157 1.476-4.011 2.753-4.305 3.065zM183.279 385.14l-.407-.246c2.668-4.371 8.515-9.509 13.672-14.041 4.56-4.011 8.875-7.796 8.449-9.064-.085-.265-.454-.416-1.079-.463-6.878-.502-17.995-11.033-18.468-11.487l.321-.34c.114.104 11.468 10.862 18.176 11.354.842.066 1.334.321 1.495.785.501 1.485-2.773 4.456-8.591 9.565-5.119 4.514-10.938 9.623-13.568 13.937zM211.928 382.131l-.454-.123c1.637-5.809 7.172-14.618 11.619-21.685 3.33-5.299 6.774-10.768 6.169-11.458-.019-.019-.171-.161-.918.189-6.491 3.018-25.376-4.816-26.18-5.156l.18-.436c.189.076 19.49 8.08 25.801 5.166.464-.217 1.126-.463 1.467-.075.804.917-1.542 4.749-6.122 12.025-4.428 7.04-9.944 15.81-11.562 21.553zM241.268 361.638l-.463-.095c.189-.88 4.57-21.61 7.588-26.199.236-.359.293-.662.161-.936-.786-1.571-7.267-2.016-13.54-2.441-5.714-.388-11.628-.786-14.968-2.006l.161-.445c3.274 1.202 9.149 1.599 14.836 1.977 6.992.474 13.028.88 13.937 2.697.208.426.151.899-.19 1.4-2.971 4.513-7.474 25.83-7.522 26.048z"
        fill="#FEFADA"
      />
      <Path
        d="M316.478 328.239s23.247-14.155 47.317 4.853c24.07 19.009 63.174 21.961 73.402 27.959 10.228 5.999-19.415 30.239-48.007 26.984-28.593-3.254-70.962-33.957-72.712-59.796z"
        fill="#3E2D4A"
      />
      <Path
        d="M418.322 369.538c-6.056 0-14.202-.577-25.149-2.176-27.902-4.087-50.931-22.471-63.307-32.339-4.409-3.52-7.598-6.065-8.988-6.33l.085-.464c1.504.284 4.56 2.725 9.196 6.425 12.338 9.849 35.311 28.176 63.08 32.244 37.193 5.45 41.886-1.173 41.934-1.239l.406.246c-.132.208-2.441 3.633-17.257 3.633z"
        fill="#FEFADA"
      />
      <Path
        d="M416.912 382.046c-.284-.312-2.138-1.589-4.296-3.075-8.827-6.064-13.53-9.584-13.066-10.814.18-.473.88-.454 1.391-.398 6.14.672 21.023-10.35 21.175-10.464l.284.378c-.615.464-15.196 11.279-21.506 10.55-.644-.076-.871.028-.899.095-.417 1.116 8.572 7.295 12.896 10.256 2.592 1.779 4.068 2.801 4.38 3.141l-.359.331zM395.529 385.14c-2.64-4.305-8.449-9.423-13.587-13.927-5.819-5.119-9.083-8.08-8.591-9.566.161-.473.644-.728 1.495-.785 6.708-.492 18.062-11.24 18.176-11.354l.331.341c-.473.444-11.591 10.975-18.469 11.486-.625.047-.984.199-1.079.464-.425 1.258 3.889 5.052 8.449 9.064 5.157 4.532 11.004 9.669 13.672 14.041l-.397.236zM366.879 382.131c-1.617-5.743-7.134-14.513-11.571-21.562-4.579-7.276-6.926-11.108-6.122-12.026.341-.388 1.003-.142 1.467.076 6.311 2.923 25.603-5.081 25.802-5.166l.179.435c-.804.331-19.68 8.165-26.18 5.157-.747-.351-.899-.209-.917-.19-.606.691 2.838 6.169 6.168 11.458 4.447 7.068 9.982 15.867 11.619 21.686l-.445.132zM337.539 361.638c-.047-.218-4.551-21.525-7.522-26.038-.331-.511-.397-.975-.189-1.4.899-1.817 6.935-2.224 13.928-2.697 5.686-.388 11.561-.785 14.835-1.977l.161.444c-3.34 1.221-9.253 1.628-14.968 2.006-6.273.426-12.764.861-13.54 2.441-.132.265-.085.568.161.937 3.019 4.589 7.409 25.319 7.588 26.199l-.454.085z"
        fill="#FEFADA"
      />
      <Path
        d="M300.791 153.182s26.7 5.289 42.756-20.834c16.057-26.124 51.764-42.36 59.296-51.509 7.531-9.159-28.631-21.705-54.366-8.818-25.735 12.887-54.934 56.296-47.686 81.161z"
        fill="#6A8185"
      />
      <Path
        d="M305.011 151.659l-.246-.407c1.211-.729 3.33-4.211 6.263-9.036 8.222-13.53 23.521-38.717 48.311-52.152 33.503-18.156 40.05-13.435 40.315-13.227l-.293.369c-.019-.01-1.656-1.202-7.371-.16-5.27.964-15.166 4.067-32.424 13.425-24.676 13.379-39.937 38.489-48.131 51.981-3.075 5.072-5.109 8.412-6.424 9.207z"
        fill="#FEFADA"
      />
      <Path
        d="M389.975 89.544c-.18-.057-17.948-5.299-23.483-2.536-.464.227-1.107.492-1.439.104-.851-.993 2.347-5.913 8.544-14.646 1.514-2.13 2.82-3.965 2.981-4.362l.435.18c-.171.425-1.211 1.901-3.028 4.465-3.037 4.268-9.348 13.162-8.572 14.07.047.047.284.075.87-.218 5.687-2.848 23.086 2.29 23.824 2.508l-.132.435zM365.347 106.887c-.142-.067-14.505-6.254-20.976-4.409-.814.236-1.363.16-1.675-.227-.984-1.23 1.069-5.138 4.768-11.94 3.265-6 6.964-12.802 7.958-17.75l.463.094c-1.003 5.024-4.721 11.855-8.004 17.892-2.905 5.336-5.649 10.379-4.816 11.42.17.217.568.236 1.173.066 6.623-1.892 20.692 4.163 21.288 4.428l-.179.426zM324.795 122.933a.564.564 0 01-.275-.066c-1.069-.586-.189-4.996 1.609-13.397 1.741-8.137 3.898-18.27 3.444-24.222l.473-.038c.463 6.018-1.703 16.189-3.454 24.354-1.305 6.122-2.658 12.442-1.845 12.887.019.009.209.094.795-.492 5.062-5.062 25.489-4.211 26.35-4.173l-.018.473c-.218-.009-21.081-.88-26.001 4.031-.293.293-.7.643-1.078.643zM313.195 143.512c-.89 0-1.542-.151-1.921-.511-.34-.321-.445-.785-.312-1.381 1.239-5.261-1.864-26.814-1.892-27.032l.463-.066c.133.889 3.151 21.866 1.893 27.211-.095.417-.038.729.17.928 1.277 1.201 7.512-.615 13.549-2.375 5.497-1.609 11.193-3.264 14.741-3.264v.473c-3.492 0-9.14 1.646-14.618 3.245-4.996 1.457-9.49 2.772-12.073 2.772z"
        fill="#FEFADA"
      />
      <Path
        d="M96.716 261.138s-27.221.416-38.329-28.167c-11.108-28.583-43.334-50.95-49.096-61.301-5.771-10.361 32.065-16.227 55.066 1.069 23.001 17.295 43.949 65.237 32.359 88.399z"
        fill="#6A8185"
      />
      <Path
        d="M92.836 258.876c-1.154-1.012-2.554-4.664-4.664-10.199-5.649-14.741-16.151-42.189-38.035-59.769-15.3-12.29-24.487-17.125-29.492-19.017-5.44-2.053-7.266-1.173-7.276-1.164l-.217-.416c.293-.161 7.588-3.633 37.287 20.238 21.98 17.665 32.52 45.188 38.178 59.967 2.015 5.27 3.472 9.074 4.541 10.01l-.322.35z"
        fill="#FEFADA"
      />
      <Path
        d="M44.98 184.745c-.35 0-.747-.255-1.04-.482-4.95-3.709-23.37-1.732-23.56-1.713l-.047-.473c.766-.085 18.8-2.015 23.89 1.807.53.398.767.417.814.379.927-.748-3.69-10.625-5.914-15.375-1.334-2.848-2.09-4.485-2.185-4.93l.463-.094c.085.416 1.041 2.46 2.148 4.825 4.532 9.698 6.803 15.12 5.781 15.943a.553.553 0 01-.35.113zM41.508 204.019l-.104-.464c.633-.151 15.554-3.586 21.742-.539.558.274.956.321 1.164.142 1.003-.871-.795-6.321-2.687-12.092-2.148-6.519-4.58-13.909-4.674-19.037l.473-.009c.085 5.052 2.507 12.404 4.646 18.894 2.422 7.361 3.737 11.572 2.554 12.603-.369.322-.927.303-1.684-.076-6.046-2.971-21.279.54-21.43.578zM78.625 227.086c-.416 0-.795-.464-1.05-.833-3.955-5.715-24.647-8.6-24.856-8.629l.067-.473c.86.114 21.108 2.943 25.177 8.828.464.671.672.634.69.624.88-.284.682-6.755.492-13.009-.255-8.346-.567-18.744.975-24.581l.454.123c-1.514 5.771-1.201 16.131-.955 24.448.255 8.591.34 13.085-.823 13.473a.457.457 0 01-.17.029zM87.169 249.5c-2.356 0-7.03-2.346-12.319-4.986-5.09-2.555-10.36-5.185-13.795-5.81l.085-.463c3.501.634 8.8 3.292 13.918 5.856 5.62 2.81 11.44 5.725 12.906 4.76.246-.161.36-.455.34-.88-.274-5.479 6.453-25.584 6.737-26.436l.445.152c-.067.208-6.983 20.862-6.709 26.255.029.606-.151 1.041-.548 1.306-.265.161-.625.246-1.06.246z"
        fill="#FEFADA"
      />
      <Path
        d="M323.498 303.62s26.701 5.289 42.757-20.834c16.056-26.124 51.764-42.36 59.295-51.509 7.532-9.159-28.63-21.705-54.366-8.818-25.735 12.887-54.933 56.296-47.686 81.161z"
        fill="#3E2D4A"
      />
      <Path
        d="M327.718 302.096l-.246-.406c1.211-.729 3.331-4.211 6.264-9.036 8.222-13.53 23.521-38.717 48.31-52.152 33.503-18.157 40.051-13.435 40.316-13.227l-.294.369c-.018-.01-1.655-1.202-7.37-.161-5.27.965-15.167 4.068-32.425 13.426-24.675 13.378-39.937 38.489-48.13 51.981-3.075 5.072-5.11 8.412-6.425 9.206z"
        fill="#FEFADA"
      />
      <Path
        d="M412.683 239.982c-.18-.048-17.949-5.299-23.484-2.536-.463.227-1.107.492-1.438.104-.852-.993 2.346-5.923 8.553-14.656 1.514-2.129 2.82-3.964 2.971-4.362l.435.18c-.17.426-1.211 1.902-3.027 4.457-3.037 4.276-9.348 13.161-8.572 14.069.047.047.283.076.87-.218 5.696-2.848 23.086 2.29 23.824 2.508l-.132.454zM388.054 257.325c-.142-.067-14.504-6.245-20.976-4.409-.814.236-1.362.16-1.675-.228-.984-1.23 1.07-5.137 4.769-11.94 3.264-5.999 6.964-12.801 7.957-17.75l.464.095c-1.003 5.024-4.721 11.855-8.005 17.892-2.904 5.336-5.648 10.379-4.816 11.42.171.217.568.236 1.174.066 6.623-1.892 20.692 4.163 21.288 4.428l-.18.426zM347.502 273.371a.563.563 0 01-.274-.066c-1.069-.587-.189-4.996 1.608-13.397 1.741-8.137 3.898-18.271 3.444-24.222l.473-.038c.464 6.018-1.703 16.189-3.453 24.354-1.306 6.122-2.659 12.442-1.845 12.887.019.009.218.085.795-.492 5.062-5.062 25.489-4.211 26.35-4.173l-.019.473c-.217-.009-21.08-.88-26 4.031-.293.293-.7.643-1.079.643zM335.902 293.95c-.889 0-1.542-.151-1.92-.511-.341-.321-.445-.795-.312-1.381 1.239-5.261-1.864-26.814-1.893-27.032l.464-.066c.132.889 3.151 21.866 1.892 27.211-.094.417-.038.729.17.928 1.278 1.211 7.513-.615 13.549-2.375 5.497-1.609 11.193-3.264 14.741-3.264v.473c-3.491 0-9.139 1.646-14.608 3.245-4.996 1.457-9.5 2.772-12.083 2.772z"
        fill="#FEFADA"
      />
      <Path
        d="M137.362 200.489s-3.794-44.904 24.288-49.531c23.833-3.926 42.832 6.709 52.18 30.769 9.339 24.051-70.271 26.029-76.468 18.762z"
        fill="#6A8185"
      />
      <Path
        d="M78.994 126.425s8.667 16.993 16.633 15.734c7.967-1.258 11.279-12.234 24.156-7.711 12.877 4.523 24.647 31.573 37.051 35.405 12.395 3.841-8.071 32.302-8.071 32.302s-99.639 6.273-103.688-1.041c-4.05-7.314 26.918-44.176 29.936-46.456 6.973-5.27-3.018-14.344-3.018-14.344l7.001-13.889z"
        fill="#E7BD9F"
      />
      <Path
        d="M78.975 114.787s10.71 21.61-.804 25.953c-7.92 2.98-16.69-9.291-16.766-16.416-.075-7.115 11.08-12.905 17.57-9.537z"
        fill="#E7BD9F"
      />
      <Path
        d="M70.123 130.487c.387-.146.547-.668.359-1.167-.188-.499-.654-.785-1.04-.639-.386.146-.547.668-.359 1.167.189.498.654.784 1.04.639zM77.447 127.725c.387-.146.547-.669.359-1.167-.188-.499-.654-.785-1.04-.639-.386.145-.547.668-.359 1.167.189.498.654.784 1.04.639z"
        fill="#34263B"
      />
      <Path
        d="M82.079 125.971s-.133-4.324 2.195-3.293c2.327 1.031 2.166 6.349.53 6.935-1.647.596-2.915-1.788-2.725-3.642z"
        fill="#E7BD9F"
      />
      <Path
        d="M75.19 123.255s1.458-1.154 3.199.416c1.012.928-4.864.786-3.198-.416zM68.936 125.45s-1.845-.208-2.488 2.053c-.369 1.315 4.532-1.93 2.489-2.053z"
        fill="#A0444E"
      />
      <Path
        d="M65.53 132.385s-2.743-3.349-3.813-1.04c-1.069 2.308 2.536 6.206 4.163 5.573 1.628-.634 1.013-3.255-.35-4.533z"
        fill="#E7BD9F"
      />
      <Path
        d="M61.944 118.638s4.088 8.525 1.874 12.924c0-.009-12.735-3.444-1.873-12.924z"
        fill="#A0444E"
      />
      <Path
        d="M75.37 133.369c-.624 0-1.92-.492-2.573-1.343l.378-.284c.568.748 1.684 1.126 2.12 1.164-.133-.634-1.06-2.895-1.968-4.92l.435-.199c1.466 3.255 2.138 5.072 1.996 5.403l-.16.151a.738.738 0 01-.228.028z"
        fill="#C87D79"
      />
      <Path
        d="M75.352 136.142c-1.666 0-3.407-1.278-3.492-1.344l.284-.378c.028.019 2.498 1.835 4.144 1.059.909-.435 1.42-1.599 1.514-3.482l.473.019c-.104 2.072-.7 3.378-1.788 3.889-.37.17-.757.237-1.135.237zM65.445 135.46c-1.088-2.658-2.318-2.545-2.327-2.545l-.067-.463c.057-.01 1.6-.208 2.839 2.838l-.445.17zM83.857 128.497c-1.078-3.104.199-3.974.256-4.002l.255.397c-.01.009-1.012.738-.066 3.453l-.445.152z"
        fill="#C87D79"
      />
      <Path
        d="M63.08 149.88s-.227-3.908-3.387-6.642c-3.16-2.735-6.567-6.822-4.617-5.895 1.949.927 4.408 2.47 4.408 2.47s-3.529-3.416-2.98-3.889c.558-.473 5.346 1.817 5.346 1.817s-4.248-2.423-2.545-3.236c1.703-.804 13.151 9.092 11.259 12.631-2.328 4.343-2.223 4.56-7.484 2.744z"
        fill="#E7BD9F"
      />
      <Path
        d="M142.159 198.493s3.681-25.385 10.767-28.631c7.087-3.245 76.364 18.564 85.211 19.444 8.856.889 14.646 8.894 13.993 10.436-.643 1.542-11.477 2.091-15.99 2.98-4.513.89-94.567 12.594-93.981-4.229z"
        fill="#6A8185"
      />
      <Path
        d="M148.697 170.846s-3.501-10.691 2.403-15.772c20.853-17.948 77.613 20.683 87.425 29.69 6.556 6.018 20.465 12.414 19.547 13.805-.918 1.4-14.23-5.857-18.828-5.81-4.589.038-94.209-5.487-90.547-21.913z"
        fill="#6A8185"
      />
      <Path
        d="M61.017 144.666s8.61-.652 9.888-.056c1.277.596 2.724 55.028-2.98 58.122-5.706 3.094-20.182 3.699-25.168.255-4.987-3.444 14.495-60.43 18.26-58.321z"
        fill="#C87D79"
      />
      <Path
        d="M76.865 152.557s16.738 12.187 18.763-10.398c0 0 12.385-10.54 21.808-10.606 15.12-.114 19.377 22.092 33.674 23.512 5.828.577-1.94 49.682-1.94 49.682s-88.21 1.921-92.723.634c-4.513-1.287-1.731-33.153 1.297-37.515 3.027-4.362 16.5-16.16 19.121-15.309zM71.993 140.324s5.137 6.556 9.007 5.97c3.87-.587 4.239-10.389 4.239-10.389l-1.732-2.233s0 11.373-11.514 6.652z"
        fill="#C87D79"
      />
      <Path
        d="M59.002 115.951s1.627-4.362 7.853-6.973c6.226-2.612 14.722-6.633 15.205-4.741.482 1.893-1.486 6.188-1.486 6.188s4.731-3.463 4.324-.87c-.407 2.592-1.892 8.487-11.164 11.24-9.273 2.753-18.895 2.583-14.732-4.844z"
        fill="#A0444E"
      />
      <Path
        d="M76.326 113.68s3.33 11.325 6.131 10.597c0 0 1.372-5.743-.397-8.913-1.77-3.179-5.734-1.684-5.734-1.684z"
        fill="#A0444E"
      />
      <Path
        d="M67.319 198.767l-.246-.397c5.005-3.113 4.598-41.479 4.589-41.867l.472-.01c.02 1.59.407 39.02-4.815 42.274zM87.32 157.95c-6.16 0-12.347-3.037-12.413-3.065l.208-.426c.066.029 6.614 3.236 12.858 3.009 5.866-.218 8.657-10.294 9.234-14.164l.464.066c-.236 1.599-1.04 5.138-2.526 8.26-1.93 4.078-4.343 6.197-7.162 6.302a16.26 16.26 0 01-.663.018z"
        fill="#A34D5B"
      />
      <Path
        d="M89.96 166.958s.445-12.395 14.05-19.387c7.929-4.078 9.528 4.296 10.001 5.526.473 1.23-20.475 16.945-23.493 16.624-3.018-.313-30.58 2.053-31.166 1.74-.587-.312-2.536-4.92.426-7.134 2.952-2.214 24.619-4.825 30.182 2.631z"
        fill="#FEFADA"
      />
      <Path
        d="M66.921 201.379s-11.874-32.065-8.875-33.238c4.078-1.599 27.619-1.495 31.914-1.174 0 0 17.22-19.244 22.613-18.033 5.393 1.201 16.955 36.767 14.741 36.918-2.214.152-16.066 11.231-27.192 16.085 0-.009-30.154.795-33.2-.558z"
        fill="#6A8185"
      />
      <Path
        d="M118.818 187.83s-6.245-3.463-10.418-3.662c-4.172-.198-9.461.398-7.408 1.069 2.053.672 4.892 1.259 4.892 1.259s-4.92.038-4.845.757c.076.719 5.129 2.375 5.129 2.375s-4.75-1.174-4.069.586c.672 1.76 9.481 2.233 12.896 5.772 3.397 3.548 8.885-5.829 3.823-8.156z"
        fill="#E7BD9F"
      />
      <Path
        d="M115.468 153.068c-6.528-7.361-1.722-17.39-1.674-17.494l.425.208c-.047.095-4.712 9.85 1.609 16.965l-.36.321z"
        fill="#A34D5B"
      />
      <Path
        d="M110.7 180.403s-3.776 12.177 0 16.377c3.775 4.201 35.49-6.443 38.858-15.81 3.359-9.367-1.722-17.674-7.399-17.627-5.686.048-14.675 12.177-31.459 17.06z"
        fill="#C87D79"
      />
      <Path
        d="M120.691 199.43c-1.807 0-3.633-.199-5.45-.644l.114-.463c10.426 2.516 21.108-2.99 27.627-8.979 4.58-4.211 7.257-8.724 6.822-11.496-1.079-6.964-10.379-25.319-10.474-25.508l.426-.218c.388.757 9.433 18.611 10.521 25.65.454 2.933-2.28 7.607-6.973 11.922-5.478 5.043-13.871 9.736-22.613 9.736z"
        fill="#A34D5B"
      />
      <Path
        d="M124.958 173.78l-.34-.332c.879-.908 9.225-7.569 12.489-8.808l.17.444c-3.217 1.221-11.448 7.797-12.319 8.696z"
        fill="#A34D5B"
      />
      <Path
        d="M63.118 166.059l-.085-.464c.719-.132 17.588-3.198 24.391.019l-.198.426c-6.67-3.151-23.928-.009-24.108.019zM96.261 168.074s16.899-9.906 17.76-11.562c.302-.577 3.425 9.31 3.425 9.31s-15.952 8.875-16.908 9.008c-.965.132-3.804-4.892-4.277-6.756z"
        fill="#FEFADA"
      />
      <Path
        d="M117.228 196.894c-3.179-6.32-2.204-17.608-2.167-18.09l.474.037c-.01.114-1.003 11.638 2.119 17.835l-.426.218zM71.378 152.122c-3.378-1.296-13.095-.927-13.2-.927l-.018-.473c.407-.019 9.916-.379 13.388.955l-.17.445z"
        fill="#A34D5B"
      />
      <Path
        d="M59.683 139.926l-.416-.227c.028-.047.662-1.192 2.46-2.166l.227.416c-1.656.899-2.261 1.968-2.27 1.977z"
        fill="#C87D79"
      />
      <Path
        d="M200.026 160.666c-9.632-4.598-26.199-7.002-37.922-2.394l-.17-.445c11.846-4.655 28.573-2.242 38.3 2.404l-.208.435z"
        fill="#515562"
      />
      <Path
        d="M235.951 182.569s-2.45 11.657.369 12.565c2.829.908 16.643-2.971 20.437-1.041 3.794 1.931 6.491 5.261 6.093.521-.407-4.74-5.327-9.112-10.199-10.474-4.873-1.363-16.7-1.571-16.7-1.571z"
        fill="#44324E"
      />
      <Path
        d="M235.97 194.32s-1.022 6.936 1.798 7.844c2.819.908 13.113-1.93 16.898 0 3.794 1.93 10.568 5.421 10.162.681-.407-4.74-5.327-9.111-10.2-10.474-4.854-1.362-18.658 1.949-18.658 1.949z"
        fill="#44324E"
      />
      <Path
        d="M157.288 158.982s-11.59 32.519-10.956 45.765c.246 5.128 20.673 3.378 27.41 3.037 6.736-.331-13.956-43.324-16.454-48.802z"
        fill="#6A8185"
      />
      <Path
        d="M151.233 182.759c-1.419-1.174-.218-6.444.842-11.089.426-1.883.832-3.671.955-4.769.407-3.69 3.927-7.891 4.078-8.061l.36.303c-.038.037-3.577 4.267-3.965 7.815-.123 1.126-.51 2.838-.965 4.825-.927 4.069-2.195 9.632-1.002 10.616l-.303.36z"
        fill="#515562"
      />
      <Path
        d="M61.604 120.691l-.237-.407c.237-.132 5.914-3.34 8.478-3.34 5.469 0 8.6-1.92 9.31-5.724l.464.085c-.757 4.05-4.05 6.103-9.774 6.103-2.432 0-8.184 3.245-8.241 3.283zM60.109 119.026l-.407-.237c.123-.217 3.17-5.298 7.314-6.415 1.656-.444 2.98-.312 4.04-.208 1.637.161 2.63.265 3.7-1.656l.416.228c-1.22 2.185-2.517 2.053-4.163 1.892-1.079-.104-2.3-.227-3.87.189-3.955 1.069-6.992 6.159-7.03 6.207zM61.736 129.623c-.066-.208-1.599-5.157-.775-6.879l.425.199c-.738 1.552.786 6.481.804 6.528l-.454.152zM81.596 122.262c-.53-2.631-1.722-3.634-1.731-3.653l.302-.369c.057.048 1.334 1.107 1.893 3.927l-.464.095z"
        fill="#863A42"
      />
      <Path
        d="M244.381 194.037c-.179-.625-1.514-2.981-2.195-4.145l.407-.236c.085.142 2.006 3.416 2.242 4.258l-.454.123zM248.573 194.235l-.464-.066c.218-1.533-1.58-4.485-1.599-4.513l.407-.246c.076.132 1.902 3.132 1.656 4.825zM245.545 202.552c-.18-.624-1.514-2.98-2.195-4.144l.407-.237c.085.142 2.006 3.416 2.242 4.258l-.454.123zM249.746 202.76l-.464-.066c.218-1.533-1.58-4.485-1.599-4.513l.407-.246c.066.123 1.892 3.122 1.656 4.825z"
        fill="#FEFADA"
      />
      <Path
        d="M235.97 194.557c-.426-.009-42.974-.993-62.266-3.993-16.615-2.583-20.446-6.121-20.607-6.263l.331-.341c.038.038 3.936 3.586 20.352 6.141 19.263 2.999 61.774 3.973 62.199 3.983l-.009.473z"
        fill="#515562"
      />
      <Path
        d="M89.392 167.052c-8.912-3.33-27.693-1.116-27.883-1.088l-.056-.473c.189-.019 19.083-2.252 28.1 1.117l-.16.444zM91.985 163.438l-.445-.161c3.075-8.269 13.956-14.258 14.069-14.315l.227.416c-.113.057-10.842 5.961-13.851 14.06z"
        fill="#D3D0B1"
      />
      <Path
        d="M100.112 202.164c-.274 0-.653-.284-6.519-16.718-2.923-8.194-5.81-16.492-5.838-16.568l.445-.151c4.466 12.858 11.354 32.254 12.054 33.021l-.132.123.009.303c-.009-.01-.019-.01-.019-.01zM105.023 200.575c-1.401-3.974-12.423-34.223-12.537-34.535l.445-.161c.114.303 11.136 30.561 12.536 34.535l-.444.161z"
        fill="#515562"
      />
      <Path
        d="M37.155 262.623s162.36 1.59 169.182 0c6.821-1.589 22.972-2.507 24.921.729 1.949 3.235 8.714 55.841-1.031 59.02-9.745 3.18-195.995 1.06-199.893 1.06-3.898 0-1.467-53.93.492-56.57 1.958-2.64-.01-3.189 6.33-4.239z"
        fill="#68818D"
      />
      <Path
        d="M79.458 277.317s-.388 25.357 2.327 29.094c2.716 3.737 116.812 3.321 120.691 2.498 3.879-.833 1.552-32.425 1.552-32.425s-124.958-3.321-124.57.833zM43.987 262.5l-.947 61.945s8.043.492 9.462.492c1.42 0 .946-63.411.946-63.411l-9.461.974zM61.49 262.472l-.946 60.08s8.043.473 9.462.473c1.419 0 .946-61.499.946-61.499l-9.462.946z"
        fill="#493558"
      />
      <Path
        d="M37.155 203.914s162.18.067 169.182 0c11.741-.104 23.985 1.486 25.934 4.627 1.949 3.141 7.701 50.25-2.044 53.325-9.745 3.075-195.995 1.022-199.893 1.022-3.898 0-1.467-52.303.492-54.867 1.958-2.564-.01-3.085 6.33-4.107z"
        fill="#493558"
      />
      <Path
        d="M29.321 214.691s172.2.473 174.092 3.785c1.892 3.311 4.258 33.588-4.258 36.427-8.515 2.838-167.468 3.784-171.726.946-4.258-2.839-4.258-40.212 1.892-41.158z"
        fill="#FEFADA"
      />
      <Path
        d="M144.884 225.354c-9.821 0-19.282-.34-27.183-.624-6.727-.237-12.035-.435-15.517-.35-19.178.463-72.323.946-72.853.946v-.473c.539 0 53.675-.483 72.853-.946 3.501-.086 8.818.104 15.546.35 9.745.35 21.865.785 34.108.558 25.802-.473 51.111-.908 51.111-.908l.01.473s-25.31.435-51.111.908c-2.337.047-4.665.066-6.964.066zM91.464 235.118c-23.597 0-63.969-.785-64.508-.794l.01-.473c.728.019 72.456 1.409 80.394.473 3.851-.454 24.278-1.211 45.897-2.025 22.973-.861 46.721-1.741 49.172-2.233l.095.464c-2.489.501-26.256 1.381-49.248 2.242-21.61.804-42.018 1.571-45.86 2.015-2.015.237-8.042.331-15.952.331zM73.289 242.309c-22.405 0-46.447-.407-46.816-.416l.01-.473c.54.009 54.65.937 76.146 0 18.601-.804 99.299-3.813 100.85-3.302l-.151.444c-1.694-.435-76.364 2.271-100.68 3.321-7.2.322-18.072.426-29.36.426zM73.071 250.238c-21.808 0-43.371-.303-43.75-.303l.01-.473c.672.01 66.902.927 84.661-.473 6.746-.53 20.011-.738 34.052-.946 23.578-.36 50.298-.766 55.284-2.82l.18.436c-5.072 2.091-31.838 2.497-55.454 2.857-14.041.218-27.297.416-34.024.946-7.683.596-24.392.776-40.959.776z"
        fill="#D3D0B1"
      />
      <Path
        d="M75.683 214.218s5.676 57.715 5.676 61.027c0 3.311 6.624-15.139 6.624-15.139s5.695 15.224 7.446 14.505c1.75-.719 4.854-58.028 2.015-59.92-2.838-1.892-21.761-.473-21.761-.473z"
        fill="#B94D59"
      />
      <Path
        d="M400.042 132.81s22.017 164.006 24.42 170.998c2.403 6.992 3.492 34.26-12.943 34.771-3.832.123-35.973 13.521-40.524-11.316-1.949-10.616-28.801-190.829-29.274-194.926-.473-4.097 48.443-9.83 51.301-8.08 2.857 1.741 5.203 2.015 7.02 8.553z"
        fill="#CA9661"
      />
      <Path
        d="M364.997 292.607s51.12-10.096 54.867-8.497c3.747 1.609 5.62 24.553 6.642 33.683 1.022 9.131-4.825 20.891-20.21 23.058-15.394 2.167-34.345 4.787-36.748-16.652-1.798-16.028-4.551-31.592-4.551-31.592z"
        fill="#C37E59"
      />
      <Path
        d="M350.861 131.241s27.145 194.453 31.412 197.802c4.267 3.349 31.961-1.457 32.453-5.469 1.003-8.165-25.849-195.02-28.857-198.313-3.009-3.302-37.241-.435-35.008 5.98z"
        fill="#FEFADA"
      />
      <Path
        d="M390.013 331.096c-4.655-5.961-34.175-195.276-35.434-203.328l.464-.075c.303 1.968 30.759 197.244 35.339 203.11l-.369.293zM401.745 325.75c-.236-1.797-23.474-180.364-27.429-200.896l.464-.085c3.955 20.55 27.202 199.127 27.429 200.925l-.464.056zM409.504 328.541l-.474-.018c.483-10.55-23.161-201.304-23.398-203.234l.473-.056c.237 1.92 23.881 192.721 23.399 203.308zM398.519 328.125c-.35-1.854-34.563-185.843-33.749-203.753l.473.018c-.814 17.854 33.389 201.796 33.739 203.65l-.463.085z"
        fill="#D3D0B1"
      />
      <Path
        d="M347.956 138.961s1.003 165.473 2.498 172.711c1.495 7.238-.889 34.421-17.257 32.84-3.823-.369-37.402 8.838-38.764-16.368-.577-10.777-4.324-192.939-4.277-197.064.057-4.125 49.295-3.596 51.915-1.495 2.621 2.091 4.911 2.659 5.885 9.376z"
        fill="#6E6C7B"
      />
      <Path
        d="M292.9 293.013s51.991-3.519 55.511-1.457c3.519 2.072 1.154 23.948 1.003 33.135-.152 9.187-6.132 21.222-21.677 21.42-15.545.199-34.459.284-32.642-21.212 1.362-16.274-2.195-31.886-2.195-31.886z"
        fill="#57495B"
      />
      <Path
        d="M299.372 131.155s2.223 196.327 6.027 200.187c3.803 3.86 31.885 2.611 32.878-1.306 2.035-7.966-.87-196.723-3.434-200.375-2.555-3.643-36.872-5.157-35.471 1.494z"
        fill="#FEFADA"
      />
      <Path
        d="M312.816 334.37c-3.86-6.5-9.092-198.03-9.31-206.185l.473-.01c.057 1.996 5.45 199.553 9.244 205.958l-.407.237zM325.126 330.557c0-1.817-.369-181.879-1.684-202.76l.473-.029c1.315 20.891 1.684 200.972 1.684 202.789h-.473zM332.477 334.303l-.463-.085c1.816-10.398 2.602-202.618 2.602-204.557h.473c-.01 1.949-.795 194.197-2.612 204.642zM321.635 332.496c-.105-1.882-10.673-188.728-7.589-206.393l.464.085c-3.075 17.608 7.494 204.397 7.598 206.289l-.473.019z"
        fill="#D3D0B1"
      />
      <Path
        d="M291.593 134.798s1.439 171.755 3.255 178.946c1.807 7.19 3.104 24.259-.293 26.369-3.387 2.119-58.993 10.18-62.541-.076-3.548-10.256-4.891-207.283-4.967-211.417-.076-4.125 57.1-2.47 59.939-.454 2.848 2.015 3.368-.057 4.607 6.632z"
        fill="#493558"
      />
      <Path
        d="M226.934 141.364h62.153s.672 8.327 0 8.327-59.144.69-61.15.227c-2.006-.464-1.003-8.554-1.003-8.554zM226.934 156.2h62.153s.672 8.326 0 8.326-59.144.691-61.15.227c-2.006-.463-1.003-8.553-1.003-8.553zM244.987 180.847h31.431s.975 114.731.634 114.731c-.341 0-30.542 3.567-31.554-2.498-1.013-6.065-.511-112.233-.511-112.233z"
        fill="#B94D59"
      />
      <Path
        d="M210.301 72.078s4.683 9.253 16.397 17.059c11.713 7.806 25.442-8.525 25.442-8.525s-36.843-12.11-41.839-8.534z"
        fill="#E7BD9F"
      />
      <Path
        d="M290.885 26.067s-2.933 11.013-1.76 14.76c1.164 3.746 14.958 4.759 19.481 10.275 4.513 5.516-8.25 55.52-8.336 58.992-.085 3.472-48.811-2.829-54.829-5.743-6.018-2.905 10.351-53.43 16.16-57.942 5.81-4.513 11.505-.303 14.325-5.583 2.82-5.28 1.608-11.05 1.608-11.05l13.351-3.71z"
        fill="#E7BD9F"
      />
      <Path
        d="M298.255 14.58s-5.336 23.522-17.002 19.623c-8.024-2.687-7.068-17.74-2.64-23.313 4.428-5.573 16.737-3.027 19.642 3.69z"
        fill="#E7BD9F"
      />
      <Path
        d="M282.482 20.499c.169-.506-.012-1.021-.403-1.152-.392-.131-.846.173-1.015.678-.169.506.012 1.022.403 1.152.392.131.846-.173 1.015-.678zM289.906 22.98c.169-.506-.012-1.022-.403-1.153-.392-.13-.846.173-1.015.679-.169.505.012 1.021.403 1.152.392.13.846-.173 1.015-.678z"
        fill="#34263B"
      />
      <Path
        d="M293.6 25.206s2.63-3.435 3.775-1.164c1.145 2.27-2.327 6.292-3.974 5.714-1.636-.577-1.116-3.226.199-4.55z"
        fill="#E7BD9F"
      />
      <Path
        d="M289.976 18.753s1.864.019 2.214 2.346c.199 1.353-4.267-2.47-2.214-2.346zM283.741 16.5s-1.305-1.324-3.226.02c-1.117.795 4.731 1.381 3.226-.02z"
        fill="#34263B"
      />
      <Path
        d="M276.721 19.737s-.01-4.324-2.299-3.217c-2.29 1.107-1.949 6.415-.294 6.954 1.656.54 2.839-1.892 2.593-3.737z"
        fill="#E7BD9F"
      />
      <Path
        d="M282.625 6.812s-2.224 9.187-6.708 11.203c0 0-7.702-10.71 6.708-11.203zM296.344 11.392s-3.756 8.676-1.372 12.98c0 .01 12.584-3.916 1.372-12.98z"
        fill="#323044"
      />
      <Path
        d="M299.211 13.274s3.122-6.462-1.41-10.19c-4.532-3.718-14.495 1.58-15.053-2.507-.35-2.583-3.321 4.069.965 5.564 0 0-4.286-3.747-4.466-2.924-1.779 7.995 15.186 16.245 19.964 10.057z"
        fill="#323044"
      />
      <Path
        d="M287.365 10.71l-.568-.198c-3.387-1.23-4.56-2.583-4.948-3.492-.369-.86-.114-1.457-.095-1.476l.294.133-.152-.066.152.066c-.01.019-.209.51.104 1.24.369.85 1.495 2.119 4.759 3.301 2.64.956 4.693 1.174 6.188 1.325 1.939.199 3.103.322 3.34 1.95l-.322.046c-.199-1.381-1.088-1.476-3.047-1.674-1.419-.152-3.302-.35-5.705-1.155zM297.896 11.969c0-.029-.029-2.687-4.324-4.693-1.798-.842-3.227-.54-4.362-.293-.852.18-1.59.33-2.205-.067-.596-.397-1.012-1.286-1.324-2.885l.312-.067c.293 1.486.672 2.337 1.192 2.678.502.331 1.145.199 1.959.019 1.192-.256 2.668-.568 4.57.322 4.503 2.1 4.513 4.872 4.513 4.986h-.331z"
        fill="#493558"
      />
      <Path
        d="M283.93 26.833c-.463-.161-1.381-1.552-1.343-2.791l.473.019c-.028.936.596 1.94.908 2.242.294-.577 1.003-2.914 1.58-5.053l.454.123c-.927 3.454-1.551 5.28-1.854 5.45l-.218.01z"
        fill="#C87D79"
      />
      <Path
        d="M283.533 29.444a2.13 2.13 0 01-.549-.066c-2.034-.502-2.857-3.643-2.885-3.775l.454-.114c.009.029.785 3 2.545 3.435.984.246 2.119-.34 3.378-1.741l.35.312c-1.174 1.296-2.271 1.95-3.293 1.95zM275.16 22.206l-.455-.142c.843-2.753-.189-3.444-.198-3.453l.246-.407c.057.047 1.362.86.407 4.002zM293.837 28.46l-.445-.16c1.126-3.095 2.659-2.953 2.725-2.943l-.057.473.028-.237-.018.237s-1.249-.085-2.233 2.63z"
        fill="#C87D79"
      />
      <Path
        d="M310.763 185.323s-4.863 13.7-2.422 18.989c2.451 5.289 8.44 7.957 11.751 7.408 3.312-.549-4.182-8.175-4.891-13.17-.71-4.996 2.204-13.237 2.204-13.237h-6.642v.01z"
        fill="#44324E"
      />
      <Path
        d="M298.179 186.392s-4.863 13.7-2.422 18.989c2.451 5.289 8.44 7.957 11.751 7.408 3.312-.548-4.182-8.174-4.891-13.17-.7-4.986 2.204-13.237 2.204-13.237h-6.642v.01z"
        fill="#44324E"
      />
      <Path
        d="M267.079 123.577s6.661-18.195 24.894-13.89c18.241 4.296 24.041 77.906 14.674 84.35-3.671 2.526-16.434.605-16.671-2.716-2.516-35.755-24.155-52.691-22.897-67.744z"
        fill="#CA9661"
      />
      <Path
        d="M283.845 110.52s29.747-4.248 43.041 8.95c13.303 13.199 3.964 70.12-7.409 75.134-4.286 1.893-11.808-1.078-11.808-1.078-2.819-3.813-32.774-70.829-23.824-83.006z"
        fill="#CA9661"
      />
      <Path
        d="M307.887 193.601l-.445-.16c2.129-5.848 7.938-44.602 3.331-66.269-2.413-11.354-6.075-15.327-8.714-16.662-2.451-1.239-4.372-.387-4.39-.387l-.199-.426c.085-.038 2.129-.946 4.787.378 2.744 1.372 6.519 5.441 8.979 16.993 4.618 21.752-1.22 60.667-3.349 66.533z"
        fill="#A36E4C"
      />
      <Path
        d="M280.326 127.342s-30.949 1.382-36.115-2.772c-8.865-7.153 1.221-20.2 1.221-20.2l46.541 5.327c0-.019-.549 17.475-11.647 17.645z"
        fill="#CA9661"
      />
      <Path
        d="M272.794 42.908s.104 8.383 9.764 9.263c8.44.766 7.674-9.727 13.53-7.38 5.857 2.346 10.03.766 13.474 9.783 3.444 9.017-1.628 57.763-4.419 57.488-2.791-.284-31.668-3.747-41.933 2.99-8.554 5.611-24.562 10.398-23.219 3.236 1.344-7.163 4.759-60.525 9.66-68.18 3.123-4.891 20.693-6.982 23.143-7.2z"
        fill="#B94D59"
      />
      <Path
        d="M201.275 64.452s54.101-7.087 56.192-1.77c2.091 5.318 2.327 16.89 0 17.74-2.328.852-53.685 4.334-55.454 1.713-1.77-2.63-.738-17.683-.738-17.683z"
        fill="#CA9661"
      />
      <Path
        d="M199.799 48.538l57.696-.076c1.637 5.554 2.176 15.706-.303 15.857-16.16.947-35.301 1.902-46.891 1.883-6.405-.01-10.502-.322-10.521-1.097-.095-3.217.019-16.568.019-16.568z"
        fill="#C87D79"
      />
      <Path
        d="M198.502 31.412s57.782-4.683 59.428.785c1.646 5.47 1.022 15.432-1.372 16.085-2.394.653-41.47.16-56.778.52-3.17.076-1.278-17.39-1.278-17.39z"
        fill="#493558"
      />
      <Path
        d="M194.406 67.006s-.038-5.128.416-6.301c1.514-3.889 4.617-7.72 4.352-5.582-.255 2.138-.936 4.967-.936 4.967s2.119-4.437 2.734-4.068c.624.378-.019 5.648-.019 5.648s.946-4.797 2.252-3.444c1.306 1.353-1.987 4.76-2.243 11.477-.17 4.93-5.544 3.453-6.556-2.697z"
        fill="#E7BD9F"
      />
      <Path
        d="M268.991 128.677c-.7 0-1.24-.105-1.58-.36a.96.96 0 01-.398-.747c-.17-2.697 10.067-5.904 11.24-6.264l.142.454c-3.075.937-11.032 3.813-10.899 5.781.009.17.075.293.208.398.908.681 4.229-.067 7.446-.776 2.536-.568 5.147-1.145 6.973-1.145h.076v.473h-.076c-1.769 0-4.361.577-6.869 1.135-2.498.549-4.749 1.051-6.263 1.051z"
        fill="#A36E4C"
      />
      <Path
        d="M292.616 192.92l-.473-.057c.52-3.992.984-15.781-5.989-38.867-4.286-14.221-9.632-26.294-13-29.369-.615-.558-1.136-.804-1.552-.71-5.771 1.297-21.345-2.024-22.007-2.166l.104-.464c.161.038 16.132 3.435 21.808 2.167.568-.123 1.23.151 1.968.823 3.426 3.122 8.828 15.29 13.133 29.577 2.337 7.73 7.579 26.984 6.008 39.066z"
        fill="#A36E4C"
      />
      <Path
        d="M300.999 58.964s10.039 8.109 12.953 15.791c2.914 7.683-15.508 27.25-23.389 28.215-7.891.965-50.354-22.036-50.648-24.004-.293-1.968.057-8.459 2.697-8.175 3.851.426 31.762 10.133 41.697 11.6 5.119.766 10.351-25.29 16.69-23.427z"
        fill="#E7BD9F"
      />
      <Path
        d="M285.785 73.175s22.499 4.769 26.937 10.531c2.204 2.867 5.961-9.972 5.648-20.409-.312-10.435-19.207-19.443-22.282-18.506-3.065.937-10.303 28.384-10.303 28.384z"
        fill="#B94D59"
      />
      <Path
        d="M291.708 69.173l-.473-.066c.066-.464 1.542-11.392 4.674-14.703 3.131-3.302 8.931-4.495 9.177-4.542l.095.464c-.057.01-5.895 1.21-8.932 4.409-3.018 3.198-4.522 14.325-4.541 14.438zM244.4 116.045l-.444-.16c.113-.322 3.112-7.929 22.31-8.932 10.029-.53 18.242-.047 24.836.331 5.999.35 10.332.606 13.019-.104l.123.454c-2.763.719-7.124.464-13.17.114-6.585-.388-14.779-.861-24.78-.332-18.847.994-21.865 8.544-21.894 8.629zM283.419 57.535c-5.374 0-13.615-11.079-13.965-11.552l.379-.284c.085.114 8.449 11.354 13.586 11.354h.067c3.387-.066 7.181-5.08 9.688-8.392 1.665-2.196 2.432-3.16 2.962-3.056l-.085.463c-.322.01-1.476 1.533-2.489 2.876-2.564 3.388-6.443 8.516-10.057 8.582-.038.01-.057.01-.086.01zM314.132 79.865c-.142-.095-14.448-9.708-24.099-7.74l-.095-.464c9.85-1.996 24.317 7.712 24.458 7.806l-.264.398z"
        fill="#9B434C"
      />
      <Path
        d="M277.752 31.668s2.611 3.444 5.734 3.387c3.113-.057 6.055-2.867 6.055-2.867s-.378 2.053-.388 2.886c-.019.832-4.125 4.078-8.629 4.068-1.873 0-2.961-3.425-2.867-3.898.095-.473.445-2.394.095-3.576z"
        fill="#C87D79"
      />
      <Path
        d="M196.922 35.405s51.726-2.006 53.723 0c1.996 2.006 1.996 8.591 0 9.32-1.997.728-53.108 1.03-53.723 1.116-.605.085-1.182-10.398 0-10.436zM206.091 52.398s50.363-1.798 52.312 0c1.949 1.797 1.94 7.701 0 8.354-1.939.653-51.716.928-52.312 1.003-.597.076-1.155-9.32 0-9.357z"
        fill="#FEFADA"
      />
      <Path
        d="M209.317 83.091l-.596-16.88s-2.782-.539-3.406 0c-.625.54-.615 16.634 0 16.69.615.058 2.27.19 4.002.19zM216.347 83.091l-.596-16.88s-2.782-.539-3.406 0c-.625.54-.615 16.634 0 16.69.615.058 2.27.19 4.002.19zM247.059 81.199l-.379-16.89s-2.772-.576-3.406-.046c-.634.53-.832 16.623-.217 16.68.634.076 2.28.237 4.002.256zM254.089 81.293l-.379-16.889s-2.772-.577-3.406-.047c-.634.53-.832 16.624-.217 16.68.624.067 2.27.228 4.002.256z"
        fill="#493558"
      />
      <Path
        d="M255.745 74.528s-12.404-4.144-16.52-4.853c-4.116-.71-9.443-.767-7.484.15 1.949.919 4.702 1.855 4.702 1.855s-4.882-.567-4.901.161c-.019.729 4.797 2.99 4.797 2.99s-5.573-2.138-5.119-.312c.455 1.826 10.966 6.13 14.628 8.572 4.087 2.734 14.637-5.63 9.897-8.563z"
        fill="#E7BD9F"
      />
      <Path
        d="M301.661 202.514c-1.892 0-4.021-.53-4.153-.568l.113-.454c1.353.341 5.109 1.022 6.216.048l.313.359c-.53.464-1.476.615-2.489.615zM302.551 205.003c-1.391 0-2.735-.436-2.82-.464l.152-.445c.028.01 2.999.965 4.465.029l.256.397c-.577.36-1.315.483-2.053.483zM314.936 201.559c-1.893 0-4.021-.53-4.154-.568l.114-.454c1.353.34 5.109 1.022 6.216.047l.312.36c-.53.463-1.466.615-2.488.615zM315.835 204.047c-1.391 0-2.735-.435-2.82-.464l.151-.444c.029.009 3 .965 4.466.028l.256.397c-.577.36-1.325.483-2.053.483z"
        fill="#FEFADA"
      />
      <Path
        d="M323.262 190.299c-.416-.019-10.152-.416-13.388-2.393l.246-.407c3.141 1.911 13.066 2.327 13.161 2.327l-.019.473zM302.352 187.764c-4.674 0-10.938-.133-12.735-.171l.009-.473c6.85.152 17.277.275 17.939.029l.218.416c-.275.151-2.555.199-5.431.199z"
        fill="#A36E4C"
      />
      <Path
        d="M196.165 38.622l-.018-.473c.141-.01 13.794-.634 20.039-.634 4.447 0 19.557-.16 28.593-.246 3.746-.038 6.405-.066 6.727-.066v.473c-.322 0-2.981.028-6.727.066-9.036.095-24.146.246-28.593.246-6.235.01-19.879.624-20.021.634zM215.439 43.466c-7.759 0-18.517-.265-18.659-.265l.01-.473c.189.01 19.292.473 24.893.161 5.611-.312 29.246-2.346 29.482-2.365l.038.473c-.237.019-23.881 2.053-29.491 2.365-1.41.066-3.672.104-6.273.104zM219.422 56.56c-6.5 0-14.013-.274-14.126-.274l.019-.473c.17.01 17.068.625 22.036 0 5.005-.624 31.771-.946 32.036-.946l.01.473c-.275 0-27.013.322-31.99.946-1.674.209-4.702.275-7.985.275zM206.091 59.759v-.473c.264 0 26.198-.17 30.229-.946 4.078-.786 22.452-.492 23.228-.473l-.009.473c-.19 0-19.103-.303-23.134.473-4.068.776-29.245.936-30.314.946z"
        fill="#D3D0B1"
      />
      <Path
        d="M239.367 37.288s.312 14.977.473 16.245c.161 1.259 3.15-5.516 3.15-5.516l3.473 5.838.634-16.87c-.01-.019-7.257-.331-7.73.303zM218.835 197.121s-10.833-4.598-15.252 10.692c-4.418 15.289-4.229 21.581 2.735 23.767 6.963 2.185 8.278-3.52 13.719-1.372 5.44 2.138 15.053 3.094 16.605-3.491 1.561-6.576-3.132-36.758-17.807-29.596z"
        fill="#B94D59"
      />
      <Path
        d="M226.31 206.346s-2.451 23.133 1.476 26.587c3.917 3.453 19.793-1.788 24.013 4.863 4.22 6.652 2.962 56.06 2.962 56.06H187.82s1.136-56.921 4.608-59.409c3.473-2.489 19.775 3.501 20.617-6.264.407-4.674.851-17.891.851-17.891l12.414-3.946z"
        fill="#E7BD9F"
      />
      <Path
        d="M213.404 223.717s7.049 5.592 12.158-3.008c0 0-.113 5.203.057 6.036.17.833-5.014 5.317-5.819 5.317-2.138 0-6.566-5.08-6.566-5.08l.17-3.265z"
        fill="#C87D79"
      />
      <Path
        d="M207.349 233.917s4.049 13.691 11.335 14.76c7.285 1.069 9.083-15.233 11.164-14.76 2.082.473 13.388-5.157 18.45 2.886 5.062 8.042 4.618 70.97 4.618 70.97h-65.105s1.258-59.266 2.933-66.164c1.675-6.897 12.801-8.61 16.605-7.692z"
        fill="#7F6873"
      />
      <Path
        d="M229.025 204.037s1.079 24.099-11.212 23.418c-8.449-.464-11.514-15.243-8.714-21.781 2.81-6.538 15.347-7.342 19.926-1.637z"
        fill="#E7BD9F"
      />
      <Path
        d="M214.943 215.792c.029-.532-.281-.982-.693-1.005-.413-.022-.77.391-.8.923-.029.532.282.982.694 1.005.412.022.77-.391.799-.923zM223.199 215.797c.029-.532-.281-.981-.694-1.004-.412-.023-.769.39-.799.923-.029.532.282.982.694 1.004.412.023.77-.39.799-.923z"
        fill="#44324E"
      />
      <Path
        d="M227.351 215.524s1.627-4.012 3.33-2.129c1.703 1.883-.587 6.68-2.318 6.557-1.731-.114-1.93-2.81-1.012-4.428z"
        fill="#E7BD9F"
      />
      <Path
        d="M222.147 210.263s1.797-.473 2.753 1.675c.558 1.249-4.759-1.259-2.753-1.675zM215.306 210.519s-1.608-.937-3.103.87c-.871 1.069 4.929.076 3.103-.87z"
        fill="#B94D59"
      />
      <Path
        d="M209.629 214.71s-1.154-4.172-3.065-2.488c-1.912 1.684-.18 6.708 1.551 6.784 1.741.075 2.233-2.574 1.514-4.296z"
        fill="#E7BD9F"
      />
      <Path
        d="M218.901 219.933h-.094c-.445-.019-1.637-.066-1.94-1.296l.464-.114c.18.757.823.899 1.438.927.085-.643-.085-3.415-.312-5.932l.473-.038c.359 3.983.426 6.122.189 6.368l-.218.085z"
        fill="#C87D79"
      />
      <Path
        d="M218.154 222.355c-2.072 0-3.662-2.763-3.728-2.876l.416-.237c.019.028 1.524 2.64 3.312 2.64h.047c1.013-.029 1.949-.89 2.791-2.574l.426.208c-.927 1.855-2.006 2.811-3.207 2.839h-.057zM208.768 217.511l-.473-.01c.085-2.876-1.088-3.274-1.107-3.274l.123-.454c.076.01 1.552.445 1.457 3.738zM228.439 218.599l-.473-.038c.264-3.274 1.788-3.548 1.854-3.558l.076.464c-.019 0-1.23.265-1.457 3.132z"
        fill="#C87D79"
      />
      <Path
        d="M206.422 211.067s1.22 1.59 4.144.18c7.361-3.548 8.875-11.59 8.875-11.59s.709 5.563 1.779 7.455c1.059 1.893 6.244 5.498 7.436 5.176 1.192-.322 4.362-7.238-1.835-12.064-6.198-4.825-7.768-2.942-11.42-1.457-3.652 1.486-11.931 7.276-8.979 12.3z"
        fill="#B94D59"
      />
      <Path
        d="M219.904 199.543a6.907 6.907 0 100-13.814 6.908 6.908 0 000 13.814z"
        fill="#B94D59"
      />
      <Path
        d="M218.684 248.876s-6.131-16.558-10.143-14.429c-4.012 2.129-7.276 14.192-3.983 16.321 3.283 2.129 14.126-1.892 14.126-1.892zM219.904 248.876s6.131-16.558 10.143-14.429c4.012 2.129 7.276 14.192 3.983 16.321-3.283 2.129-14.126-1.892-14.126-1.892z"
        fill="#FEFADA"
      />
      <Path
        d="M234.04 233.094s-1.769 54.735 1.353 60.762c3.113 6.027 17.873 3.434 19.547-1.306 1.675-4.74-.87-48.14-2.006-51.452-1.135-3.311-4.976-10.862-18.894-8.004zM207.538 233.094s1.77 54.735-1.353 60.762c-3.113 6.027-17.873 3.434-19.547-1.306-1.675-4.74.87-48.14 2.006-51.452 1.135-3.302 4.976-10.862 18.894-8.004z"
        fill="#CA9661"
      />
      <Path
        d="M153.617 314.926s-8.108-36.54 14.911-37.212c23.02-.672 73.214 40.987 57.091 55.974-16.132 14.978-70.876 9.774-72.002-18.762z"
        fill="#6A8185"
      />
      <Path
        d="M289.768 314.926s4.532-36.625-18.488-37.297c-23.019-.672-78.871 41.072-62.739 56.059 16.132 14.978 80.101 9.774 81.227-18.762z"
        fill="#6A8185"
      />
      <Path
        d="M58.065 340.595h166.447s-15.933-56.248-64.631-55.86c-48.699.388-69.06 45.576-105.364 20.692 0 0-9.736 23.36 3.548 35.168z"
        fill="#FEFADA"
      />
      <Path
        d="M377.779 340.595H211.332s15.933-56.248 64.632-55.86c48.698.388 69.059 45.576 105.363 20.692 0 0 9.736 23.36-3.548 35.168z"
        fill="#FEFADA"
      />
      <Path
        d="M203.697 233.633s-.01-6.245-1.259-10.237c-1.248-3.984-1.996-9.263-.823-7.447 1.164 1.817 2.451 4.419 2.451 4.419s-1.202-4.769-.483-4.873c.719-.113 3.586 4.362 3.586 4.362s-2.327-4.295-.454-4.087c1.873.208 11.108 11.685 5.904 22.225-2.186 4.409-5.393-.048-8.922-4.362z"
        fill="#DC9A8E"
      />
      <Path
        d="M203.205 230.691s8.979 1.021 11.647 5.847c2.668 4.825-20.919 50.95-31.706 51.215-10.786.265-16.387-8.241-18.081-10.133-1.693-1.893 28.773-50.809 38.14-46.929z"
        fill="#CA9661"
      />
      <Path
        d="M233.141 233.633s.01-6.245 1.258-10.237c1.249-3.984 1.997-9.263.824-7.447-1.164 1.817-2.451 4.419-2.451 4.419s1.202-4.769.483-4.873c-.719-.113-3.586 4.362-3.586 4.362s2.327-4.295.454-4.087c-1.874.208-11.023 13.681-5.904 22.225 2.545 4.22 5.393-.048 8.922-4.362z"
        fill="#DC9A8E"
      />
      <Path
        d="M233.141 230.691s-8.979 1.021-11.647 5.847c-2.668 4.825 20.919 50.95 31.705 51.215 10.787.265 16.388-8.241 18.081-10.133 1.694-1.893-28.772-50.809-38.139-46.929zM200.489 233.094s-4.465 38.584-7.588 44.611c-3.113 6.027-26.17 4.655-27.845-.085-1.675-4.741 15.394-33.21 16.529-36.512 1.145-3.312 4.986-10.872 18.904-8.014z"
        fill="#CA9661"
      />
      <Path
        d="M239.575 233.094s4.466 38.584 7.588 44.611c3.113 6.027 22.443 4.655 24.117-.085 1.675-4.741-14.646-32.945-15.781-36.247-1.136-3.302-3.425-10.9-15.924-8.279z"
        fill="#CA9661"
      />
      <Path
        d="M265.102 267.496c-7.399-17.797-21.392-33.229-25.574-34.166l.104-.463c4.816 1.078 18.923 17.617 25.905 34.44l-.435.189zM242.896 280.174c-.076-.132-7.872-13.246-9.537-15.091l.35-.312c1.693 1.883 9.272 14.627 9.594 15.167l-.407.236z"
        fill="#A36E4C"
      />
      <Path
        d="M202.552 220.964l-.161-.445c.17-.066 4.239-1.561 5.819-.766l-.208.426c-1.173-.587-4.324.359-5.45.785z"
        fill="#C87D79"
      />
      <Path
        d="M213.376 243.927c-2.479-3.898-17.769-10.275-17.92-10.332l.18-.435c.634.265 15.583 6.5 18.137 10.521l-.397.246zM170.364 267.089l-.435-.189c3.898-9.121 21.477-28.99 21.657-29.189l.35.312c-.18.199-17.702 20.011-21.572 29.066zM191.936 281.877l-.35-.312c.133-.151 13.256-14.93 15.451-24.524l.463.104c-2.233 9.717-15.431 24.59-15.564 24.732z"
        fill="#A36E4C"
      />
      <Path d="M380.305 340.595H58.065v16.88h322.24v-16.88z" fill="#C87D79" />
      <Path
        d="M180.166 286.353c-4.721 0-10.436-1.704-13.568-8.875a.325.325 0 01.038-.36c.237-.265.833-.104 3.34.634 4.39 1.296 12.574 3.709 21.78 3.728v.473c-9.281-.028-17.503-2.451-21.912-3.747-1.212-.359-2.29-.672-2.735-.728 5.819 12.934 20.475 7.096 20.626 7.039l.18.435c-.076.029-3.52 1.401-7.749 1.401zM222.809 242.621l-.236-.406c.51-.303 12.508-7.286 17.03-6.851l-.047.474c-4.352-.436-16.624 6.717-16.747 6.783zM253.975 283.183c-4.361 0-7.673-1.239-7.749-1.268l.171-.445c.151.057 15.166 5.668 23.19-7.493l.407.246c-4.428 7.266-10.928 8.96-16.019 8.96z"
        fill="#A36E4C"
      />
      <Path
        d="M214.095 218.816a3.395 3.395 0 01-3.387-3.387 3.39 3.39 0 013.387-3.387 3.39 3.39 0 013.387 3.387 3.395 3.395 0 01-3.387 3.387zm0-6.301a2.915 2.915 0 102.914 2.914 2.916 2.916 0 00-2.914-2.914zM222.686 218.816a3.395 3.395 0 01-3.387-3.387 3.395 3.395 0 013.387-3.387 3.395 3.395 0 013.387 3.387 3.39 3.39 0 01-3.387 3.387zm0-6.301a2.915 2.915 0 102.914 2.914 2.915 2.915 0 00-2.914-2.914z"
        fill="#FEFADA"
      />
      <Path
        d="M219.904 214.814h-2.81v.473h2.81v-.473zM208.236 211.838l-.26.395 3.128 2.062.26-.395-3.128-2.062zM225.922 214.332l-.17-.445 3.188-1.211.17.435-3.188 1.221z"
        fill="#FEFADA"
      />
      <Path
        d="M214.719 340.699c-12.754-27.514-24.533-40.4-39.378-43.068-14.259-2.564-30.703 4.257-53.458 13.681-5.469 2.271-11.127 4.617-17.248 7.03-23.663 9.357-39.578 10.871-47.308 4.485-5.98-4.949-4.532-13.01-4.513-13.086l.464.086c-.02.075-1.41 7.872 4.352 12.64 5.213 4.305 17.57 7.011 46.825-4.56 6.122-2.422 11.77-4.769 17.239-7.03 22.831-9.462 39.331-16.302 53.722-13.71 15.016 2.697 26.89 15.659 39.72 43.334l-.417.198z"
        fill="#D3D0B1"
      />
      <Path
        d="M207.075 341.296c-.19-.246-19.094-24.856-49.512-22.33-10.238.852-23.493 4.58-37.515 8.535-28.517 8.032-58 16.349-68.388 2.488l.378-.284c10.2 13.596 39.521 5.327 67.877-2.668 14.051-3.955 27.316-7.702 37.6-8.553 30.693-2.545 49.739 22.272 49.929 22.518l-.369.294z"
        fill="#D3D0B1"
      />
      <Path
        d="M117.881 340.822l-.161-.444c15.318-5.658 37.335-11.534 55.255-7.427 10.758 2.459 14.864 7.294 15.035 7.502l-.36.303c-.038-.047-4.201-4.939-14.826-7.351-9.831-2.243-27.334-2.773-54.943 7.417zM221.418 340.699l-.426-.198c12.83-27.675 24.714-40.637 39.72-43.334 14.381-2.592 30.892 4.248 53.722 13.71 5.469 2.271 11.127 4.608 17.239 7.03 16.151 6.386 37.373 12.366 46.825 4.56 5.762-4.768 4.371-12.565 4.352-12.64l.464-.086c.019.086 1.467 8.147-4.513 13.086-7.73 6.386-23.644 4.882-47.308-4.485-6.121-2.422-11.779-4.769-17.248-7.03-22.755-9.433-39.199-16.245-53.457-13.681-14.827 2.668-26.606 15.545-39.37 43.068z"
        fill="#D3D0B1"
      />
      <Path
        d="M229.073 341.296l-.379-.284c.189-.246 19.245-25.064 49.929-22.519 10.284.852 23.559 4.589 37.609 8.554 28.356 7.995 57.687 16.254 67.877 2.658l.378.284c-10.388 13.852-39.87 5.545-68.387-2.488-14.022-3.955-27.278-7.683-37.515-8.535-30.419-2.516-49.323 22.084-49.512 22.33z"
        fill="#D3D0B1"
      />
      <Path
        d="M318.266 340.823c-15.252-5.63-37.184-11.487-54.981-7.409-10.606 2.432-14.731 7.295-14.778 7.342l-.36-.303c.17-.208 4.277-5.042 15.034-7.502 9.888-2.262 27.495-2.811 55.246 7.427l-.161.445z"
        fill="#D3D0B1"
      />
      <Path
        d="M209.714 211.834l-.075-.464c.416-.076 10.237-1.864 9.556-12.517l.473-.029c.369 5.791-2.356 9.026-4.712 10.72-2.555 1.826-5.138 2.271-5.242 2.29zM214.984 197.348c-1.57-.52-2.488-1.372-2.734-2.517-.653-3.056 3.756-7.115 3.945-7.285l.322.35c-.047.038-4.399 4.05-3.803 6.841.208.974 1.021 1.703 2.422 2.166l-.152.445zM223.973 198.484l-.114-.464c.029-.01 2.451-.615 3.151-2.498.492-1.334.028-2.99-1.372-4.939l.388-.274c1.504 2.081 1.987 3.898 1.429 5.383-.786 2.12-3.369 2.763-3.482 2.792zM201.615 227.606c-.028-.047-3.046-4.617-2.072-10.143.502-2.848 1.694-4.219 2.649-5.317 1.041-1.192 1.789-2.044 1.164-4.258-.747-2.668 1.363-6.793 3.747-9.092 1.779-1.713 3.586-2.385 5.09-1.893l-.142.455c-1.324-.426-2.971.208-4.617 1.788-2.621 2.517-4.248 6.386-3.624 8.619.691 2.46-.17 3.454-1.267 4.703-.918 1.05-2.063 2.365-2.546 5.09-.936 5.336 1.978 9.755 2.006 9.802l-.388.246z"
        fill="#B94D59"
      />
      <Path
        d="M211.294 209.005l-.104-.464c4.249-.984 5.942-4.797 6.103-7.758.076-1.344-.227-2.016-.397-2.082-.067-.019-.19.057-.341.275-1.769 2.478-9.641 9.102-9.972 9.385l-.303-.359c.085-.066 8.156-6.869 9.897-9.301.35-.492.671-.511.879-.445.615.218.776 1.458.71 2.555-.18 3.122-1.977 7.144-6.472 8.194zM219.384 195.465c-1.864-1.731-1.145-4.939-1.107-5.071l.464.104c-.01.028-.691 3.084.965 4.627l-.322.34zM220.661 195.541c-.189 0-.312-.019-.321-.019l-.332-.057.161-.293c1.495-2.706 2.035-5.923 2.044-5.951l.464.075c-.019.133-.511 3.075-1.912 5.772.35-.019.89-.114 1.344-.511.605-.539.889-1.457.851-2.744l.474-.019c.047 1.438-.294 2.489-1.013 3.123-.624.539-1.343.624-1.76.624zM228.003 209.374c-.217-.312-5.27-7.74-6.433-10.446l.435-.189c1.145 2.668 6.339 10.294 6.386 10.37l-.388.265zM231.618 213.858c-.35-7.067-4.939-12.924-4.986-12.981l.369-.293c.047.057 4.73 6.036 5.09 13.256l-.473.018z"
        fill="#934048"
      />
      <Path
        d="M235.658 214.114c-.048-5.715-1.656-9.13-2.99-10.985-1.448-2.006-2.905-2.687-2.914-2.696l.198-.436c.246.114 6.094 2.867 6.188 14.107l-.482.01z"
        fill="#B94D59"
      />
      <Path
        d="M233.349 220.879c-1.069-1.476-4.891-.7-4.929-.691l-.095-.463c.17-.038 4.173-.852 5.412.88l-.388.274z"
        fill="#C87D79"
      />
    </Svg>
  )
}

export default SvgComponent
