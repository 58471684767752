import { useEffect } from 'react';
import firebase from "firebase";
import { Popup } from 'components';

const FIREBASE_CONF = {
  apiKey: "AIzaSyCQ07Xtwb22M9whWOnSfK8tQgCZ1JlJfec",
  authDomain: "social.readism.hanoiapp.xyz",
  projectId: "mxh-sach-az",
  storageBucket: "mxh-sach-az.appspot.com",
  messagingSenderId: "734948397575",
  appId: "1:734948397575:web:e804bb1f8415b9688b50a2",
  measurementId: "G-LD03FR601T"
};

function isFacebookInAppBrowser() {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}

export const useGoogleFacebookAuth = () => {

  const initFirebase = () => {
    try {
      if (!firebase.apps || !firebase.apps.length) {
        firebase.initializeApp(FIREBASE_CONF);
        firebase.analytics();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getFacebookLoginData = async () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('public_profile');
    provider.addScope('email');
    // provider.addScope('user_friends');
    try {
      // const result = await firebase.auth().signInWithRedirect(provider);
      if (isFacebookInAppBrowser()) {
        // Popup.show('Thông báo', 'Tính năng này không hỗ trợ trình duyệt của Facebook, vui lòng thử lại với trình duyệt của thiết bị bằng cách mở [...] ở cuối trang và bấm "Mở trong trình duyệt"')
        throw new Error('Tính năng này không hỗ trợ trình duyệt của Facebook, vui lòng thử lại với trình duyệt của thiết bị bằng cách mở [...] ở cuối trang và bấm "Mở trong trình duyệt"')
      }
      const result = await firebase.auth().signInWithPopup(provider);
      console.log(result);
      return [result, null];
    } catch(err) {
      return [null, err];
    }
  };

  const getGoogleLoginData = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    try {
      const result = await  firebase.auth().signInWithPopup(provider);
      console.log(result);
      return [result, null];
    } catch(err) {
      return [null, err];
    }
  };

  useEffect(() => {
    initFirebase();
  }, []);

  return { getFacebookLoginData, getGoogleLoginData };
};