import React, { useEffect, useState } from 'react';
import { Col, Text, Row } from 'react-quick-style-components';
import { CMSLayout, Button, CMSTableLayout } from 'components';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, USER_TYPE } from 'const';
import Store from 'store';
import { VarHelper, TimeHelper } from 'helpers';

const CMSList = () => {
  const { navigation, goWithPreData } = useNavFunc();
  const [{}, uApi] = Store.User.createUserStore();

  const fetcher = (page : number, options) => uApi.listUser({ type: 'all', page, pageSize: 20, options });
  const onPressRow = (rowData) => goWithPreData(SCREEN_NAME.CMSEditUser, { id: rowData.id, name: VarHelper.toSlug(rowData.displayName) }, rowData);

  const entity = "Người dùng";

  const tableSchema = [
    { title: 'Ảnh', type: 'image', key: 'photoUrl', valueStyle: { width: 50, height: 50 } },
    { title: 'Tên', type: 'text', key: 'displayName', width: 200 },
    { title: 'Email', type: 'text', key: 'email', width: 200 },
    { title: 'Loại User', type: 'text', key: (val) => USER_TYPE[val.type] },
    { title: 'Lần sửa cuối', type: 'text', width: 150, key: (val) => TimeHelper.fromNow(val.updatedAt) },
  ];

  return (
    <Col flex1>
      <CMSLayout title={"Quản lý" + entity}>
        <Row>
          <Col flex1>
            <Text h4>{entity.toUpperCase()}</Text>
          </Col>
          <Button paddingHorizontal15 small solid text={"Tạo " + entity} onPress={() => navigation.navigate(SCREEN_NAME.CMSCreateUser)} />
        </Row>
        <Row marginTop24>
          <CMSTableLayout
            initialPage={1}
            tableSchema={tableSchema}
            fetcher={fetcher}
            pageSize={20}
            onRowPress={onPressRow}
            fetchWhenFocus
            searchAndFilter={{
              search: [{ label: 'Tên', value: 'name' }],
              filter: [{ label: 'Loại User', value: 'type', options: Object.keys(USER_TYPE).map(val => ({ value: val, label: USER_TYPE[val] })) }],
              sort: [
                { label: 'Tên', orderType: 'DESC', value: 'name' },
                { label: 'Thời gian cập nhật', orderType: 'DESC', value: 'updatedAt' },
              ],
            }}
          />
        </Row>
      </CMSLayout>
    </Col>
  );
};

export default CMSList;