import React, { useCallback, useState } from 'react';
import { Col, Row, Text, Img, useDynamicResponsiveValue, usePropsStyle } from 'react-quick-style-components';
import { Input, SearchBookSuggestion, BookRecommendationRightSidebar } from 'components';
import Store from 'store';
import { IMAGE, ICON } from 'assets';
import { COLOR, SCREEN_NAME } from 'const';
import { useNavFunc } from 'navigation';

import GenresWidget from './GenresWidget';
import NewsWidget from './NewsWidget';
import { components } from 'react-select';

const Option = (props) => {
  console.log(props.data);
  const { raw, label } = props.data;
  const image = raw?.images ? raw.images[0] : undefined;
  return (
    <components.Option {...props}>
      <Row stretch>
        <Col width50 height50>
          <img src={image} style={{ height: 50, width: 50, objectFit: 'contain' }} />
        </Col>
        <Col flex1 marginLeft8>
          <Text bold>{label}</Text>
          <Text marginTop8>bởi {raw?.authors ? raw.authors[0].name : ''}</Text>
        </Col>
      </Row>
    </components.Option>
  );
}
interface IBookLeftSidebar {
  onPressGenre?: any,
  [anyProp: string]: any,
}

const BookLeftSidebar = (props : IBookLeftSidebar) => {
  const rV = useDynamicResponsiveValue();
  const { navigate, navigation } = useNavFunc();
  const [searchKeyword, setSearchKeyword] = useState('');
  const performSearch = useCallback(() => {
    if (!searchKeyword) return;
    navigate(SCREEN_NAME.BookHomepage, { search: searchKeyword })
  }, [searchKeyword]);
  const propStyle = usePropsStyle(props);
  return (
    <Col style={propStyle}>
      <Col backgroundColor="#DEEEE9" borderRadius4 padding16>
        <Row marginBottom16>
          <Img source={IMAGE.BookIcon10Deg} width40 height40 />
          <Text subtitle1 marginLeft16>Bạn đang đọc cuốn{'\n'}sách nào?</Text>
        </Row>
        {/* <Input
          bgWhite borderRadius6
          value={searchKeyword} onChange={setSearchKeyword} label="" placeholder="Nhập tên sách..."
          inputProps={{
            placeholderTextColor: COLOR.GRAY_500,
          }}
          onEnter={performSearch}
          rightIcon={{
            comp: <ICON.Search fill={COLOR.GRAY_500} />,
            onPress: performSearch,
          }}
        /> */}
        <SearchBookSuggestion
          components={{ Option }}
          navigation={navigation}
        />
      </Col>

      <GenresWidget
        marginTop32
        display={rV({ xs: 'none', md: 'flex' })}
        title="DANH MỤC SÁCH"
        onPressGenre={props.onPressGenre}
      />

      <NewsWidget marginTop32 />

      <BookRecommendationRightSidebar marginTop32 />
    </Col>
  );
};

export default BookLeftSidebar;
