import React from 'react';
import { Col, Text } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle } from 'const';
import { useNavigation } from '@react-navigation/native';
import { SCREEN_NAME } from 'const';
import { Header, Button } from 'components';
import { ICON } from 'assets';

const Settings = () => {
  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1 middle>
        <Text>Cài đặt</Text>
      </Col>
    </Col>
  );
};

export default Settings;