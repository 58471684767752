import React from 'react';
import { Col, Row, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { MainContainer } from '../layouts';
import { ICON } from 'assets';
import { A } from 'navigation';

const FooterInfo = () => {
  return (
    <Col bgMain middle>
      <MainContainer paddingVertical56>
        <Row xs='100%' lg='1:.'>
          <Col marginBottom16>
            <ICON.ReadismWithTextLogo white width={203} />
            <Text colorWhite body1 marginTop16>Mạng xã hội yêu sách đầu tiên tại Việt Nam.{'\n'}Nơi bạn đọc dừng chân chia sẻ và kết nối.</Text>
          </Col>
          <Col marginBottom16>
          </Col>
          <Col marginBottom16>
            <Text colorWhite subtitle1 marginBottom16>CONNECT</Text>
            <A forceLink={'https://www.facebook.com/readismvn/'}>
              <ICON.FacebookCircle />
            </A>
          </Col>
        </Row>
      </MainContainer>
    </Col>
  );
};

export default FooterInfo;