import React from 'react';

export const newfeed = {
  width: 781,
  height: 160,
  bg: 'white',
  color: '#ece4e4',
  renderChildren: () => (
    <>
      <rect x="0" y="0" rx="0" ry="0" width="781" height="30" />
      <rect x="0" y="60" rx="0" ry="0" width="781" height="100" />
    </>
  ),
}