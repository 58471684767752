import React from 'react';
import { Text } from 'react-quick-style-components';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import {
  Home,
  CMSHome,
  CMSListBook,
  CMSListGenre,
  CMSListNews,
  CMSListUser,
  CMSListReport,
  Login,
  Register,
  ForgotPassword,
  Terms,
  AccountVerifyNotice,
  Quote,
  Group,
  Writing,
  News,
  Settings,
  CreateBook,
  UseResetPasswordCode,
  CMSCreateBook,
  CMSEditBook,
  CMSListReview,
  CMSEditReview,
  CMSCreateNews,
  CMSEditNews,
  BookHomepage,
  NewsDetail,
  CMSBulkCreateBook,
  CMSCreateUser,
  CMSEditUser,
  BookDetail,
  BookDetailSingleReview,
  HomeAdminAllFeed,
  UserProfile,
  UserProfileAllReview,
  UserProfileSetting,
  CreateQuote,
  EditQuote,
  UserProfileAllQuote,
  CreateWriting,
  WritingDetail,
  WritingDetailChapter,
  UserProfileAllWriting,
  UpdateWriting,
  CMSEditReport,
  UpsertGroup,
  CreateGroup,
  GroupDetail,
  DirectMessage,
  UserProfileAllFriends,
  CMSBulkCreateReview,
  NotificationDetail,
  CMSDisplay,
  EditorScreen,
  UserProfileAllBookshelf,
  UserProfileBookshelfDetail,
  Feedback,
  FeedDetail,
  QuoteDetail,
  Manga,
  CreateManga,
  MangaDetail,
  MangaDetailChapter,
  UpdateManga,
  UserProfileAllManga,
  MangaDetailChapterNew,
  Test,
  MangaViewAll,
  WritingViewAll,
} from 'screens';
import { DEVICE_INFO, SCREEN_NAME } from 'const';
import { VarHelper } from 'helpers';

import { linking, parseInitialRouteForStack } from './linking';

const Stack = createStackNavigator();

interface IStackAllProps {
  initialParams?: {
    [screenName: string]: any,
  },
  initialRouteName?: string,
  [anyAdditionProps: string]: any,
}

const StackAll = (props : IStackAllProps) => {
  const getInitialParams = (name, defaultValue = undefined) => {
    if (!props.initialParams) return defaultValue;
    if (!props.initialParams[name]) return defaultValue;
    return { ...defaultValue, ...props.initialParams[name] };
  };
  return (
    <Stack.Navigator screenOptions={{ header: () => null }} {...props}>
      <Stack.Screen name={SCREEN_NAME.Home} component={Home} options={{ title: 'Trang chủ' }} />
      <Stack.Screen name={SCREEN_NAME.HomeAdminAllFeed} component={HomeAdminAllFeed} options={{ title: 'Tất cả NewsFeed' }} />
      <Stack.Screen name={SCREEN_NAME.FeedDetail} component={FeedDetail} options={{ title: 'Chi tiết Feed' }}
        initialParams={getInitialParams(SCREEN_NAME.FeedDetail)} />
      {/* CMS */}
      <Stack.Screen name={SCREEN_NAME.CMSHome} component={CMSHome} options={{ title: 'Trang quản trị' }} />
      <Stack.Screen name={SCREEN_NAME.CMSListBook} component={CMSListBook} options={{ title: 'Quản lý Sách' }} />
      <Stack.Screen name={SCREEN_NAME.CMSCreateBook} component={CMSCreateBook} options={{ title: 'Tạo Sách' }} />
      <Stack.Screen name={SCREEN_NAME.CMSBulkCreateBook} component={CMSBulkCreateBook} options={{ title: 'Tạo  hàng loạt sách' }} />
      <Stack.Screen
        name={SCREEN_NAME.CMSEditBook} component={CMSEditBook}
        initialParams={getInitialParams(SCREEN_NAME.CMSEditBook)} options={{ title: 'Chỉnh sửa sách' }}
      />
      <Stack.Screen name={SCREEN_NAME.CMSListGenre} component={CMSListGenre} options={{ title: 'Quản lý Thể loại' }} />
      <Stack.Screen name={SCREEN_NAME.CMSListUser} component={CMSListUser} options={{ title: 'Quản lý Người dùng' }} />
      <Stack.Screen name={SCREEN_NAME.CMSListNews} component={CMSListNews} options={{ title: 'Quản lý Tin tức' }} />
      <Stack.Screen name={SCREEN_NAME.CMSListReport} component={CMSListReport} options={{ title: 'Quản lý Vi phạm' }} />
      <Stack.Screen name={SCREEN_NAME.CMSEditReport} component={CMSEditReport} options={{ title: 'Quản lý Vi phạm' }}
        initialParams={getInitialParams(SCREEN_NAME.CMSEditReport)} />
      <Stack.Screen name={SCREEN_NAME.CMSListReview} component={CMSListReview} options={{ title: 'Quản lý Đánh giá' }} />
      <Stack.Screen
        name={SCREEN_NAME.CMSEditReview} component={CMSEditReview}
        initialParams={getInitialParams(SCREEN_NAME.CMSEditReview)} options={{ title: 'Chỉnh sửa Đánh giá' }}
      />
      <Stack.Screen name={SCREEN_NAME.CMSCreateNews} component={CMSCreateNews} options={{ title: 'Thêm tin' }} />
      <Stack.Screen name={SCREEN_NAME.CMSEditNews} component={CMSEditNews} options={{ title: 'Cập nhật tin' }}
        initialParams={getInitialParams(SCREEN_NAME.CMSEditNews)} />
      <Stack.Screen name={SCREEN_NAME.CMSCreateUser} component={CMSCreateUser} options={{ title: 'Tạo người dùng' }} />
      <Stack.Screen name={SCREEN_NAME.CMSEditUser} component={CMSEditUser} options={{ title: 'Sửa người dùng' }}
        initialParams={getInitialParams(SCREEN_NAME.CMSEditUser)} />
      <Stack.Screen name={SCREEN_NAME.CMSBulkCreateReview} component={CMSBulkCreateReview} options={{ title: 'Nhập đánh giá' }} />
      <Stack.Screen name={SCREEN_NAME.CMSDisplay} component={CMSDisplay} options={{ title: 'Quản lý hiển thị' }} />
      {/* AUTH */}
      <Stack.Screen name={SCREEN_NAME.Login} component={Login} options={{ title: 'Đăng nhập' }} />
      <Stack.Screen name={SCREEN_NAME.Register} component={Register} options={{ title: 'Đăng ký' }} />
      <Stack.Screen name={SCREEN_NAME.ForgotPassword} component={ForgotPassword} options={{ title: 'Quên mật khẩu' }} />
      <Stack.Screen name={SCREEN_NAME.AccountVerifyNotice} component={AccountVerifyNotice} options={{ title: 'Xác thực tài khoản' }} />
      <Stack.Screen
        name={SCREEN_NAME.UseResetPasswordCode}
        component={UseResetPasswordCode}
        options={{ title: 'Đặt lại mật khẩu' }}
        initialParams={getInitialParams(SCREEN_NAME.UseResetPasswordCode)}
      />
      {/* INFO */}
      <Stack.Screen name={SCREEN_NAME.Terms} component={Terms} options={{ title: 'Điều khoản sử dụng' }} />
      <Stack.Screen name={SCREEN_NAME.Feedback} component={Feedback} options={{ title: 'Đóng góp ý kiến' }} />
      {/* QUOTE */}
      <Stack.Screen name={SCREEN_NAME.Quote} component={Quote} options={{ title: 'Trích dẫn' }} />
      <Stack.Screen name={SCREEN_NAME.CreateQuote} component={CreateQuote} options={{ title: 'Thêm trích dẫn' }} />
      <Stack.Screen name={SCREEN_NAME.EditQuote} component={EditQuote} options={{ title: 'Sửa trích dẫn' }}
        initialParams={getInitialParams(SCREEN_NAME.EditQuote)} />
      <Stack.Screen name={SCREEN_NAME.QuoteDetail} component={QuoteDetail} options={{ title: 'Trích dẫn' }}
        initialParams={getInitialParams(SCREEN_NAME.QuoteDetail)} />
      {/* GROUP */}
      <Stack.Screen name={SCREEN_NAME.Group} component={Group} options={{ title: 'Hội nhóm' }} />
      <Stack.Screen name={SCREEN_NAME.CreateGroup} component={CreateGroup} options={{ title: 'Tạo nhóm' }} />
      <Stack.Screen name={SCREEN_NAME.UpdateGroup} component={UpsertGroup} options={{ title: 'Chỉnh sửa nhóm' }}
        initialParams={getInitialParams(SCREEN_NAME.UpdateGroup)} />
      <Stack.Screen name={SCREEN_NAME.GroupDetail} component={GroupDetail} options={{ title: 'Hội nhóm' }}
        initialParams={getInitialParams(SCREEN_NAME.GroupDetail, { tab: 'feed' })} />
      <Stack.Screen name={SCREEN_NAME.GroupDetailEditPost} component={GroupDetail} options={{ title: 'Hội nhóm' }}
        initialParams={getInitialParams(SCREEN_NAME.GroupDetailEditPost, { postmode: 'chi-tiet' })} />
      {/* WRITING */}
      <Stack.Screen name={SCREEN_NAME.Writing} component={Writing} options={{ title: 'Viết' }} />
      <Stack.Screen name={SCREEN_NAME.WritingViewAll} component={WritingViewAll} options={{ title: 'Viết' }} />
      <Stack.Screen name={SCREEN_NAME.CreateWriting} component={CreateWriting} options={{ title: 'Viết mới' }} />
      <Stack.Screen name={SCREEN_NAME.WritingDetail} component={WritingDetail} options={{ title: 'Viết' }}
        initialParams={getInitialParams(SCREEN_NAME.WritingDetail)} />
      <Stack.Screen name={SCREEN_NAME.WritingDetailChapter} component={WritingDetailChapter} options={{ title: 'Viết' }}
        initialParams={getInitialParams(SCREEN_NAME.WritingDetailChapter)} />
      <Stack.Screen name={SCREEN_NAME.UpdateWriting} component={UpdateWriting} options={{ title: 'Viết' }}
        initialParams={getInitialParams(SCREEN_NAME.UpdateWriting)} />
      {/* MANGA */}
      {/* <Stack.Screen name={SCREEN_NAME.Manga} component={Manga} options={{ title: 'Truyện tranh' }} />
      <Stack.Screen name={SCREEN_NAME.MangaViewAll} component={MangaViewAll} options={{ title: 'Truyện tranh' }} />
      <Stack.Screen name={SCREEN_NAME.CreateManga} component={CreateManga} options={{ title: 'Truyện tranh mới' }} />
      <Stack.Screen name={SCREEN_NAME.MangaDetail} component={MangaDetail} options={{ title: 'Truyện tranh' }}
        initialParams={getInitialParams(SCREEN_NAME.MangaDetail)} />
      <Stack.Screen name={SCREEN_NAME.MangaDetailChapter} component={MangaDetailChapterNew} options={{ title: 'Truyện tranh' }}
        initialParams={getInitialParams(SCREEN_NAME.MangaDetailChapter)} />
      <Stack.Screen name={SCREEN_NAME.MangaDetailChapterNew} component={MangaDetailChapterNew} options={{ title: 'Truyện tranh' }}
        initialParams={getInitialParams(SCREEN_NAME.MangaDetailChapterNew)} />
      <Stack.Screen name={SCREEN_NAME.UpdateManga} component={UpdateManga} options={{ title: 'Truyện tranh' }}
        initialParams={getInitialParams(SCREEN_NAME.UpdateManga)} /> */}
      {/* NEWS */}
      <Stack.Screen name={SCREEN_NAME.News} component={News} options={{ title: 'Tin tức' }} />
      <Stack.Screen name={SCREEN_NAME.NewsDetail} component={NewsDetail} options={{ title: 'Tin tức' }}
        initialParams={getInitialParams(SCREEN_NAME.NewsDetail)} />
      {/* USER */}
      <Stack.Screen name={SCREEN_NAME.Settings} component={Settings} options={{ title: 'Cài đặt' }} />
      <Stack.Screen name={SCREEN_NAME.UserProfile} component={UserProfile} options={{ title: 'Trang cá nhân' }}
        initialParams={getInitialParams(SCREEN_NAME.UserProfile)} />
      <Stack.Screen name={SCREEN_NAME.UserProfileAllReview} component={UserProfileAllReview} options={{ title: 'Trang cá nhân - Tất cả đánh giá' }}
        initialParams={getInitialParams(SCREEN_NAME.UserProfileAllReview)} />
      <Stack.Screen name={SCREEN_NAME.UserProfileAllQuote} component={UserProfileAllQuote} options={{ title: 'Trang cá nhân - Tất cả trích dẫn' }}
        initialParams={getInitialParams(SCREEN_NAME.UserProfileAllQuote)} />
      <Stack.Screen name={SCREEN_NAME.UserProfileAllWriting} component={UserProfileAllWriting} options={{ title: 'Trang cá nhân - Tất cả Viết' }}
        initialParams={getInitialParams(SCREEN_NAME.UserProfileAllWriting)} />
      <Stack.Screen name={SCREEN_NAME.UserProfileAllManga} component={UserProfileAllManga} options={{ title: 'Trang cá nhân - Tất cả Truyện tranh' }}
        initialParams={getInitialParams(SCREEN_NAME.UserProfileAllManga)} />
      <Stack.Screen name={SCREEN_NAME.UserProfileAllFriends} component={UserProfileAllFriends} options={{ title: 'Bạn bè' }}
        initialParams={getInitialParams(SCREEN_NAME.UserProfileAllFriends)} /> 
      <Stack.Screen name={SCREEN_NAME.UserProfileAllBookshelf} component={UserProfileAllBookshelf} options={{ title: 'Tủ sách' }}
        initialParams={getInitialParams(SCREEN_NAME.UserProfileAllBookshelf)} /> 
      <Stack.Screen name={SCREEN_NAME.UserProfileBookshelfDetail} component={UserProfileBookshelfDetail} options={{ title: 'Tủ sách' }}
        initialParams={getInitialParams(SCREEN_NAME.UserProfileBookshelfDetail)} /> 
      <Stack.Screen name={SCREEN_NAME.UserProfileSetting} component={UserProfileSetting} options={{ title: 'Thiết lập cá nhân' }}
        initialParams={getInitialParams(SCREEN_NAME.UserProfileSetting) || { tab: 'co-ban' }} />
      <Stack.Screen name={SCREEN_NAME.DirectMessage} component={DirectMessage} options={{ title: 'Tin nhắn' }}
        initialParams={getInitialParams(SCREEN_NAME.DirectMessage, { mode: 'danh-sach' })} />
      <Stack.Screen name={SCREEN_NAME.DetailConversation} component={DirectMessage} options={{ title: 'Tin nhắn' }}
        initialParams={getInitialParams(SCREEN_NAME.DetailConversation)} />
      <Stack.Screen name={SCREEN_NAME.NotificationDetail} component={NotificationDetail} options={{ title: 'Thông báo' }}
        initialParams={getInitialParams(SCREEN_NAME.NotificationDetail)} />
      {/* Book */}
      <Stack.Screen name={SCREEN_NAME.CreateBook} component={CreateBook} options={{ title: 'Đóng góp sách' }} />
      <Stack.Screen name={SCREEN_NAME.BookHomepage} component={BookHomepage} options={{ title: 'Sách' }}
        initialParams={getInitialParams(SCREEN_NAME.BookHomepage)} />
      <Stack.Screen name={SCREEN_NAME.BookDetail} component={BookDetail} options={{ title: 'Sách' }}  
        initialParams={getInitialParams(SCREEN_NAME.BookDetail)} />
      <Stack.Screen name={SCREEN_NAME.BookDetailSingleReview} component={BookDetailSingleReview} options={{ title: 'Chi tiết đánh giá' }}  
        initialParams={getInitialParams(SCREEN_NAME.BookDetailSingleReview)} />

      <Stack.Screen name={SCREEN_NAME.EditorScreen} component={EditorScreen} options={{ title: 'Trình soạn thảo' }}  
        initialParams={getInitialParams(SCREEN_NAME.EditorScreen)} />

      <Stack.Screen name={'Test'} component={Test} options={{ title: 'Test' }}  
        initialParams={{}} />
    </Stack.Navigator>
  );
}

export const AllStackFromHome = () => {
  const { routeName, screenParams } = parseInitialRouteForStack();
  return (
    <StackAll
      initialRouteName={routeName}
      initialParams={screenParams}
    />
  )
};
export const TabLogin = () => <StackAll initialRouteName={SCREEN_NAME.Login} />;
export const TabCMS = () => <StackAll initialRouteName={SCREEN_NAME.CMSHome} />;
export const AllStackFromQuote = () => <StackAll initialRouteName={SCREEN_NAME.Quote} />;
export const AllStackFromWriting = () => <StackAll initialRouteName={SCREEN_NAME.Writing} />;
export const AllStackFromNews = () => <StackAll initialRouteName={SCREEN_NAME.News} />;
export const AllStackFromGroup = () => <StackAll initialRouteName={SCREEN_NAME.Group} />;
export const AllStackFromSettings = () => <StackAll initialRouteName={SCREEN_NAME.Settings} />;