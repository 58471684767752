import User from './User.Store';
import Misc from './Misc.Store';
import Book from './Book.Store';
import Genres from './Genres.Store';
import Reviews from './Reviews.Store';
import News from './News.Store';
import Comments from './Comments.Store';
import Quotes from './Quotes.Store';
import Writing from './Writing.Store';
import Dashboard from './Dashboard.Store';
import ViolationReport from './ViolationReport.Store';
import Group from './Group.Store';
import Message from './Message.Store';
import Setting from './Setting.Store';
import Manga from './Manga.Store';

const Store = {
  User: User,
  Misc,
  Book,
  Genres,
  Reviews,
  News,
  Comments,
  Quotes,
  Writing,
  Dashboard,
  ViolationReport,
  Group,
  Message,
  Setting,
  Manga,
};

// @ts-ignore
window.Store = Store;

export default Store;
