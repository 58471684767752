import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={27}
      height={25}
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M12.14.844L9.095 7.078l-6.89.984C.983 8.25.515 9.75 1.405 10.642l4.922 4.828-1.172 6.797c-.187 1.218 1.125 2.156 2.203 1.593l6.141-3.234 6.094 3.234c1.078.563 2.39-.375 2.203-1.593l-1.172-6.797 4.922-4.828c.89-.891.422-2.391-.797-2.579l-6.844-.984L14.813.844c-.516-1.078-2.11-1.125-2.672 0z"
        fill={props.fill || "#E4E4E4"}
      />
    </Svg>
  )
}

export default SvgComponent
