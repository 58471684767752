import React, { useState, useCallback } from 'react';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { hideModal, Input, RatioCol, RateStars, Popup, YoutubeEmbed, Button } from 'components';
import { ICON } from 'assets';
import { COLOR, STYLES } from 'const';
import { useWindowDimensions, } from 'react-native';
import Store from 'store';
import { IBook } from 'type';
import { VarHelper } from 'helpers';
import { ERROR } from 'const';
import { AntDesign } from '@expo/vector-icons';

interface IAddYoutubeModalProps {
  onSelectYoutube(id: string): void,
  currentId: string,
}

const AddYoutubeModal = (props: IAddYoutubeModalProps) => {
  // const rV = useDynamicResponsiveValue();
  const [link, setLink] = useState('');
  const [youtubeId, setYoutubeId] = useState(props.currentId);
  const { width, height } = useWindowDimensions();

  const processLink = () => {
    if (!link) return Popup.showError(ERROR.co_fill_all_form);
    const youtubeId = VarHelper.getYoutubeIdFromLink(link);
    if (!!youtubeId) setYoutubeId(youtubeId);
    else Popup.showError('Có lỗi xảy ra, vui lòng chọn link khác');
  };

  const containerWidth = width < 768 ? width * 0.9 : Math.min(width / 2, height / 2);
  const containerHeight = (containerWidth - 48) / (16/9) + 48 + 30 + 44 + 16 + 12 + 33 + 20;

  return (
    <Col width={containerWidth} height={containerHeight} bgWhite borderRadius10 onPress={() => {}} activeOpacity={1}>
      <Scroll width={containerWidth} height={containerHeight} padding24 borderRadius10>
        <Row justifyContent="space-between">
          <Text fontSize20 bold>Thêm sách</Text>
          <Col width30 height30 middle onPress={hideModal}>
            <ICON.Close fill={COLOR.GRAY_500} width={13} height={20} />
          </Col>
        </Row>

        <Col>
          <Input
            label=""
            placeholder="Điền link và enter"
            value={link}
            onChange={setLink}
            onEnter={processLink}
            marginTop16
          />
          <Col padding4 absolute top='50%' right12 transform={[{ translateY: -12 }]} onPress={processLink}>
            <AntDesign name="logout" size={24} color={COLOR.MAIN} />
          </Col>
        </Col>
        

        {Boolean(youtubeId) && (
          <Col marginTop12>
            <YoutubeEmbed id={youtubeId} ratio={16/9} width={'100%'} />
            <Row marginTop12 middle>
              {Boolean(props.currentId) && (
                <Button marginRight16 outline small paddingHorizontal12 hieght33 text="Bỏ video" onPress={() => {
                  props.onSelectYoutube('');
                  hideModal();
                }} />
              )}
              <Button marginRight16 outline small paddingHorizontal12 hieght33 text="Chọn lại" onPress={() => {
                setLink('');
                setYoutubeId('');
              }} />
              <Button solid small paddingHorizontal12 hieght33 text="Thêm video" onPress={() => {
                props.onSelectYoutube && props.onSelectYoutube(youtubeId);
                hideModal();
              }} />
            </Row>
          </Col>
        )}

      </Scroll>
    </Col>
  );
};

export default AddYoutubeModal;
