import React, { useEffect, useRef } from 'react';
import {  RichTextEditor2 } from 'components';
import { useNavFunc } from 'navigation';

const EditorScreen = () => {
  const { route } = useNavFunc();
  const { placeholder, allow_link } = route.params || {};
  const editorRef = useRef<{ getHTML: Function, getJSON: Function, getText: Function, setJSON: Function }>();

  useEffect(() => {
    const handleEvent = (e) => {
      console.log('RECEIVE EVENT');
      try {
        const data = JSON.parse(e.data);
        const { action, payload } = data;
        switch(action) {
          case 'setJSON':
            editorRef.current?.setJSON(payload);
          break;
          case 'getHTML':
            if (window.self !== window.top && !!window.parent) {
              const data = {
                action: 'getHTML',
                payload: editorRef.current?.getHTML(),
              };
              window.parent.postMessage(JSON.stringify(data), '*');
            }
          break;
          case 'getJSON':
            if (window.self !== window.top && !!window.parent) {
              const data = {
                action: 'getJSON',
                payload: editorRef.current?.getJSON(),
              };
              window.parent.postMessage(JSON.stringify(data), '*');
            }
          break;
          case 'getText':
            if (window.self !== window.top && !!window.parent) {
              const data = {
                action: 'getText',
                payload: editorRef.current?.getText(),
              };
              window.parent.postMessage(JSON.stringify(data), '*');
            }
          break;
          default:
        }
      } catch(e) {}
    };
    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);

  return (
    <div style={{ backgroundColor: 'white', flex: 1 }}>
      <RichTextEditor2 ref={editorRef} allowLink={allow_link === '1'} forNews placeholder={placeholder} />
    </div>  
  )
};

export default EditorScreen;