import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { IPost, IComment } from 'type';
import Store from 'store';
import { COLOR, DEVICE_INFO, POST_VISIBILITY, SCREEN_NAME, ERROR } from 'const';
import { Avatar, Popup, showModal } from 'components/elements';
import { useNavFunc, genDetailLink } from 'navigation';
import { StyleSheet, TextInput, useWindowDimensions, Platform } from 'react-native';
import { TimeHelper, VarHelper } from 'helpers';

import ListLikeModal from '../ListLikeModal';
import UserName from '../UserName';
import SingleComment from './SingleComment';
import { useShareModal } from 'components';
import { useRefState2 } from 'components/hooks';
import CommentForm from './CommentForm';
import _ from 'lodash';

interface ILikeCommentsProps {
  postType: string,
  entityData: IPost,
  navigation?: any,
  hideLike?: boolean,
  showCommentTextInputByDefault?: boolean,
  commentBgColor?: string,
  renderRight?: any,
  [anyProp: string]: any,
}

const LikeComment = (props : ILikeCommentsProps) => {
  const { navigate, navigation } = useNavFunc(props.navigation, { params: {} });
  const { user, friends, token } = Store.User.state;
  const uApi = Store.User;
  const [{  }, cApi] = Store.Comments.createStore();
  const [numberOfLikes, setNumberOfLikes] = useState(props.entityData?.numberOfLikes || 0);
  const [numberOfComments, setNumberOfComments] = useState(props.entityData?.numberOfComments || 0);
  const [didLike, setDidLike] = useState(!!props.entityData?.likes ? props.entityData?.likes[user.id] : false);
  const [likes, setLikes] = useState(props.entityData?.likes || {});
  
  const [listComments, setListComments] = useState<Array<IComment>>(!!props.entityData?.lastComment ? [props.entityData?.lastComment] : []);
  const [showCommentForm, setShowCommentForm] = useState(props.showCommentTextInputByDefault);
  const [tempComment, setTempComment] = useState('');
  const [inputKeyID, setInputKeyID] = useState('input'+VarHelper.genId());

  const [commentLayout, getCommentLayout, setCommentLayout] = useRefState2<{ height: number; width: number; left: number; top: number; x: number; y: number }>();

  const listCommentsRef = useRef([]);

  useEffect(() => {
    listCommentsRef.current = listComments;
  }, [listComments])

  const likeArr = Object.keys(likes).map(val => ({ id: val, liked: likes[val] })).filter(val => !!val.liked);

  const showSenderIP = props.postType === 'quote';

  const toggleLike = useCallback(async () => {
    if (!token) return alert('Bạn cần đăng nhập để thực hiện tính năng này');
    setDidLike(!didLike);
    setLikes(v => ({
      ...v,
      [user.id]: !v[user.id],
    }));
    setNumberOfLikes(v => didLike ? v - 1 : v + 1);
    const [res, err] = await uApi.like({ id: props.entityData.id, postType: props.postType });
  }, [didLike, token]);

  const handleCommentPress = useCallback(() => {
    if (!token) return alert('Bạn cần đăng nhập để thực hiện tính năng này');
    setShowCommentForm(true);
  }, [token]);

  const listUserForMention = useMemo(() => {
    const fromComment = listComments.map(val => ({ id: val.createdBy.id, display: val.createdBy.name }));
    const fromFriend = friends.map(val => ({ id: val.id, display: val.name }));
    return _.uniqWith([...fromComment, ...fromFriend], _.isEqual)
  }, [listComments, friends]);

  useEffect(() => {
    if (props.showCommentTextInputByDefault) {
      showAllComment();
    }
  }, [props.showCommentTextInputByDefault]);

  const showAllComment = async () => {
    const [res, err] = await cApi.getListAll({ postId: props.entityData.id, postType: props.postType });
    if(!!res && !!res.data) {
      setListComments(res.data);
    }
  };

  const sendComment = async (commentValue = tempComment) => {
    const newList = listComments.slice();
    const content = commentValue.trim();
    if (!content) {
      setTempComment('');
      setInputKeyID('input'+VarHelper.genId())
      return;
    }
    const commentId = VarHelper.genId();
    newList.push({
      id: commentId,
      content,
      image: '',
      createdBy: {
        id: user.id,
        name: user.displayName,
        avatar: user.photoUrl,
        type: user.type,
      },
      createdAt: TimeHelper.format(undefined, 'YYYY-MM-DD HH:mm:ssZZ'),
    });
    setTempComment('');
    setListComments(newList);
    setInputKeyID('input'+VarHelper.genId());
    setNumberOfComments(v => v + 1);

    const onError = () => {
      const restoredList = newList.filter(val => val.id !== commentId);
      setListComments(restoredList);
      setNumberOfComments(v => v - 1);
    }

    const [res, err] = await uApi.comment({
      id: props.entityData.id,
      commentId,
      postType: props.postType,
      content,
      image: '',
      parentCommentId: '',
      browserName: DEVICE_INFO.BROWSER,
      browserId: DEVICE_INFO.BROWSER_ID,
    });
    if (err || res.error) {
      onError();
      return Popup.showError(ERROR.parseError(err || res.error));
    }
    if (!!res && res.data) {
      setListComments(l => {
        const newList = l.slice();
        const findIndex = listCommentsRef.current.findIndex(val => val.id === res.data.id);
        // console.log('findIndex', findIndex);
        if (findIndex !== -1) {
          newList[findIndex] = res.data;
        }
        return newList;
      });
    }
  };

  // const isWarningFraud = (() => {
  //   const listCheck = listComments.filter(val => !!val.ip);
  //   const dataCheck = [];
  //   for (let i = 0; i< listCheck.length; i++) {
  //     const findIndex = listCheck.findIndex(val => val.ip === listCheck[i].ip);
  //     if (findIndex !== -1) {
  //       if (!dataCheck[findIndex].userIds.includes(listCheck[i].createdById)) {
  //         dataCheck[findIndex].userIds.push(listCheck[i].createdById);
  //       }
  //       if (!dataCheck[findIndex].browserIds.includes(listCheck[i].browserId)) {
  //         dataCheck[findIndex].browserIds.push(listCheck[i].browserId);
  //       }
  //     } else dataCheck.push({
  //       ip: listCheck[i].ip,
  //       userIds: [listCheck[i].createdById],
  //       browserIds: [listCheck[i].browserId],
  //     });
  //   }

  //   const findOneIPMultipleUsers = 
  // })();

  const showLikeModal = () => {
    if (!token) return;
    showModal({
      component: (
        <ListLikeModal likes={likes} navigation={navigation} />
      )
    })
  }

  const shareLink = genDetailLink({
    type: props.postType,
    id: props.entityData.id,
    // @ts-ignore
    name: props.entityData.name || '',
  });

  const { showShareModal } = useShareModal({
    link: shareLink,
  })

  const onCommentFormLayout = e => {
    const layout = e?.nativeEvent?.layout;
    setCommentLayout(layout);
  }

  useEffect(() => {
    if (showCommentForm) {
      setTimeout(() => {
        const { top } = getCommentLayout() || {};
        window.scrollTo({ left: 0, top: window.scrollY + top - 60 });
      }, 500);
    }
  }, [showCommentForm]);

  const onReplyThisComment = (comment : IComment) => {
    setShowCommentForm(true);
    setTempComment(`@[${comment.createdBy?.name}](user:__${comment.createdBy?.id}__) `);
  };
  
  return (
    <Col>
      <Row paddingHorizontal24 paddingVertical16>
        {Boolean(!props.hideLike) && (
          <Col paddingVertical4 marginRight16 onPress={toggleLike}>
            <Text bold colorMain>{didLike ? 'Bỏ thích' : 'Thích'}</Text>
          </Col>
        )}
        {Boolean(!props.showCommentTextInputByDefault) && (
          <Col paddingVertical4 onPress={handleCommentPress}>
            <Text bold colorMain>Bình luận</Text>
          </Col>
        )}
        <Col flex1 />
        {Boolean(props.entityData.visibility === 'draft') && (
          <Col>
            <Text>* Không công khai</Text>
          </Col>
        )}
        {Boolean(shareLink) && (
          <Col paddingVertical4 marginLeft16 onPress={showShareModal}>
            <Text bold colorMain>Chia sẻ</Text>
          </Col>
        )}
        {Boolean(props.renderRight && typeof props.renderRight === 'function') && props.renderRight()}
      </Row>
      {Boolean(likeArr.length > 0 && !props.hideLike) && (
        <Row paddingVertical16 paddingHorizontal24 borderTopWidth1 borderTopColor={COLOR.BORDER} onPress={showLikeModal}>
          {Boolean(didLike) ? (
            <Text>
              <Text bold>Bạn</Text>
              {Boolean(likeArr.length - 1 > 0) ? (
                <>
                  <Text> và</Text>
                  <Text bold> {likeArr.length - 1} người khác</Text>
                  <Text> đã thích bài này</Text>
                </>
              ) : (
                <Text> đã thích bài này</Text>
              )}
            </Text>
          ) : (
            <Text><Text bold>{likeArr.length} người</Text> đã thích bài này</Text>
          )}
        </Row>
      )}
      {Boolean(listComments.length > 0) && (
        <>
          {Boolean(numberOfComments > listComments.length) && (
            <Col paddingHorizontal24 onPress={showAllComment} onHoverStyle={{ opacity: 1 }} useNestedHover>
              <Text bold onHoverStyle={{ textDecorationLine: 'underline' }}>Xem tất cả {numberOfComments} bình luận</Text>
            </Col>
          )}
          <Col
            paddingVertical16 paddingHorizontal24 borderTopColor={COLOR.BORDER}
            borderTopWidth={numberOfComments > listComments.length ? 0 : 1}
            paddingTop={numberOfComments > listComments.length ? 0 : 16}
          >
            {listComments.map((comment, cI) => {
              return (
                <SingleComment
                  key={comment.id}
                  comment={comment}
                  showSenderIP={showSenderIP}
                  isLast={cI === listComments.length - 1}
                  navigate={navigate}
                  commentBgColor={props.commentBgColor}
                  onReplyThisComment={() => {
                    onReplyThisComment(comment);
                  }}
                />
              );
            })}
          </Col>
        </>
      )}
      {Boolean(showCommentForm) && (
        <CommentForm
          onCommentFormLayout={onCommentFormLayout}
          tempComment={tempComment}
          setTempComment={setTempComment}
          sendComment={sendComment}
          user={user}
          inputKeyID={inputKeyID}
          setInputKeyID={setInputKeyID}
          listUserForMention={listUserForMention}
        />
      )}
    </Col>
  );
};

export default LikeComment;
