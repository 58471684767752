import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={70}
      height={70}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M61.25 23.333c-.59 0-14.146.085-26.25 5.857-12.104-5.772-25.66-5.857-26.25-5.857a2.917 2.917 0 00-2.917 2.917v28.825a2.916 2.916 0 00.89 2.097c.569.549 1.4.89 2.126.817l.37-.003c1.993 0 12.53.286 24.547 5.907.047.023.1.014.146.032.347.142.712.242 1.088.242.376 0 .74-.1 1.09-.242.047-.018.1-.01.147-.032 12.016-5.624 22.554-5.907 24.546-5.907l.37.003c.695.073 1.555-.268 2.127-.816.566-.552.887-1.31.887-2.098V26.25a2.917 2.917 0 00-2.917-2.917zM11.667 29.31c4.33.323 12.778 1.4 20.416 4.935v22.58c-8.75-3.427-16.304-4.357-20.416-4.596v-22.92zm46.666 22.919c-4.112.239-11.666 1.17-20.416 4.596v-22.58c7.638-3.535 16.085-4.612 20.416-4.935v22.919zM35 23.333a8.75 8.75 0 100-17.5 8.75 8.75 0 000 17.5z"
        fill="#C9C9C9"
      />
    </Svg>
  )
}

export default SvgComponent
