import React from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { COLOR, DEVICE_INFO, SCREEN_NAME } from 'const';
import { showModal, hideModal } from '../elements';

interface IDropdownOptionsProps {
  navigation: {
    navigate: Function,
  },
  options: Array<{ icon?: any, text: string, onPress: any, }>,
  width: number,
}

const DropdownOptions = ({ navigation, options, width } : IDropdownOptionsProps) => {

  const renderItem = (val, i) => {
    return (
      <Row
        padding12
        key={'use-dropdown-'+i}
        onPress={() => {
          hideModal();
          !!val.onPress && val.onPress();
        }}
        onHoverStyle={{
          backgroundColor: COLOR.PRI_200,
        }}
      >
        {Boolean(val.icon) && (
          <Col width30 height30 middle>
            {val.icon}
          </Col>
        )}
        <Text>{val.text}</Text>
      </Row>
    );
  }

	return (
		<Col width={width} bgWhite shadow >
      {options.map(renderItem)}
    </Col>
	);
};

export interface IUseDropdownOptions {
  navigation: {
    navigate: Function,
  },
  positions: {
    top?: any, left?: any, right?: any, bottom?: any,
  },
  useEventPosition?: boolean,
  dropdownOptions: Array<{ icon?: any, text: string, onPress: any, }>,
  width: number,
}

export const useDropdownOptions = ({ navigation, width, positions, dropdownOptions, useEventPosition } : IUseDropdownOptions) => {

	const showDropdown = (e) => {
    const left = e?.nativeEvent?.clientX ? e?.nativeEvent?.clientX - width / 2 : 0;
    const top = e?.nativeEvent?.clientY ? e?.nativeEvent?.clientY + 24 : 0;
    // console.log({ left, top });
		showModal({
			component: (
				<DropdownOptions navigation={navigation} options={dropdownOptions} width={width} />
			),
			modalProps: {
				style: {
					position: 'absolute',
          ...(useEventPosition ? { left, top } : positions),
				},
			},
			noWrapper: true,
			animation: 'pulse',
		})
	};

	return {
		showDropdown,
		hideDropdown: hideModal,
	};
};
