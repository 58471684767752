import React, { useCallback, useState } from 'react';
import { Col, Img, Row, Text, usePropsStyle, useDynamicResponsiveValue } from 'react-quick-style-components';
import { RatioCol, Avatar, CollapsableText, Button, Popup, PostTimestamp } from 'components/elements';
import { usePreDataOrFetchApi, usePostCURDDropdown } from 'components/hooks';
import UserName from '../UserName';
import { IActivity, IBook, IReview, IWriting } from 'type';
import { useNavFunc } from 'navigation';
import LikeComments from '../LikeComments/LikeComments';
import { SCREEN_NAME, STYLES, COLOR, ERROR } from 'const';
import { ICON } from 'assets';
import { VarHelper, TimeHelper } from 'helpers';
import { FontAwesome5, AntDesign } from '@expo/vector-icons';
import Store from 'store';

interface IFeedItemProps {
  data: IActivity,
  [anyProp: string]: any,
}

const WritingFeed = (props: IFeedItemProps) => {
  const rV = useDynamicResponsiveValue()
  const { navigate, navigation, goWithPreData } = useNavFunc();
  const propStyle = usePropsStyle(props);
  const data: IWriting = props.data?.data;
  const { user } = Store.User.state;
  const [{}, wApi] = Store.Writing.createStore();

  const [didRemove, setDidRemove] = useState(false);

  const isMyPost = !!user.id && user.id === data?.createdById;
  const isAdmin = !!user.type && user.type === 'ADMIN';

  const onPressGoToDetail = () => {
    navigate(SCREEN_NAME.WritingDetail, { id: data.id, name: VarHelper.toSlug(data.name) })
  }

  const goPressCreatorProfile = () => {
    navigate(SCREEN_NAME.UserProfile, { id: data.createdById, name: VarHelper.toSlug(data.createdBy.name) });
  }

  const onPressRemove = useCallback(() => {

    const doRemove = async () => {
      const [res, err] = await wApi.update({
        id: data?.id,
        visibility: 'trash',
      } as any);
      if (err) return Popup.showError(ERROR.parseError(err));
      if (res.error) return Popup.showError(ERROR.parseError(res.error));
      setDidRemove(true);
    }

    Popup.show('Bạn cho chắc chắn muốn xóa?', [
      { text: 'Có', solid: false, outline: true, onPress: doRemove },
      { text: 'Không', solid: true, outline: false, onPress: () => { } },
    ]);
  }, [data?.id]);

  const { showDropdown } = usePostCURDDropdown({
    navigation,
    onPressEdit: () => goWithPreData(SCREEN_NAME.UpdateWriting, { id: data.id, name: VarHelper.toSlug(data.name) }, props.data),
    onPressRemove: onPressRemove,
    hasEditPermission: isMyPost || isAdmin,
    objectId: data.id,
    objectType: 'writing',
  });

  if (!data || didRemove) return null;

  const shouldShowLikeCommentActivity = !!data?.lastComment?.createdAt;

  return (
    <Col style={propStyle} borderWidth1 borderColor={COLOR.BORDER} borderRadius4 bgWhite>
      <Col padding24>
        {Boolean(shouldShowLikeCommentActivity) && (
          <Row alignItems="flex-start" marginBottom16>
            <Col flex1>
              <Text><UserName  {...data.createdBy} /> đã bình luận</Text>
              <Col marginTop4 onHoverStyle={{ opacity: 1 }} useNestedHover onPress={onPressGoToDetail}>
                <Text onHoverStyle={{ textDecorationLine: 'underline' }} color={COLOR.GRAY_500}>{TimeHelper.fromNow(data?.lastComment?.createdAt)}</Text>
              </Col>
            </Col>
            <Col width24 height24 middle onPress={showDropdown}>
              <ICON.ThreeDots />
            </Col>
          </Row>
        )}
        <Row flexDirection={rV({ xs: 'column-reverse', md: 'row' })} alignItems={rV({ xs: 'flex-end', md: 'center' })}>
          <Row flex1 width={rV({ xs: '100%', md: undefined })}>
            <Col width48 onPress={goPressCreatorProfile}>
              <Avatar size={48} name={data.createdBy.name} uri={data.createdBy.avatar} />
            </Col>
            <Col marginLeft16 flex1>
              <Text><UserName  {...data.createdBy} /> đã viết <Text bold>{data.name}</Text></Text>
              {Boolean(!shouldShowLikeCommentActivity) && (
                <PostTimestamp
                  onPress={onPressGoToDetail}
                  updatedAt={data.updatedAt} createdAt={data.createdAt}
                />
              )}
            </Col>
          </Row>
          <Row justifyContent='space-between'>
              <Col width24 height24 middle marginLeft24 onPress={showDropdown}>
                <ICON.ThreeDots />
              </Col>
            </Row>
        </Row>
      </Col>
      <Col paddingHorizontal24 flexDirection={rV({ xs: 'column-reverse', md: 'column' })}>
        <Row marginVertical12 alignItems="flex-start" xs='100%' md='any:1'>
          <RatioCol ratio={1} width={rV({ xs: '100%', md: 160 })} onPress={onPressGoToDetail}>
            <Img source={{ uri: data.image }} style={STYLES.all100p} />
          </RatioCol>
          <Col middle={rV({ xs: true, md: false })}>
            <Row marginLeft16 marginTop16 xs='100%' md='1:any' alignItems='flex-start'>
              <Col marginBottom8 marginRight16 middle={rV({ xs: true, md: false })}>
                <Text subtitle1 >{data.visibility === 'draft' ? '[Nháp] ': ''}{data.name}</Text>
                <Text caption marginTop8>{data.createdBy.name}</Text>
                <Text caption marginTop16>{data.numberOfChapter} chương</Text>
              </Col>
              <Col alignItems={rV({ xs: 'center', md: 'flex-end' })}>
                <Button width146 height43 small solid text="Đọc ngay" onPress={onPressGoToDetail} />
              </Col>
            </Row>
          </Col>
        </Row>
        <CollapsableText expandMaxHeight={150} marginVertical12>
          <Text>{data.content}</Text>
        </CollapsableText>
      </Col>
      <LikeComments entityData={data} postType='writing' />
    </Col>
  );
};

export default WritingFeed;
