import { COLOR, STYLES } from 'const';
import React, { useState, useEffect } from 'react';
import { Col, Row, Text, Img, usePropsStyle } from 'react-quick-style-components';
import { IWriting } from 'type/WritingType';

interface IWritingItemProps {
  data: IWriting,
  onPress?: any,
  [anyProp: string]: any,
}

const WritingItem = (props : IWritingItemProps) => {
  const propStyle = usePropsStyle(props);
  const { data, onPress } = props;
  return (
    <Col padding24 borderRadius4 borderWidth1 borderColor={COLOR.BORDER} bgWhite style={propStyle} onPress={onPress}>
      <Row alignItems="flex-start">
        <Col width100 height134>
          <Img source={{ uri: data.image || '' }} style={STYLES.all100p} />
        </Col>
        <Col marginLeft16 flex1>
          <Text bold fontSize={16} lineHeight={19.36} numberOfLines={2}>{data.name}</Text>
          <Text marginTop8 fontSize={14} lineHeight={16}>{data.createdBy.name}</Text>
          {Boolean(data.numberOfChapter) && <Text marginTop16 fontSize={14} lineHeight={16}>{data.numberOfChapter} chương</Text>}
        </Col>
      </Row>
      <Col height100 marginTop16>
        <Text numberOfLines={3} fontSize={16} lineHeight={22}>{data.content}</Text>
      </Col>
    </Col>
  );
};

export default WritingItem;