import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Col, Text, useDynamicResponsiveValue, Row, Scroll, Img } from 'react-quick-style-components';
import {
  Header, MainContainer, ControlledRichTextEditor, Popup, Button, MangaImagesEditor,
  ControlledUploaderUI, Input, useSubmitUIState, Select, usePreDataOrFetchApi,
  DragableGrid,
  UseMemo,
} from 'components';
import { useNavFunc } from 'navigation';
import { useWindowDimensions } from 'react-native';
import { COLOR, ERROR, SCREEN_NAME, CHAPTER_EDIT_VISIBILITY, MANGA_VISIBILITY } from 'const';

import { EditableChapterTitle } from './CreateManga.Comps';
import { ICON } from 'assets';
import { ILocalFileUpload, IManga } from 'type';
import Store from 'store';

const UpdateManga = () => {
  const { reset, navigate, route } = useNavFunc();
  const { id } = route.params;
  const [mangaData] = usePreDataOrFetchApi<IManga>(id, 'manga');
  const { height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();
  const [{ listGenres }, gApi] = Store.Genres.createStore();
  const [genres, setGenres] = useState([]);
  const [step, setStep] = useState(1);
  const [chapters, setChapters] = useState([{ name: 'Tiêu đề chương 1', order: 1, visibility: 'public', images: [] }]);
  const chaptersRef = useRef({ active: 0, allChap: [] });
  const [activeChapterIndex, setActiveChapterIndex] = useState(0);
  const [previewMode, setPreviewMode] = useState(false);
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [visibility, setVisibility] = useState('public');
  const [promoted, setPromoted] = useState({ label: 'Không', value: false });
  const [localImage, setLocalImage] = useState<ILocalFileUpload>();

  const { user } = Store.User.state;
  const [{ }, mApi] = Store.Manga.createStore();
  const [{ }, miscApi] = Store.Misc.createMiscStore();
  const [{ loading, errorMes, btnText }, setSubmitUI] = useSubmitUIState();

  const writingImageRef = useRef<{ getUploadedUrl: Function }>();

  useEffect(() => {
    chaptersRef.current = {
      active: activeChapterIndex,
      allChap: chapters,
    };
  }, [chapters, activeChapterIndex]);

  useEffect(() => {
    if (!mangaData) return;
    const chapterArray = mangaData.listChapters.map(val => ({
      ...val,
      images: val.images.map(item => ({
        path: item?.url, mime: '', name: item?.name, raw: undefined,
      })),
    }));
    setName(mangaData.name);
    setContent(mangaData.content);
    setVisibility(mangaData.visibility);
    setLocalImage({
      path: mangaData.image, mime: '', name: '', raw: undefined,
    });
    chaptersRef.current = {
      active: 0,
      allChap: chapterArray,
    };
    setChapters(chapterArray as any);
    if (mangaData.promoted) {
      setPromoted({ label: 'Có', value: true });
    }
  }, [mangaData]);

  const addAnotherChapter = () => {
    setChapters([
      ...chapters,
      { name: 'Chưa có tiêu đề', order: chapters.length + 1, visibility: 'pubic', images: [] },
    ])
  };


  const onChangeEditor = useCallback((newData) => {
    const allChapData = chaptersRef.current.allChap.slice();
    allChapData[chaptersRef.current.active].images = newData;
    setChapters(allChapData);
  }, []);

  const handleDeleteChapter = index => {
    const allChapData = chaptersRef.current.allChap.slice();
    const chapContent = allChapData[chaptersRef.current.active];
    const isTheLastOne = chaptersRef.current.allChap.length === 1 && index === 0;
    const doRemove = () => {
      if (chaptersRef.current.active === index) {
        chaptersRef.current.active = Math.max(0, chaptersRef.current.active - 1);
      }
      const newChapters = [
        ...allChapData.slice(0, index),
        ...allChapData.slice(index + 1, allChapData.length),
      ].map((val, index) => ({ ...val, order: index + 1 }));
      setActiveChapterIndex(chaptersRef.current.active);
      setChapters(newChapters.length === 0 ? [{ name: 'Chưa có tiêu đề', order: 1, visibility: 'public', images: [] }] : newChapters);
      if (isTheLastOne) {
        const doDelete = async () => {
          await mApi.update({
            id,
            visibility: 'trash',
            chapters: [],
          } as any);
          reset(SCREEN_NAME.Manga);
        };
        Popup.show('Bạn có muốn xóa luôn truyện này?', [
          { text: 'Có', solid: false, outline: true, onPress: doDelete },
          { text: 'Không', solid: true, outline: false, onPress: () => { } },
        ]);
      }
    }
    if (chapContent.images.length === 0) return doRemove();
    Popup.show('Chương đang có dữ liệu, bạn có chắc muốn xóa chương này?', [
      { text: 'Có', solid: false, outline: true, onPress: doRemove },
      { text: 'Không', solid: true, outline: false, onPress: () => { } },
    ]);
  }

  const uploadChapterImages = async (chapters): Promise<Array<{ name, order, visibility, images }>> => {
    console.log('chapter', chapters);
    const results = [];
    const promiseArr = chapters.map(async (chap, chapIndex) => {
      try {
        const images = [];
        await Promise.all(chap.images.map(async (val, i) => {
          try {
            if (val.path.indexOf('http') === 0) {
              images[i] = {
                url: val.path,
                name: val.name,
              }
            } else {
              const [url] = await miscApi.uploadLocal(val);
              images[i] = {
                url,
                name: val.name,
              }
            }
          } catch (err) { }
        }));
        results[chapIndex] = {
          ...chap,
          images,
        }
      } catch (err) { }
    })
    await Promise.all(promiseArr);
    return results;
  };

  const submitManga = async () => {
    if (!name || !content) return setSubmitUI({ errorMes: ERROR.co_fill_all_form });
    console.log('chapters', chapters);
    console.log('hasEmptyChapter', chapters.filter(val => val.images.length === 0));
    const hasEmptyChapter = chapters.filter(val => val.images.length === 0).length > 0;
    if (hasEmptyChapter && visibility === 'public') return setSubmitUI({ errorMes: ERROR.wr_no_empty_chapter });
    if (!localImage || !localImage.path) return setSubmitUI({ errorMes: 'Thiếu ảnh bìa truyện' });
    setSubmitUI({ loading: true });
    const [image] = await miscApi.uploadLocal(localImage);
    if (!image) return setSubmitUI({ errorMes: 'Vui lòng thêm ảnh', loading: false });
    const uploaded = await uploadChapterImages(chapters);
    const [res, err] = await mApi.update({
      id,
      name,
      content,
      image,
      visibility,
      chapters: uploaded,
      genres: genres.map(val => ({ id: val.value, name: val.label })),
      promoted: user.type === 'ADMIN' ? promoted.value : undefined,
    })
    if (err) return setSubmitUI({ errorMes: ERROR.parseError(err), loading: false });
    if (res.error) return setSubmitUI({ errorMes: ERROR.parseError(res.error), loading: false });
    setSubmitUI({ btnText: 'Thành công', errorMes: '', loading: false });
    setTimeout(() => {
      setSubmitUI({ btnText: '', errorMes: '', loading: false });
      setTimeout(() => {
        reset(SCREEN_NAME.Manga);
      }, 500);
    }, 1500);
  };

  if (user.type === 'UNVERIFY-USER') {
    return (
      <Col flex1 backgroundColor="#F3F6F5">
        <Header />
        <Col flex1 middle>
          <Text>Bạn cần xác thực tài khoản để sử dụng tính năng này</Text>
          <Button marginTop16 small solid text="Xác thực" onPress={() => navigate(SCREEN_NAME.AccountVerifyNotice)} />
        </Col>
      </Col>
    )
  }

  const handleGoToStep2 = () => {
    console.log(chaptersRef.current);
    const countDrafChapter = chaptersRef.current.allChap.filter(val => val.visibility === 'draft').length;
    const doAfterConfirm = () => {
      setStep(2);
    }
    if (countDrafChapter > 1) {
      Popup.show('Bạn vẫn còn chương nháp, các chương nháp sẽ không hiển thị công khai', [
        { text: 'Đã hiểu', solid: false, outline: true, onPress: doAfterConfirm },
        { text: 'Chỉnh sửa tiếp', solid: true, outline: false, onPress: () => { } },
      ]);
    } else {
      setStep(2);
    }

  }

  const updateChapterOrder = (oldIndex, newIndex) => {
    if (newIndex === -1 || newIndex === chaptersRef.current.allChap.length) return;
    const allChapData = chaptersRef.current.allChap.slice();
    const item1 = Object.assign({}, allChapData[oldIndex]);
    const item2 = Object.assign({}, allChapData[newIndex]);
    allChapData[oldIndex] = undefined;
    allChapData[newIndex] = undefined;
    allChapData[oldIndex] = item2;
    allChapData[newIndex] = item1;
    setChapters(allChapData.map((val, i) => ({ ...val, order: i + 1 })));
    chaptersRef.current.active = newIndex;
    setActiveChapterIndex(newIndex);
  }

  const renderStep2 = () => {
    return (
      <Col flex1>
        <Col borderBottomWidth1 borderBottomColor={COLOR.BORDER} paddingVertical24>
          <MainContainer>
            <Row justifyContent="space-between">
              <Text bold fontSize={18}>NỘI DUNG</Text>
              <Col alignItems="flex-end">
                {Boolean(errorMes) && <Text marginBottom4 color={COLOR.DANGER_500}>{errorMes}</Text>}
                <Row>
                  <Button marginRight8 outline width154 height43 onPress={() => setStep(1)} large text="Quay lại" />
                  <Button loading={loading} width154 height43 onPress={submitManga} large solid text={btnText || "Cập nhật"} />
                </Row>
              </Col>
            </Row>
          </MainContainer>
        </Col>
        <MainContainer paddingVertical24 paddingHorizontal={rV({ xs: 10, md: 0 })}>
          <Row alignItems="flex-start" xs='100%' lg='2:any:1'>
            {/* MAIN */}
            <Col>
              <Text marginBottom24 subtitle1>{chapters[activeChapterIndex]?.name}</Text>
              <UseMemo deps={[chapters, height, activeChapterIndex]}>
                <MangaImagesEditor
                  images={chapters[activeChapterIndex]?.images}
                  onChange={onChangeEditor}
                  onPreview={() => setPreviewMode(true)}
                  height={height * 0.5}
                />
              </UseMemo>
            </Col>

            <Col width24 height0 />
            {/* SIDEBAR */}
            <Col>
              {previewMode ? (
                <Col>
                  <Col borderRadius4 borderWidth1 borderColor={COLOR.BORDER}>
                    <Col padding24>
                      <Text bold fontSize={18}>Xem thử {chapters[activeChapterIndex]?.name}</Text>
                    </Col>
                    <Scroll height={height * 0.5}>
                      {chapters[activeChapterIndex]?.images.map((val, i) => (
                        <img key={'image-' + i} src={val.path} style={{ width: '100%', height: 'auto' }} />
                      ))}
                    </Scroll>
                    <Col absolute top0 right0 width24 height24 middle onPress={() => setPreviewMode(false)}>
                      <ICON.Close width={10} height={10} color={COLOR.GRAY_300} />
                    </Col>
                  </Col>
                </Col>
              ) : (
                <>
                  <Col borderRadius4 borderWidth1 borderColor={COLOR.BORDER}>
                    <Col padding24>
                      <Text bold fontSize={18}>DANH SÁCH</Text>
                    </Col>
                    <Col>
                      {chapters.map((val, i) => {
                        return (
                          <EditableChapterTitle
                            data={val} key={'chap-' + i}
                            isActive={i === activeChapterIndex}
                            onPress={() => {
                              chaptersRef.current.active = i;
                              setActiveChapterIndex(i);
                            }}
                            onChange={({ name: newName, visibility, password }) => {
                              const allChapData = chaptersRef.current.allChap.slice();
                              allChapData[chaptersRef.current.active].name = newName;
                              allChapData[chaptersRef.current.active].visibility = visibility;
                              allChapData[chaptersRef.current.active].password = password;
                              setChapters(allChapData);
                            }}
                            onPressDelete={() => handleDeleteChapter(i)}
                            onMoveUp={() => updateChapterOrder(i, i - 1)}
                            onMoveDown={() => updateChapterOrder(i, i + 1)}
                          />
                        );
                      })}
                    </Col>
                    <Row paddingHorizontal24 paddingVertical12 onPress={addAnotherChapter}>
                      <ICON.Plus fill={COLOR.MAIN} height={15} width={15} />
                      <Text colorMain marginLeft8>Thêm chương</Text>
                    </Row>
                  </Col>
                  <Text marginTop16><Text bold>Ghi chú</Text>: Tác phẩm của bạn sẽ được hiển thị dưới dạng truyện ngắn nếu bạn chỉ có 1 chương duy nhất</Text>
                </>
              )}


            </Col>

          </Row>

        </MainContainer>
      </Col>
    );
  }

  const renderStep1 = () => {
    return (
      <Col flex1>
        <Col borderBottomWidth1 borderBottomColor={COLOR.BORDER} paddingVertical24>
          <MainContainer>
            <Row justifyContent="space-between">
              <Text bold fontSize={18}>THÔNG TIN TỔNG QUAN</Text>
              <Col alignItems="flex-end">
                <Row>
                  <Button width154 height43 onPress={handleGoToStep2} large solid text="Tiếp tục" />
                </Row>
              </Col>

            </Row>
          </MainContainer>
        </Col>
        <MainContainer paddingVertical24 paddingHorizontal={rV({ xs: 10, md: 0 })}>
          <Row alignItems="flex-start" xs='100%' lg='any:any:1'>
            <Col width170>
              <ControlledUploaderUI
                aspectRatio={170 / 226}
                ref={writingImageRef}
                value={localImage}
                onChange={setLocalImage}
              />
            </Col>
            <Col width24 />
            <Col>
              <Input
                value={name} onChange={setName}
                label='Tên' required placeholder='Nhập tên..'
              />
              <Input
                marginTop24 multiline inputStyle={{ padding: 12 }}
                value={content} onChange={setContent}
                label='Mô tả thêm' required placeholder='Một đoạn ngắn giới thiệu về truyện của bạn..'
              />
              <Select
                label="Thể loại"
                value={genres}
                options={listGenres.map(val => ({ label: val.name, value: val.id }))}
                onChange={setGenres}
                isMulti
                placeholder="Tìm và chọn thể loại"
                marginTop16
              />
              <Select
                marginTop12
                label="Hiển thị"
                options={Object.keys(MANGA_VISIBILITY).map(val => ({ value: val, label: MANGA_VISIBILITY[val] }))}
                value={{ label: MANGA_VISIBILITY[visibility], value: visibility }}
                onChange={(v) => setVisibility(v.value)}
              />
              {user.type === 'ADMIN' && (
                <Select
                  marginTop12
                  label="Đề củ (Admin only)"
                  options={[{ label: 'Không', value: false }, { label: 'Có', value: true }]}
                  value={promoted}
                  onChange={(v) => setPromoted(v)}
                />
              )}
            </Col>
          </Row>
        </MainContainer>
      </Col>
    );
  }

  return (
    <Col flex1 backgroundColor="white">
      <Header />
      {step === 1 ? renderStep1() : renderStep2()}
    </Col>
  )
};

export default UpdateManga;