import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { ILocalFileUpload, IUser } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';
import axios from 'axios';
import { ERROR, DEVICE_INFO } from 'const';
interface ISetters {
  [additionSetter: string]: (v : any) => void,
}

class Misc extends PersistReady {

  constructor() {
    super();
  }

  state = {
    
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createMiscStore() {

    return [
      {  },
      {
        genSignedUploadFile: this.genSignedUploadFile,
        uploadLocal: this.uploadLocalPHP,
        giveFeedback: this.giveFeedback,
        generalSearch: this.generalSearch,
      }
    ];
  }

  genSignedUploadFile = async ({ name, mime }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/misc/gen-signed-upload-url', { fileName: name, contentType: mime });
      return res.data;
    });
  }

  uploadLocalGoogle = async ({ path, mime, name, raw } : ILocalFileUpload) => {
    return VarHelper.erria(async () => {
      if (!raw && !path) return '';
      if (!raw && !!path && path.includes('https://')) return path;
      const [res, err] = await this.genSignedUploadFile({ mime, name: new Date().getTime() + '_' + name });
      if (err) throw err;
      if (res.error) throw new Error(res.error);
      const signedUrl = res.data;
      const response = await axios.request({
        url: signedUrl,
        method  : 'PUT',
        data: raw,
        headers: {
          'Content-Type': mime,
        }
      });
      if (response.status === 200) {
        return signedUrl.split('?')[0];
      }
      throw new Error(ERROR.co_error);
    });
  };

  uploadLocalCloudflare = async ({ path, mime, name, raw } : ILocalFileUpload) => {
    return VarHelper.erria(async () => {
      if (!raw && !path) return '';
      if (!raw && !!path && path.includes('https://')) return path;
      const nameWithTimestamp = new Date().getTime() + '_' + name;
      const [res, err] = await this.genSignedUploadFile({ mime, name: nameWithTimestamp });
      if (err) throw err;
      if (res.error) throw new Error(res.error);
      const signedUrl = res.data;
      const data = new FormData();
      data.append('file', raw, nameWithTimestamp);
      const response = await axios.request({
        url: signedUrl,
        method  : 'POST',
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      const { result } = response.data;
      if (!!result && !!result.variants && result.variants.length > 0) {
        return result.variants[0];
      }
    });
  }

  uploadLocalPHP = async ({ path, mime, name, raw } : ILocalFileUpload) => {
    return VarHelper.erria(async () => {
      if (!raw && !path) return '';
      if (!raw && !!path && path.includes('https://')) return path;
      const nameWithTimestamp = new Date().getTime() + '_' + name;
      const url = 'https://media.readism.vn/upload.php';
      const data = new FormData();
      data.append('sendimage', raw, nameWithTimestamp);
      const response = await axios.request({
        url,
        method  : 'POST',
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      if (response.data.success && response.data.data) return response.data.data;
    });
  }

  giveFeedback = async (content : string) => {
    return VarHelper.erria(async () => {
      const url = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScDeIPC8jY9k4Fb9wuclwZEnYL9beSO8XCtajeOx13Cw-esGw/formResponse';
      const device = DEVICE_INFO.BROWSER || DEVICE_INFO.MODEL;
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': "application/x-www-form-urlencoded;charset=utf-8"
        },
        body: `entry.1890184262=${encodeURI(content)}&entry.726268345=${device}`,
      })
    });
  }

  generalSearch = async (keyword) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/search', { keyword });
      return res.data; 
    });
  }
}

export default new Misc();
