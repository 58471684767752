import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { IBookShelf, IUser } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';

interface ISetters {
  setMyBookShelfList?: (v : any) => void,
  [additionSetter: string]: (v : any) => void,
}

interface State {
  myBookShelfList: Array<IBookShelf>,
}

class Book extends PersistReady {

  constructor() {
    super();
    GlobalEvent.on('LOGOUT', () => {
      this.updateState({
        myBookShelfList: [],
      });
    });
  }

  state : State = {
    myBookShelfList: [],
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createBookStore() {
    const [ myBookShelfList, setMyBookShelfList ] = useGlobalState(this.state.myBookShelfList, 'bookStore_myBookshelfList');
    if (!this.setters.setMyBookShelfList) this.setters.setMyBookShelfList = setMyBookShelfList;
    useEffect(() => {
      this.updateState({ myBookShelfList });
    }, [myBookShelfList]);
    return [
      { myBookShelfList },
      {
        adminCreateBook: this.adminCreateBook,
        listBook: this.listBook,
        detailBook: this.detailBook,
        updateBook: this.updateBook,
        useContributeBook: this.useContributeBook,
        adminBulkCreate: this.adminBulkCreate,
        getMyBookshelfList: this.getMyBookshelfList,
        computePublicBookshelfData: this.computePublicBookshelfData,
        detailBookshelf: this.detailBookshelf,
        moveBookBetweenShelves: this.moveBookBetweenShelves,
        removeBookFromBookShelf: this.removeBookFromBookShelf,
        getBookshelfListOfUser: this.getBookshelfListOfUser,
        getListNewReview: this.getListNewReview,
      }
    ];
  }

  adminCreateBook = async ({ name, authors, content, buyLinks, genres, publisher, images, visibility }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/books/create', { name, authors, content, buyLinks, genres, publisher, images, visibility });
      return res.data;
    });
  }

  listBook = async ({ visibility = 'public', page = 1, pageSize = 20, ...rest }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/books/list', { visibility, page, pageSize, ...rest });
      return res.data;
    });
  }

  detailBook = async (id) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/books/'+id, {});
      return res.data;
    });
  }

  updateBook = async (data) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/books/update', data);
      return res.data;
    });
  }

  useContributeBook = async ({ name, authors, content, genres, publisher, images, rate, review, reviewJSON, hasSpoiler, reviewHTML }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/books/create', { name, authors, content, genres, publisher, images, rate, review, reviewJSON, hasSpoiler, reviewHTML });
      return res.data;
    });
  }

  adminBulkCreate = async (data) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/books/create/bulk', { data });
      return res.data;
    });
  }

  getMyBookshelfList = async () => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/bookshelf/my-list', {});
      if (res.data.success && res.data.data) {
        !!this.setters.setMyBookShelfList && this.setters.setMyBookShelfList(res.data.data);
      }
      return res.data;
    });
  }

  getBookshelfListOfUser = async (id) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/bookshelf/user-list/'+id, {});
      return res.data;
    });
  }

  addBookToBookShelf = async (bs, { id, name, image }) => {
    return VarHelper.erria(async () => {
      const updateObj = {
        id: bs.id,
        books: [
          ...bs.books,
          { id, name, image },
        ],
      }
      const res = await Request.post(HOST + '/bookshelf/update', updateObj);
      if (res.data.success && res.data.data) {
        const newList = this.state.myBookShelfList.slice();
        const findExistingIndex = newList.findIndex(val => val.id === bs.id);
        if (findExistingIndex !== -1) {
          newList[findExistingIndex] = res.data.data;
        } else {
          newList.push(res.data.data);
        }
        !!this.setters.setMyBookShelfList && this.setters.setMyBookShelfList(newList);
      }
      return res.data;
    });
  }

  removeBookFromBookShelf = async (bs, id) => {
    return VarHelper.erria(async () => {
      const updateObj = {
        id: bs.id,
        books: bs.books.filter(val => val.id !== id),
      }
      const res = await Request.post(HOST + '/bookshelf/update', updateObj);
      if (res.data.success && res.data.data) {
        const newList = this.state.myBookShelfList.slice();
        const findExistingIndex = newList.findIndex(val => val.id === bs.id);
        if (findExistingIndex !== -1) {
          newList[findExistingIndex] = res.data.data;
        } else {
          newList.push(res.data.data);
        }
        !!this.setters.setMyBookShelfList && this.setters.setMyBookShelfList(newList);
      }
      return res.data;
    });
  }

  moveBookBetweenShelves = async (bsTarget : IBookShelf, bsSource : IBookShelf, { id, name, image }) => {
    return VarHelper.erria(async () => {
      const [res, err] = await this.addBookToBookShelf(bsSource, { id, name, image });
      if (err) throw err;
      const [res2, err2] = await this.removeBookFromBookShelf(bsTarget, id);
      if (err2) throw err2;
      return { success: true };
    });
  }

  computePublicBookshelfData = (listBookshelves) => {
    if (!listBookshelves) return [];
    return listBookshelves.filter(val => val.books.length > 0 && val.books.filter(val => val.status !== 'PENDING').length > 0);
  }

  detailBookshelf = id => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/bookshelf/'+id, {});
      return res.data;
    });
  }

  getListNewReview = () => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/books/list-new-review', {});
      return res.data;
    });
  }
}

export default new Book();
