import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { CMSLayout, Button, Input, Uploader, Select, useRefState, IframeEditor, RichTextEditor2, RichTextEditor3, CMSUploaderUI } from 'components';
import { Linking } from 'react-native';
import { useNavFunc, Reset, getScreenPreData } from 'navigation';
import { COLOR, DEVICE_INFO, ERROR, SCREEN_NAME, BOOK_VISIBILITY } from 'const';
import { ICON } from 'assets';
import { INews } from 'type';
import Store from 'store';

const CMSEditNews = () => {
  const { goBackOrTo, route } = useNavFunc();
  const [btnText, setBtnText] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [visibility, setVisibility] = useState('draft');

  const [{}, nApi] = Store.News.createStore();
  const rV = useDynamicResponsiveValue();

  const editorRef = useRef<{ getHTML: Function, getJSON: Function, getText: Function, setJSON: Function }>();
  const thumbnailRef = useRef<{ getUploadedUrl: Function, setUri: Function }>();
  const coverRef = useRef<{ getUploadedUrl: Function, setUri: Function }>();

  const id = route.params?.id;
  console.log('id', id);

  const getInitialData = async () => {
    let data : INews = getScreenPreData(id);
    if (!data) {
      const [res, err] = await nApi.detail(id);
      if (err) return alert(ERROR.parseError(err));
      if (res.error) return alert(ERROR.parseError(res.error));
      data = res.data;
    }
    setName(data.name);
    setDescription(data.description);
    if (data.images && data.images.length >0) {
      if (!!data.images[0]) thumbnailRef.current?.setUri(data.images[0]);
      if (!!data.images[1]) coverRef.current?.setUri(data.images[1]);
    }
    if (data.contentJSON) {
      editorRef.current?.setJSON(data.contentJSON);
    }
    setVisibility(data.visibility);
  }

  useEffect(() => {
    getInitialData();
  }, [id]);

  const submit = async () => {
    if (!name || !description) return setErrorMessage(ERROR.co_fill_all_required_form);
    setLoading(true);
    const [thumbImg, coverImg] = await Promise.all([
      thumbnailRef.current?.getUploadedUrl(),
      coverRef.current?.getUploadedUrl(),
    ]);
    const content = await editorRef.current?.getText();
    const contentJSON = await editorRef.current?.getJSON();
    const contentHTML = await editorRef.current?.getHTML();

    const obj = {
      id,
      name,
      description,
      images: [thumbImg[0] || '', coverImg[0] || ''],
      content,
      contentJSON,
      contentHTML,
      visibility,
    };
    // console.log('obj', obj);
    // @ts-ignore
    const [res, err] = await nApi.update(obj);
    setLoading(false);
    if (err) return setErrorMessage(ERROR.parseError(err));
    if (res.error) return setErrorMessage(ERROR.parseError(res.error));
    setBtnText('Cập nhật thành công..');
    setTimeout(() => {
      setBtnText('');
      setTimeout(() => {
        goBackOrTo(SCREEN_NAME.CMSListNews)
      }, 500)
    }, 500);
  };

  if (!id) {
    return (
      <Col middle flex1 bgWhite>
        <Text>Đường dẫn không hợp lệ..</Text>
        <Reset to={SCREEN_NAME.CMSHome} timeout={1500} />
      </Col>
    );
  }

  return (
    <Col flex1>
      <CMSLayout title="Cập nhật tin">
        <Row>
          <Col flex1>
            <Text h4></Text>
          </Col>
          <Button paddingHorizontal12 small solid text="Quay lại" onPress={() => goBackOrTo(SCREEN_NAME.CMSListNews)} />
        </Row>

        <Input marginTop24 label="Tiêu đề bài" required value={name} onChange={setName} placeholder="" />

        <Row xs='100%' lg="1:any:any" alignItems="flex-start">
          <Input
            marginTop24
            label="Nội dung ngắn" placeholder=""
            multiline
            required
            value={description}
            onChange={setDescription}
            inputStyle={{ padding: 12 }}
          />
          <Col width24 height0 />
          <Col marginTop24 width={rV({ xs: '100%', lg: 363 / 2 })}>
            <Text subtitle1 marginBottom8>Ảnh thumbnail</Text>
            <CMSUploaderUI ref={thumbnailRef} aspectRatio={363/228} subText="363x228" />
          </Col>
        </Row>
        
        <Col marginTop24>
          <Text subtitle1 marginBottom8>Nội dung *</Text>
          {/* <RichTextEditor3 ref={editorRef} allowLink /> */}
          {/* <iframe src="/editor-screen" style={{ border: 'none', width: '100%', height: 300 }} /> */}
          <IframeEditor allowLink ref={editorRef} />
        </Col>

        <Col marginTop24>
          <Text subtitle1 marginBottom8>Ảnh bìa</Text>
          <CMSUploaderUI ref={coverRef} aspectRatio={1366 * 2 /768} subText="1366x384" />
        </Col>

        <Row marginTop24 marginBottom200 justifyContent="space-between" alignItems="flex-start">
          <Col width150>
            <Select
              label="Trạng thái hiển thị"
              options={Object.keys(BOOK_VISIBILITY).map(val => ({ value: val, label: BOOK_VISIBILITY[val] }))}
              value={{ label: BOOK_VISIBILITY[visibility], value: visibility }}
              onChange={(v) => setVisibility(v.value)}
            />
          </Col>
          <Col height100p justifyContent="flex-end">
            {Boolean(errorMessage) && <Text color={COLOR.DANGER_500} marginTop8>{errorMessage}</Text>}
            <Button small solid text={btnText ? btnText : "Xác nhận"} loading={loading} paddingHorizontal12 onPress={submit} />
          </Col>
        </Row>
      </CMSLayout>
    </Col>
  );
};

export default CMSEditNews;