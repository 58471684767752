import React, { useState, useEffect, useCallback } from 'react';
import { Col, Text, Scroll, Img, Row } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import { Header, Button, MainContainer, RatioCol, GroupItem, GroupDetailLayout, Avatar, Input, useRefState } from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';
import { IGroupFeed } from 'type';
import { VarHelper } from 'helpers';

interface IGroupDetailChildrenProps {
  groupFeedData: IGroupFeed,
  getPage?: any,
  navigation?: any,
}

let _timeout;

const ListWithFilter = ({ list, navigation }) => {
  const [keyword, setKeyword] = useRefState('');
  const [filterList, setFilterList] = useState(list);
  useEffect(() => {
    setFilterList(list)
  }, [list]);
  const search = () => {
    setFilterList((() => {
      if (!keyword()) return list;
      return list?.filter(val => val?.name?.toLowerCase().includes(keyword().toLowerCase()));
    })());
  };
  return (
    <Col borderWidth1 borderColor={COLOR.BORDER} borderRadius10 bgWhite>
      <Col padding24>
        <Input
          label=""
          placeholder="Tìm kiếm thành viên"
          useRefState
          onChange={setKeyword}
          leftIcon={{
            comp: <ICON.Search fill={COLOR.MAIN} />
          }}
          rightIcon={{
            comp: <Button solid small text='Tìm' height30 width40 marginRight8 onPress={search} />,
          }}
          onEnter={search}
        />
      </Col>
      <Row xs='100%' md='50%' padding12>
        {filterList?.map((u, i) => (
          <Row
            onPress={() => navigation.navigate(SCREEN_NAME.UserProfile, { id: u.id, name: VarHelper.toSlug(u.name) })}
            key={'member-'+i} margin12 padding12 borderWidth1 borderColor={COLOR.BORDER} borderRadius10
          >
            <Avatar size={80} uri={u.avatar} name={u.name} />

            <Col flex1 marginLeft16>
              <Text subtitle1>{u.name}</Text>
              {Boolean(u.isAdmin) && <Text bold colorMain>Admin</Text>}
            </Col>
          </Row>  
        ))}
      </Row>
    </Col>
  );
}

const GroupDetailUser = (props : IGroupDetailChildrenProps) => {
  return (
    <ListWithFilter list={props.groupFeedData.detail.members} navigation={props.navigation} />
  );
};

export default GroupDetailUser;