import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { IUser, ISearchAndFilterOptions } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';

interface ISetters {
  [additionSetter: string]: (v : any) => void,
}

class ViolationReport extends PersistReady {

  constructor() {
    super();
  }

  state = {
    
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {

    return [
      {  },
      {
        list: this.list,
        update: this.update,
        detail: this.detail,
      }
    ];
  }

  list = async ({ page = 1, pageSize = 20, options, includeMyReviews } : { page: number, pageSize?: number, options?: ISearchAndFilterOptions, includeMyReviews?: boolean }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/violation-report/list', { page, pageSize, options, includeMyReviews });
      return res.data;
    });
  }

  update = async (data) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/violation-report/update', data);
      return res.data;
    });
  }

  detail = async (id) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/violation-report/'+id, {});
      return res.data;
    });
  }
}

export default new ViolationReport();
