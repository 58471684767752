import React, { useState } from 'react';
import { Row, Col, Text, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ERROR, useFontSizeStyle } from 'const';
import { useNavigation } from '@react-navigation/native';
import { SCREEN_NAME, DEVICE_INFO, COLOR } from 'const';
import { ICON, IMAGE } from 'assets';
import { Button, Checkbox, Input } from 'components';
import { StyleSheet } from 'react-native';
import { Reset } from 'navigation';
import Store from 'store';
import * as Animatable from 'react-native-animatable';

const AccountVerifyNotice = () => {
  const rV = useDynamicResponsiveValue();
  const navigation = useNavigation();
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [{ user, token }, userApi] = Store.User.createUserStore();

  const reset = (screenName) => {
    navigation.reset({ index: 0, routes: [{ name: screenName }] })
  };

  const sendVerifyCode = async () => {
    setLoading(true);
    const [res, err] = await userApi.sendVerifyCode();
    setLoading(false);
    if (err) return setErrorMessage(ERROR.parseError(err));
    if (res.error) return setErrorMessage(ERROR.parseError(res.error));
    setShowCodeForm(true);
  };

  const useCodeToVerify = async () => {
    if (!code) return setErrorMessage(ERROR.co_fill_all_form);
    setLoading(true);
    const [res, err] = await userApi.useVerifyCode(code);
    setLoading(false);
    if (err) return setErrorMessage(ERROR.parseError(err));
    if (res.error) return setErrorMessage(ERROR.parseError(res.error));
  }

  console.log('user', user);

  if (!!user.type && user.type !== 'UNVERIFY-USER') {
    return (
      <Col middle flex1 bgWhite>
        <Text>Bạn đã xác thực tài khoản..</Text>
        <Reset to={SCREEN_NAME.Home} timeout={1500} />
      </Col>
    );
  }
  
  return (
    <Animatable.View
      animation="fadeIn"
      duration={300}
      style={styles.container}
    >
      <Animatable.View
        animation="pulse"
        duration={300}
        delay={200}
      >
        <Col
          width={rV({ xs: 320, lg: 364 })}
          bgWhite borderRadius8 shadow middle
          padding24
        >
          <Img source={IMAGE.CheckMailBig} style={styles.email} resizeMode="contain" />

          <Text marginTop24 h6>Xác thực tài khoản qua email</Text>
          <Text marginTop24 body1 center colorMain bold>{user.email}</Text>
          <Text marginTop16 body1 center>Xác thực tài khoản để có thể sử dụng đủ tính năng hấp dẫn từ Readism.</Text>

          {Boolean(errorMessage) && <Text marginTop32 body1 center color={COLOR.DANGER_500}>{errorMessage}</Text>}

          {Boolean(showCodeForm) && (
            <Col width100p marginTop12>
              <Input
                label=""
                placeholder="Nhập mã xác thực"
                value={code}
                onChange={setCode}
                rightIcon={{
                  comp: <Text fontSize10 colorMain>Gửi lại</Text>,
                  onPress: sendVerifyCode,
                }}
              />
            </Col>
          )}

          <Button
            width100p marginTop={errorMessage || showCodeForm ? 12 : 45}
            text={showCodeForm ? "Xác thực" : "Gửi mã xác thực tài khoản"}
            solid large
            loading={loading}
            onPress={showCodeForm ? useCodeToVerify : sendVerifyCode}
          />

          <Col padding8 marginTop12 onPress={() => reset(SCREEN_NAME.Home)}>
            <Text colorMain btnTextMedium>Bỏ qua</Text>
          </Col>
        </Col>
      </Animatable.View>
    </Animatable.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.GRAY_300,
  },
  email: {
    width: 256,
    height: 170,
  },
});

export default AccountVerifyNotice;