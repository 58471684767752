import React, { useState, useEffect, useCallback } from 'react';
import { Col, Text, usePropsStyle } from 'react-quick-style-components';
import { LinearGradient } from 'expo-linear-gradient';

interface ICollapsableText {
  expandMaxHeight: number,
  children?: any,
  customExpandOnPress?: any,
  useForOtherComponent?: boolean,
  [anyProp: string]: any,
}

const CollapsableText = (props: ICollapsableText) => {
  const [collapsed, setCollapsed] = useState({ force: false, value: false });
  const [styles, setStyles] = useState<{ container: any, text: any }>({
    container: {},
    text: {},
  })

  const propStyle = usePropsStyle(props);

  useEffect(() => {
    const { forTextStyle, forContainerStyle } = (() => {
      const forTextStyle = {};
      const forContainerStyle = {};
      for (let key in propStyle[0]) {
        if (key.includes('margin')) {
          forContainerStyle[key] = propStyle[0][key];
        } else {
          forTextStyle[key] = propStyle[0][key];
        }
      }
      return { forTextStyle, forContainerStyle }
    })();
    setStyles({
      text: forTextStyle,
      container: forContainerStyle,
    });
  }, [propStyle]);

  const onLayout = useCallback((e) => {
    if (collapsed.force) return;
    const { nativeEvent } = e;
    const { height } = nativeEvent.layout;
    if (height > props.expandMaxHeight) {
      setCollapsed({ value: true, force: false });
    }
  }, [props.expandMaxHeight, collapsed.force]);

  return (
    <Col style={[styles.container, collapsed.value ? { height: props.expandMaxHeight } : undefined]} onLayout={onLayout}>
      {Boolean(collapsed.value) ? (
        <>
          <Col flex1 overflow="hidden">
            {Boolean(props.useForOtherComponent) ? props.children : <Text style={styles.text}>{props.children}</Text>}
            <Col absoluteFill>
              <LinearGradient
                colors={['#fff', 'rgba(255,255,255,0)']}
                start={{ x: 0, y: 1 }}
                end={{ x: 0, y: 0 }}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                }}
              >

              </LinearGradient>
            </Col>
          </Col>
          <Col onPress={!!props.customExpandOnPress ? props.customExpandOnPress : () => setCollapsed({ value: false, force: true })}>
            <Text bold fontSize14 color="rgba(0,0,0,0.7)" colorMain>Xem thêm</Text>
          </Col>
        </>
      ) : (
        <>
          {Boolean(props.useForOtherComponent) ? props.children : <Text style={propStyle}>{props.children}</Text>}
          {Boolean(collapsed.force) && (
            <Col onPress={() => setCollapsed({ value: true, force: false })} marginTop4>
              <Text bold fontSize14 color="rgba(0,0,0,0.7)" colorMain>Thu gọn</Text>
            </Col>
          )}
        </>
      )}
    </Col>
  );
};

export default CollapsableText;