import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { IPost, IComment } from 'type';
import Store from 'store';
import { COLOR, DEVICE_INFO, POST_VISIBILITY, SCREEN_NAME, ERROR } from 'const';
import { Avatar, Popup, showModal } from 'components/elements';
import { useNavFunc, genDetailLink } from 'navigation';
import { StyleSheet, TextInput, useWindowDimensions, Platform } from 'react-native';
import { TimeHelper, VarHelper } from 'helpers';
import { Mention, MentionsInput } from 'react-mentions';

import ListLikeModal from '../ListLikeModal';
import UserName from '../UserName';
import SingleComment from './SingleComment';
import { useShareModal } from 'components';
import { useRefState2 } from 'components/hooks';

const TEXTINPUT_VERTICAL_PADDING = 13;

const CommentForm = ({ onCommentFormLayout, tempComment, setTempComment, sendComment, user, listUserForMention }) => {
  const mentionInputRef = useRef(null);
  const tempCommentValueRef = useRef(tempComment);

  useEffect(() => {
    tempCommentValueRef.current = tempComment;
  }, [tempComment]);

  const onAdd = (a) => {
    // setTimeout(() => {
    //   setTempComment(v => v + ' ');
    // }, 300);
  }

  const handleSendComment = () => {
    setTimeout(() => {
      let value : string = tempCommentValueRef.current;
      if (value.endsWith('\n')) value = value.substring(0, value.length - 1);
      sendComment(value);
    }, 200);
  };

  useEffect(() => {
    if (!mentionInputRef.current) return;
    const handleEnter = (event) => {
      if (event.keyCode == 13 && !event.shiftKey) {
        handleSendComment();
      }
    }
    mentionInputRef.current.addEventListener("keydown", handleEnter);

    return () => {
      if (!mentionInputRef.current) return;
      mentionInputRef.current.removeEventListener("keydown", handleEnter);
    }
  }, []);

  return (
    <Col
      paddingHorizontal24 paddingVertical16 backgroundColor={COLOR.GRAY_100} onLayout={onCommentFormLayout}
    >
      <Row alignItems='flex-start'>
        <Col width32>
          <Avatar size={32} name={user.displayName} uri={user.photoUrl} />
        </Col>
        <Col flex1 marginLeft16>
          <MentionsInput
            value={tempComment}
            onChange={e => setTempComment(e.target.value)}
            style={defaultStyle}
            placeholder={"Bình luận, dùng '@' để nhắc đến bạn bè hoặc người bình luận"}
            a11ySuggestionsListLabel={"Suggested mentions"}
            inputRef={mentionInputRef}
          >
            <Mention
              markup="@[__display__](user:__id__)"
              trigger="@"
              data={listUserForMention}
              appendSpaceOnAdd
              renderSuggestion={(
                suggestion,
                search,
                highlightedDisplay,
                index,
                focused
              ) => (
                <div className={`user ${focused ? 'focused' : ''}`}>
                  {highlightedDisplay}
                </div>
              )}
              onAdd={onAdd}
              style={defaultMentionStyle}
            />
          </MentionsInput>
        </Col>
      </Row>
      <Col alignItems="flex-end" marginTop8>
        <Text fontSize10 color={COLOR.GRAY_500}>Shift + Enter để xuống dòng</Text>
      </Col>
    </Col>
  );
};

const styles = StyleSheet.create({
  commentInput: {
    paddingVertical: TEXTINPUT_VERTICAL_PADDING,
    paddingHorizontal: 16,
    backgroundColor: 'white',
    borderRadius: 6,
  },
});

const defaultStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    zIndex: 1000000,
    overflowY: 'auto',
    height: 100,
    position: 'relative',

    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

const defaultMentionStyle = {
  backgroundColor: COLOR.PRI_300,
  marginRight: 2,
};

export default CommentForm;