import React from 'react';
import { Col, usePropsStyle } from 'react-quick-style-components';

const FacebookLikeCommentButton = (props) => {
  // const url = 'https://readism.vn/tin-tuc/734421823410/cuoc-thi-viet-o-nha-doc-sch-thu-thch-review';
  const url = window.location.href;
  return (
    <Col {...props}>
      <div
        dangerouslySetInnerHTML={{ __html : `
          <div class="fb-like" data-href="${url}" data-width="300" data-layout="button_count" data-action="like" data-size="small" data-share="true"></div>
        `}}
      />
    </Col>
  )
};

export default FacebookLikeCommentButton;