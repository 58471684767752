import React from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue, usePropsStyle } from 'react-quick-style-components';
import { IReview } from 'type';
import { useNavFunc } from 'navigation';
import ReviewItem from './ReviewItem';
import LikeComments from '../LikeComments/LikeComments';

interface IReviewItemPublicProps {
  data: IReview,
  withoutCollapse?: boolean,
  [anyProps: string]: any,
}

const ReviewItemPublic = (props : IReviewItemPublicProps) => {
  const propStyle = usePropsStyle(props);
  return (
    <ReviewItem data={props.data} withoutCollapse={props.withoutCollapse} style={propStyle}>
      <LikeComments entityData={props.data} postType='review' />
    </ReviewItem>
  );
};

export default ReviewItemPublic;