export const SCREEN_NAME = {
  Home: 'Home',
  CMSHome: 'CMSHome',
  CMSListBook: 'CMSListBook',
  CMSCreateBook: 'CMSCreateBook',
  CMSEditBook: 'CMSEditBook',
  CMSListGenre: 'CMSListGenre',
  CMSListNews: 'CMSListNews',
  CMSListUser: 'CMSListUser',
  CMSListReport: 'CMSListReport',
  CMSListReview: 'CMSListReview',
  CMSEditReview: 'CMSEditReview',
  CMSCreateNews: 'CMSCreateNews',
  CMSEditNews: 'CMSEditNews',
  CMSBulkCreateBook: 'CMSBulkCreateBook',
  CMSCreateUser: 'CMSCreateUser',
  CMSEditUser: 'CMSEditUser',
  CMSEditReport: 'CMSEditReport',
  CMSDisplay: 'CMSDisplay',
  CreateGroup: 'CreateGroup',
  UpdateGroup: 'UpdateGroup',
  Login: 'Login',
  Register: 'Register',
  ForgotPassword: 'ForgotPassword',
  Terms: 'Terms',
  AccountVerifyNotice: 'AccountVerifyNotice',
  Group: 'Group',
  Quote: 'Quote',
  Writing: 'Writing',
  WritingViewAll: 'WritingViewAll',
  Manga: 'Manga',
  MangaViewAll: 'MangaViewAll',
  News: 'News',
  Settings: 'Settings',
  CreateBook: 'CreateBook',
  UseResetPasswordCode: 'UseResetPasswordCode',
  BookHomepage: 'BookHomepage',
  NewsDetail: 'NewsDetail',
  BookDetail: 'BookDetail',
  BookDetailSingleReview: 'BookDetailSingleReview',
  HomeAdminAllFeed: 'HomeAdminAllFeed',
  UserProfile: 'UserProfile',
  UserProfileAllReview: 'UserProfileAllReview',
  UserProfileSetting: 'UserProfileSetting',
  UserProfileAllWriting: 'UserProfileAllWriting',
  UserProfileAllFriends: 'UserProfileAllFriends',
  UserProfileAllBookshelf: 'UserProfileAllBookshelf',
  UserProfileBookshelfDetail: 'UserProfileBookshelfDetail',
  CreateQuote: 'CreateQuote',
  EditQuote: 'EditQuote',
  QuoteDetail: 'QuoteDetail',
  UserProfileAllQuote: 'UserProfileAllQuote',
  CreateWriting: 'CreateWriting',
  WritingDetail: 'WritingDetail',
  WritingDetailChapter: 'WritingDetailChapter',
  UpdateWriting: 'UpdateWriting',
  CreateManga: 'CreateManga',
  MangaDetail: 'MangaDetail',
  MangaDetailChapter: 'MangaDetailChapter',
  MangaDetailChapterNew: 'MangaDetailChapterNew',
  UpdateManga: 'UpdateManga',
  GroupDetail: 'GroupDetail',
  GroupDetailEditPost: 'GroupDetailEditPost',
  DirectMessage: 'DirectMessage',
  DetailConversation: 'DetailConversation',
  CMSBulkCreateReview: 'CMSBulkCreateReview',
  NotificationDetail: 'NotificationDetail',
  FeedDetail: 'FeedDetail',
  EditorScreen: 'EditorScreen',
  Feedback: 'Feedback',
  UserProfileAllManga: 'UserProfileAllManga',
};

export const TAB_NAME = {
  Home: 'Tab_Home',
  News: 'Tab_News',
  Group: 'Tab_Group',
  Writing: 'Tab_Writing',
  User: 'Tab_User',
};