import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={16}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M19.2 14.174L1.68.492C1.592.434 1.475.375 1.387.375a.581.581 0 00-.381.176L.713.93a.52.52 0 00-.117.294c0 .117.088.293.205.351l17.52 13.711c.058.059.175.088.292.088a.458.458 0 00.352-.176l.293-.351a.52.52 0 00.117-.293.654.654 0 00-.176-.381zM9.296 4.682l3.955 3.076C13.193 6 11.758 4.594 10 4.594c-.205.029-.498.058-.703.088zm1.377 6.416L6.719 8.02C6.777 9.78 8.213 11.156 10 11.156c.176 0 .469-.029.674-.058zM10 3.656c2.871 0 5.537 1.612 6.943 4.219-.293.557-.85 1.348-1.289 1.787l1.114.85c.527-.557 1.2-1.524 1.552-2.198.059-.117.117-.293.117-.439 0-.117-.058-.293-.117-.41C16.738 4.359 13.574 2.25 10 2.25c-1.084 0-2.11.205-3.076.557L8.27 3.89A6.83 6.83 0 0110 3.656zm0 8.438c-2.9 0-5.566-1.612-6.973-4.219.293-.527.85-1.318 1.29-1.758l-1.114-.85c-.527.557-1.201 1.524-1.553 2.198a1.07 1.07 0 000 .85C3.232 11.42 6.396 13.5 10 13.5c1.055 0 2.08-.205 3.047-.527l-1.348-1.084a6.74 6.74 0 01-1.699.205z"
        fill="#949494"
      />
    </Svg>
  )
}

export default SvgComponent
