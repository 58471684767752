import React, { useEffect, useState } from 'react';
import { Col, Text, Row } from 'react-quick-style-components';
import { CMSLayout, Button, CMSTableLayout, Popup } from 'components';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, POST_VISIBILITY, ERROR, BOOK_VISIBILITY } from 'const';
import Store from 'store';
import { VarHelper, TimeHelper } from 'helpers';
import { INews } from 'type';

const moment = require('moment');

const CMSListNews = () => {
  const { navigation, goWithPreData } = useNavFunc();
  const [{}, nApi] = Store.News.createStore();

  return (
    <Col flex1>
      <CMSLayout title="Quản lý Tin tức">
        <Row>
          <Col flex1>
            <Text h4>TIN TỨC</Text>
          </Col>
          <Button paddingHorizontal15 small solid text="Thêm tin" onPress={() => navigation.navigate(SCREEN_NAME.CMSCreateNews)} />
        </Row>
        <Row marginTop24>
          <CMSTableLayout
            initialPage={1}
            tableSchema={[
              { title: 'Ảnh', type: 'image', key: (val) => !!val.images && val.images.length > 0 ? val.images[0] : '', valueStyle: { width: 50, height: 50 * 256 / 160 } },
              { title: 'Tiêu đề', type: 'text', key: 'name', width: 270 },
              { title: 'Tác giả', type: 'text', width: 150, key: (val) => val.createdBy?.name || '' },
              { title: 'Trạng thái', type: 'text', key: (val) => POST_VISIBILITY[val.visibility] },
              { title: 'Lần sửa cuối', type: 'text', width: 100, key: (val) => TimeHelper.fromNow(val.updatedAt) },
            ]}
            fetcher={(page) => nApi.getList({ visibility: 'all', page })}
            onRowPress={(rowData) => goWithPreData(SCREEN_NAME.CMSEditNews, { id: rowData.id, name: VarHelper.toSlug(rowData.name) }, rowData)}
            fetchWhenFocus
            remover={(id, name) => new Promise((resolve, reject) => {
              const doRemove = async () => {
                // @ts-ignore
                const [res, err] = await nApi.update({ id, visibility: 'trash' });
                if (err) alert(ERROR.parseError(err));
                else if (res.error) alert(ERROR.parseError(res.error));
                resolve(undefined);
              };
              Popup.show(
                'Bạn có chắc chắn muốn xóa? ' + name,
                [
                  { text: 'Không', solid: true, onPress: () => {} },
                  {
                    text: 'Có', solid: false, onPress: doRemove,
                  }
                ],
              );
            })}
            hasFeatured
            featuredMaker={async (id, bool) => {
              // @ts-ignore
              const [res, err] = await nApi.update({ id, isFeatured: bool });
              if (err) alert(ERROR.parseError(err));
              else if (res.error) alert(ERROR.parseError(res.error));
            }}
          />
        </Row>
      </CMSLayout>
    </Col>
  );
};

export default CMSListNews;