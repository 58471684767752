import React from 'react';
import { Col, Scroll, Row, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import Header from '../widgets/header/Header';
import MainContainer from './MainContainer';

interface ILeftSidebar272Props {
  renderHeader?: any,
  renderMainContent?: any,
  renderSidebarContent?: any,
}

const LeftSidebar272 = (props : ILeftSidebar272Props) => {
  const rV = useDynamicResponsiveValue();
  return (
    <Col flex1>
      {Boolean(props.renderHeader) ? props.renderHeader() : <Header />}
      <Col marginTop={rV({ xs: 16, lg: 32 })} flex1>
        <MainContainer flex1>
          <Row
            flex1
            alignContent="flex-start"
            alignItems="stretch"
            xs='100%'
            lg='272px:1'
          >
            <Col
              marginHorizontal={rV({ xs: 0, lg: 0 })}
              marginBottom={rV({ xs: 16, lg: 0 })}
            >
              {Boolean(props.renderSidebarContent) && props.renderSidebarContent()}
            </Col>
            <Col
              bgWhite paddingVertical16 paddingHorizontal24
              // marginHorizontal={rV({ xs: 0, lg: 0 })}
            >
              {Boolean(props.renderMainContent) && props.renderMainContent()}
            </Col>
          </Row>
        </MainContainer>
      </Col>
    </Col>
  );
};

export default LeftSidebar272;
