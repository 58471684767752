import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { MainContainer, Header, FooterInfo, RatioCol, RateStars, ReviewItem, ReviewItemPublic, GiveAReview, LikeComments, CollapsableText } from 'components';
import { IMAGE, ICON } from 'assets';
import { STYLES, useFontSizeStyle, SCREEN_NAME, ERROR, fSize, COLOR } from 'const';
import { useNavFunc, Reset, getScreenPreData } from 'navigation';
import { IBook, IReview } from 'type';
import Store from 'store';
import { VarHelper } from 'helpers';

const BookDetailSingleReview = () => {
  const rV = useDynamicResponsiveValue();
  const { route, navigate } = useNavFunc();

  // @ts-ignore
  const { bookid, id, bookname } = route.params;

  const [{ token }] = Store.User.createUserStore();
  const [{ }, bookApi] = Store.Book.createBookStore();
  const [{ }, rApi] = Store.Reviews.createStore();
  const [bookData, setBookData] = useState<IBook>();
  const [detailReview, setDetailReview] = useState<IReview>();

  const getReviewData = async () => {
    const data = getScreenPreData(id);
    if (!data || !data.id) {
      const [res, err] = await rApi.detailReview(id);
      if (err) return alert(ERROR.parseError(err));
      if (res.error) return alert(ERROR.parseError(res.error));
      setDetailReview(res.data);
    } else {
      setDetailReview(data);
    }
  };

  const getBookData = async () => {
    const [res, err] = await bookApi.detailBook(bookid);
    if (err) return alert(ERROR.parseError(err));
    if (res.error) return alert(ERROR.parseError(res.error));
    setBookData(res.data);
  };

  useEffect(() => {
    getBookData();
    getReviewData();
  }, [bookid, id]);

  if (!id) {
    return (
      <Col middle flex1 bgWhite>
        <Text>Đường dẫn không hợp lệ..</Text>
        <Reset to={SCREEN_NAME.Home} timeout={1500} />
      </Col>
    );
  };

  return (
    <Col flex1 bgWhite>
      <Header />
      <Col flex1>
        <Scroll style={{ flex: 1 }}>
          <MainContainer alignSelf="center" padding10 paddingVertical24>
            <Row alignItems="flex-start" xs='100%' md='any:any:1:any'>

              <Col width={rV({ xs: '100%', md: 145 })}>

                <RatioCol width={rV({ xs: '50%', md: '100%' })} alignSelf={rV({ xs: 'center', md: undefined })} ratio={145 / 232} marginBottom16 shadow>
                  <Img source={{ uri: !!bookData?.images && !!bookData.images[0] ? bookData.images[0] : '' }} style={STYLES.all100p} />
                </RatioCol>
              </Col>

              <Col width37 height0 />

              <Col>

                <Col onPress={() => navigate(SCREEN_NAME.BookDetail, { id: bookid, name: bookname })}>
                  <Text bold fontSize={fSize(rV, 28)} colorMain>{bookData?.name}</Text>
                  <Text bold fontSize={fSize(rV, 18)} color={COLOR.GRAY_500} marginTop8>{bookData?.authors && bookData?.authors.length > 0 ? bookData.authors[0].name : ''}</Text>
                </Col>
                
                <Col height1 backgroundColor={COLOR.BORDER} marginTop24 />

                {Boolean(!!detailReview) && (
                  <>
                    <Col marginTop24>
                      <Text bold fontSize={fSize(rV, 18)}>CHI TIẾT ĐÁNH GIÁ</Text>

                      {/* <ReviewItem data={detailReview} marginTop16 withoutCollapse>
                        <LikeComments entityData={detailReview} postType='review' />
                      </ReviewItem> */}
                      <ReviewItemPublic data={detailReview} marginTop16 withoutCollapse />
                    </Col>

                    <Col height1 backgroundColor={COLOR.BORDER} marginTop24 />
                  </>
                )}

              </Col>

              <RatioCol width={rV({ xs: '100%', md: 145 })} ratio={145 / 232} marginBottom16>
              </RatioCol>
            </Row>
          </MainContainer>

        </Scroll>
      </Col>
    </Col>
  );
};

export default BookDetailSingleReview;
