import { useRef } from 'react';

export const useRefState = <T>(initialValue : T) : [() => T, (v: T) => void] => {

  const value = useRef(initialValue);
  const getValue = () => value.current;
  const setValue = (newValue) => {
    value.current = newValue;
  }

  return [
    getValue,
    setValue,
  ];
}