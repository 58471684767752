import React, { useState, useEffect } from 'react';
import { Col, Row, Text, Scroll } from 'react-quick-style-components';
import { useWindowDimensions, Platform } from 'react-native';
import { ICON } from 'assets';
import { hideModal, Avatar, Input, Button, Popup } from 'components';
import { COLOR, ERROR } from 'const';
import Store from 'store';
import LikeComment from '../LikeComments/LikeComments';

interface ICommentModalProps {
  mangaId: string,
  mangaChapterId: string,
  id: string,
  imageUrl: string,
  navigation: any,
}

const CommentModal = (props: ICommentModalProps) => {
  const { width, height } = useWindowDimensions();
  const containerWidth = width < 768 ? width * 0.9 : width * 0.8;
  const containerHeight = width < 768 ? height * 0.8 : height * 0.7;
  const [tempComment, setTempComment] = useState('');
  const { user } = Store.User.state;
  const [{}, mApi] = Store.Manga.createStore();
  const [data, setData] = useState();

  const sendComment = async (abc) => {
    
  };

  const getData = async () => {
    const [res, err] = await mApi.extraInfoDetail(props.mangaId, props.mangaChapterId, props.id);
    if (!!res) {
      setData(res.data);
    }
  };
  
  useEffect(() => {
    getData();
  }, []);

  return (
    <Row
      height={containerHeight}
      bgWhite borderRadius10 onPress={() => { }} activeOpacity={1}
      stretch
    >
      {Boolean(width >= 768) && (
        <Col flex1>
          <img src={props.imageUrl} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </Col>
      )}
      <Col flex1 backgroundColor={COLOR.GRAY_100}>
        <Row>
          <Col flex1 />
          <Col padding8 onPress={hideModal}>
            <ICON.Close width={16} height={16} />
          </Col>
        </Row>
        <Col flex1>
          <Scroll flex1 scrollViewProps={{ contentContainerStyle: { padding: 24 } }}>
            {Boolean(data) && (
              <LikeComment
                postType='manga-chapter-extra-info'
                entityData={data}
                navigation={props.navigation}
                hideLike
                showCommentTextInputByDefault
              />
            )}
          </Scroll>

        </Col>
      </Col>
    </Row>
  );
};

export default CommentModal;
