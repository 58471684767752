import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { IUser } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';

interface ISetters {
  [additionSetter: string]: (v : any) => void,
}

class Comments extends PersistReady {

  constructor() {
    super();
  }

  state = {
    
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {

    return [
      {  },
      {
        getListAll: this.getListAll,
      }
    ];
  }

  getListAll = async ({ postId, postType }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/comments/list-all', { postId, postType });
      return res.data;
    });
  }
}

export default new Comments();
