import React from 'react';
import { RatioCol } from '../index';

interface IYoutubeEmbedProps {
  ratio: number,
  width: number | string,
  id: string,
}

const YoutubeEmbed = (props: IYoutubeEmbedProps) => {
  return (
    <RatioCol ratio={props.ratio} width={props.width}>
      <iframe
        src={`https://www.youtube.com/embed/${props.id}`}
        title="YouTube Video"
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </RatioCol>
  );
};

export default YoutubeEmbed;