import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { MainContainer, Header, RatioCol, Placeholder, BookItem } from 'components';
import { View } from 'react-native';
import { IMAGE, ICON } from 'assets';
import { useNavFunc } from 'navigation';
import { COLOR, ERROR, SCREEN_NAME, STYLES } from 'const';
import Store from 'store';
import Svg, { Path } from "react-native-svg";
import { Entypo } from '@expo/vector-icons';
import { TimeHelper, VarHelper } from 'helpers';

interface INewestReviewSectionProps {
  title: string,
  options?: any,
  fetcher?: any,
}

const NewestReviewSection = (props) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [errorMes, setErrorMes] = useState('');
  const rV = useDynamicResponsiveValue();
  const { navigation, goWithPreData } = useNavFunc();
  const [{ }, bApi] = Store.Book.createBookStore();

  const getListBook = useCallback(async () => {
    setLoading(true);
    const [res, err] = await bApi.getListNewReview();
    setLoading(false);
    if (err) return setErrorMes(ERROR.parseError(err));
    if (res && res.error) return setErrorMes(ERROR.parseError(res.error));
    if (res && res.data) {
      setList(res.data);
      if (!!errorMes) setErrorMes('');
    }
  }, [list, props.options]);

  useEffect(() => {
    getListBook();
  }, [props.options, props.title]);

  return (
    <Col bgWhite padding={rV({ xs: 12, lg: 24 })} marginTop16>
      <Text h6>Sách có đánh giá mới</Text>
      <Row xs='100%' md={`50%`} padding8 left={-8} right={-8}>
        {list.map((val, i) => (
          <BookItem
            data={val}
            key={'book-' + props.title + i}
            marginTop24
            onPress={() => {
              goWithPreData(SCREEN_NAME.BookDetail, { id: val.id, name: VarHelper.toSlug(val.name) }, val);
            }}
            onPressReview={() => {
              goWithPreData(SCREEN_NAME.BookDetail, { id: val.id, name: VarHelper.toSlug(val.name), autoReview: 1 }, val );
            }}
          />
        ))}
      </Row>
      {Boolean(loading) && (
        <Placeholder.ListBook4 width={'100%'} marginTop16 />
      )}
    </Col>
  );
};

export default NewestReviewSection;
