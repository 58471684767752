import React, { useEffect, useState, useRef } from 'react';
import { useWindowDimensions, ActivityIndicator } from 'react-native';
import { Col, Row, Text, Scroll, Img } from 'react-quick-style-components';
import { ICON } from 'assets';
import { COLOR, STYLES, ERROR } from 'const';
import { hideModal, Input, RatioCol, RateStars, Popup, YoutubeEmbed, Button, Avatar, useSubmitUIState } from '../index';
import Store from 'store';

interface IAddToBookShelfProps {
  bookId: string,
  bookName: string,
  bookImage: string,
}

const AddToBookShelfModal = (props : IAddToBookShelfProps) => {
  const { width, height } = useWindowDimensions();
  const containerWidth = width < 768 ? width * 0.9 : width / 2;
  const containerHeight = width < 768 ? height * 0.8 : height / 2;
  const [{ myBookShelfList }, bApi] = Store.Book.createBookStore();
  const [{ loading }, setUI] = useSubmitUIState();
  const [{ loading : loadingAdd, btnText, errorMes }, setUIAdd] = useSubmitUIState();
  const [activeBookShelfId, setActiveBookShelfId] = useState();

  const getData = async () => {
    setUI({ loading: true });
    await bApi.getMyBookshelfList();
    setUI({ loading: false });
  };

  useEffect(() => {
    setTimeout(() => {
      if (myBookShelfList.length === 0) getData();
    }, 500);
  }, []);

  const addBook = async (bs) => {
    setActiveBookShelfId(bs.id);
    const checkExisted = !!bs.books.find(val => val.id === props.bookId);
    if (checkExisted) {
      return setUIAdd({ errorMes: 'Sách đã có sẵn trong tủ' });
    }
    setUIAdd({ loading: true });
    const [res, err] = await Store.Book.addBookToBookShelf(bs, {
      id: props.bookId,
      name: props.bookName,
      image: props.bookImage,
    });
    didAdd.current = true;
    if (err || res.error) {
      setUIAdd({ loading: false, errorMes: ERROR.parseError(err || res.error) });
      return;
    }
    setUIAdd({ loading: false, btnText: 'Đã thêm thành công' });
  };

  const allMyBooks = (() => {
    if (myBookShelfList.length === 0) return [];
    let res = [];
    myBookShelfList.forEach(val => {
      res = [...res, ...val.books];
    });
    return res;
  })();

  const didAdd = useRef(false);
  const isAlreadyInShelf = didAdd.current ? false : allMyBooks.length === 0 ? false : !!allMyBooks.find(val => val.id === props.bookId);

  return (
    <Col width={containerWidth} height={containerHeight} bgWhite borderRadius10 onPress={() => { }}>
      <Scroll width={containerWidth} height={containerHeight} padding24 borderRadius10>
        <Row justifyContent="space-between">
          <Text fontSize20 bold>Thêm vào tủ sách</Text>
          <Col width30 height30 middle onPress={hideModal}>
            <ICON.Close fill={COLOR.GRAY_500} width={13} height={20} />
          </Col>
        </Row>

        {Boolean(loading) ? (
          <Col padding12 height100 middle>
            <ActivityIndicator size='small' color={COLOR.MAIN} />
          </Col>
        ) : isAlreadyInShelf ? (
          <Col padding12 height100 middle>
            <Text>Cuốn này đã có trong tủ sách của bạn</Text>
          </Col>
        ) : (
          <Col padding12>
            {myBookShelfList.filter(val => val.name !== 'Sách đóng góp')?.map((bs, i) => (
              <Row
                key={'bs-' + i} margin12 padding12 borderWidth1 borderColor={COLOR.BORDER} borderRadius10
                onHoverStyle={{ backgroundColor: COLOR.PRI_200 }}
                onPress={() => addBook(bs)}
              >
                <RatioCol ratio={65/104} width={65}>
                  <Img
                    source={{ uri: bs.books.length === 0 ? '' : (bs.books[bs.books.length - 1].image || '') }}
                    style={STYLES.all100p}
                  />
                </RatioCol>
                <Row flex1 onResponsiveStyle={{ xs: { flexDirection: 'column' }, md: { flexDirection: 'row' } }}>
                  <Col flex1 marginLeft16>
                    <Text
                      subtitle1
                      onResponsiveStyle={{ xs: { textAlign: 'center' }, md: { flexDirection: 'center' } }}
                    >{bs.name}</Text>
                    <Text body1>{bs.books.length} cuốn</Text>
                  </Col>
                  {Boolean(activeBookShelfId === bs.id) && (
                    <Col
                      maxWidth={140} alignItems="flex-end"
                      onResponsiveStyle={{ xs: { marginLeft: 16 }, md: { marginLeft: 0 } }}
                    >
                      {Boolean(loadingAdd) ? (
                        <ActivityIndicator size='small' color={COLOR.MAIN} />
                      ) : Boolean(btnText) ? (
                        <Text body2 bold colorMain center>{btnText}</Text>
                      ) : Boolean(errorMes) ? (
                        <Text body2 bold color={COLOR.DANGER_500} center>{errorMes}</Text>
                      ) : null}
                    </Col>
                  )}
                </Row>
              </Row>
            ))}
          </Col>
        )}
      </Scroll>
    </Col>
  );
};

export default AddToBookShelfModal;