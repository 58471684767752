import { RatioCol } from 'components/elements';
import { COLOR, STYLES } from 'const';
import React from 'react';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { RateStars, showModal } from 'components';
import { IBook } from 'type';
import AddToBookshelfModal from '../AddToBookshelfModal';
import { ICON } from 'assets';
interface IBookItemProps {
  data: IBook,
  onPress?: any,
  onPressReview?: any,
  [anyProps: string]: any,
}

const BookItem = (props : IBookItemProps) => {
  const propStyle = usePropsStyle(props);
  const { data } = props;

  const bookImage = data?.images ? data?.images[0] : '';

  const openBookShelfModal = () => {
    showModal({
      component: (
        <AddToBookshelfModal bookId={data.id} bookName={data.name} bookImage={bookImage} />
      )
    })
  }
  
  return (
    <Row style={propStyle} alignItems="flex-start" height160>
      <RatioCol ratio={100/160} width={100} shadow onPress={props.onPress}>
        <Img source={{ uri: bookImage }} style={STYLES.all100p} />
      </RatioCol>
      <Col flex1 paddingHorizontal16 height160>
        <Col onPress={props.onPress} marginBottom10>
          <Text bold subtitle1 numberOfLines={2} >{data.name}</Text>
        </Col>
        {Boolean(!!data.authors && !!data.authors[0]) && <Text caption marginBottom10>{data.authors[0].name}</Text>}
        <Row>
          <RateStars
            width86 height12 activeColor={COLOR.WARNING_400} inactiveColor={COLOR.WARNING_400}
            value={Math.round(data.averageRating)}
            starSize={{ width: 14, height: 12 }}
            onChange={() => {}}
            outline
          />
          <Text subtitle2 marginLeft8>{String(data.averageRating || '0.0')}</Text>
          <Text caption color={COLOR.GRAY_500} marginLeft16>({String(data.numberOfReview || 0)} đánh giá)</Text>
        </Row>

        <Row marginTop26>
          <Col middle bgMain padding8 borderRadius4 marginRight8 onPress={openBookShelfModal}>
            <ICON.Plus fill="white" width={16} height={16} />
          </Col>
          <Col borderRadius4 bgMain middle width142 padding8 onPress={props.onPressReview}>
            <Text colorWhite btnTextSmall>Đánh giá sách</Text>
          </Col>
        </Row>
        
      </Col>
    </Row>
  );
};

export default BookItem;
