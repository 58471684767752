import GlobalEvent from "js-events-listener";
import firebase from "firebase";

class EventHelper {

  // this should fire only once
  initWindowGlobalListener() {
    function handleScroll(e) {
      GlobalEvent.emit('WINDOW_SCROLL', e);
    }
    window.addEventListener('scroll', handleScroll);
  }

  logEvent(eventName, data) {
    const analytics = window.analytics || firebase.analytics();
    analytics?.logEvent(eventName, data);
  }
  setCurrentScreen(name) {
    const analytics = window.analytics || firebase.analytics();
    analytics?.setCurrentScreen(name);
  }
  setUserId(id) {
    try {
      const analytics = window.analytics || firebase.analytics();
      analytics?.setUserId(id);
    } catch(err) {}
  }
}

export default new EventHelper();
