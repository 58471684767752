import React, { useEffect, useState, useMemo } from 'react';
import { Col, Text, Row, Scroll } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { useNavFunc, Reset } from 'navigation';
import { SCREEN_NAME } from 'const';
import { Header, Button, LeftSidebarOneThird, Avatar, Placeholder, ReviewItemPublic, FeedItem, usePreDataOrFetchApi, LoadMoreDetect, Input, showModal } from 'components';
import { ICON } from 'assets';
import Store from 'store';
import { IActivity, IUser } from 'type';
import DeleteAccountModal from './UserProfileSetting.DeleteAccountModal';
import { VarHelper } from 'helpers';

const UserProfileSetting = () => {
  const { route, goBackOrTo, navigation, reset } = useNavFunc();
  // @ts-ignore
  const { tab } = route.params;
  const [{ user }, uApi] = Store.User.createUserStore();
  // const [userData] = usePreDataOrFetchApi<IUser>(id, 'user');
  
  const [email] = useState(user.email || '');
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [bio, setBio] = useState(user.bio || '');
  const [website, setWebsite] = useState(user.website || '');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const [errorMes, setErroMes] = useState('');
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState('');

  const borderStyle = { borderTopLeftRadius: 4, borderTopRightRadius: 4 };

  const submitUpdateInfo = async () => {
    if (!firstName || !lastName) return setErroMes(ERROR.co_fill_all_required_form);
    setLoading(true);
    const [res, err] = await uApi.update({
      id: user.id, firstName, lastName, bio, website: VarHelper.removeHttpFromWebsiteLink(website),
      displayName: `${lastName} ${firstName}`,
    } as IUser);
    setLoading(false);
    if (err) return setErroMes(ERROR.parseError(err));
    if (res.error) return setErroMes(ERROR.parseError(res.error));
    console.log(res.data);
    if (res.data) {
      uApi.setUser(res.data);
    }
    if (!!errorMes) setErroMes('');
    setBtnText('Thành công');
    setTimeout(() => {
      setBtnText('');
    }, 1500);
  };

  const submitChangePassword = async () => {
    if (!newPassword || !newPasswordConfirm) return setErroMes(ERROR.co_fill_all_required_form);
    if (newPassword !== newPasswordConfirm) return setErroMes(ERROR.auth_password_not_match);
    setLoading(true);
    const [res, err] = await uApi.changePassword({
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
    setLoading(false);
    if (err) return setErroMes(ERROR.parseError(err));
    if (res.error) return setErroMes(ERROR.parseError(res.error));
    console.log(res.data);
    if (!!errorMes) setErroMes('');
    setBtnText('Thành công');
    setTimeout(() => {
      setBtnText('');
    }, 1500);
  }

  const openDeleteAccountPopup = async () => {
    showModal({
      component: <DeleteAccountModal onDone={() => reset(SCREEN_NAME.Home)} />,
    })
  };

  const renderBasicInfo = () => {
    return (
      <Col >
        <Col bgWhite padding24 marginTop36 {...borderStyle}>
          <Text h6>Thông tin cơ bản</Text>
          <Col marginTop40>
            <Input marginBottom24 required flex1 label="Email" value={email} onChange={() => {}} inputProps={{ editable: false }} placeholder="" />
            <Row xs='100%' md='1:any:1'>
              <Input marginBottom12 required flex1 label="Họ" value={lastName} onChange={setLastName} placeholder="" />
              <Col width24 />
              <Input marginBottom12 required flex1 label="Tên" value={firstName} onChange={setFirstName} placeholder="" />
            </Row>
            <Input label="Mô tả" value={bio} onChange={setBio} marginTop12 inputStyle={{ padding: 12 }} multiline placeholder="Mô tả về bạn" />
            {Boolean(user.type === 'AUTHOR') && <Input label="Website" value={website} onChange={setWebsite} marginTop24 placeholder="" />}
          </Col>
        </Col>
        <Col alignItems="flex-end" marginTop24>
          {!!errorMes && <Text textAlign="right" color={COLOR.DANGER_500} marginBottom4>{errorMes}</Text>}
          <Button onPress={submitUpdateInfo} text={btnText || "Lưu thông tin"} large solid width170 loading={loading}  />
        </Col>
      </Col>
    );
  }

  const renderChangePassword = () => {
    return (
      <Col >
        <Col bgWhite padding24 marginTop36 {...borderStyle}>
          <Text h6>Đổi mật khẩu</Text>
          <Col marginTop40 width="100%">
            <Input.Password label="Mật khẩu cũ" value={currentPassword} onChange={setCurrentPassword} placeholder="Bỏ trống nếu chưa có mật khẩu (đăng nhập qua Facebook, Google)" />
            <Input.Password label="Mật khẩu mới" value={newPassword} onChange={setNewPassword} marginTop24 placeholder="" />
            <Input.Password label="Nhập lại Mật khẩu mới" value={newPasswordConfirm} onChange={setNewPasswordConfirm} marginTop24 placeholder="" />
          </Col>
        </Col>
        <Col alignItems="flex-end" marginTop24>
          {!!errorMes && <Text textAlign="right" color={COLOR.DANGER_500} marginBottom4>{errorMes}</Text>}
          <Button onPress={submitChangePassword} text={btnText || "Đổi mật khẩu"} large solid width170 loading={loading}  />
        </Col>
      </Col>
    );
  }

  const renderDangerZone = () => {
    return (
      <Col bgWhite padding24 marginTop36 {...borderStyle}>
        <Text h6 color={COLOR.DANGER_500}>Danger Zone</Text>
        <Col marginTop40>
          <Button onPress={openDeleteAccountPopup} text={btnText || "Xóa tài khoản"} large solid width170 backgroundcolor={COLOR.DANGER_500} loading={loading}  />
        </Col>
      </Col>
    );
  }

  const TABS = [
    { label: 'Thông tin cơ bản', key: 'BASIC', slug: 'co-ban', renderer: renderBasicInfo },
    { label: 'Đổi mật khẩu', key: 'CHANGE_PASSWORD', slug: 'doi-mat-khau', renderer: renderChangePassword },
    { label: 'Danger Zone', color: 'red', key: 'DANGER_ZONE', slug: 'danger-zone', renderer: renderDangerZone },
  ];

  const changeTab = ({ slug }) => {
    navigation.navigate(SCREEN_NAME.UserProfileSetting, { tab: slug });
  }

  if (!user.id) {
    return (
      <Col flex1 middle>
        <Text>Bạn cần đăng nhập</Text>
        <Reset to={SCREEN_NAME.Login} />
      </Col>
    )
  }

  return (
    <LeftSidebarOneThird
      renderSidebarContent={() => {
        return useMemo(() => (
          <Col>
            <Text h5>Cài đặt tài khoản</Text>

            <Col marginTop12 bgWhite {...borderStyle} paddingVertical12>
              {TABS.map((val, i) => {
                const isActive = tab === val.slug;
                const colorActive = val.color || COLOR.MAIN;
                return (
                  <Row
                    key={'tab-'+i}
                    onPress={() => changeTab(val)}
                    onHoverStyle={{ opacity: 1 }} useNestedHover paddingHorizontal24 paddingVertical12
                  >
                    <Text h6 color={val.color || (isActive ? colorActive : COLOR.GRAY_500)} onHoverStyle={{ color: colorActive }}>{val.label}</Text>
                  </Row>
                );
              })}
            </Col>
          </Col>
        ), [tab])
      }}
      renderMainContent={() => {
        const findTab = TABS.find(val => val.slug === tab);
        if (!findTab) return null;
        return findTab.renderer();
      }}
    />
  );
};

export default UserProfileSetting;