import AsyncStorage from '@react-native-async-storage/async-storage';
import CryptoJS from 'crypto-js';
import GlobalEvent from 'js-events-listener';

const md5 = require('md5');

const clientEncode = (string) => {
  return CryptoJS.AES.encrypt(string, 'f1f9b8c8580a69273737a153602de6c9c97ed95d095d7bf734124a9c567a7871').toString();
};

const clientDecode = (string) => {
  return CryptoJS.AES.decrypt(string, 'f1f9b8c8580a69273737a153602de6c9c97ed95d095d7bf734124a9c567a7871').toString(CryptoJS.enc.Utf8);
};

if (typeof window === 'undefined') {
  var window : any = {
    localStorage: {
      getItem: () => '',
      setItem: () => undefined,
    },
  };
}

class StorageDependency {

  // lib = !window ? {
  //   getItem: () => '',
  //   setItem: () => undefined,
  // } : localStorage

  lib = AsyncStorage

}

export const storageDependency = new StorageDependency();

class Persist {

  save = async (value, asyncStoreItem) => {
    if (!storageDependency.lib) return;
    if (value === undefined || value === null) return;
    const valueType = typeof value;
    const stringValue = valueType !== 'object' ? String(value) : JSON.stringify(value);
    const oldDecyptedValue = await storageDependency.lib.getItem(md5(asyncStoreItem));
    if (oldDecyptedValue !== null) {
      const oldValue = clientDecode(oldDecyptedValue);
      if (oldValue === stringValue) return;
    }
    await storageDependency.lib.setItem(md5(asyncStoreItem), clientEncode(stringValue));
    storageDependency.lib.setItem('last_updated', String(new Date().getTime()));
  }

  sync = async (asyncStoreItem, type) => {
    let value = await storageDependency.lib.getItem(md5(asyncStoreItem));
    if (value == null) {
      return undefined;
    }
    value = clientDecode(value);
    if (type === 'string') return value;
    if (type === 'number') return Number(value);
    if (type === 'object' || type === 'array') {
      try {
        let parsed = JSON.parse(value);
        return parsed;
      } catch (err) {
        return undefined;
      }
    }
    if (type === 'boolean') return value === 'true';
  }
}

export class PersistReady {
  id = Math.floor(Math.random() * 1000000) + '' + Math.floor(Math.random() * 1000000);

  ready = false;

  makeReady = ( ) => {
    this.ready = true;
    GlobalEvent.emit("STORE_READY_" + this.id);
  }
  onReady = (callback = undefined) => new Promise((resolve, reject) => {
    const run = () => {
      typeof callback === 'function' && callback();
      resolve(undefined);
    }
    if (this.ready) run();
    else GlobalEvent.on("STORE_READY_" + this.id, run);
  });
}

export default new Persist();
