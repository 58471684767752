import React, { useEffect, useState } from 'react';
import { Col, Text, Row } from 'react-quick-style-components';
import { CMSLayout, useCMSNavigation } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR, DEVICE_INFO, SCREEN_NAME } from 'const';
import { Ionicons, Entypo, MaterialIcons, MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons';
import Store from 'store';
import {
  LineChart,
  BarChart,
  PieChart,
  ProgressChart,
  ContributionGraph,
  StackedBarChart
} from "react-native-chart-kit";
import { TimeHelper } from 'helpers';

const CMSHome = () => {
  const navigation = useCMSNavigation();
  const [{}, dApi] = Store.Dashboard.createStore();
  const [chartWidth, setChartWidth] = useState(300);
  const [stats, setStats] = useState({
    countBook: 0,
    countReview: 0,
    countWriting: 0,
    countQuote: 0,
    countGroup: 0,
    countReport: 0,
    countNews: 0,
    countUser: 0,
    bookChart: [],
    reviewChart: [],
  });

  const getData = async () => {
    const [res, err] = await dApi.getStats();
    if (!!res && !!res.data) {
      setStats(res.data)
    }
  }

  const updateChartWidth = e => {
    const { layout } = e.nativeEvent;
    setChartWidth(layout.width);
  }

  useEffect(() => {
    getData();
  }, []);

  const renderItem = ({ icon: IconComponent, text, onPress }) => {
    return (
      <Col
        borderRadius8 borderColor={COLOR.BORDER} borderWidth1 margin12 padding12 middle
        onHoverStyle={{ borderColor: COLOR.MAIN }} useNestedHover
        onPress={onPress}
      > 
        <Col marginTop16>
          <IconComponent color={COLOR.MAIN} />
        </Col>
        <Col marginVertical16>
          <Text subtitle1 onHoverStyle={{ color: COLOR.MAIN }}>{text}</Text>
        </Col>
      </Col>
    )
  }

  const chartConfig = {
    backgroundColor: '#ffffff',
    backgroundGradientFrom: '#ffffff',
    backgroundGradientTo: '#ffffff',
    color: (opacity = 1) => `rgba(0,0,0, ${opacity})`,
    strokeWidth: 2, // optional, default 3
    barPercentage: 0.5,
    useShadowColorFromDataset: false // optional
  }; 

  return (
    <Col flex1>
      <CMSLayout nav={navigation.setNavigation}>
        <Row xs='50%' lg={`${100/3}%`} padding12 marginLeft={-12} marginRight={-12}>
          {renderItem({
            icon: ({ color }) => <Ionicons name="stats-chart" size={32} color={color} />,
            text: 'Firebase Analytics',
            onPress: () => {
              DEVICE_INFO.IS_WEB && window.open('https://console.firebase.google.com/project/mxh-sach-az/analytics/app/web:MjkwNjNmOTYtZTM3My00NmJhLThkZmItZGVmMTM1NGM2Mzlj/overview');
            },
          })}
          {renderItem({
            icon: ({ color }) => <FontAwesome5 name="exclamation" size={32} color={color} />,
            text: 'Google Sheet góp ý',
            onPress: () => {
              DEVICE_INFO.IS_WEB && window.open('https://docs.google.com/spreadsheets/d/1kbvptEbxaBsMh3yGhCk0KyPVEaiJbHJKhRwEj2vZuHw/edit?resourcekey#gid=873125058');
            },
          })}
          {renderItem({
            icon: ({ color }) => <MaterialIcons name="library-books" size={32} color={color} />,
            text: 'Số lượng sách: ' + stats.countBook,
            onPress: () => {
              navigation.navigate(SCREEN_NAME.CMSListBook);
            },
          })}
          {renderItem({
            icon: ({ color }) => <MaterialCommunityIcons name="card-account-details-star" size={32} color={color} />,
            text: 'Số lượng đánh giá: ' + stats.countReview,
            onPress: () => {
              navigation.navigate(SCREEN_NAME.CMSListReview);
            },
          })}
          {renderItem({
            icon: ({ color }) => <MaterialIcons name="rate-review" size={32} color={color} />,
            text: 'Số lượng viết: ' + stats.countWriting,
            onPress: () => {
              navigation.navigate(SCREEN_NAME.Writing);
            },
          })}
          {renderItem({
            icon: ({ color }) => <MaterialCommunityIcons name="comment-quote" size={32} color={color} />,
            text: 'Số lượng trích dẫn: ' + stats.countQuote,
            onPress: () => {
              navigation.navigate(SCREEN_NAME.Quote);
            },
          })}
          {renderItem({
            icon: ({ color }) => <Ionicons name="newspaper" size={32} color={color} />,
            text: 'Số lượng tin tức: ' + stats.countNews,
            onPress: () => {
              navigation.navigate(SCREEN_NAME.Quote);
            },
          })}
          {renderItem({
            icon: ({ color }) => <Entypo name="user" size={24} color={color} />,
            text: 'Số lượng người dùng: ' + stats.countUser,
            onPress: () => {
              navigation.navigate(SCREEN_NAME.CMSListUser);
            },
          })}
          {renderItem({
            icon: ({ color }) => <MaterialIcons name="groups" size={32} color={color} />,
            text: 'Số lượng hội nhóm: ' + stats.countGroup,
            onPress: () => {
              navigation.navigate(SCREEN_NAME.Group);
            },
          })}
          {renderItem({
            icon: ({ color }) => <MaterialIcons name="report" size={32} color={color} />,
            text: 'Vi phạm chờ duyệt: ' + stats.countReport,
            onPress: () => {
              navigation.navigate(SCREEN_NAME.CMSListReport);
            },
          })}
        </Row>

        <Col marginTop24 onLayout={updateChartWidth}>
          <LineChart
            data={{
              labels: stats.bookChart.map(val => TimeHelper.format(val.week, 'DD-MM-YYYY')),
              datasets: [
                {
                  data: stats.bookChart.map(val => +val.count),
                  color: (opacity = 1) => `rgba(134, 65, 244, ${opacity})`, // optional
                  strokeWidth: 2 // optional
                }
              ],
              legend: ["Sách"] // optional
            }}
            width={chartWidth}
            height={300}
            chartConfig={chartConfig}
            bezier
          />

          <Col marginVertical24 />

          <LineChart
            data={{
              labels: stats.reviewChart.map(val => TimeHelper.format(val.week, 'DD-MM-YYYY')),
              datasets: [
                {
                  data: stats.reviewChart.map(val => +val.count),
                  color: (opacity = 1) => `rgba(158, 64, 30, ${opacity})`, // optional
                  strokeWidth: 2 // optional
                }
              ],
              legend: ["Đánh giá"] // optional
            }}
            width={chartWidth}
            height={300}
            chartConfig={chartConfig}
            bezier
          />
        </Col>
      </CMSLayout>
    </Col>
  );
};

export default CMSHome;