import React, { useState, useCallback, useEffect } from 'react';
import { TextInput, StyleSheet } from 'react-native';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import Store from 'store';
import { useNavFunc, Reset } from 'navigation';
import { Header, MainContainer, LeftSidebarOneThird, Input, DirectMessageLayout, Button, usePreDataOrFetchApi, useSubmitUIState, Select } from 'components';
import { COLOR, ERROR, SCREEN_NAME } from 'const';
import { ICON } from 'assets';
import { IUser } from 'type';

const NewMessage = ({ route, navigation, mode }) => {
  const { reset, navigate } = useNavFunc();
  const [{ user, friends }, uApi] = Store.User.createUserStore();
  const [{  }, mApi] = Store.Message.createStore();

  const { userId : newUserId } = route?.params || {};

  const [userSelectedValue, setUserSelectedValue] = useState<{ label: string, value: string }>();
  const [sender] = usePreDataOrFetchApi<IUser>(newUserId, 'user');
  const [content, setContent] = useState('');
  const [{ loading, errorMes }, setSubmitUI] = useSubmitUIState();

  useEffect(() => {
    if (!!newUserId && !!sender) {
      setUserSelectedValue({ label: sender.displayName, value: sender.id });
      mApi.checkConversationExists(newUserId).then((res : any) => {
        console.log('res', res);
        if (!!res && !!res[0] && !!res[0].data) {
          reset(SCREEN_NAME.DetailConversation, { conversationId: res[0].data.id });
        }
      })
    }
  }, [newUserId, sender]);

  const submit = useCallback(async () => {
    if (!content) return setSubmitUI({ errorMes: ERROR.co_fill_all_required_form });
    setSubmitUI({ loading: true });
    const [res, err] = await mApi.createNewConversation({
      otherUserId: sender.id,
      message: {
        content,
      }
    });
    if (err) return setSubmitUI({ loading: false, errorMes: ERROR.parseError(err) });
    if (res.error) return setSubmitUI({ loading: false, errorMes: ERROR.parseError(res.error) });
    setSubmitUI({ loading: false });
    reset(SCREEN_NAME.DetailConversation, { conversationId: res.data.id });
  }, [content, sender]);

  return (
    <Col>
      <Text h5>Tin nhắn mới</Text>

      <Col marginTop24 bgWhite borderRadius4 borderWidth1 borderColor={COLOR.BORDER} padding24>
        <Col>
          <Row height44 paddingVertical10 paddingLeft24 borderRadius4 borderWidth1 borderColor={COLOR.BORDER}>
            <Col width70>
              <Text>Gửi đến:</Text>
            </Col>
            {/* <TextInput
              placeholder="Tìm trong bạn bè..."
              value={nameKeyword} onChangeText={setNameKeyword}
              style={styles.input}
              editable={!newUserId}
            /> */}
            <Col flex1>
              <Select
                placeholder="Tìm trong bạn bè..."
                value={userSelectedValue}
                onChange={(newVal) => {
                  setUserSelectedValue(newVal);
                  navigate(SCREEN_NAME.DirectMessage, { mode: 'gui-tin-nhan', userId: newVal.value });
                }}
                label=""
                options={friends.map(val => ({ value: val.id, label: val.name }))}
                noBorder
                height={42}
              />
            </Col>
          </Row>
          <Input
            marginTop16
            label=""
            multiline
            inputStyle={{ padding: 24 }}
            placeholder="Viết nội dung..."
            value={content} onChange={setContent}
          />
        </Col>
        <Col marginTop12 alignItems="flex-end">
          {Boolean(errorMes) && <Text textAlign="right" color={COLOR.DANGER_500} marginBottom4>{errorMes}</Text>}
          <Button loading={loading} large solid text="Gửi tin nhắn" width170 height43 onPress={submit} />
        </Col>
      </Col>
      
    </Col>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginLeft: 12,
  },
});

export default NewMessage;
