import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { MainContainer, Header, RatioCol, Placeholder, BookItem } from 'components';
import { View } from 'react-native';
import { IMAGE, ICON } from 'assets';
import { useNavFunc } from 'navigation';
import { COLOR, ERROR, SCREEN_NAME, STYLES } from 'const';
import Store from 'store';
import Svg, { Path } from "react-native-svg";
import { Entypo } from '@expo/vector-icons';
import { TimeHelper, VarHelper } from 'helpers';

interface ITopMostReviewedSectionProps {
  title: string,
  options?: any,
  fetcher?: any,
}

const TopMostReviewedSection = (props) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [errorMes, setErrorMes] = useState('');
  const rV = useDynamicResponsiveValue();
  const { navigation, goWithPreData } = useNavFunc();
  const [{ }, bApi] = Store.Book.createBookStore();

  const getListBook = useCallback(async (page = 1) => {
    setLoading(true);
    if (page === 1) {
      setList([]);
      await TimeHelper.wait(500);
    }
    const [res, err] = await bApi.listBook({ visibility: 'public', page, pageSize: 10, options: {
      sort: [{ value: 'numberOfReview', orderType: 'DESC' }]
    } });
    setLoading(false);
    if (err) return setErrorMes(ERROR.parseError(err));
    if (res && res.error) return setErrorMes(ERROR.parseError(res.error));
    if (res && res.data && res.data.rows) {
      setList(page == 1 ? res.data.rows : [...list, ...res.data.rows]);
      setCount(res.data.count);
      setPage(page);
      if (!!errorMes) setErrorMes('');
    }
  }, [page, list, props.options]);

  const loadMore = useCallback(async () => {
    // console.log(page + 1);
    getListBook(page + 1);
  }, [page, list, count]);

  useEffect(() => {
    getListBook(1);
  }, [props.options, props.title]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getListBook(1);
    });
    return unsubscribe;
  }, []);

  return (
    <Col bgWhite padding={rV({ xs: 12, lg: 24 })} marginTop16>
      <Text h6>Sách có nhiều đánh giá</Text>
      <Row xs='100%' md={`50%`} padding8 left={-8} right={-8}>
        {list.map((val, i) => (
          <BookItem
            data={val}
            key={'book-' + props.title + i}
            marginTop24
            onPress={() => {
              goWithPreData(SCREEN_NAME.BookDetail, { id: val.id, name: VarHelper.toSlug(val.name) }, val);
            }}
            onPressReview={() => {
              goWithPreData(SCREEN_NAME.BookDetail, { id: val.id, name: VarHelper.toSlug(val.name), autoReview: 1 }, val );
            }}
          />
        ))}
      </Row>
      {Boolean(loading) && (
        <Placeholder.ListBook4 width={'100%'} marginTop16 />
      )}
      {Boolean(count > list.length) && (
        <Row marginTop24 onPress={loadMore}>
          <Text colorMain subtitle1 marginRight8>Xem thêm</Text>
          <Entypo name="chevron-down" color={COLOR.MAIN} size={14} />
        </Row>
      )}
    </Col>
  );
};

export default TopMostReviewedSection;
