import React, { useState, useEffect, useCallback } from 'react';
import { Col, Text, Scroll, Img, Row } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import { Header, Button, MainContainer, RatioCol, GroupItem, GroupDetailLayout } from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';
import { IGroupFeed } from 'type';

interface IGroupDetailChildrenProps {
  groupFeedData: IGroupFeed,
  getPage?: any,
}

const GroupDetailFeed = (props : IGroupDetailChildrenProps) => {
  return (
    <Col>
      <Text>Hello world book</Text>
    </Col>
  );
};

export default GroupDetailFeed;