import { useState, useEffect, useCallback } from 'react';
import Store from 'store';
import { IPost } from 'type/PostType';
import { VarHelper, TimeHelper } from 'helpers';
import { Popup } from '../elements';
import { DEVICE_INFO } from 'const';

interface ILikeCommentsProps {
  postType: string,
  entityData: IPost,
  [anyProp: string]: any,
}

export const useLikeCommentData = (props : ILikeCommentsProps) => {
  // const { user, token } = Store.User.state;
  const [{ user, token }] = Store.User.createUserStore();
  const uApi = Store.User;
  const [{  }, cApi] = Store.Comments.createStore();
  const [numberOfLikes, setNumberOfLikes] = useState(props.entityData?.numberOfLikes || 0);
  const [numberOfComments, setNumberOfComments] = useState(props.entityData?.numberOfComments || 0);
  const [didLike, setDidLike] = useState(!!props.entityData?.likes ? props.entityData?.likes[user.id] : false);
  const [likes, setLikes] = useState(props.entityData?.likes || {});
  
  const [listComments, setListComments] = useState(!!props.entityData?.lastComment ? [props.entityData?.lastComment] : []);

  useEffect(() => {
    setNumberOfLikes(props.entityData?.numberOfLikes || 0);
    setNumberOfComments(props.entityData?.numberOfComments || 0);
    setDidLike(!!props.entityData?.likes ? props.entityData?.likes[user.id] : false);
    setLikes(props.entityData?.likes || {});
    setListComments(!!props.entityData?.lastComment ? [props.entityData?.lastComment] : []);
  }, [props.entityData]);

  // const likeArr = Object.keys(likes).map(val => ({ id: val, liked: likes[val] })).filter(val => !!val.liked);

  const toggleLike = useCallback(async () => {
    if (!token) return Popup.showError('Bạn cần đăng nhập để thực hiện tính năng này');
    setDidLike(!didLike);
    setLikes(v => ({
      ...v,
      [user.id]: !v[user.id],
    }));
    setNumberOfLikes(v => didLike ? v - 1 : v + 1);
    const [res, err] = await uApi.like({ id: props.entityData.id, postType: props.postType });
  }, [props.entityData, didLike, token]);

  const getAllComments = async () => {
    const [res, err] = await cApi.getListAll({ postId: props.entityData.id, postType: props.postType });
    if(!!res && !!res.data) {
      setListComments(res.data);
    }
  };

  const sendComment = useCallback(async (content) => {
    const newList = listComments.slice();
    if (!content) {
      return;
    }
    const commentId = VarHelper.genId();
    const obj = {
      id: commentId,
      content,
      image: '',
      createdBy: {
        id: user.id,
        name: user.displayName,
        avatar: user.photoUrl,
        type: user.type,
      },
      createdAt: TimeHelper.format(undefined, 'YYYY-MM-DD HH:mm:ssZZ'),
    };
    console.log('obj', obj);
    newList.push(obj);
    setListComments(newList);
    setNumberOfComments(v => v + 1);

    const [res, err] = await uApi.comment({
      id: props.entityData.id,
      commentId,
      postType: props.postType,
      content,
      image: '',
      parentCommentId: '',
      browserName: DEVICE_INFO.BROWSER,
      browserId: DEVICE_INFO.BROWSER_ID,
    });
  }, [user, listComments, props.entityData, props.postType]);

  return [
    {
      numberOfLikes,
      numberOfComments,
      didLike,
      listComments,
    },
    {
      getAllComments,
      toggleLike,
      sendComment,
    }
  ];
};

