import { ICON } from 'assets';
import { COLOR } from 'const';
import React, { memo } from 'react';
import { Col } from 'react-quick-style-components';

interface ICheckboxProps {
  value: boolean,
  onChange(newValue: boolean): void,
}

const Checkbox = (props : ICheckboxProps) => {
  return (
    <Col onPress={() => props.onChange(!props.value)} width18 height18>
      {Boolean(props.value) ? (
        <ICON.CheckedBox />
      ) : (
        <Col flex1 borderRadius2 borderWidth1 borderColor={COLOR.BORDER} />
      )}
    </Col>
  );
};

export default memo(Checkbox);