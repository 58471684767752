import React, { useState, useCallback } from 'react';
import { Col, Row, Text, Scroll, Img } from 'react-quick-style-components';
import { hideModal, Input, RatioCol, RateStars } from 'components';
import AsyncSelect, { NoOptionsMessage, LoadingMessage } from 'components/elements/AsyncSelect';
import { ICON } from 'assets';
import { COLOR, STYLES } from 'const';
import { useWindowDimensions, } from 'react-native';
import Store from 'store';
import { IBook } from 'type';

interface IAddBookModalProps {
  onSelectBook(data: IBook): void,
}

const DropdownIndicator = () => null;
// const NoOptionsMessage = () => null;

const SearchBookSuggestion = ({ onSelectBook }) => {
  const [value, onChange] = useState();
  const [{}, bApi] = Store.Book.createBookStore();
  const [name, setName] = useState('');
  return (
    <AsyncSelect
      label=""
      placeholder="Nhập tìm Sách theo tên, tác giả"
      value={value}
      onChange={(data) => {
        onChange(data);
        if (!!data?.value) {
          onSelectBook(data.data);
        }
      }}
      noOptionsMessage={!!name ? undefined : () => null}
      components={{ NoOptionsMessage, DropdownIndicator, LoadingMessage }}
      defaultOptions={[]}
      onInputChange={(nameInput) => { setName(nameInput); return nameInput; }}
      loadOptions={async (value, callback) => {
        const [res, err] = await bApi.listBook({
          visibility: 'public',
          page: 1, pageSize: 20,
          options: {
            search: [{ label: 'name', value: value }, { label: 'authors', value: value }]
          },
        });
        if (!!res && !!res.data && !!res.data.rows) {
          callback(res.data.rows.map(val => ({ value: val.id, label: val.name, data: val })));
        }
      }}
      additionStyles={{
        placeholder: (style) => ({
          ...style,
          fontSize: 14,
          color: COLOR.GRAY_500,
          cursor: 'text',
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          cursor: 'text',
        }),
      }}
    />
  )
};

const AddBookModal = (props: IAddBookModalProps) => {
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState([]);
  const { width, height } = useWindowDimensions();
  const [{ }, bApi] = Store.Book.createBookStore();

  const search = useCallback(async () => {
    const [res, err] = await bApi.listBook({
      visibility: 'public',
      page: 1,
      pageSize: 10,
      options: {
        search: [{ label: 'name', value: keyword }],
      },
    });
    if (res && res.data && res.data.rows) {
      setList(res.data.rows);
    }
  }, [keyword]);

  const containerWidth = width < 768 ? width * 0.9 : width / 2;
  const containerHeight = width < 768 ? height * 0.8 : height / 2;

  return (
    <Col width={containerWidth} height={containerHeight} bgWhite borderRadius10 onPress={() => {}} activeOpacity={1}>
      <Scroll width={containerWidth} height={containerHeight} padding24 borderRadius10>
        <Row justifyContent="space-between">
          <Text fontSize20 bold>Thêm sách</Text>
          <Col width30 height30 middle onPress={hideModal}>
            <ICON.Close fill={COLOR.GRAY_500} width={13} height={20} />
          </Col>
        </Row>

        {/* <Input
          label=""
          placeholder="Điền từ khóa tên sách và nhấn phím enter"
          value={keyword}
          onChange={setKeyword}
          onEnter={search}
          marginTop16
        /> */}
        <Col marginTop16>
          <SearchBookSuggestion onSelectBook={book => {
            props.onSelectBook(book);
            hideModal();
          }} />
        </Col>

        {/* <Col marginTop12>
          {Boolean(list.length > 0) && list.map((val, i) => (
            <Row
              key={val.id} padding8 borderBottomWidth1 borderBottomColor={COLOR.BORDER}
              onHoverStyle={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
              onPress={() => {
                hideModal();
                props.onSelectBook(val);
              }}
            >
              <RatioCol ratio={100 / 160} width={100}>
                <Img source={{ uri: val.images && val.images.length > 0 ? val.images[0] : '' }} style={STYLES.all100p} />
              </RatioCol>
              <Col flex1 marginLeft16>
                <Text subtitle1>{val.name}</Text>
                <Text body1 marginTop8>{val.authors && val.authors.length > 0 ? val.authors[0].name : ''}</Text>
                <RateStars
                  marginTop16
                  width101 height15 activeColor={COLOR.WARNING_400} inactiveColor={COLOR.WARNING_400}
                  value={Math.round(val.averageRating)}
                  starSize={{ width: 17, height: 15 }}
                  onChange={() => { }}
                  outline
                />
              </Col>
            </Row>
          ))}
        </Col> */}

      </Scroll>
    </Col>
  );
};

export default AddBookModal;
