import { COLOR } from 'const';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-quick-style-components';
import { Tooltip } from 'components';
import { MaterialIcons, FontAwesome5 } from '@expo/vector-icons';
import GlobalEvent from 'js-events-listener';

const GiveFeedback = ({ onPress }) => {
  const [isBig, setIsBig] = useState(false);

  const adjustSize = () => {
    var target = document.querySelector('#onesignal-bell-launcher');
    if (!target) return;
    const { width } = target.getBoundingClientRect();
    if (width >= 48 ) setIsBig(true);
  }

  useEffect(() => {
    setTimeout(() => {
      adjustSize();
      setTimeout(() => {
        adjustSize();
      }, 4000);
    }, 1500);
  }, []); 

  if (window.location.pathname === '/editor-screen') return null;

  return (
    <Col position='fixed' bottom={isBig ? 80 : 70} right20>
      <Tooltip title="Đóng góp ý kiến" position="left">
        <Col
          flex1 middle bgMain
          borderRadius={isBig ? 49/2 : 32/2}
          width={isBig ? 49 : 32}
          height={isBig ? 49 : 32}
          // onHoverStyle={{ width: 49, height: 49, borderRadius: 49/2 }}
          onPress={onPress}
        >
          <FontAwesome5 name="exclamation" size={16} color="white" />
        </Col>
      </Tooltip>
    </Col>
  );
};

export default GiveFeedback;