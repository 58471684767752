import React, { memo, useEffect } from 'react';
import { useScrollDirection } from 'react-use-scroll-direction';

interface IProps {
  onScrollingUp: Function,
  onScrollingDown: Function,
  target?: any,
}

// work around Rendered more hooks than during the previous render
const UseScrollDirection = ({ onScrollingUp, onScrollingDown, target } : IProps) => {
  const {
    isScrollingUp, 
    isScrollingDown,
  } = !target || !target.addEventListener ? useScrollDirection() : useScrollDirection(target);
  useEffect(() => {
    if (isScrollingUp) {
      onScrollingUp();
    } else if (isScrollingDown) {
      onScrollingDown();
    }
  }, [isScrollingUp, isScrollingDown]);
  return null;
};

export default memo(UseScrollDirection, (prevProps, nextProps) => prevProps.target === nextProps.target);