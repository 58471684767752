import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={18}
      height={12}
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M9 2.594c-.264.03-.674.088-.938.146.118.205.206.586.235.791 0 .908-.762 1.64-1.64 1.64-.235 0-.616-.087-.792-.204a3.267 3.267 0 00-.146.908A3.276 3.276 0 009 9.156a3.276 3.276 0 003.281-3.281A3.295 3.295 0 009 2.594zm8.32 2.87C15.738 2.36 12.574.25 9 .25 5.396.25 2.232 2.36.65 5.465a1.07 1.07 0 000 .85C2.232 9.42 5.396 11.5 9 11.5c3.574 0 6.738-2.08 8.32-5.186a1.07 1.07 0 000-.85zM9 10.095c-2.9 0-5.566-1.612-6.973-4.219C3.434 3.268 6.1 1.656 9 1.656c2.871 0 5.537 1.612 6.943 4.219-1.406 2.607-4.072 4.219-6.943 4.219z"
        fill={props.fill || "#949494"}
      />
    </Svg>
  )
}

export default SvgComponent
