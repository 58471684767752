import React from 'react';
import { Col, Row, Text, Img, useResponsiveStyle, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useNavFunc, A } from 'navigation';
import { showModal, hideModal, useDropdownOptions } from 'components';
import { COLOR, DEVICE_INFO } from 'const';
import { AntDesign } from '@expo/vector-icons';

interface INavProps {
  screen: any,
  activeRoute: any,
  label: string,
  children?: Array<{ label: string, route: string, icon: any, }>,
  navigation?: any,
}

const NavChildren = ({ listItems }) => {

  const renderItem = (val, i) => {
    return (
      <Row
        padding12
        key={'avatar-dropdown-'+i}
        onPress={() => {
          hideModal();
          !!val.onPress && val.onPress();
        }}
        onHoverStyle={{
          backgroundColor: COLOR.PRI_200,
        }}
      >
        <Text>{val.label}</Text>
      </Row>
    );
  }

  return (
		<Col width150 bgWhite shadow>
      {listItems.map(renderItem)}
    </Col>
	);
}

const Nav = (props: INavProps) => {
  const hasChildren = props.children && props.children.length > 0;

  const { showDropdown } = useDropdownOptions({
    navigation: props.navigation,
    width: 150,
    useEventPosition: true,
    positions: { top: 60, right: 24 + 36 },
    dropdownOptions: props.children?.map(val => (
      {
        text: val.label,
        onPress: () => props.navigation.navigate(val.route),
        icon: val.icon,
      }
    )),
  });

  const isActive = !hasChildren ? (props.screen === props.activeRoute.name) : (
    props.screen === props.activeRoute.name || !!props.children.find(item => item.route === props.activeRoute.name)
  )

  return (
    <A route={props.screen} style={{ height: '100%' }} onPress={hasChildren ? showDropdown : undefined}>
      <Row
        height100p middle padding12 marginRight8
        borderBottomWidth2
        borderBottomColor={isActive ? COLOR.WHITE_100 : 'transparent'}
      >
        <Text colorWhite subtitle1>{props.label}</Text>
        {hasChildren && (
          <AntDesign name="caretdown" size={12} color="white" style={{ marginLeft: 4 }} />
        )}
      </Row>
    </A>
  );
};

export default Nav;
