import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" {...props}>
      <Path
        fill={props.fill || "#FFF"}
        d="M17.604 3.332C12.99 4 12.075 2.833 10 1 7.925 2.833 7.01 4 2.396 3.332-.063 15.58 10 19 10 19s10.063-3.42 7.604-15.668zm-5.131 9.977L10 12.009l-2.472 1.3L8 10.556l-2-1.95 2.764-.401L10 5.7l1.236 2.505L14 8.606l-2 1.949.473 2.754z"
      />
    </Svg>
  )
}

export default SvgComponent