import React, { useCallback, useState, memo } from 'react';
import { Col } from 'react-quick-style-components';
import GridView from 'react-native-draggable-gridview';
import _ from 'lodash'

interface IDragableGridProps<T> {
  data: Array<T>,
  onChange(newValue: Array<T>, newIndex?: number): void,
  renderItem(item: any, index: number): any,
  options: {
    boxesPerRow: number,
    rowHeight: number,
  },
  width: number,
  style?: any,
}

const DragableGrid = (props : IDragableGridProps<any>) => {
  const { data, onChange: setData, renderItem, options } = props;

  const onReleaseCell = useCallback(
    (items) => {
      setData(items);
    },
    [data]
  )

  console.log('data', data);

  return (
    <Col>
      <GridView
        data={data}
        keyExtractor={(item) => (item.path)}
        renderItem={renderItem}
        onReleaseCell={onReleaseCell}
        numColumns={options.boxesPerRow}
        delayLongPress={30}
        width={props.width}
      />
    </Col>
  );
};

export default memo(DragableGrid);