import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M12.826 4.106a5.4 5.4 0 0 1 6.348 0l8.489 6.167a5.4 5.4 0 0 1 1.961 6.038l-3.242 9.978a5.4 5.4 0 0 1-5.136 3.732H10.754a5.4 5.4 0 0 1-5.136-3.732l-3.242-9.978a5.4 5.4 0 0 1 1.962-6.038l8.488-6.167Z"
      fill="#F66262"
    />
    <Path
      d="M11.58 22.4v-9.729h1.587l4.857 6.196v-6.196h1.988V22.4h-1.573l-4.872-6.21v6.21H11.58Z"
      fill="#fff"
    />
  </Svg>
)

export default SvgComponent
