import React, { useState, useCallback, useEffect } from 'react';
import { TextInput, StyleSheet } from 'react-native';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { Header, MainContainer, Avatar, Input, DirectMessageLayout, Button, usePreDataOrFetchApi, useSubmitUIState } from 'components';
import { COLOR, ERROR, SCREEN_NAME } from 'const';
import { ICON } from 'assets';
import { IConversation, IUser } from 'type';
import { TimeHelper, VarHelper } from 'helpers';

const DetailConversation = ({ route, navigation }) => {
  const rV = useDynamicResponsiveValue();
  const viewport = rV({ xs: 'xs', md: 'md' });
  const { navigate } = useNavFunc();
  const [{ user }, uApi] = Store.User.createUserStore();
  const [{ messageHistory }, mApi] = Store.Message.createStore();

  const { conversationId } = route?.params || {};
  const [conversation] = usePreDataOrFetchApi<IConversation>(conversationId, 'conversation');
  const [nameKeyword, setNameKeyword] = useState('');
  const [content, setContent] = useState('');
  const [{ loading, errorMes }, setSubmitUI] = useSubmitUIState();

  // console.log('conversation', conversation);

  useEffect(() => {
    // console.log('conversation', conversation);
    if (!conversation) return;
    const userIds = conversation.userIds || [];
    const otherUserIds = Object.keys(userIds).filter(val => val !== user.id);
    // console.log('otherUserIds', otherUserIds);
    if (otherUserIds.length === 0) return;
    const otherId = otherUserIds[0];
    const otherData = conversation.userData[otherId];
    // console.log('otherData', otherData);
    setNameKeyword(otherData?.name);
    mApi.getHistoryMessage(conversationId, { page: 1, pageSize: 200 });
  }, [conversation, user]);

  const submit = useCallback(async () => {
    if (!content) return setSubmitUI({ errorMes: ERROR.co_fill_all_required_form });
    const [res, err] = await mApi.sendMessage({
      conversationId,
      message: {
        content,
      },
    })
    if (!!res && !!res.data) {
      setContent('');
    }
    // if (err) return setSubmitUI({ loading: false, errorMes: ERROR.parseError(err) });
    // if (res.error) return setSubmitUI({ loading: false, errorMes: ERROR.parseError(res.error) });
    setSubmitUI({ loading: false });
  }, [conversationId, content]);

  const goToProfile = ({ id, name, avatar }) => {
    navigate(SCREEN_NAME.UserProfile, { id, name: VarHelper.toSlug(name) });
  }

  const getUserAvatar = useCallback((uId, fallbackData) => {
    return conversation?.userData[uId]?.avatar || fallbackData.avatar;
  }, [conversation]);

  return (
    <Col>
      <Text h5>Nội dung:</Text>

      <Col marginTop24 bgWhite borderRadius4 borderWidth1 borderColor={COLOR.BORDER} padding24>
        <Col>
          <Row height44 paddingVertical10 paddingHorizontal24 borderRadius4 borderWidth1 borderColor={COLOR.BORDER}>
            <Col width70>
              <Text>Gửi đến:</Text>
            </Col>
            <TextInput
              placeholder="Tìm trong bạn bè..."
              value={nameKeyword} onChangeText={setNameKeyword}
              style={styles.input}
              editable={false}
            />
          </Row>
          <Input
            marginTop16
            label=""
            multiline
            inputStyle={{ padding: 24 }}
            placeholder="Viết nội dung..."
            value={content} onChange={setContent}
          />
        </Col>
        <Col marginTop12 alignItems="flex-end">
          {Boolean(errorMes) && <Text textAlign="right" color={COLOR.DANGER_500} marginBottom4>{errorMes}</Text>}
          <Button loading={loading} large solid text="Gửi tin nhắn" width170 height43 onPress={submit} />
        </Col>
      </Col>

      <Col marginTop24>
        {Boolean(conversationId && messageHistory[conversationId] && messageHistory[conversationId].length > 0) && (
          <Col>
            {messageHistory[conversationId].map((mes => (
              <Col
                // onHoverStyle={{ backgroundColor: COLOR.PRI_100 }}
                borderWidth1 borderColor={COLOR.BORDER} borderRadius4 bgWhite padding24
              >
                <Row alignItems="flex-start">
                  <Row flex1 alignItems="flex-start">
                    <Col width40 onPress={() => goToProfile(mes.createdBy)}>
                      <Avatar name={mes.createdBy.name} uri={getUserAvatar(mes.createdBy.id, mes.createdBy)} size={40} />
                    </Col>
                    <Col marginLeft12 flex1>
                      <Text subtitle1 marginBottom4 colorMain onPress={() => goToProfile(mes.createdBy)}>
                        {user.id === mes.createdBy.id ? 'Bạn' : mes.createdBy.name}
                      </Text>
                      {Boolean(viewport === 'xs') && (
                        <Text caption color={COLOR.GRAY_500}>{TimeHelper.fromNow(mes.createdAt)}</Text>
                      )}
                      <Text caption>{mes.content}</Text>
                    </Col>
                  </Row>
                  {Boolean(viewport === 'md') && (
                    <Text caption color={COLOR.GRAY_500}>{TimeHelper.fromNow(mes.createdAt)}</Text>
                  )}
                </Row>
                
            </Col>
            )))}
          </Col>
        )}
      </Col>

      <Col marginTop24 />
      
    </Col>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginLeft: 12,
    color: COLOR.MAIN,
    fontWeight: 'bold',
  },
});

export default DetailConversation;
