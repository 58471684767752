import React, { useState, useEffect } from 'react';
import { Col, Row, Text, Scroll, Img } from 'react-quick-style-components';
import { Header, MainContainer, RatioCol, HTMLView, FacebookLikeCommentButton } from 'components';
import { useNavFunc, getScreenPreData } from 'navigation';
import { IMAGE } from 'assets';
import Store from 'store';
import { COLOR, ERROR, STYLES, useFontSizeStyle } from 'const';
import { INews } from 'type';
import { TimeHelper, VarHelper, DomHelper } from 'helpers';

const NewsDetail = () => {
  const { navigation, route } = useNavFunc();

  const [errorMes, setErrorMes] = useState('');

  const [{}, nApi] = Store.News.createStore();

  const { h3 } = useFontSizeStyle();

  const id = route.params?.id;
  const [data, setData] = useState<INews>(getScreenPreData(id) || {});

  const getData = async () => {
    if (!data || !data.id) {
      const [res, err] = await nApi.detail(id);
      if (err) return setErrorMes(ERROR.parseError(err));
      if (res && res.error) return setErrorMes(ERROR.parseError(res.error));
      if (res && res.data) {
        setData(res.data);
      } 
    }
    
  }
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    DomHelper.loadFacebook();
  }, []);

  const coverImage = !!data && !!data.images && !!data.images[1] ? { uri: data.images[1] } : IMAGE.BannerNews;

  return (
    <Col flex1>
      <Header />
      <Col flex1>
        <Scroll>
          {Boolean(coverImage) && (
            <RatioCol ratio={1366 * 2/ 768} width='100%' marginBottom16>
              <Img source={coverImage} style={STYLES.all100p} resizeMode="cover" />
            </RatioCol>
          )}
          <MainContainer alignSelf="center" marginBottom16>
            <Text style={h3}>{data.name}</Text>
          </MainContainer>
          <MainContainer alignSelf="center" marginBottom16>
            <Row
              marginBottom16
              onResponsiveStyle={{
                xs: { flexWrap: 'wrap' },
                md: { justifyContent: 'space-between' }
              }}
            >
              <Col width200 marginBottom16>
                <Text body2 color={COLOR.GRAY_500}>Đăng ngày {TimeHelper.format(data.createdAt)}</Text>
              </Col>
              <FacebookLikeCommentButton width300 marginBottom16 />
            </Row>
            

            <HTMLView content={data.contentHTML} />

          </MainContainer>
        </Scroll>
      </Col>
    </Col>
  );
};

export default NewsDetail;
