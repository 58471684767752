import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Col, Text, Scroll, Img, Row } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import {
  Header, Button, MainContainer, RatioCol, GroupItem, GroupDetailLayout, GroupPostFeed, usePreDataOrFetchApi,
  UseMemo, LoadMoreDetect, useFetchUIState
} from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';
import { IGroupFeed, IGroupPost } from 'type';

import AddPost from './GroupDetail.AddPost/GroupDetail.AddPost';
import EditPost from './GroupDetail.AddPost/GroupDetail.EditPost';
import SinglePost from './GroupDetail.AddPost/GroupDetail.SinglePost';
import PostFeedOrder from './GroupDetail.AddPost/PostFeedOrder';

interface IGroupDetailChildrenProps {
  groupFeedData: IGroupFeed,
  page?: any,
  params?: any,
  setGroupFeedData?: any,
  myUser?: any,
  getData?: any,
  onChangeFeedOrder?: any,
  fetchUI: {
    isLoadingMore: boolean,
    hasMore: boolean,
  }
}

const GroupDetailFeed = (props : IGroupDetailChildrenProps) => {
  const { groupFeedData, setGroupFeedData } = props;
  const { postid, id, postmode } = props.params || {};
  const isPostDetail = !!postid;
  const isUpdatePostMode  = !!postid && postmode === 'chinh-sua';

  const renderFeed = () => {
    return (
      <Col>
        {Boolean(props.myUser) && (
          <UseMemo deps={[groupFeedData]}>
            <AddPost
              groupFeedData={groupFeedData}
              onNewPostAdded={(newPost) => {
                setGroupFeedData({...groupFeedData, rows: [newPost, ...groupFeedData.rows]});
              }}
              onChangeFeedOrder={props.onChangeFeedOrder}
            />
          </UseMemo>
        )}
        <Col marginTop16 borderTopColor={COLOR.BORDER} borderTopWidth1 />
        <Col alignItems="flex-end" marginTop8 marginBottom8>
          <PostFeedOrder onChange={props.onChangeFeedOrder} />
        </Col>
        <Col marginBottom30>
          {groupFeedData?.rows?.map((data, i) => (
            <GroupPostFeed
              key={'post-feed-' + data.id}
              data={data}
              marginBottom16
            />
          ))}
        </Col>
        <UseMemo deps={[props.fetchUI.hasMore, props.fetchUI.isLoadingMore, props.page, props.getData]}>
          <LoadMoreDetect
            hasMore={props.fetchUI.hasMore}
            isLoadingMore={props.fetchUI.isLoadingMore}
            fetcher={() => {
              props.getData(props.page.current + 1);
            }}
          />
        </UseMemo>
      </Col>
    );
  };
  const renderUpdatePost = () => {
    return (
      <Col>
        <EditPost groupFeedData={props.groupFeedData} groupId={id} postId={postid} setGroupFeedData={setGroupFeedData} />
      </Col>
    )
  };
  const renderDetailPost = () => {
    return (
      <Col>
        <SinglePost groupId={id} postId={postid} />
      </Col>
    )
  };
  return isUpdatePostMode ? renderUpdatePost() :
  isPostDetail ? renderDetailPost() : renderFeed();
};

export default GroupDetailFeed;