import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M16.703 8.824c0-.531-.066-.93-.133-1.361H8.734v2.822h4.649c-.166 1.229-1.395 3.553-4.649 3.553-2.822 0-5.113-2.324-5.113-5.213 0-4.615 5.445-6.74 8.367-3.918l2.258-2.158A7.986 7.986 0 008.734.39 8.212 8.212 0 00.5 8.625a8.19 8.19 0 008.234 8.234c4.748 0 7.97-3.32 7.97-8.035z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
