import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { CMSLayout, Button, Input, Uploader, Select, useRefState, CMSUploaderUI, FeedItem } from 'components';
import { Linking } from 'react-native';
import { useNavFunc, Reset } from 'navigation';
import { COLOR, DEVICE_INFO, ERROR, REPORT_REASON_FULL, REPORT_STATUS, SCREEN_NAME, USER_TYPE } from 'const';
import { ICON } from 'assets';
import Store from 'store';
import { VarHelper } from 'helpers';
import { IActivity, IUser, IViolationReport } from 'type';

const CMSEditReport = () => {
  const { goBackOrTo, navigate, route, getScreenPreData } = useNavFunc();
  const [btnText, setBtnText] = useState('');

  const [reportData, setReportData] = useState<IViolationReport>();
  const [status, setStatus] = useState('');
  const [notExist, setNotExist] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const rV = useDynamicResponsiveValue();
  const [{ }, vApi] = Store.ViolationReport.createStore();
  const avatarRef = useRef<{ getUploadedUrl: Function, setUri: Function }>();

  // @ts-ignore
  const { id }  = route.params;

  const getInitialData = async () => {
    const [res, err] = await vApi.detail(id);
    if (err) return alert(ERROR.parseError(err));
    if (res.error) return alert(ERROR.parseError(res.error));
    if(res.data) {
      setStatus(res.data.status);
      setReportData(res.data);
    }

  }

  const submit = async () => {
    setLoading(true);
    const [res, err] = await vApi.update({
      id,
      status,
    });
    setLoading(false);
    if (err) return setErrorMessage(ERROR.parseError(err));
    if (res.error) return setErrorMessage(ERROR.parseError(res.error));
    setBtnText('Cập nhật thành công..');
    setTimeout(() => {
      setBtnText('');
      setTimeout(() => {
        goBackOrTo(SCREEN_NAME.CMSListReport);
      }, 500)
    }, 500);
  };

  useEffect(() => {
    getInitialData();
  }, [id]);

  if (!id || notExist) {
    return (
      <Col middle flex1 bgWhite>
        <Text>Đường dẫn không hợp lệ..</Text>
        <Reset to={SCREEN_NAME.CMSHome} timeout={1500} />
      </Col>
    );
  }

  return (
    <Col flex1>
      <CMSLayout title="Duyệt vi phạm">
        <Row>
          <Col flex1>
            <Text h4>DUYỆT VI PHẠM</Text>
          </Col>
          <Row>
            {/* <Button
              paddingHorizontal12 small outline borderColor={COLOR.MAIN} borderWidth1
              text="Xem bài viết bị báo cáo"
              marginRight12
              onPress={() => {
                if (!reportData || !DEVICE_INFO.IS_WEB) return;
                const { objectType, objectId, data } = reportData;
                switch(objectType) {
                  case 'writing':
                    window.open(`/viet/${objectId}/${VarHelper.toSlug(data?.name)}`);
                  break;
                  case 'quote':
                    window.open(`/viet/${objectId}/${VarHelper.toSlug(data?.name)}`);
                  break;
                }
                
              }}
            /> */}
            <Button paddingHorizontal12 small solid text="Quay lại" onPress={() => goBackOrTo(SCREEN_NAME.CMSListReport)} />
          </Row>
        </Row>

        {Boolean(reportData) && (
          <Col marginTop24>
            <FeedItem
              data={{
                data: reportData.data,
                object: {
                  id: reportData.objectId,
                  type: reportData.objectType,
                }
              } as IActivity}
            />
          </Col>
        )}

        <Col marginTop24>
          <Input
            label="Lý do"
            placeholder=""
            value={reportData?.reason ? REPORT_REASON_FULL[reportData?.reason] : ''}
            inputProps={{ editable: false }}
            onChange={undefined}
          />

          <Input
            marginTop24
            label="Chi tiết"
            multiline
            inputStyle={{ padding: 12 }}
            placeholder=""
            value={reportData?.explain}
            inputProps={{ editable: false }}
            onChange={undefined}
          />
        </Col>

        <Col marginTop24>
          <Text>Quy tắc duyệt:</Text>
          <Text>- <Text bold>Đã bác bỏ</Text>: báo cáo vi phạm là không chính xác</Text>
          <Text>- <Text bold>Đã phê duyệt</Text>: cần thực hiện hành động <Text bold>sửa/xóa</Text> bài viết gốc trước khi chọn trạng thái này</Text>
        </Col>
        

        <Row marginTop24 marginBottom200 justifyContent="space-between" alignItems="flex-start">
          <Col width220>
            <Select
              label="Trạng thái"
              options={Object.keys(REPORT_STATUS).map(val => ({ value: val, label: REPORT_STATUS[val] }))}
              value={{ label: REPORT_STATUS[status], value: status }}
              onChange={(v) => setStatus(v.value)}
            />
          </Col>
          <Col height100p justifyContent="flex-end">
            {Boolean(errorMessage) && <Text color={COLOR.DANGER_500} marginTop8>{errorMessage}</Text>}
            <Button small solid text={btnText ? btnText : "Xác nhận"} loading={loading} paddingHorizontal12 onPress={submit} />
          </Col>
        </Row>
      </CMSLayout>
    </Col>
  );
};

export default CMSEditReport;