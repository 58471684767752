import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { IUser } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';

interface ISetters {
  [additionSetter: string]: (v : any) => void,
}

class Group extends PersistReady {

  constructor() {
    super();
  }

  state = {
    
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {

    return [
      {  },
      {
        create: this.create,
        list: this.list,
        feed: this.feed,
        addPost: this.addPost,
        detailPost: this.detailPost,
        updatePost: this.updatePost,
        joinGroup: this.joinGroup,
        leaveGroup: this.leaveGroup,
        update: this.update,
        inviteFriend: this.inviteFriend,
      }
    ];
  }

  create = async ({ image, name, description, cover }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/groups', { image, name, description, cover });
      return res.data;
    });
  }

  update = async ({ id, image, name, description, cover }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/groups/update', { id, image, name, description, cover });
      return res.data;
    });
  }

  list = async ({ page, pageSize }) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/groups', { page, pageSize });
      return res.data;
    });
  }

  feed = async ({ id, page, pageSize, options }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/groups/'+id+'/feeds', { page, pageSize, options });
      return res.data;
    });
  }

  addPost = async (id, { images, videos, tagBooks, content }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/groups/'+id+'/posts', { images, videos, tagBooks, content });
      return res.data;
    });
  }

  detailPost = async (groupId, postId) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/groups/'+groupId+'/post/'+postId, {});
      return res.data;
    });
  }

  updatePost = async (groupId, { id, images, videos, tagBooks, content, visibility, isPinned }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/groups/'+groupId+'/post/update', { id, images, videos, tagBooks, content, visibility, isPinned });
      return res.data;
    });
  }

  joinGroup = async (groupId) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/groups/'+groupId+'/join', {});
      return res.data;
    });
  }

  leaveGroup = async (groupId) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/groups/'+groupId+'/leave', {});
      return res.data;
    });
  }

  inviteFriend = async (groupId, friendId) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/groups/'+groupId+'/invite', { id: friendId });
      return res.data;
    });
  }
}

export default new Group();
