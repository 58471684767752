import React, { useState } from 'react';
import { Row, Col, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle } from 'const';
import { useNavigation } from '@react-navigation/native';
import { SCREEN_NAME, DEVICE_INFO, ERROR } from 'const';
import { ICON } from 'assets';
import { Button, Checkbox, Input } from 'components';
import { Reset, useNavFunc } from 'navigation';
import { VarHelper } from 'helpers';
import { StyleSheet } from 'react-native';
import { useGoogleFacebookAuth } from './useGoogleFacebookAuth';
import Store from 'store';

const Login = () => {
  const rV = useDynamicResponsiveValue();
  const { navigation, route } = useNavFunc();
  const { redirect } = route.params || {};
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [rememberLogin, setRememberLogin] = useState(false);
  const [{ user, token, loginBehaviour }, userApi] = Store.User.createUserStore();

  const reset = (screenName) => {
    navigation.reset({ index: 0, routes: [{ name: screenName }] })
  };

  const { getFacebookLoginData, getGoogleLoginData } = useGoogleFacebookAuth();

  const onLoginSuccess = () => {
    userApi.getMyExtraInfo();
  }

  const loginFacebook = async () => {
    // window.location.href = 'https://www.facebook.com/v11.0/dialog/oauth?client_id=158366536186297&response_type=token&redirect_uri=https://localhost:19006/login';
    setLoading(true);
    const onEnd = () => setLoading(false);
    const [data, err] = await getFacebookLoginData();
    if (VarHelper.handleError(data, err, setLoginErrorMessage, onEnd).shouldReturn) return;
    const { accessToken } = data?.credential;
    console.log('facebook accessToken', accessToken);
    const [res, err2] = await userApi.loginFacebook(accessToken);
    if (VarHelper.handleError(res, err2, setLoginErrorMessage, onEnd).shouldReturn) return;
    console.log('res', res);
    setLoading(false);
    onLoginSuccess();
  };

  const loginGoogle = async () => {
    setLoading(true);
    const onEnd = () => setLoading(false);
    const [data, err] = await getGoogleLoginData();
    if (VarHelper.handleError(data, err, setLoginErrorMessage, onEnd).shouldReturn) return;
    const { idToken } = data?.credential;
    const { photoURL, displayName } = data?.user;
    const { family_name: lastName, given_name: firstName } = data?.additionalUserInfo?.profile;
    const [res, err2] = await userApi.loginGoogle({ idToken, photoURL, displayName, lastName, firstName });
    if (VarHelper.handleError(res, err2, setLoginErrorMessage, onEnd).shouldReturn) return;
    setLoading(false);
    onLoginSuccess();
  };

  const login = async () => {
    if (!email || !password) {
      setLoginErrorMessage(ERROR.co_fill_all_form);
      return;
    }
    setLoading(true);
    const [res, err] = await userApi.login({ email, password });
    setLoading(false);
    if (err) return setLoginErrorMessage(String(err));
    if (res.error) return setLoginErrorMessage(String(res.error));
    onLoginSuccess();
  }

  const toggleRememberLogin = (shouldRemember) => {
    userApi.setLoginBehaviour({
      shouldRemember,
      lastTimeActivity: shouldRemember ? '0' : String(new Date().getTime()),
    })
  };

  if (!!token) {
    if (redirect) {
      setTimeout(() => {
        window.location.href = redirect;
      }, 500);
    }
    return (
      <Col middle flex1 bgWhite>
        <Text>Bạn đã đăng nhập..</Text>
        {Boolean(!redirect) && <Reset to={SCREEN_NAME.Home} timeout={500} />}
      </Col>
    );
  }

  return (
    <Col middle flex1 bgWhite>
      <Col width={rV({ xs: 320, lg: 364 })}>
        <ICON.ReadismWithTextLogo fill={COLOR.MAIN} style={{ alignSelf: 'center', width: '80%' }} />
        <Text marginTop40 h4>Đăng nhập</Text>
        <Row height44 marginTop24>
          <Row flex1 height100p backgroundColor="#425894" borderRadius6 middle onPress={loginFacebook}>
            <ICON.Facebook />
            <Text colorWhite btnTextMedium marginLeft8>Facebook</Text>
          </Row>
          <Row flex1 height100p backgroundColor="#D85140" borderRadius6 marginLeft16 middle onPress={loginGoogle}>
            <ICON.Google />
            <Text colorWhite btnTextMedium marginLeft8>Google</Text>
          </Row>
        </Row>
        <Col line marginTop16 />
        <Col marginTop22>
          <Input
            label="Email"
            value={email}
            onChange={setEmail}
            placeholder="Nhập email"
            error={!!loginErrorMessage ? ERROR.parseError(loginErrorMessage) : ''}
          />
          <Input.Password
            marginTop24
            label="Mật khẩu"
            value={password}
            password
            onChange={setPassword}
            placeholder="Nhập mật khẩu"
            renderRightLabel={() => {
              return (
                <Col onPress={() => reset(SCREEN_NAME.ForgotPassword)}>
                  <Text colorMain subtitle2>Quên mật khẩu?</Text>                
                </Col>
              );
            }}
            inputProps={{
              onSubmitEditing: login,
            }}
          />
        </Col>
        <Row marginTop16 alignItems="flex-start">
          <Checkbox 
            value={loginBehaviour.shouldRemember}
            onChange={toggleRememberLogin}
          />
          <Col flex1 marginLeft8 onPress={() => toggleRememberLogin(!loginBehaviour.shouldRemember)}>
            <Text body2>Ghi nhớ đăng nhập</Text>
          </Col>
        </Row>
        <Button marginTop32 solid large text="Đăng nhập" loading={loading} onPress={login} />
        <Col marginTop24 middle onPress={() => reset(SCREEN_NAME.Register)}>
          <Text>Chưa có tài khoản? <Text colorMain semiBold>Đăng ký ngay</Text></Text>
        </Col>
      </Col>
      <Col style={styles.cornerCloseBtn} onPress={() => reset(SCREEN_NAME.Home)}>
        <ICON.Close />
      </Col>
    </Col>
  );
};

const styles = StyleSheet.create({
  cornerCloseBtn: {
    position: 'absolute',
    top: DEVICE_INFO.IS_IOS ? 48 : 12,
    right: 12,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Login;