import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Text, Img } from 'react-quick-style-components';
import { STYLES, COLOR, useFontSizeStyle, ERROR } from 'const';
import { ICON, IMAGE } from 'assets';
import { Slider, MainContainer, RatioCol, Input, ControlledUploaderUI, Button, useSubmitUIState, Popup, hideModal } from 'components';
import { useWindowDimensions } from 'react-native';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { IBook, ILocalFileUpload } from 'type';
import AsyncSelect, { NoOptionsMessage, LoadingMessage } from 'components/elements/AsyncSelect';
import { arrayMoveImmutable } from 'array-move';
import { VarHelper } from 'helpers';
const DropdownIndicator = () => null;
import Store from 'store';

const SearchBookSuggestion = ({ onSelectBook }) => {
  const [value, onChange] = useState();
  const [{ }, bApi] = Store.Book.createBookStore();
  const [name, setName] = useState('');
  return (
    <AsyncSelect
      label=""
      placeholder="Nhập tìm Sách theo tên, tác giả"
      value={value}
      onChange={(data) => {
        onChange(data);
        if (!!data?.value) {
          onSelectBook(data.data);
          // @ts-ignore
          onChange({ label: '', value: '' });
        }
      }}
      noOptionsMessage={!!name ? undefined : () => null}
      components={{ NoOptionsMessage, DropdownIndicator, LoadingMessage }}
      defaultOptions={[]}
      onInputChange={(nameInput) => { setName(nameInput); return nameInput; }}
      loadOptions={async (value, callback) => {
        const [res, err] = await bApi.listBook({
          visibility: 'public',
          page: 1, pageSize: 20,
          options: {
            search: [{ label: 'name', value: value }, { label: 'authors', value: value }]
          },
        });
        if (!!res && !!res.data && !!res.data.rows) {
          callback(res.data.rows.map(val => ({ value: val.id, label: val.name, data: val })));
        }
      }}
      additionStyles={{
        placeholder: (style) => ({
          ...style,
          fontSize: 14,
          color: COLOR.GRAY_500,
          cursor: 'text',
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          cursor: 'text',
        }),
      }}
    />
  )
};

const BookRecommendationSidebar = () => {
  const [{ listSettings }, sApi] = Store.Setting.createStore();
  const [books, setBooks] = useState([]);
  const [{ loading }, setUI] = useSubmitUIState();
  const onSelectBook = (book: IBook) => {
    const newBooks = books.slice();
    newBooks.push({
      id: book.id,
      name: book.name,
      image: book.images?.length > 0 ? book.images[0] : '',
    })
    setBooks(newBooks);
  };
  const submit = async () => {
    setUI({ loading: true });
    const [res, err] = await sApi.upsert({ name: 'BOOK_SIDEBAR_RECOMMENDATION', value: '', data: books });
    setUI({ loading: false });
    if (err || res.error) return Popup.showError(ERROR.parseError(err || res.error));
    Popup.show('Cập nhật thành công');
  }

  useEffect(() => {
    const find = listSettings.find(val => val.name === 'BOOK_SIDEBAR_RECOMMENDATION');
    if (!!find) {
      setBooks(find.data || []);
    }
  }, [listSettings]);

  return (
    <Col>
      <SearchBookSuggestion onSelectBook={book => {
        onSelectBook(book);
        hideModal();
      }} />
      <Col marginTop16>
        {books.map((b, bI) => {
          const bookImage = b.image;
          return (
            <Row padding8 margin8 stretch key={b.id}>
              <RatioCol ratio={100 / 160} width={50} shadow>
                <Img source={{ uri: bookImage }} style={STYLES.all100p} />
              </RatioCol>
              <Col flex1 marginLeft16>
                <Text bold>{b.name}</Text>
                <Row marginTop24>
                  <Col onPress={() => window.open(`/sach/${b.id}/${VarHelper.toSlug(b.name)}`)}>
                    <Text textDecorationLine='underline'>Mở tab</Text>
                  </Col>
                </Row>
                
              </Col>
              <Col marginLeft16>
                <Button outline width={100} text='Xóa khỏi list'
                  onPress={() => setBooks(v => v.filter(a => a.id !== b.id))}
                />
              </Col>
            </Row>
          );
        })}
      </Col>
      <Col marginTop24 middle>
        <Button loading={loading} text='Cập nhật' onPress={submit} solid width150 height44 />
      </Col>
    </Col>
  );
};

export default BookRecommendationSidebar;