import React, { useState, useEffect } from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { useRefState2 } from '../hooks';
import GlobalEvent, { useEvent } from 'js-events-listener/react';
import { ICON } from 'assets';
import { COLOR } from 'const';
import Store from 'store';

const OnScreenNotification = () => {
  const [{ unreadNoti }, uApi] = Store.User.createUserStore();
  const [listNoti, getListNoti, setListNoti] = useRefState2<Array<{ id: string, content: string }>>([]);
  useEvent('NOTIFICATION_DISPLAYED', (event : any) => {
    const oldList = getListNoti() || [];
    const newList = oldList.slice();
    newList.push(event);
    setListNoti(newList);
    uApi.setUnreadNoti(unreadNoti + 1);
    setTimeout(() => {
      setListNoti(v => v.filter(val => val.id !== event?.id));
    }, 3000);
  }, [unreadNoti]);
  const showNoti = (e) => {
    setListNoti([]);
    GlobalEvent.emit('OPEN_NOTIFICATION_DROPDOWN', e);
  }
  return (
    <Col
      // @ts-ignore
      position='fixed' zIndex={20} top20 right20 flexDirection='column-reverse'>
      {listNoti.map((val, i) => (
        <Row
          width={300} marginVertical4 key={'item-'+val.id+i} backgroundColor='rgba(255,255,255,0.8)' borderRadius8 padding8
          borderWidth1 borderColor={COLOR.BORDER}
          alignItems='flex-start'
          onPress={showNoti}
        >
          <Col>
            <ICON.Notification fill={COLOR.MAIN} width={40} height={40} />
          </Col>
          <Col marginLeft8 flex1>
            <Text numberOfLines={3}>{val.content}</Text>
            <Row marginTop4 onPress={() => setListNoti(v => v.filter(vItem => vItem.id !== val?.id))}>
              <ICON.Close fill={COLOR.DANGER_300} width={10} height={10} />
              <Text marginLeft8 color={COLOR.DANGER_300}>Ẩn đi</Text>
            </Row>
          </Col>
        </Row>
      ))}
    </Col>
  );
};

export default OnScreenNotification;