import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={87}
      height={86}
      viewBox="0 0 87 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#prefix__clip0)">
        <Path
          d="M37.036 21.56C35.123 10.089 25.198 1.68 13.566 1.68l.772 10.6a32.549 32.549 0 00-5.811-.522l1.379 12.406 28.831 7.705.024.045-1.725-10.354zM73.262 12.28l.772-10.6c-11.631 0-21.557 8.408-23.47 19.88L48.84 31.915l.024-.045 28.832-7.705 1.379-12.406c-1.97 0-3.914.178-5.812.522z"
          fill="#EEFAFF"
        />
        <Path
          d="M85.12 26.875l-5.039 42.328s-10.452-1.638-16.125 0c-9.448 2.73-15.117 10.078-15.117 10.078V31.914l.024-.045c.378-.728 5.408-10.033 15.093-10.033 10.077 0 21.148 5.034 21.164 5.039z"
          fill="#63B199"
        />
        <Path
          d="M48.84 31.914v47.367a5.039 5.039 0 11-10.079 0V31.914a5.039 5.039 0 1010.078 0z"
          fill="#327A64"
        />
        <Path
          d="M38.761 31.914v47.367s-5.669-7.348-15.117-10.078c-5.672-1.638-16.125 0-16.125 0l-5.04-42.328c.095-.029 10.984-5.04 21.165-5.04 9.685 0 14.715 9.306 15.093 10.034l.024.045z"
          fill="#63B199"
        />
        <Path
          d="M5.851 69.402a1.68 1.68 0 001.928 1.46c.101-.015 10.145-1.562 15.399-.045 7.576 2.189 12.654 7.62 13.935 9.108C37.438 83.33 40.313 86 43.8 86c3.488 0 6.363-2.672 6.688-6.077 1.276-1.49 6.326-6.908 13.934-9.106 5.246-1.514 15.299.03 15.4.045a1.68 1.68 0 001.927-1.46l1.626-13.658a1.68 1.68 0 10-3.336-.398L78.615 67.31c-3.298-.4-10.539-1.045-15.125.28-5.831 1.684-10.268 4.994-12.971 7.484V32.342c.915-1.603 5.48-8.826 13.437-8.826 6.189 0 13.62 2.07 19.347 4.412l-1.477 12.407a1.68 1.68 0 003.336.397l1.626-13.658a1.68 1.68 0 00-.973-1.728c-.115-.052-2.603-1.177-6.298-2.371l1.226-11.031a1.68 1.68 0 00-1.67-1.866c-1.329 0-2.662.078-3.983.233l.62-8.509A1.68 1.68 0 0074.035 0C61.601 0 50.957 8.992 48.906 21.285c-1.765 10.678-1.747 10.344-1.747 10.629a3.363 3.363 0 01-3.36 3.36 3.363 3.363 0 01-3.36-3.36c0-.353-.114-.713-1.747-10.63C36.648 9.021 26.028 0 13.566 0a1.68 1.68 0 00-1.676 1.802l.62 8.509a34.327 34.327 0 00-3.983-.233 1.68 1.68 0 00-1.67 1.865l1.227 11.031c-3.696 1.195-6.183 2.32-6.299 2.372a1.68 1.68 0 00-.973 1.727l5.04 42.329zm71.34-55.907L76.244 22c-4.507-1.224-8.633-1.844-12.289-1.844-1.846 0-3.534.299-5.067.797 5.102-4.39 11.487-7.041 18.301-7.458zm-24.97 8.342A22.039 22.039 0 0172.223 3.432l-.544 7.453a34.265 34.265 0 00-19.714 12.488l.256-1.536zm-8.42 16.796c1.222 0 2.37-.33 3.358-.904V79.28a3.363 3.363 0 01-3.359 3.36 3.363 3.363 0 01-3.36-3.36V37.73c.99.574 2.137.904 3.36.904zm-8.422-16.796l.256 1.536a34.272 34.272 0 00-19.714-12.488l-.543-7.453c10.05.81 18.316 8.3 20.001 18.405zm-6.668-.884a16.296 16.296 0 00-5.067-.797c-3.655 0-7.781.62-12.289 1.844l-.945-8.505c6.788.415 13.18 3.05 18.301 7.458zm-5.067 2.563c7.955 0 12.521 7.221 13.437 8.826v42.732c-2.703-2.49-7.14-5.8-12.971-7.485-4.207-1.215-10.79-.805-15.125-.28L4.297 27.928c5.733-2.345 13.164-4.411 19.347-4.411z"
          fill="#333"
        />
        <Path
          d="M82.6 49.719a1.68 1.68 0 100-3.36 1.68 1.68 0 000 3.36z"
          fill="#333"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" transform="translate(.8)" d="M0 0h86v86H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default SvgComponent
