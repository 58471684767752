import React, { useState, useCallback } from 'react';
import { Col, Row, Text, Scroll, Img } from 'react-quick-style-components';
import { hideModal, Input, RatioCol, RateStars } from 'components';
import AsyncSelect, { NoOptionsMessage, LoadingMessage } from 'components/elements/AsyncSelect';
import { ICON } from 'assets';
import { COLOR, STYLES } from 'const';
import { useWindowDimensions, } from 'react-native';
import Store from 'store';
import { IBook } from 'type';

interface IAddBookModalProps {
  onSelectBook(data: IBook): void,
}

const DropdownIndicator = () => null;
// const NoOptionsMessage = () => null;

const SearchBookSuggestion = ({ onSelectBook }) => {
  const [value, onChange] = useState();
  const [{}, bApi] = Store.Book.createBookStore();
  const [name, setName] = useState('');
  return (
    <AsyncSelect
      label=""
      placeholder="Nhập tìm Sách theo tên, tác giả"
      value={value}
      onChange={(data) => {
        onChange(data);
        if (!!data?.value) {
          onSelectBook(data.data);
        }
      }}
      noOptionsMessage={!!name ? undefined : () => null}
      components={{ NoOptionsMessage, DropdownIndicator, LoadingMessage }}
      defaultOptions={[]}
      onInputChange={(nameInput) => { setName(nameInput); return nameInput; }}
      loadOptions={async (value, callback) => {
        const [res, err] = await bApi.listBook({
          visibility: 'public',
          page: 1, pageSize: 20,
          options: {
            search: [{ label: 'name', value: value }, { label: 'authors', value: value }]
          },
        });
        if (!!res && !!res.data && !!res.data.rows) {
          callback(res.data.rows.map(val => ({ value: val.id, label: val.name, data: val })));
        }
      }}
      additionStyles={{
        placeholder: (style) => ({
          ...style,
          fontSize: 14,
          color: COLOR.GRAY_500,
          cursor: 'text',
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          cursor: 'text',
        }),
      }}
    />
  )
};

const AddBookModal = (props: IAddBookModalProps) => {
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState([]);
  const { width, height } = useWindowDimensions();
  const [{ }, bApi] = Store.Book.createBookStore();

  const search = useCallback(async () => {
    const [res, err] = await bApi.listBook({
      visibility: 'public',
      page: 1,
      pageSize: 10,
      options: {
        search: [{ label: 'name', value: keyword }],
      },
    });
    if (res && res.data && res.data.rows) {
      setList(res.data.rows);
    }
  }, [keyword]);

  const containerWidth = width < 768 ? width * 0.9 : width / 2;
  const containerHeight = width < 768 ? height * 0.8 : height / 2;

  return (
    <Col width={containerWidth} height={containerHeight} bgWhite borderRadius10 onPress={() => {}} activeOpacity={1}>
      <Scroll width={containerWidth} height={containerHeight} padding24 borderRadius10>
        <Row justifyContent="space-between">
          <Text fontSize20 bold>Thêm sách</Text>
          <Col width30 height30 middle onPress={hideModal}>
            <ICON.Close fill={COLOR.GRAY_500} width={13} height={20} />
          </Col>
        </Row>
        <Col marginTop16>
          <SearchBookSuggestion onSelectBook={book => {
            props.onSelectBook(book);
            hideModal();
          }} />
        </Col>

      </Scroll>
    </Col>
  );
};

export default AddBookModal;
