import React, { useRef, forwardRef, useImperativeHandle } from 'react';

interface IFilePickerProps {

}

const FilePicker = forwardRef((props : IFilePickerProps, ref) => {

  const binary = useRef(null);

  const onChange = (e : any ) => {
    // console.log('onChange', e);
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    const file = files[0];
    // console.log('file', file);
    var reader = new FileReader();
    reader.onload = function(e) {
      // console.log('onload')
      var data = e.target.result;
      binary.current = data;
    };
    reader.onerror = function(ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file as any);
  };

  const inputRef = useRef(null);
  const waitRef = useRef(null);

  const openDialogAndWaitForBinary = () => new Promise((resolve, reject) => {
    // console.log(inputRef.current);
    inputRef.current.value = null;
    binary.current = null;
    inputRef.current.click();
    let count = 0;
    waitRef.current = setInterval(() => {
      if (binary.current) {
        resolve(binary.current);
        clearInterval(waitRef.current);
      } else {
        count++;
        if (count >= 100) {
          clearInterval(waitRef.current);
          resolve(null);
        }
      }
    }, 500);
  });

  useImperativeHandle(ref, () => ({
    getBinary: () => binary.current,
    openDialog: () => inputRef.current.click(),
    openDialogAndWaitForBinary: () => openDialogAndWaitForBinary(),
  }));

  return (
    <input ref={inputRef} type="file" style={{ display: 'none' }} onClick={() => {}} onChange={onChange} />
  );
});

export default FilePicker;