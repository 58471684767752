import React from 'react';
import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { SCREEN_NAME } from 'const';
import { COLOR } from 'const';

export const listNav = [
  // { label: 'Trang chủ', route: SCREEN_NAME.Home },
  { label: 'Đánh giá Sách', route: SCREEN_NAME.BookHomepage },
  { label: 'Hội nhóm', route: SCREEN_NAME.Group },
  { label: 'Trích dẫn', route: SCREEN_NAME.Quote },
  // { label: 'Sáng tác', route: SCREEN_NAME.Writing, children: [
  //   {
  //     label: 'Truyện Tranh',
  //     route: SCREEN_NAME.Manga,
  //     icon: <Ionicons name="images" size={16} color={COLOR.MAIN} />,
  //   },
  //   {
  //     label: 'Truyện Chữ',
  //     route: SCREEN_NAME.Writing,
  //     icon: <FontAwesome name="edit" size={16} color={COLOR.MAIN} />,
  //   },
  // ] },
  {
    label: 'Truyện Chữ',
    route: SCREEN_NAME.Writing,
    // icon: <FontAwesome name="edit" size={16} color={COLOR.MAIN} />,
  },
  { label: 'Tin tức', route: SCREEN_NAME.News },
];