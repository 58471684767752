import React from 'react';
import { usePropsStyle } from 'react-quick-style-components';
import { ContentPlaceholder, RatioCol } from '../../elements';

import { listBook6 } from './listBook6';
import { listBook4 } from './listBook4';
import { newfeed } from './newfeed';
import { circle } from './circle';
import { lineText } from './lineText';

interface IProps {
  width: number | string,
  [anyProps: string]: any,
}

const PlaceholderHOC = (exportedProps) => (props : IProps) => {
  const propsStyle = usePropsStyle(props);
  return (
    <RatioCol ratio={exportedProps.width / exportedProps.height} style={propsStyle} width={props.width}>
      <ContentPlaceholder
        width={props.width || exportedProps.width}
        height={props.height || exportedProps.height}
        svgViewBox={`0 0 ${exportedProps.width} ${exportedProps.height}`}
      >
        {!!exportedProps.renderChildren && exportedProps.renderChildren()}
      </ContentPlaceholder>
    </RatioCol>
  );
}

export default {
  ListBook6: PlaceholderHOC(listBook6),
  ListBook4: PlaceholderHOC(listBook4),
  Newfeed: PlaceholderHOC(newfeed),
  Circle: PlaceholderHOC(circle),
  LineText: PlaceholderHOC(lineText),
}

// export default Placeholder;