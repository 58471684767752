import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { ISearchAndFilterOptions } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';

interface ISetters {
  [additionSetter: string]: (v : any) => void,
}

class Manga extends PersistReady {

  constructor() {
    super();
  }

  state = {
    
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {

    return [
      {  },
      {
        getList: this.getList,
        create: this.create,
        update: this.update,
        detail: this.detail,
        checkPasswordChapter: this.checkPasswordChapter,
        extraInfoDetail: this.extraInfoDetail,
        listExtraInfo: this.listExtraInfo,
        listMangaHomePage: this.listMangaHomePage,
        saveReadingProgress: this.saveReadingProgress,
      },
    ];
  }

  getList = async ({ visibility, page = 1, pageSize = 20, options } : { visibility: string, page: number, pageSize?: number, options?: ISearchAndFilterOptions }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/manga/list', { visibility, page, pageSize, options });
      return res.data;
    });
  }

  create = async ({ name, content, image, chapters, visibility, genres }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/manga', { name, content, image, chapters, visibility, genres });
      return res.data;
    });
  }

  update = async ({ id, name, content, image, chapters, visibility, genres, promoted }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/manga/update', { id, name, content, image, chapters, visibility, genres, promoted });
      return res.data;
    });
  }

  detail = async id => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/manga/'+id, {  });
      return res.data;
    });
  }

  checkPasswordChapter = async ({ id, password }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/manga/check-password-chapter', { id, password });
      return res.data;
    });
  }

  extraInfoDetail = async (mangaId, chapterId, url) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + `/manga/${mangaId}/chapter/${chapterId}/extra-info`, {
        url
      });
      return res.data;
    });
  }

  listExtraInfo= async (mangaId, chapterId) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + `/manga/${mangaId}/chapter/${chapterId}/extra-info`, {});
      return res.data;
    });
  }

  listMangaHomePage= async () => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + `/manga/list/home`, {});
      return res.data;
    });
  }

  saveReadingProgress = async ({ mangaId, chapterId }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + `/manga/save-reading-progress`, { mangaId, chapterId });
      return res.data;
    });
  }
}

export default new Manga();
