import React, { useState } from 'react';
import { Row, Col, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ERROR, useFontSizeStyle } from 'const';
import { useNavFunc, A } from 'navigation';
import { SCREEN_NAME, DEVICE_INFO } from 'const';
import { ICON } from 'assets';
import { Button, Checkbox, Input, HTMLView, MainContainer, Popup, } from 'components';
import { StyleSheet } from 'react-native';
import Store from 'store'

const Terms = () => {
  const [content, setContent] = useState('');
  const [{}, mApi] = Store.Misc.createMiscStore();
  const rV = useDynamicResponsiveValue();
  const { goBackOrTo } = useNavFunc();

  const submit = async () => {
    if (!content) return Popup.showError(ERROR.co_fill_all_required_form);
    await mApi.giveFeedback(content);
    Popup.show('Readism.vn cảm ơn bạn về ý kiến trên. Chúc bạn có những trải nghiệm vui vẻ.', [
      { text: 'OK', solid: true, outline: false, onPress: () => goBackOrTo(SCREEN_NAME.Home) }
    ]);
  }
  return (
    <Scroll flex1>
      <Col padding20 flex1 bgWhite>
        <Col width={rV({ xs: 320, lg: 364 })} alignSelf='center'>
          <ICON.ReadismWithTextLogo style={{ alignSelf: 'center' }} />
        </Col>
        <MainContainer marginTop32>
          <Text><Text bold colorMain>Readism.vn</Text> đang ở những giai đoạn phát triển đầu tiên, nếu bạn thấy có những thứ cần chỉnh sửa, hãy không ngần ngại nói cho chúng tôi. Ý kiến đóng góp của bạn sẽ mang rất nhiều ý nghĩa với chúng tôi. Xin cảm ơn.</Text>
          <Text marginTop10><Text fontStyle='italic' bold>Lưu ý:</Text> Trong trường hợp bạn cần chúng tôi phản hồi lại, vui lòng gửi tin nhắn riêng cho <A route={SCREEN_NAME.UserProfile} params={{ id: '354756434161', name: 'admin-readism' }} style={{ fontWeight: 'bold' }}>admin</A></Text>
          <Text marginTop10>Sử dụng khung bình luận để ý kiến của bạn đến được với tác giả của bài viết</Text>

          <Input
            marginTop24
            multiline
            required
            inputStyle={{ padding: 12 }}
            placeholder="Những điểm bạn thích, những điểm cần cải thiện..."
            label="Ý kiến"
            value={content} onChange={setContent}
          />

          <Col marginTop24>
            <Button solid large width150 height43 text='Gửi' onPress={submit} />
          </Col>
        </MainContainer>
        <Col style={styles.cornerCloseBtn} onPress={() => goBackOrTo(SCREEN_NAME.Home)}>
          <ICON.Close />
        </Col>
      </Col>
    </Scroll>
  );
};

const styles = StyleSheet.create({
  cornerCloseBtn: {
    position: 'absolute',
    top: 12,
    right: 12,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Terms;