import 'react-native-gesture-handler';
import React, { useEffect, useState, Suspense } from 'react';
import { useWindowDimensions } from 'react-native';
// import Navigation from './src/navigation/Navigation';
import { navigationRef } from './src/navigation';
import { Col, Img, Text } from 'react-quick-style-components';
import { setupStyle, DEVICE_INFO, SCREEN_NAME } from './src/const';
import Store from './src/store';
import { EventHelper } from './src/helpers';
import { ModalContainer, GiveFeedback, OnScreenNotification } from './src/components';
import { useGoogleFacebookAuth } from './src/screens/auth/useGoogleFacebookAuth';
import { Entypo } from '@expo/vector-icons';
import GlobalEvent from 'js-events-listener';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const UNDER_MAINTAINCE = false;

const Navigation = React.lazy(() => import('./src/navigation/Navigation'));

Sentry.init({
  dsn: "https://4800d8b5c2c04ac6834777449d8b911f@o263647.ingest.sentry.io/5945365",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
});

import './webfont.js';
if (window.OneSignal) {
  window.OneSignal.push(function() {
    window.OneSignal.on('notificationDisplay', function(event) {
      /*
        {
          "id":"bb664c79-fe71-49a4-9b3f-1fa0bbf26f92",
          "heading":"Thông báo",
          "content":"Mai Hoa đã thích bài của bạn",
          "icon":"https://img.onesignal.com/permanent/c14c87c2-0e79-465d-a57a-7b3109b34682"
        }
      */
      console.warn('OneSignal notification displayed:', event);
      GlobalEvent.emit('NOTIFICATION_DISPLAYED', event);
    });
  });
}
window.testSendNoti = () => {
  GlobalEvent.emit('NOTIFICATION_DISPLAYED', { id: Math.random(), content: 'Test ' + Math.random() });
}

Img.ImageError = () => <Entypo name="image" size={24} color="rgba(0,0,0,0.3)" />

setupStyle({
  defaultFont: 'Inter_400Regular',
  bold: 'Inter_700Bold',
  extraBold: 'Inter_800ExtraBold',
  semiBold: 'Inter_600SemiBold',
  light: 'Inter_300Light',
  medium: 'Inter_500Medium',
});

// EventHelper.initWindowGlobalListener();

function Undermantainance() {
  useEffect(() => {
    document.querySelector('.readism-loader')?.remove();
  }, []);
  return (
    <Col flex1 middle>
      <Text>Readism đang bảo trì, vui lòng quay lại sau ít phút</Text>
      <Text>Chúng tôi xin lỗi vì sự bất tiện này</Text>
    </Col>
  )
}

function AppWeb() {
  // const { height  } = useWindowDimensions();
  // init firebase
  const { getFacebookLoginData } = useGoogleFacebookAuth();
  const [storeReady, setStoreReady] = useState(false);
  useEffect(() => {
    Store.User.onReady().then(() => {
      setStoreReady(true);
      Store.Message.getMyListConversation({ page: 1, pageSize: 1000 });
      document.querySelector('.readism-loader')?.remove();
    });
  }, []);
  if (!storeReady) {
    return <Col bgWhite />;
  }
  return (
    <ModalContainer>
      <Suspense fallback={<div />}>
        <Navigation />
      </Suspense> 
      <GiveFeedback onPress={() => navigationRef?.current.navigate(SCREEN_NAME.Feedback)} />
      <OnScreenNotification />
    </ModalContainer>
  );
}

export default UNDER_MAINTAINCE ? Undermantainance : AppWeb;