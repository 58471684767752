import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { IUser, IConversation, IMessage } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';
import _ from 'lodash';
import UserStore from './User.Store';
interface ISetters {
  setListConversation?: (v : any) => void,
  setMessageHistory?: (v : any) => void,
  setUnread?: (v : any) => void,
  [additionSetter: string]: (v : any) => void,
}

interface IGroupOfHistory {
  [conversationId: string]: Array<IMessage>,
}

interface IState {
  listConversation: Array<IConversation>,
  messageHistory: IGroupOfHistory,
  unread: number,
}

class Message extends PersistReady {

  constructor() {
    super();
    GlobalEvent.on('LOGOUT', () => {
      this.updateState({
        listConversation: [],
          messageHistory: {},
          unread: 0,
      });
    });
  }

  state : IState = {
    listConversation: [],
    messageHistory: {},
    unread: 0,
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {
    const [listConversation, setListConversation] = useGlobalState<Array<IConversation>>(this.state.listConversation, 'messageStore_listConversation');
    const [messageHistory, setMessageHistory] = useGlobalState<IGroupOfHistory>(this.state.messageHistory, 'messageStore_messageHistory');
    const [unread, setUnread] = useGlobalState<number>(this.state.unread, 'messageStore_unread');
    if (!this.setters.listConversation) this.setters.setListConversation = setListConversation;
    if (!this.setters.setMessageHistory) this.setters.setMessageHistory = setMessageHistory;
    if (!this.setters.setUnread) this.setters.setUnread = setUnread;
    useEffect(() => {
      this.updateState({ listConversation, messageHistory, unread });
    }, [listConversation, messageHistory, unread]);
    return [
      { listConversation, messageHistory, unread },
      {
        setUnread,
        getHistoryMessage: this.getHistoryMessage,
        getMyListConversation: this.getMyListConversation,
        createNewConversation: this.createNewConversation,
        sendMessage: this.sendMessage,
        conversationDetail: this.conversationDetail,
        checkConversationExists: this.checkConversationExists,
      }
    ];
  }

  getHistoryMessage = async (conversationId, { page, pageSize }) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + `/users/me/conversation/${conversationId}/messages`, { page, pageSize });
      if (res.data && res.data && res.data.data && res.data.data.rows) {
        
        const { messageHistory, listConversation } = this.state;
        const currentMessage = messageHistory[conversationId] || [];
        const updatedMessage = res.data.data.rows;
        const combined = _.uniqWith([...updatedMessage, ...currentMessage as Array<IMessage>], _.isEqual).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
        if (this.setters.setMessageHistory) {
          this.setters.setMessageHistory({
            ...this.state.messageHistory,
            [conversationId]: combined,
          });
        }

        const markedRead = res.data.data.markedRead || 0;
        const conversationIndex = listConversation.findIndex(val => val.id === conversationId);
        if (conversationIndex !== -1) {
          const thisConversation = Object.assign({}, listConversation[conversationIndex]);
          thisConversation.userData[UserStore.state.user.id].unread = Math.max(0, thisConversation.userData[UserStore.state.user.id].unread - markedRead);
          listConversation[conversationIndex] = thisConversation;
          this.setters.setListConversation(listConversation);
          this.setters.setUnread(Math.max(0, this.state.unread - markedRead));
        }
      }
      return res.data;
    });
  }

  getMyListConversation = async ({ page, pageSize }) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + `/users/me/conversations`, { page, pageSize });
      if (res.data.success && res.data.data.rows) {
        !!this.setters.setListConversation && this.setters.setListConversation(res.data.data.rows);
      }
      if (res.data.success && res.data.data.countUnread) {
        !!this.setters.setUnread && this.setters.setUnread(res.data.data.countUnread);
      }
      return res.data;
    });
  }

  createNewConversation = async ({ otherUserId, message }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + `/users/me/new-conversation`, { otherUserId, message });
      if (res.data.success && res.data.data) {
        const newList = this.state.listConversation.slice();
        newList.unshift(res.data.data);
        this.setters.setListConversation(newList);
      }
      return res.data;
    });
  }

  sendMessage = async ({ conversationId, message }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + `/users/me/send-message`, { conversationId, message });
      if (res.data && res.data.data) {
        const { messageHistory, listConversation } = this.state;
        const currentMessage = messageHistory[conversationId] || [];
        const updatedMessage = [ res.data.data ];
        const combined = _.uniqWith([...updatedMessage, ...currentMessage as Array<IMessage>], _.isEqual).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
        if (this.setters.setMessageHistory) {
          this.setters.setMessageHistory({
            ...this.state.messageHistory,
            [conversationId]: combined,
          });
        }

        // update last message
        const conversationIndex = listConversation.findIndex(val => val.id === conversationId);
        if (conversationIndex !== -1) {
          const newList = listConversation.slice();
          newList[conversationIndex].lastMessage = {
            ...res.data.data,
            sender: res.data.data.createdById,
          };
          this.setters.setListConversation(newList);
        }
      }
      return res.data;
    });
  }

  conversationDetail = async (id) => {
    return VarHelper.erria(async () => {
      if (this.state.listConversation.length > 0) {
        const detail = this.state.listConversation.find(val => val.id === id);
        if (!!detail) return { data: detail };
      }
      const res = await Request.get(HOST + `/users/me/conversation/${id}`, {});
      return res.data;
    });
  }

  checkConversationExists = async (otherUserId) => {
    return VarHelper.erria(async () => {
      if (this.state.listConversation.length > 0) {
        const detail = this.state.listConversation.find(val => !!val.userIds[otherUserId]);
        if (!!detail) return { data: detail };
      }
      const res = await Request.post(HOST + `/users/me/check-conversation`, { otherUserId });
      return res.data;
    });
  }
}

export default new Message();
