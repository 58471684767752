import React from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { A } from 'navigation';
import { COLOR, SCREEN_NAME } from 'const';
import { ICON } from 'assets';
import { StyleSheet } from 'react-native';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { VarHelper } from 'helpers';

const UserName = ({ id, name, type }) => {

  const color = (() => {
    if (type === 'ADMIN') return COLOR.MAIN;
    if (type === 'USER') return COLOR.FONT;
    if (type === 'AUTHOR') return COLOR.FONT;
    if (type === 'UNVERIFY-USER') return COLOR.GRAY_500;
  })();

  const icon = () => {
    if (type === 'AUTHOR') return (
      <Col marginLeft3 width15 height15 middle bgMain borderRadius10>
        <ICON.Check color='white' width={25/2.5} height={18/2.5} />
      </Col>
    )
    if (type === 'ADMIN') return (
      <ICON.Shield fill={COLOR.MAIN} width={15} height={15} style={styles.admin} />
    );
    return null;
  };

  return (
    <A route={SCREEN_NAME.UserProfile} params={{ id, name: VarHelper.toSlug(name) }}>
      <Text><Text bold color={color}>{name}</Text>{icon()}</Text>
    </A>
  );
};

export const UserNameWithoutA = ({ id, name, type, onPress }) => {

  const color = (() => {
    if (type === 'ADMIN') return COLOR.MAIN;
    if (type === 'USER') return COLOR.FONT;
    if (type === 'AUTHOR') return COLOR.FONT;
    if (type === 'UNVERIFY-USER') return COLOR.GRAY_500;
  })();

  const icon = () => {
    if (type === 'AUTHOR') return (
      <Col width15 height15 middle bgMain borderRadius10>
        <ICON.Check color='white' width={25/2.5} height={18/2.5} />
      </Col>
    )
    if (type === 'ADMIN') return (
      <ICON.Shield fill={COLOR.MAIN} width={15} height={15} style={styles.admin} />
    );
    return null;
  };

  return (
    <Text onPress={onPress}>
      <Text bold color={color}>{name}</Text> {icon()}
    </Text>
  );
};

const styles = StyleSheet.create({
  admin: {
    transform: [
      { translateY: 3 },
    ],
    marginLeft: 3,
  }
})

export default UserName;