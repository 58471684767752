import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={69}
      height={68}
      viewBox="0 0 69 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#prefix__clip0)">
        <Path
          d="M41.06 63.086V49.88c8.475-.77 12.711-6.906 12.711-18.405h-9.94a5.313 5.313 0 01-5.312-5.312V10.227a5.313 5.313 0 015.312-5.313H62.16a5.313 5.313 0 015.313 5.313v20.34c0 20.688-8.803 31.529-26.411 32.519zM4.67 63.086V49.88c8.474-.77 12.71-6.906 12.71-18.405H7.44a5.313 5.313 0 01-5.312-5.312V10.227a5.313 5.313 0 015.313-5.313h18.328a5.313 5.313 0 015.312 5.313v20.34c0 20.688-8.803 31.529-26.41 32.519z"
          fill="#63B199"
        />
        <Path
          d="M4.67 64.414a1.329 1.329 0 01-1.328-1.328V49.88c0-.687.524-1.26 1.208-1.322 7.416-.675 11.18-5.833 11.483-15.755H7.44A6.648 6.648 0 01.8 26.163V10.227a6.648 6.648 0 016.64-6.641H25.77a6.648 6.648 0 016.64 6.64v4.25a1.328 1.328 0 01-2.656 0v-4.25a3.989 3.989 0 00-3.984-3.984H7.44a3.989 3.989 0 00-3.985 3.985v15.936a3.989 3.989 0 003.985 3.984h9.94c.733 0 1.328.595 1.328 1.328 0 5.997-1.148 10.701-3.413 13.98-2.135 3.092-5.259 4.972-9.298 5.6v10.603c15.981-1.43 23.755-11.615 23.755-31.09V25.81a1.328 1.328 0 012.656 0v4.757c0 10.609-2.321 18.846-6.9 24.483-4.626 5.698-11.613 8.847-20.764 9.362a1.24 1.24 0 01-.075.002zM62.16 3.586H43.83a6.648 6.648 0 00-6.64 6.64v15.937a6.648 6.648 0 006.64 6.64h8.592c-.302 9.922-4.067 15.08-11.483 15.755a1.328 1.328 0 00-1.207 1.322v13.206a1.328 1.328 0 001.402 1.326c9.152-.515 16.138-3.665 20.765-9.362 4.579-5.637 6.9-13.874 6.9-24.483v-20.34a6.648 6.648 0 00-6.64-6.641zm3.984 26.981c0 19.476-7.774 29.661-23.755 31.091V51.055c4.039-.628 7.163-2.508 9.298-5.6 2.264-3.279 3.412-7.982 3.412-13.98 0-.733-.594-1.328-1.328-1.328h-9.94a3.989 3.989 0 01-3.984-3.984V10.227a3.989 3.989 0 013.984-3.985H59.77v9.833a1.328 1.328 0 002.656 0V6.252a3.989 3.989 0 013.719 3.975v20.34z"
          fill="#333"
        />
        <Path
          d="M61.097 22.02a1.328 1.328 0 01-1.328-1.328c0-.733.594-1.334 1.328-1.334.733 0 1.328.588 1.328 1.321v.013c0 .734-.595 1.328-1.328 1.328zM31.081 21.499a1.328 1.328 0 01-1.328-1.328v-.028a1.328 1.328 0 012.656 0v.028c0 .733-.594 1.328-1.328 1.328z"
          fill="#333"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" transform="translate(.8)" d="M0 0h68v68H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default SvgComponent
