import React, { memo } from 'react';
import { Img, Col, Text } from 'react-quick-style-components';
import { StyleSheet } from 'react-native';
import { RatioCol } from '../elements';
import { STYLES } from 'const';

interface IAvatarProps {
  uri: string,
  size?: number,
  name: string,
  onPress?: any,
}

const Avatar = (props : IAvatarProps) => {
  const imageStyle = props.size ? {
    width: props.size,
    height: props.size,
    borderRadius: props.size / 2,
  } : {
    width: '100%',
    aspectRatio: 1,
    borderRadius: '50%',
  };
  const placeholderSize = props.size ? {
    width: props.size - 2,
    height: props.size - 2,
    borderRadius: (props.size - 2) / 2,
    borderWidth: 1,
    borderColor: 'white',
  } : {
    width: '100%',
    paddingBottom: '100%',
    borderRadius: '50%',
  };

  // name: Le Huy -> LH
  const placeholderText = !!props.name ? props.name.split(' ').map(val => !!val ? val[0].toUpperCase() : '').join('') : '';

  const calculateFontSize = (() => {
    if (typeof props.size === 'number' && props.size > 100) return 32;
    if (typeof props.size === 'number' && props.size <= 32) return 12;
    return undefined;
  })();

  const renderContainer = ({ children, onPress }) => {
    if (props.size) return (
      <Col width={props.size} height={props.size} borderRadius={props.size / 2} overflow='hidden' onPress={onPress}>
        {children}
      </Col>
    )
    return (
      <RatioCol width='100%' ratio={1} borderRadius='50%' overflow='hidden' onPress={onPress}>
        {children}
      </RatioCol>
    )
  }

  const Container = renderContainer;

  return (
    <Container onPress={props.onPress}>
      {props.uri ? (
        <Img source={{ uri: props.uri }} style={STYLES.all100p} resizeMode="cover" /> 
      ) : (
        <Col style={STYLES.all100p} bgMain>
          <Col absoluteFill middle>
            <Text bold colorWhite style={styles.moveUpABit} fontSize={calculateFontSize}>{placeholderText}</Text>
          </Col>
        </Col>
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  moveUpABit: {
    transform: [
      { translateY: -2 }
    ]
  },
});

export default memo(Avatar);