import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Text, Scroll, Img } from 'react-quick-style-components';
import { COLOR, STYLES } from 'const';
import { DragableGrid, Popup, RatioCol } from 'components';
import { ICON } from 'assets';
import { Image } from 'react-native';
import { ILocalFileUpload } from 'type';
import { FileDrop } from 'react-file-drop';

interface IMangaImagesEditorProps {
  images: Array<ILocalFileUpload>,
  onChange: any,
  onPreview: any,
  height: number,
}

const MangaImagesEditor = (props: IMangaImagesEditorProps) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [dropZoneWidth, setDropZoneWidth] = useState(0);
  const inputRef = useRef<any>();
  const convertToUrl = (file): Promise<string> => new Promise((resolve) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      resolve(e.target.result as string);
    }
    reader.readAsDataURL(file);
  });

  const sortByName = (files: Array<{ name: string }>) => {
    const compareName = (name1, name2) => {
      const reg = /\d+/;
      const match1 = name1.match(reg);
      const match2 = name2.match(reg);
      if (!match1) return -1;
      if (!match2) return 1;
      return Number(match1[0]) < Number(match2[0]) ? -1 : 1;
    };
    return files.sort((a, b) => compareName(a.name, b.name));
  }

  const handleFiles = async files => {
    if (files.length === 0) return;
    const results = [];
    const promiseArr = files.map(async (file: any, i) => {
      try {
        const dataUrl = await convertToUrl(file);
        results[i] = {
          path: dataUrl,
          mime: file.type,
          name: file.name,
          raw: file,
        };
      } catch (err) { }
    });
    await Promise.all(promiseArr);
    Popup.show('Bạn có muốn sắp xếp lại ảnh theo tên? Lưu ý tự động sắp xếp có thể sẽ thay đổi cách bạn sắp xếp thủ công trước đó.', [
      { text: 'Có', solid: true, outline: false, onPress: () => {
        props.onChange(sortByName([
          ...props.images,
          ...results,
        ]));
      } },
      { text: 'Không', solid: false, outline: true, onPress: () => {
        props.onChange([
          ...props.images,
          ...results,
        ]);
      } },
    ])
    
  }

  const onInputChange = e => {
    const files = Array.from(e.target.files);
    handleFiles(files);
  };

  const removeImage = i => {
    props.onChange([
      ...props.images.slice(0, i),
      ...props.images.slice(i + 1, props.images.length),
    ]);
  }

  const handleDrop = (files, event) => {
    handleFiles(Array.from(files));
    setIsDragOver(false);
  };
  const onDragOver = () => {
    setIsDragOver(true);
  };
  const onDragLeave = () => {
    setIsDragOver(false);
  };

  const updateDropZoneWidth = (e) => {
    const { width } = e.nativeEvent.layout;
    console.log('width', width);
    setDropZoneWidth(width);
  }

  const { rowHeight, imageWidth, imageHeight, boxesPerRow } = (() => {
    if (dropZoneWidth === 0) return { rowHeight: 1, imageWidth: 1, imageHeight: 1, boxesPerRow: 1, };
    const boxesPerRow = dropZoneWidth > 700 ? 5 : 2;
    const imageWidth = dropZoneWidth * 0.7 / boxesPerRow;
    const imageHeight = imageWidth * 133 / 119;
    const rowHeight = imageHeight + 30;

    return { rowHeight, imageWidth, imageHeight, boxesPerRow };
  })();

  const renderItem = (val, index) => {
    return (
      <Col margin12 width100p middle>
        {/* <RatioCol width='100%' ratio={119 / 133}>
          <Img source={{ uri: val.path }} style={STYLES.all100p} />
        </RatioCol> */}

        <Col width={imageWidth} height={imageHeight}>
          <Img source={{ uri: val.path }} style={STYLES.all100p} />
          <Col
            width27 height27 borderRadius={27 / 2}
            bgWhite middle absolute top={-27 / 2} right={-27 / 2}
            borderWidth1 borderColor={COLOR.GRAY_300}
            onPress={() => removeImage(index)}
          >
            <ICON.Close fill={COLOR.GRAY_300} width={15} height={15} />
          </Col>
        </Col>
        <Text caption center marginTop4 paddingHorizontal4>{val.name}</Text>

      </Col>
    );
  };

  return (
    <Col>
      <Row style={{ flexWrap: 'wrap', marginBottom: 24 }}>
        <Row
          onPress={() => inputRef.current?.click()}
          bgMain borderRadius10 width197 height40 middle marginRight16 marginBottom12
        >
          <ICON.Upload />
          <Text marginLeft8 colorWhite>Chọn ảnh để tải lên</Text>
        </Row>
        <Row
          onPress={() => props.onChange([])}
          borderColor={COLOR.MAIN} borderWidth1 borderRadius10 width197 height40 middle marginRight16 marginBottom12
        >
          <ICON.Trash fill={COLOR.MAIN} />
          <Text marginLeft8 colorMain>Xóa tất cả</Text>
        </Row>
        <Row
          onPress={props.onPreview}
          borderColor={COLOR.MAIN} borderWidth1 borderRadius10 width197 height40 middle marginBottom12
        >
          <ICON.EyeOpen fill={COLOR.MAIN} />
          <Text marginLeft8 colorMain>Xem thử</Text>
        </Row>
      </Row>

      <input ref={inputRef}
        onChange={onInputChange}
        style={{ display: 'none' }} type="file" multiple accept="image/*" />

      <FileDrop
        // onTargetClick={() => inputRef.current?.click()}
        onDrop={handleDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        <Col
          height={props.height} borderRadius10
          borderStyle={isDragOver ? 'solid' : 'dashed'}
          borderWidth2 borderColor={isDragOver ? COLOR.MAIN : COLOR.BORDER}
          onLayout={updateDropZoneWidth}
        >
          {!!props.images && props.images.length > 0 && dropZoneWidth !== 0 ? (
            <Scroll height="100%">
              <DragableGrid
                data={props.images}
                options={{
                  boxesPerRow: boxesPerRow,
                  rowHeight: rowHeight,
                }}
                renderItem={renderItem}
                onChange={props.onChange}
                width={dropZoneWidth}
              />
            </Scroll>
          ) : (
            <Col height100p backgroundColor={COLOR.GRAY_100} middle borderRadius10>
              <Text>Kéo thả ảnh để tải lên</Text>
            </Col>
          )}
        </Col>
      </FileDrop>
    </Col>
  );
}

export default MangaImagesEditor;