import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { IUser } from 'type';
import erria from 'erria/decorator';
import { VarHelper } from 'helpers';

interface ISetters {
  setListGenres?: (v : any) => void,
  [additionSetter: string]: (v : any) => void,
}

class Genres extends PersistReady {

  constructor() {
    super();
  }

  state = {
    listGenres: [],
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {
    const [listGenres, setListGenres] = useGlobalState(this.state.listGenres, 'genresStore_listGenres');
    if (!this.setters.setListGenres) this.setters.setListGenres = setListGenres;

    useEffect(() => {
      this.updateState({ listGenres });
    }, [listGenres]);

    return [
      { listGenres },
      {
        getList: this.getList,
        upsert: this.upsert,
        remove: this.remove,
      }
    ];
  }

  getList = async (type = '') => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/genres', { type });
      if (res.data.data) {
        this.setters.setListGenres(res.data.data);
      }
      return res.data;
    });
  }

  upsert = async (data) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/genres/upsert', data);
      if (res.data.data) {
        const thisGenre = res.data.data;
        const listGenres = this.state.listGenres.slice();
        const findExistingIndex = listGenres.findIndex(val => val.id === thisGenre.id);
        if (findExistingIndex === -1) {
          listGenres.push(thisGenre);
        } else {
          listGenres[findExistingIndex] = thisGenre;
        }
        this.setters.setListGenres(listGenres);
      }
      return res.data;
    });
  }

  remove = async (id) => {
    return VarHelper.erria(async () => {
      const res = await Request.post(HOST + '/genres/remove', { id });
      if (res.data.success) {
        const findExistingIndex =  this.state.listGenres.findIndex(val => val.id === id);
        if (findExistingIndex !== -1) this.setters.setListGenres([
          ...this.state.listGenres.slice(0, findExistingIndex),
          ...this.state.listGenres.slice(findExistingIndex + 1, this.state.listGenres.length),
        ]);
      }
      return res.data;
    });
  }
}

export default new Genres();
