import React from 'react';
import { Col, Row, usePropsStyle, Text } from 'react-quick-style-components';
import { IActivity, IQuote } from 'type';
import { useNavFunc } from 'navigation';
import { COLOR, ERROR, SCREEN_NAME, STYLES } from 'const';
import { VarHelper, TimeHelper } from 'helpers';
import QuoteNoCreatorItem from '../items/QuoteNoCreatorItem';
import { Avatar, PostTimestamp } from '../../elements';
import UserName from '../UserName';

interface IQuoteItemProps {
  data: {
    id?: string,
    data: IQuote,
  },
  [anyProp: string]: any,
}

const QuoteFeed = (props: IQuoteItemProps) => {
  const { navigate, navigation, goWithPreData } = useNavFunc();
  const propStyle = usePropsStyle(props);

  const { data } = props.data;
  // console.log('data', data);

  const goToUserProfile = () => {
    navigation.navigate(SCREEN_NAME.UserProfile, { id: data.createdBy.id, name: VarHelper.toSlug(data.createdBy.name) });
  }

  const goToFeedDetail = () => {
    goWithPreData(SCREEN_NAME.QuoteDetail, { id: data.id }, data);
  }

  const shouldShowLikeCommentActivity = !!data?.lastComment?.createdAt;

  return (
    <QuoteNoCreatorItem data={data as IQuote} style={propStyle}>
      {Boolean(shouldShowLikeCommentActivity) && (
        <Row alignItems="flex-start" marginBottom16 paddingBottom8 borderBottomColor={COLOR.BORDER} borderBottomWidth1>
          <Col flex1>
            <Text><UserName  {...data.createdBy} /> đã bình luận vào bài đăng</Text>
            <Col marginTop4 onHoverStyle={{ opacity: 1 }} useNestedHover onPress={goToFeedDetail}>
              <Text onHoverStyle={{ textDecorationLine: 'underline' }} color={COLOR.GRAY_500}>{TimeHelper.fromNow(data?.lastComment?.createdAt)}</Text>
            </Col>
          </Col>
        </Row>
      )}
      <Row flex1>
        <Col width48 onPress={goToUserProfile}>
          <Avatar size={48} name={data.createdBy.name} uri={data.createdBy.avatar} />
        </Col>
        <Col marginLeft16 flex1>
          <Text><UserName  {...data.createdBy} /> đã thêm một trích dẫn</Text>
          {Boolean(!shouldShowLikeCommentActivity) && (
            <PostTimestamp
              onPress={goToFeedDetail}
              updatedAt={data.updatedAt} createdAt={data.createdAt}
            />
          )}
        </Col>
      </Row>
    </QuoteNoCreatorItem>
  )
};

export default QuoteFeed;
