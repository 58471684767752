import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { CMSLayout, Button, Input, Uploader, Select } from 'components';
import { Linking } from 'react-native';
import { useNavFunc, Reset } from 'navigation';
import { useRoute } from '@react-navigation/native';
import { BOOK_VISIBILITY, COLOR, DEVICE_INFO, ERROR, SCREEN_NAME } from 'const';
import { ICON } from 'assets';
import Store from 'store';
import { IBook } from 'type';
import BookListReview from './CMS.EditBook.ListReview';
import { VarHelper } from 'helpers';

const TAB = {
  BOOK: 'BOOK',
  REVIEW: 'REVIEW'
};

const CMSEditBook = () => {
  const { goBackOrTo, getScreenPreData, navigation, navigate } = useNavFunc();
  const [btnText, setBtnText] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [buyLink, setBuyLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [genres, setGenres] = useState([]);
  const [authorName, setAuthorName] = useState('');
  const [publisher, setPublisher] = useState('');
  const [visibility, setVisibility] = useState('draft');
  const [errorMessage, setErrorMessage] = useState('');
  const [creator, setCreator] = useState('');
  const [localFile, setLocalFile] = useState({
    path: '',
    mime: '',
    name: '',
    raw: undefined,
  });
  const [tab, setTab] = useState(TAB.BOOK);
  const [reviewData, setReviewData] = useState({ count: 0, rows: [] });
  const [bookData, setBookData] = useState<IBook>();

  const rV = useDynamicResponsiveValue();
  const [{ }, bookApi] = Store.Book.createBookStore();
  const [{ listGenres }, gApi] = Store.Genres.createStore();
  const [{ }, rApi] = Store.Reviews.createStore();
  const uploaderRef = useRef<{ openFileDialog: Function, uploadLocal: Function }>();
  const route = useRoute();

  const id = route.params?.id;
  if (!id) {
    return (
      <Col middle flex1 bgWhite>
        <Text>Đường dẫn không hợp lệ..</Text>
        <Reset to={SCREEN_NAME.CMSHome} timeout={1500} />
      </Col>
    );
  }

  useEffect(() => {
    gApi.getList('BOOK');
  }, []);

  const getInitialData = async (force = false) => {
    let data = getScreenPreData(id);
    if (!data || force) {
      const [res, err] = await bookApi.detailBook(id);
      if (err) return alert(ERROR.parseError(err));
      if (res.error) return alert(ERROR.parseError(res.error));
      data = res.data;
    }
    setBookData(data);
    setName(data.name);
    setAuthorName(!!data.authors && data.authors.length > 0 ? data.authors[0].name : '');
    setLocalFile({
      ...localFile,
      path: !!data.images && data.images.length > 0 ? data.images[0] : '',
    });
    setDescription(data.content);
    setBuyLink(!!data.buyLinks && data.buyLinks.length > 0 ? data.buyLinks[0] : '');
    setPublisher(data.publisher);
    setGenres(data.genres.map(val => ({ label: val.name, value: val.id })));
    setVisibility(data.visibility);
    getListReview(id);
    setCreator(data.createdBy?.name);
  }

  const getListReview = async id => {
    const [res, err] = await rApi.adminGetList({
      visibility: 'all',
      page: 1,
      pageSize: 20,
      options: {
        filter: [
          { label: 'bookId', value: id },
        ]
      }
    });
    if (res && res.data) {
      setReviewData(res.data);
    }
  }

  const submit = async () => {
    if (!uploaderRef.current) return setErrorMessage(ERROR.co_error);
    if (!name || !authorName) return setErrorMessage(ERROR.co_fill_all_required_form);
    setLoading(true);
    let image = localFile.path;
    if (localFile.raw) {
      const [bookImage, err] = await uploaderRef.current.uploadLocal(localFile);
      if (err) {
        setLoading(false);
        return setErrorMessage(ERROR.parseError(err));
      }
      image = bookImage
    }
    const [res, err2] = await bookApi.updateBook({
      id,
      name,
      authors: [{ id: 0, name: authorName, avatar: '' }],
      content: description,
      buyLinks: !buyLink ? [] : [buyLink],
      genres: genres.map(val => ({ id: val.value, name: val.label })),
      publisher,
      images: !!image ? [image] : undefined,
      visibility,
    });
    setLoading(false);
    if (err2) return setErrorMessage(ERROR.parseError(err2));
    if (res.error) return setErrorMessage(ERROR.parseError(res.error));
    setBtnText('Sửa sách thành công..');
    setTimeout(() => {
      setBtnText('');
      setTimeout(() => {
        goBackOrTo(SCREEN_NAME.CMSListBook)
      }, 500)
    }, 500);
  };

  // useEffect(() => {
  //   getInitialData();
  // }, [id]);
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getInitialData(true);
    });
    return unsubscribe;
  }, [id]);

  const renderTabReview = () => {
    return (
      <Col display={tab === TAB.REVIEW ? 'flex' : 'none'}>
        <BookListReview
          data={reviewData}
          bookData={bookData}
          onUpdateBookData={setBookData}
        />
      </Col>
    );
  }

  const renderTabBook = () => {
    return (
      <Col display={tab === TAB.BOOK ? 'flex' : 'none'}>
        <Row marginTop24 alignItems="flex-start">
          {useMemo(() => (
            <Col
              width160 height256 borderColor="#C9C9C9" borderWidth1 borderStyle="dashed"
              borderRadius8 backgroundColor="#EFEFEF" overflow="hidden"
              onPress={() => uploaderRef.current?.openFileDialog()}
              onHoverStyle={{ backgroundColor: COLOR.GRAY_200 }}
              useNestedHover useNativeStyleProps
            >
              {Boolean(!localFile.path) ? (
                <Col flex1 middle>
                  <ICON.Plus />
                  <Text center width83 marginTop16 subtitle1 color={COLOR.GRAY_500}>Thêm ảnh bìa sách</Text>
                </Col>
              ) : (
                <Col flex1>
                  <Col absoluteFill>
                    <Img source={{ uri: localFile.path }} width100p height100p resizeMode="cover" />
                  </Col>
                  <Col absoluteFill middle opacity={0} backgroundColor="rgba(0,0,0,0.7)" onHoverStyle={{ opacity: 1 }} useNativeStyleProps>
                    <ICON.WhitePen />
                    <Text center width83 marginTop16 subtitle1 colorWhite>Sửa ảnh bìa sách</Text>
                  </Col>
                </Col>
              )}
              <Uploader
                ref={uploaderRef}
                onChangeLocal={(newLocal) => setLocalFile(newLocal)}
              />
            </Col>
          ), [localFile])}
          <Col flex1 marginLeft={rV({ xs: 8, lg: 40 })}>
            <Input label="Tên sách" required value={name} onChange={setName} placeholder="Nhập tên sách" />
            <Input label="Tên tác giả" required value={authorName} onChange={setAuthorName} placeholder="Nhập tên tác giả" marginTop16 />
            <Select
              label="Thể loại"
              value={genres}
              options={listGenres.map(val => ({ label: val.name, value: val.id }))}
              onChange={setGenres}
              isMulti
              placeholder="Tìm và chọn thể loại"
              marginTop16
            />
          </Col>
        </Row>

        <Input
          marginTop45
          label="Mô tả thêm về cuốn sách" placeholder=""
          multiline
          value={description} onChange={setDescription}
          inputStyle={{ padding: 12 }}
        />

        <Input label="Nhà xuất bản" value={publisher} onChange={setPublisher} placeholder="" marginTop24 />

        <Input
          marginTop24
          label="Link mua sách"
          placeholder=""
          value={buyLink} onChange={setBuyLink}
          inputStyle={{ padding: 12 }}
          rightIcon={{
            comp: <Text colorMain fontSize12>Xem</Text>,
            onPress: () => DEVICE_INFO.IS_WEB ? window.open(buyLink, '_blank') : Linking.openURL(buyLink),
          }}
        />

        <Input
          marginTop24
          label="Người tạo"
          placeholder=""
          value={creator} onChange={() => {}}
          inputStyle={{ padding: 12 }}
          inputProps={{
            editable: false,
          }}
        />

        <Row marginTop24 marginBottom200 justifyContent="space-between" alignItems="flex-start">
          <Col width150>
            <Select
              label="Trạng thái hiển thị"
              options={Object.keys(BOOK_VISIBILITY).map(val => ({ value: val, label: BOOK_VISIBILITY[val] }))}
              value={{ label: BOOK_VISIBILITY[visibility], value: visibility }}
              onChange={(v) => setVisibility(v.value)}
            />
          </Col>
          <Col height100p justifyContent="flex-end">
            <Button small solid text={btnText ? btnText : "Xác nhận"} loading={loading} paddingHorizontal12 onPress={submit} />
            {Boolean(errorMessage) && <Text color={COLOR.DANGER_500} marginTop8>{errorMessage}</Text>}
          </Col>
        </Row>
      </Col>
    );
  }

  const tabItemStyle = {
    active: { wrapper: { borderWidth: 2, borderColor: COLOR.MAIN }, text: { color: COLOR.MAIN, bold: true } },
    inactive: { wrapper: { borderWidth: 1, borderColor: COLOR.BORDER }, text: {} },
  }

  return (
    <Col flex1>
      <CMSLayout title="Tạo Sách">
        <Row>
          <Row flex1 flexWrap='wrap'>
            <Col
              onPress={() => setTab(TAB.BOOK)}
              padding10 borderRadius10 middle
              {...(tab === TAB.BOOK ? tabItemStyle.active.wrapper : tabItemStyle.inactive.wrapper)}
            >
              <Text {...(tab === TAB.BOOK ? tabItemStyle.active.text : tabItemStyle.inactive.text)}>Thông tin sách</Text>
            </Col>

            <Col
              onPress={() => setTab(TAB.REVIEW)}
              marginLeft8 padding10 borderRadius10 middle
              {...(tab === TAB.REVIEW ? tabItemStyle.active.wrapper : tabItemStyle.inactive.wrapper)}
            >
              <Text {...(tab === TAB.REVIEW ? tabItemStyle.active.text : tabItemStyle.inactive.text)}>Đánh giá{reviewData.count > 0 ? ` (${reviewData.count})` : ''}</Text>
            </Col>
          </Row>
          <Row>
            {Boolean(visibility === 'public') && (
              <Button
                paddingHorizontal12 small outline borderColor={COLOR.MAIN} borderWidth1
                text="Xem trang hiển thị"
                marginRight12
                onPress={() => {
                  if (DEVICE_INFO.IS_WEB) window.open(`/sach/${id}/${VarHelper.toSlug(name)}`, '_blank')
                  else navigate(SCREEN_NAME.CMSBulkCreateBook)
                }}
              />
            )}
            <Button paddingHorizontal12 small solid text="Quay lại" onPress={() => goBackOrTo(SCREEN_NAME.CMSListBook)} />
          </Row>

        </Row>

        <Col>
          {renderTabBook()}
          {renderTabReview()}
        </Col>
      </CMSLayout>
    </Col>
  );
};

export default CMSEditBook;