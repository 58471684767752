import { createContext } from 'react';
import { initQuickStyle, useDynamicResponsiveValue } from 'react-quick-style-components';
import { COLOR } from './color';
import { DEVICE_INFO } from './deviceInfo';
import { Platform, PixelRatio, Dimensions, StyleSheet } from 'react-native';

export const useFontSizeStyle = () => {
  const rV = useDynamicResponsiveValue();
  const desktop = 'lg';
  const tablet = 'md';
  const mobile = 'xs';
  const objFontSize = {
    h1: rV({ [desktop]: 88, [tablet]: 42, [mobile]: 36 }),
    h2: rV({ [desktop]: 56, [tablet]: 38, [mobile]: 30 }),
    h3: rV({ [desktop]: 42, [tablet]: 32, [mobile]: 28 }),
  };
  return {
    h1: { fontSize: objFontSize.h1 },
    h2: { fontSize: objFontSize.h2 },
    h3: { fontSize: objFontSize.h3 },
  }
};

const {
  width: SCREEN_WIDTH,
  height: SCREEN_HEIGHT,
} = Dimensions.get('window');
// based on iphone 8's scale
const scale = SCREEN_WIDTH / 375;

function normalize(size) {
  const newSize = size * scale;
  if (DEVICE_INFO.IS_WEB) return size;
  return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 3;
}

export const setupStyle = ({ defaultFont, bold, semiBold, light, medium, extraBold }) => {
  initQuickStyle.setFontStyle({
    fontFamily: defaultFont,
    color: COLOR.FONT,
  });
  
  
  initQuickStyle.setStyleHooks({
    // @ts-ignore
    fontSize: (value) => {
      return normalize(value)
    },
  });
  initQuickStyle.setAdditionStyles({
    bold: {
      fontFamily: bold,
    },
    semiBold: {
      fontFamily: semiBold,
    },
    extraBold: {
      fontFamily: extraBold,
    },
    light: {
      fontFamily: light,
    },
    medium: {
      fontFamily: medium,
    },
    center: {
      textAlign: 'center',
    },
    shadow: {
      shadowColor: 'rgba(0,0,0,0.1)',
      shadowOffset: {
        width: 1,
        height: 1
      },
      shadowOpacity: 1,
      shadowRadius: 8,
      elevation: 2,
    },
    h4: {
      fontSize: 25,
      fontFamily: medium,
    },
    h5: {
      fontSize: 20,
      fontFamily: semiBold,
    },
    h6: {
      fontSize: 18,
      fontFamily: medium,
    },
    subtitle1: {
      fontSize: 16,
      fontFamily: semiBold,
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: 16.94,
      fontWeight: "600",
    },
    body1: {
      fontSize: 16,
      lineHeight: 24,
    },
    body2: {
      fontSize: 14,
      lineHeight: 16,
      fontWeight: "400",
    },
    caption: {
      fontSize: 12,
      lineHeight: 14.52,
    },
    overline: {
      fontSize: 12,
    },
    btnTextLarge: {
      fontSize: 18,
      lineHeight: 21.78,
      fontWeight: "600",
    },
    btnTextMedium: {
      fontSize: 16,
      lineHeight: 19.36,
      fontWeight: "600",
    },
    btnTextSmall: {
      fontSize: 14,
      lineHeight: 16.94,
    },
    line: {
      borderTopColor: '#E4E4E4',
      borderTopWidth: 1,
      height: 0,
    }
  });

  initQuickStyle.setMainColor(COLOR.MAIN);
}

export const STYLES = StyleSheet.create({
  all100p: {
    width: '100%',
    height: '100%',
  },
});

export const fSize = (rV, designedValue) => rV({ xs: designedValue * 0.7, md: designedValue * 0.8, lg: designedValue * 0.85, xl: designedValue * 0.9 , xxl: designedValue })
