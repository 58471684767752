import React, { useState, useEffect, useCallback, memo } from 'react';
import { COLOR, ERROR, SCREEN_NAME, STYLES } from 'const';
import { ICON } from 'assets';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { Avatar, CollapsableText, RatioCol, YoutubeEmbed, Button, Popup, UseMemo, Tooltip, PostTimestamp } from '../../elements';
import { usePostCURDDropdown, usePreDataOrFetchApi } from '../../hooks';
import { useNavFunc, A } from 'navigation';
import { IBook, IGroupPost } from 'type';
import Store from 'store';
import { TimeHelper, VarHelper } from 'helpers';
import { FontAwesome5, AntDesign } from '@expo/vector-icons';
import LikeComment from '../LikeComments/LikeComments';
import UserName from '../UserName';

interface IGroupPostFeedProps {
  data: IGroupPost,
  [anyProp: string]: any,
}

const GroupPostFeed = (props: IGroupPostFeedProps) => {
  const { data } = props;
  const propStyle = usePropsStyle(props);
  const { goToUserProfile, navigation, route, goWithPreData } = useNavFunc();
  const { user } = Store.User.state;
  const [{ }, gApi] = Store.Group.createStore();
  const [didRemove, setDidRemove] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    if (data.isPinned) setIsPinned(data.isPinned);
  }, [data]);

  const [book] = usePreDataOrFetchApi<IBook>(data?.tagBooks?.length > 0 ? data?.tagBooks[0].id : '', 'book');

  // @ts-ignore
  const { name } = route.params;
  const onPressEdit = async () => {
    goWithPreData(SCREEN_NAME.GroupDetailEditPost, { id: data?.groupId, name: name || '_', tab: 'feed', postid: data?.id, postmode: 'chinh-sua' }, data);
  };
  const onPressView = async () => {
    goWithPreData(SCREEN_NAME.GroupDetailEditPost, { id: data?.groupId, name: name || '_', tab: 'feed', postid: data?.id, postmode: 'chi-tiet' }, data);
  };

  const onPressRemove = useCallback(() => {
    const doRemove = async () => {
      const [res, err] = await gApi.updatePost(data.groupId, {
        id: data.id,
        visibility: 'trash',
      } as any);
      if (err) return Popup.showError(ERROR.parseError(err));
      if (res.error) return Popup.showError(ERROR.parseError(res.error));
      if (!!res && !!res.success) {
        setDidRemove(true);
      }
    };

    Popup.show('Bạn có chắc chắn muốn xóa?', [
      { text: 'Không', solid: true, outline: false, onPress: () => { } },
      { text: 'Xóa', solid: false, outline: true, onPress: () => doRemove() },
    ]);

  }, [data]);

  const pinPost = async () => {
    const [res, err] = await gApi.updatePost(data.groupId, {
      id: data.id,
      isPinned: true,
    } as any);
    if (!!res && !!res.success) {
      setIsPinned(true);
    }
  };

  const unpinPost = async () => {
    const [res, err] = await gApi.updatePost(data.groupId, {
      id: data.id,
      isPinned: false,
    } as any);
    if (!!res && !!res.success) {
      setIsPinned(false);
    }
  };

  const { showDropdown } = usePostCURDDropdown({
    navigation,
    hasEditPermission: user.id === data?.createdById || user.type === 'ADMIN',
    objectId: data.id,
    objectType: 'group-post',
    onPressEdit,
    onPressRemove,
    additionOptions: user.type === 'ADMIN' ? [
      {
        icon: <ICON.Pin fill={COLOR.MAIN} />,
        text: isPinned ? 'Bỏ ghim' : 'Ghim bài',
        onPress: isPinned ? unpinPost : pinPost,
      }
    ] : [],
  });

  const onPressGoToDetail = () => {
    if (!book) return;
    navigation.navigate(SCREEN_NAME.BookDetail, { id: book.id, name: VarHelper.toSlug(book.name) })
  };

  const goToBookDetailProps = {
    route: SCREEN_NAME.BookDetail,
    params: { id: book?.id, name: VarHelper.toSlug(book?.name) },
    preData: book,
  }

  const renderBook = () => {
    return (
      <Col marginBottom24 bgWhite borderRadius10>
        <Row height192 stretch padding24 marginHorizontal24 borderRadius4 borderWidth1 borderColor={'#ECF0EF'}>
          <A {...goToBookDetailProps}>
            <RatioCol ratio={100 / 160} width={100}>
              <Img source={{ uri: book?.images && book?.images?.length > 0 ? book?.images[0] : '' }} width100p height100p />
            </RatioCol>
          </A>
          <Col flex1 paddingLeft16>
            <A {...goToBookDetailProps}>
              <Col>
                <Text numberOfLines={2} subtitle1 marginBottom8>{book?.name}</Text>
                <Text numberOfLines={1} body2 marginBottom16>{book?.authors?.length > 0 ? book.authors[0].name : ''}</Text>
                <Text numberOfLines={2} body2 marginBottom16>{book?.content}</Text>
              </Col>
            </A>
          </Col>
        </Row>
      </Col>
    );
  }

  const shouldShowLikeCommentActivity = data?.lastComment?.createdAt;

  return (
    <UseMemo deps={[data, book, isPinned, didRemove, shouldShowLikeCommentActivity]}>
      {
        didRemove ? null : (
          <Col bgWhite borderRadius10 borderWidth1 borderColor={COLOR.BORDER} style={propStyle}>
            <Col padding24>
              {Boolean(shouldShowLikeCommentActivity) && (
                <Row alignItems="flex-start" marginBottom16>
                  <Col flex1>
                    <Text><UserName  {...data?.lastComment.createdBy} /> đã bình luận vào bài đăng</Text>
                    <Col marginTop4 onHoverStyle={{ opacity: 1 }} useNestedHover onPress={onPressView}>
                      <Text onHoverStyle={{ textDecorationLine: 'underline' }} color={COLOR.GRAY_500}>{TimeHelper.fromNow(data?.lastComment?.createdAt)}</Text>
                    </Col>
                  </Col>
                  <Col width24 height24 middle onPress={showDropdown}>
                    <ICON.ThreeDots />
                  </Col>
                </Row>
              )}
              <Row>
                <Row>
                  <Avatar name={data.createdBy?.name} uri={data?.createdBy?.avatar} size={40} onPress={() => goToUserProfile(data?.createdById, data?.createdBy?.name)} />
                  <Col marginLeft12>
                    <UserName  {...data.createdBy} /> 
                    {Boolean(!shouldShowLikeCommentActivity) && (
                      <PostTimestamp onPress={onPressView} createdAt={data.createdAt} updatedAt={data.updatedAt} />
                    )}
                  </Col>
                </Row>
                <Col flex1 />
                <Row>
                  {Boolean(isPinned) && (
                    <Tooltip title="Bài viết đã được ghim bởi admin">
                      <Col padding12>
                        <ICON.Pin fill={COLOR.GRAY_500} />
                      </Col>
                    </Tooltip>
                  )}
                  {Boolean(!shouldShowLikeCommentActivity) && (
                    <Col padding12 onPress={showDropdown}>
                      <ICON.ThreeDots />
                    </Col>
                  )}
                </Row>
              </Row>

              <Col marginTop16>
                <CollapsableText expandMaxHeight={150}>
                  <Text>{data?.content}</Text>
                </CollapsableText>
              </Col>
            </Col>
            {Boolean(data?.images?.length > 0) ? (
              <RatioCol ratio={1200 / 512} width='100%'>
                <Img source={{ uri: data?.images[0] || '' }} style={STYLES.all100p} />
              </RatioCol>
            ) : Boolean(data?.videos?.length > 0) ? (
              <YoutubeEmbed ratio={16 / 9} width='100%' id={data?.videos[0]} />
            ) : null}
            {Boolean(data?.tagBooks && data?.tagBooks?.length > 0) && renderBook()}
            <LikeComment entityData={data} postType='group-post' />
          </Col>
        )
      }
    </UseMemo>
  );
};

export default memo(GroupPostFeed);