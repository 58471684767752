import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M10.5.813A9.686 9.686 0 00.812 10.5a9.686 9.686 0 009.688 9.688 9.686 9.686 0 009.688-9.688A9.686 9.686 0 0010.5.812zm5.625 10.78a.475.475 0 01-.469.47h-3.594v3.593a.475.475 0 01-.468.469H9.406a.451.451 0 01-.469-.469v-3.594H5.345a.451.451 0 01-.469-.468V9.406c0-.234.195-.469.469-.469h3.593V5.345c0-.235.196-.469.47-.469h2.187c.234 0 .469.234.469.469v3.593h3.593c.235 0 .469.235.469.47v2.187z"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
