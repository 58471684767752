import React, { useState } from 'react';
import { Row, Col, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ERROR, useFontSizeStyle } from 'const';
import { useNavigation, useRoute } from '@react-navigation/native';
import { SCREEN_NAME, DEVICE_INFO, COLOR } from 'const';
import { ICON } from 'assets';
import { Button, Checkbox, Input } from 'components';
import { StyleSheet } from 'react-native';
import { Reset } from 'navigation';
import Store from 'store';

const UseResetPasswordCode = () => {
  const rV = useDynamicResponsiveValue();
  const navigation = useNavigation();
  const route = useRoute();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [actionSuccess, setActionSuccess] = useState(false);
  const [{ user, token }, userApi] = Store.User.createUserStore();
  const { email, code } : any = route.params || {};

  const reset = (screenName) => {
    navigation.reset({ index: 0, routes: [{ name: screenName }] })
  };

  const resetPassword = async () => {
    if (!password || !passwordConfirm) return setLoginErrorMessage(ERROR.co_fill_all_form);
    if (password !== passwordConfirm) return setLoginErrorMessage(ERROR.auth_password_not_match);
    setLoading(true);
    const [res, err] = await userApi.useResetPasswordCode({ email, newPassword: password, code });
    setLoading(false);
    if (err) return setLoginErrorMessage(ERROR.parseError(err));
    if (res.error) return setLoginErrorMessage(ERROR.parseError(res.error));
    setActionSuccess(true);
  }
  if (!email || !code || email === "_" || code === "_") {
    return (
      <Col middle flex1 bgWhite>
        <Text>Địa chỉ không hợp lệ, vui lòng thử lại..</Text>
        {/* <Reset to={SCREEN_NAME.Home} timeout={500} /> */}
      </Col>
    );
  }

  if (!!token) {
    return (
      <Col middle flex1 bgWhite>
        <Text>Bạn đã đăng nhập..</Text>
        <Reset to={SCREEN_NAME.Home} timeout={500} />
      </Col>
    );
  }

  return (
    <Col middle flex1 bgWhite>
      <Col width={rV({ xs: 320, lg: 364 })}>
        <ICON.ReadismWithTextLogo fill={COLOR.MAIN} style={{ alignSelf: 'center', width: '80%' }} />
        <Text marginTop40 h4>Đặt lại mật khẩu</Text>
        {Boolean(actionSuccess) ? (
          <>
            <Text marginTop16 body1>Đặt lại mật khẩu thành công, vui lòng đăng nhập bằng mật khẩu mới.</Text>
          </>
        ) : (
          <>
            <Col marginTop24>
              <Input.Password
                label="Mật khẩu mới"
                value={password}
                onChange={setPassword}
                placeholder=""
                error={loginErrorMessage}
              />
              <Input.Password
                label="Nhập lại mật khẩu mới"
                value={passwordConfirm}
                onChange={setPasswordConfirm}
                placeholder=""
              />
            </Col>
            <Button marginTop32 solid large text="Đặt lại mật khẩu" onPress={resetPassword} loading={loading} />
          </>
        )}
      </Col>
      <Col style={styles.cornerCloseBtn} onPress={() => reset(SCREEN_NAME.Home)}>
        <ICON.Close />
      </Col>
    </Col>
  );
};

const styles = StyleSheet.create({
  cornerCloseBtn: {
    position: 'absolute',
    top: DEVICE_INFO.IS_IOS ? 48 : 12,
    right: 12,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default UseResetPasswordCode;