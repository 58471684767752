import React, { memo, useEffect, useMemo } from 'react';
import { Col, Text, Img, useDynamicResponsiveValue, Scroll, Row } from 'react-quick-style-components';
import { COLOR, STYLES, useFontSizeStyle } from 'const';
import { useNavigation } from '@react-navigation/native';
import { SCREEN_NAME } from 'const';
import { Header, Button, Slider, MainContainer, FooterInfo, GenresShape } from 'components';
import { useWindowDimensions } from 'react-native';
import { ICON, IMAGE } from 'assets';
import { useNavFunc } from 'navigation';
import Store from 'store';
import { useGoogleFacebookAuth } from './auth/useGoogleFacebookAuth';
import HomeLoggedIn from './Home.LoggedIn';
import { useEvent } from 'js-events-listener/react';

const FEATURED_ICONS_HEIGHT = 295;

const Home = () => {
  const { navigate } = useNavFunc();
  const [{ token }] = Store.User.createUserStore();
  const [{ listSettings }, sApi] = Store.Setting.createStore();
  
  // const { token } = Store.User.state;
  const { height, width } = useWindowDimensions();
  const { h3 } = useFontSizeStyle();
  const rV = useDynamicResponsiveValue();

  useEffect(() => {
    if (listSettings.length === 0) sApi.getList();
  }, []);

  return useMemo(() => {
    const sliderHomes = (() => {
      const find = listSettings.find(val => val.name === 'SLIDER_HOME');
      if (!!find && find.data.length > 0) return find.data;
      return [
        {
          bg: IMAGE['SlideBg1'],
          useLocal: 'SlideBg1',
          title: 'MẠNG XÃ HỘI',
          text: 'dành cho những người yêu đọc sách',
          link: undefined,
          showOnlyBg: false,
        },
        {
          bg: IMAGE['SlideBg2'],
          useLocal: 'SlideBg2',
          title: 'MẠNG XÃ HỘI',
          text: 'dành cho những người viết và sáng tác',
          link: undefined,
          showOnlyBg: false,
        },
        {
          bg: IMAGE['SlideBg3'],
          useLocal: 'SlideBg3',
          title: 'MẠNG XÃ HỘI',
          text: 'dành cho những người đọc và trao đổi về sách',
          link: undefined,
          showOnlyBg: false,
        },
        {
          bg: IMAGE['SlideBg4'],
          useLocal: 'SlideBg4',
          title: 'MẠNG XÃ HỘI',
          text: 'dành cho những người yêu đọc sách',
          link: undefined,
          showOnlyBg: false,
        },
      ]
    })();

    const bannerEvent = listSettings.find(val => val.name === 'BANNER_EVENT_HOME');

    if (!!token) return <HomeLoggedIn bannerEvent={bannerEvent} />;

    const renderSlide = ({ bg, useLocal, text, title, link, showOnlyBg }, i) => {
      const shouldShow2Buttons = !link;
      return (
        <Col key={'slide-'+i} height={height - FEATURED_ICONS_HEIGHT - 60} backgroundColor="rgba(0,0,0,0.5)">
          <Col absoluteFill onPress={showOnlyBg && link ? () => { window.location = link; } : undefined}>
            <Img source={useLocal ? IMAGE[useLocal] : bg} style={STYLES.all100p} resizeMode='cover' />
          </Col>
          {showOnlyBg ? null : (
            <Col absoluteFill middle>
              <MainContainer>
                {/* <ICON.ReadismWithTextLogo width={213} /> */}
                <Text extraBold colorMain style={h3} marginTop25>{title}</Text>
                <Text medium colorWhite style={h3}>{text}</Text>
                {
                  shouldShow2Buttons ? (
                    <Row marginTop32 width={rV({ md: '50%', xs: '100%' })}>
                      <Col
                        onPress={() => {
                          window.location.pathname = '/sach';
                        }}
                        borderRadius6 height42 flex1 middle backgroundColor={COLOR.WARNING_500} marginRight8
                      >
                        <Text btnTextMedium>Đánh giá ngay</Text>
                      </Col>
                      <Col
                        onPress={() => {
                          window.location.pathname = '/viet';
                        }}
                        borderRadius6 height42 flex1 middle backgroundColor={COLOR.WARNING_500}
                      >
                        <Text btnTextMedium>Sáng tác ngay</Text>
                      </Col>
                    </Row>
                  ) : (
                    <Col
                      width228 height52 borderRadius6 marginTop32
                      middle backgroundColor={COLOR.WARNING_500}
                      onPress={!!link ? () => { window.location = link; } : () => navigate(SCREEN_NAME.Login)}
                    >
                      <Text btnTextMedium>THAM GIA NGAY</Text>
                    </Col>
                  )
                }
              </MainContainer>
            </Col>
          )}
        </Col>
      );
    };

    const renderIcon = ({ Icon, onPress, text }) => {
      // console.log('size', rV({ xs: 40, md: 80 }));
      const size = width < 768 ? 50 : 80;
      const fontSize = width < 768 ? { caption: true } : { subtitle1: true };
      return (
        <Col
          onPress={onPress}
          flex1 middle onHoverStyle={{ opacity: 1 }} useNestedHover padding12>
          <Icon width={size} height={size} />
          <Text
            marginTop16 onHoverStyle={{ color: COLOR.MAIN }}
            center {...fontSize}
          >{text}</Text>
        </Col>
      );
    }

    return (
      <Col flex1 backgroundColor="#F3F6F5">
        <Header />
        <Col flex1>
          <Scroll>
            <Col height={height - 60}>
              <Col flex1>
                <Slider numberOfSlide={sliderHomes.length}>
                  {sliderHomes.map(renderSlide)}
                </Slider>
              </Col>
              {/* <ICON.ReadismWithTextLogo width={350} /> */}
              <Col middle height={FEATURED_ICONS_HEIGHT} backgroundColor={COLOR.PRI_100}>
                {/* <ICON.HomeFeatures width={rV({ xs: '90%', lg: '70%', xl: '50%' })} /> */}
                <Row width={rV({ xs: '90%', lg: '70%', xl: '50%' })} alignItems='flex-start'>
                  {renderIcon({
                    Icon: ICON.HomeBooks,
                    text: 'Đọc và đánh giá sách',
                    onPress: () => navigate(SCREEN_NAME.BookHomepage),
                  })}
                  {renderIcon({
                    Icon: ICON.HomeMes,
                    text: 'Hội nhóm thảo luận về sách',
                    onPress: () => navigate(SCREEN_NAME.Group),
                  })}
                  {renderIcon({
                    Icon: ICON.HomeQuote,
                    text: 'Trích dẫn nổi tiếng',
                    onPress: () => navigate(SCREEN_NAME.Quote),
                  })}
                  {renderIcon({
                    Icon: ICON.HomeWriting,
                    text: 'Viết truyện',
                    onPress: () => navigate(SCREEN_NAME.Writing),
                  })}
                </Row>
              </Col>
            </Col>
            <Col padding={rV({ xs: 12, lg: 56 })} middle>
              {/* <ICON.HomeGenres
                width={rV({ xs: '90%', lg: '50%', xl: '70%' })}
              /> */}
              <GenresShape />
            </Col>
            <FooterInfo />
          </Scroll>
        </Col>
      </Col>
    );
  }, [token, listSettings, height, width]);
};

export default Home;