import React from 'react';
import { Col, Img, Row, Text, usePropsStyle } from 'react-quick-style-components';
import { RatioCol, showModal } from 'components/elements';
import { usePreDataOrFetchApi } from 'components/hooks';
import { IActivity, IBook, IReview } from 'type';
import { Image } from 'react-native';
import { useNavFunc } from 'navigation';
import ReviewItem from '../items/ReviewItem';
import LikeComments from '../LikeComments/LikeComments';
import AddToBookshelfModal from '../AddToBookshelfModal';
import { SCREEN_NAME, STYLES } from 'const';
import { VarHelper } from 'helpers';

interface IFeedItemProps {
  data: IActivity,
  [anyProp: string]: any,
}

const ReviewFeed = (props : IFeedItemProps) => {
  const { navigate } = useNavFunc();
  const propStyle = usePropsStyle(props);
  const reviewData : IReview = props.data?.data;
  const [bookData] = usePreDataOrFetchApi<IBook>(reviewData?.bookId, 'book');

  const bookImage = bookData?.images.length > 0 ? bookData.images[0] : '';

  const openBookShelfModal = () => {
    showModal({
      component: (
        <AddToBookshelfModal bookId={bookData.id} bookName={bookData.name} bookImage={bookImage} />
      )
    })
  }
  
  const renderBookDetail = () => {
    
    return (
      <Row height192 stretch padding14 marginHorizontal24 borderRadius4 borderWidth1 borderColor={'#ECF0EF'}>
        <RatioCol ratio={100/160} width={100} onPress={() => navigate(SCREEN_NAME.BookDetail, { id: bookData.id, name: VarHelper.toSlug(bookData.name) })}>
          <Img source={{ uri: bookImage }} width100p height100p />
        </RatioCol>
        <Col flex1 paddingLeft16>
          <Col onPress={() => navigate(SCREEN_NAME.BookDetail, { id: bookData.id, name: VarHelper.toSlug(bookData.name) })}>
            <Text numberOfLines={2} subtitle1 marginBottom8>{bookData?.name}</Text>
            <Text numberOfLines={1} body2 marginBottom16>{bookData?.authors.length > 0 ? bookData.authors[0].name : ''}</Text>

            <Text numberOfLines={2} body2 marginBottom16>{bookData?.content}</Text>
          </Col>
          
          <Col bgMain borderRadius8 width141 height33 middle onPress={openBookShelfModal}>
            <Text colorWhite bold>Thêm vào tủ sách</Text>
          </Col>
        </Col>
      </Row>
    );
  };

  return (
    <ReviewItem data={props.data.data} withoutCollapse={false} style={propStyle} showLikeCommentActivity>
      {renderBookDetail()}
      <LikeComments entityData={props.data.data} postType='review' />
    </ReviewItem>
  );
};

export default ReviewFeed;
