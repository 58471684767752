import React from 'react';
import { IActivity } from 'type';

import ReviewFeed from './ReviewFeed';
import BookFeed from './BookFeed';
import QuoteFeed from './QuoteFeed';
import WritingFeed from './WritingFeed';
import GroupPostFeed from './GroupPostFeed';
import MangaFeed from './MangaFeed';

interface IFeedItemProps {
  data: IActivity,
  [anyProp: string]: any,
}

const FeedItem = (props : IFeedItemProps) => {
  const dataWithUpdatedCreatedBy = !props.data.createdBy ? props : {
    ...props,
    data: {
      ...props.data,
      data: {
        ...props.data.data,
        createdBy: props.data.createdBy,
        createdAt: props.data.createdAt,
        updatedAt: props.data.updatedAt,
      }
    }
  }

  if (props.data?.object?.type === 'review') return <ReviewFeed {...dataWithUpdatedCreatedBy} />;
  if (props.data?.object?.type === 'book') return <BookFeed {...dataWithUpdatedCreatedBy} />;
  if (props.data?.object?.type === 'quote') return <QuoteFeed {...dataWithUpdatedCreatedBy} />;
  if (props.data?.object?.type === 'group-post') return <GroupPostFeed data={dataWithUpdatedCreatedBy.data.data} />;
  if (props.data?.object?.type === 'writing') return <WritingFeed {...dataWithUpdatedCreatedBy} />;
  // if (props.data?.object?.type === 'manga') return <MangaFeed {...dataWithUpdatedCreatedBy} />;

  return null;
};

export default FeedItem;
