import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={14}
      height={16}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M1.344 1.813h3.515l.293-.528c.118-.234.381-.41.616-.41h3.369c.234 0 .527.176.615.41l.264.528h3.515c.264 0 .469.234.469.468v.938a.463.463 0 01-.469.469H1.344a.482.482 0 01-.469-.47v-.937c0-.234.234-.469.469-.469zm11.103 12.744c-.029.732-.674 1.318-1.406 1.318H3.863c-.732 0-1.377-.586-1.406-1.318l-.644-9.932h11.25l-.616 9.932z"
        fill={props.fill || "#949494"}
      />
    </Svg>
  )
}

export default SvgComponent
