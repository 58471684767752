import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M38.553 5.217l-2.895-2.895C34.744 1.408 33.45.875 32.23.875c-1.218 0-2.513.533-3.427 1.447L23.09 8.035l-2.59 2.59L1.914 29.211 1 37.895c-.152 1.066.686 1.98 1.752 1.98h.228l8.684-.914L30.25 20.375l2.59-2.59 5.713-5.713a4.827 4.827 0 000-6.855zM9.988 35.457l-5.103.533.533-5.103 17.596-17.672 2.361-2.361 4.646 4.646-2.36 2.361L9.987 35.457zM35.963 9.482L32.61 12.91l-4.646-4.646 3.428-3.352a1.3 1.3 0 01.837-.38 1.3 1.3 0 01.838.38l2.895 2.895a1.196 1.196 0 010 1.675z"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
