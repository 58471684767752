import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { CMSLayout, Button, Input, Uploader, Select, Checkbox, CMSUploaderUI, FilePicker, showModal } from 'components';
import { Linking, StyleSheet, useWindowDimensions, ActivityIndicator } from 'react-native';
import { useNavFunc, Reset } from 'navigation';
import { COLOR, DEVICE_INFO, ERROR, SCREEN_NAME, BOOK_VISIBILITY } from 'const';
import { ICON } from 'assets';
import Store from 'store';
import * as Animatable from 'react-native-animatable';
import { IBook } from 'type';
import { VarHelper } from 'helpers';
import { Entypo, AntDesign } from '@expo/vector-icons';
import { saveAs } from 'file-saver';

import SearchForm from './CMS.BulkCreateBook.SeachForm';

const emptyBook = {
  name: '',
  content: '',
  images: [],
  genres: [],
  authors: [],
  publisher: '',
  buyLinks: [],
  visibility: 'public',
};

interface IBookCreate extends IBook {
  imageRef?: { getUploadedUrl: Function, setUri: Function },
}

let XLSX;

const CMSBulkCreateBook = () => {
  const { goBackOrTo, reset } = useNavFunc();
  const [btnText, setBtnText] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDownloadExcel, setLoadingDownloadExcel] = useState(false);
  const [loadingUploadExcel, setLoadingUploadExcel] = useState(false);
  const [loadingGenres, setLoadingGenres] = useState(false);
  const [shouldExportWhenSubmit, setShouldExportWhenSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [genres, setGenres] = useState([]);
  const [showSearchForm, setShowSearchForm] = useState(true);

  const rV = useDynamicResponsiveValue();
  const [{ user }, uApi] = Store.User.createUserStore();
  const [{ }, bookApi] = Store.Book.createBookStore();
  const [{ listGenres }, gApi] = Store.Genres.createStore();
  const [rows, setRows] = useState<Array<IBookCreate>>([]);

  const createObj = useRef<Array<IBookCreate>>([]);

  const uploaderRef = useRef({});
  const filePickerRef = useRef<{ openDialogAndWaitForBinary: Function, getBinary: Function, openDialog: Function }>(null);

  useEffect(() => {
    if (!XLSX) {
      import('xlsx').then(xlsx => {
        XLSX = xlsx;
      });
    }
  }, []);

  useEffect(() => {
    gApi.getList('BOOK');
  }, []);

  useEffect(() => {
    createObj.current = rows;
    // sync images
    createObj.current.forEach(val => {
      if (val.images.length === 0) return;
      if (uploaderRef.current[val.id] && uploaderRef.current[val.id].setUri) {
        uploaderRef.current[val.id].setUri(val.images[0]);
      }
    });
  }, [rows]);

  const { width } = useWindowDimensions();

  const updateUploaderRef = (ref, id) => {
    uploaderRef.current[id] = ref;
  }

  const uploadImages = async () => {
    await Promise.all(createObj.current.map(async (val, index) => {
      if (val.images.length > 0) return;
      try {
        console.log(!!uploaderRef.current[val.id] && !!uploaderRef.current[val.id].getUploadedUrl)
        if (!!uploaderRef.current[val.id] && !!uploaderRef.current[val.id].getUploadedUrl) {
          const [imageUrl, err] = await uploaderRef.current[val.id].getUploadedUrl();
          if (imageUrl) {
            createObj.current[index].images = [imageUrl];
          }
        }
      } catch(e) {}
    }))
  };

  const createAll = async () => {
    if (createObj.current.length === 0) return alert(ERROR.co_fill_all_form);
    setLoading(true);
    await uploadImages();
    const [res, err] = await bookApi.adminBulkCreate(createObj.current);
    if (err) {
      setLoading(false);
      return alert(ERROR.parseError(err));
    }
    if (res.error) {
      setLoading(false);
      return alert(ERROR.parseError(res.error));
    }
    if (shouldExportWhenSubmit) {
      alert('Tạo sách thành công, dữ liệu sẽ đồng thời được tải xuống dưới dạng excel, vui lòng lưu trữ file như 1 hình thức backup dữ liệu');
      await exportExcel();
    } else {
      alert('Tạo sách thành công');
    }
    setLoading(false);
    setTimeout(() => {
      DEVICE_INFO.IS_WEB && window.location.reload();
    }, 2000);
  };

  const importExcel = async () => {
    setLoadingUploadExcel(true);
    const binary = await filePickerRef.current?.openDialogAndWaitForBinary();
    // console.log('binary', binary);
    if (!binary) {
      return setLoadingUploadExcel(false);
    }
    var workbook = XLSX.read(binary, {
      type: 'binary'
    });
    // @ts-ignore
    var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
    var json_object = JSON.parse(JSON.stringify(XL_row_object)).filter(val => {
      // remove empty row
      if (!val['Tên sách']) return false;
      if (!!val['Tình trạng'] && val['Tình trạng'].toLowerCase() === 'xong') {
        // remove done row
        return false;
      }
      return true;
    });
    setRows([
      ...rows,
      ...json_object.map(val => ({
        id: VarHelper.genId(),
        name: val['Tên sách'],
        content: val['Mô tả'],
        images: !!val['Ảnh bìa'] ? [ val['Ảnh bìa'] ] : [],
        publisher: val['Nhà xuất bản'],
        genres: !val['Thể loại'] ? [] : val['Thể loại'].trim().split(',').map(val => {
          const genreName = val.trim();
          const find = listGenres.find(g => g.name === genreName);
          return { name: genreName, id: !find ? 0 : find.id };
        }),
        visibility: val['Hiển thị'],
        authors: val['Tác giả'] ? [{ id: 0, name: val['Tác giả'], avatar: '' }] : [],
        buyLinks: val['Link mua sách'] ? [ val['Link mua sách'] ] : [],
      }))
    ])
    setLoadingUploadExcel(false);
  }

  const exportExcel = async () => {
    setLoadingDownloadExcel(true);
    await uploadImages();
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(createObj.current.map(val => ({
      'Tên sách': val.name,
      'Ảnh': val.images.length > 0 ? val.images[0] : '',
      'Tác giả': !!val.authors && val.authors.length > 0 ? val.authors[0].name : '',
      'Nhà xuất bản': val.publisher,
      'Mô tả': val.content,
      'Thể loại': val.genres.map(val => val.name).join(', '),
      'Hiển thị': val.visibility,
      'Link mua sách': val.buyLinks.length > 0 ? val.buyLinks[0] : '',
    })));
    XLSX.utils.book_append_sheet(wb, ws, "book");
    var wbout = XLSX.write(wb, {bookType:'xlsx', type:'array'});
    saveAs(new Blob([wbout],{type:"application/octet-stream"}), `download-${new Date().getTime()}.xlsx`);
    setLoadingDownloadExcel(false);
  };

  const addNewRow = () => {
    const clone = rows.slice();
    clone.push({
      id: VarHelper.genId(),
      ...emptyBook as IBook,
      createdBy: {
        id: user.id,
        name: user.displayName,
        avatar: user.photoUrl,
        type: user.type,
      },
      createdById: user.id,
    });
    setRows(clone);
  };

  const removeRow = useCallback((index) => {
    const newRows = [
      ...rows.slice(0, index),
      ...rows.slice(index+1, rows.length),
    ];
    setRows(newRows);
  }, [rows]);

  const updateObj = (id, index, labelOrFunc) => (newValue) => {
    const newRows = rows.slice();
    const label = typeof labelOrFunc === 'string' ? labelOrFunc : undefined;
    if (!newRows[index]) {
      newRows[index] = {
        ...emptyBook as IBook,
        id,
        createdBy: {
          id: user.id,
          name: user.displayName,
          avatar: user.photoUrl,
          type: user.type,
        },
        createdById: user.id,
        ...(typeof labelOrFunc === 'string' ? { [label]: newValue } : labelOrFunc(newValue))
      }
    } else {
      newRows[index] = {
        ...newRows[index],
        ...(typeof labelOrFunc === 'string' ? { [label]: newValue } : labelOrFunc(newValue)),
      };
    }
    setRows(newRows);
  };

  const genresOptions = listGenres.map(item => ({ label: item.name, value: item.id }));

  if (!user.type || user.type !== 'ADMIN') {
    return (
      <Col middle flex1 bgWhite>
        <Text>Bạn không đủ quyền hạn để truy cập trang này..</Text>
        <Reset to={SCREEN_NAME.Home} timeout={1500} />
      </Col>
    );
  }

  const renderContent = () => {
    if (width < 1280) {
      return (
        <Col flex1 middle>
          <Text>Vui lòng sử dụng màn hình kích thước 1280px hoặc lớn hơn</Text>
        </Col>
      )
    }
    return (
      <Col flex1 backgroundColor="#F3F6F5">
        <Scroll style={{ padding: 20 }}>
          {rows.map((val, index) => {
            return (
              <Row key={'row-'+val.id} bgWhite marginBottom24 alignItems="flex-start" padding8 borderRadius8>
                <Col width90>
                  <CMSUploaderUI aspectRatio={160/256} subText="320x512" ref={ref => updateUploaderRef(ref, val.id)} />
                </Col>
                <Col flex1 bgWhite marginLeft8>
                  <Input placeholder="Tên sách" value={val.name} onChange={updateObj(val.id, index, 'name', )} />

                  <Select
                    marginTop20
                    label=""
                    value={val.genres.map(item => ({ label: item.name, value: item.id }))}
                    options={genresOptions}
                    onChange={updateObj(val.id, index, (newValues) => ({
                      genres: newValues.map(foo => ({ id: foo.value, name: foo.label }))
                    }) )}
                    isMulti
                    placeholder="Thể loại"
                  />
                </Col>
                <Col flex1 bgWhite marginLeft8>
                  <Input placeholder="Tên tác giả" value={val.authors.length === 0 ? '' : val.authors[0].name} onChange={updateObj(val.id, index, (newValue) => ({ authors: [{ name: newValue, id: 0, avatar: '' }] }) )} />
                  <Input
                    marginTop20
                    placeholder="Nhà xuất bản"
                    onChange={updateObj(val.id, index, 'publisher')}
                    value={val.publisher}
                  />
                </Col>
                <Col flex1 bgWhite marginLeft8>
                  <Input
                    placeholder="Mô tả"
                    multiline
                    onChange={updateObj(val.id, index, 'content')}
                    inputStyle={{ padding: 12 }}
                    value={val.content}
                  />
                </Col>
                <Col flex1 bgWhite marginLeft8>
                  <Input
                    placeholder="Link mua sách"
                    onChange={updateObj(val.id, index, (newValue) => ({ buyLinks: [newValue] }) )}
                    value={val.buyLinks.length > 0 ? val.buyLinks[0] : ''}
                  />
                  <Select
                    marginTop20
                    label=""
                    placeholder="Hiển thị"
                    options={Object.keys(BOOK_VISIBILITY).map(val => ({ value: val, label: BOOK_VISIBILITY[val] }))}
                    value={{ label: BOOK_VISIBILITY[val.visibility], value: val.visibility }}
                    onChange={updateObj(val.id, index, (newValue) => ({ visibility: newValue.value }) )}
                  />
                </Col>
                <Col padding10 onPress={() => removeRow(index)}>
                  <ICON.Trash fill={COLOR.GRAY_500} />
                </Col>
              </Row>
            );
          })}
          <Row marginTop24>
            <Col borderRadius8 bgMain middle width100 paddingVertical5 onPress={addNewRow}>
              <Text colorWhite>Thêm dòng</Text>
            </Col>
            <Row onPress={loadingDownloadExcel ? undefined : exportExcel} marginLeft8 borderWidth1 borderColor={COLOR.MAIN} borderRadius8 bgWhite middle width140 paddingVertical5>
              {Boolean(loadingDownloadExcel) ? (
                <ActivityIndicator size="small" color={COLOR.MAIN} />
              ) : (
                <>
                  <Entypo name="download" size={12} color={COLOR.MAIN} />
                  <Text colorMain marginLeft8>Xuất excel</Text>
                </>
              )}
            </Row>
            <Row onPress={loadingUploadExcel ? undefined : importExcel} marginLeft8 borderWidth1 borderColor={COLOR.MAIN} borderRadius8 bgWhite middle width140 paddingVertical5>
              {Boolean(loadingUploadExcel) ? (
                <ActivityIndicator size="small" color={COLOR.MAIN} />
              ) : (
                <>
                  <Entypo name="upload" size={12} color={COLOR.MAIN} />
                  <Text colorMain marginLeft8>Nhập từ excel</Text>
                </>
              )}
            </Row>
            <FilePicker ref={filePickerRef} />
            <Col flex1 />
            <Row>
              <Row marginRight8 onPress={() => setShouldExportWhenSubmit(v => !v)} bgWhite borderRadius8 paddingHorizontal16 paddingVertical5 borderWidth1 borderColor={COLOR.MAIN}>
                <Checkbox value={shouldExportWhenSubmit} onChange={setShouldExportWhenSubmit} />
                <Text marginLeft8 body1>Tự động export ra excel</Text>
              </Row>
              <Row onPress={loading ? undefined : createAll} marginLeft8 borderRadius8 bgMain middle width140 paddingVertical5>
                {Boolean(loading) ? (
                  <ActivityIndicator size="small" color={"white"} />
                ) : (
                  <>
                    <Entypo name="save" size={12} color={"white"} />
                    <Text colorWhite marginLeft8>Tạo hàng loạt</Text>
                  </>
                )}
              </Row>
            </Row>
            
          </Row>
          
        </Scroll>
      </Col>
    )
  }

  return (
    <Animatable.View
      animation="fadeIn"
      duration={300}
      style={styles.container}
    >
      <Row bgMain padding12 middle>
        <Row
          onPress={() => {
            const shouldClose = confirm('Bạn có chắc muốn đóng trang lại? Lưu ý kiểm tra lại dữ liệu chưa lưu!\nBấm OK để đóng, Cancel để quay lại');
            if (shouldClose) reset(SCREEN_NAME.CMSHome);
          }}
        >
          <ICON.Close fill={COLOR.WHITE_100} />
          <Text colorWhite marginLeft8>Đóng trang</Text>
        </Row>
        <Row
          marginLeft24
          onPress={loadingGenres ? undefined : async () => {
            setLoadingGenres(true);
            await gApi.getList();
            setLoadingGenres(false);
          }}
        >
          {Boolean(loadingGenres) ? (
            <ActivityIndicator size="small" color={"white"} />
          ) : (
            <AntDesign name="reload1" size={24} color="white" />
          )}
          <Text colorWhite marginLeft8>Làm mới danh sách thể loại</Text>
        </Row>
        <Row
          marginLeft24
          onPress={async () => {
            showModal({
              component: <SearchForm />
            })
          }}
        >
          <ICON.Search />
          <Text colorWhite marginLeft8>Tìm kiếm sách</Text>
        </Row>
      </Row>
      {renderContent()}
      
    </Animatable.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cornerCloseBtn: {
    position: 'absolute',
    top: DEVICE_INFO.IS_IOS ? 48 : 12,
    left: 12,
    height: 24,
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CMSBulkCreateBook;