import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M28.031 12.938H17.063V1.967c0-.609-.61-1.218-1.22-1.218h-2.437c-.685 0-1.219.61-1.219 1.219v10.969H1.22c-.686 0-1.219.609-1.219 1.218v2.438c0 .685.533 1.218 1.219 1.218h10.969v10.97c0 .685.533 1.218 1.218 1.218h2.438c.61 0 1.219-.533 1.219-1.219V17.813H28.03c.61 0 1.219-.534 1.219-1.22v-2.437c0-.61-.61-1.219-1.219-1.219z"
        fill={props.fill || "#AFAFAF"}
      />
    </Svg>
  )
}

export default SvgComponent
