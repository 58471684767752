import React, { useEffect, useState } from 'react';
import { Col, Text, Row, Scroll } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME } from 'const';
import { Header, Button, LeftSidebarOneThird, Avatar, Placeholder, ReviewItemPublic, FeedItem, usePreDataOrFetchApi, LoadMoreDetect } from 'components';
import { ICON } from 'assets';
import Store from 'store';
import { IActivity, IUser } from 'type';
import UserProfile from './UserProfile';

const UserProfileAllReview = () => {
  const { route, goBackOrTo } = useNavFunc();
  // @ts-ignore
  const { id, name } = route.params;
  const [{}, uApi] = Store.User.createUserStore();
  const [{}, rApi] = Store.Reviews.createStore();
  const [userData] = usePreDataOrFetchApi<IUser>(id, 'user');
  const [listReview, setListReview] = useState([]);
  const [errorMes, setErrorMes] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const getData = async (page = 1) => {
    const [res, err] = await rApi.adminGetList({
      visibility: 'public',
      page: 1,
      pageSize: 20,
      options: {
        filter: [
          { label: 'createdById', value: id },
        ]
      },
      includeMyReviews: false,
    });
    if (err) return setErrorMes(ERROR.parseError(err));
    if (res.error) return setErrorMes(ERROR.parseError(res.error));
    console.log('res.data', res.data);
    setListReview(page === 1 ? res.data.rows : [...listReview, ...res.data.rows]);
    setHasMore(res.data.hasNext);
    setPage(page);
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <UserProfile noForce noFetchOnFocus>
      {!userData ? <Col /> :
        <Scroll>
          <Col>
            <Row justifyContent="space-between">
              <Col>
                <Text btnTextLarge>ĐÁNH GIÁ</Text>
                <Text marginTop4 body2 color={COLOR.GRAY_500}>{userData.profileData?.numberOfReviews || 0} đánh giá</Text>
              </Col>
              <Col padding4 onPress={() => goBackOrTo(SCREEN_NAME.UserProfile, { id, name })}>
                <Text colorMain subtitle1>Quay lại</Text>
              </Col>
            </Row>
          </Col>

          {!!errorMes && <Text color={COLOR.DANGER_500}>{errorMes}</Text>}

          {listReview.map(val => (
            <ReviewItemPublic
              key={val.id}
              data={val}
              marginTop16
            />
          ))}
          <LoadMoreDetect
            page={page}
            hasMore={hasMore}
            fetcher={(newPage) => getData(newPage)}
          />
        </Scroll>
      }
    </UserProfile>
  );
};

export default UserProfileAllReview;