import React, { Component, useState, useRef } from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import AsyncSelect, { NoOptionsMessage, LoadingMessage } from '../elements/AsyncSelect';
import Store from 'store';
import { SCREEN_NAME, COLOR } from 'const';
import { useNavFunc } from 'navigation';
import { VarHelper } from 'helpers';
import { hideModal, Tooltip } from 'components/elements';
import { FontAwesome, Ionicons, Entypo } from '@expo/vector-icons';
import { components } from 'react-select';
interface ISearchBookSuggestionProps {
  navigation?: any,
  isInModal?: boolean,
}

const DropdownIndicator = () => null;
// const NoOptionsMessage = () => null;

const Option = (props) => {
  const { image, subLabel } = props.data;
  return (
    <components.Option {...props}>
      <Row stretch>
        <Col width50 height50>
          <img src={image} style={{ height: 50, width: 50, objectFit: 'contain' }} />
        </Col>
        <Col flex1 marginLeft8>
          <Text bold>{props.label}</Text>
          <Text marginTop8>bởi {subLabel}</Text>
        </Col>
      </Row>
    </components.Option>
  );
}

const SearchGeneralSuggestion = ({ navigation, isInModal }: ISearchBookSuggestionProps) => {
  const [compKey, setComKey] = useState('key-' + Math.random());
  const [searchTypes, setSearchTypes] = useState<Array<'book' | 'manga' | 'writing'>>(['book', 'writing', 'manga']);
  const [value, onChange] = useState();
  const [{ }, mApi] = Store.Misc.createMiscStore();
  const [name, setName] = useState('');

  const toggleType = (type: 'book' | 'manga' | 'writing') => {
    if (searchTypes.includes(type)) setSearchTypes(searchTypes.filter(val => val !== type))
    else {
      const newSearchTypes = searchTypes.slice();
      newSearchTypes.push(type);
      setSearchTypes(newSearchTypes);
      setComKey('key-' + Math.random());
    }
  }

  return (
    <Col>
      <AsyncSelect
        key={compKey}
        label=""
        placeholder="Nhập tìm Sách và Sáng tác"
        value={value}
        onChange={(data) => {
          onChange(data);
          if (!!data?.value && !!data?.type) {
            switch (data.type) {
              case 'book':
                navigation.navigate(SCREEN_NAME.BookDetail, { id: data.value, name: VarHelper.toSlug(data.label) });
                break;
              case 'writing':
                navigation.navigate(SCREEN_NAME.WritingDetail, { id: data.value, name: VarHelper.toSlug(data.label) });
                break;
              case 'manga':
                navigation.navigate(SCREEN_NAME.MangaDetail, { id: data.value, name: VarHelper.toSlug(data.label) });
                break;
              default:
            }

            isInModal && hideModal();
          }
        }}
        noOptionsMessage={!!name ? undefined : () => null}
        components={{ NoOptionsMessage, DropdownIndicator, LoadingMessage, Option }}
        defaultOptions={[]}
        onInputChange={(nameInput) => { setName(nameInput); return nameInput; }}
        loadOptions={async (value, callback) => {
          const [res, err] = await mApi.generalSearch(value);
          if (!res || !res.success) return;
          const results = [
            {
              label: 'Sách', value: 'books', options: [],
            },
            {
              label: 'Truyện chữ', value: 'writings', options: [],
            },
            {
              label: 'Truyện tranh', value: 'mangas', options: [],
            },
          ];
          if (res.data.books?.rows?.length > 0 && searchTypes.includes('book')) {
            results[0].options = res.data.books.rows.slice(0,3).map(val => ({ value: val.id, label: val.name, type: 'book', image: !!val.images ? val.images[0] : '', subLabel: !!val.authors ? val.authors[0].name : '' }));
          }
          if (res.data.writings?.rows?.length > 0 && searchTypes.includes('writing')) {
            results[1].options = res.data.writings.rows.slice(0,3).map(val => ({ value: val.id, label: val.name, type: 'writing', image: val.image, subLabel: val.createdBy?.name }));
          }
          if (res.data.mangas?.rows?.length > 0 && searchTypes.includes('manga')) {
            results[2].options = res.data.mangas.rows.slice(0,3).map(val => ({ value: val.id, label: val.name, type: 'manga', image: val.image, subLabel: val.createdBy?.name }));
          }
          callback(results.filter(val => val.options.length > 0));
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            navigation.navigate(SCREEN_NAME.BookHomepage, { search: name });
            isInModal && hideModal();
          }
        }}
        additionStyles={{
          placeholder: (style) => ({
            ...style,
            fontSize: 14,
            color: COLOR.GRAY_500,
            cursor: 'text',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            cursor: 'text',
          }),
        }}
      />
      <Row absolute top13 right5 middle>
        <Col padding2 paddingHorizontal={4} onPress={() => toggleType('book')}>
          <Tooltip title='Tìm sách'>
            <Entypo name="book" size={16} color={searchTypes.includes('book') ? COLOR.MAIN : COLOR.GRAY_200} />
          </Tooltip>
        </Col>
        <Col padding2 paddingHorizontal={4} onPress={() => toggleType('writing')}>
          <Tooltip title='Tìm truyện chữ'>
            <FontAwesome name="edit" size={16} color={searchTypes.includes('writing') ? COLOR.MAIN : COLOR.GRAY_200} />
          </Tooltip>
        </Col>
        <Col padding2 paddingHorizontal={4} onPress={() => toggleType('manga')}>
          <Tooltip title='Tìm truyện tranh'>
            <Ionicons name="images" size={16} color={searchTypes.includes('manga') ? COLOR.MAIN : COLOR.GRAY_200} />
          </Tooltip>
        </Col>
      </Row>
    </Col>
  )
};

export default SearchGeneralSuggestion;