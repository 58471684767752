import React from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { TimeHelper, VarHelper } from 'helpers';
import { ICON } from 'assets';
import { COLOR } from 'const';
import Tooltip from './Tooltip';

const PostTimestamp = ({ updatedAt, createdAt, onPress }) => {
  return (
    <Row marginTop4 onHoverStyle={{ opacity: 1 }} useNestedHover onPress={onPress}>
      <Text caption onHoverStyle={{ textDecorationLine: 'underline' }} color={COLOR.GRAY_500}>Cập nhật {TimeHelper.fromNow(updatedAt)}</Text>
      <Tooltip title={`Đăng lúc ${TimeHelper.fromNow(createdAt)}`}>
        <ICON.InfoCircle width={12} height={12} color={COLOR.MAIN} style={{ marginLeft: 4, marginTop: 2 }} />
      </Tooltip>
    </Row>
  )
};

export default PostTimestamp;
