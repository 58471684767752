import { ICON } from 'assets';
import { COLOR } from 'const';
import React, { useState } from 'react';
import { TextInputProps, StyleSheet, TextInput } from 'react-native';
import { Input as InputDefault, Row, Col, Text, usePropsStyle } from 'react-quick-style-components';

interface IInputProps {
  label?: string,
  value?: any,
  onChange(newValue: any): any,
  placeholder: string,
  style?: any,
  inputStyle?: any,
  containerStyle?: any,
  error?: string,
  onEnter?: any,
  leftIcon?: {
    comp: any,
    onPress?: any
  }
  rightIcon?: {
    comp: any,
    onPress?: any
  }
  inputProps?: TextInputProps,
  renderRightLabel?: Function,
  required?: boolean,
  multiline?: boolean,
  useRefState?: boolean,
  [additionStyleProps: string]: any,
}

interface IMinimalInputProps {
  label?: string,
  value: any,
  initialValue?: any,
  onChange(newValue: any): any,
  placeholder: string,
  inputProps?: TextInputProps,
  [additionStyleProps: string]: any,
}

const Input = (props : IInputProps) => {
  const propsStyle = usePropsStyle(props);
  const inputProps : TextInputProps = props.inputProps || {};
  inputProps.secureTextEntry = props.password;
  if (props.multiline) inputProps.multiline = true;
  return (
    <Col style={[propsStyle, props.style]}>
      {Boolean(props.label) && (
        <Row marginBottom8>
          <Col flex1>
            <Text subtitle1>{props.label} {Boolean(props.required) && <Text color={COLOR.DANGER_500}> *</Text>}</Text>
          </Col>
          {Boolean(props.renderRightLabel) && props.renderRightLabel()}
        </Row>
      )}
      <Row height={props.multiline ? 115 : 46} borderRadius8 borderColor={COLOR.BORDER} borderWidth1 style={props.containerStyle}>
        {Boolean(props.leftIcon) && (
          <Col height46 width46 middle onPress={props.leftIcon.onPress}>
            {props.leftIcon.comp}
          </Col>
        )}
        <TextInput
          ref={props.onRef}
          value={props.useRefState ? undefined : props.value}
          defaultValue={props.initialValue}
          onChangeText={props.onChange}
          placeholder={props.placeholder}
          underlineColorAndroid="transparent"
          placeholderTextColor={Text.defaultFont.color}
          style={[styles.input, props.inputStyle]}
          onSubmitEditing={props.onEnter}
          textAlignVertical="top"
          multiline={props.multiline}
          {...inputProps}
        />
        {Boolean(props.rightIcon) && (
          <Col height46 width46 middle onPress={props.rightIcon.onPress}>
            {props.rightIcon.comp}
          </Col>
        )}
      </Row>
      {Boolean(props.error) && (
        <Row marginTop8>
          <ICON.ErrorInput />
          <Col flex1 marginLeft5>
            <Text numberOfLines1 caption color={COLOR.DANGER_500}>{props.error}</Text>
          </Col>
        </Row>
      )}
    </Col>
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    height: '100%',
    paddingHorizontal: 12,
    width: '100%',
  },
});


interface IInputPassword extends IMinimalInputProps {

}

Input.Password = (props : IInputPassword) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Input
      {...props}
      password={!showPassword}
      rightIcon={{
        comp: showPassword ? <ICON.EyeClose /> : <ICON.EyeOpen />,
        onPress: () => setShowPassword(!showPassword),
      }}
    />
  )
};

export default Input;