import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row, Text, Scroll, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { Header, MainContainer, LeftSidebarOneThird, Input, DirectMessageLayout } from 'components';
import { COLOR } from 'const';
import { ICON } from 'assets';

import NewMessage from './DirectMessage.NewMessage';
import DetailConversation from './DirectMessage.DetailConversation';

const DirectMessage = () => {
  const [{ user }, uApi] = Store.User.createUserStore();
  const { route, navigate } = useNavFunc();

  const { mode, id, conversationId } = route.params || {};

  let ChildrenComponent = (p) => null;
  if (mode === 'danh-sach') ChildrenComponent = (p) => null;
  else if (mode === 'gui-tin-nhan') ChildrenComponent = (p) => <NewMessage {...p} />;
  else if (!!conversationId) ChildrenComponent = (p) => <DetailConversation {...p} />;

  return (
    <DirectMessageLayout>
      {ChildrenComponent}
    </DirectMessageLayout>
  );
};

export default DirectMessage;
