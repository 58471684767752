import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={13}
      height={4}
      viewBox="0 0 13 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M7.906 2.375c0-.762-.644-1.406-1.406-1.406-.791 0-1.406.644-1.406 1.406 0 .791.615 1.406 1.406 1.406.762 0 1.406-.615 1.406-1.406zM11.422.969c-.791 0-1.406.644-1.406 1.406 0 .791.615 1.406 1.406 1.406.762 0 1.406-.615 1.406-1.406 0-.762-.644-1.406-1.406-1.406zm-9.844 0c-.79 0-1.406.644-1.406 1.406 0 .791.615 1.406 1.406 1.406.762 0 1.406-.615 1.406-1.406 0-.762-.644-1.406-1.406-1.406z"
        fill="#949494"
      />
    </Svg>
  )
}

export default SvgComponent