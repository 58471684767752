import { useState } from 'react';

interface ISubmitUI {
  errorMes?: string,
  loading?: boolean,
  btnText?: string,
}

export const useSubmitUIState = (initialState = undefined) : [ISubmitUI, (newValue: ISubmitUI) => void] => {

  const [UIState, setUIState] = useState<ISubmitUI>(initialState || {
    errorMes: '',
    loading: false,
    btnText: '',
  });

  const setValue = (obj) => setUIState(v => ({ ...v, ...obj }));

  return [
    UIState,
    setValue,
  ];
}