import React from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue, usePropsStyle } from 'react-quick-style-components';
import { IReview } from 'type';
import { useNavFunc } from 'navigation';
import ReviewItem from './ReviewItem';
import { BOOK_VISIBILITY, SCREEN_NAME } from 'const';

interface IReviewItemCMSProps {
  data: IReview,
  [anyProps: string]: any,
}

const ReviewItemCMS = (props : IReviewItemCMSProps) => {
  const propStyle = usePropsStyle(props);
  const { navigate} = useNavFunc();
  return (
    <ReviewItem data={props.data} noSpoilerHidden style={propStyle}>
      <Row justifyContent="space-between" padding24 paddingTop0>
        <Col paddingVertical4 onPress={() => navigate(SCREEN_NAME.CMSEditReview, { id: props.data?.id })}>
          <Text bold colorMain>Sửa đánh giá</Text>
        </Col>
        <Col>
          <Text>{BOOK_VISIBILITY[props.data?.visibility]}</Text>
        </Col>
      </Row>
    </ReviewItem>
  );
};

export default ReviewItemCMS;