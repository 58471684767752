import { HTMLView } from 'components';
import { VarHelper } from 'helpers';
import React, { useState, useEffect } from 'react';
import { Col, usePropsStyle } from 'react-quick-style-components';

import WritingImageItemWithComment from './WritingImageItemWithComment'

interface IMangaReaderProps {
  html: string,
  writingId: string,
  writingChapterId: string,
  extraData: Array<{
    id: string,
    numberOfComments: number,
    writingChapterId: string,
    writingId: string,
  }>,
  [propStyle : string]: any,
}

const WritingReader = (props : IMangaReaderProps) => {
  const style = usePropsStyle(props);
  const [parts, setParts] = useState([]);

  useEffect(() => {
    const deviceIntoParts = () => {
      if (!props.html) return [];
      let results = [];
      const reg = /<p(?:(?!<p).)*?\/p>/g;
      let htmlClone = props.html;
      // console.log('html', props.html);
      const match = htmlClone.match(reg);
      // console.log('match', match);
      // console.log('props.html', props.html);
      if (!match) return [];
      let  lastIndex = 0;
      match.forEach((p, pI) => {
         const index = htmlClone.indexOf(p);
         if (index > 0) {
           const one = htmlClone.slice(lastIndex, index);
           results.push(one);
           results.push(p);
         } else {
           results.push(p);
         }
         htmlClone = htmlClone.slice(index + p.length, htmlClone.length);
        if (pI === match.length - 1) {
          if (!!htmlClone) results.push(htmlClone);
        }
      });
      return results.filter(val => !!val.trim());
    };

    setParts(deviceIntoParts());

  }, [props.html]);

  console.log(parts);

  return (
    <Col style={style}>
      {!!parts && parts.length > 0 && parts.map((val, i) => {
        if (!val.includes('</p>')) return (
          <HTMLView content={val} key={'html-'+i} />
        );
        return (
          <WritingImageItemWithComment
            key={'img-'+i}
            html={val}
            writingChapterId={props.writingChapterId}
            writingId={props.writingId}
            extraData={props.extraData?.find(item => item.id === VarHelper.genWritingParagraphId(val))}
          />
        )
      })}
    </Col>
  );
}

export default WritingReader;