import React, { useEffect, useState } from 'react';
import { Col, Text, Row, Scroll } from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME } from 'const';
import { Header, Button, LeftSidebarOneThird, Avatar, Placeholder, ReviewItemPublic, FeedItem, usePreDataOrFetchApi, LoadMoreDetect, QuoteNoCreatorItem, WritingInProfileItem } from 'components';
import { ICON } from 'assets';
import Store from 'store';
import { IActivity, IUser } from 'type';
import { VarHelper } from 'helpers';
import UserProfile from './UserProfile';

const UserProfileAllManga = () => {
  const { route, goBackOrTo, navigation } = useNavFunc();
  // @ts-ignore
  const { id, name } = route.params;
  const [{}, mApi] = Store.Manga.createStore();
  const [userData] = usePreDataOrFetchApi<IUser>(id, 'user');
  const [list, setlist] = useState([]);
  const [errorMes, setErrorMes] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  const getData = async (page = 1) => {
    const [res, err] = await mApi.getList({
      visibility: 'public',
      page: 1,
      pageSize: 20,
      options: {
        filter: [
          { label: 'createdById', value: id },
        ]
      },
    });
    if (err) return setErrorMes(ERROR.parseError(err));
    if (res.error) return setErrorMes(ERROR.parseError(res.error));
    setlist(page === 1 ? res.data.rows : [...list, ...res.data.rows]);
    setHasMore(res.data.hasNext);
    setPage(page);
  };

  useEffect(() => {
    getData();
  }, [id]);

  console.log(list);

  return (
    <UserProfile noForce noFetchOnFocus>
      {!userData ? <Col /> :
        <Scroll>
          <Col>
            <Row justifyContent="space-between">
              <Col>
                <Text btnTextLarge>TRUYỆN TRANH</Text>
                <Text marginTop4 body2 color={COLOR.GRAY_500}>{userData.profileData?.numberOfManga || 0} truyện</Text>
              </Col>
              <Col padding4 onPress={() => goBackOrTo(SCREEN_NAME.UserProfile, { id, name })}>
                <Text colorMain subtitle1>Quay lại</Text>
              </Col>
            </Row>
          </Col>

          {!!errorMes && <Text color={COLOR.DANGER_500}>{errorMes}</Text>}
          <Row xs='50%' marginLeft={-12} marginRight={-12} padding6>
            {list.map(val => (
              <WritingInProfileItem
                data={val} margin6
                key={val.id}
                onPress={() => navigation.navigate(SCREEN_NAME.MangaDetail, { id: val.id, name: VarHelper.toSlug(val.name) })}
              />
            ))}
          </Row>
          <LoadMoreDetect
            page={page}
            hasMore={hasMore}
            fetcher={(newPage) => getData(newPage)}
          />
        </Scroll>
      }
    </UserProfile>
  );
};

export default UserProfileAllManga;