import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={22}
      height={25}
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M19.24 9.69l-.705-.743c-.185-.185-.482-.185-.63 0L12.151 14.7V2.008a.479.479 0 00-.445-.446h-1.039a.451.451 0 00-.445.446v12.691l-5.79-5.752c-.148-.185-.445-.185-.63 0l-.705.742c-.186.149-.186.446 0 .631l7.756 7.756a.427.427 0 00.63 0l7.756-7.756c.186-.185.186-.482 0-.63z"
        fill={props.fill || "#fff"}
      />
      <Path
        d="M1 17v7h20v-7"
        fill="transparent"
        stroke={props.fill || "#fff"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default SvgComponent
