import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Col, Text, Scroll, Img, Row, useDynamicResponsiveValue} from 'react-quick-style-components';
import { COLOR, ERROR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import { Header, Button, MainContainer, RatioCol, Uploader, ControlledUploaderUI , Input, useSubmitUIState, usePreDataOrFetchApi} from 'components';
import { ICON, IMAGE } from 'assets';
import { LinearGradient } from 'expo-linear-gradient';
import { StyleSheet } from 'react-native';
import Store from 'store';
import { IGroupFeed, ILocalFileUpload } from 'type';

const UpsertGroup = () => {
  const rV = useDynamicResponsiveValue()
  const { navigate, goBackOrTo, route } = useNavFunc();
  const { id, name: slug } = route?.params || {};
  console.log('id', id, 'slug', slug);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState<ILocalFileUpload>();
  const [cover, setCover] = useState({
    name: '',
    path: '',
    mime: '',
    raw: undefined,
  });
  const [{ loading, btnText, errorMes }, setSubmitUI] = useSubmitUIState();
  const [{}, gApi] = Store.Group.createStore();

  const isEditMode = !!id && !!slug;
  const [groupData] = usePreDataOrFetchApi<IGroupFeed>(id, 'group-feed-detail');


  const uploaderRef = useRef<{ openFileDialog: Function, uploadLocal: Function }>();
  const avatarRef = useRef<{ getUploadedUrl: Function }>();

  useEffect(() => {
    if (!groupData) return;
    setName(groupData.detail?.name);
    setDescription(groupData?.detail?.description);
    setCover({
      path: groupData?.detail?.cover,
      name: '', mime: '', raw: undefined,
    })
    setImage({
      path: groupData?.detail?.image,
      name: '', mime: '', raw: undefined,
    })
  }, [isEditMode, groupData]);

  const submit = useCallback(async () => {
    if (!name) return setSubmitUI({ errorMes: 'Cần điền tên' });
    setSubmitUI({ loading: true });
    const [coverUrl, err] = await uploaderRef.current?.uploadLocal(cover);
    const [avatarUrl, err2] = await avatarRef.current?.getUploadedUrl();

    let res, err3;
    if (isEditMode) {
      [res, err3] = await gApi.update({
        id,
        name, description,
        image: avatarUrl,
        cover: coverUrl,
      });
    } else {
      [res, err3] = await gApi.create({
        name, description,
        image: avatarUrl,
        cover: coverUrl,
      });
    }
    if (err3) return setSubmitUI({ errorMes: ERROR.parseError(err3), loading: false });
    if (res.error) return setSubmitUI({ errorMes: ERROR.parseError(res.error), loading: false });
    setSubmitUI({ errorMes: '', loading: false, btnText: 'Thành công' });
    setTimeout(() => {
      setSubmitUI({ errorMes: '', loading: false, btnText: '' });
      goBackOrTo(SCREEN_NAME.Group);
    }, 500);
  }, [isEditMode, id, name, cover, image, description])

  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1>
        <Scroll flex1>
          <MainContainer paddingBottom32>
            <Text h4 marginTop32>TẠO GROUP</Text>

            <RatioCol ratio={1140/249} width='100%' marginTop32 borderRadius16 overflow="hidden">
              {Boolean(cover.path) && (
                <Col absoluteFill>
                  <Img source={{ uri: cover.path }} style={STYLES.all100p} />
                </Col>
              )}
              <LinearGradient
                style={StyleSheet.absoluteFill}
                colors={['rgba(0,0,0,0.8)', 'rgba(0,0,0,0)']}
                start={{ x:0, y:1 }} end={{ x: 0, y:0 }}
              >
                <Uploader
                  ref={uploaderRef}
                  onChangeLocal={(newLocal) => setCover(newLocal)}
                />
                <Row middle flex1 onPress={() => uploaderRef.current?.openFileDialog()}>
                {Boolean(cover.path) ? (
                  <>
                    <ICON.SolidPen width={32} height={32} fill='white' />
                    <Text colorWhite subtitle1 marginLeft16>Sửa ảnh bìa</Text>
                  </>
                ) : (
                  <>
                    <ICON.Plus width={32} height={32} fill='white' />
                    <Text colorWhite subtitle1 marginLeft16>Thêm ảnh bìa</Text>
                  </>
                )}
                </Row>
              </LinearGradient>
              <Row absolute top0 bottom0 left={rV({ xs: 10, lg: 54 })}>
                <Col width160>
                  <ControlledUploaderUI
                    value={image}
                    onChange={setImage}
                    text='Thêm avatar'
                    aspectRatio={1}
                    ref={avatarRef}
                  />
                </Col>
              </Row>
            </RatioCol>

            <Row xs='100%' md='2:any:1' marginTop32 alignItems="flex-start">
              <Col>
                <Col bgWhite padding={rV({xs: 10, md: 40 })}>
                  <Input
                    label="Tên nhóm" placeholder="Nhập tên nhóm"
                    required
                    value={name} onChange={setName}
                  />
                  <Input
                    marginTop24
                    label="Mô tả nhóm" placeholder="Nhập mô tả"
                    multiline
                    inputStyle={{ padding: 12 }}
                    value={description} onChange={setDescription}
                  />
                </Col>
                <Col alignItems="flex-end" marginTop16>
                  {Boolean(errorMes) && <Text marginBottom4 color={COLOR.DANGER_500}>{errorMes}</Text>}
                  <Button loading={loading} width219 height43 small solid text={btnText || (isEditMode ? "Cập nhật" : "Tạo")} onPress={submit} />
                </Col>
              </Col>
              <Col width24 />
              <Col>
                <Text h6>Hướng dẫn tạo nhóm</Text>
                <Text body1 marginTop16>Hội nhóm là nơi mà chúng ta có thể trực tiếp trao đổi, giao lưu và chia sẻ với những người bạn yêu thích sách. Vì vậy đừng ngại ngần mà hãy tạo ngay cho mình một nhóm riêng trên Readism, để tập hợp những người bạn có cùng sở thích với mình nhé.</Text>
                <Text body1 marginTop16>- Đầu tiên hãy nghĩ một cái tên vừa ngắn gọn, vừa hay để đặt cho tên nhóm của bạn nha.</Text>
                <Text body1 marginTop16>- Kế đó thì mô tả về những định hướng và mong muốn của bạn khi tạo ra nhóm này, để các thành viên tại Readism hiểu rõ hơn và join group nhé.</Text>
                <Text body1 marginTop16>- Đừng quên chăm chút thêm ảnh bìa và avatar xinh xắn cho nhóm của mình nhé.</Text>
              </Col>
            </Row>
          </MainContainer>
        </Scroll>
      </Col>
    </Col>
  );
};

export default UpsertGroup;