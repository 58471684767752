import React from 'react';
import { Col, usePropsStyle } from 'react-quick-style-components';

interface IRatioColProps {
  ratio: number, // width / height
  children: any,
  width: number | string,
  nativeID?: string,
  onPress?: any,
  [anyProp: string]: any,
}

const RatioCol = (props : IRatioColProps) => {
  const propStyle = usePropsStyle(props);
  return (
    <Col style={propStyle} nativeID={props.nativeID} onPress={props.onPress}>
      <Col paddingBottom={100 / props.ratio + '%'}>
        <Col absoluteFill>
          {props.children}
        </Col>
      </Col>
    </Col>
  );
};

export default RatioCol;