import React from 'react';
import { Row, Col, Text } from 'react-quick-style-components';
import { COLOR } from 'const';

const Pagination = ({ changePage, allPossiblePages, page }) => {
  const pageArr = new Array(allPossiblePages).fill(1).map((val, i) => i + 1);
  const displayPages = (() => {
    if (pageArr.length < 7) return pageArr;
    if (page < 4) return [1, 2, 3, 4, undefined, pageArr.length - 1];
    if (page >= pageArr.length - 3) return [1, undefined, pageArr.length - 3, pageArr.length - 2, pageArr.length - 1];
    return [
      1, undefined,
      page - 1, page, page + 1,
      undefined, pageArr.length - 1,
    ]
  })();

  return Boolean(pageArr.length > 1) && (
    <Row flexWrap="wrap" marginTop24>
      <Col
        width40 height40 borderWidth1 borderColor={COLOR.BORDER} middle marginBottom10
        onPress={() => changePage(Math.max(page - 1, 1))} bgWhite
      >
        <Text fontSize16>←</Text>
      </Col>
      {displayPages.map((val, i) => (
        <Col
          key={'page-' + i}
          width40 height40 borderWidth1 borderColor={COLOR.BORDER} middle marginBottom10
          onPress={!val ? undefined : () => changePage(val)} bgWhite
        >
          <Text bold={page === val} fontSize16>{val || '...'}</Text>
        </Col>
      ))}
      <Col
        width40 height40 borderWidth1 borderColor={COLOR.BORDER} middle marginBottom10
        onPress={() => changePage(Math.min(page + 1, pageArr.length))} bgWhite
      >
        <Text fontSize16>→</Text>
      </Col>
    </Row>
  )
};

export default Pagination;