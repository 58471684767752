import React, { useState, useRef, useCallback, useEffect } from 'react';
import { COLOR, ERROR, STYLES } from 'const';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { RateStars, Avatar, HTMLView, RichTextEditor2, Checkbox, Button } from 'components';
import { IReview } from 'type';
import { EventHelper, TimeHelper } from 'helpers';
import { BlurView } from 'expo-blur';
import { StyleSheet } from 'react-native';
import { ICON } from 'assets';
import Store from 'store';
import * as Animatable from 'react-native-animatable';
import { Popup } from 'components/elements';

interface IGiveAReviewProps {
  bookId: string,
  bookName: string,
  bookGenres?: any,
  onSuccess?: any,
  autoReview?: boolean,
  [anyProps: string]: any,
}

let _timeout;
const debounce = (func) => {
  if (_timeout) clearTimeout(_timeout);
  _timeout = setTimeout(() => {
    func();
  }, 500);
}

const GiveAReview = (props: IGiveAReviewProps) => {
  const propStyle = usePropsStyle(props);

  const [rate, setRate] = useState(0);
  const [spoiler, setSpoiler] = useState(false);
  const [showEditor, setShowEditor] = useState(props.autoReview);

  const [showLinkNotice, setShowLinkNotice] = useState(false);
  const [errorMes, setErrorMes] = useState('');
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const updateId = useRef();

  useEffect(() => {
    console.log('render first time', updateId.current);
  }, []);

  const [{ }, rApi] = Store.Reviews.createStore();
  const { user } = Store.User.state;
  const editorRef = useRef<{ getHTML: Function, getJSON: Function, getText: Function }>();

  const onEditorChange = useCallback((delta, oldDelta, source) => {
    if (!delta || !delta.ops) return;
    // console.log('delta', delta);
    if (delta.ops.filter(val => !!val.attributes && ('link' in val.attributes)).length > 0) {
      setShowLinkNotice(true);
    }
  }, []);

  const silentSubmit = async (reviewRate) => {
    if (reviewRate === 0) return;
    const isCreate = !updateId.current;
    console.log('isCreate', isCreate)
    const [res] = isCreate ? await rApi.giveAReview({
      star: reviewRate,
      bookId: props.bookId,
      bookName: props.bookName,
    }) : await rApi.updateReview({
      id: updateId.current,
      star: reviewRate,
    });
    if (!!res && !!res.data && !!res.data.id) {
      updateId.current = res.data.id;
    }
  };

  const submit = async () => {
    const review = editorRef.current?.getText();
    const reviewJSON = editorRef.current?.getJSON();
    const reviewHTML = editorRef.current?.getHTML();
    // if (review.length <= 300) return setErrorMes(ERROR.review_too_short);
    if (rate === 0) {
      return setErrorMes(ERROR.co_fill_all_required_form);
    }
    setLoading(true);
    const [res, err] = !!updateId.current ? await rApi.updateReview({
      id: updateId.current,
      content: review,
      contentJSON: reviewJSON,
      contentHTML: reviewHTML,
      star: rate,
      bookId: props.bookId,
      bookName: props.bookName,
      hasSpoiler: spoiler,
    }) : await rApi.giveAReview({
      content: review,
      contentJSON: reviewJSON,
      contentHTML: reviewHTML,
      star: rate,
      bookId: props.bookId,
      bookName: props.bookName,
      hasSpoiler: spoiler,
    });
    if (err) return setErrorMes(ERROR.parseError(err));
    if (res.error) return setErrorMes(ERROR.parseError(res.error));
    setDone(true);
    EventHelper.logEvent('Review book', { name: props.bookName, id: props.bookId, star: rate, genres: props.bookGenres });
    if (typeof props.onSuccess === 'function') props.onSuccess();
  };

  if (done) {
    return (
      <Animatable.View animation="tada" useNativeDriver duration={500} style={[styles.reviewContainer, styles.done, propStyle]}>
        <ICON.Check fill={COLOR.MAIN} />
        <Text center colorMain marginLeft16>Bạn đã gửi đánh giá thành công.</Text>
      </Animatable.View>
    )
  }

  return (
    <Animatable.View animation="fadeIn" useNativeDriver duration={500} style={[styles.reviewContainer, propStyle]}>
      <Row justifyContent="space-between">
        <Row alignItems="flex-start">
          <Avatar size={48} uri={user.photoUrl} name={user.displayName} />
          <Col marginLeft13>
            <Text fontSize14>Đánh giá của bạn về cuốn sách</Text>
            <RateStars
              marginTop8
              width151
              value={rate}
              onChange={newRate => {
                setRate(newRate);
                debounce(() => {
                  silentSubmit(newRate);
                });
              }}
            />
          </Col>
        </Row>
      </Row>

      {Boolean(showEditor) ? (
        <>
          <RichTextEditor2
            marginTop24
            ref={editorRef}
            onChange={onEditorChange}
          />

          {Boolean(showLinkNotice) && <Text marginTop8 textAlign="right">Lưu ý: Toàn bộ đường dẫn ra bên ngoài hệ thống sẽ bị gỡ bỏ</Text>}
          {Boolean(errorMes) && <Text color={COLOR.DANGER_500} marginTop8 textAlign="right">{errorMes}</Text>}
          <Row justifyContent="flex-end" marginTop24 xs='100%' md='any:any'>
            <Row onPress={() => setSpoiler(!spoiler)} marginRight16 marginBottom12>
              <Checkbox value={spoiler} onChange={setSpoiler} />
              <Text marginLeft8 body1>Spoiler alert</Text>
            </Row>
            <Button marginBottom12 width120 small solid text={"Gửi đánh giá"} loading={loading} width219 onPress={submit} />
          </Row>
        </>
      ) : (
        <Col marginTop24 onPress={() => setShowEditor(true)}>
          <Text fontStyle='italic' caption>Hãy viết đánh giá tại đây</Text>
        </Col>
      )}

    </Animatable.View>
  );
};

const styles = StyleSheet.create({
  reviewContainer: {
    padding: 24,
    borderWidth: 1,
    borderColor: COLOR.BORDER,
    borderRadius: 4,
    backgroundColor: 'white',
  },
  done: {
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

export default GiveAReview;
