import React, { useState, useEffect, useCallback } from 'react';
import { Col, Text, Scroll, Img, Row } from 'react-quick-style-components';
import { COLOR, useFontSizeStyle } from 'const';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME, STYLES } from 'const';
import { Header, Button, MainContainer, RatioCol, GroupItem } from 'components';
import { ICON, IMAGE } from 'assets';
import Store from 'store';
import { VarHelper } from 'helpers';

const Group = () => {
  const { navigate, navigation } = useNavFunc();
  const { h2 } = useFontSizeStyle();
  const [tab, setTab] = useState<'all' | 'trending'>('all');
  const [{ }, gApi] = Store.Group.createStore();

  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);

  const TABS = [
    // { label: 'Phổ biến', key: 'trending' },
    { label: 'Mới nhất', key: 'all' },
  ];

  const getData = useCallback(async (page = 1) => {
    const [res, err] = await gApi.list({ page, pageSize: 20 });
    if (!!res && res.data && res.data.rows) {
      setList(page === 1 ? res.data.rows : [...list, ...res.data.rows]);
      setPage(page);
    }
  }, [tab, list]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getData();
    });
    return unsubscribe;
  }, [tab, getData]);

  console.log('list', list);

  return (
    <Col flex1 backgroundColor="#F3F6F5">
      <Header />
      <Col flex1>
        <Scroll flex1>
          <MainContainer>
            <RatioCol ratio={1140 / 249} width="100%" marginTop16 bgMain borderRadius16>
              <Img source={IMAGE.GroupBanner} style={STYLES.all100p} />
              <Row absoluteFill paddingHorizontal='5%'>
                <Col alignItems="flex-start">
                  <ICON.ReadismWithTextLogo white height={27} />
                  <Text style={h2} colorWhite>Hội nhóm</Text>
                </Col>
              </Row>
            </RatioCol>

            <Row height68 marginTop12 stretch paddingHorizontal12 borderRadius4 borderWidth1 borderColor={COLOR.GRAY_500}>
              {TABS.map((tabItem, i) => {
                const isActive = tabItem.key === tab;
                const borderStyle = isActive ? { borderBottomWidth: 5, borderBottomColor: COLOR.MAIN } : {};
                return (
                  <Col
                    key={tabItem.key} {...borderStyle} paddingHorizontal16 middle
                    onHoverStyle={{ opacity: 1 }} useNestedHover
                    onPress={() => {
                      if (!isActive) setTab(tabItem.key as any);
                    }}
                  >
                    <Text btnTextLarge onHoverStyle={{ color: COLOR.MAIN }}>{tabItem.label}</Text>
                  </Col>
                );
              })}

              <Col flex1 />

              <Col middle>
                <Row height44 bgMain borderRadius6 paddingHorizontal16 middle onPress={() => navigate(SCREEN_NAME.CreateGroup)}>
                  <ICON.PlusCircle fill='white' width={20} height={20} />
                  <Text btnTextMedium colorWhite marginLeft8>Tạo mới</Text>
                </Row>
              </Col>
            </Row>

            <Row xs='100%' md='50%' lg={100/3 + '%'} marginLeft={-24} marginRight={-24} padding12>
              {list.map((val, i) => (
                <GroupItem
                  data={val}
                  key={val.id}
                  margin12
                  onPress={() => navigate(SCREEN_NAME.GroupDetail, { id: val.id, name: VarHelper.toSlug(val.name) })}
                />
              ))}
            </Row>
          </MainContainer>
        </Scroll>
      </Col>
    </Col>
  );
};

export default Group;