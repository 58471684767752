import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Text, Scroll, Img } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { Header, MainContainer, usePreDataOrFetchApi, HTMLView, LikeComments, Input, Popup, WritingReader, UseScrollDirection, Button } from 'components';
import { IWriting, IWritingChapter } from 'type';
import { COLOR, DEVICE_INFO, SCREEN_NAME, STYLES } from 'const';
import { Animated, Easing } from 'react-native';

import Store from 'store';

import { Feather, Entypo } from '@expo/vector-icons';
import { VarHelper } from 'helpers';

const savedPasswords: any = {};

const WritingDetailChapter = () => {
  const { route, goBackOrTo, navigate } = useNavFunc();
  // @ts-ignore
  const { id, chapterNumber } = route.params;
  const { user } = Store.User.state;
  const [{ }, wApi] = Store.Writing.createStore();
  const [writingData] = usePreDataOrFetchApi<IWriting>(id, 'writing');
  const [extraData, setExtraData] = useState([]);
  const listChapters = !writingData ? [] : writingData.listChapters;
  const [tempPass, setTempPass] = useState('');
  const [realChapData, setRealChapData] = useState<IWritingChapter>();
  const { prevChap, chapData, nextChap } = (() => {
    if (!writingData) return {
      chapData: {} as IWritingChapter,
      nextChap: {} as IWritingChapter,
      prevChap: {} as IWritingChapter,
    };
    const findChapIndex = listChapters.findIndex(val => val.id === chapterNumber);
    return {
      prevChap: findChapIndex === -1 || findChapIndex === 0 ? {} as IWritingChapter : listChapters[findChapIndex - 1],
      nextChap: findChapIndex === -1 || findChapIndex === listChapters.length - 1 ? {} as IWritingChapter : listChapters[findChapIndex + 1],
      chapData: findChapIndex === -1 ? {} as IWritingChapter : listChapters[findChapIndex],
    }
  })();

  const scrollRef: any = useRef();

  useEffect(() => {
    // console.log('chapterNumber', chapterNumber);
    DEVICE_INFO.IS_WEB && window.scrollTo({ left: 0, top: 0 });
  }, [chapterNumber]);

  useEffect(() => {
    const getExtraData = async () => {
      if (!chapterNumber || !writingData || !writingData.id) return;
      const [res, err] = await wApi.listExtraInfo(writingData.id, chapterNumber);
      if (!!err) return;
      console.log('res', res);
      if (res.data) setExtraData(res.data);
    };
    const saveProgress = async () => {
      if (!chapterNumber || !writingData || !writingData.id) return;
      await wApi.saveReadingProgress({
        writingId: writingData.id,
        chapterId: chapterNumber,
      });
    };
    getExtraData();
    saveProgress();
  }, [writingData, chapterNumber]);

  useEffect(() => {
    if (!chapData.id) return;
    if (chapData.content !== 'Bạn cần mật khẩu để truy cập') return;
    if (!!realChapData && chapData.id === realChapData.id) return;
    if (!savedPasswords[chapData.id] && !savedPasswords[prevChap.id]) return;
    const saved = !!savedPasswords[chapData.id] ? savedPasswords[chapData.id] : savedPasswords[prevChap.id];
    checkPassword(savedPasswords[chapData.id], true);
  }, [chapData, prevChap]);
  const shouldShowDraft = (user.id === writingData?.id || user.type === 'ADMIN') && chapData?.visibility !== 'public';

  const checkPassword = async (inputPassword = tempPass, isSilent = false) => {
    if (!inputPassword) return Popup.showError('Vui lòng điền mật khẩu');
    const [res, err] = await wApi.checkPasswordChapter({
      id: chapterNumber,
      password: inputPassword,
    });
    if (isSilent) {
      if (!res) return;
    } else {
      if (Popup.checkAndShowError(res, err)) return;
    }
    setRealChapData(res.data);
    savedPasswords[chapterNumber] = inputPassword;
    setTempPass('');
  };

  const displayChapData = !!realChapData && realChapData.id === chapData.id ? realChapData : chapData;

  const stickyBottom = useRef(new Animated.Value(0)).current;

  if (!writingData) {
    return (
      <Col flex1 bgWhite>
        <Header />
      </Col>
    )
  }

  const renderInputPassword = () => {
    return !!user.id ? (
      <>
        <HTMLView content={displayChapData.contentHTML} />
        <Input
          marginTop16
          width="100%"
          value={tempPass}
          onChange={setTempPass}
          placeholder="Điền mật khẩu"
          label=""
          leftIcon={{
            comp: <Entypo name="lock" size={24} color={COLOR.DANGER_500} />
          }}
          rightIcon={{
            comp: <Entypo name="login" size={24} color={COLOR.DANGER_500} />,
            onPress: () => checkPassword(tempPass, false),
          }}
          onEnter={() => checkPassword(tempPass, false)}
        />
      </>
    ) : (
      <Col height150 middle>
        <Text>Bạn cần đăng nhập để xem các chương có mật khẩu</Text>

        <Button
          solid text="Đăng nhập" paddingHorizontal16 marginTop16
          onPress={() => navigate(SCREEN_NAME.Login, { redirect: window.location.href })}
        />
      </Col>
    );
  }

  return (
    <Col flex1 bgWhite>
      <Header />
      <Col flex1>
        <Scroll onRef={ref => scrollRef.current = ref}>
          <MainContainer marginTop24>
          <Col maxWidth={700} width="80%" alignSelf="center" middle backgroundColor={COLOR.PRI_100} padding16>
                <Row onPress={() => goBackOrTo(SCREEN_NAME.WritingDetail, { id, name: VarHelper.toSlug(writingData?.name) })}>
                  <Col width119 height133>
                    <Img source={{ uri: writingData.image }} style={STYLES.all100p} />
                  </Col>
                  <Col flex1 marginLeft24>
                    <Text numberOfLines={3} subtitle1>{writingData.name}</Text>
                    <Text body1>{writingData.createdBy?.name}</Text>
                    <Text subtitle1 marginTop16>{displayChapData.name}</Text>
                  </Col>
                </Row>
              </Col>
            <Col>

              <Col maxWidth={700} width="80%" alignSelf="center">

                <Col marginVertical24>
                  {Boolean(shouldShowDraft) && (
                    <Text color={COLOR.DANGER_500} fontStyle="italic">* Chương này không hiển thị công khai</Text>
                  )}
                  {/* <HTMLView content={displayChapData.contentHTML} /> */}
                  <WritingReader
                    extraData={extraData}
                    writingId={writingData.id}
                    writingChapterId={chapterNumber}
                    html={displayChapData.contentHTML}
                  />
                  {displayChapData.content === 'Bạn cần mật khẩu để truy cập' && renderInputPassword()}
                </Col>

                <Col marginVertical16 bgWhite borderRadius4 borderColor={COLOR.BORDER} borderWidth1>
                  <LikeComments key={'chapter-' + chapterNumber} entityData={chapData} postType='writing-chapter' />
                </Col>

              </Col>

              

            </Col>
          </MainContainer>
        </Scroll>
      </Col>
      {Boolean(listChapters.length > 1) && (
        <Animated.View
          // @ts-ignore
          style={{
            borderTopColor: COLOR.BORDER,
            borderTopWidth: 1,
            position: 'fixed',
            bottom: stickyBottom,
            right: 0,
            left: 0,
            backgroundColor: 'white'
          }}
        >
          <Row maxWidth={700} width="80%" alignSelf="center" height67 stretch justifyContent="space-between">

            {Boolean(!prevChap.id) ? (
              <Row />
            ) : (
              <Row middle paddingHorizontal24 onPress={() => navigate(SCREEN_NAME.WritingDetailChapter, { id, chapterNumber: prevChap.id, name: VarHelper.toSlug(prevChap.name) })}>
                <Feather name="arrow-left" size={20} color={COLOR.GRAY_500} />
                <Text marginLeft8>Chương trước</Text>
              </Row>
            )}

            {Boolean(!nextChap.id) ? (
              <Row />
            ) : (
              <Row middle paddingHorizontal24 onPress={() => navigate(SCREEN_NAME.WritingDetailChapter, { id, chapterNumber: nextChap.id, name: VarHelper.toSlug(nextChap.name) })}>
                <Text marginRight8>Chương sau</Text>
                <Feather name="arrow-right" size={20} color={COLOR.GRAY_500} />
              </Row>
            )}
          </Row>
        </Animated.View>
      )}
      <UseScrollDirection
        onScrollingDown={() => {
          // @ts-ignore
          if (stickyBottom.__getValue() === -68) return;
          Animated.timing(stickyBottom, {
            duration: 150,
            useNativeDriver: true,
            toValue: -68
          }).start();
        }}
        onScrollingUp={() => {
          // @ts-ignore
          if (stickyBottom.__getValue() === 0) return;
          Animated.timing(stickyBottom, {
            duration: 150,
            useNativeDriver: true,
            toValue: 0
          }).start();
        }}
      />
    </Col>
  );
};

export default WritingDetailChapter;