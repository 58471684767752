import React from 'react';
import { Col, Row, Text } from 'react-quick-style-components';
import { Octicons } from '@expo/vector-icons';
import { COLOR } from 'const';
import CommentModal from './CommentModal';
import { showModal } from 'components';
import { useNavFunc } from 'navigation';
import { IMangaChapter } from 'type';

interface IMangaImageItemWithCommentProps {
  mangaChapterId: string,
  mangaId: string,
  url: string,
  extraData: {
    id: string,
    numberOfComments: number,
    mangaChapterId: string,
    mangaId: string,
  },
  noComment?: boolean,
  sources?: {
    imgbb?: string,
    cloudflare?: string,
    [sourceName: string]: string,
  }
}

const MangaImageItemWithComment = (props: IMangaImageItemWithCommentProps) => {
  const { navigation } = useNavFunc();

  const imageUrl = (() => {
    return props.url;
  })();

  const showCommentModal = () => {
    if (props.noComment) return;
    showModal({
      component: (
        <CommentModal
          mangaId={props.mangaId}
          mangaChapterId={props.mangaChapterId}
          id={props.url}
          imageUrl={imageUrl}
          navigation={navigation}
        />
      ),
      noWrapper: true,
    })
  }

  return (
    <Col>
      <img draggable={false} src={imageUrl} style={{ width: '100%', height: 'auto', cursor: props.noComment ? undefined : 'pointer' }} loading="lazy" onClick={showCommentModal} />
      {!props.noComment && (
        <Col
          absolute top30 right={-40} width40 height40 middle backgroundColor="rgba(255,255,255,0.5)" borderRadius4
          onPress={showCommentModal}
        >
          {Boolean(props.extraData?.numberOfComments > 0) && (
            <Text bold colorMain marginRight4>{props.extraData.numberOfComments}</Text>
          )}
          <Octicons name="comment-discussion" size={24} color={COLOR.MAIN} />
        </Col>
      )}
    </Col>
  )
};

export default MangaImageItemWithComment;