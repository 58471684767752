import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Col, Text, Row, Img, useDynamicResponsiveValue } from 'react-quick-style-components';
import { CMSLayout, Button, Input, Uploader, Select, useRefState } from 'components';
import { Linking } from 'react-native';
import { useNavFunc } from 'navigation';
import { COLOR, DEVICE_INFO, ERROR, SCREEN_NAME, BOOK_VISIBILITY } from 'const';
import { ICON } from 'assets';
import Store from 'store';
import { VarHelper } from 'helpers';
import { Entypo } from '@expo/vector-icons';

const CMSCreateBook = () => {
  const { goBackOrTo, navigate } = useNavFunc();
  const [btnText, setBtnText] = useState('');
  const [name, setName] = useRefState('');
  const [description, setDescription] = useRefState('');
  const [buyLink, setBuyLink] = useRefState('');
  const [loading, setLoading] = useState(false);
  const [authorName, setAuthorName] = useRefState('');
  const [publisher, setPublisher] = useRefState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [genres, setGenres] = useState([]);
  const [visibility, setVisibility] = useState('draft');
  const [similarBooks, setSimilarBooks] = useState([]);
  const [localFile, setLocalFile] = useState({
    path: '',
    mime: '',
    name: '',
    raw: undefined,
  });
  const rV = useDynamicResponsiveValue();
  const [{ }, bookApi] = Store.Book.createBookStore();
  const [{ listGenres }, gApi] = Store.Genres.createStore();
  const uploaderRef = useRef<{ openFileDialog: Function, uploadLocal: Function }>();

  useEffect(() => {
    gApi.getList('BOOK');
  }, []);

  const searchForExistedBook = async () => {
    const keyword = name();
    if (!keyword) return;
    const [res, err] = await bookApi.listBook({
      visibility: 'all',
      page: 1,
      pageSize: 3,
      options: {
        search: [{ label: 'name', value: keyword }],
      }
    });
    if (res && res.data && res.data.rows) {
      setSimilarBooks(res.data.rows);
    }
  }

  const submit = async () => {
    if (!localFile.path) return setErrorMessage(ERROR.book_need_image);
    if (!uploaderRef.current) return setErrorMessage(ERROR.co_error);
    if (!name || !authorName || genres.length === 0) return setErrorMessage(ERROR.co_fill_all_required_form);
    setLoading(true);
    const [bookImage, err] = await uploaderRef.current.uploadLocal(localFile);
    if (err) {
      setLoading(false);
      return setErrorMessage(ERROR.parseError(err));
    }
    const [res, err2] = await bookApi.adminCreateBook({
      name: name(),
      authors: [{ id: 0, name: authorName(), avatar: '' }],
      content: description(),
      buyLinks: !buyLink() ? [] : [buyLink()],
      genres: genres.map(val => ({ id: val.value, name: val.label })),
      publisher: publisher(),
      images: !!bookImage ? [bookImage] : [],
      visibility,
    });
    setLoading(false);
    if (err2) return setErrorMessage(ERROR.parseError(err2));
    if (res.error) return setErrorMessage(ERROR.parseError(res.error));
    setBtnText('Tạo sách thành công..');
    setTimeout(() => {
      setBtnText('');
      setTimeout(() => {
        goBackOrTo(SCREEN_NAME.CMSListBook)
      }, 500)
    }, 500);
  };

  return (
    <Col flex1>
      <CMSLayout title="Tạo Sách">
        <Row>
          <Col flex1>
            <Text h4>TẠO SÁCH</Text>
          </Col>
          <Row> 
            <Button
              paddingHorizontal12 small outline borderColor={COLOR.MAIN} borderWidth1
              text="Tạo hàng loạt"
              marginRight12
              onPress={() => navigate(SCREEN_NAME.CMSBulkCreateBook)}
            />
            <Button paddingHorizontal12 small solid text="Quay lại" onPress={() => goBackOrTo(SCREEN_NAME.CMSListBook)} />
          </Row>
        </Row>

        <Row marginTop24 alignItems="flex-start">
          {useMemo(() => (
            <Col
              width160 height256 borderColor="#C9C9C9" borderWidth1 borderStyle="dashed"
              borderRadius8 backgroundColor="#EFEFEF" overflow="hidden"
              onPress={() => uploaderRef.current?.openFileDialog()}
              onHoverStyle={{ backgroundColor: COLOR.GRAY_200 }}
              useNestedHover useNativeStyleProps
            >
              {Boolean(!localFile.path) ? (
                <Col flex1 middle>
                  <ICON.Plus />
                  <Text center width83 marginTop16 subtitle1 color={COLOR.GRAY_500}>Thêm ảnh bìa sách</Text>
                </Col>
              ) : (
                <Col flex1>
                  <Col absoluteFill>
                    <Img source={{ uri: localFile.path }} width100p height100p resizeMode="cover" />
                  </Col>
                  <Col absoluteFill middle opacity={0} backgroundColor="rgba(0,0,0,0.7)" onHoverStyle={{ opacity: 1 }} useNativeStyleProps>
                    <ICON.WhitePen />
                    <Text center width83 marginTop16 subtitle1 colorWhite>Sửa ảnh bìa sách</Text>
                  </Col>
                </Col>
              )}
              <Uploader
                ref={uploaderRef}
                onChangeLocal={(newLocal) => setLocalFile(newLocal)}
              />
            </Col>
          ), [localFile])}
          <Col flex1 marginLeft={rV({ xs: 8, lg: 40 })}>
            <Input
              label="Tên sách" required
              onChange={setName}
              placeholder="Nhập tên sách" useRefState
              inputProps={{
                onBlur: searchForExistedBook,
              }}
            />
            {Boolean(similarBooks.length > 0) && (
              <Col marginTop12 paddingHorizontal12>
                <Text marginBottom8>Tìm kiếm sách có tên tương tự</Text>
                <Row flexWrap="wrap">
                  {similarBooks.map(val => (
                    <Row padding8 borderRadius8 backgroundColor="rgba(0,0,0,0.1)" marginRight8 marginBottom8 onPress={() => navigate(SCREEN_NAME.CMSEditBook, { id: val.id, name: VarHelper.toSlug(val.name) })}>
                      <Entypo name="book" size={12} color={COLOR.MAIN} /> <Text marginLeft4>{val.name}</Text>
                    </Row>
                  ))}
                </Row>
              </Col>
            )}
            <Input label="Tên tác giả" required value={authorName} onChange={setAuthorName} placeholder="Nhập tên tác giả" marginTop16 useRefState />
            <Select
              label="Thể loại"
              value={genres}
              options={listGenres.map(val => ({ label: val.name, value: val.id }))}
              onChange={setGenres}
              isMulti
              placeholder="Tìm và chọn thể loại"
              marginTop16
            />
          </Col>
        </Row>

        <Input
          marginTop45
          label="Mô tả thêm về cuốn sách" placeholder=""
          multiline
          onChange={setDescription}
          inputStyle={{ padding: 12 }}
          useRefState
        />

        <Input label="Nhà xuất bản" useRefState onChange={setPublisher} placeholder="" marginTop24 />

        <Input
          marginTop24
          label="Link mua sách"
          placeholder=""
          onChange={setBuyLink}
          inputStyle={{ padding: 12 }}
          rightIcon={{
            comp: <Text colorMain fontSize12>Xem</Text>,
            onPress: () => DEVICE_INFO.IS_WEB ? window.open(buyLink(), '_blank') : Linking.openURL(buyLink()),
          }}
          useRefState
        />

        <Row marginTop24 marginBottom200 justifyContent="space-between" alignItems="flex-start">
          <Col width150>
            <Select
              label="Trạng thái hiển thị"
              options={Object.keys(BOOK_VISIBILITY).map(val => ({ value: val, label: BOOK_VISIBILITY[val] }))}
              value={{ label: BOOK_VISIBILITY[visibility], value: visibility }}
              onChange={(v) => setVisibility(v.value)}
            />
          </Col>
          <Col height100p justifyContent="flex-end">
            {Boolean(errorMessage) && <Text color={COLOR.DANGER_500} marginTop8>{errorMessage}</Text>}
            <Button small solid text={btnText ? btnText : "Xác nhận"} loading={loading} paddingHorizontal12 onPress={submit} />
          </Col>
        </Row>
      </CMSLayout>
    </Col>
  );
};

export default CMSCreateBook;