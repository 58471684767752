import React, { useEffect } from 'react';
import { useNavFunc } from 'navigation';
import { Col } from 'react-quick-style-components';
import { Header } from 'components';
import Store from 'store';
import { SCREEN_NAME } from 'const';
import { VarHelper } from 'helpers';

const NotificationDetail = () => {
  const { reset, route } = useNavFunc();
  const [{}, uApi] = Store.User.createUserStore();
  const [{}, wApi] = Store.Writing.createStore();
  const { id } = route.params || {};

  const getData = async () => {
    const [res, err] = await uApi.detailNotification(id);
    // console.log(res, err);
    if (err) return reset(SCREEN_NAME.Home);
    if (res.error) return reset(SCREEN_NAME.Home);
    const { object, data } = res.data;
    if (object.type === 'book') return reset(SCREEN_NAME.BookDetail, { id: data.id, name: VarHelper.toSlug(data.name) });
    if (object.type === 'review') return reset(SCREEN_NAME.BookDetailSingleReview, { bookid: data.bookId, bookName: VarHelper.toSlug(data.bookName), id: data.id });
    if (object.type === 'group-post') return reset(SCREEN_NAME.GroupDetailEditPost, { id: data.groupId, name: '_', tab: 'feed', postid: data.id, postmode: 'chi-tiet' });
    if (object.type === 'user') return reset(SCREEN_NAME.UserProfile, { id: data.id, name: VarHelper.toSlug(data.name) });
    if (object.type === 'quote') return reset(SCREEN_NAME.QuoteDetail, { id: data.id });
    if (object.type === 'writing') return reset(SCREEN_NAME.WritingDetail, { id: data.id, name: VarHelper.toSlug(data.name) });
    if (object.type === 'writing-chapter') {
      const [res2, err2] = await wApi.detail(data.writingId);
      if (!!res2 && !!res2.data) {
        const findChapterIndex = res2.data.listChapters?.findIndex(val => val.id === data.id);
        if (findChapterIndex !== -1) {
          return reset(SCREEN_NAME.WritingDetailChapter, { id: data.writingId, name: VarHelper.toSlug(res2.data.name), chapterNumber: findChapterIndex + 1 })
        }
      }
    }
  };  

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <Col flex1>
      <Header />
    </Col>
  );
};

export default NotificationDetail;