import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row, Text, usePropsStyle, Img } from 'react-quick-style-components';
import { RatioCol } from 'components';
import { useNavFunc } from 'navigation';
import Store from 'store';
import { TimeHelper, VarHelper } from 'helpers';
import { COLOR, SCREEN_NAME } from 'const';

interface IGenresWidgetProps {
  onPressGenre?: any,
  [anyProps: string]: any,
}

const GenresWidget = (props: IGenresWidgetProps) => {
  const { navigate } = useNavFunc();
  const propStyle = usePropsStyle(props);
  const [{ listFeatured }, nApi] = Store.News.createStore();
  useEffect(() => {
    if (listFeatured.length === 0) nApi.getListFeatured({ visibility: 'public', page: 1, pageSize: 2 });
  }, []);

  return useMemo(() => {
    return listFeatured.length === 0 ? null : (
      <Col style={propStyle}>
        <Col>
          <Text h6>TIN NỔI BẬT</Text>
        </Col>
        <Col marginTop12>
          {listFeatured.slice(0, 2).map((val, i) => (
            <Col marginTop12 key={val.id}>
              <RatioCol ratio={365 / 228} width='100%' onPress={() => navigate(SCREEN_NAME.NewsDetail, { id: val.id, name: VarHelper.toSlug(val.name) })}>
                <Img source={{ uri: !!val.images && val.images.length > 0 ? val.images[0] : '' }} width100p height100p />
              </RatioCol>
              <Col onPress={() => navigate(SCREEN_NAME.NewsDetail, { id: val.id, name: VarHelper.toSlug(val.name) })}>
                <Text subtitle1 numberOfLines={2} marginTop16>{val.name}</Text>
                <Text body2 numberOfLines={1} color={COLOR.GRAY_500} marginTop8>Đăng ngày: {TimeHelper.format(val.createdAt, 'DD/MM/YYYY')}</Text>
              </Col>
            </Col>
          ))}
        </Col>
        <Col marginTop8 onPress={() => navigate(SCREEN_NAME.News)}>
          <Text bold colorMain marginTop12>Xem thêm</Text>
        </Col>
      </Col>
    );
  }, [listFeatured]);
};

export default GenresWidget;