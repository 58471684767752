import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={15}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M8.672 11.625c.38 0 .703-.293.703-.703V6h2.549a.577.577 0 00.41-.996L7.881.55a.585.585 0 00-.791 0L2.637 5.004a.577.577 0 00.41.996h2.578v4.922c0 .41.293.703.703.703h2.344zM15 11.391a.713.713 0 00-.703-.704h-3.985v.235c0 .908-.761 1.64-1.64 1.64H6.328c-.908 0-1.64-.732-1.64-1.64v-.235H.703a.694.694 0 00-.703.704v3.28c0 .411.293.704.703.704h13.594c.38 0 .703-.293.703-.703V11.39zm-3.633 2.578a.588.588 0 01-.586.586.588.588 0 01-.586-.586c0-.322.264-.586.586-.586.322 0 .586.264.586.586zm1.875 0a.588.588 0 01-.586.586.588.588 0 01-.586-.586c0-.322.264-.586.586-.586.322 0 .586.264.586.586z"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
