import React from 'react';

export const listBook6 = {
  width: 1065,
  height: 160,
  bg: 'white',
  color: '#ece4e4',
  renderChildren: () => (
    <>
      <rect x="0" y="0" rx="0" ry="0" width="100" height="160" />
      <rect x={String(100+16)} y="0" rx="0" ry="0" width="142" height="20" />
      <rect x={String(100+16)} y={String(20+8)} rx="0" ry="0" width="142" height="10" />
      <rect x={String(100+16)} y={String(20+8+10+32)} rx="0" ry="0" width="142" height="30" />

      <rect x="363" y="0" rx="0" ry="0" width="100" height="160" />
      <rect x={String(463+16)} y="0" rx="0" ry="0" width="142" height="20" />
      <rect x={String(463+16)} y={String(20+8)} rx="0" ry="0" width="142" height="10" />
      <rect x={String(463+16)} y={String(20+8+10+32)} rx="0" ry="0" width="142" height="30" />

      <rect x="726" y="0" rx="0" ry="0" width="100" height="160" />
      <rect x={String(826+16)} y="0" rx="0" ry="0" width="142" height="20" />
      <rect x={String(826+16)} y={String(20+8)} rx="0" ry="0" width="142" height="10" />
      <rect x={String(826+16)} y={String(20+8+10+32)} rx="0" ry="0" width="142" height="30" />
    </>
  ),
}