import React, { useState, useEffect } from 'react';
import { Col, Row, Text, Scroll, Img } from 'react-quick-style-components';
import { COLOR, DEVICE_INFO, SCREEN_NAME } from 'const';
import { NavigationProp } from '@react-navigation/native';
import { showModal, hideModal, Tooltip, Avatar } from '../../elements';
import { useSubmitUIState } from '../../hooks';
import Store from 'store';
import { TimeHelper, VarHelper } from 'helpers';
import { MaterialIcons } from '@expo/vector-icons';
import { ActivityIndicator } from 'react-native';
import { INotification } from 'type';
import { ICON } from 'assets';

interface INotificationItemProps {
  data: INotification,
}

const NotificationItem = (props : INotificationItemProps) => {
  const uApi = Store.User;
  const { data } = props;
  const [status, setStatus] = useState(data?.status);
  const [subject, setSubject] = useState('');
  const [image, setImage] = useState({ type: '', uri: '', name: '' });
  useEffect(() => {
    if (!data) return;
    setStatus(data?.status);
    if (data.action === 'RECEIVE_FRIEND_REQUEST') {
      setSubject(`${data?.createdBy.name} đã gửi lời mời kết bạn`);
      setImage({ type: 'user', name: data?.createdBy.name, uri: data?.createdBy.avatar });
    } else if (data.action === 'FRIEND_REQUEST_ACCEPTED') {
      setSubject(data?.subject);
      setImage({ type: 'user', name: data?.createdBy.name, uri: data?.createdBy.avatar });
    } else {
      setSubject(data?.subject);
    }

  }, [data]);

  const handlePress = async () => {
    await uApi.markReadNotification({ id: data.id, isAll: false });
    if (!!data.linkPage) {
      window.location.href = window.location.href.includes('localhost') ? data.linkPage.replace('https://readism.vn', 'https://localhost:19006') : data.linkPage;
    };
  };

  return (
    <Row onPress={handlePress} alignItems="flex-start" padding12 borderBottomWidth1 borderBottomColor={COLOR.BORDER} onHoverStyle={{ backgroundColor: COLOR.PRI_100 }}>
      <Col width40 height40 borderRadius20 bgMain middle>
        {Boolean(!image.uri && !image.type) ? (
          <ICON.Notification width={30} height={30} />
        ) : Boolean(image.type === 'user') ? (
          <Avatar size={40} uri={image.uri} name={image.name} />
        ) : (
          <Img source={{ uri: image.uri }} style={{ width: 35, height: 35, borderRadius: 35/2 }} resizeMode="cover" />
        )}
      </Col>
      <Col flex1 marginLeft12>
        <Text bold={status === 'unread'} marginBottom4>{subject}</Text>
        <Text captain color={COLOR.GRAY_500}>{TimeHelper.fromNow(data?.createdAt)}</Text>
      </Col>
    </Row>
  )
};

export default NotificationItem;